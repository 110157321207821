import React, { useState } from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import { Icons } from 'assets'
import { DimensionValue, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { isNative } from 'lib/common'
import { R } from 'lib/utils'
import { Touchable, Typography } from 'lib/components'
import { CustomFonts, JobType } from 'lib/types'
import { Segment } from 'lib/analytics'
import {
    useAutoSelectFrequencyJobTypeAtom,
    useAutoSelectOneTimeJobTypeAtom,
    useSubscriptionSpecialRecommendationAtom,
    useUserAtom
} from 'lib/atoms'
import { FinalPrice } from 'features/bookings/components/FinalPrice'
import { redirectToSupply } from 'lib/utils/navigation'

export const SubscriptionSpecialRecommendation: React.FunctionComponent = () => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const navigation = useNavigation()
    const [isShowFullDescription, setIsShowFullDescription] = useState<boolean>(!isNative)
    const { styles, theme } = useStyles(stylesheet)
    const [subscriptionSpecialRecommendation] = useSubscriptionSpecialRecommendationAtom()
    const [, setAutoSelectFrequencyJobTypeAtom] = useAutoSelectFrequencyJobTypeAtom()
    const [, setAutoSelectOneTimeJobTypeAtom] = useAutoSelectOneTimeJobTypeAtom()

    const title = subscriptionSpecialRecommendation?.title
    const originalPrice = subscriptionSpecialRecommendation?.originalPrice
    const finalPrice = subscriptionSpecialRecommendation?.finalPrice || ''
    const supplyDescriptionList = subscriptionSpecialRecommendation?.supplyDescriptionList || []
    const remark = subscriptionSpecialRecommendation?.remark
    const ctaButtonText = subscriptionSpecialRecommendation?.ctaButtonText
    const supplyId = subscriptionSpecialRecommendation?.supplyId
    const jobType = subscriptionSpecialRecommendation?.jobType
    const tags = subscriptionSpecialRecommendation?.tags || []
    const topTag = tags?.[0] ?? ''
    const bottomTag = tags?.[1] ?? ''

    const bookJobBySupplyId = (supplyId: number) => {
        Segment.subscriptionBookNowClicked({
            serviceType: supplyId
        })

        jobType === JobType.Recurring ?
            setAutoSelectFrequencyJobTypeAtom(true) :
            setAutoSelectOneTimeJobTypeAtom(true)

        redirectToSupply(supplyId, user, navigation)
    }

    const onShowFullDescription = () => setIsShowFullDescription(true)

    return R.isDefined(supplyId) ? (
        <LinearGradient
            style={styles.wrapper}
            start={{ x: 0, y: 0 }}
            end={{ x: 0, y: 1 }}
            colors={[theme.colors.peach, theme.colors.white]}
        >
            <View>
                {
                    topTag ? (
                        <View style={styles.badgeWrapper}>
                            <View style={styles.badgeContainer}>
                                <View style={styles.tagIcon}>
                                    <Icons.Percent viewBox="0 0 18 18" size={18} />
                                </View>
                                <Typography.Regular style={styles.tag}>{topTag}</Typography.Regular>
                            </View>
                        </View>
                    ): <View style={styles.placeholder} />
                }
                <View style={styles.row}>
                    <View style={styles.headerContainer}>
                        <Typography.Regular style={styles.benefitTitle}>{title}</Typography.Regular>
                        <View style={styles.priceContainer}>
                            <Typography.Regular style={styles.originalPrice}>{originalPrice}</Typography.Regular>
                            <FinalPrice price={finalPrice as string}/>
                            {
                                bottomTag && (
                                    <View style={styles.recommendedContainer}>
                                        <View style={styles.recommendedIcon}>
                                            <Icons.ThumbUp viewBox="0 0 48 48" size={15} forceColor={theme.colors.green} />
                                        </View>
                                        <Typography.Regular style={styles.recommendedLabel}>{bottomTag}</Typography.Regular>
                                    </View>
                                )
                            }

                        </View>
                    </View>
                </View>
                {
                    isShowFullDescription ? (
                        <View style={styles.row}>
                            <View style={styles.bodyContainer}>
                                <View style={styles.benefitsContainer}>
                                    {
                                        supplyDescriptionList.map((description, index) => (
                                            <View key={index} style={styles.benefitContainer}>
                                                <View style={styles.benefitIcon}>
                                                    <Icons.CheckCircle size={22} forceColor={theme.colors.orange}/>
                                                </View>
                                                <Typography.Regular style={styles.benefit}>{description}</Typography.Regular>
                                            </View>
                                        ))
                                    }
                                </View>
                                <Typography.Regular style={styles.notice}>{remark}</Typography.Regular>
                            </View>
                        </View>
                    ): (
                        <View style={styles.row}>
                            <Touchable style={styles.learnMoreButton} onPress={onShowFullDescription}>
                                <Typography.Regular>{T.subscriptionsRecommendation.learnMore}</Typography.Regular>
                                <View style={styles.learMoreIcon}>
                                    <Icons.Chevron size={12} forceColor={theme.colors.grey}/>
                                </View>
                            </Touchable>
                        </View>
                    )}
            </View>
            <View style={styles.bottomWrapper}>
                {
                    isNative ? (
                        <View>
                            <Touchable testID={T.accessibility.bookingsScreen.subscriptionRecommendations.special} style={styles.bookNowButtonOnMobile} onPress={() => bookJobBySupplyId(supplyId as number)}>
                                <View style={styles.spacer}/>
                                <Typography.Regular style={styles.bookNowText}>{ctaButtonText}</Typography.Regular>
                                <View style={styles.bookNowIcon}>
                                    <Icons.Chevron size={12} />
                                </View>
                            </Touchable>
                        </View>
                    ) : (
                        <View style={styles.row}>
                            <View style={styles.footerContainer}>
                                <Touchable testID={T.accessibility.bookingsScreen.subscriptionRecommendations.special} style={styles.bookNowButton} onPress={() => bookJobBySupplyId(supplyId as number)}>
                                    <View style={styles.spacer}/>
                                    <Typography.Regular>{ctaButtonText}</Typography.Regular>
                                    <View style={styles.bookNowIcon}>
                                        <Icons.Chevron size={12} />
                                    </View>
                                </Touchable>
                            </View>
                        </View>
                    )}

            </View>
        </LinearGradient>
    ) : null
}

const stylesheet = createStyles(theme => ({
    bottomWrapper: {
        paddingTop: theme.utils.gap(1),
        paddingBottom: {
            lg: theme.utils.gap(2),
            md: theme.utils.gap(1.7),
            xs: theme.utils.gap(1.5)
        }
    },
    badgeWrapper: {
        width: isNative ? '40%' : 'fit-content' as DimensionValue
    },
    badgeContainer: {
        backgroundColor: theme.colors.flame,
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: theme.utils.gap(0.75),
        paddingVertical: theme.utils.gap(0.5),
        borderTopLeftRadius: 8,
        borderBottomRightRadius: 8,
        width: isNative ? undefined : 'fit-content' as DimensionValue
    },
    tag: {
        color: theme.colors.white,
        fontSize: 14,
        fontFamily: CustomFonts.Roboto400
    },
    tagIcon: {
        marginRight: theme.utils.gap(0.2)
    },
    headerContainer: {
        paddingTop: theme.utils.gap(1),
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    row: {
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            md: theme.utils.gap(1.7),
            xs: theme.utils.gap(1.5)
        }
    },
    benefitTitle: {
        fontSize: 20,
        fontFamily: CustomFonts.Poppins500,
        lineHeight: 24,
        whiteSpace: 'pre-line',
        flex: 1
    },
    benefitIcon: {
        minWidth: 22
    },
    priceContainer: {
        alignItems: 'flex-end',
        flex: {
            sm: 0.4,
            xs: 0.6
        }
    },
    originalPrice: {
        textDecorationLine: 'line-through',
        color: theme.colors.grey,
        fontSize: 12,
        fontFamily: CustomFonts.Poppins400
    },
    finalPrice: {
        color: theme.colors.orange,
        fontSize: 16,
        fontFamily: CustomFonts.Roboto400,
        marginTop: theme.utils.gap(0.3)
    },
    finalPriceNumber: {
        color: theme.colors.orange,
        fontSize: 31,
        fontFamily: CustomFonts.Poppins600
    },
    recommendedContainer: {
        backgroundColor: theme.colors.lightGreen,
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(0.25),
        borderRadius: 10,
        marginTop: theme.utils.gap(0.3),
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    recommendedLabel: {
        fontSize: 12,
        fontFamily: CustomFonts.Roboto500,
        color: theme.colors.green,
        marginLeft: theme.utils.gap(isNative ? 1 : 0.5)
    },
    recommendedIcon: {
        marginTop: theme.utils.gap(0.1)
    },
    bodyContainer: {
        flexDirection: 'column',
        paddingTop: isNative ? theme.utils.gap(1) : 0
    },
    benefitsContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingTop: theme.utils.gap(0.5)
    },
    benefitContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1)
    },
    benefit: {
        marginLeft: theme.utils.gap(0.5),
        fontSize: 16,
        fontFamily: CustomFonts.Roboto400,
        color: theme.colors.darkGrey
    },
    notice:{
        fontSize: 12,
        fontFamily: CustomFonts.Roboto400,
        color: theme.colors.grey
    },
    footerContainer: {
        flexDirection: 'row',
        justifyContent: {
            xl: 'flex-end',
            lg: 'center',
            xs: 'center'
        },
        alignItems: 'center'
    },
    learnMoreButton: {
        flexDirection: 'row',
        paddingTop: theme.utils.gap(1.5),
        alignItems: 'center',
        fontSize: 12,
        fontFamily: CustomFonts.Roboto400,
        color: theme.colors.grey,
        paddingBottom: theme.utils.gap(1)
    },
    bookNowButton: {
        borderRadius: 4,
        borderColor: theme.colors.sun,
        borderWidth: 2,
        borderStyle: 'solid',
        backgroundColor: theme.colors.sun,
        paddingVertical: theme.utils.gap(1.25),
        paddingHorizontal: theme.utils.gap(2.25),
        flexDirection: 'row',
        justifyContent: {
            xl: 'center',
            lg: 'space-between',
            xs: 'space-between'
        },
        alignItems: 'center',
        width: {
            xl: isNative ? undefined : 'max-content' as DimensionValue,
            lg: '100%',
            xs: '100%'
        },
        userSelect: 'none'
    },
    bookNowButtonOnMobile: {
        borderTopWidth: 1.5,
        paddingLeft: theme.utils.gap(0.75),
        borderColor: theme.colors.fog,
        backgroundColor: theme.colors.transparent,
        paddingTop: theme.utils.gap(2),
        paddingBottom: 0,
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    bookNowText: {
        fontSize: 16,
        fontFamily: CustomFonts.Roboto500
    },
    bookNowIcon: {
        transform: [{ rotate: '270deg' }],
        marginLeft: theme.utils.gap(0.5)
    },
    learMoreIcon: {
        marginLeft: theme.utils.gap(0.5)
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    wrapper: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        flex: 1,
        height: '100%',
        minHeight: {
            xl: 310,
            xs: isNative ? undefined : 'max-content' as DimensionValue
        },
        width: {
            xl: 270,
            xs: '100%'
        },
        borderRadius: 8,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.1))
    },
    placeholder: {
        height: theme.utils.gap(4)
    }
}))
