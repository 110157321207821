export enum Period {
    Early = 'early',
    Midday = 'midday',
    Late = 'late',
}

export enum PeriodLabel {
    Early = 'Early',
    Midday = 'Midday',
    Late = 'Late',
}

export interface TimeSlot {
    id: string;
    label: string;
    price?: string;
    group: Period;
    groupLabel: PeriodLabel;
}
