// we need a mock as during development
// segment is throwing errors
import { IdentifyTraits } from './types'

/* eslint-disable @typescript-eslint/no-unused-vars */
export const DevMockedSegment = {
    identify: (userId: string, traits: IdentifyTraits) => {},
    reset: () => {},
    track: () => {},
    bookServiceClicked: () => {},
    page: (screenName: string) => {}
}
/* eslint-enable @typescript-eslint/no-unused-vars */
