import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Segment, ServiceType } from 'lib/analytics'
import { useDynamicPriceAtom, useFeatureFlagAtom, useLocaleAtom } from 'lib/atoms'
import { BasicModal, Touchable, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { BookingFrequency, Children, CustomFonts, FrequencyOption, Nullable } from 'lib/types'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { TimeSlot } from 'features/bookings/types'
import { BookingDateCalendarPicker, BookingDateWeeklyService } from 'features/bookings/components'
import { openDynamicPriceTermsAndConditions } from 'lib/utils/linking'
import { CalendarMessage } from 'lib/components/form/adapters/components'

type BookingDateModalProps = {
    isModalOpen: boolean,
    startingDateField: Field<string>,
    bookingTimeField: Field<string>,
    bookingDaysField: Field<Record<string, Nullable<string>>>,
    maxDate?: string,
    frequency: FrequencyOption,
    onClose: VoidFunction,
    onContinue: VoidFunction,
    clearData?: VoidFunction,
    disabled?: boolean,
    header?: Children,
    isLoading?: boolean,
    title?: string,
    timeTitle?: string,
    timeDescription?: string,
    description?: string,
    minHoursNotice: number,
    timeSlots?: Array<TimeSlot>,
    isMultiSelect?: boolean,
    allowToggle?: boolean,
    testID?: string
    serviceType?: string
}

export const BookingDateModal: React.FunctionComponent<BookingDateModalProps> = ({
    isModalOpen,
    frequency,
    startingDateField,
    bookingTimeField,
    bookingDaysField,
    disabled,
    isLoading,
    header,
    onClose,
    onContinue,
    clearData,
    title,
    timeTitle,
    description,
    minHoursNotice,
    timeSlots,
    timeDescription,
    isMultiSelect,
    allowToggle,
    maxDate,
    testID,
    serviceType
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const [dynamicPriceData] = useDynamicPriceAtom()
    const [featureFlagAtom] = useFeatureFlagAtom()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const startingDate = isMultiSelect
        ? startingDateField.value.split(', ').at(0) || ''
        : startingDateField.value
    const isOneTime = frequency.value === BookingFrequency.OneTime
    const translationKey = frequency.value || BookingFrequency.OneTime
    const isMultiSelectValueSelected = Object.values(bookingDaysField.value).length === 0 || Object.values(bookingDaysField.value).some(value => value === null)
    const isValueSelected = isOneTime
        ? !bookingTimeField.value
        : Object.values(bookingDaysField.value).every(value => !value) || Object.values(bookingDaysField.value).some(value => value?.length === 0)
    const continueDisabled = isMultiSelect ? isMultiSelectValueSelected : isValueSelected

    const bookingDateDayName = dateHelpers.getWeekDayFullNames(startingDate || new Date(), locale)
    const selectedTime = dateHelpers.getDateTime(bookingDaysField.value[bookingDateDayName] || new Date())
    const selectedTimeDate = startingDate ? `${startingDate}T${selectedTime}` : new Date()
    const isPastMinimumNotice = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(selectedTimeDate || new Date(), minHoursNotice))
    const shouldShowCleaningDynamicTimeSlotLabel =
        jobHelpers.shouldShowCleaningDynamicTimeSlotLabel(serviceType as ServiceType, frequency as FrequencyOption, featureFlagAtom?.isEnableDynamicPricing) &&
        dynamicPriceData.messages?.calendarMessage

    return (
        <BasicModal
            testID={testID}
            insetsPadding
            isOpen={isModalOpen}
            isFullWindowSize={isMobile}
            onClose={() => {
                !isMultiSelect
                    ? Segment.bookingDateAndTimeModuleClosed({
                        dateSelected: Boolean(startingDateField.value),
                        timeSelected: Boolean(bookingTimeField.value)
                    })
                    : Segment.bookingInspectionDateAndTimeModuleClosed({
                        numberOfDatesSelected: Number(Object.values(bookingDaysField.value).filter(Boolean).length),
                        dateSelected: Boolean(startingDateField.value),
                        timeSelected: Boolean(bookingTimeField.value)
                    })

                onClose()

                if (clearData && isPastMinimumNotice) {
                    clearData()
                }
            }}
            isLoading={isLoading}
        >
            {
                shouldShowCleaningDynamicTimeSlotLabel ? (
                    <View>
                        <Typography.Regular style={styles.customLabel}>
                            {T.components.bookings.bookingDateModal.whenToClean}
                        </Typography.Regular>
                        <CalendarMessage calendarMessage={dynamicPriceData.messages.calendarMessage}/>
                        <Typography.Regular style={styles.link}>
                            {T.components.bookings.bookingDateModal.limitedSpotsOnly}<Touchable>
                                <Typography.Label onPress={openDynamicPriceTermsAndConditions}  style={styles.buttonLabel}>{` ${T.components.bookings.bookingDateModal.termsAndCondition}`}</Typography.Label>
                            </Touchable>
                        </Typography.Regular>
                    </View>
                ) : (
                    <View style={styles.title}>
                        <Typography.SmallSubheading>
                            {title ?? `${frequency?.title} ${T.screens.cleaning.calendarModal[translationKey].title}`}
                        </Typography.SmallSubheading>
                    </View>
                )
            }

            <View>
                {header}
            </View>
            <View
                style={isOneTime
                    ? styles.calendarContainer
                    : {
                        ...styles.calendarContainer,
                        ...styles.reverseOrder
                    }
                }
            >
                <BookingDateCalendarPicker
                    isOneTime={isOneTime}
                    frequency={frequency}
                    isLoading={isLoading}
                    onContinue={() => {
                        !isMultiSelect
                            ? Segment.bookingDateAndTimeSelected({})
                            : Segment.bookingInspectionDateAndTimeSelected({})

                        onContinue()
                    }}
                    allowToggle={allowToggle}
                    maxDate={maxDate}
                    isMultiSelect={isMultiSelect}
                    minHoursNotice={minHoursNotice}
                    continueLabel={T.common.continue}
                    continueDisabled={continueDisabled}
                    startingDateField={startingDateField}
                    selectedDays={bookingDaysField.value}
                    onChangeSelectedDays={bookingDaysField.onChangeValue}
                    description={description ?? T.screens.cleaning.calendarModal[translationKey].description}
                    disabled={disabled || !startingDate || continueDisabled || !bookingDaysField.value[bookingDateDayName] || isPastMinimumNotice}
                />
                <View style={styles.spacer} />
                <BookingDateWeeklyService
                    disabled={disabled}
                    timeTitle={timeTitle}
                    timeSlots={timeSlots}
                    isLoading={isLoading}
                    isOneTime={isOneTime}
                    onContinue={() => {
                        !isMultiSelect
                            ? Segment.bookingDateAndTimeSelected({})
                            : Segment.bookingInspectionDateAndTimeSelected({})

                        onContinue()
                    }}
                    startingDate={startingDate}
                    isMultiSelect={isMultiSelect}
                    translationKey={translationKey}
                    minHoursNotice={minHoursNotice}
                    timeDescription={timeDescription}
                    bookingDaysField={bookingDaysField}
                    continueDisabled={continueDisabled}
                    bookingTimeField={bookingTimeField}
                    onChangeSelectedDate={startingDateField.onChangeValue}
                    selectedDates={startingDateField.value}
                    serviceType={serviceType}
                />
            </View>
        </BasicModal>
    )
}

const stylesheet = createStyles(theme => ({
    title: {
        paddingHorizontal: {
            lg: theme.utils.gap(4),
            xs: 0
        },
        paddingVertical: {
            lg: theme.utils.gap(2),
            xs: 0
        }
    },
    calendarContainer: {
        width: '100%',
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        padding: {
            lg: theme.utils.gap(4),
            xs: 0
        },
        paddingTop: 0
    },
    description: {
        marginBottom: theme.utils.gap(2)
    },
    reverseOrder: {
        flexDirection: {
            lg: 'row-reverse',
            xs: 'column-reverse'
        }
    },
    spacer: {
        width: {
            lg: theme.utils.gap(4),
            xs: 0
        }
    },
    time: {
        flex: 1,
        minWidth: {
            lg: 300,
            xs: undefined
        },
        paddingTop: {
            lg: theme.utils.gap(2),
            xs: 0
        },
        justifyContent: 'space-between'
    },
    selectInputWrapper: {
        zIndex: theme.zIndex[10]
    },
    customLabel: {
        fontSize: 20,
        fontFamily: CustomFonts.Poppins600,
        lineHeight: 28,
        marginBottom: theme.utils.gap(2)
    },
    link: {
        fontSize: 13,
        fontFamily: CustomFonts.Roboto400,
        lineHeight: 22,
        marginTop: theme.utils.gap(1),
        color: theme.colors.grey
    },
    buttonLabel: {
        fontSize: 13,
        fontFamily: CustomFonts.Roboto400,
        color: theme.colors.grey,
        textDecorationLine: 'underline',
        marginTop: theme.utils.gap(0.25)
    }
}))
