import { useHive } from '@propertyguru/hive'
import { Icon } from '@propertyguru/hive-icon'
import { CalendarCheckCheckmarkAcceptOutline } from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { useStyles, useTranslations } from 'lib/hooks'
import { CleaningScheduleType, Schedule } from 'lib/native/models'
import { createStyles } from 'lib/styles'
import React from 'react'
import { View } from 'react-native'
import { dateHelpers } from 'lib/utils'

type StickyScheduleMessageProps = {
    message: string
    date: string
    schedules?: Array<Schedule>,
    interval?: CleaningScheduleType
}

export const StickyScheduleMessage: React.FunctionComponent<StickyScheduleMessageProps> = ({
    message,
    date,
    schedules,
    interval
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const getSequenceText = () => {
        const schedulesLength = schedules?.length || 0

        if (!schedulesLength) {
            return ''
        }

        return schedules?.map((schedule, index) => {
            const isFirst = index === 0
            const isLast = index === schedulesLength - 1
            const prefix = isFirst ?
                T.screens.cleaningDateTime.stickyScheduleMessage.then :
                isLast ?
                    ` ${T.screens.cleaningDateTime.stickyScheduleMessage.and}` :
                    `${T.screens.cleaningDateTime.stickyScheduleMessage.comma} `

            const intervalText = interval === CleaningScheduleType.Weekly ?
                T.screens.cleaningDateTime.stickyScheduleMessage.every :
                T.screens.cleaningDateTime.stickyScheduleMessage.everyOther

            return (
                <Text key={index} typography="body/s" color="text/success/primary">
                    {`${prefix}`}
                    <Text typography="caption/m" color="text/success/primary">
                        {` ${intervalText} ${dateHelpers.formatBookingDateWithDay(schedule.time)}`}
                    </Text>
                </Text>
            )
        })
    }

    return (
        <View style={styles.container}>
            <Icon icon={CalendarCheckCheckmarkAcceptOutline} color="icon/success/primary" />
            <Text typography="label/xs" color="text/success/primary">
                {message}
                <Text typography="label/s" color="text/success/primary">
                    {` ${date} `}
                </Text>
                <Text typography="body/s" color="text/success/primary">{getSequenceText()}</Text>
            </Text>
        </View>
    )
}

const stylesheet = createStyles(() => {
    const hive = useHive()

    return {
        container: {
            minHeight: 56,
            flexDirection: 'row',
            alignItems: 'center',
            gap: hive.spacing('x2'),
            padding: hive.spacing('x4'),
            paddingRight: hive.spacing('x10'),
            backgroundColor: hive.color('fill/success/secondary') as string
        }
    }
})
