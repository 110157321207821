import React from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { Modal } from '@propertyguru/hive-modal'
import { Text } from '@propertyguru/hive-text'
import { Badge } from '@propertyguru/hive-badge'
import { Divider } from '@propertyguru/hive-divider'
import { NativeModal } from 'lib/native/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type Props = {
    isOpen: boolean
    onClose: () => void
}

export const PlanGuideModal: React.FunctionComponent<Props> = ({ isOpen, onClose }) => {
    const T = useTranslations()
    const hive = useHive()
    const { styles } = useStyles(stylesheet)
    const regularCleaningItems = [
        T.components.planGuideModal.regularCleaning.list.one,
        T.components.planGuideModal.regularCleaning.list.two,
        T.components.planGuideModal.regularCleaning.list.three,
        T.components.planGuideModal.regularCleaning.list.four
    ]
    const oneTimeCleaningItems = [
        T.components.planGuideModal.oneTimeCleaning.list.one,
        T.components.planGuideModal.oneTimeCleaning.list.two,
        T.components.planGuideModal.oneTimeCleaning.list.three,
        T.components.planGuideModal.oneTimeCleaning.list.four
    ]

    return (
        <NativeModal fullWidth isOpen={isOpen} onClose={onClose}>
            <Modal>
                <Modal.Header>
                    <Modal.Header.Title>
                        {T.components.planGuideModal.title}
                    </Modal.Header.Title>
                    <Modal.Header.Button onPress={onClose} />
                </Modal.Header>
                <Modal.Body>
                    <View style={{ rowGap: hive.spacing('x3') }}>
                        <View style={styles.sectionTitle}>
                            <Text typography="label/m" color="text/active/primary">
                                {T.components.planGuideModal.regularCleaning.title}
                            </Text>
                            <Badge
                                label={T.components.planGuideModal.regularCleaning.badgeLabel}
                                type="primary"
                                color="green"
                                size="small"
                            />
                        </View>
                        <Text typography="body/s" color="text/active/secondary">
                            {T.components.planGuideModal.regularCleaning.description}
                        </Text>
                        <View style={{ rowGap: hive.spacing('x2'), paddingHorizontal: hive.spacing('x1') }}>
                            {regularCleaningItems.map((item, index) => (
                                <View key={index}>
                                    <Text typography="body/xs" color="text/active/secondary">
                                        •  {item}
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </View>
                    <Divider style={{ marginVertical: hive.spacing('x6') }} />
                    <View style={{ rowGap: hive.spacing('x3') }}>
                        <Text typography="label/m" color="text/active/primary">
                            {T.components.planGuideModal.oneTimeCleaning.title}
                        </Text>
                        <Text typography="body/s" color="text/active/secondary">
                            {T.components.planGuideModal.oneTimeCleaning.description}
                        </Text>
                        <View style={{ rowGap: hive.spacing('x2'), paddingHorizontal: hive.spacing('x1') }}>
                            {oneTimeCleaningItems.map((item, index) => (
                                <View key={index}>
                                    <Text typography="body/xs" color="text/active/secondary">
                                        •  {item}
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </View>
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

const stylesheet = createStyles(() => ({
    sectionTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}))
