import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
    AuthFinishSocialSignUpScreen,
    AuthForgotPasswordScreen,
    AuthNewPasswordScreen,
    AuthScreen,
    AuthSingleSignOnScreen
} from 'features/auth'
import { useTranslations } from 'lib/hooks'
import { ScreenNames } from '../screens'
import { AppStackParams } from '../stackParams'

const Stack = createNativeStackNavigator<AppStackParams>()

export const UnauthorizedWebStack = () => {
    const T = useTranslations()

    return (
        <React.Fragment>
            <Stack.Screen
                name={ScreenNames.Auth}
                component={AuthScreen}
                options={{
                    title: T.screens.auth.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AuthForgotPassword}
                component={AuthForgotPasswordScreen}
                options={{
                    title: T.screens.authOTP.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AuthNewPassword}
                component={AuthNewPasswordScreen}
                options={{
                    title: T.screens.authNewPassword.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AuthSingleSignOn}
                component={AuthSingleSignOnScreen}
                options={{
                    title: T.screens.authSingleSignOn.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AuthFinishSocialSignUp}
                component={AuthFinishSocialSignUpScreen}
                options={{
                    title: T.screens.authFinishSocialSignUp.pageTitle
                }}
            />
        </React.Fragment>
    )
}