import { useMutation } from '@tanstack/react-query'
import { useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { APP_CONFIG } from 'lib/config'
import {
    ResetPasswordRequest,
    ResetPasswordResponse,
    SendForgotPasswordEmailRequest,
    SendForgotPasswordEmailResponse,
    VerifyOTPRequest,
    VerifyOTPResponse
} from 'features/native/types'

export const requestSendForgotPasswordEmail = () => {
    const fetcher = useFetcher<SendForgotPasswordEmailResponse>(HttpMethod.POST, `${APP_CONFIG.AUTH.URL}/otp/send`)

    return useMutation<Response<SendForgotPasswordEmailResponse>, ErrorResponse, SendForgotPasswordEmailRequest>(fetcher)
}

export const requestVerifyOTP = () => {
    const fetcher = useFetcher<VerifyOTPResponse>(HttpMethod.POST, `${APP_CONFIG.AUTH.URL}/otp/verify`)

    return useMutation<Response<VerifyOTPResponse>, ErrorResponse, VerifyOTPRequest>(fetcher)
}

export const requestResetPassword = () => {
    const fetcher = useFetcher<ResetPasswordResponse>(HttpMethod.POST, `${APP_CONFIG.AUTH.URL}/password/reset`)

    return useMutation<Response<ResetPasswordResponse>, ErrorResponse, ResetPasswordRequest>(fetcher)
}

