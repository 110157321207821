import { useEffect } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useNavigation } from '@react-navigation/native'
import { useNativeIsAuthorizedAtom } from 'lib/native/atoms/auth'
import { useNativeUserAtom } from 'lib/native/atoms/user'
import { ScreenNamesNative } from '../screens'
import { NativeAppStackParams } from '../stackParams'
import { UnauthorizedNativeStack } from './UnauthorizedNativeStack'
import { ServicesStack } from './ServicesStack'
import { OnboardingStack } from './OnboardingStack'
import { AuthorizedNativeStack } from './AuthorizedNativeStack'
import { SharedStack } from './SharedStack'

const Stack = createNativeStackNavigator<NativeAppStackParams>()

export const MobileStack = () => {
    const [isAuthorized] = useNativeIsAuthorizedAtom()
    const [user] = useNativeUserAtom()
    const hasOnboardingCompleted = user?.hasOnboardingCompleted

    const navigation = useNavigation()

    useEffect(() => {
        if (!isAuthorized) {
            // Remove history from navigation stack
            navigation.reset({
                index: 0,
                routes: [{ name: ScreenNamesNative.LandingNative }]
            })
        }
    }, [isAuthorized])

    return (
        <Stack.Navigator
            initialRouteName={ScreenNamesNative.RootBottomTabs}
            screenOptions={{ headerShown: false }}
        >
            {!isAuthorized ? (
                UnauthorizedNativeStack()
            ) : hasOnboardingCompleted ? (
                AuthorizedNativeStack()
            ) : (
                OnboardingStack()
            )}
            {isAuthorized && SharedStack()}
            {ServicesStack()}
        </Stack.Navigator>
    )
}
