import { useState, useEffect } from 'react'
import * as SplashScreen from 'expo-splash-screen'
import { usePromotionAtom, useUserAtom } from 'lib/atoms'
import { SubscribePromotionAction } from 'lib/types'

export const useMarketingConsent = () => {
    const [user] = useUserAtom()
    const [, setPromotionAction] = usePromotionAtom()
    const [isLoaded, setIsLoaded]  = useState(false)

    useEffect(() => {

        if (isLoaded) {
            return
        }

        const hideSplashScreen = async () => {
            try {
                await SplashScreen.hideAsync()
                setIsLoaded(true)
            } catch (error) {
                setIsLoaded(true)
                console.warn(error)
            }
        }

        hideSplashScreen()

        if (!user
            || !user?.consumer
            || !user?.consumer?.marketing_consent
            || user?.consumer?.marketing_consent?.accepted) {
            return
        }

        const bookingCount = user?.bookingCount || 0
        const promotionType = bookingCount ?
            SubscribePromotionAction.ShouldShowMarketingConsentPopup :
            SubscribePromotionAction.ShouldShowMarketingConsentModal

        setPromotionAction(promotionType)
    }, [user, isLoaded])

    return null
}
