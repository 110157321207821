import React from 'react'
import { Surface } from '@propertyguru/hive-surface'
import { Text } from '@propertyguru/hive-text'
import { useHive } from '@propertyguru/hive'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { TimeSlot } from 'lib/native/types'
import {
    useNativeDynamicPriceSlotsAtom,
    useNativeCleaningConfigsAtom,
    useNativeJobQuotationsAtom
} from 'lib/native/atoms'
import { PriceSlot, PricingType } from 'lib/native/models'
import { PricingTypes } from '../models/DynamicPriceSlot'
import { dateHelpers } from 'lib/utils'

type Props = {
    slot: TimeSlot;
    scheduledDate: string;
    selectedSlot: TimeSlot | null;
    setSelectedSlot: (timeSlot: TimeSlot) => void;
    type?: PricingType;
    onSelectBookingTime?: (priceSlot: PriceSlot | undefined, slot?: TimeSlot) => void;
    shouldShowDynamicPriceInfo?: boolean;
}

export const ChipTimeSlot: React.FunctionComponent<Props> = ({
    selectedSlot,
    setSelectedSlot,
    slot,
    onSelectBookingTime,
    scheduledDate,
    type = PricingTypes.Dynamic,
    shouldShowDynamicPriceInfo = true
}) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)
    const [dynamicPriceSlots] = useNativeDynamicPriceSlotsAtom()
    const [cleaningConfigsAtom] = useNativeCleaningConfigsAtom()
    const [jobQuotationAtom] = useNativeJobQuotationsAtom()
    const { slots, daySelected } = dynamicPriceSlots || {}
    const dynamicPriceSlotsInDate = daySelected ? slots?.[daySelected] : {}
    const discountedTimeSlot = dynamicPriceSlotsInDate?.[slot.id]
    const isDiscountedTimeSlot = !!discountedTimeSlot && typeof discountedTimeSlot !== 'number'
    const isSelected = selectedSlot?.id === slot.id
    const isDynamicPricing = type === PricingTypes.Dynamic
    const timeOfChip = dateHelpers.combineDateAndTime(scheduledDate, slot.id)
    const isDatePast = dateHelpers.isDatePast(timeOfChip)
    const isDisabled = jobQuotationAtom?.isLoading || isDatePast

    const getPriceColor = (isSelected: boolean, isDiscounted: boolean) => {
        if (isSelected) {
            return hive.color('text/active/tertiary')
        }

        if (isDiscounted && isDynamicPricing) {
            return hive.color('text/success/primary')
        }

        return hive.color('text/active/secondary')
    }

    const renderPriceText = () => {
        const priceColor = getPriceColor(isSelected, isDiscountedTimeSlot)
        const price = (isDiscountedTimeSlot ? discountedTimeSlot.price : cleaningConfigsAtom?.oneTimePriceLabel) || ''

        return isDynamicPricing ? (
            <Text typography="caption/s" style={[styles.price, { color: priceColor }]}>
                {price}
            </Text>
        ) : <></>
    }

    const handlePress = () => {
        setSelectedSlot(slot)
        const bookingPriceSlot = isDiscountedTimeSlot ? discountedTimeSlot as PriceSlot : undefined
        onSelectBookingTime && onSelectBookingTime(bookingPriceSlot, slot)
    }

    const renderLabel = () => (
        <Text
            typography="body/xs"
            style={{
                color: isSelected
                    ? hive.color('text/active/tertiary')
                    : hive.color('text/active/primary')
            }}
        >
            {slot.label}
        </Text>
    )

    return (
        <Surface
            key={slot.id}
            style={{
                ...styles.chip,
                backgroundColor:
                    isDatePast ?
                        hive.color('fill/neutral/tertiary') :
                        isSelected ?
                            hive.color('fill/neutral/quaternary') :
                            hive.color('fill/neutral/secondary')
            }}
            onPress={() => isDisabled ? null : handlePress()}
        >
            {renderLabel()}
            {shouldShowDynamicPriceInfo && renderPriceText()}
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    chip: {
        borderWidth: 1,
        borderStyle: 'solid',
        alignItems: 'center',
        width: '100%',
        marginBottom: theme.utils.hive.spacing('x2'),
        borderRadius: (theme.utils.hive.borderRadius('medium') as number + theme.utils.hive.spacing('x1')) as number,
        padding: theme.utils.hive.spacing('x3'),
        borderColor: theme.utils.hive.color('border/active/primary') as string
    },
    price: {
        fontWeight: '300'
    }
}))
