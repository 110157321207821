import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Celebrate: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M9.27475 0.673879C9.54693 0.627274 9.80536 0.81014 9.85197 1.08232L9.85209 1.08307L9.85227 1.08408L9.85274 1.0869C9.86033 1.13346 9.86674 1.18022 9.87253 1.22704C9.88315 1.3129 9.8957 1.43347 9.90407 1.57626C9.92053 1.85735 9.92206 2.24472 9.85075 2.62913C9.76896 3.07004 9.56219 3.47322 9.39314 3.75C9.29223 3.91522 9.18218 4.07619 9.05921 4.22595L9.05696 4.22866L9.05565 4.23023C8.87824 4.44184 8.56274 4.46973 8.35113 4.29232C8.13974 4.11511 8.11185 3.80024 8.28861 3.58863L8.28907 3.58808C8.38144 3.47507 8.46374 3.35317 8.53973 3.22876C8.68119 2.99715 8.81782 2.71471 8.86753 2.44674C8.91831 2.17296 8.91984 1.87473 8.90578 1.63474C8.89832 1.50744 8.88624 1.38021 8.8668 1.25415L8.86631 1.25109C8.8198 0.978965 9.00262 0.720476 9.27475 0.673879Z" fill="#07A35D"/>
        <Path d="M12.791 1.72478C13.0507 1.81845 13.1854 2.10497 13.0917 2.36474L12.7827 3.22176C12.689 3.48153 12.4025 3.61617 12.1427 3.5225C11.8829 3.42882 11.7483 3.1423 11.842 2.88253L12.151 2.02551C12.2447 1.76574 12.5312 1.6311 12.791 1.72478Z" fill="#07A35D"/>
        <Path d="M14.614 4.71428C14.7373 4.96135 14.637 5.26162 14.3899 5.38495L13.7031 5.72775C13.4561 5.85108 13.1558 5.75076 13.0325 5.50369C12.9091 5.25662 13.0095 4.95635 13.2565 4.83302L13.9433 4.49021C14.1904 4.36689 14.4907 4.46721 14.614 4.71428Z" fill="#07A35D"/>
        <Path d="M11.43 4.58433C11.625 4.77976 11.6248 5.09634 11.4293 5.29144L10.7426 5.97705C10.5471 6.17214 10.2306 6.17187 10.0355 5.97645C9.84036 5.78102 9.84063 5.46443 10.0361 5.26934L10.7228 4.58373C10.9183 4.38863 11.2349 4.3889 11.43 4.58433Z" fill="#07A35D"/>
        <Path d="M4.02742 5.62361C4.51038 4.39278 6.09718 4.06111 7.03286 4.9952L11.0126 8.96811C11.9496 9.90357 11.6178 11.493 10.3848 11.9756L3.84114 14.5371C2.35017 15.1207 0.878414 13.6489 1.46341 12.158L4.02742 5.62361Z" fill="#07A35D"/>
        <Path d="M11.6067 7.31615C11.6184 7.04025 11.8515 6.82603 12.1273 6.83767L12.1288 6.83774L12.131 6.83784C12.2531 6.84381 12.3749 6.85681 12.496 6.87299C12.7069 6.90117 12.9956 6.95246 13.2886 7.04606C13.5626 7.13357 13.8518 7.27574 14.0597 7.38666C14.1847 7.45339 14.3086 7.52285 14.4292 7.59733L14.4298 7.59771C14.6645 7.74328 14.7371 8.05176 14.5915 8.28642C14.446 8.52105 14.1378 8.59328 13.9031 8.44777C13.8004 8.38467 13.6951 8.32561 13.5888 8.26886C13.3967 8.16631 13.1709 8.05823 12.9843 7.99863C12.7664 7.92902 12.54 7.88776 12.3636 7.86418C12.2717 7.8519 12.1793 7.84208 12.0868 7.83686L12.0851 7.83678C11.8092 7.82507 11.5951 7.59201 11.6067 7.31615Z" fill="#07A35D"/>
    </Icon>
)
