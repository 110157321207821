import { AnalyticsBrowser } from '@segment/analytics-next'
import { ENV } from 'lib/config'
import { Segment as TypeWriterSegment } from './typewriter'
import { DevMockedSegment } from './mocks'
import { IdentifyTraits } from './types'
import { getAnonymousID } from './utils'

const analytics = ENV.SEGMENT_WRITE_KEY
    ? AnalyticsBrowser.load({
        writeKey: ENV.SEGMENT_WRITE_KEY as string
    }, {
        cookie: {
            domain: ENV.SEGMENT_COOKIE_DOMAIN as string,
            sameSite: 'None',
            secure: true
        },
        user: {
            localStorageFallbackDisabled: true
        }
    })
    : DevMockedSegment

if (ENV.SEGMENT_WRITE_KEY) {
    TypeWriterSegment.setTypewriterOptions({
        // @ts-ignore
        analytics
    })
}

export const Segment = {
    identify: (userId: string, traits: IdentifyTraits) => analytics.identify(userId, traits),
    logout: () => analytics.reset(),
    trackPageView: screenName => analytics.page(screenName),
    getAnonymousID,
    ...TypeWriterSegment
}
