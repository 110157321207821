import React from 'react'
import { View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { useNativeUserAtom } from 'lib/native/atoms/user'
import { BellNotify } from 'features/native/home/components'
import { ENV } from 'lib/config'
import { linkingHelpers } from 'lib/utils'

export const WelcomeMessage: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [nativeUser] = useNativeUserAtom()

    if (!nativeUser.handle) {
        return null
    }

    const handleOpenOperationsEndingPage = () => {
        linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/operations-ending`)
    }

    return (
        <View style={styles.mainContainer}>
            <View style={styles.bannerContainer}>
                <Text typography="caption/xs" color="text/active/primary" onPress={handleOpenOperationsEndingPage}>
                    {`${T.components.welcomeMessage.operationsEndingMsg} `}
                    <Text underline typography="caption/xs" color="text/active/primary">
                        {T.components.welcomeMessage.readMore}
                    </Text>
                </Text>
            </View>
            <View style={styles.container}>
                <View
                    style={styles.message}
                    testID={T.accessibility.dashboard.greetingMessage}
                    accessible
                    accessibilityLabel={T.accessibility.dashboard.greetingMessage}
                >
                    <Text typography="label/l" color="text/active/primary">
                        {T.components.welcomeMessage.hello} {nativeUser.handle} 🌸
                    </Text>
                    <Text typography="caption/xs" color="text/active/primary">
                        {T.components.welcomeMessage.cny}
                    </Text>
                </View>
                <BellNotify />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    mainContainer: {
        paddingTop: theme.utils.hive.spacing('x8')
    },
    bannerContainer: {
        backgroundColor: theme.utils.hive.color('fill/active/primary') as string,
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        paddingVertical: theme.utils.hive.spacing('x2')
    },
    container: {
        marginTop: theme.utils.hive.spacing('x2'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        backgroundColor: theme.colors.transparent,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    message: {
        flex: 0.85
    }
}))
