import { useForm } from '@codegateinc/react-form-builder-v2'
import { bookingsHooks } from 'features/bookings'
import { contactUs } from 'features/contact/actions'
import { Segment, segmentUtils } from 'lib/analytics'
import { useCancelReasonAtom, useJobScheduleLogsAtom, useLocaleAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { Touchable, Typography } from 'lib/components'
import { useJobListeners, useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { JobStatus, JobType } from 'lib/types'
import { cancelReasonHelpers, emailTemplateHelper, getAppVersionForPlatform, jobHelpers } from 'lib/utils'
import React, { useState } from 'react'
import { Platform, View } from 'react-native'
import { cancelAirconJob, cancelJob, getAirConditionJobDetails, getJobDetails, rescheduleJob, useGetConsumerActiveJobs } from '../actions'
import { CancelationShape, RescheduleFields, RescheduleFormShape, useCancelationForm, useRescheduleBookingForm } from '../forms'
import { JobDetailsResponse } from '../types'
import { CancelBookingRequestModal } from './cancelBookingRequestModal'
import { CancelBookingSuccessModal } from './cancelBookingSuccessModal'
import { CancelRequestSentModal } from './cancelRequestSentModal'
import { RescheduleBookingModal } from './rescheduleBookingModal'
import { RescheduleConfirmationModal } from './rescheduleConfirmationModal'
import { RescheduledSuccessModal } from './rescheduledSuccessModal'
import { RescheduleRequestModal } from './rescheduleRequestModal'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'

type CancelRequestProps = {
    job: JobDetailsResponse,
    jobStatus?: JobStatus,
    text?: string,
    forceColor?: string,
    hideButton?: boolean
}

export const AirconCancelRequest: React.FunctionComponent<CancelRequestProps> =
    ({ job, jobStatus, text, forceColor, hideButton = false }) => {
        const T = useTranslations()
        const { styles, theme } = useStyles(stylesheet)
        const [token] = useUserTokenAtom()
        const [jobScheduleLogs, setJobScheduleLogs] = useJobScheduleLogsAtom()
        const [cancelationOptions] = useCancelReasonAtom()
        const [user] = useUserAtom()
        const [locale] = useLocaleAtom()
        const bookingDays = bookingsHooks.useBookingDays()
        const isAirconSubscriptionJob = isAirconSubscription(job.job)
        const getAirconJobDetailsSource = isAirconSubscriptionJob ? getAirConditionJobDetails : getJobDetails
        const { mutate: onJobCancel, isLoading: isMutating } = isAirconSubscriptionJob ? cancelAirconJob() : cancelJob()
        const { mutate: reschedule, isLoading: isRescheduleMutating } = rescheduleJob()
        const { mutate: sendContact, isLoading: isContactLoading } = contactUs()
        const { onRequestError } = useQueryHelpers()
        const { refetch, isRefetching } = getAirconJobDetailsSource(job.job._id)
        const {
            refetch: refetchParentJob,
            isRefetching: isRefetchingParentJob
        } = getAirconJobDetailsSource(job.job.recurring_job || job.job._id)
        const {
            refetch: refetchActiveJobs,
            isRefetching: isRefetchingActiveJobs,
            isLoading: isLoadingActiveJobs
        } = useGetConsumerActiveJobs()
        const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
        const [isRescheduleRequestModalOpen, setIsRescheduleRequestModalOpen] = useState<boolean>(false)
        const [isRescheduleBookingModalOpen, setIsRescheduleBookingModalOpen] = useState<boolean>(false)
        const [isCancelBookingSuccessModalOpen, setIsCancelBookingSuccessModalOpen] = useState<boolean>(false)
        const [isRescheduleSuccessModalOpen, setIsRescheduleSuccessModalOpen] = useState<boolean>(false)
        const [isRescheduleConfirmationModalOpen, setIsRescheduleConfirmationModalOpen] = useState<boolean>(false)
        const [isCancelRequestSentModal, setIsCancelRequestSentModal] = useState<boolean>(false)
        const [isTriggerByEventEmitter, setIsTriggerByEventEmitter] = useState<boolean>(false)

        const formReturn = useForm<CancelationShape>(useCancelationForm(), {
            onSuccess: ({ customReason, reason }) => {
                const cancelReason = cancelReasonHelpers.getCancelReason(reason, customReason)

                if (jobHelpers.shouldCancelJob(job)) {
                    onJobCancel(
                        {
                            id: job.job._id,
                            token,
                            job_type: job.job.recurring_job
                                ? JobType.Recurring
                                : undefined,
                            cancel_reason: cancelReason
                        },
                        {
                            onSuccess: () => {
                                setIsConfirmationModalOpen(false)
                                setIsCancelBookingSuccessModalOpen(true)
                                Segment.bookingCanceled({
                                    jobId: job.job.job_id,
                                    reason: (cancelReason?.reason ?? customReason) as string,
                                    serviceType: segmentUtils.supplyIdToServiceType(job.job.supply.id)
                                })
                            },
                            onError: error => {
                                onRequestError(error)
                            }
                        }
                    )

                    return
                }

                sendContact(
                    {
                        token,
                        profile: user,
                        contents: `
${emailTemplateHelper.getCancelRequestMessage(job, cancelReason, customReason)}
${Platform.OS} - ${getAppVersionForPlatform()} ${token}`
                    },
                    {
                        onSuccess: () => {
                            setIsConfirmationModalOpen(false)
                            setIsCancelRequestSentModal(true)
                        },
                        onError: onRequestError
                    }
                )
            }
        })

        const rescheduleForm = useForm<RescheduleFormShape>(useRescheduleBookingForm(job, bookingDays), {
            onSuccess: (form: RescheduleFormShape) => {
                const { bookingTime } = form

                if (jobHelpers.shouldRescheduleJob(job)) {
                    reschedule(
                        {
                            token,
                            job: job.job._id,
                            new_date: bookingTime
                        },
                        {
                            onSuccess: () => {
                                Segment.bookingRescheduled({
                                    jobId: job.job.job_id,
                                    serviceType: segmentUtils.supplyIdToServiceType(job.job.supply.id),
                                    dateTime: bookingTime,
                                    jobFrequency: job.job.frequency
                                })
                                setIsRescheduleRequestModalOpen(false)
                                setIsRescheduleBookingModalOpen(false)
                                setIsRescheduleSuccessModalOpen(true)
                                refetch()
                                refetchActiveJobs()
                            },
                            onError: onRequestError
                        }
                    )

                    return
                }

                setJobScheduleLogs({ [job.job._id]: {
                    job,
                    rescheduleForm: form,
                    locale
                }, ...jobScheduleLogs })

                const rescheduledBookingRequest = jobHelpers.getRescheduledBookingRequest(job, form)

                // Currently, we send a contact form and show the modal request send only. Don't call API reschedule job
                sendContact(
                    {
                        token,
                        profile: user,
                        contents: `
${emailTemplateHelper.getScheduleRequestMessage(job, form, locale)}
${Platform.OS} - ${getAppVersionForPlatform()} ${token}`,
                        ...(rescheduledBookingRequest || {})
                    },
                    {
                        onSuccess: () => {
                            setIsRescheduleBookingModalOpen(false)
                            setIsRescheduleConfirmationModalOpen(true)

                            if (job.job.recurring_job) {
                                refetchParentJob()
                            }

                            refetch()
                        },
                        onError: onRequestError
                    }
                )

            }
        })

        const resetFormData = (jobDetail: JobDetailsResponse | null = null) => {
            resetForm()
            resetRescheduleForm()
            reInitRescheduleFormValue(jobDetail)
        }

        useJobListeners(job, resetFormData, setIsRescheduleBookingModalOpen, () => {}, setIsTriggerByEventEmitter)

        const { submit, resetForm } = formReturn
        const { submit: onReschedule, resetForm: resetRescheduleForm } = rescheduleForm

        const isLoading =
            isMutating ||
            isRescheduleMutating ||
            isRefetching ||
            isRefetchingActiveJobs ||
            isLoadingActiveJobs ||
            isRefetchingParentJob

        const reInitRescheduleFormValue = (jobDetail: JobDetailsResponse | null = null) => {
            const initialValue = jobHelpers.getRescheduleFormInitialValue(jobDetail || job, bookingDays)
            const bookingTimeInitialValue = initialValue.bookingTime
            const startingDateInitialValue = initialValue.startingDate
            const frequencyInitialValue = initialValue.frequency
            const bookingDaysInitialValue = initialValue.bookingDays

            rescheduleForm.form.startingDate.onChangeValue(startingDateInitialValue)
            rescheduleForm.form.bookingTime.onChangeValue(bookingTimeInitialValue)
            rescheduleForm.form.frequency.onChangeValue(frequencyInitialValue)
            rescheduleForm.form.bookingDays.onChangeValue(bookingDaysInitialValue)
            // Need to run setFieldInitialValue after trigger event onChangeValue, to ensure that not detect the form has changed
            rescheduleForm.setFieldInitialValue(RescheduleFields.StartingDate, startingDateInitialValue)
            rescheduleForm.setFieldInitialValue(RescheduleFields.BookingTime, bookingTimeInitialValue)
            rescheduleForm.setFieldInitialValue(RescheduleFields.Frequency, frequencyInitialValue)
            rescheduleForm.setFieldInitialValue(RescheduleFields.BookingDays, bookingDaysInitialValue)
        }

        return (
            <View style={styles.wrapper}>
                <RescheduleRequestModal
                    job={job}
                    isOpen={isRescheduleRequestModalOpen}
                    onClose={() => setIsRescheduleRequestModalOpen(false)}
                    onProceedToCancel={() => {
                        setIsRescheduleRequestModalOpen(false)
                        setIsConfirmationModalOpen(true)
                    }}
                    onRescheduleBooking={() => {
                        setIsRescheduleRequestModalOpen(false)
                        setIsRescheduleBookingModalOpen(true)
                        Segment.rescheduleRequestClicked({})
                    }}
                    onPausePackage={() => {
                        setIsRescheduleRequestModalOpen(false)
                    }}
                    onProceedToCancelPackage={() => {
                        setIsRescheduleRequestModalOpen(false)
                    }}
                />
                <RescheduleBookingModal
                    isOpen={isRescheduleBookingModalOpen}
                    job={job}
                    formReturn={rescheduleForm}
                    isLoading={isLoading}
                    onReschedule={onReschedule}
                    onClose={() => setIsRescheduleBookingModalOpen(false)}
                    onBack={() => {
                        setIsRescheduleBookingModalOpen(false)

                        if (isTriggerByEventEmitter) {
                            setIsTriggerByEventEmitter(false)

                            return
                        }

                        setIsRescheduleRequestModalOpen(true)
                    }}
                />
                <CancelBookingRequestModal
                    job={job}
                    onSubmit={submit}
                    formReturn={formReturn}
                    isLoading={isLoading}
                    isOpen={isConfirmationModalOpen}
                    cancelationOptions={cancelationOptions}
                    onClose={() => setIsConfirmationModalOpen(false)}
                    onRescheduleBooking={() => {
                        setIsConfirmationModalOpen(false)
                        setIsRescheduleBookingModalOpen(true)
                    }}
                    cancelationMessage={job.job.popup.cancellation.message}
                />
                <RescheduleConfirmationModal
                    job={job}
                    isOpen={isRescheduleConfirmationModalOpen}
                    formReturn={rescheduleForm}
                    isLoading={isContactLoading}
                    onClose={() => setIsRescheduleConfirmationModalOpen(false)}/>
                <CancelRequestSentModal
                    isOpen={isCancelRequestSentModal}
                    isLoading={isContactLoading}
                    onClose={() => setIsCancelRequestSentModal(false)}/>
                <CancelBookingSuccessModal
                    job={job}
                    isOpen={isCancelBookingSuccessModalOpen}
                    onClose={() => {
                        setIsCancelBookingSuccessModalOpen(false)
                        refetchActiveJobs()
                            .finally(() => {
                                if (job.job.recurring_job) {
                                    refetchParentJob()
                                }

                                refetch()
                            })
                    }}
                    cancelationMessage={job.job.popup.cancellation.message}
                />
                <RescheduledSuccessModal
                    job={job}
                    formReturn={rescheduleForm}
                    isOpen={isRescheduleSuccessModalOpen}
                    onClose={() => setIsRescheduleSuccessModalOpen(false)}
                />
                {!hideButton && (
                    <Touchable
                        testID={T.accessibility.jobDetailsScreen.cancelRequest.cancel}
                        disabled={jobStatus === JobStatus.Cancelled}
                        style={
                            jobStatus === JobStatus.Cancelled
                                ? undefined
                                : {
                                    ...styles.button,
                                    borderBottomColor: forceColor ? forceColor : theme.colors.orange
                                }
                        }
                        onPress={() => {
                            Segment.cancelBookingClicked({
                                jobId: job.job.job_id,
                                serviceType: segmentUtils.supplyIdToServiceType(job.job.supply.id)
                            })
                            resetFormData()
                            setIsTriggerByEventEmitter(false)
                            setIsRescheduleRequestModalOpen(true)
                        }}
                    >
                        <Typography.Regular
                            bold
                            forceColor={
                                forceColor ? forceColor :
                                    jobStatus === JobStatus.Cancelled
                                        ? theme.colors.strawberry
                                        : theme.colors.orange
                            }
                        >
                            {jobStatus === JobStatus.Cancelled
                                ? T.screens.jobDetails.cancelled
                                : text || T.screens.jobDetails.cancel
                            }
                        </Typography.Regular>
                    </Touchable>
                )}

            </View>
        )
    }

const stylesheet = createStyles(theme => ({
    wrapper: {
        alignItems: 'center',
        marginTop: theme.utils.gap(4),
        marginBottom: theme.utils.gap(1)
    },
    button: {
        borderBottomWidth: 1,
        borderColor: theme.colors.orange
    }
}))
