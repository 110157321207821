import React, { useState } from 'react'
import { View } from 'react-native'
import { useSharedValue } from 'react-native-reanimated'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { FadeTabButton, FadeTabContent, Typography } from 'lib/components'
import { moversAndPackersHooks } from '../hooks'

type MoversAndPackersAddOnPricingContentProps = {
    tabIndex: number
}

export const MoversAndPackersAddOnPricingContent: React.FunctionComponent<MoversAndPackersAddOnPricingContentProps> = ({
    tabIndex
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const activeIndex = useSharedValue(tabIndex)
    const [selectedTab, setSelectedTab] = useState(tabIndex)
    const pricing = moversAndPackersHooks.useMoversAndPackersAddOnPricing()

    return (
        <View style={styles.container}>
            <Typography.SmallSubheading style={styles.title}>
                {T.components.moversAndPackersAddOnPricing.modalTitle}
            </Typography.SmallSubheading>
            <View style={styles.tabs}>
                {pricing.map((option, index) => (
                    <FadeTabButton
                        key={index}
                        text={option.tabLabel}
                        index={index}
                        activeIndex={activeIndex}
                        onPress={() => {
                            setSelectedTab(index)
                            activeIndex.value = index
                        }}
                    >
                        <Typography.Regular
                            style={styles.alignText}
                            forceColor={index === selectedTab
                                ? theme.colors.orange
                                : theme.colors.night
                            }
                        >
                            {option.tabLabel}
                        </Typography.Regular>
                    </FadeTabButton>
                ))}
            </View>
            <View>
                {pricing.map((option, index) => (
                    <FadeTabContent
                        key={index}
                        selectedTab={selectedTab}
                        activeIndex={activeIndex}
                        index={index}
                    >
                        <View style={styles.tabContainer}>
                            {option.renderContent()}
                        </View>
                    </FadeTabContent>
                ))}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1
    },
    title: {
        marginRight: {
            lg: undefined,
            xs: theme.utils.gap(4)
        }
    },
    container: {
        width: {
            xl: 1100,
            xs: '100%'
        },
        padding: {
            lg: theme.utils.gap(3 / 2),
            xs: 0
        }
    },
    tabs: {
        flex: 1,
        gap: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        },
        marginVertical: theme.utils.gap(2),
        flexDirection: 'row'
    },
    alignText: {
        textAlign: 'center'
    },
    tabContainer: {
        paddingHorizontal: {
            xl: theme.utils.gap(12),
            xs: 0
        }
    },
    tabContent: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'center',
            xs: undefined
        }
    },
    lorryImage: {
        width: {
            lg: 320,
            xs: undefined
        },
        height: {
            lg: 250,
            xs: 220
        }
    },
    table: {
        flex: 1,
        marginVertical: theme.utils.gap(2),
        marginHorizontal: {
            lg: theme.utils.gap(4),
            xs: 0
        }
    },
    tableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(1),
        borderBottomColor: theme.colors.silver
    }
}))
