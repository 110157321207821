import React, { useEffect, useRef, useState } from 'react'
import { DimensionValue, View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { useNavigation } from '@react-navigation/native'
import {
    AirConditionService,
    BookingFrequency, SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { bookingHelpers } from 'lib/utils'
import { Breakpoint, createStyles } from 'lib/styles'
import { MOCKED_NUMBER_OF_AIR_CONDITIONERS } from 'lib/data'
import { useInitializeAddress, useIsWithinBreakpoints, useSegmentSession, useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Segment, ServiceType } from 'lib/analytics'
import { Adapter, Address, BasicModal, Button, FormComponents, MediaQuery, Sticky, Touchable, Typography } from 'lib/components'
import {
    BookingCancellationFees,
    AirConditionHelpModalContent,
    AirConditionSummaryColumn,
    MobileSummary,
    PriceComponent,
    Rated,
    ServiceDescription,
    ServiceLayout,
    TotalPriceComponent,
    PriceSummaryComponent,
    BookingDateCalendarPickerLite,
    AirConditionServiceSchedule,
    BookingDateWeeklyService
} from '../components'
import { AirConditionFormShape, useAirConditionForm } from '../forms'
import { useAirConditionBreadCrumbs, useFrequencyOptions, useJobQuotation, useResetFormCallback } from '../hooks'
import { getServiceStaticConfig } from '../utils'
import { ContractTypeOption, ServicePlanOption } from '../types'
import { Icons } from 'assets'
import { generatedDatesEvery3Months } from 'lib/utils/date'

type AirConditionScreenProps = {
    route: NavigationParams<ScreenNames.AirCondition>
    navigation: NavigationProps<ScreenNames.AirCondition>
}

const MIN_NO_OF_AC_FOR_CONTRACT = 2

export const AirConditionScreen: React.FunctionComponent<AirConditionScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const config = getServiceStaticConfig(SupplyID.ACService)
    const { pricing: { servicePlan : { options: defaultAirConditionServiceOptions } } } = config
    const airConditionServiceOptions = defaultAirConditionServiceOptions.filter(option => option.frequency !== AirConditionService.Contract)
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)
    const scrollRef = useRef<KeyboardAwareScrollView>(null)
    const [scheduledDates, setScheduledDates] = useState<Array<Date>>([])
    const [isContractModalOpen, setIsContractModalOpen] = useState(false)
    const frequencyOptions = useFrequencyOptions()
    const breadcrumbs = useAirConditionBreadCrumbs()
    const { segmentSession } = useSegmentSession()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.ACService)
    const [totalPrice, setTotalPrice] = useState(0)
    const [selectedOption, setSelectedOption] = useState<ServicePlanOption>(airConditionServiceOptions.find(option => option.defaultSelected === true))
    const [selectedContractType, setSelectedContractType] = useState<ContractTypeOption>()
    const [sessionsGenerated, setSessionsGenerated] = useState<number>(0)

    const { form, isFilled, hasError, submit, resetForm } = useForm<AirConditionFormShape>(useAirConditionForm(), {
        onSuccess: form => {
            const shiftedDays = bookingHelpers.shiftBookingDays(form.bookingDays, config.min_notice_hours, form.bookingDate)
            navigation.navigate(ScreenNames.AirConditionSummary, {
                form: {
                    ...form,
                    bookingDays: shiftedDays
                },
                price: totalPrice,
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.ACService,
                selectedContract: selectedContractType?.contractType,
                sessionsGenerated
            })
        }
    })
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(100% - 10px)' as DimensionValue
    const continueDisabled = !form.bookingTime.value || !isFilled || hasError
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as AirConditionFormShape)

    useEffect(() => {
        if(!airConditionServiceOptions.length) {
            return
        }

        const defaultOption = airConditionServiceOptions.find(option => option.defaultSelected === true)
        setSelectedOption(defaultOption)
        form.serviceOption.onChangeValue(defaultOption)

        if (defaultOption?.frequency === AirConditionService.Contract) {
            const defaultContractType = defaultOption.contractTypes?.find(option => option.defaultSelected === true)
            setSelectedContractType(defaultContractType)
        }
    }, [])

    useEffect(() => {
        const option = airConditionServiceOptions.find(option => option.defaultSelected === true)
        setSelectedOption(option)
        form.serviceOption.onChangeValue(option)
    }, [route])

    useEffect(() => {
        if (form?.serviceOption?.value?.frequency !== AirConditionService.Contract) {
            setSessionsGenerated(1)

            return
        }

        setSessionsGenerated(selectedContractType?.numberOfSessions || 0)

    }, [form.serviceOption.value, selectedContractType])

    useResetFormCallback(resetForm)

    useInitializeAddress(true)

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                serviceType: ServiceType.AirCondition
            })
        }
    }, [segmentSession])

    useEffect(() => {
        if(!form.numberOfAirConditioners.value || !form.serviceOption.value) {
            return
        }

        const { frequency } = form.serviceOption.value || {}

        requestJobQuotation({
            frequency,
            numberOfUnits: form.numberOfAirConditioners.value,
            contractType: frequency === AirConditionService.Contract ? selectedContractType?.contractType : frequency,
            date: form.bookingTime.value
        })

    }, [form.numberOfAirConditioners.value, selectedOption?.title, selectedContractType])

    useEffect(() => {
        if (jobQuotation && jobQuotation.estimation) {
            const { value } = jobQuotation.estimation
            setTotalPrice(value)
        }
    }, [jobQuotation])

    const getFrequencyForDateModal = () => frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || frequencyOptions[0]

    const getNoOfACRange = () => {
        if(form?.serviceOption?.value?.frequency === AirConditionService.Contract){
            return MOCKED_NUMBER_OF_AIR_CONDITIONERS.filter(option => Number(option.value) >= MIN_NO_OF_AC_FOR_CONTRACT)
        }

        return MOCKED_NUMBER_OF_AIR_CONDITIONERS
    }

    const getNoOfACValue = () => form.numberOfAirConditioners.value

    const onNoOfACChange = (value: string) => {
        form.numberOfAirConditioners.onChangeValue(value)

        Segment.bookingNumberOfAirConditionersSelected({
            quantity: Number(value)
        })
    }

    const onSelectPlan = (option: ServicePlanOption) => {
        setSelectedOption(option)
        form.serviceOption.onChangeValue(option)
    }

    useEffect(() => {
        if (form.bookingTime.value) {
            const numberOfSessions = selectedContractType?.numberOfSessions || 0
            const scheduleDates = generatedDatesEvery3Months(form.bookingTime.value, numberOfSessions / 4)
            setScheduledDates(scheduleDates)
        }
    }, [form.bookingTime.value, selectedContractType])

    const onOpenContractModal = () => {
        setIsContractModalOpen(true)
    }

    const { pricing: { servicePlan } } = config

    return (
        <React.Fragment>
            <ServiceLayout
                ref={scrollRef}
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.airCondition.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? 0
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.airCondition.title}
                                    modalContent={(
                                        <AirConditionHelpModalContent />
                                    )}
                                    description={T.screens.airCondition.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BasicModal
                            isOpen={isContractModalOpen}
                            onClose={() => setIsContractModalOpen(false)}
                            scrollViewContainerStyles={styles.contractModal}
                        >
                            <View style={{ padding: theme.utils.gap(2) }}>
                                <Typography.Regular
                                    style={styles.description}
                                >
                                    {servicePlan.title}
                                </Typography.Regular>
                                <View style={styles.contractOptionWrapper}>
                                    {selectedOption?.contractTypes?.map((contractType, index) => (
                                        <Touchable
                                            key={index}
                                            onPress={() => {
                                                setSelectedContractType(contractType)
                                                setIsContractModalOpen(false)
                                            }}
                                            style={{
                                                ...styles.contractOption,
                                                backgroundColor: selectedContractType?.plan === contractType.plan ? theme.colors.yellow : theme.colors.white,
                                                borderColor: selectedContractType?.plan === contractType.plan ? theme.colors.orange : theme.colors.grey
                                            }}
                                        >
                                            <Typography.Regular bold style={{
                                                fontSize: 14,
                                                color: selectedContractType?.plan === contractType.plan ? theme.colors.orange : theme.colors.black
                                            }}>
                                                {contractType.plan}
                                            </Typography.Regular>
                                            <Typography.Regular style={{
                                                fontSize: 13,
                                                color: selectedContractType?.plan === contractType.plan ? theme.colors.orange : theme.colors.grey
                                            }}>
                                                {contractType.numberOfSessions} {T.servicesPlan.sessions}
                                            </Typography.Regular>
                                        </Touchable>
                                    ))}

                                </View>

                            </View>

                        </BasicModal>
                        <FormComponents.FormRow>
                            <Adapter.AirConditionServiceOptions
                                {...form.serviceOption}
                                scrollRef={scrollRef}
                                options={airConditionServiceOptions}
                                onSelectPlan={onSelectPlan}
                                selectedOption={selectedOption}
                                selectedContractType={selectedContractType}
                                onOpenContractModal={onOpenContractModal}
                            />
                        </FormComponents.FormRow>
                        <FormComponents.FormRow>
                            <Adapter.OptionsRadio
                                {...form.numberOfAirConditioners}
                                options={getNoOfACRange()}
                                value={getNoOfACValue()}
                                onChangeValue={onNoOfACChange}
                            />
                        </FormComponents.FormRow>
                        <FormComponents.FormRow style={{ marginBottom: 0 }}>
                            <View
                                style={{
                                    width: columnWidth
                                }}
                            >
                                <Typography.Regular bold>
                                    {selectedOption?.frequency === AirConditionService.Contract ? T.screens.airCondition.acContractMaintenanceScheduleTitle : T.screens.airCondition.acOnetimeMaintenanceScheduleTitle }
                                </Typography.Regular>
                                <View style={styles.dateTimeWrapper}>
                                    <View style={styles.dateWrapper}>
                                        <BookingDateCalendarPickerLite
                                            displayDate={form.bookingDate.value || T.screens.deepCleaning.formFields.startingDate.placeholder}
                                            minHoursNotice={config.min_notice_hours}
                                            disabled={false}
                                            description={T.screens.airCondition.selectDate}
                                            continueDisabled={false}
                                            frequency={getFrequencyForDateModal()}
                                            continueLabel={'string'}
                                            selectedDays={form.bookingDays.value}
                                            startingDateField={form.bookingDate}
                                            onContinue={() => {}}
                                            onChangeSelectedDays={() => {}}
                                        />
                                    </View>
                                    <View
                                        style={{
                                            ...styles.timeWrapper
                                        }}
                                    >
                                        <BookingDateWeeklyService
                                            liteMode
                                            leftIcon={() =>  (
                                                <Icons.Clock
                                                    viewBox="0 0 25 25"
                                                    size={20}
                                                    forceColor={continueDisabled ? theme.colors.fog : theme.colors.black}
                                                />
                                            )}
                                            timeTitle=""
                                            isOneTime
                                            onContinue={submit}
                                            startingDate={form.bookingDate.value}
                                            translationKey={form.frequency.value || BookingFrequency.OneTime}
                                            minHoursNotice={config.min_notice_hours}
                                            bookingDaysField={form.bookingDays}
                                            continueDisabled={continueDisabled}
                                            bookingTimeField={form.bookingTime}
                                            onChangeSelectedDate={form.bookingDate.onChangeValue}
                                            selectedDates={form.bookingDays.value}
                                            hideContinueButton
                                            disabled={continueDisabled}
                                        />
                                    </View>
                                </View>
                            </View>
                        </FormComponents.FormRow>
                        <Typography.Label style={styles.bookingDisclaimer} forceColor={theme.colors.fog}>
                            <View style={styles.bookingDisclaimerIcon}>
                                <Icons.Info forceColor={theme.colors.fog} size={12}/>
                            </View>
                            {T.screens.airCondition.bookingDisclaimer}
                        </Typography.Label>
                        {form.bookingTime.value && selectedOption?.frequency === AirConditionService.Contract && <AirConditionServiceSchedule scheduledDates={scheduledDates} />}
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.airCondition.title}
                            modalContent={(
                                <AirConditionHelpModalContent />
                            )}
                            description={T.screens.airCondition.serviceDescription}
                        />
                        <Sticky>
                            <Address />
                            <AirConditionSummaryColumn
                                priceMessage={T.screens.airCondition.estimatedMessage}
                                renderPriceComponent={() => (
                                    <TotalPriceComponent
                                        totalPrice={jobQuotation?.estimation?.value || 0}
                                        customText={T.common.estimatedPrice}
                                    />
                                )}
                                renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                    <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                ) : undefined}
                                form={formSummaryValue}
                                jobQuotation={jobQuotation}
                                jobStartingTime={form.bookingTime.value}
                                sessionsGenerated={sessionsGenerated}
                            />
                            <Button
                                onPress={submit}
                                disabled={continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={totalPrice}
                            disabled={continueDisabled}
                            isLoading={false}
                            content={(
                                <React.Fragment>
                                    <Address />
                                    <AirConditionSummaryColumn
                                        priceMessage={T.screens.airCondition.estimatedMessage}
                                        renderPriceComponent={() => (
                                            <PriceComponent
                                                price={totalPrice}
                                                text={T.common.estimatedPrice}
                                            />
                                        )}
                                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                            <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                        ) : undefined}
                                        form={formSummaryValue}
                                        jobQuotation={jobQuotation}
                                        jobStartingTime={form.bookingTime.value}
                                        sessionsGenerated={sessionsGenerated}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        marginTop: theme.utils.gap(1),
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    zIndex: {
        // zIndex: theme.zIndex[10]
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    selectWrapper: {
        // zIndex: 'unset'
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    },
    resetMargin: {
        marginTop: 0
    },
    dateTimeWrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        justifyContent: 'center',
        gap: 10
    },
    dateWrapper: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
        marginTop: 10
        // display: '',
    },
    timeWrapper: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
        paddingTop: 10,
        zIndex: -1
    },
    description: {
        marginBottom: theme.utils.gap(2)
    },
    contractOptionWrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'row'
        },
        gap: theme.utils.gap(1),
        alignItems: 'center',
        textAlign: 'center'
    },
    contractModal: {
        padding: theme.utils.gap(2)
    },
    contractOption : {
        flexGrow: 1,
        padding: 10,
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 10
    },
    bookingDisclaimer: {
        marginBottom: theme.utils.gap(4),
        marginTop: theme.utils.gap(5),
        zIndex: -1
    },
    bookingDisclaimerIcon: {
        marginRight: theme.utils.gap(0.5)
    }
}))
