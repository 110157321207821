import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { ListBottomSpacer, SectionAccordion, SectionBox, Typography } from 'lib/components'
import { Icons } from 'assets'

export const BookingSuccessFaq: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    const accordionCustomIcon = () => (
        <Icons.PlusCircle
            size={20}
            forceColor={theme.colors.orange}
        />
    )

    return (
        <SectionBox>
            <View style={styles.container}>
                <Typography.SmallSubheading forceColor={theme.colors.orange}>
                    {T.screens.bookingSuccess.faqTitle}
                </Typography.SmallSubheading>
                <SectionAccordion
                    renderCustomIcon={accordionCustomIcon}
                    containerStyles={styles.accordionContainer}
                    title={T.screens.bookingSuccess.bookingAcceptedFaq}
                >
                    <Typography.Regular>
                        {/* TODO Need content */}
                        {T.components.cleaningHelpModal.howDoesItWorkMessage}
                    </Typography.Regular>
                    <ListBottomSpacer />
                </SectionAccordion>
                <SectionAccordion
                    renderCustomIcon={accordionCustomIcon}
                    containerStyles={styles.accordionContainer}
                    title={T.screens.bookingSuccess.whatToProvideFaq}
                >
                    <Typography.Regular>
                        {/* TODO Need content */}
                        {T.components.cleaningHelpModal.howDoesItWorkMessage}
                    </Typography.Regular>
                    <ListBottomSpacer />
                </SectionAccordion>
            </View>
        </SectionBox>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingVertical: theme.utils.gap(3),
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: 0
        }
    },
    accordionContainer: {
        marginHorizontal: 0,
        paddingHorizontal: 0,
        borderRadius: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 1
    }
}))
