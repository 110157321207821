import React from 'react'
import { ActivityIndicator, DimensionValue, TextStyle, View, ViewStyle } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable } from './Touchable'
import { ButtonText } from './typography'
import { Children, VoidFunction } from '../types'

type ButtonProps = {
    disabled?: boolean,
    text: string,
    onPress: VoidFunction,
    onHoverIn?: VoidFunction,
    onHoverOut?: VoidFunction,
    white?: boolean,
    noBackground?: boolean,
    width?: DimensionValue,
    height?: number,
    isLoading?: boolean,
    textStyles?: TextStyle,
    textWrapperStyles?: TextStyle,
    renderIcon?: (color: string) => Children
    testID?: string,
    buttonStyles?: ViewStyle
}

export const Button: React.FunctionComponent<ButtonProps> = ({
    text,
    disabled,
    onPress,
    onHoverIn,
    onHoverOut,
    white,
    noBackground,
    width = '100%',
    height,
    isLoading = false,
    renderIcon,
    testID,
    textStyles = {},
    textWrapperStyles = {},
    buttonStyles = {}
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const backgroundColor = white
        ? theme.colors.white
        : disabled
            ? theme.components.button.disabled.backgroundColor
            : theme.components.button.backgroundColor
    const textColor = white
        ? theme.colors.orange
        : disabled
            ? theme.components.button.disabled.text
            : theme.components.button.typography.text
    const loaderColor = noBackground
        ? theme.colors.sun
        : theme.colors.white
    const border = noBackground
        ? {
            borderWidth: 2,
            borderColor: theme.components.button.backgroundColor
        }
        : undefined
    const icon = renderIcon
        ? renderIcon(textColor)
        : null

    return (
        <Touchable
            onHoverIn={onHoverIn}
            onHoverOut={onHoverOut}
            onPress={onPress}
            disabled={disabled || isLoading}
            style={{
                ...styles.button,
                ...border,
                width,
                height: height ?? theme.components.button.height,
                backgroundColor: noBackground
                    ? undefined
                    : backgroundColor,
                ...buttonStyles
            }}
            testID={testID}
        >
            {isLoading ? (
                <ActivityIndicator
                    size="small"
                    color={disabled
                        ? theme.colors.night
                        : loaderColor
                    }
                />
            ) : (
                <View style={styles.textContainer}>
                    <View style={textWrapperStyles}>
                        <ButtonText
                            style={textStyles}
                            forceColor={noBackground
                                ? theme.colors.orange
                                : textColor
                            }
                        >
                            {text}
                        </ButtonText>
                    </View>
                    <View style={styles.icon}>
                        {icon}
                    </View>
                </View>
            )}
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    button: {
        padding: theme.components.button.padding,
        borderRadius: theme.components.button.borderRadius,
        justifyContent: 'center',
        alignItems: 'center'
    },
    textContainer: {
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        right: theme.utils.gap(1),
        position: 'absolute'
    }
}))
