export enum MMKVKey {
    AccessToken = 'accessToken',
    RefreshToken = 'refreshToken',
    Locale = 'locale',
    IsAuthorized = 'isAuthorized',
    AuthTimer = 'authTimer',
    User = 'user',
    UserToken = 'userToken',
    ViewOption = 'viewOption',
    CreditsPackage = 'creditsPackage',
    Address = 'address',
    CancelReason = 'cancelReason',
    Notification = 'notification',
    FirstLaunch = 'firstLaunch',
    PushNotification = 'pushNotification',
    FirstSignUp = 'firstSignUp',
    SegmentSession = 'segmentSession',
    Promotion = 'promotion',
    PromotionCodeError = 'promotionCodeError',
    CrossSaleBookingRecommendations = 'crossSaleBookingRecommendations',
    SubscriptionSpecialRecommendation = 'subscriptionSpecialRecommendation',
    SubscriptionRecommendationList = 'subscriptionRecommendationList',
    AutoSelectFrequencyJobType = 'autoSelectFrequencyJobType',
    AutoSelectOneTimeJobType = 'autoSelectOneTimeJobType',
    ServicesConfig = 'servicesConfig',
    MPDropoffLocation = 'mpDropoffLocation',
    MPPickupLocation = 'mpPickupLocation',
    NewPasswordToken = 'newPasswordToken',
    SocialSignInToken = 'socialSignInToken',
    BookingJobQuotation = 'bookingJobQuotation',
    FeatureFlag = 'featureFlag',
    JobScheduleLogs = 'jobScheduleLogs',
    PromotionPopup = 'promotionPopup',
    PromotionPopupShownAt = 'promotionPopupShownAt',
    ShouldClearPromotionPopupShownAt = 'shouldClearPromotionPopupShownAt',
    ForgotPassword = 'forgotPassword',
    ForgotPasswordResend = 'forgotPasswordResend',
    VerifyIdentity = 'verifyIdentity',
    ResetPassword = 'resetPassword',
    NewAppEnabled = 'newAppEnabled',
    DynamicPriceSlots = 'dynamicPriceSlots',
    CleaningConfigs = 'cleaningConfigs',
    JobQuotations = 'jobQuotations',
    JobBookingDetail = 'jobBookingDetail',
    AvailableServices = 'availableServices',
    DynamicPrice = 'dynamicPrice',
    DynamicPriceSelected = 'dynamicPriceSelected',
    DynamicPriceError = 'dynamicPriceError',
    WaAddPayment = 'waAddPayment',
    Campaign = 'campaign'
}
