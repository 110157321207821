import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type Props = {
    children: ReactNode
}

export const TableHeaderItem: React.FunctionComponent<Props> = ({ children }) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                paddingVertical: hive.spacing('x3'),
                paddingLeft: hive.spacing('x4')
            }}
        >
            {children}
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flex: 1,
        flexDirection: 'row'
    }
}))
