// This is just a dummy file to check bottom navigation. Will be udated later.
import { useMemo } from 'react'
import { TabNavigationState } from '@react-navigation/routers'
import { Clipboard2CopyListFill, Clipboard2CopyListOutline, PeopleCircleUserPersonAvatar2Fill, PeopleCircleUserPersonAvatar2Outline, ShHomeSparkleFill, ShHomeSparkleOutline } from '@propertyguru/hive-icons'
import { useTranslations } from 'lib/hooks'
import { KeyValuePair } from 'lib/types'
import { useUnreadNotificationCounterAtom } from 'lib/atoms'
import { ScreenNamesNative } from '../routing/screens'

export const useBottomBarRoutes = (state: TabNavigationState<KeyValuePair>) => {
    const T = useTranslations()
    const [unreadNotificationCounter] = useUnreadNotificationCounterAtom()
    const routes = useMemo(() => {
        const [
            dashboardTab,
            ordersTab,
            // referTab,
            // notificationsTab
            profileTab
        ] = state.routes

        return [
            {
                name: dashboardTab?.name,
                key: dashboardTab?.key,
                text: T.common.sendHelper,
                icon: ShHomeSparkleOutline,
                iconActive: ShHomeSparkleFill,
                screenName: ScreenNamesNative.DashboardNative
            },
            {
                name: ordersTab?.name,
                key: ordersTab?.key,
                text: T.components.bottomBarTabs.order,
                icon: Clipboard2CopyListOutline,
                iconActive: Clipboard2CopyListFill,
                screenName: ScreenNamesNative.OrdersNative
                // hasDot: hasUnreadChatMessages || hasProposals || hasBillings
            },
            {
                name: profileTab?.name,
                key: profileTab?.key,
                text: T.components.bottomBarTabs.you,
                icon: PeopleCircleUserPersonAvatar2Outline,
                iconActive: PeopleCircleUserPersonAvatar2Fill,
                screenName: ScreenNamesNative.UserNative
            }
            // {
            //     name: notificationsTab?.name,
            //     key: notificationsTab?.key,
            //     text: T.components.bottomBarTabs.notification,
            //     icon: Icons.Bell,
            //     screenName: ScreenNamesNative.Cleaning
            //     // hasDot: unreadNotificationCounter > 0,
            //     // onPress: () => navigation.navigate(ScreenNames.Notifications)
            // }
        ]
    }, [T, state.routes, unreadNotificationCounter])

    return routes
}
