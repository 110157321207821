export const colors = {
    white: '#FFFFFF',
    fog: '#CCCCCC',
    marble: '#F9FAFB',
    silver: '#F2F2F2',
    shadow: '#E5E7EB',
    shadowSoft: '#282E2E',
    mouse: '#B7B7B7',
    night: '#0E2638',
    tar: '#1C1B1F',
    black: '#000000',
    strawberry: '#FF661F',
    softBlueGrey: '#6E7D88',
    darkSilver: '#D9D9D9',
    lightSilver: '#EEE0D1',
    darkGrey: '#505050',
    sky: '#EAF1F5',
    sun: '#F9B130',
    sunLight: '#F2A926',
    orange: '#DD8510',
    paleTaupe: '#E5C498',
    flame: '#E03C31',
    yellow: '#FFF6D2',
    green: '#07A35D',
    successSecondary: '#E5FFF4',
    lightGreen: '#D4FFD4',
    linen: '#FFF0E9',
    latte: '#FFF8E7',
    navajo: '#FFE79F',
    mint: '#E7FFF4',
    purple: '#845D9C',
    lightPurple: '#F0EAF4',
    magenta: '#D594EE',
    lightPink: '#f0e9f4',
    peach: '#FFF3CE',
    lightCream: '#fcf8ea',
    grey: '#999999',
    darkSlate: '#2C2C2C',
    webShadow: (opacity: number) => `rgba(0, 0, 0, ${opacity})`,
    transparent: 'transparent',
    lightApricot: '#FFE79F',
    apricot: '#FFD1BC',
    peachPuff: '#FFE9B6',
    oldLace: '#FCF8EA',
    // move this to utils file and rename it to `colorWithOpacity`
    withOpacity: (color: string, opacity: number) => color + Math.floor(opacity * 255).toString(16).toUpperCase(),
    goldenrod: '#F2A927',
    sepiaBrown: '#7F5404',
    charcoal: '#272C2D',
    darkCerulean: '#103657',
    charcoalSlate: '#3E4246',
    crimsonRed: '#C20000',
    midnightShadow: '#0E0F11',
    statusGreen: '#008549',
    statusGrey: '#A7B0B8',
    pearlBush: '#EBE5DD',
    mercury: '#E4E4E4',
    navajoWhite: '#FFDFA7',
    cloudMist: '#c1c9d2'
}
