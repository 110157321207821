import React from 'react'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { Modal, View, StyleSheet, ViewStyle, ScrollViewProps } from 'react-native'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { Measurements, isIOS } from 'lib/common'
import { createStyles } from 'lib/styles'
import { Touchable } from 'lib/components'

type NativeModalProps = {
    isOpen: boolean,
    children: Children,
    isLoading?: boolean,
    animationType?: 'none' | 'slide' | 'fade',
    scrollViewProps?: ScrollViewProps,
    wrapperStyles?: ViewStyle,
    scrollViewContainerStyles?: ViewStyle,
    scrollViewStyles?: ViewStyle,
    customScrollViewStyles?: ViewStyle,
    childrenWrapper?: ViewStyle,
    contentStyles?: ViewStyle,
    fullWidth?: boolean,
    testID?: string,
    onClose(byButton?: boolean): void
}

export const NativeModal: React.FunctionComponent<NativeModalProps> = ({
    isOpen,
    children,
    onClose,
    animationType = 'fade',
    isLoading = false,
    fullWidth = false,
    wrapperStyles = {},
    scrollViewContainerStyles = {},
    contentStyles = {},
    childrenWrapper = {},
    scrollViewStyles,
    customScrollViewStyles,
    scrollViewProps,
    testID
}) => {
    const { styles } = useStyles(stylesheet)

    const handleClose = (byButton?: boolean) => {
        if (!isLoading) {
            onClose(byButton)
        }
    }

    return (
        <Modal
            testID={testID}
            accessibilityLabel={testID}
            transparent
            visible={isOpen}
            statusBarTranslucent
            animationType={animationType}
            onRequestClose={() => handleClose()}
        >
            <View
                style={{
                    ...styles.contentWrapper,
                    ...scrollViewStyles,
                    ...wrapperStyles
                }}
            >
                <KeyboardAwareScrollView
                    style={{
                        ...styles.scrollView,
                        ...scrollViewStyles,
                        ...customScrollViewStyles
                    }}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{
                        ...styles.contentContainer,
                        ...scrollViewContainerStyles
                    }}
                    {...scrollViewProps}
                >
                    <Touchable
                        onPress={handleClose}
                        style={styles.backdrop}
                    />
                    <View style={{
                        ...(fullWidth ? styles.fullWidth : {}),
                        ...contentStyles
                    }}>
                        <View style={childrenWrapper}>
                            {children}
                        </View>
                    </View>
                </KeyboardAwareScrollView>
            </View>
        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    scrollView: {
        width: '100%',
        minHeight: '100%',
        backgroundColor: theme.colors.transparent
    },
    contentContainer: {
        minHeight: '100%',
        backgroundColor: theme.colors.transparent,
        justifyContent: 'center',
        alignItems: 'center'
    },
    backdrop: {
        ...StyleSheet.absoluteFillObject
    },
    contentWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.components.modal.backdrop.backgroundColor,
        paddingTop: isIOS
            ? Measurements.StatusBarHeight
            : 0,
        padding: theme.utils.gap(2)
    },
    closeButton: (topInset: number) => ({
        zIndex: theme.zIndex[10],
        position: 'absolute',
        right: theme.utils.gap(2.5),
        top: theme.utils.gap(2) + topInset
    }),
    fullWidth: {
        width: '100%'
    }
}))
