import { NativeJobSchedule } from 'lib/types'
import { PaymentType, Promotion } from 'lib/models'
import { Address, NewAddress, BookingFrequency, CleaningScheduleType } from 'lib/native/models'

export enum PropertyType {
    Residential = 'residential',
    Commercial = 'commercial'
}

export enum HousingType {
    Condominium = 'condominium',
    HDB = 'hdb',
    LandedProperty = 'landed_property',
    ShophouseHome = 'shophouse_home',
}

export type Schedule = {
    day: string;
    time: string;
}

export type JobBookingDetail = {
    frequency: BookingFrequency,
    numberOfHours: number,
    slotId?: string,
    ironing?: boolean,
    address: Address | undefined,
    propertyInfo: PropertyType | undefined,
    housingType: HousingType | undefined,
    bedrooms: number | undefined,
    bathrooms: number | undefined,
    commercialSize: string | undefined,
    date: Date | undefined,
    petOnPresmises: boolean,
    addressNotes: string | undefined,
    messageToSupplier: string | undefined,
    newAddress: NewAddress | undefined,
    paymentType: PaymentType | undefined,
    paymentMethodId: string,
    scheduleType: CleaningScheduleType | undefined,
    isDateInDynamicPriceSlot?: boolean,
}

type Booking = {
    date?: string,// THIS DATE IS SEND TO API PAYLOAD. THIS IS NOT SHOWN IN UI.
    uiDate?: string,// THIS DATE IS WHAT WE SHOW IN THE UI. THIS IS NOT SENT IN API PAYLOAD.
    schedule?: Array<NativeJobSchedule>
}

export type JobBookingDetailAtom = JobBookingDetail & {
    token: string,
    booking: Booking,
    promo?: Promotion
}
