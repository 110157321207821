import { useTranslations } from 'lib/hooks'
import { MoversAndPackersComponents } from '../../components'

export const useMoversAndPackersAddOnPricing = () => {
    const T = useTranslations()

    return [
        {
            tabLabel: T.components.moversAndPackersAddOnPricing.options.movers.title,
            renderContent: () => (
                <MoversAndPackersComponents.MoversAndPackersMoversPricing />
            )
        },
        {
            tabLabel: T.components.moversAndPackersAddOnPricing.options.tailgatingServices.title,
            renderContent: () => (
                <MoversAndPackersComponents.MoversAndPackersTailgatingPricing />
            )
        },
        {
            tabLabel: T.components.moversAndPackersAddOnPricing.options.wrappingService.title,
            renderContent: () => (
                <MoversAndPackersComponents.MoversAndPackersWrappingPricing />
            )
        }
    ]
}
