import React from 'react'
import { Children } from 'lib/types'
import { createProvider, ThemeVariant } from '@propertyguru/hive'
import { SendhelperDarkTheme, SendhelperTheme } from '@propertyguru/hive-sendhelper-theme'

type HiveComponentProviderProps = {
    children: Children
}

export const HiveComponentProvider: React.FunctionComponent<HiveComponentProviderProps> = ({ children }) => {

    const HiveProvider = createProvider({
        theme: {
            light: SendhelperTheme,
            dark: SendhelperDarkTheme,
            highContrast: SendhelperTheme
        }
    })

    return (
        <HiveProvider variant={ThemeVariant.Light}>
            {children}
        </HiveProvider>
    )
}
