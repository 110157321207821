import React, { useEffect } from 'react'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { ScreenNames } from 'lib/routing'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { NativeStackProps } from 'lib/native/routing'

type ScreenRedirectProps = {
    to: ScreenNamesNative | ScreenNames
    isReplace?: boolean
}

export const ScreenRedirect: React.FunctionComponent<ScreenRedirectProps> = ({ to, isReplace }) => {
    const navigation = useNavigation<NativeStackProps<ScreenNamesNative>>()
    const isFocused = useIsFocused()
    const route = useRoute()

    useEffect(() => {
        try {
            if (isReplace) {
                // Need to cast to any because of the type mismatch between the two navigation types
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return navigation.replace(to as any, route.params as any)
            }

            // Need to cast to any because of the type mismatch between the two navigation types
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            navigation.navigate(to as any, route.params as any)
        } catch (error) {
            console.error('Error redirect screen:', error)
        }
    }, [isFocused])

    return null
}
