import React, { useState } from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography } from 'lib/components'
import RescheduleBookingForm from './RescheduleBookingForm'
import { JobDetailsResponse } from 'features/jobs/types'
import { RescheduleFormShape } from 'features/jobs/forms'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { BookingFrequency, CustomFonts } from 'lib/types'
import { dateHelpers, jobHelpers, linkingHelpers } from 'lib/utils'
import { isNative } from 'lib/common'
import { ENV } from 'lib/config'
import { RescheduledMessage } from './RescheduledMessage'
import { isAirconSubscriptionParentOrChild } from 'features/bookings/utils/airconUtils'

type RescheduleBookingModalProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    onBack: VoidFunction,
    onReschedule: VoidFunction,
    formReturn: UseFormReturn<RescheduleFormShape>
    job: JobDetailsResponse,
    isLoading: boolean
}

export const RescheduleBookingModal: React.FunctionComponent<RescheduleBookingModalProps> = ({
    isOpen,
    formReturn,
    onClose,
    onBack,
    onReschedule,
    job,
    isLoading
}) => {

    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [bookingDaysInitialValue] = useState(formReturn.form.bookingDays.value)
    const [startingDateInitialValue] = useState(formReturn.form.startingDate.value)
    const isOneTime = formReturn.form.frequency.value.value  === BookingFrequency.OneTime
    const shouldDisableSubmitButtonForOneTime =
        !formReturn.formHasChanges() ||
        !formReturn.isFilled ||
        formReturn.hasError ||
        isLoading ||
        (isAirconSubscriptionParentOrChild(job.job) && !formReturn.form.bookingTime.value)
    // We need to handle the function check form has changed. Because the form does not support comparing object value (bookingDays field value is an object)
    const isBookingDaysHasChanges =
        JSON.stringify(bookingDaysInitialValue) !== JSON.stringify(formReturn.form.bookingDays.value)
    const isStartingDateHasChanges = startingDateInitialValue !== formReturn.form.startingDate.value
    const isAllBookingDaysValid = Object.values(formReturn.form.bookingDays.value).every(value => value !== '')
    const startingDay = dateHelpers.getFullNameDayOfWeek(formReturn.form.startingDate.value)
    const isStartingDayValid = Boolean(formReturn.form.bookingDays.value?.[startingDay])
    const shouldDisableSubmitButtonForRecurring =
        !(isBookingDaysHasChanges || isStartingDateHasChanges) ||
        !isAllBookingDaysValid ||
        !isStartingDayValid
    const shouldDisableSubmitButton =
        isOneTime || isAirconSubscriptionParentOrChild(job.job) ?
            shouldDisableSubmitButtonForOneTime :
            shouldDisableSubmitButtonForRecurring

    const shouldShowSubscriptionContent = jobHelpers.shouldShowSubscriptionContent(job)
    const isAcceptedSubscription = jobHelpers.isAcceptedSubscription(job)

    return (
        <CustomModal
            testID={T.accessibility.bookingDetailsScreen.reschedule.modal}
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.headerWrapper}>
                <Typography.SmallSubheading style={styles.title}>
                    {
                        shouldShowSubscriptionContent ?
                            T.screens.jobDetails.rescheduleBooking.planTitle :
                            T.screens.jobDetails.rescheduleBooking.packageTitle
                    }
                </Typography.SmallSubheading>
                {
                    !shouldShowSubscriptionContent && (
                        <View style={styles.messageContainer}>
                            <Typography.Regular style={styles.message}>
                                {T.screens.jobDetails.rescheduleBooking.message}
                            </Typography.Regular>
                        </View>
                    )
                }
                {
                    isAcceptedSubscription && <RescheduledMessage/>
                }
            </View>
            <View style={styles.formWrapper}>
                <View style={isOneTime ? styles.oneTimeFormWrapper : styles.recurringFormWrapper}>
                    <RescheduleBookingForm isLoading={isLoading} formReturn={formReturn} job={job}/>
                    <View style={styles.actionContainer}>
                        <View style={{
                            ...styles.buttonWrapper,
                            ...styles.firstButtonWrapper
                        }}>
                            <Button
                                testID={T.accessibility.bookingDetailsScreen.reschedule.back}
                                disabled={isLoading}
                                noBackground
                                text={T.screens.jobDetails.rescheduleBooking.back}
                                onPress={onBack}
                                textWrapperStyles={styles.buttonTextWrapper}
                                textStyles={styles.message}
                            />
                        </View>
                        <View style={{
                            ...styles.buttonWrapper,
                            ...styles.lastButtonWrapper
                        }}>
                            <Button
                                testID={T.accessibility.bookingDetailsScreen.reschedule.submit}
                                disabled={shouldDisableSubmitButton}
                                text={T.screens.jobDetails.rescheduleBooking.send}
                                onPress={onReschedule}
                                textWrapperStyles={styles.buttonTextWrapper}
                                textStyles={styles.message}
                            />
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.footerContainer}>
                <Typography.Regular>
                    <Typography.Regular>
                        {T.screens.jobDetails.rescheduleBooking.haveAnIssue}
                    </Typography.Regular>
                    <Typography.Regular style={styles.link} onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contacts`)}>
                        {` ${T.screens.jobDetails.rescheduleBooking.contactUs} `}
                    </Typography.Regular>
                    <Typography.Regular>
                        {T.screens.jobDetails.rescheduleBooking.orViewOur}
                    </Typography.Regular>
                    <Typography.Regular style={styles.link} onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/terms`)}>
                        {` ${T.screens.jobDetails.rescheduleBooking.termsOfService} `}
                    </Typography.Regular>
                    <Typography.Regular>
                        {T.screens.jobDetails.rescheduleBooking.and}
                    </Typography.Regular>
                    <Typography.Regular style={styles.link} onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/cancellation-policy`)}>
                        {` ${T.screens.jobDetails.rescheduleBooking.cancellationPolicy}`}
                    </Typography.Regular>
                </Typography.Regular>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    headerWrapper: {
        alignItems: 'center'
    },
    formWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    oneTimeFormWrapper: {
        alignItems: 'center',
        maxWidth: {
            md: 400,
            xs: '100%'
        },
        width: isNative ? '100%' : undefined
    },
    recurringFormWrapper: {
        alignItems: 'center',
        maxWidth: {
            lg: 800,
            md: 600,
            xs: '100%'
        },
        width: isNative ? '100%' : undefined
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 31,
        fontFamily: CustomFonts.Poppins600
    },
    messageContainer: {
        marginTop: theme.utils.gap(1.5),
        alignItems: 'center'
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3),
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        justifyContent: 'center',
        zIndex: -1,
        width: '100%'
    },
    buttonWrapper: {
        marginHorizontal: {
            xs: undefined,
            md: theme.utils.gap(1)
        },
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center'
    },
    firstButtonWrapper: {
        marginLeft: {
            xs: undefined,
            md: 0
        }
    },
    lastButtonWrapper: {
        marginRight: {
            xs: undefined,
            md: 0
        },
        marginTop: {
            xs: theme.utils.gap(2),
            md: undefined
        }
    },
    message: {
        textAlign: 'center'
    },
    footerContainer: {
        marginTop: theme.utils.gap(3),
        textAlign: 'center',
        zIndex: -1
    },
    link: {
        color: theme.colors.orange,
        cursor: 'pointer',
        fontWeight: 'bold'
    }
}))
