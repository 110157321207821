import React, { useState } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { ENV } from 'lib/config'
import { JobType } from 'lib/types'
import { linkingHelpers, cancelReasonHelpers } from 'lib/utils'
import { Segment, segmentUtils } from 'lib/analytics'
import { JobDetails } from 'lib/models'
import { useCancelReasonAtom, useUserTokenAtom } from 'lib/atoms'
import { Button } from 'lib/components'
import { useQueryHelpers, useTranslations } from 'lib/hooks'
import { CancelationShape, useCancelationForm } from '../forms'
import { cancelAirconJob, cancelJob, getAirConditionJobDetails, getJobDetails } from '../actions'
import { CancelRequestModal } from './cancelRequestModal'
import { CancelBillingModal } from './cancelBillingModal'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'

type CancelBillingProps = {
    job: JobDetails,
    customButton?(onPress: VoidFunction): JSX.Element
}

export const CancelBilling: React.FunctionComponent<CancelBillingProps> = ({
    job,
    customButton
}) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const [cancelationOptions] = useCancelReasonAtom()
    const isAirconSubscriptionJob = isAirconSubscription(job)
    const { mutate: onJobCancel, isLoading: isMutating } = isAirconSubscriptionJob ? cancelAirconJob() : cancelJob()
    const { onRequestError } = useQueryHelpers()
    const { refetch, isRefetching } = isAirconSubscriptionJob ? getAirConditionJobDetails(job._id) : getJobDetails(job._id)
    const [isReasonModalOpen, setReasonModalOpen] = useState(false)
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const formReturn = useForm<CancelationShape>(useCancelationForm(), {
        onSuccess: ({ customReason, reason }) => {
            const cancelReason = cancelReasonHelpers.getCancelReason(reason, customReason)

            onJobCancel(
                {
                    id: job._id,
                    token,
                    job_type: job.recurring_job
                        ? JobType.Recurring
                        : undefined,
                    cancel_reason: cancelReason
                },
                {
                    onSuccess: () => {
                        refetch()
                            .finally(() => setIsConfirmationModalOpen(false))

                        Segment.bookingCanceled({
                            jobId: job.job_id,
                            reason: (cancelReason?.reason ?? customReason) as string,
                            serviceType: segmentUtils.supplyIdToServiceType(job.supply.id)
                        })
                    },
                    onError: error => {
                        onRequestError(error)
                        setIsConfirmationModalOpen(false)
                    }
                }
            )
        }
    })
    const { submit, resetForm } = formReturn
    const isLoading = isMutating || isRefetching

    return (
        <React.Fragment>
            <CancelRequestModal
                onSubmit={submit}
                formReturn={formReturn}
                isLoading={isLoading}
                isOpen={isReasonModalOpen}
                cancelationOptions={cancelationOptions}
                onClose={() => {
                    resetForm()
                    setReasonModalOpen(false)
                }}
                cancelationMessage={job?.popup?.cancellation?.message}
            />
            <CancelBillingModal
                isLoading={isLoading}
                cancelationMessage={job?.popup?.cancellation?.message}
                isOpen={isConfirmationModalOpen}
                onCancel={() => {
                    setIsConfirmationModalOpen(false)
                    linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contacts`)
                }}
                onConfirm={() => {
                    setIsConfirmationModalOpen(false)
                    setReasonModalOpen(true)
                }}
                onClose={() => setIsConfirmationModalOpen(false)}
            />
            {customButton
                ? customButton(() => setIsConfirmationModalOpen(true))
                : (
                    <Button
                        noBackground
                        onPress={() => setIsConfirmationModalOpen(true)}
                        text={T.components.cancelJobModal.declineJob}
                    />
                )
            }
        </React.Fragment>
    )
}
