import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Job } from 'lib/models'
import { getAirConditionJobDetails, getJobDetails } from '../../actions'
import { NextSessionInfo } from './NextSessionInfo'
import { RescheduledMessage } from '../RescheduledMessage'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'

type NextSessionTileProps = {
    nextSession: Job,
    sessionIndex: number,
    allSessionsCount: number
}

export const NextSessionTile: React.FunctionComponent<NextSessionTileProps> = ({
    nextSession,
    sessionIndex,
    allSessionsCount
}) => {
    const { styles } = useStyles(stylesheet)
    const { data: response } = isAirconSubscription(nextSession) ? getAirConditionJobDetails(nextSession?._id) : getJobDetails(nextSession?._id || '')
    const nextResponseData = response?.data
    const nextJobDetails = nextResponseData?.job

    return (
        <View style={styles.content}>
            <NextSessionInfo
                job={nextSession}
                jobDetails={nextJobDetails}
                nextSessionResponse={nextResponseData}
                sessionIndex={sessionIndex}
                allSessionsCount={allSessionsCount}
            />
            {
                nextResponseData && <RescheduledMessage response={nextResponseData}/>
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        overflow: 'hidden',
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1.5),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(4),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
