import React, { useState } from 'react'
import { FlatList, InteractionManager, View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Icons } from 'assets'
import { useUserAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { modalHelpers } from 'lib/utils'
import { Measurements } from 'lib/common'
import { useStyles, useTranslations } from 'lib/hooks'
import { AnimatedModal, Button, Touchable, Typography } from 'lib/components'
import { NativeAddressSelectItem } from './NativeAddressSelectItem'
import { NativeNewAddressModal } from './NativeNewAddressModal'

type NativeAddressSelectProps = {
    postCodeField: Field<string>,
    isOpen: boolean,
    onClose: VoidFunction,
    onConfirm: VoidFunction
}

export const NativeAddressSelect: React.FunctionComponent<NativeAddressSelectProps> = ({
    postCodeField,
    isOpen,
    onClose,
    onConfirm
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const { styles, theme } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const [isAddingNewAddress, setAddingNewAddress] = useState(false)
    const modalHeight = modalHelpers.modalHeight(user.locations.length) + theme.utils.gap(2)

    const openAddAddressModal = () => {
        setAddingNewAddress(true)
    }

    return (
        <React.Fragment>
            <NativeNewAddressModal
                onClose={() => {
                    setAddingNewAddress(false)
                }}
                onConfirm={onConfirm}
                isOpen={isAddingNewAddress}
                onSuccess={address => postCodeField.onChangeValue(`${address.postcode}/${address._id}`)}
            />
            <AnimatedModal
                isVisible={isOpen}
                backdropHeight={Measurements.WindowHeight - modalHeight}
                onClose={onClose}
            >
                {({ onModalClose }) => (
                    <View
                        style={{
                            ...styles.container,
                            height: modalHeight,
                            paddingBottom: insets.bottom + theme.utils.gap(1)
                        }}
                    >
                        <View style={styles.title}>
                            <Typography.Subheading>
                                {T.components.nativeAddressSelectModal.title}
                            </Typography.Subheading>
                            <Touchable
                                onPress={() => {
                                    onModalClose()
                                }}
                                hitSlopBottom={20}
                                hitSlopRight={20}
                                hitSlopLeft={20}
                                hitSlopTop={20}
                            >
                                <Icons.Close size={18} />
                            </Touchable>
                        </View>
                        <View style={styles.subTitle}>
                            <Typography.Regular bold>
                                {T.components.nativeAddressSelectModal.savedAddresses}
                            </Typography.Regular>
                            <Touchable
                                style={styles.newAddressButton}
                                onPress={() => {
                                    onModalClose(openAddAddressModal)
                                }}
                            >
                                <Icons.Plus
                                    size={10}
                                    forceColor={theme.colors.orange}
                                />
                                <View style={styles.newAddressText}>
                                    <Typography.Label forceColor={theme.colors.orange}>
                                        {T.components.nativeAddressSelectModal.addNewAddress}
                                    </Typography.Label>
                                </View>
                            </Touchable>
                        </View>
                        <FlatList
                            data={user.locations}
                            renderItem={({ item, index }) => (
                                <NativeAddressSelectItem
                                    item={item}
                                    isPrimary={index === 0}
                                    postCodeField={postCodeField}
                                    isSelected={item._id === postCodeField.value.split('/').at(1)}
                                />
                            )}
                            showsVerticalScrollIndicator={false}
                        />
                        <Button
                            disabled={!postCodeField.value}
                            testID={T.accessibility.nativeSelectAddress.confirmBtn}
                            onPress={() => {
                                onModalClose()

                                InteractionManager.runAfterInteractions(() => {
                                    onConfirm()
                                })
                            }}
                            text={T.common.confirm}
                        />
                    </View>
                )}
            </AnimatedModal>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.utils.gap(2)
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: theme.utils.gap(2)
    },
    newAddressText: {
        marginLeft: theme.utils.gap(1) / 2,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange
    },
    newAddressButton: {
        flexDirection: 'row',
        alignItems: 'center'
    }
}))
