import React from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { useHive } from '@propertyguru/hive'
import { Text } from '@propertyguru/hive-text'
import { isAndroid } from 'lib/common'
import { Touchable } from 'lib/components'
import { useStyles, useKeyboardVisible, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { isIOS } from '../../common'
import { useBottomBarRoutes } from '../hooks/useBottomBar.native'

export const BottomBar: React.FunctionComponent<BottomTabBarProps> = ({ state, navigation }) => {
    const hive = useHive()
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const routes = useBottomBarRoutes(state)
    const { isKeyboardVisible } = useKeyboardVisible()
    const { bottom } = useSafeAreaInsets()

    const linkTestIdMap = {
        Sendhelper: T.accessibility.dashboard.bottomBar.sendhelper,
        Orders: T.accessibility.dashboard.bottomBar.order,
        Feed: T.accessibility.dashboard.bottomBar.feed,
        You: T.accessibility.dashboard.bottomBar.you
    }

    return (
        <View
            style={{
                ...styles.container(isKeyboardVisible),
                borderColor: hive.color('border/active/secondary')
            }}
        >
            {routes.map((link, index) => {
                const isActive = state.index === index
                const Icon = React.createElement(isActive ? link.iconActive : link.icon, {
                    width: 24,
                    height: 24
                })

                return (
                    <Touchable
                        testID={linkTestIdMap[link.text] ?? T.accessibility.dashboard.bottomBar.other}
                        style={styles.link}
                        key={index}
                        onPress={() => navigation.navigate(link.name)}
                    >
                        <View
                            style={{
                                ...styles.linkContainer(bottom),
                                rowGap: hive.spacing('x1')
                            }}
                        >
                            {Icon}
                            <Text
                                typography={isActive ? 'caption/s' : 'caption/xs'}
                                color={isActive ? 'text/active/primary' : 'text/inactive/secondary'}
                            >
                                {link.text}
                            </Text>
                        </View>
                    </Touchable>
                )
            })}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: (isKeyboardVisible: boolean) => ({
        display: isKeyboardVisible && isAndroid ? 'none' : 'flex',
        width: '100%',
        flexDirection: 'row',
        backgroundColor: theme.colors.white,
        borderTopWidth: 1,
        borderColor: theme.colors.silver
    }),
    link: {
        flex: 1
    },
    linkContainer: (bottomInset: number) => ({
        paddingTop: theme.utils.gap(2),
        paddingBottom: isIOS ? Math.max(bottomInset, theme.utils.gap(2)) : theme.utils.gap(2) + bottomInset,
        alignItems: 'center'
    })
}))
