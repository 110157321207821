import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Typography } from 'lib/components'
import { jobHelpers } from 'lib/utils'
import { DynamicTimeSlots, SelectInputOption } from 'lib/types'

type IconWithPriceProps = {
    item: SelectInputOption,
    timeSlots: DynamicTimeSlots
}

const IconWithPrice: React.FunctionComponent<IconWithPriceProps> = React.memo(({ item, timeSlots }) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.itemIconContainer}>
            <View style={styles.itemIcon}>
                <Icons.TrendsDownward
                    forceColor={theme.colors.green}
                    size={16}
                    viewBox="0 0 16 16"
                />
            </View>
            <Typography.Regular forceColor={theme.colors.green}>
                {jobHelpers.getDynamicTimeSelected(item.value, timeSlots)?.price}
            </Typography.Regular>
        </View>
    )
})

export default IconWithPrice

const stylesheet = createStyles(theme => ({
    itemIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    itemIcon: {
        marginRight: theme.utils.gap(0.5)
    }
}))
