import React from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { Text } from '@propertyguru/hive-text'
import { Touchable } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'

type HiveCalendarYearProps = {
    year: number,
    isSelected: boolean,
    isDisabled: boolean,
    onPress: (year: number) => void
}

export const HiveCalendarYear = ({ year, isSelected, isDisabled, onPress }: HiveCalendarYearProps) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            onPress={() => !isDisabled && onPress(year)}
            style={{
                ...styles.year,
                paddingVertical: hive.spacing('x2')
            }}
            disabled={isDisabled}
        >
            <View
                style={{
                    ...styles.yearContainer,
                    ...(isDisabled ? styles.yearContainerDisabled : {}),
                    height: hive.spacing('x10'),
                    paddingHorizontal: hive.spacing('x4'),
                    borderRadius: hive.borderRadius('large'),
                    backgroundColor: isSelected ? hive.color('fill/active/primary') : 'transparent'
                }}
            >
                <Text typography="body/m" color={isDisabled ? 'text/inactive/primary' : 'text/active/primary'}>
                    {year}
                </Text>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(() => ({
    year: {
        alignItems: 'center'
    },
    yearContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    yearContainerDisabled: {
        opacity: 0.5
    }
}))
