import React from 'react'
import ReactSelect from 'react-select'
import { useStyles, useTranslations } from 'lib/hooks'
import { SearchInputProps } from './types'
import { ErrorMessage } from '../ErrorMessage'
import { CustomClearIndicator, CustomValueContainer } from './webComponents'

export const SearchInput: React.FunctionComponent<SearchInputProps> = ({
    disabled,
    onChange,
    value,
    options,
    placeholder,
    errorMessage
}) => {
    const { theme } = useStyles()
    const T = useTranslations()
    const getBorderColor = () => {
        if (errorMessage) {
            return theme.components.input.error.borderColor
        }

        if (!value) {
            return theme.components.input.emptyBorderColor
        }

        return theme.components.input.filledBorderColor
    }

    return (
        <React.Fragment>
            <ReactSelect
                isClearable
                backspaceRemovesValue
                value={value}
                options={options}
                isDisabled={disabled}
                placeholder={placeholder}
                onChange={onChange}
                noOptionsMessage={() => T.common.noOptions}
                components={{
                    ValueContainer: CustomValueContainer,
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: null
                }}
                styles={{
                    control: base => ({
                        ...base,
                        height: theme.components.input.height,
                        borderRadius: theme.components.input.borderRadius,
                        borderColor: getBorderColor(),
                        paddingLeft: theme.components.input.padding,
                        fontFamily: theme.components.typography.fontFamily,
                        boxShadow: 'none',
                        '&:hover': {
                            borderColor: theme.colors.night
                        }
                    }),
                    valueContainer: base => ({
                        ...base,
                        paddingLeft: theme.utils.gap(3)
                    }),
                    singleValue: base => ({
                        ...base,
                        fontSize: 14,
                        color: theme.components.input.typography.text
                    }),
                    input: base => ({
                        ...base,
                        fontSize: 14,
                        color: theme.components.input.typography.text,
                        gridTemplateColumns: '0 minmax(min-content, 1fr)'
                    }),
                    placeholder: base => ({
                        ...base,
                        fontSize: 14,
                        marginLeft: 0,
                        color: errorMessage
                            ? theme.components.input.typography.error
                            : theme.components.input.typography.placeholder
                    }),
                    indicatorSeparator: base => ({
                        ...base,
                        width: 0
                    }),
                    menu: base => ({
                        ...base,
                        borderRadius: theme.components.input.borderRadius,
                        boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.16)'
                    }),
                    option: (base, props) => ({
                        ...base,
                        fontSize: 16,
                        color: props.isFocused
                            ? theme.colors.orange
                            : theme.components.input.typography.text,
                        backgroundColor: props.isFocused
                            ? theme.colors.yellow
                            : 'transparent',
                        fontFamily: theme.components.typography.fontFamily
                    }),
                    noOptionsMessage: base => ({
                        ...base,
                        color: theme.components.input.typography.placeholder,
                        fontFamily: theme.components.typography.fontFamily
                    })
                }}
            />
            <ErrorMessage text={errorMessage} />
        </React.Fragment>
    )
}
