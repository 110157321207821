export const WEEKDAYS_MAP = {
    Su: 'Sunday',
    Mo: 'Monday',
    Tu: 'Tuesday',
    We: 'Wednesday',
    Th: 'Thursday',
    Fr: 'Friday',
    Sa: 'Saturday'
}

export const WEEKDAYS = Object.keys(WEEKDAYS_MAP)

