import React, { useRef } from 'react'
import * as Linking from 'expo-linking'
import appsFlyer from 'react-native-appsflyer'
import ErrorBoundary from 'react-native-error-boundary'
import { getStateFromPath, NavigationContainer, NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { isNative, isWeb } from 'lib/common'
import { useCleanApp, useTranslations, useUserTracking } from 'lib/hooks'
import { ErrorBoundaryHandler } from 'lib/components'
import { authActions } from 'features/auth'
import { ScreenNames, ScreensConfig } from './screens'
import { NativeStack, WebStack } from './stacks'
import {
    useIsAuthorizedAtom,
    useToastAtom,
    useUnreadJobChatsAtom,
    useUserAtom,
    useUserTokenAtom,
    useWAPaymentAtom
} from '../atoms'
import { linkingHelpers } from '../utils'
import { NotificationType } from '../types'
import { GoogleAnalytics, Segment } from '../analytics'
import { NavigationScreenParams } from './stackParams'
import { UserPath } from './types/path'
import { saveAccessToken } from 'features/auth/utils'

const renderAppStack = (isAuthorized: boolean) => {
    if (isWeb) {
        return WebStack(isAuthorized)
    }

    return NativeStack(isAuthorized)
}

type AppRouterProps = {
    navigationRef: NavigationContainerRefWithCurrent<NavigationScreenParams>,
    onNavigationReady: VoidFunction
}

export const AppRouter: React.FunctionComponent<AppRouterProps> = ({ navigationRef, onNavigationReady }) => {
    const T = useTranslations()
    const routeNameRef = useRef<string>()
    const deepLinkVal = useRef<string | null>()
    const [isAuthorized, setIsAuthorized] = useIsAuthorizedAtom()
    const [, setWaPaymentAdd] = useWAPaymentAtom()
    const [, setToastMessage] = useToastAtom()
    const [unreadJobChats] = useUnreadJobChatsAtom()
    const [token, setUserToken] = useUserTokenAtom()
    const [user, setUser] = useUserAtom()
    const { mutate: getMe } = authActions.useGetMe()
    const { cleanPersonalData } = useCleanApp()

    useUserTracking()

    const fetchUserData = () => {
        getMe(
            { token },
            { onSuccess: ({ data }) => setUser(data.profile) }
        )
    }

    const logScreenView = () => {
        const screenName = isNative
            ? routeNameRef.current
            : `/${ScreensConfig[routeNameRef.current as ScreenNames]?.path}`

        if (screenName) {
            GoogleAnalytics.logScreenView(screenName)
            Segment.trackPageView(routeNameRef.current as ScreenNames)

            const isUserPath = isNative ?
                screenName === ScreenNames.User:
                [
                    UserPath.Information,
                    UserPath.Address,
                    UserPath.Preferences,
                    UserPath.CardsNew
                ].includes(screenName as UserPath)

            const shouldFetchUserData = isAuthorized && isUserPath
            shouldFetchUserData && fetchUserData()
        }
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorBoundaryHandler}>
            <NavigationContainer
                ref={navigationRef}
                documentTitle={{
                    formatter: (options, route) => `${unreadJobChats.length === 0 ? '' : `(${unreadJobChats.length}) `}`
                        .concat(`${options?.title ?? route?.name} - ${T.common.sendHelper}`)
                }}
                onReady={() => {
                    routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name
                    onNavigationReady()

                    // When navigation is ready,
                    // redirect if there is a deep link value
                    if (user.email_id && deepLinkVal.current && ScreenNames[deepLinkVal.current]) {
                        navigationRef.current?.navigate(ScreenNames[deepLinkVal.current])
                        deepLinkVal.current = null
                    }

                    // onStageChange is not called on first app entry, so let's send trackPageView manually
                    // it will also re-generate anonymousId for Segment
                    logScreenView()
                }}
                linking={{
                    prefixes: [Linking.createURL('/')],
                    config: {
                        screens: {
                            ...ScreensConfig,
                            [ScreenNames.RootLeftDrawer]: {
                                screens: isAuthorized ? {
                                    [ScreenNames.AllServices]: {
                                        path: 'sendhelper'
                                    }
                                } : {
                                    [ScreenNames.Home]: {
                                        path: 'sendhelper'
                                    }
                                }
                            }
                        }
                    },
                    getStateFromPath: (path, options) => {
                        const linkData = linkingHelpers.parseWhatsappAddPaymentLink(path)
                        const isDifferentUserSession = isAuthorized && linkData.hasParams && token !== linkData.accessParams.token

                        if (isAuthorized && linkData.hasParams && isDifferentUserSession) {
                            cleanPersonalData()
                            linkingHelpers.openUrl(path, false)

                            return getStateFromPath(path, options)
                        }

                        if (linkData.hasParams) {
                            setWaPaymentAdd(linkData.addPaymentParams)
                            saveAccessToken(linkData.accessParams.authToken)
                            setUserToken(linkData.accessParams.token)
                            setIsAuthorized(true)
                        }

                        if (!linkingHelpers.isBookingLink(path)) {
                            return getStateFromPath(path, options)
                        }

                        const redirectUrl = linkingHelpers.getRedirectUrlFromBookingLink(
                            isAuthorized,
                            () => {
                                setToastMessage({
                                    message: T.linking.unsupportedServiceError,
                                    type: NotificationType.Info
                                })
                            },
                            path
                        )

                        return getStateFromPath(redirectUrl || path, options)
                    },
                    getInitialURL: async ()  => {
                        // getIntitialURL is called only once on app start
                        // we can extract the deepLink value that opened the app here
                        const url = await Linking.getInitialURL()

                        if (url) {
                            const deepLinkParam = url.split('&').find(val => val.includes('deep_link_value'))
                            const routeName = deepLinkParam && deepLinkParam.length? deepLinkParam.split('=')[1] : undefined

                            // eslint-disable-next-line nested-if/nested-if-statements
                            if (user.email_id && routeName && ScreenNames[routeName]) {
                                deepLinkVal.current = routeName
                            }
                        }

                        return url
                    },
                    subscribe: listener => {
                        // Listen to incoming links from AppsFlyer
                        // For cases when app transitions from background to foreground
                        // due to deep link clicked.
                        const onDeepLinkCanceller = appsFlyer.onDeepLink(res => {
                            const routeName = res.data?.deep_link_value

                            if (user.email_id && routeName && ScreenNames[routeName]) {
                                navigationRef.current?.navigate(ScreenNames[routeName])
                            }
                        })

                        const linkingSubscription = Linking.addEventListener('url', data => {
                            const deepLinkParam = data.url.split('&').find(val => val.includes('deep_link_value'))
                            const routeName = deepLinkParam && deepLinkParam.length ? deepLinkParam.split('=')[1] : undefined

                            if (user.email_id && routeName && ScreenNames[routeName]) {
                                navigationRef.current?.navigate(ScreenNames[routeName])
                            }

                            listener(data.url)
                        })

                        return () => {
                            onDeepLinkCanceller()
                            linkingSubscription.remove()
                        }
                    }
                }}
                onStateChange={() => {
                    const previousRouteName = routeNameRef.current
                    const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name

                    if (previousRouteName === currentRouteName) {
                        return
                    }

                    routeNameRef.current = currentRouteName

                    logScreenView()
                }}
            >
                {renderAppStack(isAuthorized)}
            </NavigationContainer>
        </ErrorBoundary>
    )
}
