import React from 'react'
import { View } from 'react-native'
import { columns, stringHelpers } from 'lib/utils'
import { Address } from 'lib/models'
import { useStyles } from 'lib/hooks'
import { useUserAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { UserAddressTile } from './UserAddressTile'
import { UserAddressEmpty } from './UserAddressEmpty'

type UserAddressesListProps = {
    searchValue: string,
    setAddAddress?: VoidFunction
}

export const UserAddressesList: React.FunctionComponent<UserAddressesListProps> = ({
    searchValue,
    setAddAddress
}) => {
    const { styles } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const filteredResults = () => {
        try {
            const searchedAddress = JSON.parse(searchValue) as Address

            return user.locations?.filter(item => item._id === searchedAddress._id)
        } catch {
            return user.locations?.filter(item => {
                const addressUnit = stringHelpers.getFormattedAddressUnit(item.unit)

                return `${addressUnit}${item.address}, ${item.postcode}, ${item.district}`.toLowerCase().includes(searchValue?.toLowerCase())
            })
        }
    }
    const columnsArray = columns.splitArrayToColumns(filteredResults())

    if (filteredResults().length === 0) {
        return (
            <UserAddressEmpty setAddAddress={setAddAddress} />
        )
    }

    return (
        <View style={styles.container}>
            {columnsArray.map((column, columnIndex) => (
                <React.Fragment key={columnIndex}>
                    <View style={styles.column}>
                        {column.map((item, itemIndex) => (
                            <UserAddressTile
                                key={itemIndex}
                                {...item}
                                // todo wait for backend implementation
                                // for now first address is primary
                                // and it cannot be changed
                                isPrimary={columnIndex === 0 && itemIndex === 0}
                            />
                        ))}
                    </View>
                    {columnIndex < columnsArray.length - 1 && (
                        <View style={styles.columnSpacer} />
                    )}
                </React.Fragment>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: {
            lg: 'row',
            ':w[, 1150]': 'column'
        }
    },
    column: {
        flex: {
            lg: 1,
            ':w[, 1150]': undefined
        }
    },
    columnSpacer: {
        width: theme.utils.gap(2)
    }
}))
