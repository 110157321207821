import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { PaymentNewCardModal } from 'features/payments'
import { useSegmentSession, useTranslations, useSessionData } from 'lib/hooks'
import { BookingFrequency, ServicesPlan, SupplyID } from 'lib/types'
import {
    useAddressAtom,
    useDynamicServiceAtom,
    useBookingJobQuotationAtom,
    useUserTokenAtom,
    useDynamicPriceSelectedAtom
} from 'lib/atoms'
import { Segment, ServiceType } from 'lib/analytics'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { BookingPaymentView, CleaningSummaryView } from 'features/bookings/components'
import { ServiceAddonsFormShape, useServiceAddonsForm } from 'features/bookings/forms'
import {
    useBookingScheduleDates,
    useCleaningTotalPrice,
    useCleaningCreateJob,
    useJobQuotation
} from 'features/bookings/hooks'
import {
    CreateCleaningJobRequest,
    CleaningQuotationRequest,
    CreateCleaningGuestJobRequest
} from 'features/bookings/types'
import { MobileOtpModal } from 'features/native/bookings/components'

type CleaningSummaryScreenProps = {
    navigation: NavigationProps<ScreenNames.CleaningSummary>,
    route: NavigationParams<ScreenNames.CleaningSummary>
}

export const CleaningSummaryScreen: React.FunctionComponent<CleaningSummaryScreenProps> = ({ route }) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const [address] = useAddressAtom()
    const [, setBookingJobQuotationAtom] = useBookingJobQuotationAtom()
    const { segmentSession } = useSegmentSession()
    const [paymentView, setPaymentView] = useState(false)
    const [config] = useDynamicServiceAtom(route.params.supplyId)
    const [dynamicPriceSelected] = useDynamicPriceSelectedAtom()
    const supplyId = config._id
    const frequency = route.params.form.frequency.value
    const isRecurring = frequency !== BookingFrequency.OneTime
    const { jobQuotation, requestJobQuotation, isLoading: isJobQuotationLoading } = useJobQuotation(SupplyID.Cleaner)
    const [totalPrice, setTotalPrice] = useState(0)
    const [originalPrice, setOriginalPrice] = useState(0)
    const sessionData = useSessionData()

    const [isLoading, setIsLoading] = useState(false)
    const recurringDates = useBookingScheduleDates(frequency, Object.values(route.params.form.bookingDays))
    const formReturn = useForm<ServiceAddonsFormShape>(useServiceAddonsForm(), {
        onSuccess: ({ ironing, comment, pets, extraHours }) => {
            setBookingJobQuotationAtom(jobQuotation || {})
            const [recurringStartDate] = recurringDates
            const recurringJobData = isRecurring
                ? Object.keys(route.params.form.bookingDays)
                    .map(dayKey => ({
                        activated: Boolean(route.params.form.bookingDays[dayKey]),
                        // They use day name in format like 'mon', 'tue' etc. They have it hardcoded in mobile app. I do not know if this support localization
                        day: dayKey.slice(0, 3).toLocaleLowerCase(),
                        fortnightly: frequency === BookingFrequency.Fortnightly,
                        start_time: route.params.form.bookingDays[dayKey] ?? recurringStartDate
                    }))
                : undefined
            const createJobPayload: CreateCleaningJobRequest = {
                token,
                ...sessionData,
                bookingSessionId: segmentSession.id,
                booking: {
                    messageToSupplier: comment,
                    date: isRecurring
                        ? recurringStartDate
                        : route.params.form.bookingTime,
                    location: addressCreated ?? address,
                    numberOfHours: parseFloat(route.params.form.hours) + Number(extraHours),
                    frequency: isRecurring ? 'recurring' : 'onetime',
                    buildingType: route.params.form.propertyType,
                    ironing: Boolean(ironing),
                    paymentType: paymentMethod,
                    petOnPresmises: Boolean(pets)
                },
                slotId: dynamicPriceSelected?.slotId
            }

            if (isRecurring) {
                createJobPayload.booking.schedule = recurringJobData
            }

            if (promotionPackage?.promotion) {
                createJobPayload.promo = {
                    code: promotionPackage?.promotion.promo_code,
                    auth: promotionPackage?.promotion.promo_auth
                }
            }

            onCreateJob(
                route.params.supplyId,
                createJobPayload,
                () => {
                    resetForm()
                    removePromoCode()
                }
            )
        }
    })
    const { form, resetForm, submit } = formReturn

    const { isLoadingPromotion, requestPromotion, promotionPackage, removePromoCode } = useCleaningTotalPrice({
        is_recurring: frequency !== BookingFrequency.OneTime,
        date_time: route.params.form.startingDate,
        num_hours: parseFloat(route.params.form.hours) + Number(form.extraHours.value),
        supply: supplyId
    })

    const {
        onCreateJob,
        isLoading: isCreatingJob,
        addressCreated,
        paymentMethod,
        isDisabled,
        handleSubmit,
        isLoadingAddressForm,
        setPaymentMethod,
        fetchPostalCodes,
        addressForm,
        isNewCardModalOpen,
        setIsNewCardModalOpen,
        onCreateGuestJob,
        isMobileOtpModalOpen,
        setIsMobileOtpModalOpen
    } = useCleaningCreateJob(submit, ScreenNames.CleaningDetails, route.params.postalcode)

    useEffect(() => {
        setIsLoading(isLoadingPromotion)
    }, [isLoadingPromotion])

    const useDebouncedCallback = (callback, delay) => {
        const argsRef = useRef<undefined | Array<undefined>>(undefined)
        const timeout = useRef<NodeJS.Timeout | undefined>()

        useEffect(() => () => {
            if (timeout.current) {
                clearTimeout(timeout.current)
            }
        }, [])

        return useCallback((...args) => {
            argsRef.current = args

            if (timeout.current) {
                clearTimeout(timeout.current)
            }

            timeout.current = setTimeout(() => {
                if (argsRef.current) {
                    callback(...argsRef.current)
                }
            }, delay)
        }, [callback, delay])
    }

    const debouncedRequestJobQuotation = useDebouncedCallback(payload => {
        requestJobQuotation(payload)
    }, 1000)

    useEffect(() => {
        const { form: { hours } } = route.params
        const numberOfHours = parseFloat(hours) + Number(form.extraHours.value)
        const requestQuotationPayload: CleaningQuotationRequest = {
            numberOfHours,
            frequency: isRecurring ? ServicesPlan.Recurring : ServicesPlan.OneTime,
            slotId: dynamicPriceSelected?.slotId
        }

        if (promotionPackage?.promotion) {
            requestQuotationPayload.promo = {
                code: promotionPackage?.promotion.promo_code,
                auth: promotionPackage?.promotion.promo_auth
            }
        }

        debouncedRequestJobQuotation(requestQuotationPayload)

    }, [frequency, route.params.form.hours, form.extraHours.value, promotionPackage?.promotion.promo_code, dynamicPriceSelected?.slotId])

    useEffect(() => {
        if (promotionPackage) {
            onRequestPromotion(form.promoCode.value, true)
        }
    }, [form.extraHours.value])

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingSummary({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                amount: route.params?.price as number,
                serviceType: ServiceType.Cleaning
            })
        }
    }, [segmentSession])

    useEffect(() => {
        if(jobQuotation) {
            setTotalPrice(jobQuotation?.total?.value)
            setOriginalPrice(jobQuotation?.total?.valueBeforeDiscount ?? 0)
        }
    }, [jobQuotation])

    const onRequestPromotion = (code: string, isAlreadyApplied?: boolean) => {
        requestPromotion(
            code,
            {
                is_recurring: frequency !== BookingFrequency.OneTime,
                date_time: route.params.form.startingDate,
                num_hours: parseFloat(route.params.form.hours) + Number(form.extraHours.value),
                supply: supplyId
            },
            isAlreadyApplied
        )
    }

    if (paymentView) {
        return (
            <BookingPaymentView
                disabled={isDisabled}
                submit={handleSubmit}
                onRemovePromoCode={removePromoCode}
                isRecurring={isRecurring}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                promotionPackage={promotionPackage}
                setPaymentView={setPaymentView}
                service={T.screens.cleaning.title}
                setPaymentMethod={setPaymentMethod}
                selectedPaymentMethod={paymentMethod}
                discount={promotionPackage?.promotion.percent || promotionPackage?.promotion.package.credit}
                promotionType={promotionPackage?.promotion.promo_type}
                requestPromotion={onRequestPromotion}
                promoCode={form.promoCode}
            />
        )
    }

    const onSubmitGuestBookingData = data => {
        const { form: jobForm } = route?.params ?? {}
        const payload: CreateCleaningGuestJobRequest = {
            startDate: jobForm?.startingDate.toString(),
            typeOfProperty: jobForm?.propertyType,
            numberOfHours:  +jobForm?.hours + +form?.extraHours?.value,
            ironing: form?.ironing?.value,
            petsAtHome: !!form?.pets?.value,
            messageToSupplier: form?.comment?.value,
            customerName: data?.name?.value,
            phoneNumber: data?.country?.value?.code + data?.phoneNumber?.value,
            address:  data?.address?.value,
            promoCode: form?.promoCode?.value
        }
        onCreateGuestJob(payload)
    }

    return (
        <React.Fragment>
            <MobileOtpModal
                isOpen={isMobileOtpModalOpen}
                onClose={() => setIsMobileOtpModalOpen(false)}
                onOtpVerify={() => setIsMobileOtpModalOpen(false)}
            />
            <PaymentNewCardModal
                isOpen={isNewCardModalOpen}
                onClose={() => setIsNewCardModalOpen(false)}
            />
            <CleaningSummaryView
                submit={handleSubmit}
                isRecurring={isRecurring}
                disabled={isDisabled}
                fetchPostalCodes={fetchPostalCodes}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                extraHours={form.extraHours.value}
                setPaymentView={setPaymentView}
                promotionPackage={promotionPackage}
                onRemovePromoCode={removePromoCode}
                setPaymentMethod={setPaymentMethod}
                requestPromotion={onRequestPromotion}
                selectedPaymentMethod={paymentMethod}
                formShape={route.params.form}
                addressForm={addressForm}
                addonsForm={formReturn}
                jobQuotation={jobQuotation}
                onSubmitGuestBooking={onSubmitGuestBookingData}
                isMainPageLoading={isJobQuotationLoading || isCreatingJob}
            />
        </React.Fragment>
    )
}
