import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type Props = {
    children: ReactNode,
}

export const FooterBreakdownBodyRow = ({ children }: Props) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}))
