import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { MutateOptions } from '@tanstack/query-core'
import { useNavigation } from '@react-navigation/native'
import { R } from 'lib/utils'
import { Address, PaymentType } from 'lib/models'
import { DetailsScreensNames, ScreenNames, StackProps } from 'lib/routing'
import { isNative } from 'lib/common'
import { useQueryHelpers, useSegmentSession, useTranslations } from 'lib/hooks'
import { authActions } from 'features/auth'
import { useUserNewAddress } from 'features/user'
import { useAddNewCreditCard } from 'features/payments'
import {
    NotificationType,
    Nullable,
    SupplyID,
    Response,
    ErrorResponse
} from 'lib/types'
import {
    useAddressAtom,
    useCrossSaleBookingRecommendationsAtom,
    useDynamicPriceAtom,
    useResetCrossSaleBookingRecommendationsAtom,
    useResetDynamicPriceSelectedAtom,
    useResetFormAtom,
    useToastAtom,
    useUserAtom,
    useUserTokenAtom
} from 'lib/atoms'
import { createCleaningJob, createGuestCleaningJob } from 'features/bookings/actions'
import {
    CreateCleaningJobRequest,
    CreateJobResponse,
    CreateCleaningGuestJobRequest,
    CreateGuestJobResponse
} from 'features/bookings/types'
import { useDynamicPrice } from 'features/bookings/hooks'
import { ErrorCode } from 'features/jobs/types'
import { useNativeUserTokenAtom } from 'lib/native/atoms'

export const useCleaningCreateJob = (submit: VoidFunction, detailsScreenName: DetailsScreensNames, postalCode?: string) => {
    const T = useTranslations()
    const [legacyToken] = useUserTokenAtom()
    const [nativeToken] = useNativeUserTokenAtom()
    const token = legacyToken || nativeToken
    const [user, setUser] = useUserAtom()
    const [dynamicPriceData] = useDynamicPriceAtom()
    const { requestDynamicPrice } = useDynamicPrice()
    const [,resetDynamicPriceSelectedAtom] = useResetDynamicPriceSelectedAtom()
    const [, setCrossSaleBookingRecommendations] = useCrossSaleBookingRecommendationsAtom()
    const [, setResetCrossSaleBookingRecommendations] = useResetCrossSaleBookingRecommendationsAtom()
    const { clearSession } = useSegmentSession()
    const navigation = useNavigation<StackProps<ScreenNames>>()
    const [, setToastMessage] = useToastAtom()
    const [, setResetForm] = useResetFormAtom()
    const { onRequestError } = useQueryHelpers()
    const [address, setSavedAddress] = useAddressAtom()
    const [paymentMethod, setPaymentMethod] = useState<PaymentType>(PaymentType.Card)
    const [defaultCard] = user.payment_providers
        ? user.payment_providers.filter(item => item.default)
        : []
    const jobUniqueId = `${uuidv4()}-${new Date().getTime()}`
    const { isLoading: isCreatingJob, mutate } = createCleaningJob()
    const { isLoading: isGuestCreatingJob, mutate: guestCleaningJob } = createGuestCleaningJob()
    const { mobileSubmit, isLoading: isLoadingNewCard } = useAddNewCreditCard(R.T)
    const [isNewCardModalOpen, setIsNewCardModalOpen] = useState(false)
    const [isMobileOtpModalOpen, setIsMobileOtpModalOpen] = useState(false)
    const [addressCreated, setAddressCreated] = useState<Nullable<Address>>(null)
    const { mutate: getMe, isLoading: isLoadingUserProfile } = authActions.useGetMe()
    const {
        submit: submitAddressForm,
        isLoading: isLoadingAddressForm,
        isFilled,
        form: addressForm,
        fetchPostalCodesWithDebounce,
        hasError
    } = useUserNewAddress({
        postcode: address.postcode || postalCode,
        onSuccess: setAddressCreated
    })

    const isLoading = isCreatingJob || isGuestCreatingJob || isLoadingNewCard || isLoadingUserProfile

    const onCreateJob = (supplyId: SupplyID, data: CreateCleaningJobRequest, onSuccess: VoidFunction) => {
        if(isLoading) {
            return
        }

        const mutateOptions: MutateOptions<Response<CreateJobResponse>, ErrorResponse, CreateCleaningJobRequest> = {
            onSuccess: response => {
                getMe(
                    {
                        token
                    },
                    {
                        onSuccess: ({ data: { profile } }) => {
                            onSuccess()
                            clearSession()

                            setUser(profile)
                            setResetForm(true)
                            setSavedAddress({} as Address)

                            if (isNative) {
                                setToastMessage({
                                    isModalOnly: true,
                                    message: response.data.popup.success.message,
                                    type: NotificationType.Success
                                })

                                return navigation.navigate(ScreenNames.Bookings, {
                                    screenName: detailsScreenName,
                                    bookingId: response.data.jobIdName,
                                    jobId: response.data.jobId
                                })

                            }

                            navigation.replace(ScreenNames.BookingSuccess, {
                                bookingId: response.data.jobIdName
                            })
                            setToastMessage({
                                message: response.data.popup.success.message,
                                type: NotificationType.Success
                            })
                        },
                        onError: onRequestError
                    }
                )

                const crossSaleBookingRequestRecommendations = response?.data?.crossaleBookingRequestRecommendations || []
                setCrossSaleBookingRecommendations(crossSaleBookingRequestRecommendations)
            },
            onError: error => {
                onRequestError(error)
                const errorCode = (error as ErrorResponse)?.errors?.error_code

                if(
                    errorCode === ErrorCode.SlotLimitExceeded ||
                    errorCode === ErrorCode.NoSlotsFound
                ) {
                    resetDynamicPriceSelectedAtom()
                    requestDynamicPrice(dynamicPriceData.month, dynamicPriceData.day)
                }
            }
        }
        setResetCrossSaleBookingRecommendations()
        mutate(data, mutateOptions)
    }

    const onCreateGuestJob = (data: CreateCleaningGuestJobRequest) => {
        if(isLoading) {
            return
        }

        const mutateOptions: MutateOptions<Response<CreateGuestJobResponse>, ErrorResponse, CreateCleaningGuestJobRequest> = {
            onSuccess: response => {
                if (isNative) {
                    setToastMessage({
                        isModalOnly: true,
                        message: T.components.bookingSummary.bookingSuccessful,
                        type: NotificationType.Success
                    })

                    return navigation.navigate(ScreenNames.Bookings, {
                        screenName: detailsScreenName,
                        bookingId: response.data.bookingId,
                        jobId: response.data.bookingId
                    })
                }

                navigation.replace(ScreenNames.BookingSuccess, {
                    bookingId: response.data.bookingId
                })

                setToastMessage({
                    message: T.components.bookingSummary.bookingSuccessful,
                    type: NotificationType.Success
                })
            },
            onError: onRequestError
        }

        guestCleaningJob(data, mutateOptions)
    }

    const handleSubmit = () => {
        if (token && !user.phone) {
            return setIsMobileOtpModalOpen(true)
        }

        if(isLoading) {
            return
        }

        if (paymentMethod === PaymentType.Card && !defaultCard) {
            return isNative
                ? mobileSubmit()
                : setIsNewCardModalOpen(true)
        }

        if (!address.postcode) {
            return submitAddressForm()
        }

        submit()
    }

    useEffect(() => {
        if (token && !user.phone) {
            setIsMobileOtpModalOpen(true)
        }
    }, [token, user.phone])

    // This is so we need first create new address on API and then submit new job with that address
    useEffect(() => {
        if (addressCreated && user.phone) {
            submit()
        }
    }, [addressCreated])

    return {
        jobUniqueId,
        onCreateJob,
        onCreateGuestJob,
        handleSubmit,
        setPaymentMethod,
        addressForm,
        fetchPostalCodes: fetchPostalCodesWithDebounce,
        isNewCardModalOpen,
        setIsNewCardModalOpen,
        isMobileOtpModalOpen,
        setIsMobileOtpModalOpen,
        addressCreated,
        paymentMethod,
        isLoadingAddressForm,
        isDisabled: (!address.postcode) && (!isFilled || hasError),
        isLoading: isCreatingJob || isLoadingUserProfile || isLoadingNewCard || isGuestCreatingJob
    }
}
