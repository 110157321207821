import React, { useEffect, useState } from 'react'
import { Field, useForm } from '@codegateinc/react-form-builder-v2'
import { UploadedPhoto } from 'lib/components'
import { useSegmentSession, useTranslations, useSessionData } from 'lib/hooks'
import { PaymentNewCardModal } from 'features/payments'
import { PaymentOption } from 'lib/models'
import { useAddressAtom, useDynamicServiceAtom, useResetBookingJobQuotationAtom, useUserTokenAtom } from 'lib/atoms'
import { Segment, ServiceType } from 'lib/analytics'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { useAirconCreateJob, useBookingPromoCode, useJobQuotation } from '../hooks'
import { AirConditionSummaryView, BookingPaymentView } from '../components'
import { ServiceAddonsFormShape, useServiceAddonsForm } from '../forms'
import { AirConditionService, SupplyID } from 'lib/types'
import { CreateAirconJobRequest } from '../types'
import { MobileOtpModal } from 'features/native/bookings/components'

type AirConditionSummaryScreenProps = {
    navigation: NavigationProps<ScreenNames.AirConditionSummary>,
    route: NavigationParams<ScreenNames.AirConditionSummary>
}

export const AirConditionSummaryScreen: React.FunctionComponent<AirConditionSummaryScreenProps> = ({ route }) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const [address] = useAddressAtom()
    const { segmentSession } = useSegmentSession()
    const [uploadedPhotos, setUploadedPhotos] = useState<Array<UploadedPhoto>>([])
    const [isUploadingPhotos, setIsUploadingPhotos] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [paymentView, setPaymentView] = useState(false)
    const [config] = useDynamicServiceAtom(route.params.supplyId)
    const [, setResetJobQuotationTypeAtom] = useResetBookingJobQuotationAtom()
    const supplyId = config._id
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.ACService)
    const [originalPrice, setOriginalPrice] = useState(0)
    const sessionData = useSessionData()
    const isOneTime = route.params.form.serviceOption.frequency === AirConditionService.OneTime

    const { form, submit, resetForm } = useForm<ServiceAddonsFormShape>(useServiceAddonsForm(), {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSuccess: ({ ironing, comment, pets }) => {
            setResetJobQuotationTypeAtom()
            // const linkUploads = uploadedPhotos.reduce((acc, nextValue) => ({
            //     ...acc,
            //     // TODO Find out why we need this, on mobile it maps to [file.key]: downloadURL, but on web we do not have file key.
            //     [nextValue.fileUUID]: nextValue.downloadUrl || ''
            // }), {})

            const createJobPayload: CreateAirconJobRequest = {
                token,
                ...sessionData,
                bookingSessionId: segmentSession.id,
                booking: {
                    messageToSupplier: comment,
                    date: route.params.form.bookingTime,
                    location: addressCreated ?? address,
                    frequency: route.params.form.serviceOption.frequency || AirConditionService.OneTime,
                    paymentType: paymentMethod,
                    contractType: (route.params.form.serviceOption.frequency === AirConditionService.Contract ? route.params.selectedContract : route.params.form.serviceOption.frequency)!,
                    serviceType: route.params.form.serviceOption.value,
                    numberOfUnits: Number(route.params.form.numberOfAirConditioners),
                    petOnPresmises: Boolean(pets),
                    photos: uploadedPhotos.map(photo => photo.downloadUrl || ''),
                    isDuctedAC: form.acDuctMount.value,
                    requiresLadder: form.acCeiling.value
                }
            }

            if (promotionPackage?.promotion) {
                createJobPayload.promo = {
                    code: promotionPackage?.promotion.promo_code,
                    auth: promotionPackage?.promotion.promo_auth
                }
            }

            onCreateJob(
                route.params.supplyId,
                createJobPayload,
                () => {
                    resetForm()
                    removePromoCode()
                }
            )
        }
    })
    const {
        jobUniqueId,
        onCreateJob,
        isLoading: isCreatingJob,
        addressCreated,
        paymentMethod,
        isDisabled,
        handleSubmit,
        isLoadingAddressForm,
        setPaymentMethod,
        fetchPostalCodes,
        addressForm,
        isNewCardModalOpen,
        setIsNewCardModalOpen,
        isMobileOtpModalOpen,
        setIsMobileOtpModalOpen
    } = useAirconCreateJob(submit, ScreenNames.AirConditionDetails, route.params.postalcode)
    const { isLoading: isLoadingPromotion, promotionPackage, requestPromotion, removePromoCode } = useBookingPromoCode()
    const isLoading = isLoadingPromotion || isCreatingJob
    const onRequestPromotion = (code: string) => {
        requestPromotion(code, {
            jobUniqueId,
            photos: [],
            petOnPresmises: Boolean(form.pets.value),
            ironing: Boolean(form.ironing.value),
            isAllImageUploaded: true,
            message_to_supplier: form.comment.value,
            linkUploads: {},
            targetPromoCode: promotionPackage?.promotion.promo_code || null,
            checkingPayment: false,
            date_time: route.params.form.bookingTime,
            location: addressCreated ?? address,
            is_purchasing_services: false,
            supply: supplyId,
            is_recurring: !isOneTime,
            paymentType: paymentMethod,
            paymentOptions: [PaymentOption.Card, PaymentOption.SendPay],
            num_air_con: String(route.params.form.numberOfAirConditioners),
            service_type: route.params.form.serviceOption.value
        })
    }

    useEffect(() => {
        if (jobQuotation && jobQuotation.estimation) {
            const { value } = jobQuotation.estimation
            setTotalPrice(value)
            setOriginalPrice(jobQuotation?.total?.valueBeforeDiscount ?? 0)
        }
    }, [jobQuotation])

    useEffect(() => {
        if (!route.params.form.numberOfAirConditioners || !route.params.form.serviceOption.frequency) {
            return
        }

        const promoPayload = {
            code: promotionPackage?.promotion?.promo_code,
            auth: promotionPackage?.promotion?.promo_auth
        }

        const frequency = route.params.form.serviceOption?.frequency

        const data = {
            numberOfUnits: route.params.form.numberOfAirConditioners,
            contractType:  frequency === AirConditionService.Contract ? route.params.selectedContract : frequency,
            frequency: frequency?.toLowerCase(),
            isDuctedAC: form.acDuctMount.value,
            requiresLadder: form.acCeiling.value,
            messageToSupplier: form.comment.value,
            ...(promoPayload.code && { promo: promoPayload })
        }

        requestJobQuotation(data)

    }, [promotionPackage?.promotion.promo_code, form.acCeiling.value, form.acDuctMount.value])

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingSummary({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                amount: totalPrice,
                serviceType: ServiceType.AirCondition
            })
        }
    }, [segmentSession])

    if (paymentView) {
        return (
            <BookingPaymentView
                showBillingMessage
                disabled={isDisabled}
                submit={handleSubmit}
                onRemovePromoCode={removePromoCode}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                promotionPackage={promotionPackage}
                setPaymentView={setPaymentView}
                service={T.screens.airCondition.title}
                setPaymentMethod={setPaymentMethod}
                selectedPaymentMethod={paymentMethod}
                discount={promotionPackage?.promotion.percent || promotionPackage?.promotion.package.credit}
                promotionType={promotionPackage?.promotion.promo_type}
                requestPromotion={onRequestPromotion}
                promoCode={form.promoCode}
            />
        )
    }

    return (
        <React.Fragment>
            <MobileOtpModal
                isOpen={isMobileOtpModalOpen}
                onClose={() => setIsMobileOtpModalOpen(false)}
                onOtpVerify={() => setIsMobileOtpModalOpen(false)}
            />
            <PaymentNewCardModal
                isOpen={isNewCardModalOpen}
                onClose={() => setIsNewCardModalOpen(false)}
            />
            <AirConditionSummaryView
                submit={handleSubmit}
                disabled={isDisabled}
                onUploadSuccess={data => {
                    setIsUploadingPhotos(false)
                    setUploadedPhotos(prevState => [...prevState, ...data])
                }}
                onPhotoRemove={uuid => setUploadedPhotos(prevState => prevState.filter(prevFile => uuid !== prevFile.fileUUID))}
                isUploadingPhotos={isUploadingPhotos}
                setIsUploadingPhotos={setIsUploadingPhotos}
                fetchPostalCodes={fetchPostalCodes}
                setPaymentMethod={setPaymentMethod}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                setPaymentView={setPaymentView}
                originalPrice={originalPrice}
                promotionPackage={promotionPackage}
                onRemovePromoCode={removePromoCode}
                requestPromotion={onRequestPromotion}
                selectedPaymentMethod={paymentMethod}
                formShape={route.params.form}
                addressForm={addressForm}
                form={form as Record<keyof ServiceAddonsFormShape, Field<string & boolean>>}
                jobQuotation={jobQuotation}
                sessionsGenerated={route.params.sessionsGenerated}
                bookingTime={route.params.form.bookingTime}
            />
        </React.Fragment>
    )
}
