export const INCOMPLETE_PROFILE = 'incomplete-profile'
export const CLEANING_TOOLS_READY = 'cleaning-tools-ready'
export const CLEANING_HELPER_ARRIVED = 'cleaning-helper-arrived'
export const ROUTINE_CLEANING = 'routine-cleaning'
export const PRIMARY = 'primary'
export const SECONDARY = 'secondary'
export const RATINGPOPUP = 'RatingPopup'
export const RECEIPTPOPUP = 'ReceiptPopup'
export const POPUP = 'popup'
export const REDIRECT = 'redirect'
export const URL = 'url'
