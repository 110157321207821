import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { ServiceAddonsFields } from './forms'

export const useServiceAddonsForm = () => {
    const T = useTranslations()

    const extraHours = useField<string>({
        key: ServiceAddonsFields.ExtraHours,
        initialValue: '0',
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.cleaningSummary.formFields.extraHours.label,
        placeholder: T.screens.cleaningSummary.formFields.extraHours.placeholder
    })

    const ironing = useField<boolean>({
        key: ServiceAddonsFields.Ironing,
        initialValue: false,
        isRequired: false,
        label: T.screens.cleaningSummary.formFields.ironing.label
    })

    const cleaningSupplies = useField<boolean>({
        key: ServiceAddonsFields.CleaningSupplies,
        initialValue: false,
        isRequired: false,
        label: T.screens.cleaningSummary.formFields.cleaningSupplies.label
    })

    const pets = useField<string>({
        key: ServiceAddonsFields.Pets,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.cleaningSummary.formFields.pets.placeholder
    })

    const comment = useField<string>({
        key: ServiceAddonsFields.Comment,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.cleaningSummary.formFields.comment.placeholder
    })

    const promoCode = useField<string>({
        key: ServiceAddonsFields.PromoCode,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.cleaningSummary.formFields.promoCode.placeholder
    })

    const allergies = useField<boolean>({
        key: ServiceAddonsFields.Allergies,
        initialValue: false,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.cleaningSummary.formFields.allergies.label
    })

    const allergiesComment = useField<string>({
        key: ServiceAddonsFields.AllergiesComment,
        initialValue: '',
        isRequired: false,
        validateOnBlur: false,
        placeholder: T.screens.cleaningSummary.formFields.allergiesComment.placeholder
    })

    const acDuctMount = useField<boolean>({
        key: ServiceAddonsFields.ACDuctMount,
        initialValue: false,
        isRequired: false,
        label: T.screens.airCondition.acDuctMount
    })

    const acCeiling = useField<boolean>({
        key: ServiceAddonsFields.ACCeiling,
        initialValue: false,
        isRequired: false,
        label: T.screens.airCondition.acCeilingHeight
    })

    return {
        pets,
        comment,
        ironing,
        extraHours,
        cleaningSupplies,
        allergies,
        allergiesComment,
        promoCode,
        acDuctMount,
        acCeiling
    }
}
