import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { Divider } from '@propertyguru/hive-divider'
import { Icon } from '@propertyguru/hive-icon'
import * as Icons from '@propertyguru/hive-icons'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { Chip } from '@propertyguru/hive-chip'
import { Switch } from '@propertyguru/hive-switch'
import { NotificationType, SupplyID } from 'lib/types'
import { Touchable } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { AlertV2, ChipBox, ScreenHeader } from 'lib/native/components'
import { useNativeJobBookingDetailAtom, useNativeResetDynamicPriceSlotsAtom } from 'lib/native/atoms'
import { useDynamicPriceSlots, useJobQuotation } from 'lib/native/hooks'
import { BookingFrequency, PropertyType } from 'lib/native/models'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { DURATION_OPTIONS_MAX, RECOMMENDED_HOURS_DEFAULT } from 'features/native/bookings/constants'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { useNativeUserAtom } from 'lib/native/atoms/user'
import { useToastAtom } from 'lib/atoms'
import { Footer, IroningGuideModal, PlanGuideModal } from '../components'
import { Segment, SegmentNative } from 'lib/analytics'
import { APP_CONFIG } from 'lib/config'

export const CleaningServiceDetailsScreen: React.FunctionComponent = () => {
    useDynamicPriceSlots()
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()
    const { requestJobQuotation } = useJobQuotation(SupplyID.Cleaner)
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const [, resetDynamicPriceSlotsAtom] = useNativeResetDynamicPriceSlotsAtom()
    const config = getServiceStaticConfig(SupplyID.Cleaner)
    const { propertyInfo } = config
    const {
        frequency,
        duration
    } = config.serviceDetails
    const {
        title,
        recommendation,
        ironing,
        options: durationOptions
    } = duration
    const { guarantee } = frequency
    const [recommendationPartOne, recommendationPartTwo] = recommendation.split(' {duration} ')
    const propertyType = jobBookingDetailAtom?.propertyInfo
    const bedrooms = jobBookingDetailAtom?.bedrooms
    const bathrooms = jobBookingDetailAtom?.bathrooms
    const commercialSize = jobBookingDetailAtom?.commercialSize
    const recommendedHours = useMemo(() => {
        try {
            const propertyTypeConfig = propertyInfo.options.find(item => item.key === propertyType)

            if (propertyType === PropertyType.Residential) {
                return propertyTypeConfig.recommendations[`${bathrooms}-${bedrooms}`]
            }

            return propertyTypeConfig?.spec?.options?.find(option => option.label === commercialSize)?.recommendation
        } catch (err) {
            console.error('Error getting recommended hours:', err)

            return RECOMMENDED_HOURS_DEFAULT
        }
    }, [propertyInfo, propertyType, bedrooms, bathrooms, commercialSize])
    const [user] = useNativeUserAtom()
    const bookingCount = user?.bookingCount || 0
    const isFirstTimeBooking = bookingCount === 0
    const [selectedDuration, setSelectedDuration] = useState(recommendedHours)
    const [isDurationOptionsExpanded, setIsDurationOptionsExpanded] = useState(
        () => recommendedHours > durationOptions[DURATION_OPTIONS_MAX - 1].value
    )
    const toggleDurationOptions = () => setIsDurationOptionsExpanded(!isDurationOptionsExpanded)
    const [withIroning, setWithIroning] = useState(false)
    const toggleWithIroning = () => {
        setWithIroning(!withIroning)
        Segment.bookingAdditionalInformationAdded({ information: { type: 'Ironing Service', value: 'true' } })
    }
    const [selectedFrequency, setSelectedFrequency] = useState<BookingFrequency>(BookingFrequency.Recurring)
    const [frequencyError, setFrequencyError] = useState('')
    const [shouldShowPlanGuide, setShouldShowPlanGuide] = useState(false)
    const showPlanGuide = () => {
        setShouldShowPlanGuide(true)
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.CleaningPlanGuideClicked })
    }
    const hidePlanGuide = () => setShouldShowPlanGuide(false)
    const [shouldShowIroningGuide, setShouldShowIroningGuide] = useState(false)
    const showIroningGuide = () => {
        setShouldShowIroningGuide(true)
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.IroningGuideClicked })
    }
    const hideIroningGuide = () => setShouldShowIroningGuide(false)
    const handleNext = () => {
        if (!selectedFrequency) {
            setToastMessage({
                message: T.screens.cleaningServiceDetails.frequencyOptions.error,
                type: NotificationType.Error
            })

            return setFrequencyError(T.screens.cleaningServiceDetails.frequencyOptions.error)
        }

        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            booking: {
                ...jobBookingDetailAtom.booking,
                date: undefined
            }
        })

        navigation.navigate(ScreenNamesNative.CleaningDateTimeNative)
    }

    const onSelectedDuration = (value: number) => {
        setSelectedDuration(value)
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            numberOfHours: value
        })
        Segment.bookingHoursSelected({
            hours: Number(value)
        })
    }

    const onSelectFrequency = (servicePlan: BookingFrequency) => {
        const isRegular = servicePlan === BookingFrequency.Recurring

        if (isRegular) {
            resetDynamicPriceSlotsAtom()
        }

        setSelectedFrequency(servicePlan)
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            frequency: servicePlan
        })
        Segment.cleaningPlanSelected({
            type: servicePlan
        })
    }

    useEffect(() => {
        if (
            selectedFrequency &&
            jobBookingDetailAtom?.frequency &&
            selectedFrequency !== jobBookingDetailAtom?.frequency
        ) {
            setSelectedFrequency(jobBookingDetailAtom?.frequency as BookingFrequency)
        }
    }, [
        jobBookingDetailAtom?.frequency
    ])

    useEffect(() => {
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            numberOfHours: selectedDuration,
            ironing: withIroning,
            frequency: selectedFrequency
        })
    }, [selectedDuration, withIroning, selectedFrequency])

    useEffect(() => {
        if(jobBookingDetailAtom?.frequency) {
            requestJobQuotation()
        }
    }, [
        jobBookingDetailAtom?.frequency,
        jobBookingDetailAtom?.numberOfHours,
        jobBookingDetailAtom?.slotId
    ])

    useEffect(() => {
        setFrequencyError('')
    }, [selectedFrequency])

    useEffect(() => {
        if (
            jobBookingDetailAtom?.frequency !== BookingFrequency.OneTime &&
            APP_CONFIG?.DISABLE_SUBSCRIPTION_SERVICE?.CLEANING
        ) {
            setSelectedFrequency(BookingFrequency.OneTime)
        }
    }, [jobBookingDetailAtom?.frequency])

    return (
        <View style={{ ...styles.container, paddingTop: insets.top }}>
            <ScreenHeader>
                <ScreenHeader.Button icon={Icons.ChevronLeftOutline} onPress={navigation.goBack} />
                <ScreenHeader.Title>
                    {T.screens.cleaningServiceDetails.pageTitle}
                </ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <ScrollView style={styles.main}>
                <View style={styles.wrapper}>
                    <View style={styles.section}>
                        <Text typography="label/m" color="text/active/primary">
                            {title}
                        </Text>
                        <AlertV2>
                            <AlertV2.Icon>
                                <Icon icon={Icons.Ai2StarsSparklesOutline} color="icon/warning/primary" width={24} height={24} />
                            </AlertV2.Icon>
                            <AlertV2.Body>
                                <Text typography="caption/xs" color="text/warning/primary">
                                    {recommendationPartOne}{' '}
                                    <Text typography="caption/s" color="text/warning/primary">
                                        {recommendedHours} {T.common.hour}
                                    </Text>{' '}
                                    {recommendationPartTwo}
                                </Text>
                            </AlertV2.Body>
                        </AlertV2>
                        <View style={styles.chips}>
                            {
                                durationOptions.slice(0, isDurationOptionsExpanded ? durationOptions.length : DURATION_OPTIONS_MAX).map((option, index) => (
                                    <View key={index} style={styles.chip}>
                                        <Chip
                                            active={selectedDuration === option.value}
                                            text={option.label}
                                            onPress={() => onSelectedDuration(option.value)}
                                        />
                                    </View>
                                ))
                            }
                            <View style={styles.chip}>
                                <Chip
                                    text={isDurationOptionsExpanded
                                        ? T.screens.cleaningServiceDetails.durationOptions.viewLess
                                        : T.screens.cleaningServiceDetails.durationOptions.viewMore}
                                    disabled={selectedDuration > durationOptions[DURATION_OPTIONS_MAX - 1].value}
                                    onPress={toggleDurationOptions}
                                />
                            </View>
                        </View>
                        <View style={styles.sectionIroning}>
                            <Touchable style={styles.switchContainer} onPress={toggleWithIroning} >
                                <Text typography="label/s" color="text/active/primary">
                                    {ironing.title}{' '}
                                    <Text typography="body/xs">
                                        {ironing.note}
                                    </Text>
                                </Text>
                                <Switch checked={withIroning} onCheckChange={toggleWithIroning} />
                            </Touchable>
                            {withIroning && (
                                <>
                                    <Text typography="body/xs" color="text/active/secondary">
                                        {ironing.description}
                                    </Text>
                                    <Touchable style={styles.buttonText} onPress={showIroningGuide}>
                                        <Text typography="caption/s" color="text/active/primary">
                                            {ironing.guideLinkText}
                                        </Text>
                                        <Icon icon={Icons.ChevronRightSmallOutline} color="icon/active/primary" width={16} height={16} />
                                    </Touchable>
                                </>
                            )}
                        </View>
                    </View>
                    <View style={styles.section}>
                        {
                            APP_CONFIG?.DISABLE_SUBSCRIPTION_SERVICE?.CLEANING ? null : (
                                <>
                                    <View style={styles.titleSection}>
                                        <Text typography="label/m" color="text/active/primary">
                                            {frequency.title}
                                        </Text>
                                        <Text typography="body/xs" color="text/active/secondary">
                                            {frequency.description}
                                        </Text>
                                    </View>
                                    <View style={styles.sectionFrequency}>
                                        {frequencyError && (
                                            <Text typography="caption/xs" color="text/error/primary">
                                                {frequencyError}
                                            </Text>
                                        )}
                                        <View style={styles.chipBoxes}>
                                            {
                                                frequency.options.map(frequencyOption => {
                                                    const frequencyId = frequencyOption.id
                                                    const frequencyValue = frequencyOption.value
                                                    const frequencyBadgeText = frequencyOption.bestValueText

                                                    return (
                                                        <ChipBox
                                                            key={frequencyId}
                                                            active={selectedFrequency === frequencyValue}
                                                            text={frequencyOption.label}
                                                            icon={Icons[frequencyOption.icon]}
                                                            iconActive={Icons[frequencyOption.iconActive]}
                                                            onPress={() => onSelectFrequency(frequencyValue)}
                                                            badgeProps={frequencyBadgeText && {
                                                                label: frequencyBadgeText,
                                                                type: 'primary',
                                                                color: 'green',
                                                                size: 'small'
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </View>
                                        <Touchable style={styles.buttonText} onPress={showPlanGuide}>
                                            <Text typography="caption/s" color="text/active/primary" underline>
                                                {frequency.guideLinkText}
                                            </Text>
                                            <Icon icon={Icons.ChevronRightSmallOutline} color="icon/active/primary" width={16} height={16} />
                                        </Touchable>
                                    </View>
                                </>
                            )}

                        {isFirstTimeBooking && (
                            <AlertV2>
                                <AlertV2.Body>
                                    <AlertV2.Body.Title>
                                        <Icon icon={Icons[guarantee.icon]} color="icon/warning/primary" width={16} height={16} />
                                        <Text typography="caption/s" color="text/warning/primary">
                                            {guarantee.title}
                                        </Text>
                                    </AlertV2.Body.Title>
                                    <AlertV2.Body.Description>
                                        <Text typography="body/xs" color="text/warning/primary">
                                            {guarantee.description}
                                        </Text>
                                    </AlertV2.Body.Description>
                                </AlertV2.Body>
                            </AlertV2>
                        )}
                    </View>
                </View>
            </ScrollView>
            <Footer style={{ paddingBottom: insets.bottom }}>
                <Footer.Buttons>
                    <Button.Icon icon={Icons.ArrowLeftOutline} onPress={navigation.goBack} />
                    <Button text={T.screens.cleaningServiceDetails.footerButtons.next} style={styles.footerBtnNext} onPress={handleNext} />
                </Footer.Buttons>
            </Footer>
            <PlanGuideModal isOpen={shouldShowPlanGuide} onClose={hidePlanGuide} />
            <IroningGuideModal isOpen={shouldShowIroningGuide} onClose={hideIroningGuide} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    main: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    },
    wrapper: {
        rowGap: theme.utils.hive.spacing('x2')
    },
    section: {
        backgroundColor: theme.colors.white,
        paddingVertical: theme.utils.hive.spacing('x12'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        rowGap: theme.utils.hive.spacing('x6')
    },
    sectionIroning: {
        rowGap: theme.utils.hive.spacing('x4')
    },
    titleSection: {
        rowGap: theme.utils.hive.spacing('x3')
    },
    sectionFrequency: {
        rowGap: theme.utils.hive.spacing('x3')
    },
    chips: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: theme.utils.hive.spacing('x2'),
        marginHorizontal: -theme.utils.hive.spacing('x1')
    },
    chip: {
        width: '33.33%',
        paddingHorizontal: theme.utils.hive.spacing('x1')
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttonText: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.utils.hive.spacing('x1'),
        paddingVertical: theme.utils.hive.spacing('x2')
    },
    chipBoxes: {
        flexDirection: 'row',
        columnGap: theme.utils.hive.spacing('x2')
    },
    footerBtnNext: {
        flex: 1
    }
}))
