import { useFormatPrice } from 'lib/hooks'
import { R } from 'lib/utils'
import { PestControlPackage, PestControlSession } from '../../types'

type GetSummaryEntriesProps = {
    showPrices?: boolean,
    services: Array<PestControlSession>,
    packages: Array<PestControlPackage>
}

export const useGetSummaryEntries = ({ showPrices, services, packages }: GetSummaryEntriesProps) => {
    const formatPrice = useFormatPrice()
    const uniqueServices = R.collectBy( // present services with the same pest as one
        item => item.pestCategory|| '',
        services.filter(service => service.isSelected) || []
    ).map(sameServices => {
        const [firstService] = sameServices
        const cumulativeServiceInfo = sameServices.reduce((acc, item) => ({
            price: acc.price + item.number_of_treatments * item.price_per_treatment,
            numberOfTreatments: acc.numberOfTreatments + Number(item.number_of_treatments)
        }), { price: 0, numberOfTreatments: 0 })

        return showPrices
            ? [`${firstService.pestCategory} (x${cumulativeServiceInfo.numberOfTreatments})`, formatPrice(cumulativeServiceInfo.price)]
            : ['', `${firstService.pestCategory} (x${cumulativeServiceInfo.numberOfTreatments})`]
    })
    const [firstService, ...otherServices] = uniqueServices || []
    const packagesEntries = packages?.map(packageItem => showPrices
        ? [`${packageItem.package_name} - ${packageItem.title}`, formatPrice(packageItem.cost * packageItem.sessions)]
        : [packageItem.package_name, packageItem.title]
    ) || []

    return {
        firstService,
        otherServices,
        packagesEntries
    }
}
