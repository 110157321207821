import { NotificationType } from 'lib/types'
import { CheckRadioCircleCheckboxCheckCheckmarkConfirmOutline, ErrorWarningAlertOutline, InfoCircleTooltipOutline } from '@propertyguru/hive-icons'

export const useToastIcon = (type: NotificationType) => {
    switch (type) {
        case NotificationType.Success:
            return CheckRadioCircleCheckboxCheckCheckmarkConfirmOutline
        case NotificationType.Error:
            return ErrorWarningAlertOutline
        default:
            return InfoCircleTooltipOutline
    }
}
