import { useMutation } from '@tanstack/react-query'
import { AirConConfig, CleaningConfig, DeepCleaningConfig, CookingConfig, LaundryConfig, HandymanConfig, PestControlConfig, TaskAndErrandsConfig, MoversAndPackersConfig } from 'features/bookings'
import { useExternalFetcher } from 'lib/api'
import { HttpMethod } from 'lib/types'
import { APP_CONFIG } from 'lib/config'

export const getAirconServiceConfig = () => {
    const fetcher = useExternalFetcher<AirConConfig>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/aircon/configs`)

    return useMutation(fetcher)
}

export const getCleanerServiceConfig = () => {
    const fetcher = useExternalFetcher<CleaningConfig>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/cleaning/configs`)

    return useMutation(fetcher)
}

export const getDeepCleaningServiceConfig = () => {
    const fetcher = useExternalFetcher<DeepCleaningConfig>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/deep-cleaning/configs`)

    return useMutation(fetcher)
}

export const getCookingServiceConfig = () => {
    const fetcher = useExternalFetcher<CookingConfig>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/cooking/configs`)

    return useMutation(fetcher)
}

export const getLaundryServiceConfig = () => {
    const fetcher = useExternalFetcher<LaundryConfig>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/laundry/configs`)

    return useMutation(fetcher)
}

export const getHandymanServiceConfig = () => {
    const fetcher = useExternalFetcher<HandymanConfig>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/handyman/configs`)

    return useMutation(fetcher)
}

export const getPestControlServiceConfig = () => {
    const fetcher = useExternalFetcher<PestControlConfig>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/pest-control/configs`)

    return useMutation(fetcher)
}

export const getTaskErrandsServiceConfig = () => {
    const fetcher = useExternalFetcher<TaskAndErrandsConfig>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/tasks-and-errands/configs`)

    return useMutation(fetcher)
}

export const getMoversPackersServiceConfig = () => {
    const fetcher = useExternalFetcher<MoversAndPackersConfig>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/movers-and-packers/configs`)

    return useMutation(fetcher)
}

export const checkIsNewAppEnabled = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.API.URL}/check-feature`)

    return useMutation(fetcher)
}
