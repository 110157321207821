import React, { ReactNode } from 'react'
import { View, ViewStyle } from 'react-native'
import { FooterBreakdownBodyRow } from './BreakdownBodyRow.native'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'

type Props = {
    children: ReactNode,
    style?: ViewStyle
}

const FooterBreakdownBody = ({ children, style }: Props) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={{ ...styles.container, ...style }}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingBottom: theme.utils.hive.spacing('x4'),
        rowGap: theme.utils.hive.spacing('x4')
    }
}))

export default Object.assign(FooterBreakdownBody, {
    Row: FooterBreakdownBodyRow
})
