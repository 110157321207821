import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'

export const CompleteProfileButton: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable style={styles.container}>
            <View>
                <Typography.Label bold>
                    {T.components.subHeader.completeProfile}
                </Typography.Label>
                <Typography.Error forceColor={theme.colors.softBlueGrey}>
                    {T.components.subHeader.completeProfileMessage}
                </Typography.Error>
            </View>
            <View style={styles.icon}>
                <Icons.ArrowInCircle
                    forceColor={theme.colors.night}
                    size={22}
                />
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.colors.sky,
        paddingHorizontal: theme.utils.gap(3),
        borderRadius: theme.components.button.borderRadius
    },
    icon: {
        marginLeft: theme.utils.gap(2)
    }
}))
