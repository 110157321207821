import React from 'react'
import { View } from 'react-native'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { CustomFonts } from 'lib/types'
import { isNative } from 'lib/common'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { ContractTypeOption, ServicePlanOption } from 'features/bookings'

type ServicesPlanItemProps = {
    option: ServicePlanOption,
    index: number,
    isSelected: boolean,
    isLast: boolean,
    onChangeValue: VoidFunction,
    onOpenContractModal?: VoidFunction,
    selectedContractType?: ContractTypeOption
    testID?: string,
    disableChangeContractType?: boolean
}

export const ServicesPlanItem: React.FunctionComponent<ServicesPlanItemProps> = ({
    option,
    index,
    isSelected,
    onChangeValue,
    onOpenContractModal,
    selectedContractType,
    testID,
    disableChangeContractType
}) => {
    const T = useTranslations()
    const isHovered = useSharedValue(0)
    const { styles, theme } = useStyles(stylesheet)
    const fontColor = option.defaultSelected
        ? theme.colors.orange
        : theme.colors.night
    const animatedBackgroundStyles = useAnimatedStyle(() => ({
        backgroundColor: isSelected
            ? theme.colors.yellow
            : interpolateColor(isHovered.value, [0, 1], [theme.colors.white, theme.colors.yellow]),
        borderColor: isSelected
            ? theme.colors.orange
            : interpolateColor(isHovered.value, [0, 1], [theme.colors.silver, theme.colors.orange])
    }))

    const numberOfSessions = option?.numberOfSessions as number || 0
    const contractTypes = option?.contractTypes || []

    const renderContractTypes = () => {
        if (contractTypes.length !== 0) {
            return (
                <>
                    <View style={styles.contractTypeText}>
                        <Typography.Regular style={styles.session} bold>
                            {selectedContractType?.title ?? ''}
                        </Typography.Regular>
                    </View>
                    <Touchable style={styles.changeContractButton} onPress={onOpenContractModal} disabled={disableChangeContractType}>
                        <Typography.Error style={styles.changeContractText}>
                            <Icons.Edit size={12} forceColor={theme.colors.orange} />
                            {T.common.change}
                        </Typography.Error>
                    </Touchable>
                </>
            )
        }

        return `${numberOfSessions} ${ numberOfSessions > 1 ? T.servicesPlan.sessions : T.servicesPlan.session}`
    }

    return (
        <>
            <Touchable
                activeOpacity={1}
                key={index}
                onPress={onChangeValue}
                onHoverIn={() => {
                    isHovered.value = withTiming(1)
                }}
                onHoverOut={() => {
                    isHovered.value = withTiming(0)
                }}
                style={styles.optionWrapper}
                testID={testID}
            >
                <Animated.View
                    style={[
                        styles.option,
                        animatedBackgroundStyles
                    ]}
                >
                    <View style={styles.row}>
                        <View style={styles.infoWrapper}>
                            <View style={styles.titleContainer}>
                                <View style={styles.titleAndPriceContainer}>
                                    <Typography.Label style={styles.title}>
                                        {option?.title}
                                    </Typography.Label>
                                </View>
                            </View>
                        </View>
                        <View style={styles.priceWrapper}>
                            <View style={styles.priceContainer}>
                                {option.price && (
                                    <View style={styles.price}>
                                        <Typography.Regular forceColor={fontColor}>
                                            {option.price.currency}
                                        </Typography.Regular>
                                        {option.price.amount !== 0 && (
                                            <Typography.SmallSubheading forceColor={fontColor}>
                                                {` ${option.price.amount} `}
                                            </Typography.SmallSubheading>
                                        )}
                                        <Typography.Regular forceColor={fontColor}>
                                            {option.price.perUnit}
                                        </Typography.Regular>
                                    </View>
                                )}
                                <View>
                                    <Typography.Error forceColor={theme.colors.orange}>
                                        {option.saverText}
                                    </Typography.Error>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.sessionAndRenewalContainer}>
                        <Typography.Regular style={styles.session} bold>
                            {renderContractTypes()}
                        </Typography.Regular>
                        {
                            option?.bestValueText ? (
                                <View style={styles.bestValueContainer}>
                                    <View style={styles.bestValueWidget}>
                                        <View style={styles.bestValueIcon}>
                                            <Icons.ThumbUp viewBox="0 0 48 48" size={12} forceColor={theme.colors.green} />
                                        </View>
                                        <Typography.Regular style={styles.bestValueLabel}>{option?.bestValueText}</Typography.Regular>
                                    </View>
                                </View>
                            ) : null
                        }

                        {
                            option?.autoRenewal && (
                                <View style={isSelected ? [styles.renewalContainer, styles.selectedRenewalContainer] : styles.renewalContainer}>
                                    <Icons.Refresh size={10} forceColor={theme.colors.darkSlate} />
                                    <Typography.Error style={styles.renewal}>
                                        {T.servicesPlan.autoRenewal}
                                    </Typography.Error>
                                </View>
                            )
                        }
                    </View>
                    <View style={styles.lastRow}>
                        <View style={styles.benefitWrapper}>
                            <View style={styles.description}>
                                {option?.benefits?.map((item, index) => (
                                    <View
                                        key={index}
                                        style={styles.listItem}
                                    >
                                        <View style={styles.iconWrapper}>
                                            <Icons.Check
                                                size={14}
                                                forceColor={theme.colors.orange}
                                            />
                                        </View>
                                        <Typography.Label style={styles.listText}>
                                            {item}
                                        </Typography.Label>
                                    </View>
                                ))}
                            </View>
                        </View>
                    </View>
                </Animated.View>
            </Touchable>
        </>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row'
    },
    lastRow: {
        flexDirection: 'row',
        flex: 1
    },
    sessionAndRenewalContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.utils.gap(0.4)
    },
    renewalContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.utils.gap(0.5),
        backgroundColor: theme.colors.shadow,
        borderRadius: 4,
        paddingHorizontal: theme.utils.gap(1) / 2
    },
    selectedRenewalContainer: {
        backgroundColor: theme.colors.white
    },
    priceWrapper: {
        flex: 0.5,
        justifyContent: 'space-between'
    },
    selectedButton: {
        flex: 0.15
    },
    session: {
        color: theme.colors.grey,
        fontSize: 12
    },
    renewal: {
        color: theme.colors.darkSlate,
        fontSize: 9,
        marginLeft: theme.utils.gap(0.25)
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    bestValueWidget: {
        backgroundColor: theme.colors.lightGreen,
        paddingHorizontal: theme.utils.gap(1) / 2,
        borderRadius: 8,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    bestValueLabel: {
        fontSize: 8,
        fontFamily: CustomFonts.Roboto500,
        color: theme.colors.green,
        marginLeft: theme.utils.gap(isNative ? 0.5 : 0.25)
    },
    bestValueIcon: {
        marginTop: theme.utils.gap(0.1)
    },
    infoWrapper: {
        flex: 0.5,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    benefitWrapper: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    titleContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    titleAndPriceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    priceContainer: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: - theme.utils.gap(0.3)
    },
    bestValueContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: theme.utils.gap(0.5)
    },
    optionWrapper: {
        flex: 1,
        minHeight: 170,
        userSelect: 'none'
    },
    option: {
        flexDirection: 'column',
        flex: 1,
        borderWidth: 1,
        padding: {
            lg: theme.utils.gap(1.5),
            xs: theme.utils.gap(1.75)
        },
        minHeight: 170,
        borderRadius: theme.components.input.borderRadius,
        marginBottom: {
            lg: undefined,
            xs: theme.utils.gap(1)
        },
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    listText: {
        fontSize: 12,
        flexShrink: 1,
        flexWrap: 'wrap'
    },
    title: {
        fontSize: 18,
        fontFamily: CustomFonts.Roboto500,
        lineHeight: 24
    },
    description: {
        flex: 1,
        marginTop: theme.utils.gap(1)
    },
    listItem: {
        paddingRight: theme.utils.gap(1)/5,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1) / 2
    },
    iconWrapper: {
        marginTop: theme.utils.gap(0.2),
        marginRight: theme.utils.gap(0.2),
        height: '100%'
    },
    price: {
        flexDirection: 'row',
        alignItems: 'baseline'
        // width: 'max-content'
    },
    changeContractButton: {
        marginLeft: {
            lg: theme.utils.gap(1)
        },
        flexDirection: 'row'
    },
    changeContractText: {
        color: theme.colors.orange,
        textDecorationLine: 'underline'
    },
    contractTypeText: {
        flexDirection: 'row',
        paddingRight: theme.utils.gap(0.5)
    }
}))
