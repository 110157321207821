import React from 'react'
import { Image, View } from 'react-native'
import { SvgProps } from 'react-native-svg'
import { Surface } from '@propertyguru/hive-surface'
import { Text } from '@propertyguru/hive-text'
import { Touchable } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { CommonCardAction } from 'features/bookings'
import { useHandleCardAction } from 'lib/native/hooks'

export type Svg = React.FunctionComponent<SvgProps>
export type SvgUrl = { url: string }
export type SvgIcon = Svg | SvgUrl

export type ServiceRecommendationCardProps = {
    title: string;
    label: string;
    labelIcon: string;
    image: string;
    action: CommonCardAction;
    imageStyle?: object;
    accessibilityLabel?: string;
}

export const ServiceRecommendationCard: React.FunctionComponent<ServiceRecommendationCardProps> = ({
    title,
    labelIcon,
    label,
    image,
    imageStyle,
    action
}) => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const actionTestIdMap = {
        cleaning: T.accessibility.dashboard.serviceRecommendation.cleaning,
        aircon: T.accessibility.dashboard.serviceRecommendation.aircon,
        handyman: T.accessibility.dashboard.serviceRecommendation.repair,
        contact: T.accessibility.dashboard.serviceRecommendation.contact,
        default: T.accessibility.dashboard.serviceRecommendation.default
    }

    const handleCardAction = useHandleCardAction()

    return (
        <Surface elevation="soft/down/small" style={styles.container}>
            <Touchable
                style={styles.recommendedServiceCard}
                onPress={() => handleCardAction(action)}
                testID={action.url ? actionTestIdMap.contact : actionTestIdMap[action.name?.toLowerCase() ?? 'default']}
            >
                <View style={styles.label}>
                    <Text typography="label/m">{title}</Text>
                    <View style={styles.description}>
                        <Image style={styles.labelImage} source={{ uri: labelIcon }} />
                        <Text typography="caption/xs" style={styles.descText}>
                            {label}
                        </Text>
                    </View>
                </View>
                <View style={styles.imageContainer}>
                    <Image source={{ uri: image }} style={{ ...styles.image, ...imageStyle }} />
                </View>
            </Touchable>
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    recommendedServiceCard: {
        width: 160,
        height: 175,
        backgroundColor: 'white',
        borderRadius: theme.utils.hive.borderRadius('large')
    },
    description: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.utils.hive.spacing('x2')
    },
    descText: {
        color: theme.colors.charcoalSlate
    },
    image: {
        width: 100,
        height: 65,
        resizeMode: 'contain',
        marginTop: theme.utils.hive.spacing('x1')
    },
    imageContainer: {
        position: 'absolute',
        bottom: 5,
        right: 0
    },
    label: {
        padding: theme.utils.hive.spacing('x4')
    },
    labelImage: {
        width: 16,
        height: 16,
        marginRight: theme.utils.hive.spacing('x1')
    },
    container: {
        display: 'flex',
        minHeight: 175,
        width: '100%',
        borderRadius: theme.utils.hive.borderRadius('large'),
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string
    }
}))
