import React from 'react'
import { Image } from 'react-native'
import { createStyles } from 'lib/styles'
import { Images } from 'assets'
import { useStyles } from 'lib/hooks'
import { Touchable } from './Touchable'

type LogoProps = {
    onPress: VoidFunction,
    disabled?: boolean
}

export const Logo: React.FunctionComponent<LogoProps> = ({
    onPress,
    disabled = false
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            onPress={onPress}
            disabled={disabled}
        >
            <Image
                source={Images.Logo}
                style={styles.image}
            />
        </Touchable>
    )
}

const stylesheet = createStyles(() => ({
    image: {
        width: 136,
        height: 64,
        resizeMode: 'contain'
    }
}))
