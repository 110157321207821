import React, { useMemo } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { View } from 'react-native'
import { useBookingTimeOptions, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { IconProps, Nullable } from 'lib/types'
import { Adapter, Button, TimeZoneMessage, Typography } from 'lib/components'
import { dateHelpers } from 'lib/utils'
import { validateTimeSlots } from '../utils'
import { TimeSlot } from '../types'

type BookingDateWeeklyServiceProps = {
    isOneTime: boolean,
    liteMode?: boolean,
    isMultiSelect?: boolean,
    isLoading?: boolean,
    disabled?: boolean,
    continueDisabled: boolean,
    startingDate: string,
    minHoursNotice: number,
    timeTitle?: string,
    translationKey: string,
    timeDescription?: string,
    hideContinueButton?: boolean,
    bookingTimeField: Field<string>,
    timeSlots?: Array<TimeSlot>,
    bookingDaysField: Field<Record<string, Nullable<string>>>,
    selectedDates: string,
    leftIcon?: React.FunctionComponent<IconProps>
    onContinue: VoidFunction,
    testIDOneTimeSelect?: string,
    serviceType?: string,
    onChangeSelectedDate(date: string): void
}

export const BookingDateWeeklyService: React.FunctionComponent<BookingDateWeeklyServiceProps> = ({
    isOneTime,
    isLoading,
    startingDate,
    isMultiSelect,
    minHoursNotice,
    bookingTimeField,
    disabled,
    continueDisabled,
    timeDescription,
    timeSlots,
    onContinue,
    onChangeSelectedDate,
    bookingDaysField,
    selectedDates,
    hideContinueButton,
    leftIcon,
    liteMode,
    testIDOneTimeSelect,
    serviceType
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const bookingTimeOptions = useMemo(() => useBookingTimeOptions(startingDate)
        .filter(({ value }) => !dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, minHoursNotice))), [startingDate, minHoursNotice])

    const timeSlotsOptions = useMemo(() => timeSlots?.map(timeSlot => ({
        label: timeSlot.display,
        value: timeSlot.display
    })), [timeSlots])

    const hasTimeSlotError = useMemo(() => {
        if (!timeSlots?.length) {
            return false
        }

        return !validateTimeSlots({
            minHoursNotice,
            timeSlots: timeSlots || [],
            bookingTime: bookingTimeField.value,
            startingDate
        })
    }, [bookingTimeField.value, minHoursNotice, timeSlots, startingDate])

    return (
        <View style={liteMode ? styles.timeWithLiteMode : styles.time}>
            <View style={styles.selectInputWrapper}>
                <Typography.Regular
                    bold
                    style={liteMode ? styles.descriptionWithLiteMode : styles.description}
                />
                <TimeZoneMessage wideModel={liteMode}/>
                {timeDescription && (
                    <Typography.Label style={liteMode ? styles.descriptionWithLiteMode : styles.description}>
                        {timeDescription}
                    </Typography.Label>
                )}
                {isOneTime && !isMultiSelect
                    ? (
                        <Adapter.SelectInput
                            testID={testIDOneTimeSelect}
                            {...bookingTimeField}
                            options={timeSlotsOptions || bookingTimeOptions}
                            disabled={isLoading || !startingDate}
                            leftIcon={leftIcon}
                            serviceType={serviceType}
                            isLoading={isLoading}
                        />
                    ) : (
                        <Adapter.BookingDays
                            {...bookingDaysField}
                            isMultiSelect={isMultiSelect}
                            onChangeSelectedDateValue={onChangeSelectedDate}
                            startDate={isMultiSelect
                                ? selectedDates
                                : startingDate
                            }
                            disabled={Boolean(isLoading)}
                        />
                    )
                }
            </View>
            {isOneTime && !hideContinueButton && (
                <Button
                    disabled={disabled || continueDisabled || hasTimeSlotError}
                    isLoading={isLoading}
                    onPress={onContinue}
                    text={T.common.continue}
                />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    time: {
        flex: 1,
        minWidth: {
            lg: 300,
            xs: undefined
        },
        paddingTop: {
            lg: theme.utils.gap(2),
            xs: 0
        },
        justifyContent: 'space-between'
    },
    timeWithLiteMode: {
        flex: 1,
        justifyContent: 'space-between'
    },
    selectInputWrapper: {
        zIndex: theme.zIndex[10]
    },
    description: {
        marginBottom: theme.utils.gap(2)
    },
    descriptionWithLiteMode: {
        marginBottom: theme.utils.gap(1)
    }
}))
