import React from 'react'
import { Image, View } from 'react-native'
import Animated, { SharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { useStyles } from 'lib/hooks'
import { Page } from '../screens/LandingScreen.native'
import { Text } from '@propertyguru/hive-text'

type OnboardingPageProps = {
    page: Page,
    index: number,
    activeIndex: SharedValue<number>
}

export const LandingUspPage: React.FunctionComponent<OnboardingPageProps> = ({
    page,
    index,
    activeIndex
}) => {
    const { styles } = useStyles(stylesheet)
    const activeAnimatedStyles = useAnimatedStyle(() => {
        const isActive = index === activeIndex.value

        return {
            opacity: withTiming(isActive
                ? 1
                : 0
            )
        }
    })

    return (
        <View>
            <View style={styles.pagesContent}>
                <Animated.View style={[activeAnimatedStyles]}>
                    <Text typography="title/xs"
                        style={styles.titleText}
                    >
                        {page.title}
                    </Text>
                </Animated.View>
            </View>
            <Animated.View style={[styles.imageWrapper, activeAnimatedStyles]}>
                <Image
                    style={styles.image}
                    resizeMode="contain"
                    source={page.image}
                />
            </Animated.View>
            <View style={styles.pagesContent}>
                <Animated.View style={[activeAnimatedStyles]}>
                    <Text typography="body/s" style={styles.messageText}>
                        {page.message}
                    </Text>
                </Animated.View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(() => ({
    imageWrapper: {
        width: Measurements.WindowWidth,
        justifyContent: 'center',
        alignItems: 'center'
    },
    titleText: {
        fontSize: 22,
        textAlign: 'center',
        paddingVertical: 0,
        lineHeight: 28
    },
    messageText: {
        textAlign: 'center',
        paddingHorizontal: 20,
        marginTop: 10
    },
    image: {
        width: '65%',
        height: undefined,
        aspectRatio: 285 / 300
    },
    pagesContent: {
        minHeight: 50
    }
}))
