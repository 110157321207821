import React, { ReactNode } from 'react'
import { View } from 'react-native'

type Props = {
    children: ReactNode
}

export const AlertV2BodyDescription: React.FunctionComponent<Props> = ({ children }) => (
    <View>
        {children}
    </View>
)
