import { SupplyID } from 'lib/types'
import { getAuthorizedCleaningJobQuotation, getCleaningJobQuotation } from 'features/native/bookings/actions'
import { useNativeJobBookingDetailAtom, useNativeJobQuotationsAtom, useNativeUserTokenAtom } from 'lib/native/atoms'
import { Promotion } from 'lib/models'

export const useJobQuotation = supplyID => {
    const [token] = useNativeUserTokenAtom()
    const [jobBookingDataAtom] = useNativeJobBookingDetailAtom()
    const [jobQuotationAtom, setJobQuotationAtom] = useNativeJobQuotationsAtom()
    const { mutate: getCleaningJobQuotationData } = getCleaningJobQuotation()
    const { mutate: getAuthorizedCleaningJobQuotationData } = getAuthorizedCleaningJobQuotation()

    const getFunctionFetchJobQuotationData = supplyID => {
        switch (supplyID) {
            case SupplyID.Cleaner:
                return token ? getAuthorizedCleaningJobQuotationData : getCleaningJobQuotationData
            default:
                return undefined
        }
    }

    const requestJobQuotation = (promotion?: Promotion) => {
        const functionFetchJobQuotationData = getFunctionFetchJobQuotationData(supplyID)

        setJobQuotationAtom({
            ...jobQuotationAtom,
            isLoading: true
        })

        if (functionFetchJobQuotationData) {
            functionFetchJobQuotationData({
                ...jobBookingDataAtom,
                token,
                promo: promotion ? {
                    code: promotion.promo_code,
                    auth: promotion.promo_auth
                } : undefined
            }, {
                onSuccess: response => {
                    const responseData = token ? response.data : response
                    setJobQuotationAtom({
                        ...responseData,
                        isLoading: false
                    })
                },
                onError: () => {
                    setJobQuotationAtom({
                        ...jobQuotationAtom,
                        isLoading: false
                    })
                }
            })
        }
    }

    return { requestJobQuotation }
}
