import React from 'react'
import Animated, { SharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable } from '../Touchable'

type FadeTabButtonProps = {
    activeIndex: SharedValue<number>,
    index: number,
    onPress: VoidFunction,
    text: string,
    children: Children
}

export const FadeTabButton: React.FunctionComponent<FadeTabButtonProps> = ({
    activeIndex,
    index,
    onPress,
    children
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const animatedTabStyles = useAnimatedStyle(() => ({
        backgroundColor: withTiming(activeIndex.value === index
            ? theme.colors.yellow
            : theme.colors.white
        ),
        borderColor: withTiming(activeIndex.value === index
            ? theme.colors.orange
            : theme.colors.darkSilver
        )
    }))

    return (
        <Touchable
            style={styles.tabWrapper}
            onPress={onPress}
        >
            <Animated.View style={[styles.tab, animatedTabStyles]}>
                {children}
            </Animated.View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    tabWrapper: {
        flex: 1
    },
    tab: {
        flex: 1,
        borderRadius: 8,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: theme.utils.gap(3 / 2),
        paddingHorizontal: {
            lg: theme.utils.gap(3 / 2),
            xs: theme.utils.gap(1)
        },
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
