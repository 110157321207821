import React, { FunctionComponent } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { NativeAppStackParams } from 'lib/native/routing/stackParams'
import { useTranslations } from 'lib/hooks'
import { AirConditionScreen, AirConditionSummaryScreen, CookingScreen, CookingSummaryScreen, DeepCleaningScreen, DeepCleaningSummaryScreen, HandymanScreen, HandymanSummaryScreen, LaundryScreen, LaundrySummaryScreen, MoversAndPackersScreen, MoversAndPackersServiceDetailsScreen, PestControlScreen, PestControlSummaryScreen, TaskAndErrandsScreen, TaskAndErrandsSummaryScreen } from 'features/bookings'
import { APP_CONFIG } from 'lib/config'
import { CleaningConfirmationScreen, CleaningDateTimeScreen, CleaningPropertyDetailsScreen, CleaningScreen, CleaningServiceAddressScreen, CleaningServiceCustomiseSchedule, CleaningServiceDetailsScreen, CleaningSummaryScreen, PaymentMethodScreen, PromoCodeScreen } from 'features/native/bookings'
import { ScreenRedirect } from 'lib/native/components'

const Stack = createNativeStackNavigator<NativeAppStackParams>()

export const ServicesStack = () => {
    const T = useTranslations()

    return (
        <React.Fragment>
            <Stack.Screen
                name={ScreenNamesNative.CleaningNative}
                component={CleaningScreen}
            />
            <Stack.Screen
                name={ScreenNamesNative.Cleaning}
            >
                {() => <ScreenRedirect to={ScreenNamesNative.CleaningNative} isReplace />}
            </Stack.Screen>
            <Stack.Screen
                name={ScreenNamesNative.CleaningPropertyDetailsNative}
                component={CleaningPropertyDetailsScreen}
            />
            <Stack.Screen
                name={ScreenNamesNative.CleaningServiceDetailsNative}
                component={CleaningServiceDetailsScreen}
            />
            <Stack.Screen
                name={ScreenNamesNative.CleaningServiceCustomiseScheduleNative}
                component={CleaningServiceCustomiseSchedule}
            />
            <Stack.Screen
                name={ScreenNamesNative.CleaningDateTimeNative}
                component={CleaningDateTimeScreen}
            />
            <Stack.Screen
                name={ScreenNamesNative.CleaningServiceAddressNative}
                component={CleaningServiceAddressScreen}
            />
            <Stack.Screen
                name={ScreenNamesNative.CleaningSummaryNative}
                component={CleaningSummaryScreen}
            />
            <Stack.Screen
                name={ScreenNamesNative.CleaningConfirmationNative}
                component={CleaningConfirmationScreen}
            />
            <Stack.Screen
                name={ScreenNamesNative.PromoCodeNative}
                component={PromoCodeScreen}
            />
            <Stack.Screen
                name={ScreenNamesNative.PaymentMethodNative}
                component={PaymentMethodScreen}
            />
            <Stack.Screen
                name={ScreenNamesNative.DeepCleaning}
                component={DeepCleaningScreen as FunctionComponent}
                options={{
                    title: T.screens.deepCleaning.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNamesNative.DeepCleaningSummary}
                component={DeepCleaningSummaryScreen as FunctionComponent}
                options={{
                    title: T.screens.deepCleaningSummary.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNamesNative.AirCondition}
                component={AirConditionScreen as FunctionComponent}
                options={{
                    title: T.screens.airCondition.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNamesNative.AirConditionSummary}
                component={AirConditionSummaryScreen as FunctionComponent}
                options={{
                    title: T.screens.airConditionSummary.pageTitle
                }}
            />
            {APP_CONFIG.SERVICES.IS_COOKING_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNamesNative.Cooking}
                        component={CookingScreen as FunctionComponent}
                        options={{
                            title: T.screens.cooking.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNamesNative.CookingSummary}
                        component={CookingSummaryScreen as FunctionComponent}
                        options={{
                            title: T.screens.cookingSummary.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNamesNative.Handyman}
                        component={HandymanScreen as FunctionComponent}
                        options={{
                            title: T.screens.handyman.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNamesNative.HandymanSummary}
                        component={HandymanSummaryScreen as FunctionComponent}
                        options={{
                            title: T.screens.handymanSummary.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNamesNative.TaskAndErrands}
                        component={TaskAndErrandsScreen as FunctionComponent}
                        options={{
                            title: T.screens.taskAndErrands.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNamesNative.TaskAndErrandsSummary}
                        component={TaskAndErrandsSummaryScreen as FunctionComponent}
                        options={{
                            title: T.screens.taskAndErrands.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNamesNative.PestControl}
                        component={PestControlScreen as FunctionComponent}
                        options={{
                            title: T.screens.pestControl.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNamesNative.PestControlSummary}
                        component={PestControlSummaryScreen as FunctionComponent}
                        options={{
                            title: T.screens.pestControl.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_MOVERS_AND_PACKERS_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNamesNative.MoversAndPackers}
                        component={MoversAndPackersScreen as FunctionComponent}
                        options={{
                            title: T.screens.moversAndPackers.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNamesNative.MoversAndPackersSummary}
                        component={MoversAndPackersServiceDetailsScreen as FunctionComponent}
                        options={{
                            title: T.screens.moversAndPackers.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNamesNative.Laundry}
                        component={LaundryScreen as FunctionComponent}
                        options={{
                            title: T.screens.laundry.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNamesNative.LaundrySummary}
                        component={LaundrySummaryScreen as FunctionComponent}
                        options={{
                            title: T.screens.laundrySummary.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    )
}
