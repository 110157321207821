import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { TableHeaderItem } from './HeaderItem.native'

type Props = {
    children: ReactNode
}

const TableHeader: React.FunctionComponent<Props> = ({ children }) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                backgroundColor: hive.color('fill/neutral/primary'),
                borderTopLeftRadius: hive.borderRadius('medium'),
                borderTopRightRadius: hive.borderRadius('medium'),
                paddingHorizontal: hive.spacing('x2')
            }}
        >
            {children}
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flexDirection: 'row'
    }
}))

export default Object.assign(TableHeader, {
    Item: TableHeaderItem
})
