import { getBestDeals } from '../actions'
import { useState } from 'react'
import { BestDealsResponse } from 'features/bookings/types'

export const useBestDeals = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [bestDeals, setBestDeals] = useState<BestDealsResponse>()
    const { mutate: fetchBestDeals } = getBestDeals()

    const requestBestDeals = () => {
        setIsLoading(true)

        fetchBestDeals(
            {},
            {
                onSuccess: response => {
                    setBestDeals(response)
                },
                onError: error => {
                    console.log(error)
                },
                onSettled: () => {
                    setIsLoading(false)
                }
            }
        )
    }

    return {
        bestDeals,
        requestBestDeals,
        isLoading
    }
}
