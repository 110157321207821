import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Icons } from 'assets'
import { JobType } from 'lib/types'
import { useJobFrequencyText, useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { RecurringJob } from 'lib/models'
import { CopyBookingId, Typography } from 'lib/components'
import { JobDetailsResponse } from '../types'
import { JobStatusLabelSmall } from './JobStatusLabelSmall'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'

type JobNameProps = {
    job: JobDetailsResponse,
    isRecurring: boolean,
    recurringJob?: RecurringJob,
    customStyles?: ViewStyle
}

export const JobName: React.FunctionComponent<JobNameProps> = ({
    job,
    recurringJob,
    customStyles = {}
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const { job: { job_id, supply: { name }, schedule } } = job
    const isAirconSubscriptionJob = isAirconSubscription(job.job)
    const frequencyText = useJobFrequencyText(schedule, job.job, isAirconSubscriptionJob)
    const jobType = job.job.job_type

    const getJobFrequency = () => {

        if (recurringJob) {
            return recurringJob.frequency
        }

        if (jobType === JobType.Recurring) {
            return job.job.frequency
        }
    }
    const jobFrequency = getJobFrequency()

    return (
        <View
            style={{
                ...styles.wrapper,
                ...customStyles
            }}
        >
            <View style={styles.nameContainer}>
                <View style={styles.jobStatus}>
                    <Typography.Label
                        bold
                        forceColor={theme.colors.darkGrey}
                    >
                        {job_id}
                    </Typography.Label>
                    <JobStatusLabelSmall job={job.job} />
                </View>
                <View style={styles.nameWrapper}>
                    <Typography.Regular
                        bold
                        style={styles.labelText}
                    >
                        {`${name} ${jobFrequency ? `- ${frequencyText}` : ''}`}
                    </Typography.Regular>
                    {jobFrequency && (
                        <View>
                            <Icons.Refresh size={16} />
                        </View>
                    )}
                </View>
            </View>
            <View style={styles.copyContainer}>
                <CopyBookingId id={job_id} />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(2)
    },
    copyContainer: {
        flex: {
            xs: 0.15
        },
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    jobStatus: {
        alignItems: 'center',
        flexDirection: 'row'
    },
    nameWrapper: {
        alignItems: 'center',
        flexDirection: 'row'
    },
    labelText: {
        marginRight: theme.utils.gap(1),
        textTransform: 'capitalize',
        fontSize: {
            xs: 12
        }
    },
    nameContainer: {
        flex: {
            lg: 1,
            xs: 0.85
        }
    }
}))
