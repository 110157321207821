import { useEffect, useState } from 'react'
import { dateHelpers } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { jobActions } from 'features/jobs'
import { useFirstSignUpAtom } from 'lib/atoms'
import { WelcomeText } from '../types'

export const useWelcomeMessage = () => {
    const T = useTranslations()
    const [firstSignUp, setFirstSignUp] = useFirstSignUpAtom()
    const [welcomeText, setWelcomeText] = useState<WelcomeText>({
        welcome: T.welcomeHeader.newUser.welcome,
        message: T.welcomeHeader.newUser.message
    })
    const { data: activeJobsResponse, isLoading } = jobActions.getConsumerActiveJobs()

    useEffect(() => {
        if (!firstSignUp) {
            return
        }

        if (dateHelpers.getDaysDifferenceFromNow(firstSignUp) > 30) {
            setFirstSignUp('')
        }
    }, [])

    useEffect(() => {
        if (!activeJobsResponse) {
            return
        }

        if (activeJobsResponse.data.job_list.length > 0) {
            return setWelcomeText({
                welcome: T.welcomeHeader.userWithUpComingBookings.welcome,
                message: T.welcomeHeader.userWithUpComingBookings.message
            })
        }

        if (firstSignUp) {
            return
        }

        return setWelcomeText({
            welcome: T.welcomeHeader.userWithoutBookings.welcome,
            message: T.welcomeHeader.userWithoutBookings.message
        })
    }, [activeJobsResponse])

    return {
        isLoading,
        isClickable: activeJobsResponse
            ? activeJobsResponse.data.job_list.length > 0
            : false,
        welcome: welcomeText.welcome,
        message: welcomeText.message
    }
}
