import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Button, Typography } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'

type MobileSummaryContinueProps = {
    price: number,
    onContinue: VoidFunction
}

export const MobileSummaryContinue: React.FunctionComponent<MobileSummaryContinueProps> = ({
    onContinue,
    price
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const formattedPrice = usePrice(price)

    return (
        <View style={styles.content}>
            <View>
                <Typography.Label>
                    {T.common.startingFrom}
                </Typography.Label>
                <Typography.SmallSubheading>
                    {formattedPrice}
                </Typography.SmallSubheading>
            </View>
            <View style={styles.summary}>
                <Button
                    white
                    width={180}
                    onPress={onContinue}
                    text={T.common.continue}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        height: 60,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1),
        backgroundColor: theme.colors.sun
    },
    summary: {
        flexDirection: 'row',
        alignItems: 'center'
    }
}))
