/* eslint-disable no-loops/no-loops, functional/no-let */

export const getWeekStartDate = (date: Date): Date => {
    const d = new Date(date)
    const day = d.getDay()
    const diff = d.getDate() - day

    return new Date(d.setDate(diff))
}

export const resetDateTime = (date: Date): Date => {
    const d = new Date(date)

    d.setHours(0, 0, 0, 0)

    return d
}

export const getCalendarDays = (currentDate: Date): Array<Date> => {
    const days: Array<Date> = []

    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay()
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate()

    // Previous month's days
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    const daysInPrevMonth = new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 0).getDate()

    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
        days.push(new Date(prevMonth.getFullYear(), prevMonth.getMonth(), daysInPrevMonth - i))
    }

    // Current month's days
    for (let day = 1; day <= daysInMonth; day++) {
        days.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), day))
    }

    // Next month's days
    const totalDays = 6 * 7 // 6 weeks, 7 days each
    const remainingDays = totalDays - days.length
    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)

    for (let i = 1; i <= remainingDays; i++) {
        days.push(new Date(nextMonth.getFullYear(), nextMonth.getMonth(), i))
    }

    return days
}

export const getCalendarDaysInWeek = (startDate: Date): Array<Date> => {
    const days: Array<Date> = []

    for (let i = 0; i < 7; i++) {
        days.push(new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000))
    }

    return days
}

export const getCalendarYears = (minYear: number, maxYear: number): Array<number> => {
    const years: Array<number> = []

    for (let year = minYear; year <= maxYear; year++) {
        years.push(year)
    }

    return years
}
