import React from 'react'
import { Linking } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { APP_CONFIG } from 'lib/config'
import { createStyles } from 'lib/styles'

export const RecaptchaNote: React.FunctionComponent = () => {
    const T = useTranslations()
    const { theme, styles } = useStyles(stylesheet)

    return (
        <Typography.Label
            style={styles.text}
            forceColor={theme.colors.darkGrey}
        >
            <React.Fragment>
                {`${T.screens.authOTP.reCaptcha.protectedByNote} `}
                <Touchable onPress={() => Linking.openURL(APP_CONFIG.RECAPTCHA.PRIVACY_POLICY)}>
                    <Typography.Label forceColor={theme.colors.orange}>
                        {T.screens.authOTP.reCaptcha.privacyPolicy}
                    </Typography.Label>
                </Touchable>
                {` ${T.common.and} `}
                <Touchable onPress={() => Linking.openURL(APP_CONFIG.RECAPTCHA.TERMS_OF_SERVICE)}>
                    <Typography.Label forceColor={theme.colors.orange}>
                        {`${T.screens.authOTP.reCaptcha.termsOfService} `}
                    </Typography.Label>
                </Touchable>
                {T.screens.authOTP.reCaptcha.apply}.
            </React.Fragment>
        </Typography.Label>
    )
}

const stylesheet = createStyles(() => ({
    text: {
        textAlign: 'center'
    }
}))
