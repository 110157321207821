import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { Divider } from '@propertyguru/hive-divider'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { TableBodyRowItem } from './BodyRowItem.native'

type Props = {
    children: ReactNode
}

const TableBodyRow: React.FunctionComponent<Props> = ({ children }) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <>
            <Divider />
            <View
                style={{
                    ...styles.container,
                    paddingVertical: hive.spacing('x1'),
                    paddingHorizontal: hive.spacing('x2')
                }}
            >
                {children}
            </View>
        </>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flexDirection: 'row'
    }
}))

export default Object.assign(TableBodyRow, {
    Item: TableBodyRowItem
})
