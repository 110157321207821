import React, { useEffect, useRef } from 'react'
import { View, ViewStyle } from 'react-native'
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { isWeb } from 'lib/common'
import { initToastState, useToastAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { EducationBanner } from '@propertyguru/hive-education-banner'
import { useToastIcon, useToastTitle } from 'lib/native/hooks'
import { TOAST_HEIGHT, TOAST_SHOW_DURATION } from 'lib/native/constants'

type ToastProps = {
    isModal?: boolean
}

export const Toast: React.FunctionComponent<ToastProps> = ({ isModal }) => {
    const { styles } = useStyles(stylesheet)
    const [toast, setToastMessage] = useToastAtom()
    const title = useToastTitle(toast.type)
    const icon = useToastIcon(toast.type)
    const timeoutRef = useRef<NodeJS.Timeout>()
    const isOpened = useSharedValue(Boolean(toast.message))
    const animatedSlideInStyle = useAnimatedStyle<ViewStyle>(() => ({
        transform: [{
            translateY: withTiming(
                isOpened.value
                    ? -TOAST_HEIGHT
                    : TOAST_HEIGHT*2,
                {},
                () => {
                    if (!isOpened.value) {
                        runOnJS(setToastMessage)(initToastState)
                    }
                }
            )
        }]
    }))

    useEffect(() => {
        if (toast.isModalOnly && !isModal) {
            return
        }

        if (!toast.message) {
            isOpened.value = false

            return clearTimeout(timeoutRef.current)
        }

        isOpened.value = true
        timeoutRef.current = setTimeout(() => {
            isOpened.value = false
        }, TOAST_SHOW_DURATION)

        return () => clearTimeout(timeoutRef.current)
    }, [toast.message])

    if (toast.isModalOnly && !isModal) {
        return null
    }

    return (
        <Animated.View
            style={[
                styles.wrapper as ViewStyle,
                animatedSlideInStyle
            ]}
        >
            <View style={styles.container}>
                <EducationBanner icon={icon} title={title} body={toast.message} numberOfLinesBody={5} onClosePress={() => {
                    isOpened.value = false
                }}/>
            </View>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        left: 0,
        right: 0,
        bottom: 0,
        position: isWeb
            ? 'fixed'
            : 'absolute',
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        width: {
            lg: '60%',
            xs: '90%'
        },
        borderRadius: 8,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.utils.gap(1),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(0, 0.8, 20, 70)
    }
}))
