import React from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { Modal } from '@propertyguru/hive-modal'
import { Text } from '@propertyguru/hive-text'
import { NativeModal } from 'lib/native/components'
import { useTranslations } from 'lib/hooks'

type Props = {
    isOpen: boolean
    onClose: () => void,
    content?: string
}

export const PlatformFeeModal: React.FunctionComponent<Props> = ({ isOpen, onClose, content }) => {
    const T = useTranslations()
    const hive = useHive()

    return content ? (
        <NativeModal fullWidth isOpen={isOpen} onClose={onClose}>
            <Modal>
                <Modal.Header>
                    <Modal.Header.Title>
                        {T.components.platformFeeModal.title}
                    </Modal.Header.Title>
                    <Modal.Header.Button onPress={onClose} />
                </Modal.Header>
                <Modal.Body>
                    <View style={{ rowGap: hive.spacing('x3') }}>
                        <Text typography="body/s" color="text/active/secondary">
                            {content}
                        </Text>
                    </View>
                </Modal.Body>
            </Modal>
        </NativeModal>
    ) : null
}
