import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { Button, MediaQuery } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { Segment } from 'lib/analytics'
import { CompleteProfileButton } from './CompleteProfileButton'

export const SubHeader: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()

    return (
        <MediaQuery.Visible from={Breakpoint.LG}>
            <View style={styles.container}>
                <Button
                    testID="book-a-service-button"
                    width={120}
                    height={50}
                    text={T.common.bookService}
                    onPress={() => {
                        Segment.bookServiceClicked({})
                        navigation.navigate(ScreenNames.AllServices)
                    }}
                />
                <CompleteProfileButton />
            </View>
        </MediaQuery.Visible>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderTopWidth: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderColor: theme.colors.silver,
        backgroundColor: theme.colors.white,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(9)
    }
}))
