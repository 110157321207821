import React from 'react'
import { SectionList, SectionListData, View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { FormComponents, Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { R } from 'lib/utils'
import { SelectableItem } from 'features/bookings'

interface CustomSection extends SectionListData<SelectableItem> {
    subtitle?: string
}

interface CheckboxListProps extends Field<Array<SelectableItem>>{
    disabled?: boolean,
    sections: Array<CustomSection>
}

export const CheckboxList: React.FunctionComponent<CheckboxListProps> = ({
    disabled,
    sections,
    ...formProps
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <SectionList
            style={styles.list}
            contentContainerStyle={styles.listContainer}
            sections={sections}
            renderItem={({ item }) => (
                <FormComponents.Checkbox
                    isSelected={item.isSelected}
                    disabled={disabled}
                    style={styles.checkbox}
                    renderComponent={() => (
                        <Typography.Regular>
                            {item?.value}
                        </Typography.Regular>
                    )}
                    onChange={() => formProps.onChangeValue(formProps.value.map(formItem => ({
                        ...formItem,
                        ...(R.equals(formItem, item)
                            ? { isSelected: !item.isSelected }
                            : {}
                        )
                    })))}
                />
            )}
            renderSectionHeader={({ section }) => (
                <View style={styles.sectionHeader}>
                    <Typography.Label>
                        {section?.title}
                    </Typography.Label>
                    <Typography.Label>
                        {section?.subtitle}
                    </Typography.Label>
                </View>
            )}
            renderSectionFooter={() => (
                <View style={styles.sectionFooter}/>
            )}
            ListFooterComponent={() => (
                <View style={styles.sectionFooter}>
                    <FormComponents.ErrorMessage text={formProps.errorMessage}/>
                </View>
            )}
        />
    )
}

const stylesheet = createStyles(theme => ({
    listContainer: {
        flex: 1,
        width: '100%'
    },
    list: {
        flex: 1
    },
    checkbox: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver
    },
    sectionHeader: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    sectionFooter: {
        paddingBottom: theme.utils.gap(1)
    }
}))
