import React, { useState, Fragment, useEffect } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Divider } from '@propertyguru/hive-divider'
import {
    ArrowLeftOutline,
    BathOutline,
    BedOutline,
    BusinessShopStoreFill,
    BusinessShopStoreOutline,
    ChevronLeftOutline,
    HomeDoorHouseFill,
    HomeDoorHouseOutline
} from '@propertyguru/hive-icons'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { useNavigation } from '@react-navigation/native'
import { Icon } from '@propertyguru/hive-icon'
import * as Icons from '@propertyguru/hive-icons'
import { Chip } from '@propertyguru/hive-chip'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { NotificationType, SupplyID } from 'lib/types'
import { AlertV2, ChipBox, ScreenHeader } from 'lib/native/components'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { CommercialSizeModal, Footer } from 'features/native/bookings/components'
import { useNativeJobBookingDetailAtom } from 'lib/native/atoms'
import { HousingType, PropertyType } from 'lib/native/models/JobBookingDetail'
import { Segment } from 'lib/analytics'
import { useToastAtom } from 'lib/atoms'
import { AddressPropertyType } from 'lib/native/types'
import { CommercialPropertyDetails, ResidentialPropertyDetails } from 'lib/native/models'
import { ADDRESS_HOUSING_TYPE_MAP_REVERSE } from 'lib/native/constants/Address'

export const CleaningPropertyDetailsScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()
    const config = getServiceStaticConfig(SupplyID.Cleaner)
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const propertyInfo = config.propertyInfo
    const residentialOption = config.propertyInfo.options.find(option => option.key === 'residential')
    const commercialOption = config.propertyInfo.options.find(option => option.key === 'commercial')
    const defaultOption = config.propertyInfo.options.find(option => option.default)
    const bedroomOptions = residentialOption.specs.items.find(items => items.label === 'Bedroom').options
    const bathroomOptions = residentialOption.specs.items.find(items => items.label === 'Bathroom').options
    const commercialSizeOptions = commercialOption?.spec?.options || []

    const [isOpen, setIsOpen] = useState(false)

    const initialPropertyDetails = jobBookingDetailAtom.address?.propertyDetails
    const initialPropertyType = initialPropertyDetails?.type
    const initialIsResidential =
        initialPropertyType === AddressPropertyType.Residential
            ? true
            : initialPropertyType === AddressPropertyType.Commercial
                ? false
                : undefined
    const initialHousingType =
        initialPropertyDetails && initialIsResidential === true
            ? ADDRESS_HOUSING_TYPE_MAP_REVERSE[(initialPropertyDetails as ResidentialPropertyDetails).housingType]
            : undefined
    const initialBedrooms =
        initialPropertyDetails && initialIsResidential === true
            ? (initialPropertyDetails as ResidentialPropertyDetails).bedrooms
            : undefined
    const initialBathrooms =
        initialPropertyDetails && initialIsResidential === true
            ? (initialPropertyDetails as ResidentialPropertyDetails).bathrooms
            : undefined
    const initialCommercialSize =
        initialPropertyDetails && initialIsResidential === false
            ? (initialPropertyDetails as CommercialPropertyDetails).size
            : undefined
    const defaultHousingType = defaultOption.specs.type.options.find(option => option.default)?.key
    const defaultBedrooms = Number(bedroomOptions.find(option => option.default)?.value)
    const defaultBathrooms = Number(bathroomOptions.find(option => option.default)?.value)
    const defaultCommercialSize = commercialSizeOptions.find(option => option.default)?.label

    const [isResidential, setIsResidential] = useState(initialIsResidential ?? residentialOption.default)

    // note the checks here use !==false this esures same behaviour for true & undefined
    // i.e if initialIsResidential is undefined, it will default to true
    // which will set the state to initial value if defined or will set to default value
    // if initialIsResidential is false i.e. commercial, it will set the state to undefined
    const [housingType, setHousingType] = useState<string | undefined>(
        initialIsResidential !== false ? initialHousingType ?? defaultHousingType : undefined
    )
    const [bedrooms, setBedrooms] = useState<number | undefined>(
        initialIsResidential !== false ? initialBedrooms ?? defaultBedrooms : undefined
    )
    const [bathrooms, setBathrooms] = useState<number | undefined>(
        initialIsResidential !== false ? initialBathrooms ?? defaultBathrooms : undefined
    )

    // similar to above checks, this will default to undefined unless initialIsResidential is false i.e. is commercial in which case it will use initial size or default size
    const [commercialSize, setCommercialSize] = useState<string | undefined>(
        initialIsResidential === false ? initialCommercialSize ?? defaultCommercialSize : undefined
    )

    const handleNext = () => {
        if (isResidential && !housingType) {
            setToastMessage({
                message: T.screens.cleaningPropertyDetails.housingTypeErrorMessage,
                type: NotificationType.Error
            })

            return
        }

        navigation.navigate(ScreenNamesNative.CleaningServiceDetailsNative)
    }

    const handleSelect = (value: string) => {
        setIsOpen(false)
        setCommercialSize(value)
    }

    const handleHouseType = (value: string) => {
        setHousingType(value)
    }

    const handlePropertyType = (value: string) => {
        setIsResidential(value === 'residential')

        // eslint-disable-next-line no-else/no-else
        if (value !== 'residential') {
            setHousingType(undefined)
            setBedrooms(undefined)
            setBathrooms(undefined)
            setCommercialSize(initialCommercialSize ?? defaultCommercialSize)
        } else {
            setCommercialSize(undefined)
            setHousingType(initialHousingType ?? defaultHousingType)
            setBedrooms(initialBedrooms ?? defaultBedrooms)
            setBathrooms(initialBathrooms ?? defaultBathrooms)
        }
    }

    useEffect(() => {
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            propertyInfo: isResidential ? PropertyType.Residential : PropertyType.Commercial,
            bedrooms: isResidential ? bedrooms : undefined,
            bathrooms: isResidential ? bathrooms : undefined,
            commercialSize: isResidential ? undefined : commercialSize,
            housingType: isResidential ? (housingType ? (housingType as HousingType) : undefined) : undefined
        })
        Segment.bookingPropertyTypeSelected({ propertyType: isResidential ? 'residential' : 'commercial', housingType })
        Segment.bookingPropertySizeSelected({ propertySize: commercialSize })
        Segment.bedsAndBathSelected({ bedrooms, bathrooms })
    }, [isResidential, housingType, bedrooms, bathrooms, commercialSize])

    return (
        <View style={{ ...styles.container, paddingTop: insets.top }}>
            <ScreenHeader>
                <ScreenHeader.Button icon={ChevronLeftOutline} onPress={navigation.goBack} />
                <ScreenHeader.Title>{T.screens.cleaningPropertyDetails.pageTitle}</ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <ScrollView style={styles.main}>
                <View style={styles.housingTypeContainer}>
                    <Text typography="label/m">{propertyInfo.title}</Text>
                    <Text typography="body/xs" style={styles.housingTypeDescription}>
                        {propertyInfo.subtitle}
                    </Text>
                    <View style={styles.chipBox}>
                        {propertyInfo.options.map(option => (
                            <ChipBox
                                key={option.key}
                                active={option.key === 'residential' ? isResidential : !isResidential}
                                text={option.label}
                                icon={option.key === 'residential' ? HomeDoorHouseOutline : BusinessShopStoreOutline}
                                iconActive={option.key === 'residential' ? HomeDoorHouseFill : BusinessShopStoreFill}
                                onPress={() => handlePropertyType(option.key)}
                                badgeProps={option.key === 'commercial' ? {
                                    label: T.screens.cleaningPropertyDetails.comingSoon,
                                    type: 'primary',
                                    color: 'lightNeutral',
                                    size: 'small'
                                } : undefined}
                            />
                        ))}
                    </View>
                    {isResidential && (
                        <Fragment>
                            <Text typography="label/s">{residentialOption.specs.type.label}</Text>
                            <View style={styles.housingTypeChips}>
                                {residentialOption.specs.type.options.map(option => (
                                    <Chip
                                        key={option.key}
                                        style={styles.housingTypeChip}
                                        active={housingType === option.key}
                                        text={option.label}
                                        onPress={() => handleHouseType(option.key)}
                                    />
                                ))}
                            </View>
                        </Fragment>
                    )}
                    {!isResidential && (
                        <AlertV2>
                            <AlertV2.Body>
                                <AlertV2.Body.Title>
                                    <Icon icon={Icons.BubbleMessageAnnotationExclamationMarkAlertTooltipOutline} color="icon/warning/primary" width={16} height={16} />
                                    <Text typography="caption/s" color="text/warning/primary" style={styles.comingSoonTitle}>
                                        {T.screens.cleaningPropertyDetails.commercialAlertTitle}
                                    </Text>
                                </AlertV2.Body.Title>
                                <AlertV2.Body.Description>
                                    <Text typography="body/xs" color="text/warning/primary">
                                        {T.screens.cleaningPropertyDetails.commercialAlertMessage}
                                    </Text>
                                </AlertV2.Body.Description>
                            </AlertV2.Body>
                        </AlertV2>
                    )}
                </View>
                {isResidential && (
                    <View style={styles.roomsQuestionContainer}>
                        <Text typography="label/m" style={styles.roomsHeader}>
                            {residentialOption?.specs?.title}
                        </Text>
                        <Text typography="body/xs" style={styles.roomsQuestionDescription}>
                            {residentialOption.specs.subtitle}
                        </Text>
                        {residentialOption.specs.items.map((item, index) => (
                            <Fragment key={index}>
                                <View style={styles.headingRow}>
                                    <Icon icon={item.label === 'Bedroom' ? BedOutline : BathOutline} />
                                    <Text typography="label/s">{item.label}</Text>
                                </View>
                                <View style={styles.chipRow} key={item.label}>
                                    {item.options.map(option => (
                                        <Chip
                                            key={option.key}
                                            style={styles.bedroomChip}
                                            active={
                                                item.label === 'Bedroom'
                                                    ? bedrooms === Number(option.value)
                                                    : bathrooms === Number(option.value)
                                            }
                                            text={option.label}
                                            onPress={() =>
                                                item.label === 'Bedroom'
                                                    ? setBedrooms(Number(option.value))
                                                    : setBathrooms(Number(option.value))
                                            }
                                        />
                                    ))}
                                </View>
                            </Fragment>
                        ))}
                    </View>
                )}
            </ScrollView>
            <CommercialSizeModal
                currentSize={commercialSize}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onSelect={handleSelect}
                label={commercialOption?.spec?.label}
                options={commercialOption?.spec?.options?.map(option => option.label) || []}
            />
            <Footer style={{ paddingBottom: insets.bottom }}>
                <Footer.Buttons>
                    <Button.Icon icon={ArrowLeftOutline} onPress={navigation.goBack} />
                    <Button
                        text={T.screens.cleaningPropertyDetails.footerButtons.next}
                        style={styles.footerBtnNext}
                        disabled={!isResidential}
                        onPress={handleNext}
                    />
                </Footer.Buttons>
            </Footer>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    main: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    },
    housingTypeContainer: {
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        paddingVertical: theme.utils.hive.spacing('x12'),
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string,
        marginBottom: theme.utils.hive.spacing('x3')
    },
    housingTypeDescription: {
        marginTop: theme.utils.hive.spacing('x4')
    },
    chipBox: {
        flexDirection: 'row',
        gap: theme.utils.hive.spacing('x2'),
        marginVertical: theme.utils.hive.spacing('x6')
    },
    housingTypeChips: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: theme.utils.hive.spacing('x3'),
        gap: theme.utils.hive.spacing('x3')
    },
    housingTypeChip: {
        borderRadius: theme.utils.hive.borderRadius('small'),
        height: 40,
        alignItems: 'center',
        justifyContent: 'center'
    },
    roomsQuestionContainer: {
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        paddingVertical: theme.utils.hive.spacing('x12'),
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string
    },
    roomsQuestionDescription: {
        marginTop: theme.utils.hive.spacing('x4')
    },
    roomsHeader: {
        height: 24
    },
    commercialSpaceQuestionDescription: {
        marginTop: theme.utils.hive.spacing('x4')
    },
    headingRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.hive.spacing('x3'),
        marginTop: theme.utils.hive.spacing('x6')
    },
    chipRow: {
        flexDirection: 'row',
        gap: theme.utils.hive.spacing('x3'),
        marginTop: theme.utils.hive.spacing('x2')
    },
    sizeSelect: {
        marginTop: theme.utils.hive.spacing('x6')
    },
    footerBtnNext: {
        flex: 1
    },
    bedroomChip: {
        height: 40,
        alignItems: 'center',
        justifyContent: 'center'
    },
    comingSoonTitle: {
        fontWeight: '700'
    }
}))
