import { useMutation } from '@tanstack/react-query'
import { GetPromotionPackagesResponse } from 'features/sendPay'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { MutationKey, useFetcher } from 'lib/api'
import { APP_CONFIG } from 'lib/config'
import {
    SignUpResponse,
    SignInResponse,
    NativeSignUpWithEmailRequest,
    NativeSignInWithEmailRequest,
    GetCancellationReasonsResponse,
    GetMeResponse,
    GetMeRequest,
    GetProfileDataRequest,
    SignInWithAppleRequest,
    SignInWithFacebookRequest,
    SignInWithGoogleNativeRequest,
    SendOtpResponse,
    SendOtpRequest,
    VerifyOtpResponse,
    VerifyOtpRequest
} from './types'

export const useSignInWithGoogleNative = () => {
    const fetcher = useFetcher<SignInResponse>(HttpMethod.POST, `${APP_CONFIG.AUTH.URL}/social/google`)

    return useMutation<Response<SignInResponse>, ErrorResponse & { token: string }, SignInWithGoogleNativeRequest>(fetcher)
}

export const useSignInWithFacebookNative = () => {
    const fetcher = useFetcher<SignInResponse>(HttpMethod.POST, `${APP_CONFIG.AUTH.URL}/social/facebook`)

    return useMutation<Response<SignInResponse>, ErrorResponse & { token: string }, SignInWithFacebookRequest>(fetcher)
}

export const useSignInWithAppleNative = () => {
    const fetcher = useFetcher<SignInResponse>(HttpMethod.POST, `${APP_CONFIG.AUTH.URL}/social/apple`)

    return useMutation<Response<SignInResponse>, ErrorResponse & { token: string }, SignInWithAppleRequest>(fetcher)
}

export const useGetCancelationReasons = () => {
    const fetcher = useFetcher<GetCancellationReasonsResponse>(HttpMethod.POST, '/consumer-get-job-cancellation-reasons')

    return useMutation<Response<GetCancellationReasonsResponse>, ErrorResponse, GetProfileDataRequest>(fetcher)
}

export const useGetCreditPackages = () => {
    const fetcher = useFetcher<GetPromotionPackagesResponse>(HttpMethod.POST, '/consumer-get-promotion-packages')

    return useMutation<Response<GetPromotionPackagesResponse>, ErrorResponse, GetProfileDataRequest>(fetcher)
}

export const useGetMe = () => {
    const fetcher = useFetcher<GetMeResponse>(HttpMethod.POST, `${APP_CONFIG.API.URL}/consumer-get-profile`)

    return useMutation<Response<GetMeResponse>, ErrorResponse, GetMeRequest>(fetcher, {
        mutationKey: [MutationKey.GetMe]
    })
}

export const useSignUpWithEmail = () => {
    const fetcher = useFetcher<SignUpResponse>(HttpMethod.POST, `${APP_CONFIG.AUTH.URL}/signup/email`)

    return useMutation<Response<SignUpResponse>, ErrorResponse, NativeSignUpWithEmailRequest>(fetcher)
}

export const useSignInWithEmail = () => {
    const fetcher = useFetcher<SignInResponse>(HttpMethod.POST, `${APP_CONFIG.AUTH.URL}/login/email`)

    return useMutation<Response<SignInResponse>, ErrorResponse , NativeSignInWithEmailRequest>(fetcher)
}

export const useSendMobileOtp = () => {
    const fetcher = useFetcher<SendOtpResponse>(HttpMethod.POST, `${APP_CONFIG.QUOTATION.URL}/auth/otp/send/verify-phone-number`)

    return useMutation<Response<SendOtpResponse>, ErrorResponse, SendOtpRequest>(fetcher)
}

export const useVerifyMobileOtp = () => {
    const fetcher = useFetcher<VerifyOtpResponse>(HttpMethod.POST, `${APP_CONFIG.QUOTATION.URL}/auth/otp/verify/phone-number`)

    return useMutation<Response<VerifyOtpResponse>, ErrorResponse, VerifyOtpRequest>(fetcher)
}
