import { dateHelpers } from 'lib/utils'

export const useIsLastTimeSlotExpired = (timeSlots, scheduledDate) => {

    if (!timeSlots || timeSlots.length === 0) {
        return false
    }

    const lastTimeSlot = timeSlots.at(-1)
    const timeOfChip = lastTimeSlot ? dateHelpers.combineDateAndTime(scheduledDate, lastTimeSlot.id) : null

    return timeOfChip ? dateHelpers.isSGTDatePast(timeOfChip) : false
}
