import { useHive } from '@propertyguru/hive'
import React, { ReactNode } from 'react'
import { View } from 'react-native'

type Props = {
    children: ReactNode
}

export const AlertV2Icon: React.FunctionComponent<Props> = ({ children }) => {
    const hive = useHive()

    return (
        <View style={{ paddingVertical: hive.spacing('x1') }}>
            {children}
        </View>
    )
}
