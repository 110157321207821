import React from 'react'
import { View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Button } from '@propertyguru/hive-button'
import { isAndroid, isNative } from 'lib/common'
import { createStyles } from 'lib/styles'
import { linkingHelpers } from 'lib/utils'
import { useStyles, useTranslations } from 'lib/hooks'
import { Images } from 'assets'
import { Icons } from 'assets/native'
// import { CustomFonts } from 'lib/types'
import { SvgProps } from 'react-native-svg'

export type Svg = React.FunctionComponent<SvgProps>
export type SvgUrl = { url: string }
export type SvgIcon = Svg | SvgUrl

type SocialAuthAction = {
    key: string;
    enabled: boolean;
    image: Images;
    text: string;
    icon: Svg;
    onPress: VoidFunction;
    accessibilityLabel?: string;
}

type SocialButtonsProps = {
    prefixText?: string,
    onContinueWithFacebook?: VoidFunction,
    onContinueWithApple?: VoidFunction,
    onContinueWithGoogle?: VoidFunction
}

export const SocialButtonsNative: React.FunctionComponent<SocialButtonsProps> = ({
    prefixText,
    onContinueWithFacebook,
    onContinueWithApple,
    onContinueWithGoogle
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const socialAuth: Array<SocialAuthAction> = [
        {
            key: 'facebook',
            enabled: true,
            image: Images.Facebook,
            icon: Icons.FacebookIcon,
            text: isNative
                ? T.components.socialButtons.nativeFacebook
                : T.components.socialButtons.facebook,
            onPress: onContinueWithFacebook ? onContinueWithFacebook : () => { },
            accessibilityLabel: T.accessibility.native.socialButtons.facebook
        },
        {
            key: 'apple',
            enabled: !isAndroid,
            image: Images.Apple,
            icon: Icons.AppleIcon,
            text: isNative ? T.components.socialButtons.nativeApple : T.components.socialButtons.apple,
            onPress: () => {
                if (isNative) {
                    return onContinueWithApple && onContinueWithApple()
                }

                linkingHelpers.openSendhelperAppInStore()
            },
            accessibilityLabel: T.accessibility.native.socialButtons.apple
        },
        {
            key: 'google',
            enabled: isNative,
            image: Images.Google,
            icon: Icons.GoogleIcon,
            text: T.components.socialButtons.nativeGoogle,
            onPress: onContinueWithGoogle ? onContinueWithGoogle : () => { },
            accessibilityLabel: T.accessibility.native.socialButtons.google
        }
    ].filter(item => item.enabled)

    // useEffect(() => {
    //     initFacebookSDK(APP_CONFIG.KEYS.FACEBOOK_SCRIPT_ID, Languages.en_US)
    //         .catch(R.T)
    //     // todo for now not supported
    //     // initAppleSDK(APP_CONFIG.KEYS.APPLE_SCRIPT_ID, Languages.en_US)
    //     //     .catch(R.T)
    // }, [])

    return (
        <View
            style={styles.buttonContentWrapper}
        >
            {
                prefixText && (
                    <Text typography="body/xs">{prefixText}</Text>
                )
            }
            {socialAuth.map((item, index) => (
                <View key={index}>
                    <Button.Icon icon={item.icon} accessibilityLabel={item.accessibilityLabel} onPress={item.onPress} />
                </View>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    buttonContentWrapper: {
        flexDirection: 'row',
        width: '100%',
        gap: theme.utils.gap(1),
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
