import { useAtom } from 'jotai'
import { FeatureFlag } from 'lib/types'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'

const initialFeatureFlag: FeatureFlag = {
    paymentBySessionFlow: true,
    isEnableDynamicPricing: true
}

const { atom, resetAtom } = createAtom<FeatureFlag>(initialFeatureFlag, MMKVKey.FeatureFlag)

export const useFeatureFlagAtom = () => useAtom(atom)
export const useResetFeatureFlagAtom = () => useAtom(resetAtom)
