import React from 'react'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Typography } from 'lib/components'
import { CustomFonts, SelectInputOption } from 'lib/types'

type LabelProps = {
    item: SelectInputOption,
    isSelected: boolean
}

const Label: React.FunctionComponent<LabelProps> = React.memo(({ item, isSelected }) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Typography.Regular
            style={{
                ...styles.itemLabel,
                ...(isSelected ? styles.itemLabelSelected : {})
            }}
        >
            {item.label}
        </Typography.Regular>
    )
})

export default Label

const stylesheet = createStyles(theme => ({
    itemLabel: {
        fontSize: 16,
        fontFamily: CustomFonts.Roboto400
    },
    itemLabelSelected: {
        color: theme.colors.orange
    }

}))
