import React from 'react'
import { View, ScrollView, RefreshControl } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useHive } from '@propertyguru/hive'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Text } from '@propertyguru/hive-text'
import { useShouldAskForPushNotificationPermissionAtom, useToastAtom } from 'lib/atoms'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { AnimatedTabs } from 'lib/components'
import { Measurements } from 'lib/common'
import { NotificationType } from 'lib/types'
import { useGetNotifications } from 'features/notifications/actions'
import { useNotificationsTabs, useNotifications } from 'features/notifications/hooks'
import { EnablePushNotificationModal } from 'features/pushNotifications'
import { BackButton, RefreshButton } from 'features/native/common/components'

export const NotificationsScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const hive = useHive()
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const notificationsTabs = useNotificationsTabs()
    const [, setToastMessage] = useToastAtom()
    const [allMessagesTab, unreadMessagesTab] = notificationsTabs
    const [shouldRequestPermission] = useShouldAskForPushNotificationPermissionAtom()

    const { refetch, isRefetching } = useGetNotifications(false, () => {
        setToastMessage({
            message: T.screens.notifications.statusMessages.success,
            type: NotificationType.Success
        })
    })

    const { renderNotifications: renderAllNotifications } = useNotifications({
        emptyTitle: isRefetching
            ? T.screens.notifications.statusMessages.inProgress
            : T.screens.notifications.noNotifications.title,
        emptyMessage: isRefetching ? '' : T.screens.notifications.noNotifications.message
    })
    const { renderNotifications: renderUnreadNotifications } = useNotifications({
        emptyTitle: isRefetching
            ? T.screens.notifications.statusMessages.inProgress
            : T.screens.notifications.noUnreadNotifications.title,
        emptyMessage: isRefetching ? '' : T.screens.notifications.noUnreadNotifications.message,
        filterNotifications: notification => !notification.read
    })

    const tabs = [{ text: allMessagesTab.text }, { text: unreadMessagesTab.text }]

    return (
        <View style={styles.container}>
            <View
                style={{
                    ...styles.backButtonContainer,
                    marginTop: insets.top + hive.spacing('x4')
                }}
            >
                <BackButton onPress={() => (navigation.canGoBack() ? navigation.goBack() : null)} />
                <Text typography="label/l" style={styles.container}>{T.screens.notifications.pageTitle}</Text>
                <RefreshButton onPress={refetch} />
            </View>
            <ScrollView refreshControl={<RefreshControl progressViewOffset={hive.spacing('x4')} refreshing={isRefetching} onRefresh={refetch} tintColor={hive.color('fill/active/primary')}/>}>
                {!isRefetching && (
                    <AnimatedTabs tabs={tabs} activeLabelStyles={styles.activeLabel}>
                        {() => (
                            <React.Fragment>
                                <View style={styles.tabContainer}>{renderAllNotifications()}</View>
                                <View style={styles.tabContainer}>{renderUnreadNotifications()}</View>
                            </React.Fragment>
                        )}
                    </AnimatedTabs>
                )}
            </ScrollView>
            <EnablePushNotificationModal
                // should be visible every time user enters notification screen
                shouldBeVisible={shouldRequestPermission}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string
    },
    backButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.hive.spacing('x4'),
        borderBottomWidth: 0.5,
        borderColor: theme.utils.hive.color('border/active/secondary') as string
    },
    tabContainer: {
        width: Measurements.WindowWidth,
        height: Measurements.WindowHeight
    },
    activeLabel: {
        color: theme.colors.orange
    }
}))
