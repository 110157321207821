import React from 'react'
import { View  } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Field, useForm } from '@codegateinc/react-form-builder-v2'
import { AvoidSoftInputView } from 'react-native-avoid-softinput'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { ScreenNames } from 'lib/routing'
import { ProviderDetails } from 'lib/models'
import { useAvoidInput, useBackHandler, useStyles, useTranslations } from 'lib/hooks'
import { Separator, Touchable, Typography } from 'lib/components'
import { SignUpForm, SocialButtons, TermsAndPrivacy } from '../components'
import { useSignUp, useSignIn } from '../hooks'
import { SignUpFormShape, useSignUpForm } from '../forms'
import { SocialButtonVariant } from '../types'
import { isIOS } from 'lib/common'

export const SignUpNativeScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const insets = useSafeAreaInsets()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const { isLoading, verifyUserEmail, signUpWithApple } = useSignUp()
    const { signInWithFacebook, signInWithGoogle, isLoading: isSignInLoading } = useSignIn()
    const { form, submit, isFilled, setError, hasError } = useForm<SignUpFormShape>(useSignUpForm(ProviderDetails.Email), {
        onSuccess: form => verifyUserEmail(
            form,
            (authUser, verifyCode) => navigation.navigate(ScreenNames.AuthVerificationCodeNative, {
                authUser,
                verifyCode
            }),
            setError
        )
    })
    const translationY = useSharedValue(0)
    const scrollHandler = useAnimatedScrollHandler(event => {
        translationY.value = event.contentOffset.y
    })

    useAvoidInput()
    useBackHandler(() => isLoading || isSignInLoading)

    return (
        <View style={styles.wrapper}>
            <Animated.ScrollView
                onScroll={scrollHandler}
                scrollEventThrottle={16}
                showsVerticalScrollIndicator={false}
            >
                <AvoidSoftInputView>
                    <View
                        style={{
                            ...styles.contentContainer,
                            paddingBottom: insets.bottom + theme.utils.gap(2)
                        }}
                    >
                        <View
                            style={styles.navigationWrapper}
                        >
                            <Touchable
                                style={styles.backButton}
                                onPress={() => navigation.navigate(ScreenNames.LogInNative)}
                            >
                                <View style={styles.chevron}>
                                    <Typography.Regular>
                                        <Icons.Chevron
                                            size={10}
                                            forceColor={theme.colors.night}
                                        />
                                    </Typography.Regular>
                                </View>
                            </Touchable>
                        </View>
                        <View style={styles.headerText}>
                            <Typography.Subheading>
                                {T.common.signUp}
                            </Typography.Subheading>
                            <Touchable
                                testID={T.accessibility.signUp.hasAccountLogin}
                                style={styles.subHeaderText}
                                onPress={() => navigation.navigate(ScreenNames.LogInNative)}
                            >
                                <Typography.Regular forceColor={theme.colors.darkGrey}>
                                    {T.screens.auth.signUpHeaderTextNative}
                                </Typography.Regular>
                            </Touchable>
                        </View>
                        <SocialButtons
                            disabled={isLoading || isSignInLoading}
                            onContinueWithFacebook={signInWithFacebook}
                            onContinueWithApple={signUpWithApple}
                            onContinueWithGoogle={signInWithGoogle}
                            variant={SocialButtonVariant.Narrow}
                        />
                        <Separator
                            height={20}
                            text={T.screens.auth.signUpSeparatorNative}
                        />
                        <SignUpForm
                            providerDetails={ProviderDetails.Email}
                            form={form as Record<keyof SignUpFormShape, Field<string & boolean>>}
                            isLoading={isLoading || isSignInLoading}
                            onSubmit={submit}
                            formHasError={!isFilled || hasError}
                        />
                        <View style={styles.terms}>
                            <TermsAndPrivacy/>
                        </View>
                    </View>
                </AvoidSoftInputView>
            </Animated.ScrollView>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    navigationWrapper: {
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    backButton: {
        borderWidth: 1,
        alignItems: 'center',
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1),
        borderRadius: 4
    },
    chevron: {
        transform: [{ rotate: '90deg' }, { translateY: isIOS ? 3: 0 }]
    },
    wrapper: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    contentContainer: {
        marginTop: 40,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(2)
    },
    forgotPasswordContainer: {
        alignItems: 'flex-end',
        marginBottom: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        }
    },
    forgotButton: {
        borderBottomWidth: 1
    },
    submit: {
        alignItems: 'center'
    },
    headerText: {
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    subHeaderText: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(1)
    },
    resetPasswordMessage: {
        marginVertical: theme.utils.gap(3)
    },
    resetPasswordMessageText: {
        textAlign: 'center'
    },
    terms:{
        marginTop: theme.utils.gap(2)
    }
}))
