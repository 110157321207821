import React, { ReactNode } from 'react'
import { View } from 'react-native'

type Props = {
    children: ReactNode,
}

export const FooterButtonsInfo = ({ children }: Props) => (
    <View>
        {children}
    </View>
)
