import React, { useState } from 'react'
import { View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Icon } from '@propertyguru/hive-icon'
import { Surface } from '@propertyguru/hive-surface'
import {
    ChevronDownSmallOutline,
    ChevronTopSmallOutline,
    TicketAdmitVipOutline
} from '@propertyguru/hive-icons'
import { Touchable } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { BookingFrequency } from 'lib/native/models'
import { useNativeJobBookingDetailAtom, useNativeJobQuotationsAtom } from 'lib/native/atoms'
import { FooterBreakdownBodyWrapper, Footer } from 'features/native/bookings/components'
import { Segment, SegmentNative } from 'lib/analytics'
import { SpinningIcon } from 'features/native/common/components'

type Props = {
    showPromoCode?: boolean
}

export const FooterBreakdownWrapper: React.FunctionComponent<Props> = props => {
    const { showPromoCode } = props
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const [jobQuotationsAtom] = useNativeJobQuotationsAtom()
    const [jobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const isOneTime = jobBookingDetailAtom.frequency === BookingFrequency.OneTime
    const jobQuotationsTotalData = isOneTime ? jobQuotationsAtom?.total : jobQuotationsAtom?.totalSubscription
    const promotion = jobBookingDetailAtom.promo
    const isLoading = jobQuotationsAtom?.isLoading
    const [isBreakdownVisible, setIsBreakdownVisible] = useState(false)
    const hasPromoCode = showPromoCode && promotion
    const totalPrice = usePrice(jobQuotationsTotalData?.value ?? 0)
    const handleBreakdownPress = () => {
        setIsBreakdownVisible(!isBreakdownVisible)
        isBreakdownVisible && Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.ViewSummaryClicked })
    }

    return (
        <Footer.Breakdown>
            {isBreakdownVisible && <FooterBreakdownBodyWrapper />}
            <Footer.Breakdown.Actions>
                {
                    isLoading ? (
                        <Surface style={styles.loadingContainer}>
                            <SpinningIcon />
                        </Surface>
                    ) :(
                        <Touchable style={styles.footerActionsItem} onPress={handleBreakdownPress}>
                            <Text typography="label/l">
                                {totalPrice}
                            </Text>
                            <Text typography="label/s">
                                {T.screens.cleaningNative.footerBreakdown.nett}
                            </Text>
                        </Touchable>
                    )
                }

                {/* TODO: replace with hive badge color="lightGreen" once available */}
                {hasPromoCode && (
                    <View style={styles.badgeLightGreen}>
                        <Icon icon={TicketAdmitVipOutline} color="icon/success/primary" width={16} height={16} />
                        <Text typography="caption/xs" color="text/success/primary">
                            {promotion?.promo_code?.toUpperCase()} {T.common.applied}
                        </Text>
                    </View>
                )}
                {!showPromoCode && (
                    <Touchable style={styles.viewMoreBtn} onPress={handleBreakdownPress}>
                        <Text typography="body/xs" color="text/active/primary">
                            {
                                isBreakdownVisible ?
                                    T.screens.cleaningNative.footerBreakdown.hideSummary :
                                    T.screens.cleaningNative.footerBreakdown.viewSummary
                            }

                        </Text>
                        <Icon
                            width={24}
                            height={24} icon={
                                isBreakdownVisible ?
                                    ChevronDownSmallOutline :
                                    ChevronTopSmallOutline
                            }
                        />
                    </Touchable>
                )}
            </Footer.Breakdown.Actions>
        </Footer.Breakdown>
    )
}

const stylesheet = createStyles(theme => ({
    footerActionsItem: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.utils.hive.spacing('x1')
    },
    viewMoreBtn: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    badgeLightGreen: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: theme.utils.hive.borderRadius('small'),
        paddingVertical: theme.utils.hive.spacing('x1'),
        paddingHorizontal: theme.utils.hive.spacing('x2'),
        gap: theme.utils.hive.spacing('x1'),
        backgroundColor: theme.utils.hive.color('fill/success/secondary') as string
    },
    loadingContainer: {
        height: 28
    }
}))
