import { useTranslations } from 'lib/hooks'

export const useDayNames = () => {
    const T = useTranslations()

    return [
        T.components.hiveCalendar.days.sunday,
        T.components.hiveCalendar.days.monday,
        T.components.hiveCalendar.days.tuesday,
        T.components.hiveCalendar.days.wednesday,
        T.components.hiveCalendar.days.thursday,
        T.components.hiveCalendar.days.friday,
        T.components.hiveCalendar.days.saturday
    ]
}

export const useMonthNames = () => {
    const T = useTranslations()

    return [
        T.components.hiveCalendar.months.january,
        T.components.hiveCalendar.months.february,
        T.components.hiveCalendar.months.march,
        T.components.hiveCalendar.months.april,
        T.components.hiveCalendar.months.may,
        T.components.hiveCalendar.months.june,
        T.components.hiveCalendar.months.july,
        T.components.hiveCalendar.months.august,
        T.components.hiveCalendar.months.september,
        T.components.hiveCalendar.months.october,
        T.components.hiveCalendar.months.november,
        T.components.hiveCalendar.months.december
    ]
}
