import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { FooterBreakdownActions } from './BreakdownActions.native'
import { FooterBreakdownTitle } from './BreakdownTitle'
import FooterBreakdownBody from './BreakdownBody.native'

type Props = {
    children: ReactNode,
}

const FooterBreakdown = ({ children }: Props) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingTop: theme.utils.hive.spacing('x4'),
        paddingHorizontal: theme.utils.hive.spacing('x4')
    }
}))

export default Object.assign(FooterBreakdown, {
    Title: FooterBreakdownTitle,
    Body: FooterBreakdownBody,
    Actions: FooterBreakdownActions
})
