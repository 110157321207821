import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Surface } from '@propertyguru/hive-surface'
import { Icon } from '@propertyguru/hive-icon'
import { ArrowRightOutline, BroomBrushOutline, RotateRepeatRefreshFill } from '@propertyguru/hive-icons'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { addHoursToDate, shortDateMonth, selectTimeLabel } from 'lib/utils/date'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { NativeStackProps } from 'lib/native/routing'
import { RecurringData } from '../types'

type RecurringCardParams = {
    booking: RecurringData;
}

export const RecurringCard: React.FunctionComponent<RecurringCardParams> = ({ booking }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation<NativeStackProps<ScreenNamesNative>>()
    const handleCtaPress = () => {
        navigation.navigate(ScreenNamesNative.CleaningDetails, { jobId: booking._id })
    }

    return (
        <Surface elevation="soft/down/large" style={styles.container}>
            <Surface style={styles.wrapper}>
                <Surface style={styles.cardSectionIcon}>
                    <Icon icon={BroomBrushOutline} height={24} width={24} />
                </Surface>
                <Surface style={styles.cardSectionLarge}>
                    <Text style={styles.cardHeader}>
                        <Text typography="label/s" style={styles.cardLabel}>
                            {shortDateMonth(booking.date_time)}
                        </Text>
                        <Text typography="label/s" style={styles.cardLabel}>
                            {' '}
                            |{' '}
                        </Text>
                        <Text typography="label/s" style={styles.cardLabel}>
                            {' '}
                            {selectTimeLabel(booking.date_time)} -{' '}
                            {selectTimeLabel(addHoursToDate(booking.date_time, booking.num_hours))}
                        </Text>
                    </Text>
                    <Surface style={styles.cardDescription}>
                        <Text typography="caption/xs" style={styles.cardDescriptionLabel}>
                            {T.screens.dashboard.recurringCard.upcomingRegularCleaning}
                        </Text>
                        <Icon icon={RotateRepeatRefreshFill} height={16} width={16} />
                    </Surface>
                </Surface>
                <Surface style={styles.cardSectionCTA}>
                    <Button.Icon icon={ArrowRightOutline} size="small" onPress={handleCtaPress} />
                </Surface>
            </Surface>
            <Surface style={styles.statusBarWrapper}>
                <Surface style={styles.statusBar} />
            </Surface>
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderRadius: theme.utils.hive.borderRadius('medium'),
        marginTop: theme.utils.hive.spacing('x1'),
        marginBottom: theme.utils.hive.spacing('x3')
    },
    wrapper: {
        flexDirection: 'row',
        gap: theme.utils.hive.spacing('x2'),
        backgroundColor: theme.colors.white,
        padding: theme.utils.hive.spacing('x3'),
        borderRadius: theme.utils.hive.borderRadius('medium'),
        position: 'relative',
        height: 64,
        alignItems: 'center'
    },
    cardHeader: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row'
    },
    cardSectionIcon: {
        justifyContent: 'center',
        alighItems: 'center'
    },
    cardSectionCTA: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    cardSectionLarge: {
        flex: 1
    },
    statusBarWrapper: {
        position: 'absolute',
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    statusBar: {
        height: 35,
        width: theme.utils.hive.spacing('x1'),
        borderTopRightRadius: theme.utils.hive.borderRadius('medium'),
        borderBottomRightRadius: theme.utils.hive.borderRadius('medium'),
        backgroundColor: theme.utils.hive.color('fill/active/primary') as string
    },
    cardDescription: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.hive.spacing('x1'),
        display: 'flex'
    },
    cardDescriptionLabel: {
        marginRight: theme.utils.hive.spacing('x1'),
        fontWeight: '400'
    },
    cardLabel: {
        fontWeight: '700'
    }
}))
