import React, { useState } from 'react'
import { NativeSyntheticEvent, TextInputChangeEventData, View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Button } from '@propertyguru/hive-button'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { checkEmailValid } from 'lib/native/utils'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { SendForgotPasswordEmailActions } from 'features/native/constants'
import { TextInputField, UnauthorizedNativeLayout } from 'features/native/common/components'
import {
    ForgotPasswordScreenParams as ForgotPasswordScreenProps
} from 'lib/native/routing/screenParams'
import { requestSendForgotPasswordEmail } from 'features/native/actions'
import { Segment } from 'lib/analytics'

export const ForgotPasswordScreen: React.FunctionComponent<ForgotPasswordScreenProps> = ({ navigation }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [email, setEmail] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { mutate: requestSendEmailResetPassword, isLoading: isSending } = requestSendForgotPasswordEmail()
    const shouldShowError = Boolean(errorMessage)
    const shouldDisableSubmitButton = !email.trim() || isSending

    const onSubmit = () => {
        setErrorMessage('')

        if (!checkEmailValid(email)) {
            setErrorMessage(T.screens.forgotPassword.emailInvalid)
            Segment.emailValidated({
                status: false,
                errorMessage: T.screens.forgotPassword.emailInvalid
            })

            return
        }

        requestSendEmailResetPassword({ email, action: SendForgotPasswordEmailActions.Send }, {
            onSuccess: response => {
                const timerValue = response?.data?.timerValue
                Segment.emailValidated({
                    status: true
                })

                const screenParams = {
                    email,
                    sentAt: Date.now(),
                    timerValue: timerValue ?? 0
                }

                navigation?.navigate(ScreenNamesNative.VerifyIdentityNative, screenParams)
            },
            onError: error => {
                Segment.emailValidated({
                    status: false,
                    errorMessage: error?.errors?.error_msg || T.common.error
                })
                setErrorMessage(error?.errors?.error_msg || T.common.error)
            }
        })
    }

    const onChangeEmail = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        if (shouldShowError) {
            setErrorMessage('')
        }

        setEmail(e.nativeEvent.text)
    }

    const onEmailBlur = () => {
        if (email.length === 0) {
            setErrorMessage('')

            return
        }

        if (!checkEmailValid(email)) {
            setErrorMessage(T.screens.forgotPassword.emailInvalid)
        }
    }

    const goToLoginScreen = () => {
        navigation?.navigate(ScreenNamesNative.LogInNative)
    }

    return (
        <UnauthorizedNativeLayout fullScrollContainerHeight={false}>
            <View style={styles.container}>
                <View style={styles.titleContainer}>
                    <Text typography="title/xs" style={styles.title}>{T.screens.forgotPassword.title}</Text>
                    <Text typography="body/s" style={styles.subTitle}>{T.screens.forgotPassword.subTitle}</Text>
                    <Text typography="body/s" style={styles.description}>{T.screens.forgotPassword.description}</Text>
                </View>
                <TextInputField
                    value={email}
                    onChange={onChangeEmail}
                    onBlur={onEmailBlur}
                    errorMessage={errorMessage}
                    placeholder={T.screens.forgotPassword.email}
                    accessibilityLabel={T.accessibility.native.forgotPassword.email}
                    errorAccessibilityLabel={T.accessibility.native.forgotPassword.emailError}
                />
                <View style={styles.actionContainer}>
                    <Button disabled={shouldDisableSubmitButton} loading={isSending} accessibilityLabel={T.accessibility.native.forgotPassword.sendVerificationCode} onPress={onSubmit} type="primary" text={T.screens.forgotPassword.sendVerificationCode} />
                </View>
                <View style={styles.linkContainer}>
                    <Text onPress={goToLoginScreen} accessibilityLabel={T.accessibility.native.forgotPassword.backToLogin} typography="body/xs" style={styles.link}>{T.screens.forgotPassword.backToLogin}</Text>
                </View>
            </View>
        </UnauthorizedNativeLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.utils.gap(2),
        flex: 1,
        paddingHorizontal: {
            xs: theme.utils.gap(2)
        }
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: theme.utils.gap(1.5)
    },
    actionContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    },
    title: {
        fontWeight: '600'
    },
    subTitle: {
        marginTop: theme.utils.gap(1.5)
    },
    description: {
        marginTop: theme.utils.gap(1.75)
    },
    linkContainer: {
        marginTop: theme.utils.gap(2)
    },
    link: {
        textDecorationLine: 'underline'
    },
    textInput: {
        width: '100%'
    },
    errorMessage: {
        marginTop: theme.utils.gap(0.5)
    }
}))
