import React from 'react'
import Animated, { SharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type OnboardingPaginationDotProps = {
    index: number,
    activeIndex: SharedValue<number>
}

export const LandingPaginationBar: React.FunctionComponent<OnboardingPaginationDotProps> = ({
    index,
    activeIndex
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const activeAnimatedStyles = useAnimatedStyle(() => {
        const isActive = index === activeIndex.value

        return {
            backgroundColor: withTiming(isActive
                ? theme.colors.sunLight
                : theme.colors.lightSilver
            )
        }
    })

    return (
        <Animated.View style={[styles.dot, activeAnimatedStyles]} />
    )
}

const stylesheet = createStyles(() => ({
    dot: {
        width: 65,
        height: 5,
        borderRadius: 10,
        marginHorizontal: 2
    }
}))
