import { useStyles } from 'lib/hooks'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import React, { ReactNode } from 'react'

type Props = {
    children: ReactNode,
}

export const FooterBreakdownActions = ({ children }: Props) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}))
