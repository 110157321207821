import { useForm } from '@codegateinc/react-form-builder-v2'
import { BookingDateModal, bookingsHooks } from 'features/bookings'
import { isAirconParentSubscription, isAirconSubscription } from 'features/bookings/utils/airconUtils'
import { contactUs } from 'features/contact/actions'
import { Segment, segmentUtils } from 'lib/analytics'
import { useDynamicServiceAtom, useJobScheduleLogsAtom, useLocaleAtom, useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { CallToActionButton, Typography } from 'lib/components'
import { useJobListeners, useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { NotificationType } from 'lib/types'
import { dateHelpers, emailTemplateHelper, emitter, getAppVersionForPlatform, jobHelpers } from 'lib/utils'
import React, { useState } from 'react'
import { Platform, View } from 'react-native'
import { getAirConditionJobDetails, getConsumerActiveJobs, getJobDetails, rescheduleJob } from '../actions'
import { RescheduleFields, RescheduleFormShape, useRescheduleBookingForm, useRescheduleForm } from '../forms'
import { JobDetailsResponse } from '../types'
import { RescheduleBookingModal } from './rescheduleBookingModal'
import { RescheduleConfirmationModal } from './rescheduleConfirmationModal'

type RescheduleSessionProps = {
    data: JobDetailsResponse,
    label?: string,
    fullWidth?: boolean,
    description?: string,
    buttonWidth?: number,
    disabled?: boolean
}

export const RescheduleAirconSession: React.FunctionComponent<RescheduleSessionProps> = ({
    data,
    label,
    fullWidth,
    description,
    buttonWidth,
    disabled
}) => {

    const T = useTranslations()
    const [locale] = useLocaleAtom()
    const [user] = useUserAtom()
    const [token] = useUserTokenAtom()
    const { styles, theme } = useStyles(stylesheet)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { onRequestError } = useQueryHelpers()
    const [, setToastMessage] = useToastAtom()
    const [jobScheduleLogs, setJobScheduleLogs] = useJobScheduleLogsAtom()
    const [config] = useDynamicServiceAtom(data.job.supply.id)
    const [isTriggerByEventEmitter, setIsTriggerByEventEmitter] = useState<boolean>(false)
    const bookingDays = bookingsHooks.useBookingDays()
    const { mutate: sendContact, isLoading: isContactLoading } = contactUs()
    const { mutate: reschedule, isLoading: isMutating } = rescheduleJob()
    const getJobDetailsSource = isAirconSubscription(data.job) ? getAirConditionJobDetails : getJobDetails
    const { refetch: refetchJobDetails, isRefetching: isRefetchingJobDetails } = getJobDetailsSource(data.job._id)
    const { refetch: refetchActiveJobs, isRefetching: isRefetchingActiveJobs } = getConsumerActiveJobs()
    const rescheduleForm = useForm<RescheduleFormShape>(useRescheduleBookingForm(data, bookingDays), {
        onSuccess: (form: RescheduleFormShape) => {
            const { bookingTime } = form

            if (jobHelpers.shouldRescheduleJob(data)) {

                reschedule(
                    {
                        token,
                        job: data.job._id,
                        new_date: bookingTime
                    },
                    {
                        onSuccess: () => {
                            Segment.bookingRescheduled({
                                jobId: data.job.job_id,
                                serviceType: segmentUtils.supplyIdToServiceType(data.job.supply.id),
                                dateTime: bookingTime,
                                jobFrequency: data.job.frequency
                            })
                            setIsRescheduleBookingModalOpen(false)
                            refetchJobDetails()
                            refetchActiveJobs()
                        },
                        onError: onRequestError
                    }
                )

                return
            }

            setJobScheduleLogs({ [data.job._id]: {
                job: data,
                rescheduleForm: form,
                locale
            }, ...jobScheduleLogs })

            const rescheduledBookingRequest = jobHelpers.getRescheduledBookingRequest(data, form)

            // Currently, we send a contact form and show the modal request send only. Don't call API reschedule job
            sendContact(
                {
                    token,
                    profile: user,
                    contents: `
${emailTemplateHelper.getScheduleRequestMessage(data, form, locale)}
${Platform.OS} - ${getAppVersionForPlatform()} ${token}`,
                    ...(rescheduledBookingRequest || {})
                },
                {
                    onSuccess: () => {
                        setIsRescheduleBookingModalOpen(false)
                        setIsRescheduleConfirmationModalOpen(true)
                        // setIsRescheduleSuccessModalOpen(true)

                        if (data.job.recurring_job) {
                            refetchParentJob()
                        }

                        refetchJobDetails()
                    },
                    onError: onRequestError
                }
            )

        },
        onError: error => {
            console.log('error', error)

        }
    })
    const resetFormData = (jobDetail: JobDetailsResponse | null = null) => {
        resetForm()
        resetRescheduleForm()
        reInitRescheduleFormValue(jobDetail)
    }
    const [isRescheduleBookingModalOpen, setIsRescheduleBookingModalOpen] = useState<boolean>(false)
    const [isRescheduleConfirmationModalOpen, setIsRescheduleConfirmationModalOpen] = useState<boolean>(false)

    useJobListeners(data, resetFormData, setIsRescheduleBookingModalOpen, () => {}, setIsTriggerByEventEmitter)
    const { form, submit, resetForm } = useForm<RescheduleFormShape>(useRescheduleForm(), {
        onSuccess: ({ bookingTime }) => {
            reschedule(
                {
                    token,
                    job: data.job._id,
                    new_date: bookingTime
                },
                {
                    onSuccess: () => {
                        resetForm()
                        refetchJobDetails()
                        refetchActiveJobs()
                        setIsModalOpen(false)
                        setToastMessage({
                            type: NotificationType.Success,
                            message: T.screens.jobDetails.rescheduleSuccessMessage
                        })
                        Segment.rescheduleBookingClicked({
                            jobId: data.job.job_id,
                            serviceType: segmentUtils.supplyIdToServiceType(data.job.supply.id)
                        })
                    },
                    onError: onRequestError
                }
            )
        }
    })
    const {
        refetch: refetchParentJob
    } = getJobDetailsSource(data.job.recurring_job || data.job._id)

    if(!jobHelpers.shouldShowRescheduleButton(data)) {
        return null
    }

    const getRescheduleButtonLabel = (jobDetail: JobDetailsResponse, defaultLabel: string): string => {
        const jobId = jobDetail?.job?._id
        const isSubmittedSubscription = jobHelpers.isSubmittedSubscription(jobDetail)
        const isAcceptedSubscription = jobHelpers.isAcceptedSubscription(jobDetail)
        const jobScheduleLog = jobScheduleLogs[jobId as string]

        if (isSubmittedSubscription || (isAcceptedSubscription && isAirconParentSubscription(jobDetail.job))) {
            return jobScheduleLog ?
                T.screens.jobDetails.updateRescheduleRequest :
                T.screens.jobDetails.rescheduleTheBooking
        }

        if (isAirconSubscription(jobDetail.job)) {
            return jobScheduleLog ?
                T.screens.jobDetails.updateRescheduleRequest :
                T.screens.jobDetails.rescheduleSession
        }

        return defaultLabel
    }

    const isLoading = isMutating || isRefetchingJobDetails || isRefetchingActiveJobs

    const defaultLabel = label || T.screens.jobDetails.rescheduleSession

    const { submit: onReschedule, resetForm: resetRescheduleForm } = rescheduleForm

    const reInitRescheduleFormValue = (jobDetail: JobDetailsResponse | null = null) => {
        const initialValue = jobHelpers.getRescheduleFormInitialValue(jobDetail || data, bookingDays)
        const bookingTimeInitialValue = initialValue.bookingTime
        const startingDateInitialValue = initialValue.startingDate
        const frequencyInitialValue = initialValue.frequency
        const bookingDaysInitialValue = initialValue.bookingDays

        rescheduleForm.form.startingDate.onChangeValue(startingDateInitialValue)
        rescheduleForm.form.bookingTime.onChangeValue(bookingTimeInitialValue)
        rescheduleForm.form.frequency.onChangeValue(frequencyInitialValue)
        rescheduleForm.form.bookingDays.onChangeValue(bookingDaysInitialValue)
        // Need to run setFieldInitialValue after trigger event onChangeValue, to ensure that not detect the form has changed
        rescheduleForm.setFieldInitialValue(RescheduleFields.StartingDate, startingDateInitialValue)
        rescheduleForm.setFieldInitialValue(RescheduleFields.BookingTime, bookingTimeInitialValue)
        rescheduleForm.setFieldInitialValue(RescheduleFields.Frequency, frequencyInitialValue)
        rescheduleForm.setFieldInitialValue(RescheduleFields.BookingDays, bookingDaysInitialValue)
    }

    return (
        <View style={fullWidth ? styles.containerFullWidth : styles.container}>
            <RescheduleBookingModal
                isOpen={isRescheduleBookingModalOpen}
                job={data}
                formReturn={rescheduleForm}
                isLoading={isLoading}
                onReschedule={onReschedule}
                onClose={() => setIsRescheduleBookingModalOpen(false)}
                onBack={() => {
                    setIsRescheduleBookingModalOpen(false)

                    if (isTriggerByEventEmitter) {
                        setIsTriggerByEventEmitter(false)

                        return
                    }
                }}
            />
            <RescheduleConfirmationModal
                job={data}
                isOpen={isRescheduleConfirmationModalOpen}
                formReturn={rescheduleForm}
                isLoading={isContactLoading}
                onClose={() => setIsRescheduleConfirmationModalOpen(false)}/>
            <BookingDateModal
                testID={T.accessibility.bookingDetailsScreen.reschedule.modal}
                onContinue={submit}
                disabled={isLoading}
                isLoading={isLoading}
                isModalOpen={isModalOpen}
                description={description}
                frequency={form.frequency.value}
                startingDateField={form.startingDate}
                bookingTimeField={form.bookingTime}
                bookingDaysField={form.bookingDays}
                minHoursNotice={config?.min_notice_hours}
                onClose={() => setIsModalOpen(false)}
                title={T.screens.jobDetails.reschedule}
                header={(
                    <View style={styles.currentSessionWrapper}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.currentServiceDate}
                        </Typography.Label>
                        <Typography.Label>
                            {dateHelpers.getFullBookingDate(data.job.date_time || data.job.beginning_date, locale)}
                        </Typography.Label>
                    </View>
                )}
            />
            <View style={fullWidth ? styles.buttonWrapperFullWidth : styles.buttonWrapper}>
                <CallToActionButton
                    onPress={() => {
                        emitter.emit('openRescheduleModal', data)

                        Segment.rescheduleBookingClicked({})
                    }}
                    text={getRescheduleButtonLabel(data, defaultLabel)}
                    width={buttonWidth}
                    disabled={disabled}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1
    },
    containerFullWidth:{
        flex: 1,
        width: '100%'
    },
    buttonWrapper: {
        width: {
            ':w[1250, ]': 240,
            ':w[, 1250]': undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    buttonWrapperFullWidth: {
        marginBottom: theme.utils.gap(2),
        width: '100%'
    },
    currentSessionWrapper: {
        marginLeft: {
            lg: theme.utils.gap(4),
            xs: 0
        }
    }
}))
