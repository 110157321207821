import React from 'react'
import { ViewStyle } from 'react-native'
import Animated, {
    Extrapolation,
    interpolate,
    useAnimatedReaction,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withTiming
} from 'react-native-reanimated'
import { Alert, AlertSeverity } from 'lib/components'
import { useInternetConnection, useStyles, useTranslations } from 'lib/hooks'
import { InternetConnectivityStatus } from 'lib/types'

export const AnimatedConnectivityAlert: React.FunctionComponent = () => {
    const T = useTranslations()
    const { theme } = useStyles()
    const { isOnline } = useInternetConnection()
    const animatedAlertHeight = useSharedValue(0)
    const animatedConnectivity = useSharedValue(isOnline)

    useAnimatedReaction(
        () => ({
            wasOnline: animatedConnectivity.value
        }),
        ({ wasOnline }) => {
            if (wasOnline && isOnline) {
                animatedConnectivity.value = isOnline

                return
            }

            const backOnline = !wasOnline && isOnline
            const wentOffline = wasOnline && !isOnline

            if (!backOnline && !wentOffline) {
                return
            }

            animatedConnectivity.value = isOnline

            if (wentOffline) {
                animatedAlertHeight.value = withTiming(theme.components.alert.minHeight, {
                    duration: 300
                })

                return
            }

            animatedAlertHeight.value = withDelay(1400, withTiming(0, {
                duration: 300
            }))
        },
        [isOnline]
    )

    const animatedAlertStyles = useAnimatedStyle<ViewStyle>(() => ({
        height: animatedAlertHeight.value,
        opacity: interpolate(
            animatedAlertHeight.value,
            [0, theme.components.alert.minHeight],
            [0, 1],
            {
                extrapolateLeft: Extrapolation.CLAMP,
                extrapolateRight: Extrapolation.CLAMP
            }
        )
    }))

    return (
        <Animated.View style={animatedAlertStyles}>
            <Alert
                type={AlertSeverity[
                    isOnline
                        ? AlertSeverity.Success
                        : AlertSeverity.Error
                ]}
                message={`${T.components.animatedConnectivityAlert.internetStatusMessage[
                    isOnline
                        ? InternetConnectivityStatus.online
                        : InternetConnectivityStatus.offline
                ]}`}
            />
        </Animated.View>
    )
}
