import { Job, JobDetails } from 'lib/models'
import { JobType, SupplyID } from 'lib/types'

export const getPricingOptionsForAircon = (airconConfig: object) => {
    const pricingMap = {}
    const plansWithFrequency = Object.keys(airconConfig).map(key => airconConfig[key]).filter(item => !!item.frequency)
    const planItems = plansWithFrequency.length ? plansWithFrequency : [airconConfig]

    planItems.forEach(option => {
        option.items.forEach(pricingOption => {
            const label = pricingOption.label
            const minPrice = `$${pricingOption.priceRange?.min}`
            const maxPrice = `$${pricingOption.priceRange?.max}`
            const rangePrice = pricingOption.priceRange?.max ? `${minPrice} - ${maxPrice}` : minPrice
            const price = pricingOption.priceRange ? rangePrice : `$${pricingOption.price}`

            pricingMap[label] = pricingMap[label] || { label, price: [] }
            pricingMap[label].price.push(price)
        })
    })

    return pricingMap
}

export const isAirconSubscription = (job: Job | JobDetails) => job?.isSubscription || (job.supply.id === SupplyID.ACService && job.job_type === JobType.Recurring)
export const isAirconParentSubscription = (job: Job | JobDetails) => job?.isSubscription || (job.supply.id === SupplyID.ACService && job.job_type === JobType.Recurring && !job.recurring_job)
export const isAirconSubscriptionParentOrChild = (job: Job | JobDetails) => isAirconSubscription(job) || isAirconParentSubscription(job)
