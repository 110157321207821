import { useEffect } from 'react'
import { useGetDynamicPriceSlotsNative } from 'features/native/bookings'
import {
    useNativeDynamicPriceSlotsAtom,
    useNativeResetDynamicPriceSlotsAtom,
    useNativeJobBookingDetailAtom
} from 'lib/native/atoms'
import { BookingFrequency } from 'lib/native/models'

export const useDynamicPriceSlots = () => {
    const [jobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const [dynamicPriceSlots, setDynamicPriceSlots] = useNativeDynamicPriceSlotsAtom()
    const [, resetDynamicPriceSlotsAtom] = useNativeResetDynamicPriceSlotsAtom()
    const { mutate: getDynamicPriceSlots } = useGetDynamicPriceSlotsNative()

    useEffect(() => {
        if (!jobBookingDetailAtom?.frequency || jobBookingDetailAtom?.frequency === BookingFrequency.Recurring) {
            resetDynamicPriceSlotsAtom()

            return
        }

        setDynamicPriceSlots({
            ...dynamicPriceSlots,
            isLoading: true
        })

        getDynamicPriceSlots({}, {
            onSuccess: response => {
                setDynamicPriceSlots({
                    ...(response?.data ?? {}),
                    isLoading: false
                })
            },
            onError: () => {
                resetDynamicPriceSlotsAtom()
            }
        })
    }, [jobBookingDetailAtom?.frequency])
}
