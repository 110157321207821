import React from 'react'
import { Button } from 'lib/components'
import { JobDetails } from 'lib/models'
import { useUserTokenAtom } from 'lib/atoms'
import { useQueryHelpers, useTranslations } from 'lib/hooks'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'
import { acceptCharges, getAirConditionJobDetails, getJobDetails } from '../actions'

type AcceptBillingProps = {
    job: JobDetails
}

export const AcceptBilling: React.FunctionComponent<AcceptBillingProps> = ({
    job
}) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const { mutate: onAcceptCharges, isLoading } = acceptCharges()
    const { onRequestError } = useQueryHelpers()
    const { refetch, isRefetching } =  isAirconSubscription(job) ? getAirConditionJobDetails(job._id) : getJobDetails(job._id)

    const handleAccept = () => {
        onAcceptCharges(
            {
                token,
                id: job._id,
                current_charges: job?.customer_charges || []
            },
            {
                onSuccess: () => refetch(),
                onError: onRequestError
            }
        )
    }

    return (
        <Button
            onPress={handleAccept}
            isLoading={isLoading || isRefetching}
            text={T.common.accept}
        />
    )
}
