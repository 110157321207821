import React from 'react'
import { Typography } from 'lib/components'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Icons } from 'assets'
import { useDynamicPriceAtom, useDynamicPriceSelectedAtom } from 'lib/atoms'

export const SelectedDiscountTimeSlotMessage: React.FunctionComponent = () => {
    const [dynamicPriceData] = useDynamicPriceAtom()
    const [dynamicPriceSelected] = useDynamicPriceSelectedAtom()
    const { styles, theme } = useStyles(stylesheet)

    return dynamicPriceSelected?.slotId ? (
        <View style={styles.discountTimeSlotMessageContainer}>
            <View style={styles.discountTimeSlotIcon}>
                <Icons.Celebrate size={19} viewBox="0 0 19 19"/>
            </View>
            <Typography.Error forceColor={theme.colors.green}>
                {dynamicPriceData?.messages?.dynamicPricingMessage}
            </Typography.Error>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    discountTimeSlotMessageContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.utils.gap(0.5)
    },
    discountTimeSlotIcon: {
        marginRight: theme.utils.gap(0.25)
    }
}))
