import { useEffect, useState } from 'react'
import axios from 'axios'
import { isNative } from 'lib/common'
import { APP_CONFIG } from 'lib/config'
import { DeviceInfo } from 'lib/utils'
import { useIsNewAppEnabledAtom } from 'lib/atoms'

export const useIsNewAppEnabled = () => {
    const [isEnabled, setIsEnabled] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [, setIsNewAppEnabled] = useIsNewAppEnabledAtom()
    useEffect(() => {
        const { uniqueId } = DeviceInfo.get()
        setIsFetching(true)
        axios.post(`${APP_CONFIG.API.URL}/check-feature`, { deviceId: uniqueId })
            .then(response => {
                const { IsAppEnabled } = response.data
                setIsNewAppEnabled(IsAppEnabled && isNative)
                setIsEnabled(IsAppEnabled && isNative)
            })
            .catch(error => {
                console.error('Error checking feature:', error)
            })
            .finally(() => {
                setIsFetching(false)
            })
    }, [])

    return { isEnabled, isFetching }
}

