import { Checkbox } from './Checkbox'
import { Input } from './TextInput'
import { ErrorMessage } from './ErrorMessage'
import { SelectInput, AsyncSelectInput, CustomDropdownNoOptions, CustomDropdownOptionItem } from './selectInput'
import { SearchInput } from './searchInput'
import { FormRow } from './FormRow'
import { ChatInput } from './ChatInput'
import { ServiceInput } from './ServiceInput'
import { SearchTextInput } from './SearchTextInput'
import { Number } from './Number'
import { FrequencyOptionsItem } from './FrequencyOptionsItem'
import { ServicesPlanItem } from './ServicesPlanItem'
import { FrequencySelect } from './FrequencySelect'
import { CustomServiceInput } from './CustomServiceInput'
import { OneOffTimeSlotPicker } from './oneOffTimeSlotPicker'

const FormComponents = {
    Checkbox,
    Input,
    FormRow,
    ErrorMessage,
    SelectInput,
    SearchInput,
    AsyncSelectInput,
    ServiceInput,
    ChatInput,
    SearchTextInput,
    CustomDropdownNoOptions,
    CustomDropdownOptionItem,
    Number,
    FrequencyOptionsItem,
    ServicesPlanItem,
    FrequencySelect,
    CustomServiceInput,
    OneOffTimeSlotPicker
}

export {
    FormComponents
}
