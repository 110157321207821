import React, { useState } from 'react'
import { View, Image, DimensionValue } from 'react-native'
import { useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { PaymentProvider } from 'lib/models'
import { NotificationType } from 'lib/types'
import { LoadingIndicator, Touchable, Typography } from 'lib/components'
import { useQueryHelpers, userCreditCardNumber, useStyles, useTranslations } from 'lib/hooks'
import { PrimaryCardBadge } from './PrimaryCardBadge'
import { CardDeleteModal } from './CardDeleteModal'
import { setDefaultCard } from '../actions'

type PaymentCardTileProps = {
    canDelete: boolean,
    card: PaymentProvider,
    index: number
}

export const PaymentCardTile: React.FunctionComponent<PaymentCardTileProps> = ({
    canDelete,
    card,
    index
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [, setUser] = useUserAtom()
    const [token] = useUserTokenAtom()
    const [, setToastMessage] = useToastAtom()
    const { onRequestError } = useQueryHelpers()
    const { mutate: onSetDefaultCard, isLoading } = setDefaultCard()
    const borderColor = card.default
        ? theme.colors.navajo
        : theme.colors.silver
    const cardNumber = userCreditCardNumber(card.last_4)

    const handleSetAsDefault = () => {
        onSetDefaultCard(
            {
                token,
                card_id: card._id
            },
            {
                onSuccess: ({ data }) => {
                    setUser(data.user_profile)
                    setToastMessage({
                        message: T.screens.paymentAndCards.setAsPrimaryMessage,
                        type: NotificationType.Success
                    })
                },
                onError: onRequestError
            }
        )
    }

    return (
        <React.Fragment>
            <CardDeleteModal
                card={card}
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
            />
            <View
                style={card.default
                    ? {
                        ...styles.container,
                        ...styles.isPrimary
                    }
                    : styles.container
                }
            >
                <LoadingIndicator isLoading={isLoading} />
                <View style={styles.title}>
                    <Typography.Title bold>
                        {card.card_type}
                    </Typography.Title>
                    <PrimaryCardBadge
                        isPrimary={card.default}
                        onSetAsPrimary={handleSetAsDefault}
                    />
                </View>
                <View style={styles.cardNumber}>
                    <Image
                        style={styles.cardImage}
                        source={{
                            uri: card.imageUrl
                        }}
                    />
                    <Typography.Regular>
                        {cardNumber}
                    </Typography.Regular>
                </View>
                <View>
                    <Typography.Label>
                        {`${T.common.expiryDate} ${card.expiration.expiry_month}/${card.expiration.expiry_year}`}
                    </Typography.Label>
                </View>
                <View
                    style={{
                        ...styles.deleteContainer,
                        borderColor
                    }}
                >
                    {canDelete && (
                        <Touchable
                            onPress={() => setIsDeleteModalOpen(true)}
                            style={styles.button}
                        >
                            <Typography.Regular
                                bold
                                forceColor={theme.colors.orange}
                            >
                                {T.screens.paymentAndCards.deleteCard}
                            </Typography.Regular>
                        </Touchable>
                    )}
                </View>
            </View>
            {index % 2 === 0 && (
                <View style={styles.spacer} />
            )}
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        maxWidth: {
            lg: 'calc(50% - 10px)' as DimensionValue,
            xs: undefined
        },
        flexBasis: {
            lg: 'calc(50% - 10px)' as DimensionValue,
            xs: undefined
        },
        flexShrink: 1,
        borderWidth: 1,
        borderRadius: 8,
        paddingVertical: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        borderColor: theme.colors.silver
    },
    title: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    isPrimary: {
        backgroundColor: theme.colors.latte,
        borderColor: theme.colors.latte
    },
    cardNumber: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: theme.utils.gap(2)
    },
    deleteContainer: {
        borderTopWidth: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: theme.utils.gap(2),
        paddingTop: theme.utils.gap(3),
        paddingBottom: theme.utils.gap(1)
    },
    button: {
        borderBottomWidth: 1,
        borderColor: theme.colors.orange
    },
    spacer: {
        width: theme.utils.gap(2)
    },
    cardImage: {
        width: 45,
        height: 25,
        resizeMode: 'contain',
        marginRight: theme.utils.gap(1)
    }
}))
