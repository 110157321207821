import { useState } from 'react'
import {
    DynamicPriceError,
    DynamicPriceMessages,
    DynamicPriceMessagesKeys,
    DynamicTimeSlots,
    SupplyID
} from 'lib/types'
import { useDynamicPriceAtom, useResetDynamicPriceSelectedAtom } from 'lib/atoms'
import { DynamicPriceResponse } from 'features/bookings/types'
import { getCleaningDynamicPrice } from 'features/bookings/actions'

export const useDynamicPrice = (supplyID: SupplyID = SupplyID.Cleaner) => {
    const [isLoading, setIsLoading] = useState(false)
    const { mutate: getCleaningDynamicPriceData } = getCleaningDynamicPrice()
    const [dynamicPriceData, setDynamicPriceData] = useDynamicPriceAtom()
    const [, resetDynamicPriceSelectedAtom] = useResetDynamicPriceSelectedAtom()

    const updateDynamicPriceData = (response: DynamicPriceResponse = {}, month: undefined, day: undefined) => {
        if ('errors' in response) {
            setDynamicPriceData({
                ...dynamicPriceData,
                timeSlots: {},
                errors: response as DynamicPriceError,
                month,
                day
            })

            return
        }

        const isMessageResponse =
            DynamicPriceMessagesKeys.BookingFlowMessage in response ||
            DynamicPriceMessagesKeys.CalendarMessage in response ||
            DynamicPriceMessagesKeys.DynamicPricingMessage in response

        setDynamicPriceData({
            ...dynamicPriceData,
            ...(isMessageResponse ? { messages: response as DynamicPriceMessages } : { timeSlots: response as DynamicTimeSlots }),
            month,
            day
        })
    }

    const getFunctionFetchDynamicPriceData = supplyID => {
        switch(supplyID) {
            case SupplyID.Cleaner:
                return getCleaningDynamicPriceData
            default:
                return undefined
        }
    }

    const requestDynamicPrice = (month, day) => {
        const functionFetchDynamicPriceData = getFunctionFetchDynamicPriceData(supplyID)
        setIsLoading(true)

        if (!functionFetchDynamicPriceData) {
            setIsLoading(false)

            return
        }

        resetDynamicPriceSelectedAtom()

        functionFetchDynamicPriceData({ month, day }, {
            onSuccess: response => {
                updateDynamicPriceData((response ?? {}) as DynamicPriceResponse, month, day)
            },
            onError: () => {
                setDynamicPriceData({
                    ...dynamicPriceData,
                    timeSlots: {},
                    month,
                    day
                })
            },
            onSettled: () => {
                setIsLoading(false)
            }
        })
    }

    return {
        requestDynamicPrice,
        isLoading
    }
}
