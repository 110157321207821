import React from 'react'
import { View } from 'react-native'
import { Surface } from '@propertyguru/hive-surface'
import { Chip } from '@propertyguru/hive-chip'
import { Text } from '@propertyguru/hive-text'
import { Icon } from '@propertyguru/hive-icon'
import { TrendsDownwardFill } from '@propertyguru/hive-icons'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { useNativeDynamicPriceSlotsAtom } from 'lib/native/atoms/dynamicPriceSlots'
import { useNativeCleaningConfigsAtom } from 'lib/native/atoms/cleaningConfigs'
import { useNativeJobBookingDetailAtom } from 'lib/native/atoms'
import { BookingFrequency } from 'lib/native/models'
import { parseMessageText } from 'lib/native/utils'
import { TextType } from 'lib/native/utils/string'
import { emitter } from 'lib/utils'

type Props = {
    shouldShowDynamicPriceInfo: boolean
}

export const TimeSlotHeader: React.FunctionComponent<Props> = ({ shouldShowDynamicPriceInfo = false }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [dynamicPriceSlots] = useNativeDynamicPriceSlotsAtom()
    const [cleaningConfigsAtom] = useNativeCleaningConfigsAtom()
    const [jobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const isOneTime = jobBookingDetailAtom.frequency === BookingFrequency.OneTime
    const headerData = dynamicPriceSlots?.header
    const totalDaysHasDynamicPriceSlots = Object.keys(dynamicPriceSlots?.slots ?? {}).length
    const { lowestRate = 0, highestRate = 0 } = dynamicPriceSlots
    const shouldRenderHeaderWithRate = lowestRate && highestRate && highestRate >= lowestRate
    const allDynamicPriceSlotsOrderedMsg = headerData?.disclaimerNoSlots ? parseMessageText(headerData?.disclaimerNoSlots) : []
    const shouldShowAllDynamicPriceSlotsOrdered =
        !shouldShowDynamicPriceInfo &&
        Boolean(headerData?.disclaimerNoSlots) &&
        allDynamicPriceSlotsOrderedMsg?.length > 0
    const shouldShowDynamicPriceSlotsOnOtherDay =
        totalDaysHasDynamicPriceSlots > 0 &&
        shouldShowAllDynamicPriceSlotsOrdered &&
        Boolean(headerData?.disclaimerSlotsAvailable)

    const handleOpenFullCalendar = () => {
        emitter.emit('openFullCalendarModal')
    }

    const renderHeader = () =>  (
        <View style={styles.label}>
            <Text typography="label/m">{headerData?.subtitle}</Text>
            <Text typography="label/m" color="text/success/primary">
                {`${headerData?.price}* `}
            </Text>
            <Icon icon={TrendsDownwardFill} color="icon/success/primary" width={24} />
        </View>
    )

    const renderHeaderWithRate = () => (
        <View style={styles.label}>
            <Text typography="label/m">{headerData?.subtitle}</Text>
            <Text typography="label/m" color="text/success/primary">
                {`S$${lowestRate} - ${highestRate}/hr*`}
            </Text>
        </View>
    )

    return (
        <Surface style={styles.header}>
            {headerData?.title ? (
                <Chip text={headerData?.title} style={styles.chip} />
            ) : null}

            {
                headerData?.subtitle &&
                headerData?.price ?
                    (
                        shouldRenderHeaderWithRate ?
                            renderHeaderWithRate():
                            renderHeader()
                    ) : cleaningConfigsAtom.oneTimePriceLabel ? (
                        <Text typography="label/s" color="text/active/primary">
                            {`${
                                isOneTime ? T.components.timeSlots.header.titleOneTime : T.components.timeSlots.header.titleFlat
                            } ${isOneTime ? cleaningConfigsAtom.oneTimePriceLabel : cleaningConfigsAtom.flatPriceLabel}`}
                        </Text>
                    ) : null
            }

            {
                shouldShowDynamicPriceSlotsOnOtherDay ?
                    (
                        <Surface style={styles.dynamicPriceSlotsMsg}>
                            <Text style={styles.textCenter} typography="caption/xs" color="text/active/secondary">
                                {`${headerData?.disclaimerSlotsAvailable} `}
                                <Text
                                    style={styles.viewCalendarLink}
                                    typography="caption/s"
                                    color="text/active/secondary"
                                    onPress={handleOpenFullCalendar}
                                >
                                    {T.components.timeSlots.header.viewCalendar}
                                </Text>
                            </Text>
                        </Surface>
                    ) :
                    shouldShowAllDynamicPriceSlotsOrdered ?
                        (
                            <Surface style={styles.dynamicPriceSlotsMsg}>
                                {
                                    allDynamicPriceSlotsOrderedMsg?.map((msg, index) => (
                                        <Text key={index} typography={msg.type === TextType.Regular ? 'caption/xs' : 'caption/s'} color="text/active/secondary">
                                            {msg.text}
                                        </Text>
                                    ))
                                }
                            </Surface>
                        ) : null
            }
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    header: {
        alignItems: 'center',
        paddingBottom: theme.utils.hive.spacing('x2')
    },
    chip: {
        borderWidth: 0,
        backgroundColor: theme.utils.hive.color('fill/success/secondary') as string,
        paddingHorizontal: theme.utils.hive.spacing('x6')
    },
    label: {
        flexDirection: 'row',
        textAlign: 'center',
        alignContent: 'center',
        marginTop: theme.utils.hive.spacing('x4')
    },
    dynamicPriceSlotsMsg: {
        paddingHorizontal: theme.utils.hive.spacing('x8'),
        marginTop: theme.utils.hive.spacing('x4'),
        alignItems: 'center'
    },
    viewCalendarLink: {
        textDecorationLine: 'underline'
    },
    textCenter: {
        textAlign: 'center'
    }
}))
