// TODO: Need to explore further how to ignore this file later
// copied the content from src/features/auth/social/facebook.web.ts for now
import { APP_CONFIG, ENV } from 'lib/config'
import { Languages } from 'lib/locale'
import { AuthorizeWithAppleResponse } from 'lib/types'
import { AuthFlowError } from '../types'

export const getAppleInstance = () => {
    if (!window.AppleID) {
        throw AuthFlowError.ServerError
    }

    return window.AppleID
}

export const initAppleSDK = (id: string, language: string) => new Promise<void>((resolve, reject) => {
    const doesScriptExist = Boolean(document.getElementById(id))

    if (doesScriptExist) {
        return resolve()
    }

    if (!language.includes('_')) {
        return reject()
    }

    const scriptTag = document.createElement('script')

    scriptTag.id = id
    scriptTag.src = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${language}/appleid.auth.js`
    scriptTag.onload = () => {
        const apple = getAppleInstance()

        apple.auth.init({
            scope: 'name email',
            clientId: ENV.APPLE_BUNDLE_IDENTIFIER as string,
            redirectURI: window.location.origin,
            usePopup: true
        })

        resolve()
    }
    scriptTag.onerror = () => reject()

    const body = document.querySelector('body')

    if (!body) {
        return reject()
    }

    body.append(scriptTag)
})

export const authorizeWithApple = (): Promise<AuthorizeWithAppleResponse> =>
    // fixme change it for better language support
    initAppleSDK(APP_CONFIG.KEYS.APPLE_SCRIPT_ID, Languages.en_US)
        .then(() => getAppleInstance())
        .then(apple => apple.auth.signIn())
