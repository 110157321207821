import React from 'react'
import { useTranslations } from 'lib/hooks'
import { AppLayout, Tabs } from 'lib/components'
import { PaymentHistoryEmpty, PaymentMessage } from '../components'
import { usePaymentTabs } from '../hooks'

export const PaymentHistoryScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const paymentAndCardsTabs = usePaymentTabs()

    return (
        <AppLayout title={T.screens.paymentAndCards.title}>
            <Tabs tabs={paymentAndCardsTabs} />
            <PaymentMessage />
            <PaymentHistoryEmpty />
            {/* TODO Render this if user has payments history */}
            {/* <PaymentHistoryList /> */}
        </AppLayout>
    )
}

