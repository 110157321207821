import React from 'react'
import { View } from 'react-native'
import { Badge } from '@propertyguru/hive-badge'
import { Button } from '@propertyguru/hive-button'
import { Modal } from '@propertyguru/hive-modal'
import { Text } from '@propertyguru/hive-text'
import { useNavigation } from '@react-navigation/native'
import { useStyles, useTranslations } from 'lib/hooks'
import { NativeModal } from 'lib/native/components'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { createStyles } from 'lib/styles'

interface DateChangeConfirmationModalProps {
    isOpen: boolean;
    date: string;
    onClose: () => void;
}

export const DateChangeConfirmationModal: React.FC<DateChangeConfirmationModalProps> = ({ isOpen, onClose, date }) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)

    const onConfirm = () => navigation.navigate(ScreenNamesNative.CleaningDateTimeNative)

    return (
        <NativeModal isOpen={isOpen} onClose={onClose} fullWidth>
            <Modal>
                <Modal.Header>
                    <Modal.Header.Title>
                        {T.screens.cleaningDateTime.changeDayModal.title}
                    </Modal.Header.Title>
                </Modal.Header>
                <Modal.Body style={styles.modalBody} >
                    <View style={styles.dateContainer}>
                        <Text typography="body/s" color="text/active/secondary" >{T.screens.cleaningDateTime.changeDayModal.firstCleaning}</Text>
                    </View>
                    <Badge label={date} color="green" size="small"  />
                    <Text typography="label/s" style={styles.confirmText} >{T.screens.cleaningDateTime.changeDayModal.confirmMessage}</Text>
                    <Button text={T.screens.cleaningDateTime.changeDayModal.actionText} style={styles.actionBtn} onPress={onConfirm} />
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalBody: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    confirmText: {
        textAlign: 'center',
        marginTop: theme.utils.hive.spacing('x4')
    },
    actionBtn: {
        flex: 1,
        width: '100%',
        marginTop: theme.utils.hive.spacing('x4')
    },
    dateContainer: {
        marginBottom: theme.utils.hive.spacing('x1'),
        marginTop: theme.utils.hive.spacing('x2')
    }
}))

export default DateChangeConfirmationModal
