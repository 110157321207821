import React, { useEffect } from 'react'
import { useNativeIsAuthorizedAtom } from 'lib/native/atoms'
import { NativeNavigationProps } from 'lib/native/routing'
import { ScreenNamesNative } from 'lib/native/routing/screens'

type HomeScreenProps = {
    navigation: NativeNavigationProps<ScreenNamesNative.NativeHome>
}

export const HomeScreen: React.FunctionComponent<HomeScreenProps> = ({ navigation }) => {
    const [isAuthorized] = useNativeIsAuthorizedAtom()

    useEffect(() => {
        if (isAuthorized) {
            return navigation.navigate(ScreenNamesNative.DashboardNative)
        }

        return navigation.navigate(ScreenNamesNative.LandingNative)
    }, [])

    return null
}
