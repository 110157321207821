import React, { useMemo, useState } from 'react'
import { Surface } from '@propertyguru/hive-surface'
import { Text } from '@propertyguru/hive-text'
import { Icon } from '@propertyguru/hive-icon'
import { LightBulbIdeaLightOutline } from '@propertyguru/hive-icons'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { WebViewModal } from 'features/native/common/components'
import { SupplyID } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { useNativeDynamicPriceSlotsAtom } from 'lib/native/atoms'

export const TimeSlotFooter: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [shouldShowTermsModal, setShouldShowTermsModal] = useState(false)
    const config = getServiceStaticConfig(SupplyID.Cleaner)
    const rescheduleMessage = config?.pricing?.rescheduleDisclaimer?.description
    const [dynamicPriceSlots] = useNativeDynamicPriceSlotsAtom()

    const shouldShowTerms =
        !dynamicPriceSlots?.daySelected ||
        Object.keys(dynamicPriceSlots?.slots?.[dynamicPriceSlots?.daySelected] ?? {}).length > 0

    const formattedTexts = useMemo(() => {
        if (!rescheduleMessage) {
            return undefined
        }

        const boldStrings = rescheduleMessage.match(/\*.*?\*/g)
        const otherStrings = rescheduleMessage.split(/\*.*?\*/g)

        const output: Array<React.JSX.Element> = []
        otherStrings.forEach((string, key) => {
            output.push(
                <Text key={key} typography="caption/xs" color="text/warning/primary">
                    {string}
                </Text>
            )

            if (boldStrings && boldStrings[key]) {
                output.push(
                    <Text key={string} typography="caption/s" color="text/warning/primary">
                        {boldStrings[key].replace(/\*/g, '')}
                    </Text>
                )
            }
        })

        return output
    }, [rescheduleMessage])

    return (
        <Surface style={styles.container}>
            {shouldShowTerms && (
                <Text
                    typography="caption/xs"
                    color="text/active/secondary"
                    onPress={() => setShouldShowTermsModal(true)}
                >
                    {T.components.timeSlots.footer.message}
                </Text>
            )}

            {rescheduleMessage && (
                <Surface style={styles.reschedule}>
                    <Surface style={styles.iconWrapper}>
                        <Icon icon={LightBulbIdeaLightOutline} color="icon/warning/tertiary" width={20} height={45} />
                    </Surface>

                    <Surface style={styles.rescheduleMessageWrapper}>
                        <Text typography="caption/xs" color="text/warning/primary" style={styles.rescheduleMessage}>
                            {formattedTexts}
                        </Text>
                    </Surface>
                </Surface>
            )}
            <WebViewModal
                title={T.components.timeSlots.footer.termsAndConditionsTitle}
                isOpen={shouldShowTermsModal}
                onClose={() => setShouldShowTermsModal(false)}
                uri={T.components.timeSlots.footer.termsAndConditionsLink}
            />
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        width: '100%'
    },
    chip: {
        borderWidth: 0
    },
    reschedule: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.utils.hive.spacing('x4'),
        width: '100%',
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        paddingVertical: theme.utils.hive.spacing('x2'),
        backgroundColor: theme.utils.hive.color('fill/warning/secondary') as string,
        borderRadius: theme.utils.hive.borderRadius('full')
    },
    iconWrapper: {
        paddingRight: theme.utils.hive.spacing('x2')
    },
    rescheduleMessageWrapper: {
        flexShrink: 1,
        width: '100%'
    },
    rescheduleMessage: {
        flexWrap: 'wrap'
    }
}))
