import React, { useEffect, useMemo, useState } from 'react'
import { Image, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { Divider } from '@propertyguru/hive-divider'
import { Icon } from '@propertyguru/hive-icon'
import {
    ArrowLeftOutline,
    ChevronLeftOutline,
    ChevronRightSmallOutline,
    DateTimeCalendarTimeFill,
    EditPencilWriteOutline,
    LightBulbIdeaLightOutline,
    PinLocationFill,
    RotateRepeatRefreshOutline,
    TicketAdmitVipOutline,
    HourglassTimeWatchClockFill
} from '@propertyguru/hive-icons'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { AlertV2, ScreenHeader } from 'lib/native/components'
import { Touchable } from 'lib/components'
import { ENV } from 'lib/config'
import { dateHelpers, R } from 'lib/utils'
import { Address, CreateJobPromotionTemp, PaymentType } from 'lib/models'
import { useToastAtom } from 'lib/atoms'
import { NativeJobSchedule, NotificationType, SupplyID } from 'lib/types'
import { useAddNewCreditCard } from 'features/payments'
import { Segment, SegmentNative, ServiceType } from 'lib/analytics'
import {
    usePrice,
    useSegmentSession,
    useSessionData,
    useStyles,
    useTranslations
} from 'lib/hooks'
import {
    useNativeAvailableServicesAtom,
    useNativeCampaignAtom,
    useNativeJobBookingDetailAtom,
    useNativeJobQuotationsAtom,
    useNativeUserAtom,
    useNativeUserTokenAtom
} from 'lib/native/atoms'
import { useJobQuotation } from 'lib/native/hooks'
import { BookingFrequency, CleaningScheduleType, PropertyType } from 'lib/native/models'
import { usePaymentMethod } from 'features/native/user/hooks'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { BookingSummary } from 'lib/analytics/segment/typewriter/segmentNative'
import { createCleaningJob } from 'features/native/bookings/actions'
import {
    Footer,
    FooterBreakdownBodyWrapper,
    FooterBreakdownWrapper,
    MobileOtpModal
} from 'features/native/bookings/components'
import { CreateCleaningJobRequest } from 'features/native/bookings/types'
import { WEEKDAYS_MAP } from 'features/native/bookings/constants'
import { SpinningIcon, WebViewModal } from 'features/native/common/components'
import { stylesheet } from './styles'
import { Benefits } from './Benefits.native'
import { PaymentMethod } from './PaymentMethod.native'
import { getMappedScheduleDays, getSelectedSchedule } from '../../utils'

export const CleaningSummaryScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [user] = useNativeUserAtom()
    const [token] = useNativeUserTokenAtom()
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const { segmentSession } = useSegmentSession()
    const [campaign] = useNativeCampaignAtom()
    const sessionData = useSessionData()

    const session = useSessionData()
    const [, setToastMessage] = useToastAtom()
    const [paymentType, setPaymentType] = useState<PaymentType | undefined>(undefined)
    const [paymentMethod, setPaymentMethod] = useState<string | undefined>(undefined)
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const [jobQuotationsAtom] = useNativeJobQuotationsAtom()
    const { requestJobQuotation } = useJobQuotation(SupplyID.Cleaner)
    const { defaultPaymentType, defaultPaymentMethod, defaultPaymentMethodId } = usePaymentMethod()
    const [availableServicesAtom] = useNativeAvailableServicesAtom()
    const { mobileSubmit, isLoading } = useAddNewCreditCard(R.T)
    const isOneTime = jobBookingDetailAtom.frequency === BookingFrequency.OneTime
    const isWeekly = jobBookingDetailAtom.scheduleType === CleaningScheduleType.Weekly
    const balance = usePrice(user?.consumer?.balance_credit ?? 0)
    const { isLoading: isCreatingJob, mutate: createJob } = createCleaningJob()
    const address = jobBookingDetailAtom?.newAddress?.postcode ? jobBookingDetailAtom.newAddress : jobBookingDetailAtom.address
    const promotion = jobBookingDetailAtom.promo
    const isCalculating = jobQuotationsAtom?.isLoading
    const isSubmitDisabled = isCalculating || isLoading || isCreatingJob
    const discount = jobQuotationsAtom?.discount
    const [isCleaningSuppliesModalOpen, setIsCleaningSuppliesModalOpen] = useState(false)

    const selectedPaymentMethodId = useMemo(() => jobBookingDetailAtom.paymentMethodId || defaultPaymentMethodId, [jobBookingDetailAtom.paymentMethodId, defaultPaymentMethodId])
    const selectedPaymentMethod = useMemo(() => user?.payment_providers?.find(el => el._id === selectedPaymentMethodId), [user, selectedPaymentMethodId])
    const priceTotal = usePrice(isOneTime ? jobQuotationsAtom?.total?.value ?? 0 : jobQuotationsAtom?.totalSubscription?.value ?? 0)
    const cleaningImageUrl = useMemo(() => availableServicesAtom?.categories?.find(category => category?.supplyShortId === SupplyID.Cleaner)?.image, [availableServicesAtom])

    useEffect(() => {
        const segmentData = {
            postcode: user.locations[0]?.postcode,
            amount: jobQuotationsAtom?.total?.value,
            serviceType: ServiceType.Cleaning
        } as BookingSummary

        if (campaign) {
            segmentData.campaign = campaign
        }

        if (segmentSession.id) {

            segmentData.bookingSessionId = segmentSession.id

            Segment.bookingSummary({
                bookingSessionId: segmentSession.id,
                postcode: user.locations[0]?.postcode,
                amount: jobQuotationsAtom?.total?.value,
                serviceType: ServiceType.Cleaning,
                campaign: campaign ? campaign : undefined
            })
        }
    }, [segmentSession])

    const handlePaymentMethodPress = () => {
        Segment.bookingEditPaymentClicked({})
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.ChangePaymentMethodClicked })
        navigation.navigate(ScreenNamesNative.PaymentMethodNative, {
            defaultPaymentMethod: paymentMethod,
            defaultPaymentMethodId: selectedPaymentMethodId,
            onChangePaymentMethod: (paymentType: PaymentType, paymentMethod: string, paymentMethodId: string) => {
                setPaymentType(paymentType)
                setPaymentMethod(paymentMethod)
                setJobBookingDetailAtom({
                    ...jobBookingDetailAtom,
                    paymentType,
                    paymentMethodId
                })
            }
        })
    }

    const handleNext = (isPhoneVerified: boolean) => {
        if (!isPhoneVerified) {
            setIsMobileOtpModalOpen(true)

            Segment.elementViewed({ elementType: SegmentNative.ElementViewedElementType.PhoneVerificationModalViewed })

            return
        }

        if ((defaultPaymentMethod && defaultPaymentMethod === PaymentType.Card) || !defaultPaymentType) {
            return mobileSubmit()
        }

        const schedule = getMappedScheduleDays(jobBookingDetailAtom.booking.schedule)

        const payload: CreateCleaningJobRequest = {
            token,
            ...sessionData,
            sessionId: Date.now().toString(),
            booking: {
                messageToSupplier: jobBookingDetailAtom.messageToSupplier || '',
                date: jobBookingDetailAtom.booking.date || '',
                location: (jobBookingDetailAtom.newAddress?._id ? jobBookingDetailAtom.newAddress : jobBookingDetailAtom.address) as Address,
                numberOfHours: jobBookingDetailAtom.numberOfHours,
                frequency: jobBookingDetailAtom.frequency,
                buildingType: jobBookingDetailAtom.housingType || '',
                propertyType: jobBookingDetailAtom.propertyInfo || '',
                ironing: jobBookingDetailAtom.ironing || false,
                paymentType: jobBookingDetailAtom.paymentType || '',
                petOnPresmises: jobBookingDetailAtom.petOnPresmises,
                schedule: (isOneTime ? undefined : schedule) as Array<NativeJobSchedule>
            },
            slotId: jobBookingDetailAtom?.slotId || undefined,
            promo: promotion ? {
                validated: true,
                code: promotion?.promo_code,
                auth: promotion?.promo_auth
            } as CreateJobPromotionTemp : undefined,
            tracking: {
                campaign: session?.campaign
            }
        }

        createJob(payload, {
            onSuccess: response => {
                const bookingId = response?.data?.jobIdName
                setToastMessage({
                    message: bookingId,
                    type: NotificationType.Success
                })
                navigation.navigate(ScreenNamesNative.CleaningConfirmationNative, {
                    bookingId,
                    jobId: response?.data?.jobId,
                    propertyType: jobBookingDetailAtom.propertyInfo as PropertyType
                })
            },
            onError: error => {
                setToastMessage({
                    message: error?.errors?.error_msg || T.common.errorMessage,
                    type: NotificationType.Error
                })
            }
        })
    }

    const [isMobileOtpModalOpen, setIsMobileOtpModalOpen] = useState(false)

    const onCloseMobileOtpModal = () => {
        setIsMobileOtpModalOpen(false)
    }

    const onOtpVerify = () => {
        onCloseMobileOtpModal()
        handleNext(true)
    }
    const onConfirmOrder = () => handleNext(!!user.phone)
    const editAddress = () => {
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.EditAddressClicked })
        navigation.navigate(ScreenNamesNative.CleaningServiceAddressNative)
    }
    const editDateTime = () => {
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.EditDateAndTimeClicked })
        navigation.navigate(ScreenNamesNative.CleaningDateTimeNative)
    }
    const editPromoCode = () => {
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.PromoCodeFieldClicked })

        navigation.navigate(ScreenNamesNative.PromoCodeNative)
    }

    const onEditHours = () => {
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.EditCleaningHoursClicked })
        navigation.navigate(ScreenNamesNative.CleaningServiceDetailsNative)
    }
    const editAdditionalInfo = () => {
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.EditAdditionalInfoClicked })
        navigation.navigate(ScreenNamesNative.CleaningServiceAddressNative)
    }

    useEffect(() => {
        if (paymentType || paymentMethod || !defaultPaymentType || !defaultPaymentMethod) {
            return
        }

        setPaymentType(defaultPaymentType)
        setPaymentMethod(defaultPaymentMethod)
        setJobBookingDetailAtom(prev => ({
            ...prev,
            paymentType: defaultPaymentType,
            promo: promotion
        }))
    }, [paymentType, paymentMethod, defaultPaymentType, defaultPaymentMethod, promotion])

    useEffect(() => {
        requestJobQuotation(promotion)
    }, [promotion])

    const onCloseInfoModal = () => {
        setIsCleaningSuppliesModalOpen(false)
    }

    const selectedSchedule = getSelectedSchedule(jobBookingDetailAtom?.booking.schedule)

    return (
        <View style={{ ...styles.container, paddingTop: insets.top }}>
            <WebViewModal
                title={T.screens.cleaningNative.infoButtons.supplies}
                isOpen={isCleaningSuppliesModalOpen}
                onClose={onCloseInfoModal}
                uri={`${ENV.SENDHELPER_MARKETING_SITE_URL}/cleaning-supplies`}
            />
            <ScreenHeader>
                <ScreenHeader.Button icon={ChevronLeftOutline} onPress={navigation.goBack} />
                <ScreenHeader.Title>
                    {T.screens.cleaningSummaryNative.pageTitle}
                </ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <ScrollView style={styles.main}>
                <View style={styles.jobSummary}>
                    <Text typography="label/l">
                        {user?.handle || ''}, {T.screens.cleaningSummaryNative.jobSummary.title}
                    </Text>
                    <View style={styles.jobCard}>
                        <View style={styles.jobCardHeader}>
                            {
                                cleaningImageUrl ?
                                    <Image source={{ uri: cleaningImageUrl }} style={styles.jobCardHeaderIcon} /> :
                                    <View style={styles.jobCardHeaderWithoutIcon} />
                            }

                            <View style={styles.jobCardHeaderInfo} >
                                <View style={styles.jobCardHeaderInfoTitle}>
                                    <Text typography="label/m" color="text/active/primary">
                                        {
                                            isOneTime ?
                                                T.screens.cleaningServiceDetails.frequencyOptions.once :
                                                T.screens.cleaningServiceDetails.frequencyOptions.regular
                                        }
                                    </Text>
                                    {!isOneTime && <Icon icon={RotateRepeatRefreshOutline} color="icon/active/primary" width={16} height={16} />}
                                </View>
                                <View style={styles.jobCardHeaderInfoAddress}>
                                    <Icon icon={PinLocationFill} color="icon/active/secondary" width={24} height={24} />
                                    <Text typography="body/xs" color="text/active/secondary" style={styles.jobCardHeaderInfoAddressText}>
                                        {address?.address ?? ''}. {address?.district ?? ''} {address?.unit ?? ''}
                                    </Text>
                                    <Touchable onPress={editAddress}>
                                        <Icon icon={EditPencilWriteOutline} color="icon/active/primary" width={16} height={16} />
                                    </Touchable>
                                </View>
                            </View>
                        </View>
                        <Divider />
                        <View style={styles.jobSection}>
                            <View style={styles.jobSectionHeader}>
                                <Icon icon={DateTimeCalendarTimeFill} color="icon/active/secondary" width={24} height={24} />
                                {
                                    jobBookingDetailAtom?.booking?.date && (
                                        <Text typography="label/xs" color="text/active/primary" style={styles.jobSectionHeaderTitle}>
                                            <Text typography="label/s" color="text/active/primary">
                                                {`${dateHelpers.shortDateMonth(jobBookingDetailAtom.booking.uiDate as string)},`}
                                            </Text>
                                            <Text typography="label/xs" >
                                                {` ${dateHelpers.selectTimeLabelWithoutTimezone(jobBookingDetailAtom.booking.uiDate as string)} - ${dateHelpers.durationTimeLabelWithoutTimezone(jobBookingDetailAtom.booking.uiDate as string, jobBookingDetailAtom.numberOfHours)}`}
                                            </Text>
                                        </Text>
                                    )}

                                <Touchable onPress={editDateTime}>
                                    <Icon icon={EditPencilWriteOutline} color="icon/active/primary" width={16} height={16} />
                                </Touchable>
                            </View>
                            {
                                !isOneTime && selectedSchedule?.map((schedule, index) => (
                                    <View style={styles.jobSectionRow} key={index}>
                                        <Text typography="label/xs" color="text/active/secondary">
                                            {isWeekly ? T.screens.cleaningSummary.every : T.screens.cleaningSummary.everyOther} {WEEKDAYS_MAP[schedule.day]}
                                        </Text>
                                        <Text typography="label/xs" color="text/active/secondary">
                                            {dateHelpers.formatDateWithTimeInterval(jobBookingDetailAtom.numberOfHours, dateHelpers.combineDateAndTime(jobBookingDetailAtom?.booking?.uiDate || new Date(), schedule.time || ''))}
                                        </Text>
                                    </View>
                                ))
                            }

                        </View>
                        <Divider />
                        <View style={styles.jobSection}>
                            <View style={styles.jobSectionHeader}>
                                <Icon icon={HourglassTimeWatchClockFill} color="icon/active/secondary" width={24} height={24} />
                                {
                                    jobBookingDetailAtom?.booking?.date && (
                                        <Text typography="label/xs" color="text/active/primary" style={styles.jobSectionHeaderTitle}>
                                            <Text typography="label/s" color="text/active/primary">
                                                {jobBookingDetailAtom?.numberOfHours} {T.common.smallHours} {' '}
                                            </Text>
                                            <Text typography="label/xs" >
                                                {jobBookingDetailAtom?.ironing ? T.screens.cleaningSummaryNative.ironing.with : T.screens.cleaningSummaryNative.ironing.without}
                                            </Text>
                                        </Text>
                                    )}

                                <Touchable onPress={onEditHours}>
                                    <Icon icon={EditPencilWriteOutline} color="icon/active/primary" width={16} height={16} />
                                </Touchable>
                            </View>
                        </View>
                        <Divider />
                        <View style={styles.jobSection}>
                            <View style={styles.jobSectionHeader}>
                                <Text typography="label/s" color="text/active/primary" style={styles.jobSectionHeaderTitle}>
                                    {T.screens.cleaningSummaryNative.jobSummary.addInfo}
                                </Text>
                                <Touchable onPress={editAdditionalInfo}>
                                    <Icon icon={EditPencilWriteOutline} color="icon/active/primary" width={16} height={16} />
                                </Touchable>
                            </View>
                            {
                                jobBookingDetailAtom?.messageToSupplier && (
                                    <Text typography="body/xs" color="text/active/secondary" style={styles.jobSectionBody}>
                                        {jobBookingDetailAtom.messageToSupplier}
                                    </Text>
                                )
                            }
                        </View>
                        <Divider />
                        <View style={styles.jobSection}>
                            <View style={styles.jobSectionHeader}>
                                <Text typography="label/s" color="text/active/primary" style={styles.jobSectionHeaderTitle}>
                                    {T.screens.cleaningSummaryNative.jobSummary.preparation.cleaning.title}
                                </Text>
                            </View>
                            <Text typography="body/xs" color="text/active/secondary" style={styles.jobSectionBody}>
                                {T.screens.cleaningSummaryNative.jobSummary.preparation.cleaning.description}
                            </Text>
                            <Touchable style={styles.jobSectionButtonText} onPress={() => {
                                setIsCleaningSuppliesModalOpen(true)
                            }}>
                                <Text typography="caption/s" color="text/active/primary" underline>
                                    {T.screens.cleaningSummaryNative.jobSummary.preparation.cleaning.ctaText}
                                </Text>
                                <Icon icon={ChevronRightSmallOutline} color="icon/active/primary" width={16} height={16} />
                            </Touchable>
                        </View>
                    </View>
                </View>
                <View style={styles.sections}>
                    <View style={styles.section}>
                        <FooterBreakdownBodyWrapper
                            style={styles.priceBreakdown}
                            titleTypography="label/m"
                            labelTypography="body/s"
                            valueTypography="label/m"
                        >
                            {discount ? (
                                <Footer.Breakdown.Body.Row>
                                    <Touchable style={styles.buttonText} onPress={editPromoCode}>
                                        <Text typography="caption/m" color="text/active/primary">
                                            {discount.code?.toUpperCase()} {T.common.applied}
                                        </Text>
                                        <Icon icon={ChevronRightSmallOutline} color="icon/active/primary" width={24} height={24} />
                                    </Touchable>
                                    <Text typography="label/m" color="text/error/primary">
                                        - {discount.value}
                                    </Text>
                                </Footer.Breakdown.Body.Row>
                            ) : promotion ? (
                                <Footer.Breakdown.Body.Row>
                                    <Touchable style={styles.buttonText} onPress={editPromoCode}>
                                        <Text typography="caption/m" color="text/active/primary">
                                            {promotion?.promo_code?.toUpperCase()} {T.common.applied}
                                        </Text>
                                        <Icon icon={ChevronRightSmallOutline} color="icon/active/primary" width={24} height={24} />
                                    </Touchable>
                                </Footer.Breakdown.Body.Row>
                            ) : (
                                <Touchable style={styles.buttonText} onPress={editPromoCode}>
                                    <Icon icon={TicketAdmitVipOutline} color="icon/active/primary" />
                                    <Text typography="caption/m" color="text/active/primary" underline>
                                        {T.screens.cleaningSummaryNative.promoCode.hook}
                                    </Text>
                                </Touchable>
                            )}

                        </FooterBreakdownBodyWrapper>
                        <View style={styles.priceTotal}>
                            <Text typography="label/l" color="text/active/primary">
                                {T.common.total}
                            </Text>
                            {isCalculating ? (
                                <View style={styles.loadingContainer}>
                                    <SpinningIcon />
                                </View>
                            ) : (
                                <Text typography="label/l" color="text/active/primary">
                                    {priceTotal}
                                </Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text typography="label/m" color="text/active/primary">
                            {T.common.paymentMethod}
                        </Text>
                        <View style={styles.sectionBody}>
                            <Text typography="body/s" color="text/active/secondary">
                                {T.screens.cleaningSummaryNative.paymentMethod.secure}
                            </Text>
                            <PaymentMethod
                                balance={balance}
                                paymentMethod={paymentMethod}
                                paymentType={paymentType}
                                selectedPaymentMethod={selectedPaymentMethod}
                                handlePaymentMethodPress={handlePaymentMethodPress}
                            />
                            <AlertV2>
                                <AlertV2.Icon>
                                    <Icon icon={LightBulbIdeaLightOutline} color="icon/warning/primary" width={24} height={24} />
                                </AlertV2.Icon>
                                <AlertV2.Body>
                                    <Text typography="caption/xs" color="text/warning/primary">
                                        <Text typography="caption/s" color="text/warning/primary">
                                            {T.screens.cleaningSummaryNative.paymentMethod.alerts.payNothing}
                                        </Text>{'\n'}
                                        {isOneTime ?
                                            T.screens.cleaningSummaryNative.paymentMethod.alerts.oneTime :
                                            T.screens.cleaningSummaryNative.paymentMethod.alerts.regular}
                                    </Text>
                                </AlertV2.Body>
                            </AlertV2>
                        </View>
                    </View>
                    <Benefits />
                    <MobileOtpModal
                        isOpen={isMobileOtpModalOpen}
                        onClose={onCloseMobileOtpModal}
                        onOtpVerify={onOtpVerify}
                    />
                </View >
            </ScrollView >
            <Footer style={{ paddingBottom: insets.bottom }}>
                <FooterBreakdownWrapper showPromoCode />
                <Footer.Buttons>
                    <Button.Icon icon={ArrowLeftOutline} onPress={navigation.goBack} disabled={isCreatingJob} />
                    <Button disabled={isSubmitDisabled} loading={isSubmitDisabled} text={T.screens.cleaningSummaryNative.footerButtons.next} style={styles.footerBtnNext} onPress={onConfirmOrder} />
                </Footer.Buttons>
            </Footer>
        </View >
    )
}
