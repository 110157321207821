import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useFormatPrice, useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'

type AirConditionPriceDetailsProps = {
    job: JobDetailsResponse,
}

export const AirConditionPriceDetails: React.FunctionComponent<AirConditionPriceDetailsProps> = ({
    job
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const formatPrice = useFormatPrice()
    const isAirconSubscriptionChildJob = !!job?.job?.recurring_job
    const totalPriceValue = job?.estimatedPrice || 0
    const totalPrice = formatPrice(totalPriceValue)

    return (
        <View style={styles.wrapper}>
            <View style={styles.flex}>
                <View style={styles.title}>
                    <Typography.Label forceColor={theme.colors.mouse}>
                        {T.screens.jobDetails.sections.promotionCode}
                    </Typography.Label>
                </View>
                <Typography.Label>
                    {job.job.promotion?.code ?? '-'}
                </Typography.Label>
            </View>
            { !isAirconSubscriptionChildJob && totalPriceValue > 0 && (
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.sections.estimatedPrice}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {totalPrice}
                    </Typography.Label>
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    cleaningDays: {
        textTransform: 'capitalize'
    }
}))
