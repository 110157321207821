import { useHive } from '@propertyguru/hive'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import React from 'react'
import { NativeModal } from 'lib/native/components'
import { Modal } from '@propertyguru/hive-modal'
import { Button } from '@propertyguru/hive-button'
import { PriceSlot, PricingTypes } from 'lib/native/models'
import { useTimeSlotsByPeriod } from 'lib/native/hooks'
import { TimeSlot } from 'lib/native/types'
import { useNativeJobBookingDetailAtom } from 'lib/native/atoms'
import { TimeSlots } from 'features/native/bookings/components'

type TimeSlotsModalProps = {
    isOpen: boolean,
    handleClose: () => void,
    time: string,
    onConfirm: (time?: TimeSlot) => void
}

export const TimeSlotsModal: React.FunctionComponent<TimeSlotsModalProps> = ({ time , onConfirm, isOpen, handleClose }) => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const [selectedSlot, setSelectedSlot] = React.useState<TimeSlot>()
    const timeSlots = useTimeSlotsByPeriod()
    const [jobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const activeTimeSlot = timeSlots.find(slot => slot.id === time)
    const onSelectBookingTime = (priceSlot?: PriceSlot, slot?: TimeSlot) => {
        if(slot) {
            setSelectedSlot(slot)
        }
    }

    const handleConfirm = () => {
        onConfirm(selectedSlot)
        handleClose()
    }

    return (
        <NativeModal fullWidth isOpen={isOpen} onClose={handleClose} >
            <Modal>
                <Modal.Header>
                    <Modal.Header.Title>
                        {T.screens.cleaningDateTime.cleaningScheduleModal.chooseStartTime}
                    </Modal.Header.Title>
                </Modal.Header>
                <Modal.Body>
                    <TimeSlots
                        scheduledDate={jobBookingDetailAtom?.booking?.date || new Date().toISOString()}
                        activeSlot={activeTimeSlot}
                        type={PricingTypes.Regular}
                        onSelectBookingTime={onSelectBookingTime}
                    />
                    <Button text={T.common.confirm} style={styles.actionBtn} onPress={handleConfirm} />
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

const stylesheet = createStyles(() => {
    const hive = useHive()

    return ({
        timeSlotsContainer: {
            paddingHorizontal: hive.spacing('x6'),
            paddingVertical: hive.spacing('x4'),
            gap: hive.spacing('x2'),
            marginHorizontal: hive.spacing('x4'),
            marginVertical: hive.spacing('x4')
        },
        timeSlotWrapper: {
            flexDirection: 'row',
            justifyContent: 'center',
            gap: hive.spacing('x2')
        },
        timeSlotColumn: {
            flex: 1,
            gap: hive.spacing('x2')
        },
        timeSlotColumnTitle: {
            marginBottom: hive.spacing('x1'),
            textAlign: 'center'

        },
        timeSlotTitle: {
            textAlign: 'center',
            marginBottom: hive.spacing('x4')
        },
        actionBtn: {
            marginTop: hive.spacing('x4')
        }
    })
})
