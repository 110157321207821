import { SupplyID } from 'lib/types'
import { AnalyticsEvent } from './types'

export const getPaymentEventForSupplyId = (supplyId: SupplyID): AnalyticsEvent => {
    switch (supplyId) {
        case SupplyID.ACService:
            return AnalyticsEvent.AirConPayment
        case SupplyID.Cleaner:
            return AnalyticsEvent.CleaningPayment
        case SupplyID.DeepCleaning:
            return AnalyticsEvent.DeepCleaningPayment
        case SupplyID.Handyman:
            return AnalyticsEvent.HandymanPayment
        case SupplyID.TasksErrands:
            return AnalyticsEvent.TasksErrandsPayment
        case SupplyID.PestControl:
            return AnalyticsEvent.PestPayment
        case SupplyID.Cooking:
            return AnalyticsEvent.CookingPayment
        case SupplyID.Laundry:
            return AnalyticsEvent.LaundryPayment
        default:
            return AnalyticsEvent.UserPurchase
    }
}
