import React from 'react'
import { View } from 'react-native'
import { AppLayout, Tabs } from 'lib/components'
import { useUserAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { AddPaymentButton, PaymentCards, PaymentEmpty, PaymentMessage } from '../components'
import { usePaymentTabs } from '../hooks'

type UserCardsScreenProps = {
    navigation: NavigationProps<ScreenNames.UserCards>
}

export const UserCardsScreen: React.FunctionComponent<UserCardsScreenProps> = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const paymentAndCardsTabs = usePaymentTabs()
    const userHavePaymentMethods = user.payment_providers.length > 0

    return (
        <AppLayout title={T.screens.paymentAndCards.title}>
            <View style={styles.tabsContainer}>
                <Tabs tabs={paymentAndCardsTabs} />
                {userHavePaymentMethods && (
                    <AddPaymentButton />
                )}
            </View>
            <View>
                {!userHavePaymentMethods && (
                    <PaymentMessage />
                )}
            </View>
            {userHavePaymentMethods
                ? (
                    <PaymentCards />
                )
                : (
                    <PaymentEmpty />
                )
            }
        </AppLayout>
    )
}

const stylesheet = createStyles(() => ({
    tabsContainer: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'center',
            xs: undefined
        },
        justifyContent: 'space-between'
    }
}))
