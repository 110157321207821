import { useMutation } from '@tanstack/react-query'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { useFetcher, useExternalFetcher } from 'lib/api'
import { APP_CONFIG } from 'lib/config'
import {
    CreateCleaningJobRequest,
    CreateJobResponse,
    GetCleaningConfigsResponse,
    GetDynamicPriceSlotsRequest,
    GetDynamicPriceSlotsResponse,
    GetPromotionApplicableResponse,
    GetPromotionApplicableRequest,
    GetListPromotionsResponse
} from 'features/native/bookings/types'

export const useGetListPromotions = () => {
    const fetcher = useFetcher<GetListPromotionsResponse>(HttpMethod.POST, `${APP_CONFIG.QUOTATION.URL}/services/list-promotions`)

    return useMutation(fetcher)
}

export const useGetPromotionApplicable = () => {
    const fetcher = useFetcher<GetPromotionApplicableResponse>(HttpMethod.POST, '/promotion/get_promotion_applicable')

    return useMutation<Response<GetPromotionApplicableResponse>, ErrorResponse, GetPromotionApplicableRequest>(fetcher)
}

export const useGetDynamicPriceSlotsNative = () => {
    const fetcher = useFetcher<GetDynamicPriceSlotsResponse>(HttpMethod.GET, `${APP_CONFIG.QUOTATION.URL}/services/cleaning/dynamic-price-slots`)

    return useMutation<Response<GetDynamicPriceSlotsResponse>, ErrorResponse & { token: string }, GetDynamicPriceSlotsRequest>(fetcher)
}

export const useGetCleaningConfigsNative = () => {
    const fetcher = useExternalFetcher<GetCleaningConfigsResponse>(HttpMethod.GET, `${APP_CONFIG.QUOTATION.URL}/services/cleaning/configs`)

    return useMutation(fetcher)
}

export const getCleaningJobQuotation = () => {
    const fetcher = useExternalFetcher<GetCleaningConfigsResponse>(HttpMethod.POST, `${APP_CONFIG.QUOTATION.URL}/services/cleaning/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedCleaningJobQuotation = () => {
    const fetcher = useFetcher<GetCleaningConfigsResponse>(HttpMethod.POST, `${APP_CONFIG.QUOTATION.URL}/services/cleaning/quotations`)

    return useMutation(fetcher)
}

export const createCleaningJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-cleaning-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateCleaningJobRequest>(fetcher)
}
