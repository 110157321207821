import { GA4SessionData } from 'lib/types'

export type NativeGA4SessionData = GA4SessionData & {
    anonymousId?: string
}

export enum NativeErrorCodes {
    INVALID_AUTH_REQUEST = 'INVALID_AUTH_REQUEST',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
    ACCOUNT_DOES_NOT_EXISTS = 'ACCOUNT_DOES_NOT_EXISTS'
}

type Campaign = {
    utm_source?: string,
    utm_medium?: string,
    utm_campaign?: string,
    utm_content?: string,
    name?: string,
    source?: string
}

export type Tracking = {
    campaign: Campaign
}
