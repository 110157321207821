import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import TableBody from './sub-components/Body.native'
import TableHeader from './sub-components/Header.native'

type TableProps = {
    children: ReactNode,
}

const Table: React.FunctionComponent<TableProps> = ({ children }) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                borderRadius: hive.borderRadius('medium'),
                borderColor: hive.color('border/active/primary'),
                columnGap: hive.spacing('x2')
            }}
        >
            {children}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        borderWidth: 1,
        backgroundColor: theme.colors.white
    }
}))

export default Object.assign(Table, {
    Header: TableHeader,
    Body: TableBody
})
