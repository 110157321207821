import { createStyles } from 'lib/styles'
import { COLOR_GREY, FONT_WEIGHT_NORMAL } from '../constants'

export const stylesheet = createStyles(theme => ({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    mobileWrapper: {
        rowGap: theme.utils.hive.spacing('x5')
    },
    modalStyle: {
        justifyContent: 'center'
    },
    modalWrapperStyle: {
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        paddingBottom: theme.utils.hive.spacing('x10')
    },
    modalBody: {
        padding: theme.utils.hive.spacing('x5')
    },
    mobileInputWrapper: {
        flexDirection: 'row',
        columnGap: theme.utils.hive.spacing('x2')
    },
    countryCodeInput: {
        flex: 1
    },
    mobileInput: {
        flex: 5
    },
    errorMobileWrapper: {
        paddingTop: theme.utils.hive.spacing('x2')
    },
    errorMessage: {
        color: theme.utils.hive.color('fill/error/primary') as string,
        fontWeight: '400'
    },
    description: {
        fontWeight: '400',
        color: COLOR_GREY
    },
    otpTitle: {
        fontWeight: '600'
    },
    otpMobileWrapper: {
        flexDirection: 'row',
        columnGap: theme.utils.hive.spacing('x2')
    },
    otpMobile: {
        fontWeight: '600'
    },
    fontWeightNormal: {
        fontWeight: FONT_WEIGHT_NORMAL
    },
    otpResendWrapper: {
        flexDirection: 'row'
    },
    otpResendAlert: {
        flexDirection: 'row'
    },
    otpResendAlertText: {
        flex: 1,
        fontWeight: '600'
    },
    otpResendAlertClose: {
        alignSelf: 'flex-start'
    },
    buttonForWeb: {
        borderRadius: 25
    },
    customInputStyle: {
        borderRadius: 25
    },
    pinError: {
        marginTop: theme.utils.gap(1)
    },
    borderedInput: {
        borderRadius: 24,
        borderWidth: 1,
        borderColor: theme.colors.cloudMist,
        minHeight: 48
    },
    countryCodeInputForWeb: {
        textAlign: 'center',
        maxWidth: 100
    },
    mobileInputForWeb: {
        paddingHorizontal: theme.utils.gap(2),
        width: '100%'
    }
}))
