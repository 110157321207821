import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { Surface } from '@propertyguru/hive-surface'
import { useHive } from '@propertyguru/hive'
import { Divider } from '@propertyguru/hive-divider'
import {
    ArrowLeftOutline,
    ChevronLeftOutline,
    ChevronRightSmallOutline,
    MoneyHandCoinsOutline
} from '@propertyguru/hive-icons'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { Icon } from '@propertyguru/hive-icon'
import { Touchable } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { useToastAtom } from 'lib/atoms'
import { dateHelpers } from 'lib/utils'
import { JobQuotationSavedItems, NotificationType } from 'lib/types'
import { BookingFrequency, PriceSlot } from 'lib/native/models'
import { ScreenHeader } from 'lib/native/components'
import { TimeSlot } from 'lib/native/types'
import { NativeNavigationParams } from 'lib/native/routing'
import { useNativeJobBookingDetailAtom, useNativeJobQuotationsAtom } from 'lib/native/atoms'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import {
    CalendarWrapper,
    CleaningScheduleTypeModal,
    Footer,
    FooterBreakdownWrapper,
    TimeSlots
} from 'features/native/bookings/components'
import { PricingTypes } from 'lib/native/models/DynamicPriceSlot'
import { Segment } from 'lib/analytics'

type CleaningDateTimeScreenProps = {
    route: NativeNavigationParams<ScreenNamesNative.CleaningDateTimeNative>
}

export const CleaningDateTimeScreen: React.FunctionComponent<CleaningDateTimeScreenProps> = () => {
    const hive = useHive()
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const [jobQuotationsAtom] = useNativeJobQuotationsAtom()
    const [, setToastMessage] = useToastAtom()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [slot, setSlot] = useState<TimeSlot>()
    const [scheduledDate, setScheduledDate] = useState<string>(new Date().toISOString())
    const [uiDate, setUIDate] = useState<string>(new Date().toISOString())
    const jobQuotationItems = jobQuotationsAtom?.saved?.items || []
    const savedOneOffPlan = jobQuotationItems.find(item => item.name === JobQuotationSavedItems.SavedOneOffPlan)
    const isOneTimePlan = jobBookingDetailAtom?.frequency === BookingFrequency.OneTime

    const handleBannerPress = () => {
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            booking: {
                ...jobBookingDetailAtom.booking,
                date: undefined
            },
            frequency: BookingFrequency.Recurring
        })
    }

    const handleNext = () => {
        if (!jobBookingDetailAtom?.booking?.date || !slot) {
            setToastMessage({
                message: T.screens.cleaningDateTime.toastMessages.selectTimeSlot,
                type: NotificationType.Error
            })

            return
        }

        if (!isOneTimePlan) {
            setIsModalOpen(true)

            return
        }

        navigation.navigate(ScreenNamesNative.CleaningServiceAddressNative)
    }

    const handleModalClose = () => setIsModalOpen(false)

    const onSelectBookingDate = (date: Date) => {
        setSlot(undefined)
        setScheduledDate(date.toISOString())
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            slotId: undefined
        })
        Segment.cleaningDateSelected({ pricingType: isOneTimePlan ? PricingTypes.Dynamic : PricingTypes.Regular })
    }

    const onSelectBookingTime = (dynamicPriceSlot: PriceSlot | undefined, slot?: TimeSlot) => {
        const startDate = dateHelpers.combineDateAndTimeToSGT(scheduledDate, slot?.id as string)
        const selectedDate = dateHelpers.combineDateAndTime(scheduledDate, slot?.id as string)
        setSlot(slot)
        setScheduledDate(startDate)
        setUIDate(selectedDate)
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            slotId: isOneTimePlan ? dynamicPriceSlot?.slotId : undefined,
            booking: {
                ...jobBookingDetailAtom.booking,
                date: startDate,
                uiDate: selectedDate
            },
            promo: undefined
        })
        Segment.cleaningTimeSlotSelected({ pricingType: isOneTimePlan ? PricingTypes.Dynamic : PricingTypes.Regular })
    }

    return (
        <View style={{ ...styles.container, paddingTop: insets.top }}>
            <ScreenHeader>
                <ScreenHeader.Button icon={ChevronLeftOutline} onPress={navigation.goBack} />
                <ScreenHeader.Title>
                    {isOneTimePlan
                        ? T.screens.cleaningDateTime.pageTitleOneTime
                        : T.screens.cleaningDateTime.pageTitleRegular}
                </ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <ScrollView
                style={{
                    ...styles.main,
                    backgroundColor: hive.color('fill/neutral/primary')
                }}
            >
                <CalendarWrapper onSelectBookingDate={onSelectBookingDate} />
                <Surface style={styles.timeSlotsWrapper}>
                    <Surface style={styles.timeSlotsContainer}>
                        <TimeSlots
                            scheduledDate={scheduledDate}
                            type={isOneTimePlan ? PricingTypes.Dynamic : PricingTypes.Regular}
                            activeSlot={slot}
                            onSelectBookingTime={onSelectBookingTime}
                        />
                    </Surface>
                </Surface>
                {isOneTimePlan ? (
                    <Text typography="label/xs" style={styles.centered}>
                        {T.screens.cleaningDateTime.selectedDateMessage} {T.screens.cleaningDateTime.oneTime}
                        {'\n'}
                        {T.common.on.toLowerCase()}{' '}
                        <Text typography="label/s">{dateHelpers.formatDateWithDayAndMonth(scheduledDate)}</Text>
                    </Text>
                ) : (
                    <Text typography="label/xs" style={styles.centered}>
                        {T.screens.cleaningDateTime.selectedDateMessage} {T.screens.cleaningDateTime.regular}
                        {'\n'}
                        {T.common.starting.toLowerCase()} {T.common.on.toLowerCase()}{' '}
                        <Text typography="label/s">{dateHelpers.formatDateWithDayAndMonth(scheduledDate)}</Text>
                    </Text>
                )}
            </ScrollView>
            <Footer style={{ paddingBottom: insets.bottom }}>
                {isOneTimePlan && !!savedOneOffPlan?.value && (
                    <Footer.Banner>
                        <View style={{ ...styles.footerBannerItem, columnGap: hive.spacing('x2') }}>
                            <Icon icon={MoneyHandCoinsOutline} color="icon/active/primary" width={16} height={16} />
                            <Text
                                typography="caption/s"
                                color="text/active/primary"
                                style={styles.footerBannerItemText}
                            >
                                {`${T.screens.cleaningDateTime.footerBanner.save} ${savedOneOffPlan?.value} ${
                                    T.screens.cleaningDateTime.footerBanner.more
                                }`}
                            </Text>
                        </View>
                        <Touchable
                            style={{ ...styles.footerBannerItem, columnGap: hive.spacing('x1') }}
                            onPress={handleBannerPress}
                        >
                            <Text typography="caption/xs" color="text/active/primary" underline>
                                {T.screens.cleaningDateTime.footerBanner.ctaText}
                            </Text>
                            <Icon icon={ChevronRightSmallOutline} color="icon/active/primary" width={16} height={16} />
                        </Touchable>
                    </Footer.Banner>
                )}

                <FooterBreakdownWrapper />
                <Footer.Buttons>
                    <Button.Icon icon={ArrowLeftOutline} onPress={navigation.goBack} />
                    <Button text={T.common.continue} style={styles.footerBtnNext} onPress={handleNext} />
                </Footer.Buttons>
            </Footer>
            {isModalOpen && (
                <CleaningScheduleTypeModal date={scheduledDate} uiDate={uiDate} isModalOpen={isModalOpen} onClose={handleModalClose} />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    main: {
        flex: 1
    },
    footerBannerItem: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    footerBannerItemText: {
        fontWeight: '600'
    },
    footerActionsItem: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    footerBtnNext: {
        flex: 1
    },
    timeSlotsContainer: {
        paddingVertical: theme.utils.hive.spacing('x8'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string,
        borderRadius: theme.utils.hive.borderRadius('large')
    },
    timeSlotsWrapper: {
        padding: theme.utils.hive.spacing('x4'),
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    },
    centered: {
        textAlign: 'center',
        paddingTop: theme.utils.hive.spacing('x2'),
        paddingBottom: theme.utils.hive.spacing('x6'),
        paddingVertical: theme.utils.hive.spacing('x4')
    }
}))
