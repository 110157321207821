import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { View } from 'react-native'
import { Dropdown } from 'react-native-element-dropdown'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { Segment } from 'lib/analytics'
import { Typography } from 'lib/components'
import { Nullable, SelectInputOption } from 'lib/types'
import { useDynamicPriceAtom, useDynamicPriceErrorAtom, useDynamicPriceSelectedAtom, useLocaleAtom, useResetDynamicPriceErrorAtom, useResetDynamicPriceSelectedAtom } from 'lib/atoms'
import { Item, RightIcon } from 'lib/components/form/components/oneOffTimeSlotPicker'

type OneOffTimeSlotPickerProps = {
    value: Nullable<SelectInputOption>,
    onChange: (option: Nullable<SelectInputOption>) => void,
    options: Array<SelectInputOption>,
    onInputChange?: (value: string) => void,
    disabled?: boolean,
    isLoading?: boolean,
}

const OneOffTimeSlotPicker: React.FunctionComponent<OneOffTimeSlotPickerProps> = React.memo(props => {
    const T = useTranslations()
    const { options, onChange, disabled, isLoading } = props
    const { styles } = useStyles(stylesheet)
    const [dynamicPriceData] = useDynamicPriceAtom()
    const [dynamicPriceErrorData] = useDynamicPriceErrorAtom()
    const [, resetDynamicPriceErrorData] = useResetDynamicPriceErrorAtom()
    const [locale] = useLocaleAtom()
    const [, setDynamicPriceSelected] = useDynamicPriceSelectedAtom()
    const [, resetDynamicPriceSelected] = useResetDynamicPriceSelectedAtom()
    const [value, setValue] = useState<SelectInputOption | null>(null)
    const [isFocus, setIsFocus] = useState(false)
    const isOptionsEmpty = useMemo(() => options.length === 0, [options.length])
    const selectedValue = props?.value
    const currentStateValue = value?.value

    useEffect(() => {
        if (selectedValue?.value !== currentStateValue) {
            setValue(selectedValue)
        }
    }, [selectedValue?.value, currentStateValue])

    const renderItem = (item: SelectInputOption) =>
        (isOptionsEmpty || isLoading) ?
            null :
            <Item item={item} value={value} timeSlots={dynamicPriceData.timeSlots} />

    const renderRightIcon = () =>
        isOptionsEmpty ? null : (
            <RightIcon isFocus={isFocus} isOptionsEmpty={isOptionsEmpty} />
        )

    const onSelectItem = useCallback((item: SelectInputOption) => {
        setValue(item)
        setIsFocus(false)
        onChange(item)

        const dynamicTimeSlot = jobHelpers.getDynamicTimeSelected(item.value, dynamicPriceData.timeSlots)
        resetDynamicPriceErrorData()

        if (dynamicTimeSlot) {
            setDynamicPriceSelected(dynamicTimeSlot)
            Segment.dynamicPricingSelected({
                dayOfTheWeek: dateHelpers.getWeekDayFullNames(item.value, locale),
                timeOfTheDay: item.label,
                pricePerHour: parseInt(dynamicTimeSlot?.price?.match(/\d+/)?.[0] || '0', 10)
            })

            return
        }

        resetDynamicPriceSelected()
    }, [onChange, dynamicPriceData.timeSlots, setDynamicPriceSelected, resetDynamicPriceSelected])

    return (
        <View style={styles.container}>
            <Dropdown
                inverted={false}
                closeModalWhenSelectedItem
                dropdownPosition="top"
                containerStyle={styles.containerStyle}
                style={{
                    ...styles.dropdown,
                    ...(isFocus ? styles.dropdownFocused : {}),
                    ...(disabled ? styles.dropdownDisabled : {})
                }}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                data={options}
                renderItem={renderItem}
                search={false}
                maxHeight={300}
                labelField="label"
                valueField="value"
                placeholder={T.components.oneOffTimeSlotPicker.placeholder}
                value={value?.value}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onChange={onSelectItem}
                renderRightIcon={renderRightIcon}
                disable={isOptionsEmpty || disabled}
            />
            <Typography.Error style={styles.errorMsg}>{dynamicPriceErrorData?.errors?.error_msg}</Typography.Error>
        </View>
    )
})

export default OneOffTimeSlotPicker

const stylesheet = createStyles(theme => ({
    dropdownDisabled: {
        backgroundColor: theme.colors.fog
    },
    containerStyle: {
        marginBottom: theme.utils.gap(0.5),
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        zIndex: 10
    },
    container: {
        backgroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(2)
    },
    dropdown: {
        height: 48,
        borderColor: theme.colors.fog,
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: theme.utils.gap(1)
    },
    dropdownFocused: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderColor: theme.colors.night
    },
    placeholderStyle: {
        fontSize: 14
    },
    selectedTextStyle: {
        fontSize: 14
    },
    errorMsg: {
        color: theme.colors.flame,
        marginTop: theme.utils.gap(0.75),
        fontSize: 13,
        lineHeight: 16
    }
}))
