import React, { useEffect } from 'react'
import { Icons } from 'assets'
import { DimensionValue, TextInput, View } from 'react-native'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, FormComponents, Touchable, Typography } from 'lib/components'
import { useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { CustomFonts, NotificationType } from 'lib/types'
import { isNative, Measurements } from 'lib/common'
import { addConsumerRedeemGiftCard } from 'features/sendPay/actions'

type VoucherRedemptionModalProps = {
    isOpen: boolean
    onClose: () => void,
    onVoucherRedeemed?: () => void,
}

export const VoucherRedemptionModal: React.FunctionComponent<VoucherRedemptionModalProps> = ({ isOpen, onClose }) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const [, setUser] = useUserAtom()
    const [, setToastMessage] = useToastAtom()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { styles, theme } = useStyles(stylesheet)
    const [code, setCode] = React.useState<string>('')
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const { isLoading, mutate } = addConsumerRedeemGiftCard()
    const isDisabled = !code.trim() || isLoading

    useEffect(() => {
        setErrorMessage('')
        setCode('')
    }, [isOpen])

    const onChangeText = (value: string) => {
        setCode(value)
    }

    const onRedeem = () => {
        setErrorMessage('')
        mutate({
            token,
            cardToken: code
        }, {
            onSuccess: ({ data }) => {
                const successMessage = data?.data?.message || T.components.sendPayModal.voucherRedemption.successMessage
                setToastMessage({
                    type: NotificationType.Success,
                    message: successMessage
                })

                setUser(prevState => ({
                    ...prevState,
                    consumer: {
                        ...prevState.consumer,
                        balance_credit: data?.data?.balance_credit
                    }
                }))

                onClose()
            },
            onError: error => {
                const errorMessage = error?.errors?.error_msg || T.components.sendPayModal.voucherRedemption.errorMessage
                setErrorMessage(errorMessage)
            }
        })
    }

    const SubmitButton = () => (
        <Touchable
            disabled={isDisabled}
            style={{
                ...styles.submitButton,
                ...(isDisabled ? styles.disabledButton : {})
            }}
            onPress={onRedeem}
        >
            <Typography.Regular style={{
                ...styles.submitText,
                ...(isDisabled ? styles.disabledText : {})
            }} numberOfLines={1}>
                {T.components.sendPayModal.voucherRedemption.redeemNow}
            </Typography.Regular>
        </Touchable>
    )

    return (
        <CustomModal
            isOpen={isOpen}
            onClose={onClose}
            closeButtonStyles={styles.closeButton}
            contentStyles={styles.modalContent}
            childrenWrapper={styles.modalChildrenWrapper}
            customScrollViewStyles={styles.customScrollViewStyles}
        >
            <View style={styles.modalContainer}>
                <View style={styles.headerContainer}>
                    <Typography.Regular style={styles.title}>
                        {T.components.sendPayModal.voucherRedemption.addVoucher}
                    </Typography.Regular>
                    <View style={styles.modalActionContainer}>
                        <Touchable
                            disabled={isLoading}
                            onPress={onClose}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}>
                            <Icons.Close size={14} />
                        </Touchable>
                    </View>
                </View>
                <View style={styles.contentContainer}>
                    <View style={styles.inputContainer}>
                        <TextInput
                            onFocus={() => setErrorMessage('')}
                            placeholder={T.components.sendPayModal.voucherRedemption.enterYourCodeHere}
                            value={code}
                            style={{
                                ...styles.input,
                                ...(errorMessage ? styles.inputWithError : {})
                            }}
                            placeholderTextColor={theme.colors.fog}
                            onChangeText={onChangeText}
                            editable={!isLoading}
                        />
                        <FormComponents.ErrorMessage text={errorMessage} />
                    </View>
                    {
                        !isMobile && (
                            <View style={errorMessage ? styles.submitButtonErrorContainer : styles.submitButtonContainer}>
                                <SubmitButton />
                            </View>
                        )
                    }

                </View>
                {
                    isMobile && (
                        <View style={styles.submitButtonMobileContainer}>
                            <SubmitButton />
                        </View>
                    )
                }
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    submitButtonMobileContainer: {
        paddingTop: theme.utils.gap(2)
    },
    termAndCondition: {
        color: theme.colors.night,
        textDecorationLine: 'underline'
    },
    inputContainer: {
        flex: 3
    },
    inputWithError: {
        borderColor: theme.colors.flame,
        color: theme.colors.flame
    },
    input: {
        height: 50,
        fontFamily: CustomFonts.Poppins400,
        color: theme.components.OTPInput.typography.text,
        backgroundColor: theme.components.OTPInput.backgroundColor,
        borderRadius: 8,
        borderColor: theme.colors.fog,
        borderWidth: 1,
        paddingVertical: theme.utils.gap(0.5),
        paddingHorizontal: theme.utils.gap(1),
        marginRight: theme.utils.gap(1)
    },
    closeButton: {
        display: 'none'
    },
    modalContent:{
        paddingTop: 0,
        paddingBottom: theme.utils.gap(2)
    },
    modalChildrenWrapper: {
        paddingTop: 0,
        maxWidth: 500,
        width: {
            xs: isNative ? Measurements.WindowWidth * 0.75 : '75vw' as DimensionValue
        }
    },
    customScrollViewStyles: {
        marginTop: theme.utils.gap(2)
    },
    modalContainer: {
        flex: 1
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontSize: 18,
        fontFamily: CustomFonts.Poppins500,
        lineHeight: 24,
        color: theme.colors.night
    },
    modalActionContainer: {
        paddingLeft: theme.utils.gap(1)
    },
    contentContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(0.25)
    },
    submitButtonContainer: {
        flex: 1.5,
        marginBottom: theme.utils.gap(2)
    },
    submitButtonErrorContainer: {
        flex: 1.5,
        marginBottom: theme.utils.gap(4)
    },
    submitButton: {
        height: 50,
        borderRadius: 8,
        backgroundColor: theme.colors.sun,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(2),
        justifyContent: 'center',
        alignItems: 'center'
    },
    submitText: {
        fontSize: 14,
        fontFamily: CustomFonts.Poppins500,
        color: theme.colors.night,
        whileSpace: 'nowrap'
    },
    disabledButton: {
        backgroundColor: theme.colors.silver
    },
    disabledText: {
        color: theme.colors.mouse
    }
}))
