import React, { ReactNode } from 'react'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { View, ViewStyle } from 'react-native'
import { FooterBanner } from './sub-components/Banner.native'
import FooterButtons from './sub-components/Buttons.native'
import FooterBreakdown from './sub-components/Breakdown.native'

type FooterProps = {
    children: ReactNode,
    style?: ViewStyle
}

const Footer = ({ children, style = {} }: FooterProps) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={{ ...styles.container, ...style }}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        shadowColor: theme.colors.shadowSoft,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        elevation: 4
    }
}))

export default Object.assign(Footer, {
    Banner: FooterBanner,
    Breakdown: FooterBreakdown,
    Buttons: FooterButtons
})
