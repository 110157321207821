import React, { useState } from 'react'
import { DimensionValue, View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { AREAS } from 'lib/data'
import { Segment } from 'lib/analytics'
import { useIsAuthorizedAtom } from 'lib/atoms'
import { Nullable, PointerEvent } from 'lib/types'
import { isNative } from 'lib/common'
import { stringHelpers } from 'lib/utils'
import { Breakpoint, createStyles } from 'lib/styles'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, Grid, Touchable, EditAddressModal, NewAddressModal, FormComponents, Typography } from 'lib/components'
import { MoversAndPackersFields, MoversAndPackersFormShape } from '../../forms'
import { moversAndPackersHooks } from '../../hooks'
import { HomePropertyType, MoversAndPackersServiceType } from '../../types'

type MoversAndPackersRequirementsPropertyProps = {
    formErrors: Nullable<Record<keyof MoversAndPackersFormShape, string>>,
    formReturn: UseFormReturn<MoversAndPackersFormShape>,
    isDropOffProperty?: boolean
}

export const MoversAndPackersRequirementsProperty: React.FunctionComponent<MoversAndPackersRequirementsPropertyProps> = ({
    formReturn,
    formErrors,
    isDropOffProperty
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { form } = formReturn
    const route = useRoute<NavigationParams<ScreenNames.Summary>>()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isAuthorized] = useIsAuthorizedAtom()
    const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const selectedLocation = (isDropOffProperty ? form.dropOffLocation : form.pickUpLocation)?.value
    const {
        homePropertyTypeOptions,
        commercialPropertyTypeOptions,
        numberOfBedroomsOptions,
        floorOptions,
        propertiesWithFloors,
        propertiesWithElevator
    } = moversAndPackersHooks.useMoversAndPackersOptions()
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(50% - 10px)' as DimensionValue
    const isPropertyTypeSelected = isDropOffProperty ? form.dropOffPropertyType?.value : form.pickUpPropertyType?.value
    const isOtherPropertyType = isDropOffProperty ? form.dropOffPropertyType?.value === HomePropertyType.Other : form.pickUpPropertyType?.value === HomePropertyType.Other
    const showPropertyDetails = propertiesWithFloors.some(propertyType => {
        const selectedType = isDropOffProperty ? form.dropOffPropertyType?.value : form.pickUpPropertyType?.value

        return propertyType === selectedType
    })
    const showElevatorOption = propertiesWithElevator.some(propertyType => {
        const selectedType = isDropOffProperty ? form.dropOffPropertyType?.value : form.pickUpPropertyType?.value

        return propertyType === selectedType
    })
    const addressUnit = stringHelpers.getFormattedAddressUnit(selectedLocation.unit)
    const inputText = selectedLocation.address
        ? `${addressUnit}${selectedLocation.address}, ${selectedLocation.postcode}`
        : !isDropOffProperty && route.params?.postalcode
            ? route.params?.postalcode
            :  ''

    return (
        <FormComponents.FormRow
            title={isDropOffProperty
                ? form.dropOffLocation?.label
                : form.pickUpLocation?.label
            }
            style={styles.container}
        >
            <Touchable
                onPress={() => isAuthorized
                    ? setIsEditModalOpen(true)
                    : setIsNewAddressModalOpen(true)
                }
                pointerEvents={PointerEvent.boxOnly}
            >
                <Adapter.TextInput
                    {...(isDropOffProperty ? form.dropOffLocation : form.pickUpLocation)}
                    value={inputText}
                    disabled
                    inputProps={{
                        style: {
                            backgroundColor: theme.components.input.backgroundColor,
                            color: selectedLocation
                                ? theme.components?.input.typography?.text
                                : theme.components?.input.typography?.placeholder,
                            borderColor: selectedLocation
                                ? theme.components.input.filledBorderColor
                                : theme.components.input.emptyBorderColor
                        }
                    }}
                    leftIcon={() => (
                        <Icons.Navigation size={20}/>
                    )}
                />
            </Touchable>
            <View>
                <View style={styles.row}>
                    <View
                        style={{
                            ...styles.selectWrapper,
                            ...styles.noFlex,
                            width: columnWidth
                        }}
                    >
                        <Adapter.SelectInputColumn
                            {...(isDropOffProperty
                                ? form.dropOffPropertyType
                                : form.pickUpPropertyType)
                            }
                            errorMessage={isDropOffProperty
                                ? formErrors?.dropOffPropertyType || ''
                                : formErrors?.pickUpPropertyType || ''}
                            smallLabel
                            options={form.serviceType?.value === MoversAndPackersServiceType.CommercialMoving
                                ? commercialPropertyTypeOptions
                                : homePropertyTypeOptions
                            }
                            onOptionSelect={option => {
                                Segment.bookingPropertyTypeSelected({
                                    propertyType: option.value
                                })
                            }}
                            onMenuOpen={() => {
                                Segment.bookingPropertyTypeClicked({})
                            }}
                            onChangeValue={value => {
                                if (!value) {
                                    Segment.bookingClearPropertyTypeClicked({})
                                }

                                isDropOffProperty
                                    ? form.dropOffPropertyType.onChangeValue(value)
                                    : form.pickUpPropertyType.onChangeValue(value)
                            }}
                        />
                    </View>
                    <Grid.Gap gapRight={2}/>
                    {Boolean(isPropertyTypeSelected) && !isOtherPropertyType && (
                        <View
                            style={{
                                ...styles.selectWrapper,
                                ...styles.zIndex
                            }}
                        >
                            {form.serviceType?.value === MoversAndPackersServiceType.CommercialMoving ? (
                                <Adapter.SelectInputColumn
                                    {...(isDropOffProperty
                                        ? form.dropOffPropertySize
                                        : form.pickUpPropertySize
                                    )}
                                    errorMessage={isDropOffProperty
                                        ? formErrors?.dropOffPropertySize || ''
                                        : formErrors?.pickUpPropertySize || ''}
                                    smallLabel
                                    options={AREAS.map(area => ({
                                        label: area.area,
                                        value: area.area
                                    }))}
                                />
                            ) : (
                                <Adapter.SelectInputColumn
                                    {...(isDropOffProperty
                                        ? form.dropOffNumberOfBedrooms
                                        : form.pickUpNumberOfBedrooms
                                    )}
                                    errorMessage={isDropOffProperty
                                        ? formErrors?.dropOffNumberOfBedrooms || ''
                                        : formErrors?.pickUpNumberOfBedrooms || ''}
                                    smallLabel
                                    options={numberOfBedroomsOptions}
                                    onOptionSelect={option => {
                                        Segment.bookingBedroomNumberSelected({
                                            number: Number(option.value)
                                        })
                                    }}
                                    onMenuOpen={() => {
                                        Segment.bookingBedroomNumberClicked({})
                                    }}
                                    onChangeValue={value => {
                                        if (!value) {
                                            Segment.bookingClearBedroomsClicked({})
                                        }

                                        isDropOffProperty
                                            ? form.dropOffNumberOfBedrooms.onChangeValue(value)
                                            : form.pickUpNumberOfBedrooms.onChangeValue(value)
                                    }}
                                />
                            )}
                        </View>
                    )}
                </View>
                {isOtherPropertyType && (
                    <View>
                        <Typography.Label
                            style={styles.textRow}
                            forceColor={theme.colors.darkGrey}
                        >
                            {T.screens.moversAndPackers.formFields.details.title}
                        </Typography.Label>
                        <Typography.Error
                            style={styles.textRow}
                            forceColor={theme.colors.mouse}
                        >
                            {T.screens.moversAndPackers.formFields.details.label}
                        </Typography.Error>
                        <Adapter.TextInput
                            {...(isDropOffProperty
                                ? form.dropOffDetails
                                : form.pickupDetails
                            )}
                            inputProps={{
                                multiline: true
                            }}
                            onBlur={() => {
                                if (form.dropOffDetails.value || form.pickupDetails.value) {
                                    Segment.bookingAdditionalInformationAdded({
                                        information: {
                                            type: isDropOffProperty
                                                ? MoversAndPackersFields.DropOffDetails
                                                : MoversAndPackersFields.PickUpDetails,
                                            value: isDropOffProperty
                                                ? form.dropOffDetails.value
                                                : form.pickupDetails.value
                                        }
                                    })
                                }
                            }}
                        />
                    </View>
                )}
            </View>
            {showPropertyDetails && (
                <View style={styles.zIndex}>
                    <View style={styles.row}>
                        <View
                            style={{
                                ...styles.selectWrapper,
                                ...styles.noFlex,
                                width: columnWidth
                            }}
                        >
                            <Adapter.SelectInputColumn
                                {...(isDropOffProperty
                                    ? form.dropOffFloor
                                    : form.pickUpFloor
                                )}
                                errorMessage={isDropOffProperty
                                    ? formErrors?.dropOffFloor || ''
                                    : formErrors?.pickUpFloor || ''}
                                smallLabel
                                options={floorOptions}
                                onOptionSelect={option => {
                                    Segment.bookingPropertyFloorSelected({
                                        number: Number(option.value)
                                    })
                                }}
                                onMenuOpen={() => {
                                    Segment.bookingPropertyFloorClicked({})
                                }}
                                onChangeValue={value => {
                                    if (!value) {
                                        Segment.bookingClearPropertyFloorClicked({})
                                    }

                                    isDropOffProperty
                                        ? form.dropOffFloor.onChangeValue(value)
                                        : form.pickUpFloor.onChangeValue(value)
                                }}
                            />
                        </View>
                        <Grid.Gap gapRight={2}/>
                        {showElevatorOption && (
                            <View
                                style={{
                                    ...styles.selectWrapper,
                                    ...styles.zIndex
                                }}
                            >
                                <Adapter.RadioButtons
                                    {...(isDropOffProperty
                                        ? form.dropOffHasElevator
                                        : form.pickUpHasElevator
                                    )}
                                    onChangeValue={value => {
                                        Segment.bookingElevatorSelected({
                                            elevator: value
                                        })

                                        isDropOffProperty
                                            ? form.dropOffHasElevator.onChangeValue(value)
                                            : form.pickUpHasElevator.onChangeValue(value)
                                    }}
                                />
                            </View>
                        )}
                    </View>
                </View>
            )}
            {isAuthorized && (
                <EditAddressModal
                    skipConfirmation
                    isOpen={isEditModalOpen}
                    modalTitle={isDropOffProperty
                        ? T.screens.moversAndPackers.formFields.dropOffLocation.modalTitle
                        : T.screens.moversAndPackers.formFields.pickUpLocation.modalTitle
                    }
                    onClose={() => setIsEditModalOpen(false)}
                    onAddNew={() => {
                        setIsEditModalOpen(false)
                        setIsNewAddressModalOpen(true)
                    }}
                    onSuccess={address => isDropOffProperty
                        ? form.dropOffLocation?.onChangeValue(address)
                        : form.pickUpLocation?.onChangeValue(address)
                    }
                />
            )}
            <NewAddressModal
                skipConfirmation
                isOpen={isNewAddressModalOpen}
                modalTitle={isDropOffProperty
                    ? T.screens.moversAndPackers.formFields.dropOffLocation.modalTitle
                    : T.screens.moversAndPackers.formFields.pickUpLocation.modalTitle
                }
                onClose={() => setIsNewAddressModalOpen(false)}
                onEdit={() => {
                    setIsEditModalOpen(true)
                    setIsNewAddressModalOpen(false)
                }}
                onSubmit={address => {
                    setIsNewAddressModalOpen(false)
                    isDropOffProperty
                        ? form.dropOffLocation?.onChangeValue(address)
                        : form.pickUpLocation?.onChangeValue(address)
                }}
                onSuccess={address => isDropOffProperty
                    ? form.dropOffLocation?.onChangeValue(address)
                    : form.pickUpLocation?.onChangeValue(address)
                }
            />
        </FormComponents.FormRow>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginBottom: theme.utils.gap(2)
    },
    row: {
        zIndex: theme.zIndex[10],
        marginTop: theme.utils.gap(1),
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    noFlex: {
        flex: undefined
    },
    selectWrapper: {
        flex: 1,
        zIndex: theme.zIndex[10]
    },
    zIndex: {
        zIndex: -1
    },
    textRow: {
        marginBottom: theme.utils.gap(1 / 2)
    }
}))
