import React, { useEffect } from 'react'
import { useNativeIsAuthorizedAtom, useNativeUserAtom, useNativeUserTokenAtom } from '../atoms'
import { useIsAuthorizedAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { User } from 'lib/models'

// This component is responsible for updating the old atoms with the new atoms
export const AtomUpdater: React.FunctionComponent = () => {
    const [nativeIsAuthorized] = useNativeIsAuthorizedAtom()
    const [nativeUser] = useNativeUserAtom()
    const [nativeUserToken] = useNativeUserTokenAtom()
    const [, setIsAuthorized] = useIsAuthorizedAtom()
    const [, setUser] = useUserAtom()
    const [, setUserToken] = useUserTokenAtom()

    useEffect(() => {
        setIsAuthorized(nativeIsAuthorized)
    }, [nativeIsAuthorized])

    useEffect(() => {
        setUser(nativeUser as User)
    }, [nativeUser])

    useEffect(() => {
        setUserToken(nativeUserToken)
    }, [nativeUserToken])

    return null
}
