import React from 'react'
import { DimensionValue, View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { isNative, Measurements } from 'lib/common'
import { SmallSubheading } from '../typography'

type BookingsCalendarBlankDaysProps = {
    numberOfDays: number,
    previousMonthNumber?: number,
    mobilePadding: number
}

export const BookingsCalendarBlankDays: React.FunctionComponent<BookingsCalendarBlankDaysProps> = ({
    numberOfDays,
    previousMonthNumber = 0,
    mobilePadding
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const dayContainerWidth = isNative
        ? Math.floor((Measurements.WindowWidth - mobilePadding) / 7)
        : 'calc(100% / 7)' as DimensionValue
    const blankDaysArray = Array.from(new Array(numberOfDays))

    return (
        <React.Fragment>
            {blankDaysArray.map((item, index) => {
                const dayNumber = previousMonthNumber
                    ? previousMonthNumber - (numberOfDays - (index + 1))
                    : index + 1

                return (
                    <View
                        key={index}
                        style={{
                            ...styles.dayContainer,
                            width: dayContainerWidth,
                            borderRightWidth: !previousMonthNumber && index === blankDaysArray.length - 1
                                ? 0
                                : 1
                        }}
                    >
                        <SmallSubheading forceColor={theme.colors.fog}>
                            {dayNumber.toString()}
                        </SmallSubheading>
                    </View>
                )
            })}
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    dayContainer: {
        height: {
            ':w[860, ]': 150,
            ':w[, 860]': 50
        },
        alignItems: 'center',
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        }
    }
}))
