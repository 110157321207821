import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useTranslations } from 'lib/hooks'
import { APP_CONFIG } from 'lib/config'
import { AppStackParams } from '../stackParams'
import { ScreenNames } from '../screens'
import {
    AirConditionScreen,
    AirConditionSummaryScreen,
    CleaningScreen,
    CleaningSummaryScreen,
    CookingScreen,
    CookingSummaryScreen,
    DeepCleaningScreen,
    DeepCleaningSummaryScreen,
    HandymanScreen,
    HandymanSummaryScreen,
    LaundryScreen,
    LaundrySummaryScreen,
    MoversAndPackersScreen,
    MoversAndPackersServiceDetailsScreen,
    PestControlScreen,
    PestControlSummaryScreen,
    TaskAndErrandsScreen,
    TaskAndErrandsSummaryScreen
} from 'features/bookings'

const Stack = createNativeStackNavigator<AppStackParams>()

// TODO: Rename to Onboarding stack when consolidating navigation
export const ServicesStack = () => {
    const T = useTranslations()

    return (
        <React.Fragment>
            <Stack.Screen
                name={ScreenNames.Cleaning}
                component={CleaningScreen}
                options={{
                    title: T.screens.cleaning.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.CleaningSummary}
                component={CleaningSummaryScreen}
                options={{
                    title: T.screens.cleaningSummary.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.DeepCleaning}
                component={DeepCleaningScreen}
                options={{
                    title: T.screens.deepCleaning.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.DeepCleaningSummary}
                component={DeepCleaningSummaryScreen}
                options={{
                    title: T.screens.deepCleaningSummary.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AirCondition}
                component={AirConditionScreen}
                options={{
                    title: T.screens.airCondition.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AirConditionSummary}
                component={AirConditionSummaryScreen}
                options={{
                    title: T.screens.airConditionSummary.pageTitle
                }}
            />
            {APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNames.Handyman}
                        component={HandymanScreen}
                        options={{
                            title: T.screens.handyman.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.HandymanSummary}
                        component={HandymanSummaryScreen}
                        options={{
                            title: T.screens.handymanSummary.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_COOKING_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNames.Cooking}
                        component={CookingScreen}
                        options={{
                            title: T.screens.cooking.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.CookingSummary}
                        component={CookingSummaryScreen}
                        options={{
                            title: T.screens.cookingSummary.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNames.Laundry}
                        component={LaundryScreen}
                        options={{
                            title: T.screens.laundry.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.LaundrySummary}
                        component={LaundrySummaryScreen}
                        options={{
                            title: T.screens.laundrySummary.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNames.PestControl}
                        component={PestControlScreen}
                        options={{
                            title: T.screens.pestControl.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.PestControlSummary}
                        component={PestControlSummaryScreen}
                        options={{
                            title: T.screens.pestControl.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNames.TaskAndErrands}
                        component={TaskAndErrandsScreen}
                        options={{
                            title: T.screens.taskAndErrands.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.TaskAndErrandsSummary}
                        component={TaskAndErrandsSummaryScreen}
                        options={{
                            title: T.screens.taskAndErrands.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {APP_CONFIG.SERVICES.IS_MOVERS_AND_PACKERS_ENABLED && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNames.MoversAndPackers}
                        component={MoversAndPackersScreen}
                        options={{
                            title: T.screens.moversAndPackers.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.MoversAndPackersSummary}
                        component={MoversAndPackersServiceDetailsScreen}
                        options={{
                            title: T.screens.moversAndPackers.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    )
}
