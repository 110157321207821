import { useMutation } from '@tanstack/react-query'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { useExternalFetcher, useFetcher } from 'lib/api'
import { BookingEventsResponse, ServiceRecommendationResponse } from './types/response'
import { RequestWithToken } from './types/request'
import { RateJobResponse } from './types/responses'
import { HelperArrivedRequest, RateJobRequest } from './types/requests'
import { APP_CONFIG } from 'lib/config'

export const useGetCardsData = () => {
    const fetcher = useFetcher<BookingEventsResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/booking/events`)

    return useMutation<Response<BookingEventsResponse>, ErrorResponse, RequestWithToken>(fetcher)

}

export const useRateJob = () => {
    const fetcher = useFetcher<RateJobResponse>(HttpMethod.POST, `${APP_CONFIG.API.URL}/job/rate`)

    return useMutation<Response<RateJobResponse>, ErrorResponse, RateJobRequest>(fetcher)

}

export const useGetServiceRecommendations = () => {
    const fetcher = useExternalFetcher<ServiceRecommendationResponse>(HttpMethod.GET,  `${APP_CONFIG.BOOKING_SERVICES.URL}/recommendations`)

    return useMutation(fetcher)

}

export const useSaveHelperArrived = () => {
    const fetcher = useFetcher<HelperArrivedRequest>(HttpMethod.POST,  `${APP_CONFIG.BOOKING_SERVICES.URL}/booking/update/event`)

    return useMutation<Response<HelperArrivedRequest>, ErrorResponse, RequestWithToken>(fetcher)
}
