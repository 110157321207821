import type { AnimationObject } from 'lottie-react-native'
import * as Icons from './icons'
const signUp = require('./images/sign_up.png')
const logIn = require('./images/log_in.png')
const getOff = require('./images/get_off.png')
const cleaningHero = require('./images/cleaning_hero.png')
const getStartedAnimation = require('./lottie/get_started_animation.json')
const orderConfirmedAnimation = require('./lottie/order_confirmed_animation.json')
// TODO: Remove these imports and files while integrating from BE
enum Images {
    SignUp = signUp,
    LogIn = logIn,
    GetOff = getOff,
    CleaningHero = cleaningHero
}

const LottieFiles: Record<string, AnimationObject> = {
    GetStarted: getStartedAnimation,
    OrderConfirmed: orderConfirmedAnimation
}

export {
    Icons,
    Images,
    LottieFiles
}
