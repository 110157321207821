// @ts-nocheck
// noinspection ALL

// This file is generated by svgr

import React, { memo } from 'react'
import Svg, { Path, type SvgProps } from 'react-native-svg'

const SvgApple = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            fill="#0D1011"
            d="M20.993 17.586c-.302.699-.66 1.342-1.075 1.933-.565.807-1.028 1.365-1.385 1.674-.553.509-1.146.77-1.78.784-.456 0-1.005-.13-1.645-.392-.641-.262-1.23-.392-1.77-.392-.565 0-1.172.13-1.82.392-.65.263-1.173.4-1.573.413-.609.026-1.216-.242-1.82-.805-.387-.337-.87-.914-1.45-1.733-.62-.874-1.132-1.887-1.532-3.043-.428-1.248-.643-2.457-.643-3.627 0-1.34.29-2.496.87-3.465A5.102 5.102 0 0 1 7.19 7.483a4.9 4.9 0 0 1 2.463-.695c.483 0 1.117.15 1.905.443.785.295 1.29.444 1.51.444.166 0 .726-.175 1.676-.523.898-.323 1.656-.457 2.277-.404 1.683.135 2.947.799 3.787 1.994-1.505.912-2.249 2.189-2.234 3.827.013 1.276.476 2.338 1.386 3.181.413.392.873.694 1.385.909-.11.322-.228.63-.353.927ZM17.134 2.4c0 1-.365 1.934-1.094 2.799-.879 1.027-1.942 1.621-3.095 1.527a3.112 3.112 0 0 1-.023-.379c0-.96.418-1.987 1.16-2.828.371-.425.842-.779 1.414-1.061.57-.278 1.11-.432 1.617-.458.015.134.021.267.021.4Z"
        />
    </Svg>
)
const Memo = memo(SvgApple)
export default Memo
