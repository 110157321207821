import React, { FunctionComponent, useState } from 'react'
import { ActivityIndicator, Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import WebView from 'react-native-webview'
import { Modal } from '@propertyguru/hive-modal'
import { NativeModal } from 'lib/native/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type Props = {
    title: string
    uri: string
    isOpen: boolean
    onClose?: () => void
}

export const WebViewModal: FunctionComponent<Props> = ({ title, uri, isOpen, onClose }) => {
    const isWeb = Platform.OS === 'web'
    const { styles } = useStyles(stylesheet)
    const { top, bottom } = useSafeAreaInsets()
    const [isLoading, setIsLoading] = useState(true)

    const onCloseModal = () => {
        setIsLoading(true)
        onClose?.()
    }

    const onWebViewLoaded = () => {
        setIsLoading(false)
    }

    return (
        <NativeModal
            fullWidth
            isOpen={isOpen}
            onClose={onCloseModal}
            wrapperStyles={{ ...styles.wrapper, paddingTop: top }}
        >
            <Modal style={styles.modal}>
                <Modal.Header>
                    <Modal.Header.Title>
                        {title}
                    </Modal.Header.Title>
                    <Modal.Header.Button onPress={onCloseModal} />
                </Modal.Header>
                <Modal.Body style={{ ...styles.body, paddingBottom: bottom }}>
                    {isLoading && (
                        <View style={styles.loadingWrapper}>
                            <ActivityIndicator />
                        </View>
                    )}
                    {isWeb ? (
                        <iframe
                            onLoad={onWebViewLoaded}
                            allowFullScreen
                            src={uri}
                        />
                    ) : (
                        <WebView
                            scrollEnabled
                            nestedScrollEnabled
                            originWhitelist={['*']}
                            onLoadEnd={onWebViewLoaded}
                            source={{ uri }}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        padding: theme.utils.hive.spacing('none')
    },
    modal: {
        height: '100%'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    body: {
        width: '100%',
        flex: 1,
        padding: theme.utils.hive.spacing('none')
    },
    loadingWrapper: {
        zIndex: 1,
        backgroundColor: theme.utils.hive.color('fill/pressed/primary') as string,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    }
}))
