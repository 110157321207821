import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useNavigation } from '@react-navigation/native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography, Touchable, DockedModal } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { ScreenNames } from 'lib/routing'
import { isNative } from 'lib/common'
import { JobDetailsResponse } from 'features/jobs/types'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'

type SuggestPauseSubscriptionModalProps = {
    job: JobDetailsResponse,
    isOpen: boolean,
    onClose: VoidFunction,
    onRescheduleBooking: VoidFunction,
    onPauseBooking: VoidFunction,
}

export const SuggestPauseSubscriptionModal: React.FunctionComponent<SuggestPauseSubscriptionModalProps> = ({
    isOpen,
    job,
    onClose,
    onRescheduleBooking,
    onPauseBooking
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)
    const Modal = isNative ? DockedModal : CustomModal

    // const subscriptionPlanSavings = job?.subscriptionPlanSavings || {} as SubscriptionPlanSavings
    // const { message: savingMessage = '', value: savingValue = '' } = subscriptionPlanSavings

    const onProceedToCancel = () => {
        onClose()

        navigation.navigate(ScreenNames.CancelJob, {
            jobId: job.job._id
        })
    }

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.mainContainer}>
                <View style={styles.headerWrapper}>
                    <Typography.SmallSubheading style={styles.title}>
                        {T.screens.jobDetails.suggestPauseSubscriptionModal.packageTitle}
                    </Typography.SmallSubheading>
                    <View style={styles.messageContainer}>
                        <Typography.Regular style={styles.message}>
                            {T.screens.jobDetails.suggestPauseSubscriptionModal.message}
                        </Typography.Regular>
                    </View>
                </View>
                <View style={styles.contentWrapper}>
                    <Typography.Regular style={styles.highlightTitle}>
                        {T.screens.jobDetails.suggestPauseSubscriptionModal.highlightOfYourPlan}
                    </Typography.Regular>

                    <View style={styles.benefitWrapper}>
                        <View style={styles.benefitItem}>
                            <View style={styles.benefitIconContainer}>
                                <Icons.AnyTime forceColor={theme.colors.white} size={64} />
                            </View>
                            <Typography.Regular style={styles.benefitText}>
                                {T.screens.jobDetails.suggestPauseSubscriptionModal.pauseAndUnpauseAnytime}
                            </Typography.Regular>
                        </View>
                        <View style={styles.benefitItem}>
                            <View style={styles.benefitIconContainer}>
                                <Icons.SavedMoney forceColor={theme.colors.white} size={64} />
                            </View>
                            <Typography.Regular style={styles.benefitText}>
                                {T.screens.jobDetails.suggestPauseSubscriptionModal.bestValueSaving}
                            </Typography.Regular>
                        </View>
                        {/* {
                            savingMessage && savingValue && (
                                <View style={styles.benefitItem}>
                                    <View style={styles.benefitIconContainer}>
                                        <Icons.SavedMoney forceColor={theme.colors.white} size={64} />
                                    </View>
                                    <Typography.Regular style={styles.benefitText}>
                                        {savingMessage}
                                        <Typography.Regular style={{
                                            ...styles.benefitText,
                                            ...styles.boldText,
                                            ...styles.noWrap
                                        }}  forceColor={theme.colors.orange} bold>
                                            {` ${savingValue}`}
                                        </Typography.Regular>
                                    </Typography.Regular>
                                </View>
                            )
                        } */}

                        <View style={styles.benefitItem}>
                            <View style={styles.benefitIconContainer}>
                                <Icons.Cleaner forceColor={theme.colors.white} size={64} />
                            </View>
                            <Typography.Regular style={styles.benefitText}>
                                {T.screens.jobDetails.suggestPauseSubscriptionModal.youGetTheSameCleaner}
                            </Typography.Regular>
                        </View>

                        <View style={styles.benefitItem}>
                            <View style={styles.benefitIconContainer}>
                                <Icons.DedicatedSupport forceColor={theme.colors.white} size={64} />
                            </View>
                            <Typography.Regular style={styles.benefitText}>
                                <Typography.Regular style={{
                                    ...styles.benefitText,
                                    ...styles.boldText
                                }} forceColor={theme.colors.orange} bold>
                                    {`${T.screens.jobDetails.suggestPauseSubscriptionModal.dedicatedSupport} `}
                                </Typography.Regular>
                                <Typography.Regular style={styles.benefitText}>
                                    {T.screens.jobDetails.suggestPauseSubscriptionModal.resolveYourIssues}
                                </Typography.Regular>
                            </Typography.Regular>
                        </View>
                    </View>
                </View>

                <View style={styles.modalWrapper}>
                    <View style={styles.actionContainer}>
                        <View style={styles.buttonWrapper}>
                            <Button
                                testID={T.accessibility.jobDetailsScreen.suggestPauseSubscription.reschedule}
                                noBackground
                                text={T.screens.jobDetails.suggestPauseSubscriptionModal.reschedulePlan}
                                onPress={onRescheduleBooking}
                                textWrapperStyles={styles.buttonTextWrapper}
                                textStyles={styles.buttonText}
                            />
                        </View>
                        { !isAirconSubscription(job.job) && (
                            <View style={styles.buttonWrapper}>
                                <Button
                                    testID={T.accessibility.jobDetailsScreen.suggestPauseSubscription.pause}
                                    text={T.screens.jobDetails.suggestPauseSubscriptionModal.pausePlan}
                                    onPress={onPauseBooking}
                                    textWrapperStyles={styles.buttonTextWrapper}
                                    textStyles={styles.buttonText}
                                />
                            </View>
                        )}
                    </View>
                </View>
                <View style={styles.footerWrapper}>
                    <Touchable onPress={onProceedToCancel} testID={T.accessibility.jobDetailsScreen.suggestPauseSubscription.cancel}>
                        <Typography.Regular style={styles.cancelPlanLink}>
                            {T.screens.jobDetails.suggestPauseSubscriptionModal.noIWantToCancelPlan}
                        </Typography.Regular>
                    </Touchable>
                </View>
            </View>

        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    mainContainer: {
        flex: 1
    },
    modalWrapper: {
        paddingHorizontal: {
            lg: theme.utils.gap(12),
            md: theme.utils.gap(8),
            sm: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    headerWrapper: {
        alignItems: 'center'
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 31,
        fontFamily: CustomFonts.Poppins600
    },
    messageContainer: {
        marginTop: theme.utils.gap(1.5)
    },
    message: {
        textAlign: 'center'
    },
    actionContainer: {
        paddingBottom: theme.utils.gap(2.5),
        flexDirection: {
            md: 'row',
            xs: 'column-reverse'
        },
        justifyContent: 'center',
        zIndex: -1,
        flex: 1
    },
    buttonWrapper: {
        marginHorizontal: {
            md: theme.utils.gap(1),
            xs: undefined
        },
        marginVertical: {
            md: undefined,
            xs: theme.utils.gap(0.5)
        },
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(2.25)
    },
    buttonText: {
        textAlign: 'center'
    },
    footerWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    cancelPlanLink: {
        color: theme.colors.mouse,
        fontFamily: CustomFonts.Poppins500,
        fontSize: 14
    },
    additionFeeWarningContainer: {
        flexDirection: 'column',
        marginVertical: theme.utils.gap(2),
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.linen,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    warningText: {
        color: theme.colors.strawberry,
        fontFamily: CustomFonts.Poppins400,
        fontSize: 16,
        lineHeight: 24
    },
    boldText: {
        fontWeight: 'bold'
    },
    iconContainer: {
        marginBottom: theme.utils.gap(1.25)
    },
    noticeText: {
        textAlign: 'center',
        fontSize: 15,
        lineHeight: 24
    },
    warningContainer: {
        marginBottom: theme.utils.gap(1.5)
    },
    link: {
        color: theme.colors.strawberry,
        fontFamily: CustomFonts.Poppins500,
        fontSize: 15,
        lineHeight: 24,
        cursor: 'pointer'
    },
    contentWrapper: {
        padding: theme.utils.gap(2)
    },
    highlightTitle: {
        textAlign: 'center',
        color: theme.colors.orange,
        fontFamily: CustomFonts.Poppins500
    },
    benefitWrapper: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.utils.gap(2)
    },
    benefitItem: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1),
        backgroundColor: theme.colors.latte,
        borderRadius: 8,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1.5),
        width: {
            md: '70%',
            xs: '100%'
        }
    },
    benefitText: {
        fontFamily: CustomFonts.Poppins400,
        fontSize: 14,
        lineHeight: 22,
        width:  isNative ?  '70%' : undefined
    },
    benefitIconContainer: {
        marginRight: theme.utils.gap(1)
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
}))
