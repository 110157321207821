import React, { useCallback, useEffect, useState } from 'react'
import { FlatList, View, Image } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Badge } from '@propertyguru/hive-badge'
import { useHive } from '@propertyguru/hive'
import { TicketAdmitVipOutline } from '@propertyguru/hive-icons'
import { Surface } from '@propertyguru/hive-surface'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable } from 'lib/components'
import { Deal } from 'lib/types'
import { useBestDeals } from 'features/bookings/hooks/useBestDeals'
import { useHandleCardAction } from 'lib/native/hooks'
import { CommonCardAction } from 'features/bookings'
import { SegmentNative } from 'lib/analytics'

export const DealsSection: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const hive = useHive()
    const T = useTranslations()
    const labelTestIdMap = {
        'Discount available for all services': T.accessibility.dashboard.deals.welcome,
        'Earnings for you and your friend': T.accessibility.dashboard.deals.referral
    }
    const { bestDeals, requestBestDeals } = useBestDeals()
    const [isPressEnabled, setIsPressEnabled] = useState(true) // this is to prevent onPress function call on scroll
    const [deals, setDeals] = useState<Array<Deal> | undefined>(undefined)

    const handleCardAction = useHandleCardAction()

    const getTrackingInfo = (coupon: string) => ({ tracking: { elementType: SegmentNative.ElementClickedElementType.MarketingBannerClicked, information: {
        type: 'promo', value: coupon
    } } }) as CommonCardAction

    const handleDealPress = useCallback((deal: Deal) => {
        if (isPressEnabled) {
            handleCardAction({ ...deal.action, ...getTrackingInfo(deal.coupon) })
        }
    }, [isPressEnabled])

    useEffect(() => {
        requestBestDeals()
    }, [])

    useEffect(() => {
        if (bestDeals?.bestDealsCards) {
            setDeals(
                bestDeals?.bestDealsCards.map(deal => ({
                    image: { uri: deal.image },
                    label: deal.label,
                    coupon: deal.badge.code,
                    icon: deal.badge.icon ? TicketAdmitVipOutline : undefined,
                    action: deal.action
                }))
            )
        }
    }, [bestDeals])

    return (
        deals && (
            <View
                testID={T.accessibility.dashboard.deals.section}
                accessible
                accessibilityLabel={T.accessibility.dashboard.deals.section}
                style={styles.container}
            >
                <Text typography="label/l" style={styles.heading}>
                    {T.screens.dashboard.dealsSection.heading}
                </Text>
                <FlatList
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    data={deals}
                    onScrollBeginDrag={() => setIsPressEnabled(false)}
                    onScrollEndDrag={() => setIsPressEnabled(true)}
                    renderItem={({ item, index }) => (
                        <Surface elevation="soft/down/medium">
                            <View
                                key={index}
                                style={{
                                    ...styles.dealContainer,
                                    marginLeft: index === 0 ? hive.spacing('x4') : hive.spacing('x3'),
                                    marginRight: index === deals.length - 1 ? hive.spacing('x4') : 0
                                }}
                            >
                                <Touchable
                                    testID={labelTestIdMap[item.label] ?? T.accessibility.dashboard.deals.other}
                                    onPress={() => handleDealPress(item)}
                                    style={styles.card}
                                >
                                    <Image source={item.image} style={styles.image} />
                                    <View style={styles.deal}>
                                        <Text typography="label/xs">{item.label}</Text>
                                        <View style={styles.badgeWrapper}>
                                            <Badge
                                                label={item.coupon}
                                                icon={item.icon}
                                                color="orange"
                                                style={styles.badge}
                                                size="small"
                                                iconSize={16}
                                                type="secondary"
                                            />
                                        </View>
                                    </View>
                                </Touchable>
                            </View>
                        </Surface>
                    )}
                />
            </View>
        )
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        gap: theme.utils.hive.spacing('x4'),
        marginTop: theme.utils.hive.spacing('x6'),
        marginBottom: theme.utils.hive.spacing('x10')
    },
    heading: {
        marginLeft: theme.utils.hive.spacing('x4')
    },
    dealContainer: {
        margin: theme.utils.hive.spacing('x3'),
        marginBottom: theme.utils.hive.spacing('x4')
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.white,
        width: 329,
        overflow: 'hidden',
        borderRadius: theme.utils.hive.borderRadius('large')
    },
    image: {
        width: 329,
        height: 150
    },
    deal: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: 'auto',
        gap: theme.utils.hive.spacing('x2'),
        padding: theme.utils.hive.spacing('x4')
    },
    badgeWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: 'auto'
    },
    badge: {
        borderWidth: 0,
        height: 25,
        backgroundColor: theme.utils.hive.color('fill/warning/secondary') as string
    }
}))
