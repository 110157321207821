import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Divider } from '@propertyguru/hive-divider'
import { ChevronLeftOutline, LockPrivateOutline, PlusAddSmallFill, Wallet2Outline } from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { Icon } from '@propertyguru/hive-icon'
import { R } from 'lib/utils'
import { PaymentType } from 'lib/models'
import { createStyles } from 'lib/styles'
import { useAddNewCreditCard } from 'features/payments'
import { Segment, segmentUtils } from 'lib/analytics'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { ScreenHeader } from 'lib/native/components'
import { useNativeJobBookingDetailAtom, useNativeJobQuotationsAtom, useNativeUserAtom } from 'lib/native/atoms'
import { NativeNavigationParams, NativeNavigationProps } from 'lib/native/routing'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { BookingFrequency } from 'lib/native/models'
import { usePaymentMethod } from 'features/native/user/hooks'
import { PaymentMethodTile } from 'features/native/bookings/components'

type PaymentMethodScreenProps = {
    route: NativeNavigationParams<ScreenNamesNative.PaymentMethodNative>
    navigation: NativeNavigationProps<ScreenNamesNative.CleaningConfirmationNative>
}

export const PaymentMethodScreen: React.FunctionComponent<PaymentMethodScreenProps> = ({ route, navigation }) => {
    const {
        defaultPaymentMethod: defaultPaymentMethodByParams,
        defaultPaymentMethodId: defaultPaymentMethodIdByParams,
        onChangePaymentMethod
    } = route.params || {}

    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const [user] = useNativeUserAtom()
    const { mobileSubmit, isLoading } = useAddNewCreditCard(R.T)
    const {
        defaultPaymentType: defaultPaymentTypeByUserInfo,
        defaultPaymentMethod: defaultPaymentMethodByUserInfo,
        defaultPaymentMethodId: defaultPaymentMethodIdByUserInfo
    } = usePaymentMethod()
    const [sendpayError, setSendpayError] = useState<string | undefined>(undefined)

    const balance = usePrice(user?.consumer?.balance_credit ?? 0)
    const defaultMethod = user.payment_providers.filter(item => item.default)
    const defaultMethodId = defaultMethod?.[0]?._id
    const [selectedMethod, setSelectedMethod] = useState<string | undefined>()
    const [selectedMethodId, setSelectedMethodId] = useState<string | undefined>()
    const [previousDefaultMethodId, setPreviousDefaultMethodId] = useState<string | undefined>(undefined)
    const [jobQuotationsAtom] = useNativeJobQuotationsAtom()
    const [jobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const isOneTime = jobBookingDetailAtom.frequency === BookingFrequency.OneTime
    const priceTotal = isOneTime
        ? jobQuotationsAtom?.total?.value ?? 0
        : jobQuotationsAtom?.totalSubscription?.value ?? 0

    useEffect(() => {
        if (defaultMethodId && previousDefaultMethodId && previousDefaultMethodId !== defaultMethodId) {
            const selectedMethod = `${defaultMethod?.[0]?.card_type} ${defaultMethod?.[0]?.last_4}`
            setSelectedMethod(selectedMethod)
            setSelectedMethodId(defaultMethodId)
            onChangePaymentMethod && onChangePaymentMethod(PaymentType.Card, selectedMethod, defaultMethodId)
        }

        setPreviousDefaultMethodId(defaultMethodId)
    }, [defaultMethodId])

    useEffect(() => {
        if (defaultPaymentMethodByUserInfo) {
            setSelectedMethod(defaultPaymentMethodByUserInfo)
            setSelectedMethodId(defaultPaymentMethodIdByUserInfo)
            onChangePaymentMethod &&
                onChangePaymentMethod(
                    PaymentType.Card,
                    defaultPaymentTypeByUserInfo as string,
                    defaultPaymentMethodIdByUserInfo as string
                )
        }
    }, [])

    useEffect(() => {
        if (defaultPaymentMethodByParams && defaultPaymentMethodIdByParams) {
            setSelectedMethod(defaultPaymentMethodByParams)
            setSelectedMethodId(defaultPaymentMethodIdByParams)
        }
    }, [defaultPaymentMethodByParams, defaultPaymentMethodIdByParams])

    const defaultPaymentMethod = useMemo(
        () =>
            defaultMethod.length > 0 &&
            defaultMethod.map(paymentProvider => ({
                prefixIconUrl: paymentProvider.imageUrl,
                isSelected: selectedMethodId === paymentProvider?._id,
                label: `${paymentProvider.card_type} ${paymentProvider.last_4}`,
                onSelect: () => {
                    const selectedMethod = `${paymentProvider.card_type} ${paymentProvider.last_4}`
                    const selectedMethodId = paymentProvider._id
                    setSelectedMethod(selectedMethod)
                    setSelectedMethodId(selectedMethodId)
                    onChangePaymentMethod && onChangePaymentMethod(PaymentType.Card, selectedMethod, selectedMethodId)
                    Segment.bookingPaymentSwitched({
                        selectedPaymentMethod: segmentUtils.getSelectedPaymentMethod(PaymentType.Card)
                    })
                }
            }))[0],
        [selectedMethod, defaultMethod]
    )

    const otherProviders = useMemo(
        () => [
            ...user.payment_providers
                .filter(item => !item.default)
                .map(paymentProvider => ({
                    prefixIconUrl: paymentProvider.imageUrl,
                    isSelected: selectedMethodId === paymentProvider?._id,
                    label: `${paymentProvider.card_type} ${paymentProvider.last_4}`,
                    onSelect: () => {
                        const selectedMethod = `${paymentProvider.card_type} ${paymentProvider.last_4}`
                        const selectedMethodId = paymentProvider._id
                        setSendpayError(undefined)
                        setSelectedMethod(selectedMethod)
                        setSelectedMethodId(paymentProvider._id)
                        onChangePaymentMethod &&
                            onChangePaymentMethod(PaymentType.Card, selectedMethod, selectedMethodId)
                    }
                })),
            {
                prefixIcon: PlusAddSmallFill,
                label: T.screens.paymentMethod.addCard,
                onSelect: () => {
                    setSendpayError(undefined)
                    mobileSubmit()
                }
            },
            ...(isOneTime
                ? [
                    {
                        prefixIcon: Wallet2Outline,
                        label: T.common.sendPay,
                        description: balance,
                        actionText: T.common.topUp,
                        isSelected: selectedMethod === PaymentType.Credits,
                        errorText: sendpayError,
                        onSelect: () => {
                            if ((user?.consumer?.balance_credit ?? 0) < priceTotal) {
                                setSendpayError(T.screens.sendPay.notEnoughCredits)

                                return
                            }

                            setSelectedMethod(PaymentType.Credits)
                            setSelectedMethodId(PaymentType.Credits)
                            onChangePaymentMethod &&
                                  onChangePaymentMethod(PaymentType.Credits, PaymentType.Credits, PaymentType.Credits)
                            Segment.bookingPaymentSwitched({
                                selectedPaymentMethod: segmentUtils.getSelectedPaymentMethod(PaymentType.Credits)
                            })
                        },
                        onAction: () => {
                            navigation.navigate(ScreenNamesNative.SendPay)
                        }
                    }
                ]
                : [])
        ],
        [sendpayError, balance, priceTotal, user, mobileSubmit, selectedMethod, selectedMethodId, onChangePaymentMethod]
    )

    const allPaymentMethods = useMemo(() => {
        const paymentMethods = [...otherProviders]

        if (defaultPaymentMethod) {
            paymentMethods.unshift(defaultPaymentMethod)
        }

        return paymentMethods
    }, [defaultPaymentMethod, otherProviders])

    useEffect(() => {
        if ((user?.consumer?.balance_credit ?? 0) >= priceTotal) {
            setSendpayError(undefined)
        }
    }, [user, priceTotal])

    return (
        <View style={{ ...styles.container, paddingTop: insets.top, paddingBottom: insets.bottom }}>
            <ScreenHeader>
                <ScreenHeader.Button icon={ChevronLeftOutline} onPress={navigation.goBack} />
                <ScreenHeader.Title>{T.screens.paymentMethod.pageTitle}</ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <View style={styles.serviceDetailBanner}>
                <Icon icon={LockPrivateOutline} color="icon/success/primary" />
                <Text typography="label/xs" color="text/success/primary">
                    {T.screens.paymentMethod.bannerText}
                </Text>
            </View>
            <View style={styles.main}>
                {isLoading ? (
                    <View style={styles.loadingContainer}>
                        <Text typography="label/xs" color="text/success/primary">
                            {T.screens.paymentMethod.loading}
                        </Text>
                    </View>
                ) : (
                    <FlatList
                        data={allPaymentMethods}
                        renderItem={({ item }) => <PaymentMethodTile {...item} />}
                        ItemSeparatorComponent={() => <Divider />}
                        refreshing={isLoading}
                    />
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    main: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    },
    serviceDetailBanner: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.utils.hive.spacing('x1'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        paddingVertical: theme.utils.hive.spacing('x2'),
        backgroundColor: theme.utils.hive.color('fill/success/secondary') as string
    },
    loadingContainer: {
        padding: theme.utils.hive.spacing('x4'),
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
