import React from 'react'
import { useSharedValue, withTiming } from 'react-native-reanimated'
import { DimensionValue, ImageBackground, View } from 'react-native'
import { NewBadge, Touchable, Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics } from 'lib/analytics'
import { IconProps } from 'lib/types'
import { stringHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { BookNow } from './BookNow'
import { BookInMobileApp } from './BookInMobileApp'
import { SendhelperService } from '../../types'

type ServiceTileProps = {
    isLast: boolean
} & SendhelperService

export const ServiceTile: React.FunctionComponent<ServiceTileProps> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLast,
    ...service
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const animatedScale = useSharedValue(1)
    const animatedOpacity = useSharedValue(0)
    const Icon = React.createElement<IconProps>(service.icon, {
        forceColor: theme.colors.white,
        size: 16
    })

    return (
        <Touchable
            activeOpacity={0.8}
            style={styles.container}
            onHoverIn={() => {
                animatedScale.value = withTiming(1.02, { duration: 500 })
                animatedOpacity.value = withTiming(1)
            }}
            onHoverOut={() => {
                animatedScale.value = withTiming(1)
                animatedOpacity.value = withTiming(0)
            }}
        >
            <ImageBackground
                source={service.image}
                imageStyle={styles.image}
                style={styles.image}
                resizeMode="cover"
            >
                <View style={styles.row}>
                    <View style={styles.iconContainer}>
                        {Icon}
                    </View>
                    <Typography.Regular
                        bold
                        forceColor={theme.colors.white}
                    >
                        {service.name}
                    </Typography.Regular>
                    {service.isNew && (
                        <NewBadge large />
                    )}
                </View>
                <Typography.Label
                    numberOfLines={3}
                    style={styles.description}
                    forceColor={theme.colors.white}
                >
                    {service.description}
                </Typography.Label>
            </ImageBackground>
            {service.isAvailable ? (
                <BookNow
                    testID={stringHelpers.toKebabCase(`book-${service.name}`)}
                    onPress={() => {
                        service.onPress()
                        GoogleAnalytics.logEvent({
                            eventType: AnalyticsEvent.UserAction,
                            description: AnalyticsEventDescription.ServiceClicked,
                            value: service.name
                        })
                    }}
                    onLearnMore={service.onLearnMore}
                    animatedScale={animatedScale}
                    animatedOpacity={animatedOpacity}
                />
            ) : (
                <BookInMobileApp
                    animatedScale={animatedScale}
                    animatedOpacity={animatedOpacity}
                    onPress={() => {
                        GoogleAnalytics.logEvent({
                            eventType: AnalyticsEvent.UserAction,
                            description: AnalyticsEventDescription.ServiceClicked,
                            value: service.name
                        })
                    }}
                />
            )}
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        // It is safe here we are not using this screen on native
        width: 'calc((100% / 3) - 20px)' as DimensionValue,
        height: 215,
        backgroundColor: theme.colors.white,
        borderRadius: 8,
        ...theme.utils.createShadow()
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 8,
        justifyContent: 'flex-end',
        paddingHorizontal: theme.utils.gap(2)
    },
    iconContainer: {
        marginRight: 6 // custom
    },
    description: {
        height: 17 * 4, // 4 lines of line height
        marginTop: theme.utils.gap(1) / 2
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    }
}))
