import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const CalendarEmpty: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M25.973 2.79c0-1.598-1.326-2.89-2.957-2.89-1.63 0-2.957 1.292-2.957 2.89v4.552h-8.472c-5.838 0-10.576 4.624-10.576 10.332v52.093c0 5.71 4.738 10.333 10.577 10.333H64.92c5.839 0 10.576-4.624 10.576-10.333V17.674c0-5.708-4.737-10.332-10.576-10.332h-8.472V2.79c0-1.599-1.326-2.891-2.957-2.891-1.63 0-2.957 1.292-2.957 2.89v4.552H25.973V2.79ZM11.588 13.124h8.471v4.551c0 1.6 1.326 2.891 2.957 2.891 1.631 0 2.957-1.292 2.957-2.89v-4.552h24.562v4.551c0 1.6 1.326 2.891 2.957 2.891 1.631 0 2.957-1.292 2.957-2.89v-4.552h8.472c2.577 0 4.662 2.04 4.662 4.551v11.993H6.926V17.674c0-2.511 2.085-4.55 4.662-4.55ZM6.926 35.45h62.657v34.318c0 2.512-2.085 4.552-4.662 4.552H11.587c-2.576 0-4.661-2.04-4.661-4.552V35.45Z" />
    </Icon>
)
