import React from 'react'
import { AppLayout, SectionBox } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { UserNewAddressForm } from '../components'

type UserEditAddressScreenProps = {
    navigation: NavigationProps<ScreenNames.UserEditAddress>,
    route: NavigationParams<ScreenNames.UserEditAddress>
}

export const UserEditAddressScreen: React.FunctionComponent<UserEditAddressScreenProps> = () => {
    const T = useTranslations()

    return (
        <AppLayout title={T.screens.user.title}>
            <SectionBox title={T.screens.editAddress.section.editAddress}>
                <UserNewAddressForm />
            </SectionBox>
        </AppLayout>
    )
}

