import React from 'react'
import { Image, View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Images } from 'assets'
import { Text } from '@propertyguru/hive-text'
import { StarFavoriteAwardFill } from '@propertyguru/hive-icons'

export const CredibilitySection: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()

    return (
        <View style={styles.container}>
            <Image source={Images.Logo} style={styles.image} />
            <View style={styles.ratingContainer}>
                <View style={styles.rating}>
                    {[...Array(5).keys()].map(idx => (
                        <StarFavoriteAwardFill key={idx} height={7} width={7} fill={styles.image.tintColor} />
                    ))}
                </View>
                <Text typography="caption/s" color={'text/neutral/primary'}>
                    {T.screens.dashboard.credibilitySection.rating}
                </Text>
                <Text typography="caption/s" color={'text/neutral/primary'}>
                    {T.screens.dashboard.credibilitySection.jobs}
                </Text>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginVertical: 30,
        padding: theme.utils.hive.spacing('x4')
    },
    image: {
        height: 31,
        width: 115,
        tintColor: theme.utils.hive.color('text/neutral/primary') as string
    },
    ratingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    rating: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: theme.utils.hive.spacing('x1'),
        marginBottom: theme.utils.hive.spacing('x1')
    }
}))
