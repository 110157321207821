import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { Touchable } from 'lib/components'
import { Icon } from '@propertyguru/hive-icon'
import {
    CheckRadioCircleCheckboxCheckCheckmarkConfirmFill,
    CirclePlaceholderOnOutline
} from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { Badge } from '@propertyguru/hive-badge'
import { useHive } from '@propertyguru/hive'
import { linkingHelpers } from 'lib/utils'
import { PromoBoxProps } from './PromoBox.types.native'
import { stylesheet } from './PromoBox.styles.native'

export const PromoBoxContent: React.FC<PromoBoxProps> = ({
    title,
    condition,
    codeProps,
    active,
    selected,
    customBadgeStyles,
    dealProps,
    action
}) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)
    const icon = selected ? CheckRadioCircleCheckboxCheckCheckmarkConfirmFill : CirclePlaceholderOnOutline

    const onTermsConditionsPress = () => {
        if (action?.action?.type === 'url') {
            linkingHelpers.openUrl(action.action.url)
        }
    }

    return (
        <View style={{
            paddingHorizontal: active ? 0 : hive.spacing('x4'),
            paddingBottom: active ? 0 : hive.spacing('x4'),
            paddingTop: active ? 0 : hive.spacing('x4'),
            maxWidth: '100%'
        }}>
            <View style={styles.titleWrapper}>
                <Text
                    typography={'label/s'}
                    color={active ? 'text/active/primary' : 'text/active/secondary'}
                    style={styles.title}
                >
                    {title}
                </Text>
                <Icon icon={icon} width={28} height={28} />
            </View>
            <Text
                typography={'body/xs'}
                color={active ? 'text/active/secondary' : 'text/inactive/primary'}
                style={styles.condition}
            >
                {condition}
            </Text>

            <View style={{ ...styles.footerWrapper, paddingTop: hive.spacing('x2') }}>
                <View style={{ ...styles.promoDetails, columnGap: hive.spacing('x2') }}>
                    <Badge {...codeProps} style={customBadgeStyles} />
                    {dealProps && <Badge {...dealProps} />}
                </View>

                {action?.title && action?.action?.type === 'url' && (
                    <Touchable onPress={onTermsConditionsPress}>
                        <Text typography={'caption/xs'} style={{ color: '#0E2638' }} underline>
                            {action.title}
                        </Text>
                    </Touchable>
                )}
            </View>
        </View>
    )
}
