import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Text } from '@propertyguru/hive-text'
import { Icon } from '@propertyguru/hive-icon'
import { BellNotifyOutline } from '@propertyguru/hive-icons'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { useUnreadNotificationCounterAtom } from 'lib/atoms'
import { Touchable } from 'lib/components'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { MAX_COUNT_BADGE_LIMIT } from 'features/native/home/constants'
import { NativeStackProps } from 'lib/native/routing'

export const BellNotify: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const navigation = useNavigation<NativeStackProps<ScreenNamesNative>>()
    const [unreadNotificationCounter] = useUnreadNotificationCounterAtom()
    const unreadNotificationCounterText =
        unreadNotificationCounter > MAX_COUNT_BADGE_LIMIT ? `${MAX_COUNT_BADGE_LIMIT}+` : unreadNotificationCounter

    const handleNotificationPress = () => {
        navigation.navigate(ScreenNamesNative.Notifications)
    }

    return (
        <Touchable
            testID={T.accessibility.dashboard.notificationIcon}
            style={styles.container}
            onPress={handleNotificationPress}
        >
            {unreadNotificationCounter > 0 && (
                <View style={styles.badgeContainer}>
                    <View style={styles.badge}>
                        <Text typography="label/s">{unreadNotificationCounterText}</Text>
                    </View>
                </View>
            )}
            <Icon width={24} height={24} icon={BellNotifyOutline} />
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.utils.hive.spacing('x1')
    },
    badgeContainer: {
        position: 'absolute',
        top: -theme.utils.hive.spacing('x2'),
        left: theme.utils.hive.spacing('x3'),
        zIndex: 1
    },
    badge: {
        height: theme.utils.hive.spacing('x5'),
        minWidth: theme.utils.hive.spacing('x6'),
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'row',
        borderRadius: theme.utils.hive.borderRadius('full'),
        backgroundColor: theme.utils.hive.color('fill/active/primary') as string
    }
}))
