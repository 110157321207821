import React, { useState, useEffect } from 'react'
import { Dimensions } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { Modal } from '@propertyguru/hive-modal'
import { Text } from '@propertyguru/hive-text'
import { Icon } from '@propertyguru/hive-icon'
import { CheckRadioCircleCheckboxCheckCheckmarkConfirmFill } from '@propertyguru/hive-icons'
import { Touchable } from 'lib/components'
import { NativeModal } from 'lib/native/components'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'

export type CommercialSizeModalProps = {
    label: string
    options: Array<string>
    currentSize: string | undefined
    isOpen: boolean
    onClose: VoidFunction
    onSelect: (value: string) => void
}

export const CommercialSizeModal: React.FunctionComponent<CommercialSizeModalProps> = ({
    label,
    options,
    currentSize,
    isOpen,
    onClose,
    onSelect
}) => {
    const { styles } = useStyles(stylesheet)
    const hive = useHive()
    const [size, setSize] = useState(currentSize)

    useEffect(
        () => {
            setSize(currentSize)
        },
        [isOpen]
    )

    const handlePress = (value: string) => {
        setSize(value)
        onSelect(value)
    }

    return (
        <NativeModal isOpen={isOpen} onClose={onClose} scrollViewContainerStyles={styles.modalContainer}>
            <Modal style={styles.modal}>
                <Modal.Header>
                    <Modal.Header.Title>{label}</Modal.Header.Title>
                    <Modal.Header.Button onPress={onClose} />
                </Modal.Header>
                <Modal.Body style={styles.body}>
                    {options.map(option => (
                        <Touchable
                            key={option}
                            onPress={() => handlePress(option)}
                            style={{
                                ...styles.tile,
                                backgroundColor: size === option ? hive.color('fill/neutral/primary') : undefined
                            }}
                        >
                            <Text typography="label/xs">{option}</Text>
                            {size === option && (
                                <Icon
                                    icon={CheckRadioCircleCheckboxCheckCheckmarkConfirmFill}
                                    color={'icon/success/primary'}
                                    width={24}
                                    height={24}
                                />
                            )}
                        </Touchable>
                    ))}
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalContainer: { flex: 1, justifyContent: 'flex-end' },
    modal: { width: Dimensions.get('window').width - theme.utils.hive.spacing('x10') },
    body: { padding: 0 },
    tile: {
        padding: theme.utils.hive.spacing('x3'),
        borderBottomWidth: 1,
        borderBottomColor: theme.utils.hive.color('border/active/secondary') as string,
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}))
