import React, { useEffect, useRef } from 'react'
import { NativeSyntheticEvent, TextInputKeyPressEventData, View, TextInput as RNTextInput } from 'react-native'
import { TextInput } from '@propertyguru/hive-text-input'
import { Text } from '@propertyguru/hive-text'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { CustomFonts, Nullable } from 'lib/types'
import { regexes } from 'lib/utils'
import { OTP_LENGTH } from 'features/native/onboarding/constants'

type OTPInputsProps = {
    length: number,
    value: Array<string>,
    disabled: boolean,
    errorMessage?: string,
    placeholder?: string,
    onChange(value: Array<string>): void,
}

export const OTPInputs: React.FunctionComponent<OTPInputsProps> = ({
    length,
    value,
    placeholder,
    onChange,
    errorMessage
}) => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const inputRefs = useRef<Array<Nullable<RNTextInput | null>>>(Array(length).fill(null))
    const shouldShowError = Boolean(errorMessage)

    const onChangeValue = (text: string, index: number) => {
        const updatedValues = [...value]
        updatedValues[index] = text
        const newValues = [...updatedValues].map((v, i) => (i === index ? text : v))
        onChange(newValues)
    }

    const handleChange = (text: string, index: number, shouldKeepFocusOnCurrentInput: boolean = false) => {
        if (!regexes.isValidDigit(text)) {
            return
        }

        onChangeValue(text, index)
        const nextIndex = text.length !== 0 ? index + 1 : index - 1
        !shouldKeepFocusOnCurrentInput && inputRefs?.current?.[nextIndex]?.focus()
    }

    const handleKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>, index: number) => {
        const { nativeEvent } = event

        if (nativeEvent.key === 'Backspace') {
            const shouldKeepFocusOnCurrentInput = Boolean(value?.[index])

            return handleChange('', index, shouldKeepFocusOnCurrentInput)
        }

        if (!regexes.isValidDigit(nativeEvent.key)) {
            return
        }

        return handleChange(nativeEvent.key, index)
    }

    const autoFocusFirstInput = () => {
        const [firstInput] = inputRefs.current || []
        firstInput?.focus()
    }

    useEffect(() => {
        autoFocusFirstInput()
    }, [])

    useEffect(() => {
        if (!errorMessage) {
            return
        }

        inputRefs.current?.forEach(input => input?.blur())
    }, [errorMessage])

    const handleSingleCharChange = (text: string, index: number, shouldKeepFocusOnCurrentInput: boolean = false) => {
        handleChange(text?.slice(0, 1), index, shouldKeepFocusOnCurrentInput)
    }

    const fillRemainingOTP = (text: string, index: number, otpValues: Array<string>) => {
        const newValues = [...otpValues]
        newValues.length = OTP_LENGTH
        newValues.fill('', otpValues.length)

        text.split('').forEach((char, i) => {
            if (index + i < newValues.length) {
                newValues[index + i] = char
            }
        })

        return newValues
    }

    const onChangeText = (text: string, index: number) => {
        const numericText = text.replace(/\D/g, '')

        if (!numericText.length) {
            handleSingleCharChange('', index, true)

            return
        }

        if (numericText.length < OTP_LENGTH) {
            const currentChar = value?.[index] || ''
            handleSingleCharChange(currentChar, index)

            return
        }

        if (numericText.length >= OTP_LENGTH) {
            const newValues = fillRemainingOTP(numericText, index, value)
            onChange(newValues)

            inputRefs?.current?.[newValues.length - 1]?.focus()
        }
    }

    return (
        <View>
            <View style={styles.container}>
                {[...new Array(length)].map((item, index) => (
                    <TextInput
                        onPress={() => inputRefs.current?.[index]?.focus()}
                        ref={el => (inputRefs.current[index] = el)}
                        testID={`otp-number-${index}`}
                        key={index}
                        value={value[index]}
                        editable
                        error={shouldShowError}
                        style={styles.input}
                        placeholder={placeholder}
                        keyboardType="decimal-pad"
                        onChangeText={text => onChangeText(text, index)}
                        onKeyPress={event => handleKeyPress(event, index)}
                        accessibilityLabel={`${T.accessibility.native.failedLogin.sixDigitCode}${index}`}
                    />
                ))}
            </View>
            { shouldShowError && <Text color="text/error/primary" typography="caption/xs" style={styles.errorMessage}>{errorMessage}</Text> }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    input: {
        width: 50,
        height: 50,
        textAlign: 'center',
        fontFamily: CustomFonts.Poppins400
    },
    errorMessage: {
        marginTop: theme.utils.gap(0.5)
    }
}))
