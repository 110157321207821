import React, { FunctionComponent, useMemo, useState } from 'react'
import { View } from 'react-native'
import {
    GroundFloorLowFloorOutline,
    AnnotationBubbleXCloseDeleteOutline,
    ChecklistBoxCheckListNotificationOutline
} from '@propertyguru/hive-icons'
import { WebViewModal } from 'features/native/common/components'
import { ENV } from 'lib/config'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { CleaningInfoButton } from './CleaningInfoButton.native'
import { Segment, SegmentNative } from 'lib/analytics'

export const CleaningInfo: FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [shouldShowInfoModal, setShouldShowInfoModal] = useState(false)
    const [selectedTitle, setSelectedTitle] = useState<string>('')
    const [selectedUri, setSelectedUri] = useState<string>('')

    const onPress = ({ title, uri, track }: typeof infoButtons[0]) => () => {
        setShouldShowInfoModal(true)
        setSelectedTitle(title)
        setSelectedUri(uri)
        track()
    }

    const onCloseInfoModal = () => {
        setShouldShowInfoModal(false)
    }

    const infoButtons = useMemo(() => [
        {
            title: T.screens.cleaningNative.infoButtons.coverage,
            icon: GroundFloorLowFloorOutline,
            uri: `${ENV.SENDHELPER_MARKETING_SITE_URL}/coverage-areas-details`,
            track: () => Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.CleaningCoverageAreasClicked })
        },
        {
            title: T.screens.cleaningNative.infoButtons.notIncluded,
            icon: AnnotationBubbleXCloseDeleteOutline,
            uri: `${ENV.SENDHELPER_MARKETING_SITE_URL}/whats-not-included`,
            track: () => Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.WhatsNotIncludedClicked })
        },
        {
            title: T.screens.cleaningNative.infoButtons.supplies,
            icon: ChecklistBoxCheckListNotificationOutline,
            uri: `${ENV.SENDHELPER_MARKETING_SITE_URL}/cleaning-supplies`,
            track: () => Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.CleaningSuppliesClicked })
        }
    ], [])

    return (
        <View style={styles.container}>
            {infoButtons.map((button, index) => (
                <CleaningInfoButton
                    key={index}
                    title={button.title}
                    icon={button.icon}
                    onPress={onPress(button)}
                />
            ))}
            <WebViewModal
                title={selectedTitle}
                isOpen={shouldShowInfoModal}
                onClose={onCloseInfoModal}
                uri={selectedUri}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        rowGap: theme.utils.hive.spacing('x3')
    }
}))
