import { Field } from '@codegateinc/react-form-builder-v2'
import React from 'react'
import { DimensionValue, View } from 'react-native'
import { bookingsHooks, ServiceAddonsFormShape } from 'features/bookings'
import { NewAddressShape } from 'features/user'
import { useAddressAtom, useIsAuthorizedAtom } from 'lib/atoms'
import { isNative } from 'lib/common'
import { Segment } from 'lib/analytics'
import { Adapter, FormComponents, PolicyLinks } from 'lib/components'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { SelectInputOption } from 'lib/types'
import { SummaryNewAddressForm } from '../SummaryNewAddressForm'
import { ServiceAddonsFields } from '../../forms'

type TaskAndErrandsSummaryContentProps = {
    isLoading: boolean,
    additionalInformation: string,
    form: Record<keyof ServiceAddonsFormShape, Field<boolean & string>>
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const TaskAndErrandsSummaryContent: React.FunctionComponent<TaskAndErrandsSummaryContentProps> = ({
    form,
    isLoading,
    additionalInformation,
    addressForm,
    fetchPostalCodes
}) => {
    const T = useTranslations()
    const [address] = useAddressAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const { styles } = useStyles(stylesheet)
    const petsOptions = bookingsHooks.usePetsOptions()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(50% - 10px)' as DimensionValue

    return (
        <View>
            <View style={styles.wrapper}>
                <FormComponents.FormRow
                    isOptional
                    title={T.screens.deepCleaningSummary.sections.additionalInformation}
                >
                    <View
                        style={{
                            ...styles.selectWrapper,
                            width: columnWidth
                        }}
                    >
                        <Adapter.SelectInputColumn
                            {...form.pets}
                            disabled={isLoading}
                            options={petsOptions}
                            onOptionSelect={({ label }) => {
                                Segment.bookingAdditionalInformationAdded({
                                    information: {
                                        type: ServiceAddonsFields.Pets,
                                        value: label
                                    }
                                })
                            }}
                        />
                    </View>
                    <Adapter.TextInput
                        {...form.comment}
                        extraContent={additionalInformation}
                        inputProps={{
                            multiline: true
                        }}
                        onBlur={() => {
                            if (form.comment.value) {
                                Segment.bookingAdditionalInformationAdded({
                                    information: {
                                        type: ServiceAddonsFields.Comment,
                                        value: form.comment.value
                                    }
                                })
                            }
                        }}
                    />
                    <View>
                        {!address.postcode && isAuthorized && (
                            <SummaryNewAddressForm
                                form={addressForm}
                                isLoading={isLoading}
                                fetchPostalCodes={fetchPostalCodes}
                            />
                        )}
                    </View>
                </FormComponents.FormRow>
            </View>
            {isMobile && (
                <PolicyLinks />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        zIndex: theme.zIndex[10]
    },
    selectWrapper: {
        zIndex: theme.zIndex[10],
        marginTop: -theme.utils.gap(2)
    }
}))
