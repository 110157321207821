import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

export const MoversAndPackersMoversPricing: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [
        numberColumn,
        descriptionColumn,
        priceColumn
    ] = Object.values(T.components.moversAndPackersAddOnPricing.options.movers.columns)
    const rows = Object.values(T.components.moversAndPackersAddOnPricing.options.movers.rows)

    return (
        <View>
            <View style={styles.row}>
                <View style={styles.numberColumn}>
                    <Typography.Regular bold>
                        {numberColumn}
                    </Typography.Regular>
                </View>
                <View style={styles.flex}>
                    <Typography.Regular bold>
                        {descriptionColumn}
                    </Typography.Regular>
                </View>
                <View>
                    <Typography.Regular bold>
                        {priceColumn}
                    </Typography.Regular>
                </View>

            </View>
            {rows.map((item, index) => (
                <View
                    key={index}
                    style={styles.row}
                >
                    <View style={styles.numberColumn}>
                        <Typography.Regular style={styles.alignCenter}>
                            {index + 1}
                        </Typography.Regular>
                    </View>
                    <View style={styles.flex}>
                        <Typography.Regular>
                            {item.description}
                        </Typography.Regular>
                    </View>
                    <View>
                        <Typography.Regular bold>
                            {item.price}
                        </Typography.Regular>
                    </View>
                </View>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1,
        marginHorizontal: theme.utils.gap(1 / 2)
    },
    alignCenter: {
        textAlign: 'center'
    },
    numberColumn: {
        width: {
            lg: 130,
            xs: 50
        }
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        paddingBottom: theme.utils.gap(1),
        paddingTop: theme.utils.gap(3),
        borderBottomColor: theme.colors.silver
    }
}))
