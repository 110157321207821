import React, { ReactNode } from 'react'
import { View } from 'react-native'
import TableBodyRow from './BodyRow.native'

type Props = {
    children: ReactNode
}

const TableBody: React.FunctionComponent<Props> = ({ children }) => (
    <View>
        {children}
    </View>
)

export default Object.assign(TableBody, {
    Row: TableBodyRow
})
