import React from 'react'
import { ImageBackground } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Animated, { FadeIn } from 'react-native-reanimated'
import { Images } from 'assets'
import { useStyles } from 'lib/hooks'
import { useUserAtom } from 'lib/atoms'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { useWelcomeMessage } from '../hooks'

export const NativeAllServicesHeaderImage: React.FunctionComponent = () => {
    const [user] = useUserAtom()
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)
    const { welcome, message, isLoading, isClickable } = useWelcomeMessage()

    if (isLoading) {
        return (
            <ImageBackground
                style={styles.image}
                source={Images.NativeAllServicesHeader}
            />
        )
    }

    return (
        <Touchable
            disabled={!isClickable}
            onPress={() => navigation.navigate(ScreenNames.Bookings, {
                initialTabIndex: 0
            })}
        >
            <ImageBackground
                style={styles.image}
                source={Images.NativeAllServicesHeader}
            >
                <Animated.View entering={FadeIn}>
                    <Typography.Subheading>
                        {welcome}
                        <Typography.Subheading forceColor={theme.colors.orange}>
                            {` ${user.handle}`}
                        </Typography.Subheading>
                    .
                    </Typography.Subheading>
                    <Typography.Subheading>
                        {message}
                    </Typography.Subheading>
                </Animated.View>
            </ImageBackground>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    image: {
        height: 120,
        justifyContent: 'center',
        paddingHorizontal: theme.utils.gap(2)
    }
}))
