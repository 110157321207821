/* eslint-disable max-lines */
import React, { useState } from 'react'
import { Image, View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Badge } from '@propertyguru/hive-badge'
import { Surface } from '@propertyguru/hive-surface'
import { Icon } from '@propertyguru/hive-icon'
import { IconColor, useHive } from '@propertyguru/hive'
import {
    AddedPeopleAddUserAddedPersonOutline,
    DateTimeCalendarTimeOutline,
    HourglassTimeWatchClockOutline,
    WhatsappBw,
    RotateRepeatRefreshFill,
    CheckSmallCheckmarkOutline
} from '@propertyguru/hive-icons'
import { useNavigation } from '@react-navigation/native'
import { LinearGradient } from 'expo-linear-gradient'
import { selectTimeLabel, shortDateMonth } from 'lib/utils/date'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { linkingHelpers } from 'lib/utils'
import { Touchable } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { ServicesPlan } from 'lib/types'
import { RatingPopup } from './RatingPopup.native'
import { BookingStatus, DynamicBookingCards } from '../types'
import { POPUP, RATINGPOPUP, RECEIPTPOPUP, REDIRECT, URL } from '../constants'
import { Segment, SegmentNative, segmentUtils } from 'lib/analytics'

interface BookingCardProps {
    data?: DynamicBookingCards
    loading?: boolean
}

export const BookingCard: React.FunctionComponent<BookingCardProps> = ({ data, loading }) => {
    const { styles, theme } = useStyles(stylesheet)
    const T = useTranslations()
    const hive = useHive()
    const navigation = useNavigation()
    const [isOpen, setIsOpen] = useState(false)

    const getStatusBarColor = (status: BookingStatus) => {
        const statusColors = {
            [BookingStatus.Accepted]: hive.color('fill/error/primary'),
            [BookingStatus.Open]: hive.color('fill/error/primary'),
            [BookingStatus.Started]: hive.color('fill/active/primary'),
            [BookingStatus.Cancelled]: hive.color('fill/inactive/tertiary'),
            [BookingStatus.Completed]: hive.color('fill/success/primary')
        }

        return statusColors[status] || hive.color('fill/active/primary')
    }

    const timelineIcons = (index: number, status: BookingStatus) => {
        const gradientProps = {
            style: styles.timelineIcon,
            start: { x: 0.5, y: 0 },
            end: { x: 0.5, y: 1 },
            colors: ['#00CC70', '#009194']
        }

        if (
            index === 0 &&
            (status === BookingStatus.Accepted || status === BookingStatus.Started || status === BookingStatus.Open)
        ) {
            return (
                <LinearGradient {...gradientProps}>
                    <Icon
                        color={'fill/neutral/secondary' as IconColor}
                        icon={CheckSmallCheckmarkOutline}
                        width={28}
                        height={28}
                    />
                </LinearGradient>
            )
        }

        if (index === 1 && (status === BookingStatus.Started || data?.bookingMarkedAsStarted)) {
            return (
                <LinearGradient
                    {...gradientProps}
                    testID={T.accessibility.dashboard.booking.started}
                    accessibilityLabel={T.accessibility.dashboard.booking.started}
                    accessible
                >
                    <Icon
                        color={'fill/neutral/secondary' as IconColor}
                        icon={CheckSmallCheckmarkOutline}
                        width={28}
                        height={28}
                    />
                </LinearGradient>
            )
        }

        return <View style={styles.emptyTimelineIcon} />
    }

    const getTimelineSeperator = (index: number, status: BookingStatus) => {
        const gradientProps = {
            style: styles.timelineSeparator,
            start: { x: 0.5, y: 0 },
            end: { x: 0.5, y: 1 },
            colors: ['#00CC70', '#009194']
        }

        if (index === 0 && (status === BookingStatus.Started || data?.bookingMarkedAsStarted)) {
            return (
                <LinearGradient {...gradientProps}>
                    <View style={styles.timelineSeparator} />
                </LinearGradient>
            )
        }

        if (index === 0 && (status === BookingStatus.Accepted || status === BookingStatus.Open)) {
            return <View style={[styles.timelineSeparator, { backgroundColor: theme.colors.statusGrey }]} />
        }

        return <View style={[styles.timelineSeparator, { backgroundColor: theme.colors.statusGrey }]} />
    }

    const actionTestIdMap = {
        'Recover Order': T.accessibility.dashboard.booking.recover,
        'Rate Us': T.accessibility.dashboard.booking.rate,
        'View Receipt': T.accessibility.dashboard.booking.reciept,
        default: T.accessibility.dashboard.booking.other
    }

    const onOpenRatingsModal = () => {
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.RateUsClicked })
        setIsOpen(true)
    }

    const onRatingPress = (value: number) => {
        setIsOpen(false)
        Segment.serviceRatingSubmitted({ serviceType: data?.title })
        navigation.navigate(ScreenNamesNative.NativeRatingsScreen, { rating: value, jobId: data?._id || '' })
    }

    const onOpenPopup = (name: string) => {
        switch (name) {
            case RATINGPOPUP:
                return onOpenRatingsModal()
            case RECEIPTPOPUP:
                return null
            default:
                console.log('Other Popup')
        }
    }

    const onActionClick = action => {

        data?.status && Segment.cleaningDynamicCardClicked({ jobType: data?.job_type, jobStatus: segmentUtils.getReadableJobStatus(data?.bookingStatus) })

        switch (action?.action?.type) {
            case POPUP:
                return onOpenPopup(action?.action?.name)
            case URL:
                return linkingHelpers.openUrl(action?.action?.url)
            case REDIRECT: {
                const componentName = action?.action?.name as string
                componentName.includes(ScreenNamesNative.CleaningDetails) && Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.ViewReceiptClicked })

                if (ScreenNamesNative[componentName]) {
                    return navigation.navigate(ScreenNamesNative[componentName], {
                        jobId: data?._id || '',
                        jobStatus: data?.status || '',
                        isSession: true // It will be true because it is completed. Check 'CleaningDetailsScreen' for more info
                    })
                }

                return null
            }
            default:
                break
        }
    }

    const renderBottomSection = () => {
        if (data?.actions?.length) {
            return (
                <View style={styles.actionsContainer}>
                    {data.actions.map((action, index) => (
                        <React.Fragment key={action.title}>
                            <Touchable style={styles.actionContainer} onPress={() => onActionClick(action)}>
                                <View
                                    testID={actionTestIdMap[action.title] ?? actionTestIdMap.default}
                                    accessible
                                    accessibilityLabel={actionTestIdMap[action.title] ?? actionTestIdMap.default}
                                    style={styles.actionWrapper}
                                >
                                    <Image
                                        source={{
                                            uri: action.image
                                        }}
                                        style={{ width: 21, height: 21 }}
                                    />
                                    <Text typography="label/s" color={'text/active/primary'}>
                                        {' '}
                                        {action.title}{' '}
                                    </Text>
                                </View>
                            </Touchable>
                            {index < data.actions.length - 1 && <View style={styles.actionSeparator} />}
                        </React.Fragment>
                    ))}
                </View>
            )
        }

        return (
            <View
                style={styles.timelineWrapper}
                testID={T.accessibility.dashboard.booking.status}
                accessible
                accessibilityLabel={T.accessibility.dashboard.booking.status}
            >
                <View>
                    <View
                        style={{
                            ...styles.timelineContainer,
                            marginLeft: 8
                        }}
                    >
                        {data?.status !== undefined && timelineIcons(0, data?.status)}
                        {data?.status !== undefined && getTimelineSeperator(0, data?.status)}
                        {data?.status !== undefined && timelineIcons(1, data.status)}
                        {data?.status !== undefined && getTimelineSeperator(1, data?.status)}
                        {data?.status !== undefined && timelineIcons(2, data.status)}
                    </View>
                </View>

                <View style={styles.labelContainer}>
                    <View style={styles.timelineLabel}>
                        <Text typography="caption/xs" color={'text/active/secondary'}>
                            {' '}
                            Ordered{' '}
                        </Text>
                    </View>
                    <View style={styles.timelineLabelMiddle}>
                        <Text typography="caption/xs" color={'text/active/secondary'}>
                            {' '}
                            Started{' '}
                        </Text>
                    </View>
                    <View style={styles.timelineLabel}>
                        <Text typography="caption/xs" color={'text/active/secondary'}>
                            {' '}
                            Done{' '}
                        </Text>
                    </View>
                </View>
            </View>
        )
    }

    const handleWhatsappTap = () => {
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.DynamicCardContactUsClicked })
        linkingHelpers.openUrl(T.screens.dashboard.supportSection.whatsappUrl)
    }

    const renderIcon = () => {
        if (
            data?.status === BookingStatus.Accepted ||
            data?.status === BookingStatus.Started ||
            data?.status === BookingStatus.Open
        ) {
            return (
                <Touchable onPress={handleWhatsappTap}>
                    <Icon icon={WhatsappBw} width={24} height={24} />
                </Touchable>
            )
        }

        return null
    }

    const renderShimmerCard = () => (
        <View style={styles.container}>
            <View style={styles.topContainer}>
                <View style={styles.multipleShimmerElements}>
                    <View style={styles.shimmerElement} />
                </View>
                <View style={styles.multipleShimmerElements}>
                    <View style={styles.shimmerElement} />
                </View>
                <View style={styles.multipleShimmerElements}>
                    <View style={styles.shimmerElement} />
                    <View style={styles.shimmerElement} />
                </View>
                <View style={styles.multipleShimmerElementsBottom}>
                    <View style={styles.shimmerElementFullWidth} />
                </View>
            </View>
        </View>
    )

    const onDynamicCardClick = () => {
        data?.status !== undefined && Segment.cleaningDynamicCardClicked({ jobType: data?.job_type, jobStatus: segmentUtils.getReadableJobStatus(Number(data?.status)) })
        navigation.navigate(ScreenNames.CleaningDetails, { jobId: data?._id || '' })
    }

    if (loading) {
        return renderShimmerCard()
    }

    return (
        <Surface style={styles.container} elevation="soft/down/small">
            <Touchable style={styles.container} onPress={onDynamicCardClick}>
                <View style={styles.topContainer}>
                    <View>
                        {data?.label && (
                            <View style={styles.labelWrapper}>
                                <Image source={{ uri: data?.labelIcon }} style={styles.cardLabelIcon} />
                                <Text style={{ marginBottom: 6 }} typography="body/xs" color={'text/active/secondary'}>
                                    {data.label}
                                </Text>
                            </View>
                        )}
                        <View
                            style={{
                                ...styles.cardHeader,
                                width: data?.status === BookingStatus.Completed ? '60%' : '100%'
                            }}
                        >
                            <Text
                                typography="label/m"
                                color={'text/active/primary'}
                                testID={
                                    data?.status === BookingStatus.Completed
                                        ? T.accessibility.dashboard.booking.completed
                                        : undefined
                                }
                                accessibilityLabel={
                                    data?.status === BookingStatus.Completed
                                        ? T.accessibility.dashboard.booking.completed
                                        : undefined
                                }
                                accessible
                            >
                                {data?.title}
                                <View style={{ paddingLeft: 8, display: 'flex' }}>
                                    {data?.job_type === ServicesPlan.Recurring && (
                                        <Icon icon={RotateRepeatRefreshFill} width={16} height={16} />
                                    )}
                                </View>
                            </Text>
                            {data?.titleBadge && (
                                <Badge
                                    label={data?.titleBadge || ''}
                                    style={styles.badge}
                                    size="small"
                                    iconSize={16}
                                    type="secondary"
                                />
                            )}
                        </View>
                        <Text style={styles.cardIconWrapper}>{renderIcon()}</Text>
                        {data?.status !== BookingStatus.Completed && (
                            <View
                                style={styles.dateContainer}
                                testID={T.accessibility.dashboard.booking.date}
                                accessible
                                accessibilityLabel={T.accessibility.dashboard.booking.date}
                            >
                                <View style={styles.textContainer}>
                                    <Icon icon={DateTimeCalendarTimeOutline} width={20} height={20} />
                                    <Text typography="body/xs" color={'text/active/secondary'}>
                                        {shortDateMonth(data?.date_time ?? new Date())}
                                    </Text>
                                </View>
                                <Text style={styles.dateSeparator}>|</Text>
                                <Text typography="body/xs" color={'text/active/secondary'}>
                                    {T.common.from} {selectTimeLabel(data?.date_time ?? new Date())}
                                </Text>
                            </View>
                        )}
                        <View style={styles.bookingDetails}>
                            {data?.status !== BookingStatus.Completed && (
                                <View
                                    style={styles.userTimeContainer}
                                    testID={T.accessibility.dashboard.booking.duration}
                                    accessible
                                    accessibilityLabel={T.accessibility.dashboard.booking.duration}
                                >
                                    <Icon icon={HourglassTimeWatchClockOutline} width={20} height={20} />
                                    <Text typography="body/xs" color={'text/active/secondary'}>
                                        {data?.num_hours} {T.common.smallHours}
                                    </Text>
                                </View>
                            )}
                            {data?.supplier && (
                                <View
                                    style={styles.userTimeContainer}
                                    testID={T.accessibility.dashboard.booking.supplier}
                                    accessible
                                    accessibilityLabel={T.accessibility.dashboard.booking.supplier}
                                >
                                    <Icon icon={AddedPeopleAddUserAddedPersonOutline} width={20} height={20} />
                                    <Text typography="body/xs" color={'text/active/secondary'} numberOfLines={1}>
                                        {data?.supplier.handle}
                                    </Text>
                                </View>
                            )}
                            {data?.status === BookingStatus.Completed && (
                                <View style={styles.imageWrapper}>
                                    <Image source={{ uri: data?.image }} style={styles.celebrateImage} />
                                </View>
                            )}
                        </View>
                    </View>
                    <View
                        style={{
                            ...styles.statusBar,
                            backgroundColor:
                                data?.status !== undefined ? getStatusBarColor(data.status) : theme.colors.statusGrey
                        }}
                    />
                </View>
                <View style={styles.bottomContainer}>{renderBottomSection()}</View>
            </Touchable>
            <RatingPopup
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onRatingPress={onRatingPress}
                supplierName={data?.supplier?.handle}
                date={shortDateMonth(data?.date_time ?? new Date())}
                hours={data?.num_hours}
            />
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        minHeight: 175,
        width: '100%',
        borderRadius: theme.utils.hive.borderRadius('large'),
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string
    },
    topContainer: {
        display: 'flex',
        paddingVertical: 16,
        paddingHorizontal: 12,
        justifyContent: 'center'
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 4
    },
    dateSeparator: {
        marginHorizontal: 8
    },
    bookingDetails: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8
    },
    bottomContainer: {
        display: 'flex',
        marginTop: 'auto',
        borderTopWidth: 1,
        borderTopColor: '#E0E0E0'
    },
    statusBar: {
        height: 72,
        width: 4,
        position: 'absolute',
        borderTopRightRadius: 24,
        borderBottomRightRadius: 24
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4
    },
    userTimeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
        flex: 1
    },
    timelineContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    labelContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    timelineSeparator: {
        height: 3,
        flex: 1
    },
    timelineIcon: {
        flexShrink: 0,
        borderWidth: 1,
        borderColor: '#EFF1F5',
        borderRadius: 100,
        backgroundColor: '#C1C9D2'
    },
    emptyTimelineIcon: {
        flexShrink: 0,
        borderWidth: 1,
        borderColor: '#EFF1F5',
        borderRadius: 100,
        backgroundColor: '#C1C9D2',
        width: 30,
        height: 30
    },
    timelineLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    timelineLabelMiddle: {
        marginLeft: -12
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        gap: 6,
        paddingVertical: 10
    },
    actionWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 6
    },
    actionSeparator: {
        width: 1,
        height: '100%',
        backgroundColor: '#E0E0E0'
    },
    timelineWrapper: {
        paddingVertical: 8,
        paddingHorizontal: 24
    },
    shimmerElement: {
        flex: 0.75,
        height: 8,
        backgroundColor: '#E0E0E0',
        borderRadius: 24,
        padding: 8
    },
    shimmerElementFullWidth: {
        flex: 1,
        height: 8,
        backgroundColor: '#E0E0E0',
        borderRadius: 24,
        padding: 8
    },
    multipleShimmerElements: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        paddingVertical: 8
    },
    multipleShimmerElementsBottom: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 12,
        borderTopWidth: 1,
        borderTopColor: '#E0E0E0'
    },
    cardLabelIcon: {
        width: 16,
        height: 16,
        marginRight: 4
    },
    celebrateImage: {
        width: 84,
        height: 84,
        flex: 1
    },
    cardIconWrapper: {
        position: 'absolute',
        right: 0,
        top: 0
    },
    imageWrapper: {
        position: 'absolute',
        right: 30,
        top: -60
    },
    badge: {
        borderWidth: 0,
        height: 25,
        backgroundColor: theme.utils.hive.color('fill/neutral/tertiary') as string,
        marginLeft: 8,
        color: theme.utils.hive.color('text/active/primary') as string
    },
    labelWrapper: {
        display: 'flex',
        flexDirection: 'row'
    }
}))
