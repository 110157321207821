import React, { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useHive } from '@propertyguru/hive'
import { Divider } from '@propertyguru/hive-divider'
import { Chip } from '@propertyguru/hive-chip'
import { Icon } from '@propertyguru/hive-icon'
import {
    ArrowLeftOutline,
    CalendarEditDateEditOutline,
    CheckSmallCheckmarkFill,
    ChevronLeftOutline,
    ChevronRightSmallFill
} from '@propertyguru/hive-icons'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { useNavigation } from '@react-navigation/native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { NativeNavigationParams } from 'lib/native/routing'
import { ScreenHeader } from 'lib/native/components'
import { TimeSlot } from 'lib/native/types'
import { CleaningScheduleType, Schedule } from 'lib/native/models'
import { useNativeJobBookingDetailAtom } from 'lib/native/atoms'
import { WEEKDAYS } from 'features/native/bookings/constants'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { useCleaningRecurringSchedule } from 'features/native/bookings/hooks'
import {
    DateChangeConfirmationModal,
    Footer,
    FooterBreakdownWrapper,
    StickyScheduleMessage,
    TimeSlotsModal
} from 'features/native/bookings/components'
import { Touchable } from 'lib/components'
type CleaningServiceCustomiseScheduleProps = {
    route: NativeNavigationParams<ScreenNamesNative.CleaningServiceCustomiseScheduleNative>
}

export const CleaningServiceCustomiseSchedule: React.FunctionComponent<CleaningServiceCustomiseScheduleProps> = ({ route }) => {
    const hive = useHive()
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const defaultDate = route.params.scheduleDateTime
    const defaultDay = dateHelpers.getDayOfDateShort(route.params.scheduleDateTime)
    const defaultTime = dateHelpers.formatDateWithTime(route.params.scheduleDateTime)
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const { handleDatesChange } = useCleaningRecurringSchedule()
    const [activeDays, setActiveDays] = useState<Array<Schedule>>([{ day: defaultDay, time: defaultTime }])
    const [activeDay, setActiveDay] = useState<Schedule>({ day: defaultDay, time: defaultTime })
    const [timeSlotModalOpen, setTimeSlotModalOpen] = useState(false)
    const [scheduleType, setScheduleType] = useState<CleaningScheduleType>(CleaningScheduleType.Weekly)
    const [changeDayConfirmModal, setChangeDayConfirmModal] = useState(false)
    const isDayActive = (day: string) => activeDays.findIndex(item => item.day === day) > -1
    const formattedDayDate = dateHelpers.formatDateWithDayAndMonth(route.params.scheduleDateTime) as string
    const isWeekly = scheduleType === CleaningScheduleType.Weekly
    const getSchedule = () => activeDays.map(item => ({ date: dateHelpers.changeDayOfDate(dateHelpers.combineDateAndTimeToSGT(defaultDate, item.time), item.day), day: item.day }))
    const getLocalSchedule = () => activeDays.map(item => ({ date: dateHelpers.changeDayOfDate(dateHelpers.combineDateAndTime(defaultDate, item.time), item.day), day: item.day }))

    useEffect(() => {
        if (defaultDate && defaultDay && activeDays.length > 0) {
            const schedule = getSchedule()
            const uiSchedule = getLocalSchedule()
            handleDatesChange(schedule, scheduleType === CleaningScheduleType.BiWeekly, uiSchedule)
        }
    }, [activeDays, scheduleType])

    const handleNext = () => {
        navigation.navigate(ScreenNamesNative.CleaningServiceAddressNative)
    }

    const handleTimeSlotModalConfirm = (timeSlot?: TimeSlot) => {
        if (timeSlot) {
            const updatedDays = activeDays.map(item => item.day === activeDay.day ? { day: item.day, time: timeSlot.id } : item)
            setActiveDays(updatedDays)
        }

        setTimeSlotModalOpen(false)
    }

    const handleSlotModalClose = () => setTimeSlotModalOpen(false)

    const handleSlotModalOpen = (day: string) => {
        const currentActiveDay = activeDays.find(item => item.day === day)
        setActiveDay({ day, time: currentActiveDay?.time || defaultTime })
        setTimeSlotModalOpen(true)
    }

    const handleChangeStartingDay = () => navigation.goBack()

    const onChangeScheduleType = (type: CleaningScheduleType) => {
        setScheduleType(type)
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            scheduleType: type as CleaningScheduleType
        })
    }

    const handleDayClick = (day: string) => {
        if (isDayActive(day) && day === defaultDay) {
            setChangeDayConfirmModal(true)

            return
        }

        if (isDayActive(day)) {
            setActiveDays(activeDays.filter(item => item.day !== day))

            return
        }

        setActiveDays([...activeDays, { day, time: defaultTime }])
    }

    return (
        <View style={{ ...styles.container, paddingTop: insets.top }}>
            <ScreenHeader>
                <ScreenHeader.Button icon={ChevronLeftOutline} onPress={navigation.goBack} />
                <ScreenHeader.Title>
                    {T.screens.cleaningServiceCustomiseSchedule.pageTitle}
                </ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <StickyScheduleMessage message="First cleaning starts on" date={formattedDayDate} />
            <ScrollView style={{
                ...styles.main,
                backgroundColor: hive.color('fill/neutral/primary')
            }}>
                <View style={styles.dayDateInfoContainer}>
                    <Text typography="label/m" style={styles.daysText} >Which days should the expert come?</Text>
                    <View style={styles.daysContainer}>
                        {WEEKDAYS.map((day, key) => (
                            <View style={styles.chipContainer} key={key}>
                                {/* TODO: extend support for border radius in Hive Chip component and uncomment line 74 and remove line 75 */}
                                {/* <Chip text={day} active={isDayActive(day)} style={styles.dayChip} onPress={() => handleDayClick(day)} borderRadius="medium" /> */}
                                <Chip text={day} active={isDayActive(day)} style={styles.dayChip} onPress={() => handleDayClick(day)} />
                                {isDayActive(day) && defaultDay === day && <Icon icon={CheckSmallCheckmarkFill} color="icon/success/primary" />}
                            </View>
                        ))}
                    </View>
                    <Text typography="label/m" style={styles.regularCleaningText} >Regular cleaning schedule</Text>
                    {activeDays.length > 0 && activeDays.map(({ day, time }, key) => (
                        <View key={key} style={styles.dayTimeContainer}>
                            <Text typography="label/s">{dateHelpers.formatDateWithDay(dateHelpers.changeDayOfDate(defaultDate, day))}</Text>
                            <Touchable style={styles.timeContainer} onPress={() => handleSlotModalOpen(day)}>
                                <Text typography="label/xs">
                                    {dateHelpers.formatDateWithTimeInterval(jobBookingDetailAtom.numberOfHours, dateHelpers.combineDateAndTime(defaultDate, time))}
                                </Text>
                                <Icon icon={ChevronRightSmallFill} />
                            </Touchable>
                        </View>
                    ))}

                    <View style={styles.changeStartDayContainer}>
                        <Icon icon={CalendarEditDateEditOutline} />
                        <Text typography="caption/xs" style={styles.changeStartDay} onPress={handleChangeStartingDay} >Change starting day </Text>
                    </View>

                </View>
                <View style={styles.intervalContainer}>
                    <Text typography="label/m" style={styles.daysText} >Cleaning interval</Text>
                    <View style={styles.intervals}>
                        <Chip text={T.screens.cleaningServiceCustomiseSchedule.schedule.everyWeek} style={styles.intervalChip} active={isWeekly} onPress={() => onChangeScheduleType(CleaningScheduleType.Weekly)} />
                        <Chip text={T.screens.cleaningServiceCustomiseSchedule.schedule.everyTwoWeeks} style={styles.intervalChip} active={!isWeekly} onPress={() => onChangeScheduleType(CleaningScheduleType.BiWeekly)} />
                    </View>
                </View>
            </ScrollView>
            <Footer style={{ paddingBottom: insets.bottom }}>
                <FooterBreakdownWrapper />
                <Footer.Buttons>
                    <Button.Icon icon={ArrowLeftOutline} onPress={navigation.goBack} />
                    <Button text={T.common.continue} style={styles.footerBtnNext} onPress={handleNext} />
                </Footer.Buttons>
            </Footer>
            <TimeSlotsModal isOpen={timeSlotModalOpen} handleClose={handleSlotModalClose} time={activeDay.time} onConfirm={handleTimeSlotModalConfirm} />
            <DateChangeConfirmationModal isOpen={changeDayConfirmModal} onClose={() => setChangeDayConfirmModal(false)} date={formattedDayDate} />
        </View>
    )
}

const stylesheet = createStyles(theme => {
    const hive = useHive()

    return ({
        container: {
            flex: 1,
            backgroundColor: theme.colors.white
        },
        textCenter: {
            textAlign: 'center'
        },
        main: {
            flex: 1
        },
        footerBtnNext: {
            flex: 1
        },
        daysText: {
            marginTop: hive.spacing('x4')
        },
        regularCleaningText: {
            marginVertical: hive.spacing('x5')
        },
        dayDateInfoContainer: {
            padding: hive.spacing('x4'),
            backgroundColor: hive.color('surface') as string
        },
        daysContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: hive.spacing('x3')
        },
        dayChip: {
            maxWidth: 42,
            minWidth: 42,
            minHeight: 40,
            maxHeight: 40,
            marginRight: hive.spacing('x2'),
            marginBottom: hive.spacing('x1'),
            paddingVertical: hive.spacing('x2'),
            paddingHorizontal: hive.spacing('x2'),
            justifyContent: 'center'
        },
        chipContainer: {
            alignItems: 'center'
        },
        dayTimeContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: hive.spacing('x4')
        },
        timeContainer: {
            flexDirection: 'row',
            alignItems: 'center'
        },
        changeStartDay: {
            textDecorationLine: 'underline',
            marginLeft: hive.spacing('x1')
        },
        changeStartDayContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: hive.spacing('x6')
        },
        intervalContainer: {
            marginTop: hive.spacing('x4'),
            padding: hive.spacing('x4'),
            backgroundColor: hive.color('surface') as string
        },
        intervals: {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            gap: hive.spacing('x2'),
            marginTop: hive.spacing('x3')
        },
        intervalChip: {
            flex: 1,
            height: 40
        }
    })
})
