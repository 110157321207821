import React, { FunctionComponent } from 'react'
import { View } from 'react-native'
import { SvgProps } from 'react-native-svg'
import { Icon } from '@propertyguru/hive-icon'
import { ChevronRightSmallFill } from '@propertyguru/hive-icons'
import { Surface } from '@propertyguru/hive-surface'
import { Text } from '@propertyguru/hive-text'
import { Touchable } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type Props = {
    title: string
    icon: React.NamedExoticComponent<SvgProps>
    onPress: () => void
}

export const CleaningInfoButton: FunctionComponent<Props> = ({
    title,
    icon,
    onPress
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Surface borderRadius="large" elevation="hard/down/medium">
            <Touchable style={styles.button} onPress={onPress}>
                <View style={styles.leftCol}>
                    <Icon
                        icon={icon}
                        height={24}
                        width={24}
                    />
                    <Text typography="label/s">{title}</Text>
                </View>
                <Icon
                    icon={ChevronRightSmallFill}
                    height={24}
                    width={24}
                />
            </Touchable>
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    button: {
        width: '100%',
        minHeight: 70,
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: theme.utils.hive.spacing('x2'),
        padding: theme.utils.hive.spacing('x4')
    },
    leftCol: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.utils.hive.spacing('x2')
    }
}))
