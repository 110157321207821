import { useEffect } from 'react'
import { BackHandler } from 'react-native'
import { isWeb } from 'lib/common'

export const useBackHandler = (onBackPressed: () => boolean) => {
    useEffect(() => {
        if (!isWeb) {
            const backHandler = BackHandler.addEventListener(
                'hardwareBackPress',
                onBackPressed
            )

            return () => backHandler.remove()
        }
    }, [onBackPressed])
}
