import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { isAndroid } from 'lib/common'
import { CalendarMessage as CalendarMessageType, CustomFonts } from 'lib/types'

interface CalendarMessageProps {
    calendarMessage: string | CalendarMessageType | undefined,
}

export const CalendarMessage: React.FunctionComponent<CalendarMessageProps> = ({
    calendarMessage
}) => {
    const { styles } = useStyles(stylesheet)

    if (!calendarMessage) {
        return null
    }

    return typeof calendarMessage === 'string' ? (
        <Typography.Label style={styles.exclusiveValue}>
            {calendarMessage}
        </Typography.Label>
    ) : (
        <Typography.Label>
            <View style={styles.exclusive}>
                <Typography.Label style={styles.exclusiveLabel}>
                    {` ${calendarMessage.platform} `}
                </Typography.Label>
            </View>
            <Typography.Label style={styles.exclusiveDescription}>
                {` ${calendarMessage.message}`}
            </Typography.Label>
            <Typography.Label style={styles.exclusiveValue}>
                {calendarMessage.daysOfWeek?.join(' &')}{calendarMessage.price}
            </Typography.Label>
        </Typography.Label>
    )
}

const stylesheet = createStyles(theme => ({
    exclusive: {
        backgroundColor: theme.colors.mint,
        borderRadius: 6,
        padding: theme.utils.gap(0),
        marginTop: -theme.utils.gap(isAndroid ? 0 : 0.5),
        paddingHorizontal: theme.utils.gap(0.25),
        marginBottom: theme.utils.gap(isAndroid ? 0 : 0.15)
    },
    exclusiveLabel: {
        color: theme.colors.green,
        lineHeight: isAndroid ? 18 : 22,
        fontSize: 12,
        fontFamily: CustomFonts.Roboto500
    },
    exclusiveDescription: {
        lineHeight: 22,
        fontSize: 13,
        color: theme.colors.grey
    },
    exclusiveValue: {
        color: theme.colors.night,
        lineHeight: 22,
        fontSize: 13,
        fontFamily: CustomFonts.Roboto500,
        marginTop: theme.utils.gap(1)
    }
}))
