import React from 'react'
import { DimensionValue, View } from 'react-native'
import { Measurements, isNative } from 'lib/common'
import { createStyles } from 'lib/styles'
import { useLocalizedDates, useStyles } from 'lib/hooks'
import { Regular } from '../typography'

type BookingsCalendarDaysNameProps = {
    mobilePadding: number
}

export const BookingsCalendarDaysName: React.FunctionComponent<BookingsCalendarDaysNameProps> = ({ mobilePadding }) => {
    const { styles, theme } = useStyles(stylesheet)
    const { getWeekDayLongNames } = useLocalizedDates()
    const weekDays = getWeekDayLongNames()
    const dayContainerWidth = isNative
        ? Math.floor((Measurements.WindowWidth - mobilePadding) / 7)
        : 'calc(100% / 7)' as DimensionValue

    return (
        <View style={styles.container}>
            {weekDays.map((dayName, index) => (
                <View
                    key={index}
                    style={{
                        ...styles.dayName,
                        width: dayContainerWidth,
                        borderRightWidth: index === weekDays.length - 1
                            ? 0
                            : 1
                    }}
                >
                    <Regular
                        bold
                        forceColor={theme.colors.darkSilver}
                    >
                        {dayName}
                    </Regular>
                </View>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row'
    },
    dayName: {
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(2)
    }
}))
