import React from 'react'
import { Surface } from '@propertyguru/hive-surface'
import { Text } from '@propertyguru/hive-text'
import { useIsLastTimeSlotExpired, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { useTimeSlotsByPeriod } from 'lib/native/hooks'
import { Period, TimeSlot } from 'lib/native/types'
import { PERIOD_TO_LABEL_MAP } from 'features/native/bookings/constants'
import { ChipTimeSlot } from 'lib/native/components'
import { PriceSlot, PricingType  } from 'lib/native/models'
import { dateHelpers } from 'lib/utils'

type Props = {
    period: Period;
    selectedSlot: TimeSlot | null;
    scheduledDate: string;
    setSelectedSlot: (timeSlot: TimeSlot) => void;
    type?: PricingType;
    onSelectBookingTime?: (priceSlot: PriceSlot | undefined, slot?: TimeSlot) => void;
    shouldShowDynamicPriceInfo?: boolean;
}

export const GroupedTimeSlots: React.FunctionComponent<Props> = ({
    selectedSlot,
    setSelectedSlot,
    period,
    onSelectBookingTime,
    type,
    scheduledDate,
    shouldShowDynamicPriceInfo = true
}) => {
    const timeSlots = useTimeSlotsByPeriod(period)
    const T = useTranslations()
    const isTimeSlotExpired = useIsLastTimeSlotExpired(timeSlots, scheduledDate)

    const { styles } = useStyles(stylesheet)

    return (
        <Surface style={styles.container}>
            <Text
                style={styles.label}
                typography="label/s"
                color={'text/active/secondary'}
            >
                {PERIOD_TO_LABEL_MAP?.[period]}
            </Text>
            {isTimeSlotExpired && (
                <Text
                    typography="caption/xs"
                    color={'text/active/secondary'}
                >
                    {T.screens.cleaningDateTime.unavailable}
                </Text>
            )}
            {!isTimeSlotExpired && timeSlots?.map(slot => {
                const timeOfChip = dateHelpers.combineDateAndTime(scheduledDate, slot.id)
                const isPastTimeSlot = dateHelpers.isSGTDatePast(timeOfChip)

                if (isPastTimeSlot) {
                    return null
                }

                return (
                    <ChipTimeSlot
                        key={slot.id}
                        slot={slot}
                        scheduledDate={scheduledDate}
                        type={type}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        onSelectBookingTime={onSelectBookingTime}
                        shouldShowDynamicPriceInfo={shouldShowDynamicPriceInfo}
                    />
                )
            })}
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: 1
    },
    label: {
        paddingBottom: theme.utils.hive.spacing('x4')
    }
}))
