import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Lock: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M49.455 28.571h-34.91v-9.4c0-3.569 1.444-6.991 4.014-9.515a13.83 13.83 0 0 1 9.688-3.942h1.688a13.83 13.83 0 0 1 9.688 3.942 13.336 13.336 0 0 1 4.013 9.515c0 .758.307 1.485.852 2.02a2.936 2.936 0 0 0 2.057.838c.772 0 1.512-.301 2.057-.837a2.831 2.831 0 0 0 .853-2.02c-.008-5.083-2.067-9.955-5.726-13.548C40.069 2.03 35.109.008 29.935 0h-1.688c-5.174.008-10.135 2.03-13.794 5.624-3.659 3.593-5.718 8.465-5.726 13.547v9.4a8.809 8.809 0 0 0-6.17 2.51A8.495 8.495 0 0 0 0 37.144v26.571c.008 4.317 1.757 8.455 4.865 11.508 3.108 3.052 7.321 4.77 11.717 4.778H41.6c4.395-.008 8.609-1.726 11.717-4.778 3.108-3.053 4.857-7.19 4.865-11.508V37.143c0-2.273-.92-4.454-2.556-6.061a8.809 8.809 0 0 0-6.171-2.51Zm2.909 35.143c0 2.804-1.134 5.493-3.153 7.475a10.864 10.864 0 0 1-7.611 3.097H16.582a10.864 10.864 0 0 1-7.611-3.097 10.477 10.477 0 0 1-3.153-7.475V37.143c0-.758.307-1.485.852-2.02a2.936 2.936 0 0 1 2.057-.837h40.728c.771 0 1.511.3 2.057.837.545.535.852 1.262.852 2.02v26.571ZM32 53.486v9.371c0 .758-.306 1.485-.852 2.02a2.936 2.936 0 0 1-2.057.837 2.936 2.936 0 0 1-2.057-.837 2.832 2.832 0 0 1-.852-2.02v-9.371a5.747 5.747 0 0 1-2.466-2.762 5.62 5.62 0 0 1-.245-3.666 5.71 5.71 0 0 1 2.078-3.054 5.887 5.887 0 0 1 3.542-1.181c1.28 0 2.526.415 3.542 1.18a5.71 5.71 0 0 1 2.078 3.055 5.62 5.62 0 0 1-.245 3.666A5.747 5.747 0 0 1 32 53.486Z" />
    </Icon>
)
