import React, { useState } from 'react'
import { DimensionValue, View } from 'react-native'
import { Field, UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { NewAddressShape } from 'features/user'
import { isNative } from 'lib/common'
import { Adapter, FormComponents, Grid, PolicyLinks, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { Nullable, SelectInputOption } from 'lib/types'
import { MoversAndPackersAddonsShape } from '../../forms'
import { usePetsOptions } from '../../hooks'
import { MoverAndPackersAddOnPricing } from '../MoverAndPackersAddOnPricing'
import { ExtraPriceLabel } from './ExtraPriceLabel'

type MoversAndPackersSummaryContentProps = {
    isLoading: boolean,
    formReturn: UseFormReturn<MoversAndPackersAddonsShape>,
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const MoversAndPackersSummaryContent: React.FunctionComponent<MoversAndPackersSummaryContentProps> = ({
    isLoading,
    formReturn
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const petsOptions = usePetsOptions()
    const [selectedPricingDetails, setSelectedPricingDetails] = useState<Nullable<number>>(null)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { form } = formReturn
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(50% - 10px)' as DimensionValue

    return (
        <View>
            <MoverAndPackersAddOnPricing
                tabIndex={selectedPricingDetails || 0}
                isOpen={selectedPricingDetails !== null}
                onClose={() => setSelectedPricingDetails(null)}
            />
            <View style={styles.wrapper}>
                <FormComponents.FormRow
                    isOptional
                    title={T.screens.moversAndPackersSummary.services}
                >
                    <View style={styles.addonsRow}>
                        <View
                            style={{
                                ...styles.checkboxWrapper,
                                width: columnWidth
                            }}
                        >
                            <Adapter.AddonCheckBox
                                {...form.movers}
                                disabled={isLoading}
                                value={Boolean(form.movers.value)}
                                onChangeValue={value => form.movers.onChangeValue(value ? 1 : 0)}
                                extraLabelText={() => (
                                    <ExtraPriceLabel price={40} />
                                )}
                                renderContent={() => (
                                    <Typography.Regular
                                        style={styles.underline}
                                        forceColor={theme.colors.orange}
                                        onPress={() => setSelectedPricingDetails(0)}
                                    >
                                        {T.screens.moversAndPackersSummary.viewDetails}
                                    </Typography.Regular>
                                )}
                            />
                            {Boolean(form.movers.value) && (
                                <View>
                                    <Adapter.NumberInput
                                        {...form.movers}
                                        label={T.screens.moversAndPackersSummary.formFields.movers.numberLabel}
                                        minValue={1}
                                    />
                                </View>
                            )}
                        </View>
                        <View
                            style={{
                                ...styles.checkboxWrapper,
                                width: columnWidth
                            }}
                        >
                            <Adapter.AddonCheckBox
                                {...form.tailgating}
                                disabled={isLoading}
                                extraLabelText={() => (
                                    <ExtraPriceLabel price={20} />
                                )}
                                renderContent={() => (
                                    <Typography.Regular
                                        style={styles.underline}
                                        forceColor={theme.colors.orange}
                                        onPress={() => setSelectedPricingDetails(1)}
                                    >
                                        {T.screens.moversAndPackersSummary.viewDetails}
                                    </Typography.Regular>
                                )}
                            />
                        </View>
                        <View
                            style={{
                                ...styles.checkboxWrapper,
                                width: columnWidth
                            }}
                        >
                            <Adapter.AddonCheckBox
                                {...form.wrapping}
                                disabled={isLoading}
                                value={Boolean(form.wrapping.value)}
                                onChangeValue={value => form.wrapping.onChangeValue(value ? 1 : 0)}
                                extraLabelText={() => (
                                    <ExtraPriceLabel price={20} />
                                )}
                                renderContent={() => (
                                    <Typography.Regular
                                        style={styles.underline}
                                        forceColor={theme.colors.orange}
                                        onPress={() => setSelectedPricingDetails(2)}
                                    >
                                        {T.screens.moversAndPackersSummary.viewDetails}
                                    </Typography.Regular>
                                )}
                            />
                            {Boolean(form.wrapping.value) && (
                                <View>
                                    <Adapter.NumberInput
                                        {...form.wrapping}
                                        label={T.screens.moversAndPackersSummary.formFields.wrapping.numberLabel}
                                        minValue={1}
                                    />
                                </View>
                            )}
                        </View>
                    </View>
                </FormComponents.FormRow>
                <FormComponents.FormRow
                    isOptional
                    title={T.screens.pestControlSummary.additionalInformation}
                >
                    <View
                        style={{
                            ...styles.selectWrapper,
                            width: columnWidth
                        }}
                    >
                        <Adapter.SelectInputColumn
                            {...form.pets}
                            disabled={isLoading}
                            options={petsOptions}
                        />
                    </View>
                    <Grid.Gap gapBottom={1}/>
                    <Adapter.TextInput
                        {...form.comment}
                        inputProps={{
                            multiline: true
                        }}
                    />
                </FormComponents.FormRow>
            </View>
            {isMobile && (
                <PolicyLinks />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        zIndex: theme.zIndex[10]
    },
    selectWrapper: {
        zIndex: theme.zIndex[10],
        marginTop: -theme.utils.gap(2)
    },
    addonsRow: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    checkboxWrapper: {
        zIndex: theme.zIndex[10]
    },
    underline: {
        textDecorationLine: 'underline'
    }
}))
