import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const OpenGift: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            stroke="#07A35D"
            d="M33.63 44.334h34.458c.801 0 1.447.646 1.447 1.445v32.775c0 .8-.647 1.445-1.447 1.445H45.1L33.63 80H10.641c-.8 0-1.447-.646-1.447-1.446V48.007l-.752.328a1.438 1.438 0 0 1-1.109.029 1.458 1.458 0 0 1-.8-.772L1.12 35.207c-.318-.733.02-1.581.743-1.9l10.775-4.703c-4.293-1.263-7.12-5.524-6.444-10.074.734-4.973 5.383-8.425 10.361-7.683 3.348.492 9.58 5.39 13.004 8.27.212-4.472.868-12.367 2.779-15.152 2.846-4.145 8.537-5.215 12.685-2.372 3.792 2.593 4.998 7.567 3.01 11.567L58.81 8.455a1.463 1.463 0 0 1 1.91.743l5.42 12.387c.154.347.164.752.02 1.11a1.458 1.458 0 0 1-.773.8L30.716 38.618l-13.11 5.716 16.024-.001Zm33.011 2.892H46.547v29.882H66.64V47.226ZM43.653 77.108V47.226h-8.576v29.882h8.576Zm-31.564 0h20.093V47.226H12.087l.002 29.882ZM8.606 45.105l1.34-.588c.078-.038.155-.077.232-.106l18.05-7.876-4.254-9.736-19.62 8.56 4.252 9.746Zm32.017-13.968-4.254-9.736-1.756.762c-.781.357-1.476.646-2.016.878l-1.1.482-1.167.51c-.501.223-1.119.492-1.833.8l-1.871.82 4.254 9.736 9.743-4.252ZM16.14 13.7c-3.405-.501-6.579 1.851-7.08 5.244a6.24 6.24 0 0 0 5.257 7.076c2.219.318 8.238-1.813 12.956-3.808l.983-.434c-4.003-3.403-9.733-7.721-12.115-8.078Zm28.882-1.07c1.94-2.834 1.216-6.719-1.62-8.656a6.202 6.202 0 0 0-3.511-1.09 6.225 6.225 0 0 0-5.151 2.709c-1.36 1.985-2.084 9.119-2.306 14.372l.984-.424c4.668-2.1 10.33-5.06 11.604-6.91Zm13.63-.945-19.62 8.56 4.254 9.736 19.62-8.56-4.253-9.736Z"
        />
    </Icon>
)
