import React from 'react'
import { View } from 'react-native'
import { MediaQuery } from 'lib/components'
import { Job, RecurringJob } from 'lib/models'
import { Breakpoint, createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobStatus, JobType } from 'lib/types'
import { jobHelpers } from 'lib/utils'
import { JobName } from './JobName'
import { JobDetailsResponse } from '../types'
import { BookingsProposals } from './BookingsProposals'
import { CancelRequest } from './CancelRequest'
import { ServiceLocation } from './ServiceLocation'
import { AdditionalInformation } from './AdditionalInformation'
import { CleaningServiceDate } from './CleaningServiceDate'
import { ServiceDetailsAccepted } from './ServiceDetailsAccepted'
import { RecurringSchedule } from './RecurringSchedule'
import { CleaningRecurringDetails } from './CleaningRecurringDetails'
import { JobRating } from './JobRating'
import { SubmittedMessage } from './SubmittedMessage'
import { RescheduledMessage } from './RescheduledMessage'

type CleaningDetailsContentProps = {
    response: JobDetailsResponse,
    recurringJob?: RecurringJob,
    allJobsData: Array<Job>,
    jobStatus?: JobStatus,
    isSession?: boolean,
}

export const CleaningDetailsContent: React.FunctionComponent<CleaningDetailsContentProps> = ({
    response,
    jobStatus,
    recurringJob,
    allJobsData,
    isSession
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isSubmitted = response.job.status === JobStatus.Submitted
    const isRecurring = response.job.job_type === JobType.Recurring && isSubmitted
    const isRecurringAccepted =
        response.job.job_type === JobType.Recurring &&
        (response.job.status === JobStatus.Accepted || response.job.status === JobStatus.Paused) &&
        isSession

    const isSubmittedSubscription = jobHelpers.isSubmittedSubscription(response)

    return (
        <View style={styles.wrapper}>
            {isRecurringAccepted ?
                (
                    <CleaningRecurringDetails
                        response={response}
                        allJobsData={allJobsData}
                    />
                ) : (
                    <View style={styles.contentWrapper}>
                        <View style={styles.content}>
                            <JobName
                                job={response}
                                isRecurring={response.job.job_type === JobType.Recurring}
                                recurringJob={recurringJob}
                            />
                            <RescheduledMessage response={response} />
                            {((recurringJob?._id && isRecurring) || response.job.schedule) &&
                                response.job.status !== JobStatus.Accepted && (
                                <RecurringSchedule schedule={response.job.schedule || recurringJob?.schedule} />
                            )}
                            {isSubmitted && (
                                <SubmittedMessage />
                            )}
                            <CleaningServiceDate
                                job={response}
                                isSubmitted={isSubmitted}
                            />
                            <AdditionalInformation job={response} />
                            <ServiceLocation job={response.job} />
                            <CancelRequest
                                job={response}
                                jobStatus={jobStatus}
                                text={
                                    isSubmittedSubscription ?
                                        T.screens.jobDetails.cancelPlan:
                                        isSubmitted ?
                                            T.screens.jobDetails.cancelRequest : undefined
                                }
                                forceColor={isSubmittedSubscription ? theme.colors.mouse : undefined}
                                hideButton={jobHelpers.isCancelButtonHiddenForChildJob(response)}
                            />
                        </View>
                        {jobStatus === JobStatus.Completed && (
                            <JobRating job={response} />
                        )}
                    </View>
                )
            }
            {response.job.status === JobStatus.Submitted
                ? (
                    <BookingsProposals
                        job={response}
                        isRecurring={isRecurring}
                    />
                ) : (
                    <React.Fragment>
                        <MediaQuery.Visible from={Breakpoint.LG}>
                            <ServiceDetailsAccepted
                                job={response}
                                jobStatus={jobStatus}
                                isRecurringAccepted={Boolean(isRecurringAccepted)}
                            />
                        </MediaQuery.Visible>
                    </React.Fragment>
                )
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'flex-start',
            xs: undefined
        },
        marginTop: -theme.utils.gap(6),
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    contentWrapper: {
        flex: 1
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
