import React from 'react'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { Modal, View, StyleSheet, DimensionValue } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Children, SidebarPosition } from 'lib/types'
import { Touchable } from './Touchable'

type AnimatedSidebarProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    children: Children,
    position: SidebarPosition
}

export const AnimatedSidebar: React.FunctionComponent<AnimatedSidebarProps> = ({
    isOpen,
    onClose,
    children,
    position
}) => {
    const { styles } = useStyles(stylesheet)
    const animatedSidebarStyles = useAnimatedStyle(() => ({
        [position]: withTiming(isOpen ? 0 : -260)
    }))

    return (
        <Modal
            transparent
            visible={isOpen}
            animationType="fade"
            onRequestClose={onClose}
        >
            <View style={styles.contentWrapper}>
                <Touchable
                    onPress={onClose}
                    style={styles.backdrop}
                />
                <View style={styles.backGround} />
            </View>
            <Animated.View
                style={[
                    styles.container,
                    animatedSidebarStyles
                ]}
            >
                {children}
            </Animated.View>
        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex[10],
        ...StyleSheet.absoluteFillObject
    },
    contentWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backGround: {
        flex: 1,
        width: '100%',
        backgroundColor: theme.colors.transparent,
        marginTop: 65
    },
    container: {
        width: 260,
        // This is ok, we only use this component on web, on mobile we use native drawers
        height: 'calc(100% - 65px)' as DimensionValue,
        position: 'absolute',
        top: 65,
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(0, 0.8, 8, 8)
    }
}))
