import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { LinkButton, Typography } from 'lib/components'

type UserPreviewPasswordProps = {
    onEdit: VoidFunction
}

export const UserPreviewPassword: React.FunctionComponent<UserPreviewPasswordProps> = ({ onEdit }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View>
            <View style={styles.row}>
                <View style={styles.labelColumn}>
                    <Typography.Regular>
                        {/* This is just placeholder. We need to move it somewhere? */}
                        **************
                    </Typography.Regular>
                </View>
            </View>
            <View style={styles.button}>
                <LinkButton
                    onPress={onEdit}
                    text={T.screens.user.changePassword}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        paddingVertical: theme.utils.gap(2),
        borderBottomColor: theme.colors.silver
    },
    labelColumn: {
        width: 100
    },
    button: {
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(3)
    }
}))
