import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { AlertV2BodyTitle } from './BodyTitle.native'
import { AlertV2BodyDescription } from './BodyDescription.native'

type Props = {
    children: ReactNode
}

const AlertV2Body: React.FunctionComponent<Props> = ({ children }) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={{ ...styles.container, rowGap: hive.spacing('x2') }}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flex: 1
    }
}))

export default Object.assign(AlertV2Body, {
    Title: AlertV2BodyTitle,
    Description: AlertV2BodyDescription
})
