import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Bulb: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fillRule="evenodd"
            d="M40.334.334a4 4 0 0 1 4 4v4a4 4 0 0 1-8 0v-4a4 4 0 0 1 4-4ZM12.049 12.05a4 4 0 0 1 5.657 0l2.829 2.828a4 4 0 0 1-5.657 5.657l-2.829-2.828a4 4 0 0 1 0-5.657Zm56.569 0a4 4 0 0 1 0 5.657l-2.828 2.828a4 4 0 1 1-5.657-5.657l2.828-2.828a4 4 0 0 1 5.657 0ZM29.02 29.02c-6.248 6.249-6.248 16.38 0 22.628l2.189 2.188a17.495 17.495 0 0 1 2.072 2.498h14.106c.607-.889 1.3-1.726 2.072-2.498l2.188-2.188c6.249-6.249 6.249-16.38 0-22.628-6.248-6.248-16.378-6.248-22.627 0ZM53.313 62.01a9.496 9.496 0 0 1 1.803-2.516l2.188-2.188c9.373-9.373 9.373-24.569 0-33.942-9.372-9.372-24.568-9.372-33.94 0-9.373 9.373-9.373 24.57 0 33.942l2.188 2.188a9.498 9.498 0 0 1 2.782 6.717v2.124c0 6.627 5.372 12 12 12 6.627 0 12-5.373 12-12V66.21a9.497 9.497 0 0 1 .98-4.201Zm-8.879 2.325h-8.201c.067.62.1 1.246.1 1.876v2.124a4 4 0 1 0 8 0V66.21c0-.63.035-1.256.102-1.876Zm-44.1-24a4 4 0 0 1 4-4h4a4 4 0 1 1 0 8h-4a4 4 0 0 1-4-4Zm68 0a4 4 0 0 1 4-4h4a4 4 0 1 1 0 8h-4a4 4 0 0 1-4-4Z"
        />
    </Icon>
)
