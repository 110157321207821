import React, { useState } from 'react'
import { View } from 'react-native'
import { JobDetails } from 'lib/models'
import { createStyles } from 'lib/styles'
import { jobHelpers, linkingHelpers } from 'lib/utils'
import { CallToActionButton, Grid, PhotoGallery, Touchable, Typography } from 'lib/components'
import { CustomerChargeStatus, CustomFonts, JobStatus, SupplyID, PriceSummaryItemNames } from 'lib/types'
import { useFormatPrice, useStyles, useTranslations } from 'lib/hooks'
import { PriceSummaryComponent } from 'features/bookings/components'
import { JobBillings } from './JobBillings'
import { NoBilling } from './NoBilling'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'
import { JobDetailsResponse, ReceiptSummary } from '../types'
import { AirconBilling } from './AirconBilling'
import { useJobPrice } from '../hooks'

type JobReceiptProps = {
    job: JobDetails,
    jobDetails?: JobDetailsResponse,
    isSessionJob?: boolean,
    showPayBySessionForJobDetail?: boolean,
    receiptSummary?: Array<ReceiptSummary>
}

export const JobReceipt: React.FunctionComponent<JobReceiptProps> = ({
    job,
    isSessionJob,
    showPayBySessionForJobDetail,
    jobDetails,
    receiptSummary
}) => {
    const consumerJobCostBreakdown = job?.consumerJobCostBreakdown || job?.estimateCostBreakdown

    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isAirconSubscriptionJob = isAirconSubscription(job)
    const formatPrice = useFormatPrice()
    const totalPrice = showPayBySessionForJobDetail ?
        formatPrice(consumerJobCostBreakdown?.totalPerSessionSubjectToDiscount as number) :
        formatPrice(job.bookingSummary?.total?.value || 0)
    const airconTotalPrice = formatPrice(job?.consumerJobCostBreakdown?.total || 0)
    const jobPrice = useJobPrice(job)
    const price = showPayBySessionForJobDetail ? formatPrice(consumerJobCostBreakdown?.totalPerSession || 0) : formatPrice(jobPrice)
    const isTaskAndErrands = job.supply.id === SupplyID.TasksErrands
    const receipts = job?.laundry_options?.receipt?.images.map(image => image?.url) || []
    const [isReceiptVisible, setReceiptVisibility] = useState(false)
    const formattedTip = formatPrice(job.tip || 0)
    const hasChargesAndIsAccepted = job.customer_charges?.some(item => item.auth_status === CustomerChargeStatus.Pending) && job.status === JobStatus.Accepted

    if(jobDetails && isAirconSubscriptionJob) {
        return (
            <>
                <AirconBilling job={jobDetails} />
                {hasChargesAndIsAccepted && !isTaskAndErrands && <JobBillings job={job} />}
            </>
        )
    }

    if (!isTaskAndErrands && hasChargesAndIsAccepted) {
        return (
            <JobBillings job={job} />
        )
    }

    if ((job?.bookingSummary?.total?.value === 0 && job?.customer_charges?.length === 0)|| (!hasChargesAndIsAccepted && isAirconSubscriptionJob)) {
        return (
            <NoBilling job={job} />
        )
    }

    if (job?.bookingSummary?.total?.value === 0) {
        return null
    }

    const ReceiptSummaryForPayBySession = () => {
        const receiptSummaryData = jobHelpers.getReceiptSummaryItemByName(receiptSummary as Array<ReceiptSummary>, PriceSummaryItemNames.ReceiptSummary)

        if (receiptSummaryData) {
            return (
                <View style={styles.receiptContainer}>
                    <View>
                        <Typography.Regular bold style={styles.receiptTitle}>
                            {receiptSummaryData.name}
                        </Typography.Regular>
                        <Typography.Label style={styles.perSession}>
                            {`(${receiptSummaryData?.label})`}
                        </Typography.Label>
                    </View>

                    <Typography.Regular bold style={styles.receiptTitle}>
                        {receiptSummaryData?.value}
                    </Typography.Regular>
                </View>
            )
        }

        return (
            <View style={styles.receiptContainer}>
                <View>
                    <Typography.Regular bold style={styles.receiptTitle}>
                        {T.common.receiptSummary}
                    </Typography.Regular>
                    {
                        !isSessionJob && (
                            <Typography.Label style={styles.perSession}>
                                {`(${T.common.perSession})`}
                            </Typography.Label>
                        )
                    }
                </View>

                <Typography.Regular bold style={styles.receiptTitle}>
                    {job.job_id}
                </Typography.Regular>
            </View>
        )
    }

    const ReceiptSummary = () =>
        showPayBySessionForJobDetail ? (
            <ReceiptSummaryForPayBySession/>
        ) : (
            <Typography.Regular bold>
                {`${T.common.receiptSummary}-${job.job_id}`}
            </Typography.Regular>
        )

    const JobPricingForPayBySession = () => {
        const cleaningPriceData = jobHelpers.getReceiptSummaryItemByName(receiptSummary as Array<ReceiptSummary>, PriceSummaryItemNames.CleaningPrice)

        if (cleaningPriceData) {
            return (
                <View style={styles.row}>
                    <Typography.Label numberOfLines={1}>
                        {cleaningPriceData.name}
                    </Typography.Label>
                    <View style={styles.priceColumn}>
                        <Typography.Label bold testID={T.accessibility.jobDetailsScreen.jobReceipt.cleaningPrice}>
                            {formatPrice(cleaningPriceData?.value ?? 0 as number)}
                        </Typography.Label>
                    </View>
                </View>
            )
        }

        return (
            <View style={styles.row}>
                <Typography.Label numberOfLines={1}>
                    {T.common.cleaningPrice}
                </Typography.Label>
                <View style={styles.priceColumn}>
                    <Typography.Label bold testID={T.accessibility.jobDetailsScreen.jobReceipt.cleaningPrice}>
                        {totalPrice}
                    </Typography.Label>
                </View>
            </View>
        )
    }

    const JobPricing = () =>
        showPayBySessionForJobDetail ? <JobPricingForPayBySession/> : (
            <View style={styles.pricingRow}>
                <Typography.Label numberOfLines={1}>
                    {`${job.supply.name} ${job.job_type}`}
                </Typography.Label>
                <View style={styles.priceColumn}>
                    <Typography.Label bold>
                        {price}
                    </Typography.Label>
                </View>
            </View>
        )

    const TotalPriceForPayBySession = () => {
        const cleaningPriceData = jobHelpers.getReceiptSummaryItemByName(receiptSummary as Array<ReceiptSummary>, PriceSummaryItemNames.TotalPrice)

        if (cleaningPriceData) {
            const shouldShowOriginalPrice =
                cleaningPriceData?.valueBeforeDiscount &&
                cleaningPriceData?.valueBeforeDiscount !== cleaningPriceData?.value

            return (
                <View style={styles.totalPrice}>
                    <Typography.Regular bold>
                        {cleaningPriceData.name}
                    </Typography.Regular>

                    {shouldShowOriginalPrice && (
                        <Typography.Error style={styles.lineThrough} testID={T.accessibility.jobDetailsScreen.jobReceipt.originalPrice}>
                            {formatPrice(cleaningPriceData.valueBeforeDiscount as number)}
                        </Typography.Error>
                    )}

                    <Typography.Regular bold testID={T.accessibility.jobDetailsScreen.jobReceipt.totalPrice}>
                        {formatPrice(cleaningPriceData?.value as number)}
                    </Typography.Regular>
                </View>
            )
        }

        return (
            <View style={styles.totalPrice}>
                <Typography.Regular bold>
                    {T.common.totalPrice}
                </Typography.Regular>

                {(consumerJobCostBreakdown?.totalPerSessionBeforeDiscount as number) > (consumerJobCostBreakdown?.totalPerSessionSubjectToDiscount as number) && (
                    <Typography.Error style={styles.lineThrough} testID={T.accessibility.jobDetailsScreen.jobReceipt.originalPrice}>
                        {formatPrice(consumerJobCostBreakdown?.totalPerSessionBeforeDiscount as number)}
                    </Typography.Error>
                )}

                <Typography.Regular bold testID={T.accessibility.jobDetailsScreen.jobReceipt.totalPrice}>
                    {price}
                </Typography.Regular>
            </View>
        )
    }

    const TotalPrice = () => showPayBySessionForJobDetail ? <TotalPriceForPayBySession /> : (
        <View style={styles.totalPrice}>
            <Typography.Regular bold>
                {T.common.totalPrice}
            </Typography.Regular>
            {
                showPayBySessionForJobDetail ? (
                    <>
                        {(consumerJobCostBreakdown?.totalPerSessionBeforeDiscount as number) > (consumerJobCostBreakdown?.totalPerSessionSubjectToDiscount as number) && (
                            <Typography.Error style={styles.lineThrough} testID={T.accessibility.jobDetailsScreen.jobReceipt.originalPrice}>
                                {formatPrice(consumerJobCostBreakdown?.totalPerSessionBeforeDiscount as number)}
                            </Typography.Error>
                        )}</>
                ) : !isAirconSubscriptionJob  ? (
                    <>
                        {job?.bookingSummary?.total?.valueBeforeDiscount > job?.bookingSummary?.total?.value && (
                            <Typography.Error style={styles.lineThrough}>
                                {formatPrice(job.bookingSummary.total.valueBeforeDiscount)}
                            </Typography.Error>
                        )}</>
                ) : <></>
            }

            {isAirconSubscriptionJob && job.estimateCostBreakdown && (
                <Typography.Error style={styles.lineThrough}>
                    {formatPrice(job.estimateCostBreakdown?.totalSubjectToDiscount || 0)}
                </Typography.Error>
            )}

            <Typography.Regular bold testID={T.accessibility.jobDetailsScreen.jobReceipt.totalPrice}>
                {showPayBySessionForJobDetail ? price : isAirconSubscriptionJob ? airconTotalPrice : totalPrice}
            </Typography.Regular>
        </View>
    )

    return (
        <React.Fragment>
            {isTaskAndErrands && job.customer_charges?.some(item => item.auth_status === CustomerChargeStatus.Pending) && job.status === JobStatus.Accepted && (
                <JobBillings job={job} />
            )}
            {isAirconSubscriptionJob && jobDetails && hasChargesAndIsAccepted && <JobBillings job={job} />}

            <View style={styles.content}>
                <ReceiptSummary/>

                <JobPricing/>

                <View style={styles.row}>
                    <PriceSummaryComponent
                        showPayBySessionForJobDetail={showPayBySessionForJobDetail}
                        priceSummary={job?.bookingSummary?.priceSummary}
                        promotion={job?.bookingSummary?.discount}
                        consumerJobCostBreakdown={consumerJobCostBreakdown}
                        receiptSummary={receiptSummary}
                    />
                </View>

                {Boolean(job.tip) && (
                    <View style={styles.row}>
                        <Typography.Label>
                            {T.common.tip}
                        </Typography.Label>
                        <Typography.Label bold>
                            {formattedTip}
                        </Typography.Label>
                    </View>
                )}

                {job.promotion?.warning_message && (
                    <View style={styles.termsAndConditions}>
                        <Typography.Label
                            bold
                            forceColor={theme.colors.flame}
                        >
                            {`${T.screens.referFriend.jobDetailsMessage} `}
                            <Touchable onPress={() => linkingHelpers.openReferralTermsAndConditions()}>
                                <Typography.Label
                                    bold
                                    style={styles.viewTerms}
                                    forceColor={theme.colors.flame}
                                >
                                    {T.screens.referFriend.footerPartTwo}
                                </Typography.Label>
                            </Touchable>
                        </Typography.Label>
                    </View>
                )}
                <TotalPrice />

            </View>

            {receipts.length > 0 && job.supply.id === SupplyID.Laundry && (
                <React.Fragment>
                    <Grid.Gap gapBottom={2}/>
                    <CallToActionButton
                        text={T.screens.laundryDetails.viewReceipt}
                        onPress={() => setReceiptVisibility(true)}
                    />
                    <PhotoGallery
                        photos={receipts}
                        isVisible={isReceiptVisible}
                        onClose={() => setReceiptVisibility(false)}
                    />
                </React.Fragment>
            )}

        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    totalPrice: {
        borderTopWidth: 1,
        alignItems: 'center',
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        marginTop: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    row: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(2),
        justifyContent: 'space-between'
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(2),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    lineThrough: {
        textDecorationLine: 'line-through'
    },
    priceColumn: {
        marginLeft: theme.utils.gap(1) / 2
    },
    termsAndConditions: {
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: theme.utils.gap(1)
    },
    viewTerms: {
        textDecorationLine: 'underline'
    },
    receiptContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingBottom: theme.utils.gap(1.25)
    },
    perSession: {
        color: theme.colors.mouse,
        fontSize: 13,
        fontFamily: CustomFonts.Poppins500,
        marginTop: theme.utils.gap(0.3)
    },
    receiptTitle: {
        fontFamily: CustomFonts.Roboto500
    },
    pricingRow: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(2),
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingBottom: theme.utils.gap(2)
    }
}))
