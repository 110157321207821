import React from 'react'
import { usePrice, useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'

type ExtraPriceLabelProps = {
    price: number
}

export const ExtraPriceLabel: React.FunctionComponent<ExtraPriceLabelProps> = ({ price }) => {
    const { styles, theme } = useStyles(stylesheet)
    const formattedPrice = usePrice(price)

    return (
        <Typography.Label
            style={styles.label}
            forceColor={theme.colors.darkGrey}
        >
            {`+${formattedPrice}`}
        </Typography.Label>
    )
}

const stylesheet = createStyles(theme => ({
    label: {
        marginLeft: theme.utils.gap(1)
    }
}))
