import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { AlertV2Icon } from './sub-components/Icon.native'
import AlertV2Body from './sub-components/Body.native'

type AlertV2Props = {
    children: ReactNode
}

const AlertV2: React.FunctionComponent<AlertV2Props> = ({ children }) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={{
            ...styles.container,
            borderRadius: hive.borderRadius('medium'),
            backgroundColor: hive.color('fill/warning/secondary'),
            columnGap: hive.spacing('x2'),
            padding: hive.spacing('x4')
        }}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        position: 'relative',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    }
}))

export default Object.assign(AlertV2, {
    Icon: AlertV2Icon,
    Body: AlertV2Body
})
