import React, { Fragment } from 'react'
import { Image, View } from 'react-native'
import { Icon } from '@propertyguru/hive-icon'
import {
    CheckRadioCircleCheckboxCheckCheckmarkConfirmFill,
    ChevronRightOutline,
    ChevronRightSmallOutline,
    CreditCard1CardPaymentOutline,
    Wallet2Outline
} from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { Touchable } from 'lib/components'
import { PaymentProvider, PaymentType } from 'lib/models'
import { useStyles, useTranslations } from 'lib/hooks'
import { stylesheet } from './styles'

type Props = {
    balance: string,
    paymentMethod: string | undefined,
    paymentType: PaymentType | undefined,
    selectedPaymentMethod: PaymentProvider | undefined,
    handlePaymentMethodPress: VoidFunction,
}

export const PaymentMethod: React.FunctionComponent<Props> = ({
    balance,
    paymentType,
    paymentMethod,
    selectedPaymentMethod,
    handlePaymentMethodPress
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return paymentMethod ? (
        <Fragment>
            <View style={styles.paymentMethod}>
                {paymentType === PaymentType.Credits ? (
                    <>
                        <View style={styles.sendPayIcon}>
                            <Icon icon={Wallet2Outline} color="icon/active/primary" width={24} height={24} />
                        </View>
                        <View style={styles.paymentMethodText}>
                            <Text typography="label/s" color="text/active/primary">
                                {T.screens.sendPay.pageTitle}
                            </Text>
                            <Text typography="body/xs" color="text/active/primary" style={styles.paymentMethodValue}>
                                {balance}
                            </Text>
                        </View>
                        <View style={styles.sendPayIcon}>
                            <Icon icon={CheckRadioCircleCheckboxCheckCheckmarkConfirmFill} color="icon/success/primary" width={24} height={24} />
                        </View>
                    </>
                ) : (
                    <>
                        <Image
                            style={styles.creditCardImage}
                            source={{ uri: selectedPaymentMethod?.imageUrl }}
                        />
                        <Text typography="label/s" color="text/active/primary" style={styles.paymentMethodText}>
                            {paymentMethod}
                        </Text>
                        <Icon icon={CheckRadioCircleCheckboxCheckCheckmarkConfirmFill} color="icon/success/primary" width={24} height={24} />
                    </>
                )}
            </View>
            <Touchable style={styles.buttonText} onPress={handlePaymentMethodPress}>
                <Text typography="caption/xs" color="text/active/primary" underline>
                    {T.screens.cleaningSummaryNative.paymentMethod.change}
                </Text>
                <Icon icon={ChevronRightOutline} color="icon/active/primary" width={16} height={16} />
            </Touchable>
        </Fragment>
    ) : (
        <Touchable style={styles.paymentMethod} onPress={handlePaymentMethodPress}>
            <Icon icon={CreditCard1CardPaymentOutline} color="icon/active/primary" width={24} height={24} />
            <Text typography="label/s" color="text/active/primary" style={styles.paymentMethodText}>
                {T.screens.cleaningSummaryNative.paymentMethod.choose}
            </Text>
            <Icon icon={ChevronRightSmallOutline} color="icon/active/primary" width={24} height={24} />
        </Touchable>
    )
}
