import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Photo: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M8.163 6.53c-.901 0-1.632.732-1.632 1.633v63.674c0 .901.73 1.632 1.632 1.632h63.674c.901 0 1.632-.73 1.632-1.632V8.163c0-.901-.73-1.632-1.632-1.632H8.163ZM0 8.164A8.163 8.163 0 0 1 8.163 0h63.674A8.163 8.163 0 0 1 80 8.163v63.674A8.163 8.163 0 0 1 71.837 80H8.163A8.163 8.163 0 0 1 0 71.837V8.163Z" />
        <Path d="M55.316 33.325a1.7 1.7 0 0 0-.736.167c-.228.11-.427.27-.58.466L43.14 47.874a3.328 3.328 0 0 1-2.4 1.258 3.368 3.368 0 0 1-2.56-.919l-3.38-3.248a1.653 1.653 0 0 0-.59-.364 1.706 1.706 0 0 0-1.363.094 1.629 1.629 0 0 0-.528.44l-11.395 14.6c-1.09 1.397-3.153 1.68-4.607.632-1.454-1.048-1.75-3.03-.659-4.428l11.394-14.598a8.15 8.15 0 0 1 2.643-2.2 8.535 8.535 0 0 1 6.817-.467 8.274 8.274 0 0 1 2.942 1.818l.696.67 8.584-11a8.16 8.16 0 0 1 2.903-2.327 8.501 8.501 0 0 1 3.68-.835c1.277 0 2.537.286 3.679.835a8.16 8.16 0 0 1 2.903 2.328l8.764 11.23 8.632 10.534c1.124 1.372.878 3.36-.55 4.441s-3.498.845-4.622-.528l-8.656-10.563-.047-.06-8.787-11.259a1.632 1.632 0 0 0-.58-.465 1.7 1.7 0 0 0-.737-.168ZM20.5 16.429a4.071 4.071 0 1 0 0 8.142 4.071 4.071 0 0 0 0-8.142ZM11 20.5a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0Z" />
        <Path d="M0 57c0-2.21 1.462-4 3.265-4h73.47C78.538 53 80 54.79 80 57s-1.462 4-3.265 4H3.265C1.462 61 0 59.21 0 57Z" />
    </Icon>
)
