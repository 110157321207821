import { useTranslations } from 'lib/hooks'
import { ErrorResponse } from '../types/api'

export const useNativeGetErrorMessage = () => {
    const T = useTranslations()

    return {
        getErrorMessage: (error?: ErrorResponse) => error?.errors?.error_msg ?? T.common.errorMessage,
        getErrorTitle: (error?: ErrorResponse) => error?.errors?.error_title ?? T.errorBoundary.title
    }
}
