import React, { useEffect, useState } from 'react'
import { View, NativeSyntheticEvent, TextInputChangeEventData } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import LottieView from 'lottie-react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Text } from '@propertyguru/hive-text'
import { TextInput } from '@propertyguru/hive-text-input'
import { Button } from '@propertyguru/hive-button'
import { Surface } from '@propertyguru/hive-surface'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { AuthorizedNativeLayout } from 'features/native/common/components'
import { LottieFiles } from 'assets/native'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { useNativeUserAtom } from 'lib/native/atoms/user'
import { isValidUserName } from 'lib/utils/regexes'
import { AuthSegmentMethod } from 'lib/analytics'

export const GetStartedScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const [user] = useNativeUserAtom()
    const isAppleSignUp = user?.consumer?.signUpAuthProvider === AuthSegmentMethod.Apple.toLowerCase()

    const [name, setName] = useState<string>('')
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [isValidName, setIsValidName] = React.useState<boolean>(false)
    const { styles, theme } = useStyles(stylesheet)

    useEffect(() => {
        setName(user.handle || '')
    }, [user])

    const checkErrorMessage = (inputText: string) => {
        if (inputText.trim().length < 1) {
            setErrorMessage(T.screens.getStarted.errorMessageEmptyName)
            setIsValidName(false)

            return
        }

        if (!isValidUserName(inputText)) {
            setErrorMessage(T.screens.getStarted.errorMessageInvalidName)
            setIsValidName(false)

            return
        }

        setErrorMessage('')
        setIsValidName(true)
    }

    const onNameBlur = () => {
        checkErrorMessage(name)
    }

    const onChangeName = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        const inputText = e.nativeEvent.text

        checkErrorMessage(inputText)

        setName(inputText)
    }

    const onCheckName = () => {
        if (isValidUserName(name) || isAppleSignUp) {
            navigation?.navigate(ScreenNamesNative.MarketingConsentNative, { name: name.trim() || user?.handle })

            return
        }

        checkErrorMessage(name)
    }

    return (
        <View style={styles.wrapper}>
            <AuthorizedNativeLayout isBackButtonVisible={false}>
                <View style={isAppleSignUp ? styles.withoutTextContainer : styles.container}>
                    <View>
                        <Text typography="title/s">{T.screens.getStarted.title}</Text>
                        <Text typography="label/l" style={styles.description}>{T.screens.getStarted.subtitle}</Text>
                    </View>
                    {!isAppleSignUp && (
                        <View style={styles.namesWrapper}>
                            <Text typography="label/s" style={styles.description}>{T.screens.getStarted.nameInputLabel}</Text>
                            <TextInput
                                value={name}
                                style={styles.textInput}
                                onChange={onChangeName}
                                error={Boolean(errorMessage)}
                                onBlur={onNameBlur}
                                placeholder={T.screens.getStarted.inputPlaceholder}
                                accessibilityLabel={T.accessibility.native.onboarding.nameBox}
                            />
                            {!isValidName && <Text style={styles.errorContainer} accessibilityLabel={T.accessibility.native.onboarding.nameBoxError} typography="caption/xs" color="text/error/primary">{errorMessage}</Text>}
                        </View>
                    )}
                </View>
                <View style={styles.imageContainer}>
                    <LottieView
                        source={LottieFiles.GetStarted}
                        style={styles.image}
                        resizeMode="cover"
                        autoPlay
                        loop
                    />
                </View>
                <Surface style={{
                    ...styles.bottomCTAWrapper,
                    paddingBottom: insets.bottom + theme.utils.gap(3)
                }}>
                    <Button type="primary" accessibilityLabel={T.accessibility.native.onboarding.nextButton} onPress={onCheckName} text={T.common.next} />
                </Surface>
            </AuthorizedNativeLayout>
        </View>

    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        backgroundColor: theme.colors.white,
        flex: 1
    },
    container: {
        paddingHorizontal: {
            xs: theme.utils.gap(2)
        },
        paddingTop: theme.utils.gap(3),
        marginTop: theme.utils.gap(5),
        backgroundColor: theme.colors.transparent,
        paddingBottom: theme.utils.gap(3)
    },
    withoutTextContainer: {
        paddingHorizontal: {
            xs: theme.utils.gap(2)
        },
        paddingTop: theme.utils.gap(20)
    },
    subTitle: {
        marginTop: theme.utils.gap(1.5)
    },
    description: {
        marginTop: theme.utils.gap(2)
    },
    bottomCTAWrapper: {
        padding: {
            xs: theme.utils.gap(2)
        },
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        paddingTop: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        width: '100%'
    },
    textInput: {
        marginTop: theme.utils.gap(1)
    },
    namesWrapper: {
        marginTop: theme.utils.gap(1)
    },
    imageContainer: {
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
        width: '100%',
        marginBottom: -24
    },
    image: {
        width: '100%',
        height: '100%',
        minHeight: 445,
        position: 'absolute',
        bottom: -50,
        zIndex: -1
    },
    errorContainer: {
        marginTop: theme.utils.gap(0.5)
    }
}))
