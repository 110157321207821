import { ButtonType } from '@propertyguru/hive-button/lib/typescript/src/types'
import { AnyDate } from 'lib/utils/date'
import { CommonCardAction } from 'features/bookings'

export interface CardsData {
    status: boolean;
    bookings: Array<BookingsData>
    ctas: Array<CTAData>
    upcomingSessionsNotifications: [];
}

// BOOKINGS TYPE
export interface BookingAction {
    bookingStatus: number;
    title: string;
    image: string;
    action: {
        popup?: string;
        redirect?: string;
    };
    enabled: boolean;
}

export interface BookingsData {
    _id: string;
    num_hours: number;
    date_time: string;
    supply: {
        _id: string;
        id: number;
    };
    status: number;
    supplier: {
        _id: string;
        handle: string;
    };
    actions: Array<BookingAction>;
}

export enum BookingStatus {
    Open = 0,
    Accepted = 1,
    Cancelled = 2,
    Completed = 3,
    Rated = 4,
    Started = 7
}

// SERVICE RECOMMENDATION TYPE
export type ServiceRecommendation = {
    title: string,
    label: string,
    labelIcon: string,
    image: string,
    action: CommonCardAction
}

// CTAs TYPE
type Action = {
    save: string;
    data: {
        clearAfter: string;
    };
}

export type TitleElement = {
    text?: string;
    textBold?: string;
    textAction?: string;
    action?: Action;
}

export type ActionDetail = {
    title: string;
    actionType: ButtonType;
    action: {
        url: string;
    };
}

export type CTAData = {
    id: string;
    enabled: boolean;
    image: string;
    title: Array<TitleElement>;
    actions: Array<ActionDetail>;
}

// RECURRING BOOKING DATA TYPE
type Supply = {
    _id: string;
    id: number;
}

type Supplier = {
    _id: string;
    handle: string;
}

export type RecurringData = {
    _id: string;
    num_hours: number;
    date_time: string;
    supply: Supply;
    status: number;
    supplier: Supplier;
}

export enum ActionTitleParam {
    TextBold = 'textBold',
    Text = 'text',
    TextAction = 'textAction'
}

// DYNAMIC BOOKING CARDS TYPE
export type DynamicBookingCards = {
    _id: string;
    num_hours: number;
    date_time: AnyDate;
    supply: {
        _id: string;
        id: number;
    };
    status: BookingStatus;
    supplier: {
        _id: string;
        handle: string;
    };
    bookingStatus: number;
    labelIcon?: string;
    label?: string;
    title: string;
    titleBadge?: string;
    image: string;
    actions: Array<{
        title: string;
        popup?: string;
        redirect?: string;
        image?: string;
    }>;
    enabled: boolean;
    job_type?: string;
    bookingMarkedAsStarted?: boolean;
}
