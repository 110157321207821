import { Platform } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { createStyles } from 'lib/styles'

const getBoxShadowStyle = () => {
    if (Platform.OS === 'ios') {
        return {
            shadowOffset: { width: 0, height: 4 },
            shadowOpacity: 0.12,
            shadowRadius: 4
        }
    }

    if (Platform.OS === 'android') {
        return {
            elevation: 4
        }
    }

    return {}
}

export const stylesheet = createStyles(theme => {
    const hive = useHive()

    return ({
        container: {
            flex: 1,
            alignItems: 'flex-start',
            borderWidth: 1,
            shadowColor: theme.colors.shadowSoft,
            ...getBoxShadowStyle()
        },
        titleWrapper: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        title: {
            fontWeight: '600',
            maxWidth: '90%'
        },
        condition: {
            fontWeight: '400'
        },
        footerWrapper: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        promoDetails: {
            flexDirection: 'row',
            alignItems: 'center'
        },
        preconditionWrapper: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            borderTopWidth: 1,
            paddingVertical: hive.spacing('x2'),
            paddingHorizontal: hive.spacing('x4'),
            borderColor: hive.color('fill/neutral/tertiary'),
            columnGap: hive.spacing('x2')
        }
    })
})
