import React, { useCallback, useEffect, useState } from 'react'
import { Dimensions, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useFocusEffect } from '@react-navigation/native'
import { Surface } from '@propertyguru/hive-surface'
import { useHive } from '@propertyguru/hive'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { useNativeUserAtom } from 'lib/native/atoms/user'
import { BookingCard } from './BookingCard.native'
import { ServiceRecommendationCard, ServiceRecommendationCardProps } from './ServiceRecommendationCard.native'
import { RecurringCard } from './RecurringCard.native'
import { ActionCard } from './ActionCard.native'
import { useGetCardsData, useGetServiceRecommendations } from '../actions'
import { CTAData, DynamicBookingCards, RecurringData } from '../types'
import { SegmentNative } from 'lib/analytics'

export const ServiceRecommendations: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const hive = useHive()
    const { isLoading, mutate: fetchCardsData } = useGetCardsData()
    const { mutate: fetchRecommendations } = useGetServiceRecommendations()
    const [user] = useNativeUserAtom()
    const [dynamicCardsData, setDynamicCardsData] = useState<Array<DynamicBookingCards>>([])
    const [serviceRecommendations, setServiceRecommendations] = useState<Array<ServiceRecommendationCardProps>>([])
    const shouldShowLoadingCard = isLoading && dynamicCardsData?.length === 0 && serviceRecommendations?.length === 0
    const [recurringCardInfo, setRecurringCardInfo] = useState<Array<RecurringData>>()
    const [actionCardData, setActionCardData] = useState<Array<CTAData>>()
    const cardsDataPayload = { token: user?._id || '' }

    const getPageData = () => {
        fetchCardsData(cardsDataPayload, {
            onSuccess: response => {
                setDynamicCardsData(response.data.bookings)
                setRecurringCardInfo(response.data.upcomingSessionsNotifications)
                setActionCardData(response.data.ctas)
            },
            onError: error => {
                console.log('error getPageData', Object.keys(error))
            }
        })
        fetchRecommendations(
            {},
            {
                onSuccess: response => {
                    setServiceRecommendations(response)
                },
                onError: error => console.log('error fetchRecommendations', error)
            }
        )
    }

    // This useEffect calls the api on initial mount
    useEffect(() => {
        getPageData()
    }, [])

    // This useFocusEffect calls the api after initial mount and when the component is focused
    useFocusEffect(
        useCallback(() => {
            getPageData()
        }, [])
    )

    const getServiceAction = (service: ServiceRecommendationCardProps) => ({
        ...service.action, tracking: {
            elementType: SegmentNative.ElementClickedElementType.RecommendationCardClicked, information: {
                type: 'serviceType', value: service.action.name || service.label
            }
        }
    })

    return (
        <Surface
            style={styles.container}
            testID={T.accessibility.dashboard.serviceRecommendation.section}
            accessible
            accessibilityLabel={T.accessibility.dashboard.serviceRecommendation.section}
        >
            <ScrollView
                horizontal
                nestedScrollEnabled
                showsHorizontalScrollIndicator={false}
            >
                {dynamicCardsData?.map((booking, index) => (
                    <View
                        key={booking?._id}
                        style={{
                            ...styles.dynamicCardWrapper,
                            marginLeft: index === 0 ? hive.spacing('x4') : hive.spacing('x3')
                        }}
                    >
                        <BookingCard data={booking} />
                    </View>
                ))}
                {serviceRecommendations?.map((service, index) => (
                    <View
                        style={{
                            ...styles.serviceCardWrapper,
                            marginLeft: index === 0 ? hive.spacing('x4') : undefined,
                            marginRight: hive.spacing('x5')
                        }}
                        key={index + 1}
                    >
                        <ServiceRecommendationCard {...service} action={getServiceAction(service)} />
                    </View>
                ))}
                {shouldShowLoadingCard && (
                    <View style={styles.loadingCardWrapper}>
                        <BookingCard loading />
                    </View>
                )}
            </ScrollView>
            <View style={styles.ctaCardWrapper}>
                {recurringCardInfo?.map((booking, index) => (
                    <RecurringCard key={index + booking._id} booking={booking} />
                ))}
                {actionCardData?.map((actionCard, index) => (
                    <ActionCard key={index + actionCard.id} actionData={actionCard} refreshDynamicCardData={getPageData} />
                ))}
            </View>
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        justifyContent: 'center'
    },
    dynamicCardWrapper: {
        paddingTop: theme.utils.hive.spacing('x4'),
        paddingBottom: theme.utils.hive.spacing('x3'),
        marginRight: theme.utils.hive.spacing('x1'),
        width: 328
    },
    serviceCardWrapper: {
        paddingTop: theme.utils.hive.spacing('x4'),
        paddingBottom: theme.utils.hive.spacing('x3')
    },
    loadingCardWrapper: {
        paddingTop: theme.utils.hive.spacing('x4'),
        paddingBottom: theme.utils.hive.spacing('x3'),
        width: Dimensions.get('window').width - theme.utils.hive.spacing('x8'),
        marginHorizontal: theme.utils.hive.spacing('x4')
    },
    ctaCardWrapper: {
        paddingHorizontal: theme.utils.hive.spacing('x4')
    }
}))
