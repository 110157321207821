import React from 'react'
import { View } from 'react-native'
import { Address } from 'lib/models'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { Nullable } from 'lib/types'

interface ServiceLocationProps {
    address: Nullable<Address>,
    label: string
}

export const ServiceLocation: React.FunctionComponent<ServiceLocationProps> = ({
    label,
    address
}) => {
    const { styles, theme } = useStyles(stylesheet)

    if (!address) {
        return null
    }

    return (
        <View>
            <Typography.SmallSubheading>
                {label}
            </Typography.SmallSubheading>
            <View style={styles.container}>
                <View style={styles.flex}>
                    <View style={styles.name}>
                        <Typography.SmallSubheading numberOfLines={1}>
                            {address.address}
                        </Typography.SmallSubheading>
                    </View>
                    <View>
                        <Typography.Label
                            numberOfLines={1}
                            forceColor={theme.colors.darkGrey}
                        >
                            {`${address.postcode}, ${address.district}, ${address.unit}, ${address.address}`}
                        </Typography.Label>
                    </View>
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderColor: theme.colors.fog,
        borderRadius: 8,
        marginTop: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(2),
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        },
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    flex: {
        flex: 1
    },
    name: {
        marginBottom: theme.utils.gap(1)
    }
}))
