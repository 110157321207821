import React, { useState, useEffect } from 'react'
import { DimensionValue, View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { isNative } from 'lib/common'
import { Grid, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useStyles } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { PackageDetailsModalContent, PestControlPackage } from '../../types'
import { PestControlPackageTile } from './PestControlPackageTile'

interface PestControlPackagesProps extends Field<Array<PestControlPackage>> {
    disabled?: boolean,
    propertyType: number,
    onDetailsPress(modalContent: PackageDetailsModalContent): void
}

export const PestControlPackages: React.FunctionComponent<PestControlPackagesProps> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disabled,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propertyType,
    onDetailsPress,
    ...formProps
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const [currentPackages, setCurrentPackages] = useState<Array<PestControlPackage>>(formProps.value)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const columnWidth = isNative || isMobile
        ? '100%'
        : 'calc(50% - 10px)' as DimensionValue

    if (!currentPackages) {
        return null
    }

    useEffect(() => {
        setCurrentPackages(formProps.value)
    }, [formProps.value])

    const onDetailsPressed = (packageName, item) => {
        onDetailsPress({
            title: `${packageName} - ${item.details.title}`,
            description: item.details.description,
            includedTitle: item.details.includedTitle,
            message: item.details.message
        })
    }

    const onPackageItemChange = updatedPackageItem => {
        const updatedPackages = currentPackages.map(p => ({
            ...p,
            items: p.items?.map(item => {
                item.isSelected = false

                if (item.id === updatedPackageItem.id) {
                    return updatedPackageItem
                }

                return item
            })
        }))
        formProps.onChangeValue(updatedPackages)
    }

    return (
        <>
            {currentPackages.map(currentPackage => (
                <View key={currentPackage.package_name}>
                    <Typography.Regular
                        bold
                        forceColor={theme.colors.orange}
                    >
                        {currentPackage.package_name}
                    </Typography.Regular>
                    <Grid.Gap gapBottom={1} />
                    <Typography.Label>
                        {currentPackage.description}
                    </Typography.Label>
                    <Grid.Gap gapBottom={3} />
                    <View style={styles.row}>
                        {currentPackage.items?.map(item => (
                            <View style={{ width: columnWidth }} key={item.id}>
                                <PestControlPackageTile
                                    onChangeValue={onPackageItemChange}
                                    packageItem={item}
                                    packageName={currentPackage.package_name}
                                    onDetailsPress={onDetailsPressed}
                                />
                            </View>
                        ))}
                    </View>
                </View>
            ))}
        </>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        flexWrap: 'wrap'
    },
    tileMarginRight: {
        marginRight: theme.utils.gap(2)
    }
}))
