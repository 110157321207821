import React, { useState } from 'react'
import { DimensionValue, Image, View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Nullable, SelectInputOption, SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { useAddressAtom, useFilesAtom, useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { Segment } from 'lib/analytics'
import { PaymentType } from 'lib/models'
import { NewAddressShape } from 'features/user'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, Address, Button, FormComponents, MediaQuery, PhotoUpload, PolicyLinks, Typography, UploadedPhoto } from 'lib/components'
import { GetPromotionResponse, JobQuotationResponse } from '../types'
import { ServiceLayout } from './ServiceLayout'
import { PaymentMethodTile } from './PaymentMethodTile'
import { BookingCancellationFees } from './BookingCancellationFees'
import { AirConditionFormShape, ServiceAddonsFields, ServiceAddonsFormShape } from '../forms'
import { useAirConditionBreadCrumbs, usePetsOptions } from '../hooks'
import { PromotionCodeInput } from './PromotionCodeInput'
import { Rated } from './Rated'
import { MobileSummary } from './MobileSummary'
import { SummaryNewAddressForm } from './SummaryNewAddressForm'
import { AirConditionSummaryColumn } from './AirConditionSummaryColumn'
import { VisitCharges } from './VisitCharges'
import { ServiceDescription } from './ServiceDescription'
import { AirConditionHelpModalContent } from './AirConditionHelpModalContent'
import { BookingAuth } from './unauthorized'
import { TotalPriceComponent } from './TotalPriceComponent'
import { PriceSummaryComponent } from './PriceSummaryComponent'
import { getServiceStaticConfig } from '../utils'
import { SubTotalComponent } from './SubTotalComponent'
import { Images } from 'assets'

type AirConditionSummaryViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    form: Record<keyof ServiceAddonsFormShape, Field<boolean & string>>
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    formShape: AirConditionFormShape,
    isLoading: boolean,
    isUploadingPhotos: boolean,
    totalPrice: number,
    sessionsGenerated?: number,
    bookingTime?: string,
    jobQuotation?: JobQuotationResponse,
    onUploadSuccess: (data: Array<UploadedPhoto>) => void,
    submit: VoidFunction,
    setPaymentMethod: (value: PaymentType) => void,
    promotionPackage: Nullable<GetPromotionResponse>
    originalPrice?: Nullable<number>,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction,
    onPhotoRemove: (uuid: string) => void,
    setIsUploadingPhotos: (value: boolean) => void,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const AirConditionSummaryView: React.FunctionComponent<AirConditionSummaryViewProps> = ({
    form,
    disabled,
    formShape,
    totalPrice,
    originalPrice,
    requestPromotion,
    setPaymentView,
    onPhotoRemove,
    onUploadSuccess,
    promotionPackage,
    isLoading,
    addressForm,
    submit,
    setPaymentMethod,
    isUploadingPhotos,
    setIsUploadingPhotos,
    selectedPaymentMethod,
    fetchPostalCodes,
    onRemovePromoCode,
    jobQuotation,
    sessionsGenerated,
    bookingTime
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [address] = useAddressAtom()
    const { styles } = useStyles(stylesheet)
    const petsOptions = usePetsOptions()
    const [files] = useFilesAtom()
    const breadcrumbs = useAirConditionBreadCrumbs()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const [isAuthorized] = useIsAuthorizedAtom()
    const [authenticationView, setAuthenticationView] = useState(false)
    const config = getServiceStaticConfig(SupplyID.ACService)
    const { pricing: { visitCharges: { details: visitChargesDetails, title, subtitle }, acOptions } } = config
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(50% - 10px)' as DimensionValue
    const confirmDisabled = isAuthorized
        ? disabled
        : false
    const handleConfirm = () => {
        if (!isAuthorized) {
            return setAuthenticationView(true)
        }

        submit()
    }

    const onSelectAddons = (addonId: number) => {
        if (addonId === 10) {
            (form.acDuctMount.onChangeValue as (value: boolean) => void)(!form.acDuctMount.value)
        }

        if (addonId === 20) {
            (form.acCeiling.onChangeValue as (value: boolean) => void)(!form.acCeiling.value)
        }
    }

    const isAddonChecked = id => {
        if(id === 10) {
            return Boolean(form.acDuctMount.value)
        }

        if(id === 20) {
            return Boolean(form.acCeiling.value)
        }

        return false
    }

    const getAddonsImage = (id: number) => {
        if(id === 10) {
            return Images.AcDuct
        }

        if(id === 20) {
            return Images.AcLadder
        }

        return Images.AcDuct
    }

    return (
        <ServiceLayout
            onInfoPress={() => setHelpModalOpen(true)}
            title={T.screens.airCondition.title}
            withBackground
            authenticationView={authenticationView}
            onBackFromDetails={() => {
                setAuthenticationView(false)
            }}
            breadcrumbs={breadcrumbs}
            contentColumn={authenticationView
                ? (
                    <BookingAuth
                        supplyId={SupplyID.ACService}
                        onUploadSuccess={onUploadSuccess}
                        notEnoughCredits={notEnoughCredits}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                ) : (
                    <View>
                        {isNative && (
                            <ServiceDescription
                                isHelpModalOpen={isHelpModalOpen}
                                setHelpModalOpen={setHelpModalOpen}
                                title={T.screens.airCondition.title}
                                modalContent={(
                                    <AirConditionHelpModalContent />
                                )}
                                description={T.screens.airCondition.serviceDescription}
                            />
                        )}
                        <View style={styles.wrapper}>
                            <FormComponents.FormRow
                                isOptional
                                title={T.screens.deepCleaningSummary.sections.additionalInformation}
                            >
                                <View
                                    style={{
                                        ...styles.selectWrapper,
                                        width: columnWidth
                                    }}
                                >
                                    <Adapter.SelectInputColumn
                                        {...form.pets}
                                        disabled={isLoading}
                                        options={petsOptions}
                                        onOptionSelect={({ label }) => {
                                            Segment.bookingAdditionalInformationAdded({
                                                information: {
                                                    type: ServiceAddonsFields.Pets,
                                                    value: label
                                                }
                                            })
                                        }}
                                    />
                                </View>
                                <Adapter.TextInput
                                    {...form.comment}
                                    disabled={isLoading}
                                    inputProps={{
                                        multiline: true
                                    }}
                                    onBlur={() => {
                                        if (form.comment.value) {
                                            Segment.bookingAdditionalInformationAdded({
                                                information: {
                                                    type: ServiceAddonsFields.Comment,
                                                    value: form.comment.value
                                                }
                                            })
                                        }
                                    }}
                                />
                                <Typography.Label style={styles.addonsTitle} bold>
                                    {acOptions.label}
                                </Typography.Label>
                                <View style={styles.acOptionsWrapper}>
                                    {acOptions.items.map((item, index) => (
                                        <View key={index} style={styles.acOption}>
                                            <View>
                                                <Image
                                                    source={getAddonsImage(item.id)}
                                                    resizeMode="cover"
                                                    style={styles.acOptionImage}
                                                />
                                            </View>
                                            <View style={styles.acDescription}>
                                                <View style={styles.cardHeader}>
                                                    <Typography.Regular style={styles.cardHeaderText} bold>
                                                        {item.title}
                                                    </Typography.Regular>
                                                    <View style={styles.cardHeaderSelect}>
                                                        <FormComponents.Checkbox
                                                            isSelected={isAddonChecked(item.id)}
                                                            onChange={() => {
                                                                onSelectAddons(item.id)
                                                            }}
                                                            renderComponent={() => <></>}
                                                        />
                                                    </View>
                                                </View>
                                                <View style={styles.cardDescription}>
                                                    <Typography.Regular style={styles.cardDescriptionText} bold>
                                                        {item.subtitle}
                                                    </Typography.Regular>
                                                </View>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                                <PhotoUpload
                                    onPhotoRemove={onPhotoRemove}
                                    isLoading={isUploadingPhotos}
                                    onSuccess={onUploadSuccess}
                                    onError={() => setIsUploadingPhotos(false)}
                                    onUploadStarts={() => setIsUploadingPhotos(true)}
                                    supplyId={SupplyID.ACService}
                                />
                                <View>
                                    {!address.postcode && isAuthorized && (
                                        <SummaryNewAddressForm
                                            form={addressForm}
                                            isLoading={isLoading}
                                            fetchPostalCodes={fetchPostalCodes}
                                        />
                                    )}
                                </View>
                            </FormComponents.FormRow>
                        </View>
                        {isMobile && (
                            <PolicyLinks />
                        )}
                    </View>
                )
            }
            summaryColumn={(
                <MediaQuery.Visible from={Breakpoint.LG}>
                    {!(isAuthorized && authenticationView) && (
                        <View>
                            <Address disabled={isLoading} />
                        </View>
                    )}
                    <AirConditionSummaryColumn
                        originalPrice={originalPrice}
                        totalPrice={totalPrice}
                        title={T.common.bookingSummary}
                        priceMessage={T.screens.airCondition.estimatedMessage}
                        renderPriceComponent={() => (
                            <TotalPriceComponent
                                totalPrice={jobQuotation?.estimation?.value || 0}
                                customText={T.common.estimatedPrice}
                            />
                        )}
                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                            <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                        ) : undefined}
                        renderSubTotalComponent={jobQuotation?.subTotal ? () => (
                            <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                        ) : undefined}
                        promotionPackage={promotionPackage}
                        form={formShape}
                        couponText={promotionPackage
                            ? promotionPackage.promotion.message_to_client
                            : undefined
                        }
                        jobStartingTime={bookingTime}
                        sessionsGenerated={sessionsGenerated}
                        renderExtraContent={() => (
                            <View>
                                <VisitCharges
                                    labelText={title}
                                    chargesMessage={subtitle}
                                    modalTitle={visitChargesDetails.title}
                                    messageText={visitChargesDetails.description}
                                />
                                <PromotionCodeInput
                                    showBillingMessage
                                    promoCode={form.promoCode}
                                    isLoading={isLoading}
                                    onRemovePromoCode={onRemovePromoCode}
                                    promotionPackage={promotionPackage}
                                    requestPromotion={requestPromotion}
                                />
                            </View>
                        )}
                        jobQuotation={jobQuotation}
                    />
                    {!authenticationView && (
                        <PaymentMethodTile
                            notEnoughCredits={notEnoughCredits}
                            onPress={() => setPaymentView(true)}
                            selectedPaymentMethod={selectedPaymentMethod}
                        />
                    )}
                    <PolicyLinks />
                    {(!authenticationView || isAuthorized) && (
                        <Button
                            disabled={notEnoughCredits || confirmDisabled}
                            isLoading={isLoading || isUploadingPhotos || (Boolean(files.length) && authenticationView)}
                            onPress={handleConfirm}
                            text={T.common.confirm}
                        />
                    )}
                    <BookingCancellationFees />
                </MediaQuery.Visible>
            )}
            footer={(
                <MediaQuery.Hidden from={Breakpoint.LG}>
                    <MobileSummary
                        authenticationView={authenticationView && !isAuthorized}
                        submit={handleConfirm}
                        submitText={T.common.confirm}
                        priceLabel={T.common.totalPrice}
                        price={totalPrice}
                        onRemovePromoCode={onRemovePromoCode}
                        priceBeforeDiscount={originalPrice}
                        promoCode={form.promoCode}
                        promotionPackage={promotionPackage}
                        requestPromotion={requestPromotion}
                        disabled={notEnoughCredits || confirmDisabled}
                        isLoading={isLoading || isUploadingPhotos || (Boolean(files.length) && authenticationView)}
                        content={(
                            <React.Fragment>
                                <View>
                                    <Address disabled={isLoading} />
                                </View>
                                <AirConditionSummaryColumn
                                    originalPrice={originalPrice}
                                    totalPrice={totalPrice}
                                    promotionPackage={promotionPackage}
                                    priceMessage={T.screens.airCondition.estimatedMessage}
                                    renderPriceComponent={() => (
                                        <TotalPriceComponent
                                            totalPrice={jobQuotation?.estimation?.value || 0}
                                            customText={T.common.estimatedPrice}
                                            originalPrice={originalPrice}
                                        />
                                    )}
                                    renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                        <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                    ) : undefined}
                                    renderSubTotalComponent={jobQuotation?.subTotal ? () => (
                                        <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                                    ) : undefined}
                                    form={formShape}
                                    couponText={promotionPackage
                                        ? promotionPackage.promotion.message_to_client
                                        : undefined
                                    }
                                    renderExtraContent={() => (
                                        <View>
                                            <VisitCharges
                                                labelText={title}
                                                chargesMessage={subtitle}
                                                modalTitle={visitChargesDetails.title}
                                                messageText={visitChargesDetails.description}
                                            />
                                            {/* remove this check after guest booking experiment  */}
                                            {!isAuthorized && (
                                                <PromotionCodeInput
                                                    showBillingMessage
                                                    promoCode={form.promoCode}
                                                    isLoading={isLoading}
                                                    onRemovePromoCode={onRemovePromoCode}
                                                    promotionPackage={promotionPackage}
                                                    requestPromotion={requestPromotion}
                                                />
                                            )}
                                        </View>
                                    )}
                                    jobQuotation={jobQuotation}
                                    sessionsGenerated={sessionsGenerated}
                                    jobStartingTime={bookingTime}
                                />
                                {!authenticationView && (
                                    <PaymentMethodTile
                                        notEnoughCredits={notEnoughCredits}
                                        onPress={() => setPaymentView(true)}
                                        selectedPaymentMethod={selectedPaymentMethod}
                                    />
                                )}
                                <PolicyLinks />
                                <Rated />
                                <BookingCancellationFees />
                            </React.Fragment>
                        )}
                    />
                </MediaQuery.Hidden>
            )}
        />
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        zIndex: theme.zIndex[10]
    },
    selectWrapper: {
        zIndex: theme.zIndex[10],
        marginTop: -theme.utils.gap(2)
    },
    addonsRow: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(2)
    },
    acOptionImage: {
        height: {
            lg: 120,
            xs: 120
        },
        width: '100%'
    },
    acOptionsWrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        justifyContent: 'space-between',
        gap: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    acOption: {
        flex : 1,
        borderRadius: 4,
        marginBottom: theme.utils.gap(1),
        borderColor: '#E5E5E5',
        borderWidth: 1
    },
    cardHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    cardDescription: {
        opacity: 0.4,
        color: theme.colors.grey
    },
    cardDescriptionText: {
        fontSize: 13
    },
    cardHeaderText: {
        fontSize: 14,
        flexGrow: 2,
        flexShrink: 1,
        flexBasis: '0%'
    },
    cardHeaderSelect: {
        alignItems: 'flex-end',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%'
    },
    acDescription: {
        padding: theme.utils.gap(1)
    },
    addonsTitle: {
        marginBottom: theme.utils.gap(1)
    }
}))
