import { useMemo } from 'react'
import { useNavigation } from '@react-navigation/native'
import { TabNavigationState } from '@react-navigation/routers'
import { Icons } from 'assets'
import { jobActions } from 'features/jobs'
import { useTranslations } from 'lib/hooks'
import { CustomerChargeStatus, JobStatus, KeyValuePair } from 'lib/types'
import { useUnreadJobChatsAtom, useUnreadNotificationCounterAtom } from '../atoms'
import { ScreenNames } from '../routing'

export const useBottomBarRoutes = (state: TabNavigationState<KeyValuePair>) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const [unreadJobChatsAtom] = useUnreadJobChatsAtom()
    const [unreadNotificationCounter] = useUnreadNotificationCounterAtom()
    const { data: response } = jobActions.useGetConsumerActiveJobs()
    const hasBillings = [...response?.data.job_list || []].some(item => item.customer_charges?.some(item => item.auth_status === CustomerChargeStatus.Pending) && item.status === JobStatus.Accepted)
    const proposals = [...response?.data.job_list || []].filter(job => job.job_proposals?.length !== 0 && job.status === JobStatus.Submitted)
    const hasUnreadChatMessages = unreadJobChatsAtom.length > 0
    const hasProposals = proposals.length > 0
    const routes = useMemo(() => {
        const [
            homeTab,
            bookingsTab,
            notificationsTab,
            profileTab
        ] = state.routes

        return [
            {
                name: homeTab?.name,
                key: homeTab?.key,
                text: T.components.bottomBarTabs.home,
                icon: Icons.House,
                screenName: ScreenNames.AllServices
            },
            {
                name: bookingsTab?.name,
                key: bookingsTab?.key,
                text: T.components.bottomBarTabs.bookings,
                icon: Icons.Calendar,
                screenName: ScreenNames.Bookings,
                hasDot: hasUnreadChatMessages || hasProposals || hasBillings
            },
            {
                name: notificationsTab?.name,
                key: notificationsTab?.key,
                text: T.components.bottomBarTabs.notification,
                icon: Icons.Bell,
                screenName: ScreenNames.Notifications,
                hasDot: unreadNotificationCounter > 0,
                onPress: () => navigation.navigate(ScreenNames.Notifications)
            },
            {
                name: profileTab?.name,
                key: profileTab?.key,
                text: T.components.bottomBarTabs.profile,
                icon: Icons.User,
                screenName: ScreenNames.User,
                onPress: () => navigation.navigate(ScreenNames.User)
            }
        ]
    }, [T, state.routes, unreadNotificationCounter])

    return routes
}
