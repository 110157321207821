import { useEffect } from 'react'
import { emitter } from 'lib/utils'

export const useCalendarListeners = setIsFullCalendarOpen => {
    const openFullCalendarListener = () => setIsFullCalendarOpen(true)

    useEffect(() => {
        emitter.on('openFullCalendarModal', openFullCalendarListener)

        return () => {
            emitter.off('openFullCalendarModal', openFullCalendarListener)
        }
    }, [])
}
