import { R } from 'lib/utils'

export const getFirstLetters = (value: string) =>
    value ? value
        .split(' ')
        .map(word => word.charAt(0))
        .join('') : ''

export const formatAddress = (address: string, withoutPostCode?: boolean) =>
    R.when(
        R.is(String),
        R.pipe(
            R.toLower,
            R.replace(/(\s.)/g, R.toUpper),
            R.replace(' Singapore', ', Singapore'),
            withoutPostCode ? R.replace(/\d{6}/, '') : R.identity,
            R.trim
        )
    )(address)

export const toKebabCase = (value: string) =>
    R.when(
        R.is(String),
        R.pipe(
            R.replace(/([a-z])([A-Z])/g, '$1-$2'), // insert hyphen between lower and upper case letters
            R.replace(/[^a-zA-Z0-9]+/g, '-'), // replace non-alphanumeric characters with hyphens
            R.replace(/\s+/g, '-'), // replace spaces with hyphens
            R.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`), // add hyphen before capital letters
            R.replace(/--+/g, '-'), // replace double hyphens with a single hyphen
            R.replace(/^-/, ''), // remove hyphen at the beginning of the string
            R.toLower,
            R.trim
        )
    )(value)

export const getFormattedAddressUnit = (unit?: string) => unit && unit.trim().length > 0 ? `${unit}, ` : ``
