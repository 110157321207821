import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Send: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M70 40 15.118 14.882 32.096 40.01 16.322 66.32 70 40Z"
            fill="#0E2638"
            stroke="#0E2638"
            strokeWidth={1.6}
            strokeLinejoin="round"
        />
    </Icon>
)
