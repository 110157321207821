import React from 'react'
import { View, ViewStyle } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { Surface } from '@propertyguru/hive-surface'
import { Icon, IconProps } from '@propertyguru/hive-icon'
import { CheckRadioCircleCheckboxCheckCheckmarkConfirmFill } from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { Badge, BadgeProps } from '@propertyguru/hive-badge'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type AddressChipBoxProps = {
    active?: boolean,
    text: string,
    icon: IconProps['icon'],
    iconActive: IconProps['icon'],
    iconWidth?: number,
    iconHeight?: number,
    badgeProps?: BadgeProps,
    style?: ViewStyle,
    onPress?: () => void;
}

export const AddressChipBox: React.FunctionComponent<AddressChipBoxProps> = props => {
    const { active, text, icon, iconActive, iconWidth = 24, iconHeight = 24, badgeProps, style, onPress } = props
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <Surface style={{ ...styles.container, ...style }} onPress={onPress}>
            <View
                style={[
                    styles.wrapper,
                    active && styles.boxShadow,
                    { borderColor: hive.color(active ? 'border/active/tertiary' : 'border/active/primary') }
                ]}
            >
                <Icon icon={active ? iconActive : icon} color="icon/active/primary" width={iconWidth} height={iconHeight} />
                <View style={styles.info}>
                    <Text typography="body/s" color="text/active/primary">{text}</Text>
                    {badgeProps && (
                        <View style={styles.badgeContainer} pointerEvents="none">
                            <Badge {...badgeProps} />
                        </View>
                    )}
                </View>
                <View style={{ width: hive.spacing('x6') }}>
                    {active && <Icon icon={CheckRadioCircleCheckboxCheckCheckmarkConfirmFill} width={24} height={24} color="icon/success/primary" />}
                </View>
            </View>
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        overflow: 'hidden',
        paddingBottom: theme.utils.hive.spacing('x1'),
        marginBottom: -theme.utils.hive.spacing('x1'),
        borderRadius: theme.utils.hive.borderRadius('medium')
    },
    wrapper: {
        flexDirection: 'row',
        backgroundColor: theme.colors.white,
        borderWidth: 1,
        paddingVertical: theme.utils.hive.spacing('x5'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        borderRadius: theme.utils.hive.borderRadius('medium'),
        columnGap: theme.utils.hive.spacing('x3')
    },
    info: {
        flex: 1,
        alignItems: 'flex-start'
    },
    badgeContainer: {
        marginTop: theme.utils.hive.spacing('x1')
    },
    boxShadow: {
        ...theme.utils.createShadow(0, 0.4, 4, 4, 0, theme.colors.shadowSoft)
    }
}))

