import React, { useEffect } from 'react'
import { ScrollView, Image, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Images } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import {
    WelcomeMessage,
    ServiceRecommendations,
    ServiceCategories,
    DealsSection,
    SupportSection,
    CredibilitySection
} from '../components'
import { useFirstSessionAtom } from 'lib/atoms'
import { usePushNotificationPermission } from 'features/pushNotifications'

export const DashboardScreen: React.FunctionComponent = () => {
    const insets = useSafeAreaInsets()
    const { styles } = useStyles(stylesheet)

    const [isFirstSession] = useFirstSessionAtom()
    const { isEnabled, requestPushNotificationPermission } = usePushNotificationPermission()

    useEffect(() => {
        if (!isEnabled && isFirstSession) {
            requestPushNotificationPermission()
        }
    }, [])

    return (
        <View>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.container}>
                    <Image source={Images.DashboardGradient} style={styles.image} />
                    <View style={{ paddingTop: insets.top, marginTop: -(260 + insets.bottom) }}>
                        <WelcomeMessage />
                    </View>
                    <View>
                        <ServiceRecommendations />
                    </View>
                    <ServiceCategories />
                    <DealsSection />
                    <SupportSection />
                    <CredibilitySection />
                </View>
            </ScrollView>
            <View style={styles.topBackground} />
            <View style={styles.bottomBackground} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    },
    image: { height: 270, width: '100%' },
    topBackground: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '50%',
        zIndex: -1,
        backgroundColor: theme.colors.navajoWhite
    },
    bottomBackground: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '50%',
        zIndex: -1,
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    }
}))
