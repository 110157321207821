import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { ENV, APP_CONFIG } from '../config'
import { Label } from './typography'
import { isNative, isWeb, Measurements } from '../common'
import { getAppVersionForPlatform, getAppBuildEnvironment } from '../utils'

export const AppVersion: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)

    if (ENV.APP_ENV !== 'integration') {
        return null
    }

    return (
        <View style={styles.container}>
            <Label>
                {getAppVersionForPlatform()} {getAppBuildEnvironment()}
            </Label>
            {/* todo temporary */}
            {isWeb && ENV.APP_ENV === 'integration' && (
                <Label>
                    {APP_CONFIG.API.URL?.includes('web.integration.sendhelper.com') ? 'API V2' : 'API V1'}
                </Label>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        position: 'absolute',
        top: isNative
            ? Measurements.StatusBarHeight - 4
            : Measurements.WindowHeight - 60,
        right: 0,
        backgroundColor: theme.colors.linen,
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: 4,
        borderRadius: 1,
        borderWidth: 1,
        borderColor: theme.colors.silver,
        borderTopLeftRadius: theme.utils.gap(1),
        borderBottomLeftRadius: theme.utils.gap(1)
    }
}))
