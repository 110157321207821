import React, { useEffect, useState } from 'react'
import { ActivityIndicator, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Divider } from '@propertyguru/hive-divider'
import { AnnotationBubbleXCloseDeleteOutline, ArrowRightOutline, ChevronLeftOutline, CrossedLargeCloseFill, TicketAdmitVipOutline } from '@propertyguru/hive-icons'
import { Icon } from '@propertyguru/hive-icon'
import { Text } from '@propertyguru/hive-text'
import { Button } from '@propertyguru/hive-button'
import { TextInput } from '@propertyguru/hive-text-input'
import { useNavigation } from '@react-navigation/native'
import { PromoBox, ScreenHeader } from 'lib/native/components'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { useDynamicServiceAtom } from 'lib/atoms'
import { SupplyID } from 'lib/types'
import { useNativeJobBookingDetailAtom, useNativeUserTokenAtom } from 'lib/native/atoms'
import { Segment } from 'lib/analytics'
import { Promotion as PromotionApplicable } from 'lib/models'
import { Promotion, BookingFrequency } from 'lib/native/models'
import { Footer } from '../components'
import { useGetListPromotions, useGetPromotionApplicable } from '../actions'

export const PromoCodeScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()

    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const [inputPromo, setInputPromo] = useState('')
    const [promotions, setPromotions] = useState<Array<Promotion>>([])
    const [selectedPromotion, setSelectedPromotion] = useState<Promotion | null>(null)
    const [applicablePromotion, setApplicablePromotion] = useState<PromotionApplicable | null>(null)

    const [token] = useNativeUserTokenAtom()
    const [config] = useDynamicServiceAtom(SupplyID.Cleaner)
    const supplyId = config._id

    const handleApplyPress = () => {
        if (applicablePromotion) {
            setJobBookingDetailAtom(prev => ({
                ...prev,
                promo: applicablePromotion
            }))
            Segment.bookingDiscountCodeApplied({
                promoCode: applicablePromotion.promo_code,
                success: true
            })
            navigation.navigate(ScreenNamesNative.CleaningSummaryNative)
        }
    }

    const filteredPromotions = React.useMemo(() => {
        if (!inputPromo || !promotions.length) {
            return promotions
        }

        return promotions.filter(promo =>
            promo.promoCode.toLowerCase().includes(inputPromo.toLowerCase())
        )
    }, [promotions, inputPromo])

    const onInputChange = (value: string) => {
        setErrorMessage('')
        setInputPromo(value)

        const hasExactMatch = (promo: string) => promo.toLowerCase() === value.toLowerCase()
        const shouldAutoApplyPromo =
            filteredPromotions?.length === 1 &&
            value &&
            hasExactMatch(filteredPromotions[0].promoCode)

        if (shouldAutoApplyPromo) {
            setSelectedPromotion(filteredPromotions[0])
            validatePromoCode(value)
        }
    }

    const { mutate: validatePromotionCode, isLoading } = useGetPromotionApplicable()
    const { mutate: getListPromotions, isLoading: isLoadingPromotions } = useGetListPromotions()

    const [errorMessage, setErrorMessage] = useState('')

    const resetPromoState = () => {
        setSelectedPromotion(null)
        setInputPromo('')
        setApplicablePromotion(null)
        setErrorMessage('')
    }

    const validatePromoCode = (code: string) => {
        setApplicablePromotion(null)
        validatePromotionCode(
            {
                token,
                promo_code: code,
                job_data: {
                    is_recurring: jobBookingDetailAtom.frequency !== BookingFrequency.OneTime,
                    date_time: jobBookingDetailAtom.booking?.date || '',
                    num_hours: jobBookingDetailAtom.numberOfHours,
                    supply: supplyId,
                    ...(jobBookingDetailAtom.slotId && { slotId: jobBookingDetailAtom.slotId })
                }
            },
            {
                onSuccess: response => {
                    const promo = response.data.promotion
                    const promoCard = response.data?.card

                    if (promoCard) {
                        setSelectedPromotion(promoCard)
                        setPromotions(prev => {
                            const exists = prev.some(p => p.promoCode === promoCard.promoCode)

                            return exists ? prev : [...prev, promoCard]
                        })
                    }

                    setApplicablePromotion(promo)
                    setErrorMessage('')
                },
                onError: error => {
                    setApplicablePromotion(null)
                    setErrorMessage(error?.errors?.error_msg)
                }
            }
        )
    }

    useEffect(() => {
        const payload = {
            token,
            supply: supplyId,
            isRecurring: jobBookingDetailAtom.frequency !== BookingFrequency.OneTime,
            datetime: jobBookingDetailAtom.booking?.date || '',
            ...(jobBookingDetailAtom.slotId && { slotId: jobBookingDetailAtom.slotId }),
            ...(jobBookingDetailAtom.numberOfHours && { numHours: jobBookingDetailAtom.numberOfHours })
        }
        getListPromotions(payload, {
            onSuccess: response => {
                setPromotions(response?.data?.promotions ?? [])
            },
            onError: () => {
                setPromotions([])
            }
        })
    }, [])

    return (
        <View style={{ ...styles.container, paddingTop: insets.top }}>
            <ScreenHeader>
                <ScreenHeader.Button icon={ChevronLeftOutline} onPress={navigation.goBack} />
                <ScreenHeader.Title>
                    {T.screens.promoCode.pageTitle}
                </ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <View style={styles.searchWrapper}>
                <TextInput
                    style={styles.searchInput}
                    placeholder={T.screens.promoCode.placeholder.promoCode}
                    value={inputPromo}
                    onChangeText={onInputChange}
                    size="default"
                    inputMode="text"
                    keyboardType="default"
                    accessibilityLabel={T.accessibility.native.promoScreen.promoInput}
                    endIcon={CrossedLargeCloseFill}
                    onEndIconPress={() => {
                        resetPromoState()
                    }}
                />
                <Button.Icon
                    icon={ArrowRightOutline}
                    disabled={inputPromo === ''}
                    onPress={() => validatePromoCode(inputPromo)}
                />
            </View>
            <ScrollView style={styles.main}>
                <View style={styles.promoCodeWrapper}>
                    {isLoadingPromotions && <ActivityIndicator />}
                    {filteredPromotions.length === 0 && !isLoadingPromotions && (
                        <View style={styles.noPromoCodeFound}>
                            <View>
                                <Text typography="label/l" color="text/active/primary">
                                    {T.screens.promoCode.errorMessages.noPromosFoundHeader}
                                </Text>
                            </View>
                            <View>
                                <Text typography="body/xs" color="text/active/secondary">
                                    {T.screens.promoCode.errorMessages.noPromosFoundText}
                                </Text>
                            </View>
                        </View>
                    )}
                    {!isLoadingPromotions && filteredPromotions?.map((promo, index) => (
                        <PromoBox
                            key={`${promo?.promoCode}-${index}`}
                            title={promo?.title}
                            condition={promo?.label || ''}
                            codeProps={{
                                label: promo.badge || '',
                                type: 'secondary',
                                color: 'green',
                                size: 'small',
                                icon: TicketAdmitVipOutline,
                                iconSize: 16
                            }}
                            onPress={() => {
                                if (selectedPromotion?.promoCode === promo.promoCode) {
                                    resetPromoState()

                                    return
                                }

                                setSelectedPromotion(promo)
                                setInputPromo(promo.promoCode)
                                validatePromoCode(promo.promoCode)
                            }}
                            active
                            selected={inputPromo.toLowerCase() === promo.promoCode.toLowerCase()}
                            customBadgeStyles={styles.badgeLightGreen}
                            accessibilityLabel={`${T.accessibility.native.promoScreen.visiblePromo}-${promo.promoCode}`}
                            action={promo.actions?.[0]}
                        />
                    ))}
                </View>
            </ScrollView>
            <Footer style={{ paddingBottom: insets.bottom }}>
                {errorMessage !== '' && (
                    <View style={styles.errorWrapper}>
                        <Icon icon={AnnotationBubbleXCloseDeleteOutline} color="icon/error/primary" width={16} height={16} />
                        <View>
                            <Text typography="caption/s" color="text/warning/primary">{errorMessage}</Text>
                            <Text typography="caption/s" color="text/warning/primary">{T.screens.promoCode.errorMessages.tryAgainPromo}</Text>
                        </View>
                    </View>
                )}
                <Footer.Buttons>
                    <View style={styles.footerPromoTextWrapper}>
                        {applicablePromotion?.promo_code && selectedPromotion && (
                            <>
                                <Text typography="body/xs" color="text/active/primary">
                                    {T.screens.promoCode.youSave}
                                </Text>
                                <Text typography="label/l" style={styles.footerPromoTextSavingAmount}>
                                    {selectedPromotion?.saved}
                                </Text>
                            </>
                        )}
                        {!applicablePromotion?.promo_code && (
                            <>
                                <Text style={styles.footerPromoEmpty} typography="label/l" color="text/active/primary">-</Text>
                            </>
                        )}
                    </View>
                    <Button
                        text={T.screens.promoCode.footerButtons.apply}
                        style={styles.footerBtnNext}
                        disabled={isLoading || !applicablePromotion || inputPromo === ''}
                        loading={isLoading}
                        onPress={handleApplyPress}
                    />
                </Footer.Buttons>
            </Footer>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    main: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    },
    searchWrapper: {
        flexDirection: 'row',
        paddingVertical: theme.utils.hive.spacing('x4'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        backgroundColor: theme.colors.white
    },
    searchInput: {
        flex: 1,
        marginRight: theme.utils.hive.spacing('x3')
    },
    badgeLightGreen: {
        backgroundColor: theme.utils.hive.color('fill/success/secondary') as string
    },
    promoCodeWrapper: {
        paddingVertical: theme.utils.hive.spacing('x4'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        rowGap: theme.utils.hive.spacing('x4')
    },
    noPromoCodeFound: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: theme.utils.hive.spacing('x2'),
        flex: 1,
        height: 400
    },
    errorWrapper: {
        paddingVertical: theme.utils.hive.spacing('x3'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        flexDirection: 'row',
        columnGap: theme.utils.hive.spacing('x2'),
        backgroundColor: theme.utils.hive.color('fill/error/secondary') as string
    },
    footerBtnNext: {
        flex: 0.5
    },
    footerPromoEmpty: {
        textAlignVertical: 'center',
        paddingTop: theme.utils.hive.spacing('x2')
    },
    footerPromoTextWrapper: {
        flex: 0.5
    },
    footerPromoTextSavingAmount: {
        color: theme.utils.hive.color('fill/success/primary') as string,
        fontWeight: 'bold'
    }
}))
