import React, { useMemo } from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { jobHelpers } from 'lib/utils'
import { DynamicTimeSlots, Nullable, SelectInputOption } from 'lib/types'
import { useFeatureFlagAtom } from 'lib/atoms'
import { Label, IconWithPrice } from 'lib/components/form/components/oneOffTimeSlotPicker'

type ItemProps = {
    item: SelectInputOption,
    value: Nullable<SelectInputOption>,
    timeSlots: DynamicTimeSlots
}

const Item: React.FunctionComponent<ItemProps> = React.memo(({ item, value, timeSlots }) => {
    const { styles } = useStyles(stylesheet)
    const [featureFlagAtom] = useFeatureFlagAtom()
    const isSelected = useMemo(() => value?.value === item.value, [value, item])
    const shouldShowDynamicTimeSlotIcon = useMemo(
        () =>
            jobHelpers.shouldShowDynamicTimeSlotIcon(item.value, timeSlots) &&
            featureFlagAtom?.isEnableDynamicPricing,
        [item.value, timeSlots, featureFlagAtom]
    )

    return (
        <View
            style={[
                styles.itemContainer,
                isSelected && styles.itemContainerSelected
            ]}
            key={item.value}
        >
            <Label item={item} isSelected={isSelected} />
            {shouldShowDynamicTimeSlotIcon && (
                <IconWithPrice
                    item={item}
                    timeSlots={timeSlots}
                />
            )}
        </View>
    )
})

export default Item

const stylesheet = createStyles(theme => ({
    itemContainerSelected: {
        backgroundColor: theme.colors.yellow
    },
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: theme.utils.gap(1.25),
        paddingLeft: theme.utils.gap(1),
        paddingRight: theme.utils.gap(2),
        zIndex: 10
    }
}))
