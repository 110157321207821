import React, { useEffect } from 'react'
import { View, Image } from 'react-native'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { useHive } from '@propertyguru/hive'
import { createStyles } from 'lib/styles'
import { useCleanApp, useStyles, useTranslations } from 'lib/hooks'
import { CredibilitySection } from 'features/native/home/components'
import { Images } from 'assets'

type ErrorBoundaryHandlerProps = {
    error: Error;
    resetError: VoidFunction;
}

export const ErrorBoundaryHandler: React.FunctionComponent<ErrorBoundaryHandlerProps> = ({ resetError }) => {
    const { styles } = useStyles(stylesheet)
    const hive = useHive()
    const T = useTranslations()
    const { cleanPersonalData } = useCleanApp()

    useEffect(() => {
        cleanPersonalData()
    }, [])

    return (
        <View
            style={{ ...styles.container, backgroundColor: hive.color('fill/neutral/primary') }}
            testID={T.accessibility.errorScreenNative.container}
            accessible
            accessibilityLabel={T.accessibility.errorScreenNative.container}
        >
            <Image source={Images.ErrorBanner} style={styles.image} />
            <View style={{ ...styles.messageContainer, padding: hive.spacing('x6') }}>
                <Text
                    style={styles.textCenter}
                    typography="label/l"
                    testID={T.accessibility.errorScreenNative.message}
                    accessible
                    accessibilityLabel={T.accessibility.errorScreenNative.message}
                >
                    {T.errorBoundaryNative.title}
                </Text>
                <View style={{ paddingTop: hive.spacing('x2'), paddingBottom: hive.spacing('x4') }}>
                    <Text style={styles.textCenter} typography="body/xs">
                        {T.errorBoundaryNative.message}
                    </Text>
                </View>
                <Button
                    onPress={resetError}
                    text={T.errorBoundaryNative.submit}
                    testID={T.accessibility.errorScreenNative.button}
                    accessible
                    accessibilityLabel={T.accessibility.errorScreenNative.button}
                />
            </View>
            <CredibilitySection />
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flex: 1,
        justifyContent: 'space-between'
    },
    image: {
        width: '100%',
        height: '50%'
    },
    messageContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    textCenter: {
        textAlign: 'center'
    }
}))
