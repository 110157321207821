import axios, { InternalAxiosRequestConfig } from 'axios'
import { APP_CONFIG } from '../config'
import { HttpMethod, KeyValuePair } from '../types'
import { authUtils } from 'features/auth'

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
    isNewAppEnabled?: boolean;
}

// Need this for file upload, need to remove all request headers
export const axiosWithoutInterceptors = axios.create()

export const setAxiosDefaults = () => {
    axios.defaults.baseURL = APP_CONFIG.API.URL
    axios.defaults.timeout = APP_CONFIG.API.REQUEST_TIMEOUT
}

axios.interceptors.request.use((request: CustomAxiosRequestConfig) => {
    request.headers = request.headers || {} as KeyValuePair

    const isNewAppEnabled = request.isNewAppEnabled

    if (request.method !== HttpMethod.GET) {
        request.headers.Accept = 'application/json'
        request.headers['Content-Type'] = 'application/json'
    }

    const hasNoAuth = !request.headers.Authorization

    if (hasNoAuth && !isNewAppEnabled) {
        request.headers.Authorization = `Bearer ${authUtils.getAccessToken()}`
    }

    if (hasNoAuth && isNewAppEnabled) {
        const nativeAuthUtils = require('features/native/auth').authUtils

        request.headers.Authorization = `Bearer ${nativeAuthUtils.getAccessToken()}`
    }

    request.baseURL = APP_CONFIG.API.URL

    return request
})
