import { useEffect, useState } from 'react'
import { dateHelpers } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { AnyDate } from 'lib/utils/date'
import { CleaningScheduleType } from 'lib/native/models'

export const useRegularBookingTimeGenerator = (date: AnyDate, scheduleType: CleaningScheduleType) => {
    const T = useTranslations()
    const [formattedStartDate, setFormattedStartDate] = useState<string>('')
    const [sequenceText, setSequenceText] = useState<string>('')

    const getEveryText = () => {
        switch(scheduleType) {
            case CleaningScheduleType.Weekly:
                return T.screens.cleaningDateTime.cleaningScheduleModal.description.every
            case CleaningScheduleType.BiWeekly:
                return T.screens.cleaningDateTime.cleaningScheduleModal.description.everyOther
            case CleaningScheduleType.Custom:
                return T.screens.cleaningDateTime.cleaningScheduleModal.description.custom
            default:
                return T.screens.cleaningDateTime.cleaningScheduleModal.description.every
        }
    }

    useEffect(() => {
        if (!date) {
            return
        }

        const formattedDayDate = dateHelpers.formatDateWithDayAndMonth(date)
        const formattedDayTime = dateHelpers.formatDateWithDayAndTime(date)
        const isCustomSchedule = scheduleType === CleaningScheduleType.Custom

        setFormattedStartDate(formattedDayDate)
        setSequenceText(` ${getEveryText()} ${isCustomSchedule ? '' : formattedDayTime}`)
    }, [date, scheduleType])

    return {
        formattedStartDate,
        sequenceText
    }

}
