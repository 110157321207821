import React, { useState } from 'react'
import { TextButton } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { HandymanPricingChartModal } from './HandymanPricingChartModal'

export const HandymanPricingChart: React.FunctionComponent = () => {
    const T = useTranslations()
    const [isPricingChartModalOpen, setIsPricingChartModalOpen] = useState(false)

    return (
        <React.Fragment>
            <HandymanPricingChartModal
                isOpen={isPricingChartModalOpen}
                onClose={() => setIsPricingChartModalOpen(false)}
            />
            <TextButton
                onPress={() => setIsPricingChartModalOpen(true)}
                text={T.common.priceReference}
            />
        </React.Fragment>
    )
}
