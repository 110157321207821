import React, { useState, useRef, useEffect, useMemo } from 'react'
import { View, ScrollView } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { useHive } from '@propertyguru/hive'
import { Button } from '@propertyguru/hive-button'
import { Divider } from '@propertyguru/hive-divider'
import { Text } from '@propertyguru/hive-text'
import { ChevronDownSmallOutline, ChevronLeftSmallOutline, ChevronRightSmallOutline } from '@propertyguru/hive-icons'
import { Touchable } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { getCalendarDays, getCalendarYears, resetDateTime } from './utils'
import { useDayNames, useMonthNames } from './hooks'
import { HiveCalendarDay as Day } from './HiveCalendarDay.native'
import { HiveCalendarYear } from './HiveCalendarYear.native'
import { YEAR_RANGE } from './constants'

type HiveCalendarProps = {
    selectedDate: Date,
    minYear?: number,
    maxYear?: number,
    daysWithIcons?: Array<{ date: Date, icon: React.ReactNode }>,
    dayNamesWithIcons?: Array<{ day: number, icon: React.ReactNode }>,
    minDate?: Date,
    maxDate?: Date,
    onDateSelect: (date: Date) => void
}

export const HiveCalendar = ({
    daysWithIcons = [],
    dayNamesWithIcons = [],
    onDateSelect,
    ...props
}: HiveCalendarProps) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)
    const monthNames = useMonthNames()
    const dayNames = useDayNames()
    const yearScrollViewRef = useRef<ScrollView>(null)
    const selectedDate = useMemo(() => resetDateTime(props.selectedDate), [props.selectedDate])
    const todayDate = useMemo(() => resetDateTime(new Date()), [])
    const minDate = useMemo(() => props.minDate ? resetDateTime(props.minDate) : undefined, [props.minDate])
    const minDateYear = useMemo(() => minDate?.getFullYear(), [minDate])
    const maxDate = useMemo(() => props.maxDate ? resetDateTime(props.maxDate) : undefined, [props.maxDate])
    const maxDateYear = useMemo(() => maxDate?.getFullYear(), [maxDate])
    const minYear = useMemo(() => {
        const date = minDate || new Date()

        return date.getFullYear() - YEAR_RANGE
    }, [minDate])
    const maxYear = useMemo(() => {
        const date = maxDate || new Date()

        return date.getFullYear() + YEAR_RANGE
    }, [maxDate])
    const [currentDate, setCurrentDate] = useState(() => new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1))
    const [isYearView, setIsYearView] = useState(false)
    const isYearViewVisible = useSharedValue(false)
    const animatedChevronStyles = useAnimatedStyle(() => ({
        transform: [
            {
                rotate: withTiming(`${isYearViewVisible.value ? 180 : 0}deg`, {
                    duration: 200
                })
            }
        ]
    }))
    const calendarDays = useMemo(() => getCalendarDays(currentDate), [currentDate])
    const calendarYears = useMemo(() => getCalendarYears(minYear, maxYear), [minYear, maxYear])
    const isPrevMonthDisabled = useMemo(() => isYearView || (minDate && currentDate <= new Date(minDate.getFullYear(), minDate.getMonth(), 1)), [isYearView, currentDate, minDate])
    const isNextMonthDisabled = useMemo(() => isYearView || (maxDate && currentDate >= new Date(maxDate.getFullYear(), maxDate.getMonth(), 1)), [isYearView, currentDate, maxDate])

    const handlePrevMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
    }

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))
    }

    const handleDateSelect = (date: Date) => {
        setCurrentDate(new Date(date.getFullYear(), date.getMonth(), 1))
        onDateSelect(resetDateTime(date))
    }

    const handleYearPress = () => {
        setIsYearView(!isYearView)
    }

    const handleYearSelect = (year: number) => {
        setCurrentDate(new Date(year, currentDate.getMonth(), 1))
        setIsYearView(false)
    }

    useEffect(() => {
        isYearViewVisible.value = isYearView
    }, [isYearView])

    useEffect(() => {
        setTimeout(() => {
            if (isYearView && yearScrollViewRef.current) {
                const currentYearIndex = currentDate.getFullYear() - minYear
                const yearViewHeight = 300
                const yearItemHeight = 56
                const scrollTo = currentYearIndex * yearItemHeight - yearViewHeight / 2 + yearItemHeight / 2

                yearScrollViewRef.current.scrollTo({ y: scrollTo, animated: true })
            }
        }, 0)
    }, [isYearView, currentDate])

    return (
        <View style={{
            ...styles.calendar,
            borderColor: hive.color('border/active/secondary'),
            borderRadius: hive.borderRadius('large'),
            paddingVertical: hive.spacing('x6'),
            paddingHorizontal: hive.spacing('x4')
        }}>
            <View style={styles.header}>
                <View style={{ ...styles.headerContent, columnGap: hive.spacing('x2') }}>
                    <Button.Icon
                        icon={ChevronLeftSmallOutline}
                        size="small"
                        disabled={isPrevMonthDisabled}
                        onPress={!isPrevMonthDisabled ? handlePrevMonth : undefined}
                    />
                    <Text style={styles.headerTitle} typography="body/xs" color="text/active/primary">{monthNames[currentDate.getMonth()]}</Text>
                    <Button.Icon
                        icon={ChevronRightSmallOutline}
                        size="small"
                        disabled={isNextMonthDisabled}
                        onPress={!isNextMonthDisabled ? handleNextMonth : undefined}
                    />
                </View>
                <Touchable onPress={handleYearPress} style={{
                    ...styles.yearButton,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: hive.color('border/active/primary'),
                    borderRadius: hive.borderRadius('large'),
                    paddingVertical: hive.spacing('x3'),
                    paddingHorizontal: hive.spacing('x4')
                }}>
                    <Text style={styles.yearButtonText} typography="body/xs" color="text/inactive/primary">{currentDate.getFullYear()}</Text>
                    <Animated.View style={animatedChevronStyles}>
                        <ChevronDownSmallOutline width={24} height={24} fill={hive.color('icon/active/primary')} />
                    </Animated.View>
                </Touchable>
            </View>
            <Divider style={{ marginVertical: hive.spacing('x6') }} />
            {
                isYearView ? (
                    <ScrollView ref={yearScrollViewRef} style={styles.yearView}>
                        {
                            calendarYears.map(year => {
                                const isSelected = year === currentDate.getFullYear()
                                const isDisabled = (minDateYear && year < minDateYear) || (maxDateYear && year > maxDateYear) || false

                                return (
                                    <HiveCalendarYear
                                        key={year}
                                        year={year}
                                        isSelected={isSelected}
                                        isDisabled={isDisabled}
                                        onPress={handleYearSelect}
                                    />
                                )
                            })
                        }
                    </ScrollView>
                ) : (
                    <View style={{ paddingHorizontal: hive.spacing('x4') }}>
                        <View style={{ ...styles.weekDays, paddingVertical: hive.spacing('x1') }}>
                            {dayNames.map(day => (
                                <Text key={day} typography="label/s" color="text/active/secondary" style={styles.weekDayText}>
                                    {day}
                                </Text>
                            ))}
                        </View>
                        <View style={styles.days}>
                            {calendarDays.map(date => {
                                const dayIndex = date.getDay()
                                const isCurrentMonth = date.getMonth() === currentDate.getMonth()
                                const isToday = date.toDateString() === todayDate.toDateString()
                                const isSelected = date.toDateString() === selectedDate.toDateString()
                                const isDisabled = (minDate && date < minDate) || (maxDate && date > maxDate) || false
                                const icon = daysWithIcons.find(d => d.date.toDateString() === date.toDateString())?.icon || dayNamesWithIcons.find(d => d.day === dayIndex)?.icon

                                return (
                                    <Day
                                        key={date.toDateString()}
                                        date={date}
                                        isCurrentMonth={isCurrentMonth}
                                        isToday={isToday}
                                        isSelected={isSelected}
                                        isDisabled={isDisabled}
                                        icon={icon}
                                        onPress={handleDateSelect}
                                    />
                                )
                            })}
                        </View>
                    </View>
                )
            }
        </View >
    )
}

const stylesheet = createStyles(theme => ({
    calendar: {
        backgroundColor: theme.colors.white,
        maxWidth: 350
    },
    header: {
        minWidth: 299,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerContent: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    headerTitle: {
        minWidth: 76,
        textAlign: 'center'
    },
    yearButton: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    yearButtonText: {
        minWidth: 46
    },
    weekDays: {
        maxWidth: 280,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignSelf: 'center'
    },
    weekDayText: {
        width: 40,
        textAlign: 'center'
    },
    days: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        maxWidth: 280,
        alignSelf: 'center'
    },
    yearView: {
        maxHeight: 364
    }
}))
