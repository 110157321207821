import React, { useEffect, useState } from 'react'
import { View, Image, Platform } from 'react-native'
import emojiRegex from 'emoji-regex'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { UnauthorizedNativeLayout } from 'features/native/common/components'
import { Text } from '@propertyguru/hive-text'
import { TextInput } from '@propertyguru/hive-text-input'
import { Button } from '@propertyguru/hive-button'
import { Surface } from '@propertyguru/hive-surface'
import { AccessibilityEyeA11YOutline, ShowEyeSeeRevealLookVisibleOutline } from '@propertyguru/hive-icons'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Images } from 'assets/native'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { checkEmailValid } from 'lib/native/utils'
import { LoginScreenParams } from 'lib/native/routing/screenParams'
import { getAnonymousID } from 'lib/analytics/segment/utils'
import { DeviceInfo, linkingHelpers } from 'lib/utils'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, Segment } from 'lib/analytics'
import { NativeErrorCodes } from 'lib/native/types'
import { AuthMethod } from 'lib/analytics/segment/typewriter/segment'
import { useGetProfileData } from '../hooks/useNativeGetProfileData'
import { saveAccessToken, saveRefreshToken } from '../utils'
import { useSignInWithEmail } from '../actions'
import { useGoogleSignIn, useSocialSignIn } from '../hooks'
import { ErrorModal, SocialButtonsNative } from '../components'

export const LoginScreen: React.FunctionComponent<LoginScreenParams> = ({ navigation }) => {
    const T = useTranslations()
    const insets = useSafeAreaInsets()
    const { styles, theme } = useStyles(stylesheet)
    const { onPress: onGoogleSignInClick } = useGoogleSignIn(() => { })
    const regexEmoji = emojiRegex()
    const { signInWithFacebook, signInWithApple } = useSocialSignIn()
    const [isPasswordHidden, setIsPasswordHidden] = React.useState(true)
    const [email, setEmail] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [emailErrorString, setEmailErrorString] = useState(T.screens.auth.inCorrectEmail)

    const [password, setPassword] = useState('')
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [passwordErrorString, setPasswordErrorString] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [errorModalTitle, setErrorModalTitle] = useState('')
    const [errorModalMessage, setErrorModalMessage] = useState('')
    const { mutate: signInWithEmail } = useSignInWithEmail()
    const { getMe, isFetchingProfile } = useGetProfileData(error => {
        onReceiveAuthEmailError(error)
        setIsLoading(false)
    })
    const isButtonDisabled = isLoading || isFetchingProfile

    useEffect(() => {
        resetState()
    }, [])

    const resetState = () => {
        setEmail('')
        setPassword('')
        setIsPasswordValid(true)
        setIsValidEmail(true)
    }

    const onPasswordChange = value => {
        setPassword(value.replace(regexEmoji, ''))
        setIsPasswordValid(value.length > 0)
    }

    const onPasswordBlur = () => {
        if (password.length === 0) {
            setPasswordErrorString('')
            setIsPasswordValid(true)

            return
        }

        setIsPasswordValid(password.length > 0)
        setPasswordErrorString(
            password.length > 0 ? '' : T.screens.auth.formFields.password.validation.missingPassword
        )
    }

    const onShowPassword = () => {
        setIsPasswordHidden(!isPasswordHidden)
    }

    const onEmailChange = (value: string) => {
        setEmail(value.replace(regexEmoji, ''))
        setEmailErrorString('')
        setIsValidEmail(true)
    }

    const onEmailBlur = () => {
        if (email.length === 0) {
            setEmailErrorString('')
            setIsValidEmail(true)

            return
        }

        if (email.length !== 0) {
            setIsValidEmail(checkEmailValid(email))
        }

        if (!checkEmailValid(email)) {
            setIsValidEmail(false)
            setEmailErrorString(T.screens.auth.inCorrectEmail)
        }
    }

    const navigateToSignUp = () => {
        resetState()
        navigation?.navigate(ScreenNamesNative.SignUpNative)
    }

    const onNavigateToForgotPassword = () => {
        resetState()
        Segment.forgotPasswordClicked({})
        navigation?.navigate(ScreenNamesNative.ForgotPasswordNative)
    }

    const onReceiveAuthEmailError = error => {
        const details = error.details
        const { email, password } = details

        if (email) {
            setEmailErrorString(email)
            setIsValidEmail(false)
        }

        if (password) {
            setIsPasswordValid(false)
            setPasswordErrorString(password)
        }
    }

    const onReceiveInvalidPasswordError = () => {
        setIsPasswordValid(false)
        setPasswordErrorString(`${T.screens.auth.formFields.password.validation.incorrectEmailOrPassword}`)
    }

    const onReceiveAccountDoesNotExistsError = errors => {
        const { error_msg } = errors
        setEmailErrorString(error_msg)
        setIsValidEmail(false)
    }

    const onReceiveError = errors => {
        const { error_code, error_msg } = errors
        Segment.loginFailed({
            authMethod: AuthMethod.Email,
            errorMessage: error_msg
        })
        switch (error_code) {
            case NativeErrorCodes.INVALID_AUTH_REQUEST:
                onReceiveAuthEmailError(errors)
                break
            case NativeErrorCodes.INVALID_CREDENTIALS:
                onReceiveInvalidPasswordError()
                break
            case NativeErrorCodes.ACCOUNT_DOES_NOT_EXISTS:
                onReceiveAccountDoesNotExistsError(errors)
                break
            default:
                setIsErrorModalOpen(true)
                setErrorModalTitle(T.screens.auth.errors.loginFailed)
                setErrorModalMessage(error_msg)
        }
    }

    const onUserLogin = async () => {
        setIsLoading(true)
        signInWithEmail(
            {
                email,
                password,
                tracking: {
                    anonymousId: await getAnonymousID()
                }
            },
            {
                onSuccess: ({ data }) => {
                    saveAccessToken(data.tokens.accessToken)
                    saveRefreshToken(data.tokens.refreshToken)
                    getMe(data.token)

                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.EmailSignIn
                    })

                    const { deviceId, deviceName } = DeviceInfo.get()

                    Segment.identify(data.token, {
                        email,
                        device: {
                            deviceId,
                            deviceName,
                            platform: Platform.OS
                        }
                    })
                    resetState()
                    setIsLoading(false)
                },
                onError: response => {
                    setIsLoading(false)
                    onReceiveError(response.errors)
                }
            }
        )
    }

    const navigateToTermsAndPrivacy = () => {
        linkingHelpers.openUrl(`https://www.sendhelper.com/terms`)
    }

    const navigateToPrivacyPolicy = () => {
        linkingHelpers.openUrl(`https://www.sendhelper.com/privacy`)
    }

    return (
        <UnauthorizedNativeLayout themeColor={theme.colors.yellow} bounces={false}>
            <Surface style={styles.imageContainer}>
                <Image source={Images.LogIn} style={styles.image} />
            </Surface>
            <View style={{ ...styles.container, paddingBottom: insets.bottom }}>
                <View style={styles.titleContainer}>
                    <Text typography="body/s">{T.screens.auth.loginWithEmail}</Text>
                </View>
                <View style={styles.inputContainer}>
                    <TextInput
                        placeholder={T.screens.forgotPassword.email}
                        style={styles.textInput}
                        value={email}
                        onChangeText={onEmailChange}
                        error={!isValidEmail}
                        size="default"
                        onBlur={onEmailBlur}
                        inputMode="email"
                        keyboardType="email-address"
                        accessibilityLabel={T.accessibility.native.login.email}
                    />
                    {!isValidEmail && (
                        <Text
                            style={styles.errorContainer}
                            accessibilityLabel={T.accessibility.native.login.errorEmail}
                            typography="caption/xs"
                            color="text/error/primary"
                        >
                            {emailErrorString}
                        </Text>
                    )}
                </View>
                <View style={styles.inputContainer}>
                    <TextInput
                        placeholder={T.screens.auth.formFields.password.placeholder}
                        onChangeText={onPasswordChange}
                        secureTextEntry={isPasswordHidden}
                        style={styles.textInput}
                        value={password}
                        size="default"
                        onBlur={onPasswordBlur}
                        error={!isPasswordValid}
                        endIcon={isPasswordHidden ? AccessibilityEyeA11YOutline : ShowEyeSeeRevealLookVisibleOutline}
                        onEndIconPress={onShowPassword}
                        accessibilityLabel={T.accessibility.native.login.password}
                    />
                    {!isPasswordValid && (
                        <Text
                            style={styles.errorContainer}
                            accessibilityLabel={T.accessibility.native.login.errorPassword}
                            typography="caption/xs"
                            color="text/error/primary"
                        >
                            {passwordErrorString}
                        </Text>
                    )}
                </View>
                <View style={styles.linkContainer}>
                    <Text typography="body/xs">
                        {T.screens.auth.noAccount}
                        <Text
                            typography="body/xs"
                            accessibilityLabel={T.accessibility.native.login.emailSignUp}
                            style={styles.link}
                            onPress={navigateToSignUp}
                        >
                            {' '}
                            {T.common.signUp}
                        </Text>
                    </Text>
                    <Text
                        typography="body/xs"
                        style={styles.link}
                        accessibilityLabel={T.accessibility.native.login.forgotPassword}
                        onPress={onNavigateToForgotPassword}
                    >
                        {T.screens.auth.forgotPassword}
                    </Text>
                </View>
                <View style={styles.actionContainer}>
                    <Button type="primary" disabled={isButtonDisabled} loading={isButtonDisabled} onPress={onUserLogin} text={T.common.continue} accessibilityLabel={T.accessibility.native.login.continueButton} />
                </View>
                <View style={styles.socialSignUpContainer}>
                    <SocialButtonsNative
                        prefixText={T.screens.auth.socialSignInLabelExtended}
                        onContinueWithGoogle={onGoogleSignInClick}
                        onContinueWithFacebook={signInWithFacebook}
                        onContinueWithApple={signInWithApple}
                    />
                </View>
                <View style={styles.tcWrapper}>
                    <Text typography="caption/xs" color="text/inactive/primary">
                        {T.screens.auth.termsConditionPartOneLogin}
                        <Text typography="caption/xs" style={styles.link} onPress={navigateToTermsAndPrivacy}>
                            {T.screens.auth.termsConditionPartTwo}
                        </Text>
                        {T.common.and}{' '}
                        <Text typography="caption/xs" style={styles.link} onPress={navigateToPrivacyPolicy}>
                            {T.components.policies.privacy}
                        </Text>
                        {T.screens.auth.termsConditionPartThree}
                    </Text>
                </View>
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => {
                        setIsErrorModalOpen(false)
                    }}
                    title={errorModalTitle}
                    message={errorModalMessage}
                />
            </View>
        </UnauthorizedNativeLayout>
    )
}

const stylesheet = createStyles(theme => ({
    imageContainer: {
        aspectRatio: 374 / 231
    },
    image: {
        width: '100%',
        height: '100%'
    },
    container: {
        marginTop: theme.utils.gap(2),
        flex: 1,
        paddingHorizontal: {
            xs: theme.utils.gap(2)
        }
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: theme.utils.gap(1.5)
    },
    inputContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    },
    actionContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    },
    textInput: {
        width: '100%'
    },
    subTitle: {
        marginTop: theme.utils.gap(1.5)
    },
    description: {
        marginTop: theme.utils.gap(1.75)
    },
    linkContainer: {
        marginTop: theme.utils.gap(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    link: {
        textDecorationLine: 'underline'
    },
    socialSignUpContainer: {
        marginTop: theme.utils.gap(2)
    },
    tcWrapper: {
        marginVertical: theme.utils.gap(1)
    },
    errorContainer: {
        marginTop: theme.utils.gap(1)
    }
}))
