import * as Linking from 'expo-linking'
import * as WebBrowser from 'expo-web-browser'
import { R } from 'lib/utils'
import { isIOS, isNative } from 'lib/common'
import { Nullable, SupplyID } from '../types'
import { Campaign } from 'lib/native/models'
import { ScreenNames, ScreensConfig } from '../routing'
import { APP_CONFIG, ENV } from '../config'
import { Segment, OSSegment } from '../analytics'

type BookingQueryParams = {
    postalcode: string
    service: string
}

type AddPaymentParams = {
    token: string
    auth: string
    id: string
}

export const BOOK_URL = 'book'
export const ADD_PAYMENT_URL = 'cards/new'

export const isBookingLink = (url: string) => {
    // for now, it's only enabled for web
    if (isNative) {
        return false
    }

    const parsedUrl = Linking.parse(url)
    const { queryParams, path } = parsedUrl

    if (path !== BOOK_URL) {
        return false
    }

    const { postalcode, service } = queryParams as BookingQueryParams
    const parsedService = parseInt(service, 10)

    if (isNaN(parsedService) || SupplyID[parsedService] === undefined) {
        return false
    }

    const parsedPostalCode = parseInt(postalcode, 10)

    // Singapore postal code has 6 digits
    // https://en.wikipedia.org/wiki/Postal_codes_in_Singapore
    return !isNaN(parsedPostalCode) && postalcode.length === 6
}

export type WhatsappAddPaymentParams = {
    accessParams: { token: string; authToken: string }
    addPaymentParams: { paymentId: string; redirectUrl: string; screen: ScreenNames.UserCardsNew | ScreenNames.Auth }
}

export type WhatsappAddPaymentLinkData = { hasParams: false } | ({ hasParams: true } & WhatsappAddPaymentParams)

export const parseWhatsappAddPaymentLink = (url: string): WhatsappAddPaymentLinkData => {
    // for now, it's only enabled for web
    if (isNative) {
        return { hasParams: false }
    }

    const screen = ScreenNames.UserCardsNew
    const parsedUrl = Linking.parse(url)
    const { queryParams, path } = parsedUrl
    const { token, auth, id } = queryParams as AddPaymentParams

    if (path !== ADD_PAYMENT_URL || !token || !auth || !id) {
        return { hasParams: false }
    }

    return {
        hasParams: true,
        accessParams: { token, authToken: auth },
        addPaymentParams: { paymentId: id, redirectUrl: url, screen }
    }
}

const getSupplyIdFromPath = (path: string) => {
    const { queryParams } = Linking.parse(path)
    const { service } = queryParams as BookingQueryParams

    return SupplyID[SupplyID[parseInt(service, 10)]] as SupplyID
}

export const getInternalPathForBooking = (path: string) => {
    const supplyID = getSupplyIdFromPath(path)

    return getInternalPathBySupplyId(supplyID)
}

export const getInternalPathBySupplyId = (supplyID: SupplyID) => {
    // todo implement it
    // do we need to handle each screen individually, or simply pass route params
    // for now redirecting to bookings eg.: /book?postalcode=111111&service=6
    // fixme remember to remove this service from getExternalPathForBooking()!
    switch (supplyID) {
        case SupplyID.Cleaner:
            return {
                url: ScreensConfig[ScreenNames.Cleaning].path,
                screenName: ScreenNames.Cleaning
            }
        case SupplyID.DeepCleaning:
            return {
                url: ScreensConfig[ScreenNames.DeepCleaning].path,
                screenName: ScreenNames.DeepCleaning
            }
        case SupplyID.ACService:
            return {
                url: ScreensConfig[ScreenNames.AirCondition].path,
                screenName: ScreenNames.AirCondition
            }
        // eslint-disable-next-line no-fallthrough
        case SupplyID.Handyman:
            if (APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED) {
                return {
                    // @ts-ignore
                    url: ScreensConfig[ScreenNames.Handyman].path,
                    screenName: ScreenNames.Handyman
                }
            }

            return {
                url: null,
                screenName: null
            }
        // eslint-disable-next-line no-fallthrough
        case SupplyID.Laundry:
            if (APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED) {
                return {
                    // @ts-ignore
                    url: ScreensConfig[ScreenNames.Laundry].path,
                    screenName: ScreenNames.Laundry
                }
            }

            return {
                url: null,
                screenName: null
            }
        // eslint-disable-next-line no-fallthrough
        case SupplyID.Cooking:
            if (APP_CONFIG.SERVICES.IS_COOKING_ENABLED) {
                return {
                    // @ts-ignore
                    url: ScreensConfig[ScreenNames.Cooking].path,
                    screenName: ScreenNames.Cooking
                }
            }

            return {
                url: null,
                screenName: null
            }
        // eslint-disable-next-line no-fallthrough
        case SupplyID.PestControl:
            if (APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED) {
                return {
                    // @ts-ignore
                    url: ScreensConfig[ScreenNames.PestControl].path,
                    screenName: ScreenNames.PestControl
                }
            }

            return {
                url: null,
                screenName: null
            }
        // eslint-disable-next-line no-fallthrough
        case SupplyID.TasksErrands:
            if (APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED) {
                return {
                    // @ts-ignore
                    url: ScreensConfig[ScreenNames.TaskAndErrands].path,
                    screenName: ScreenNames.TaskAndErrands
                }
            }

            return {
                url: null,
                screenName: null
            }
            // eslint-disable-next-line no-fallthrough
        case SupplyID.MoversPackers:
            if (APP_CONFIG.SERVICES.IS_MOVERS_AND_PACKERS_ENABLED) {
                return {
                    // @ts-ignore
                    url: ScreensConfig[ScreenNames.MoversAndPackers].path,
                    screenName: ScreenNames.MoversAndPackers
                }
            }

            return {
                url: null,
                screenName: null
            }
        // eslint-disable-next-line no-fallthrough
        default:
            return {
                url: null,
                screenName: null
            }
    }
}

const getExternalPathForBooking = (path: string): Nullable<string> => {
    const supplyID = getSupplyIdFromPath(path)

    switch (supplyID) {
        // eslint-disable-next-line no-fallthrough
        case SupplyID.Cooking:
            if (!APP_CONFIG.SERVICES.IS_COOKING_ENABLED) {
                return 'cooking'
            }
        // eslint-disable-next-line no-fallthrough
        case SupplyID.TasksErrands:
            if (!APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED) {
                return 'tasks-errands'
            }
        // eslint-disable-next-line no-fallthrough
        case SupplyID.PestControl:
            if (!APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED) {
                return 'pest-control'
            }
        // eslint-disable-next-line no-fallthrough
        case SupplyID.Handyman:
            if (!APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED) {
                return 'handyman'
            }
        // eslint-disable-next-line no-fallthrough
        case SupplyID.Laundry:
            if (!APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED) {
                return 'laundry'
            }
        // eslint-disable-next-line no-fallthrough
        default:
            return null
    }
}

export const getRedirectUrlFromBookingLink = (
    isAuthorized: boolean,
    onUnsupportedServiceError: VoidFunction,
    path: string
) => {
    const { url } = getInternalPathForBooking(path)

    if (url) {
        return path.replace(BOOK_URL, url.toLowerCase())
    }

    // service is not supported, redirect back to marketing site
    const externalRoute = getExternalPathForBooking(path)

    // service is not supported internally nor externally
    if (!externalRoute) {
        onUnsupportedServiceError()

        return isAuthorized
            ? path.replace(BOOK_URL, ScreensConfig[ScreenNames.AllServices].path.toLowerCase())
            : path.replace(BOOK_URL, ScreenNames.Auth.toLowerCase())
    }

    openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/download/${externalRoute}`, false)

    return null
}

export const openSendhelperAppInStore = () => {
    if (isNative) {
        return
    }

    const isAndroid = navigator.userAgent.includes('Android')

    isAndroid
        ? openSendhelperGooglePlayStore()
        : openSendhelperAppStore()
}

export const openSendhelperAppStore = () => {
    if (isNative) {
        return
    }

    Segment.mobileAppDownload({
        os: OSSegment.IOS,
        pageName: window.location.pathname
    })

    openUrl(ENV.APP_STORE_URL)
}
export const openSendhelperGooglePlayStore = () => {
    if (isNative) {
        return
    }

    Segment.mobileAppDownload({
        os: OSSegment.Android,
        pageName: window.location.pathname
    })

    openUrl(ENV.GOOGLE_PLAY_STORE_URL)
}

export const openUrl = (url?: string, openInNewTab: boolean = true) => {
    if (!url) {
        return
    }

    Segment.externalLinkClicked({
        url
    })

    if (isNative) {
        return openInInAppBrowser(url)
    }

    if (openInNewTab) {
        return window.open(url)
    }

    // required by Microsoft Edge, also no need to handle clearTimeout
    // as user is redirected out of the webapp

    setTimeout(() => {
        window.location.replace(url)
    }, 0)
}

export const openInInAppBrowser = (url: string) => {
    if (isIOS) {
        WebBrowser
            .openBrowserAsync(url, {
                enableBarCollapsing: true
            })
            .catch(R.T)

        return
    }

    WebBrowser
        .getCustomTabsSupportingBrowsersAsync()
        .then(packages => {
            // we should prefer chrome
            const chromeBrowser = packages.browserPackages
                .find(packageName => packageName.toLowerCase().includes('chrome'))

            if (chromeBrowser) {
                return chromeBrowser
            }

            // user doesn't have chrome, let's find other browsers
            const [otherBrowser] = packages.browserPackages

            if (otherBrowser) {
                return otherBrowser
            }

            // this is the worst case, user have no chrome, no other browser and locked Sendhelper as default browser
            // falling back to linking
            return Promise.reject()
        })
        .then(preferredBrowser => WebBrowser
            .openBrowserAsync(url, {
                enableBarCollapsing: true,
                browserPackage: preferredBrowser
            })
        )
        .catch(() => Linking
            .openURL(url)
            .catch(R.T)
        )
}

export const openReferralTermsAndConditions = () => {
    openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/blog/customer-referral-program-refer-a-friend-you-both-get-50`)
}

export const openDynamicPriceTermsAndConditions = () => {
    openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/cleaning-dynamic-pricing-t-c`)
}

export const getDeepLinkCampaignDetails = (url:  string) => {
    const decodedUrl = decodeURIComponent(url)
    const params = new URLSearchParams(decodedUrl.split('?')[1])
    const name = params.get('campaign')
    const source = params.get('media_source')
    const af_adset = params.get('af_adset')
    const af_ad = params.get('af_ad')
    const af_channel = params.get('af_channel')

    return { name, source, af_adset, af_ad, af_channel } as Campaign
}
