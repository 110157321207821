import { useState } from 'react'
import { Platform } from 'react-native'
import { AnalyticsEvent, AnalyticsEventDescription, AuthSegmentMethod, GoogleAnalytics, Segment } from 'lib/analytics'
import { useFirstSessionAtom, useSocialSignInTokenAtom, useToastAtom } from 'lib/atoms'
import { useGetErrorMessage, useSessionData, useTranslations } from 'lib/hooks'
import { AuthorizeWithAppleResponse, ErrorCodes, NotificationType } from 'lib/types'
import { DeviceInfo, R, userInfoHelper } from 'lib/utils'
import { getAnonymousID } from 'lib/analytics/segment/utils'
import { saveAccessToken, saveRefreshToken } from 'features/auth/utils'
import { useSignInWithAppleNative, useSignInWithFacebookNative, useSignInWithGoogleNative } from '../actions'
import { AuthFlowError, GoogleSignInUser } from '../types/social'
import { useGetProfileData } from './useNativeGetProfileData'
import { authorizeWithApple, authorizeWithFacebook, getFacebookUserDetails } from '../social'
import { SignInResponse } from '../types/responses'
import { SignInWithAppleRequest } from '../types'

export const useSocialSignIn = (onError?: (error: string) => void) => {
    const T = useTranslations()
    const [, setToastMessage] = useToastAtom()
    const sessionData = useSessionData()
    const [, setSocialSignInToken] = useSocialSignInTokenAtom()
    const [, setIsFirstSession] = useFirstSessionAtom()
    const { mutate: signInWithGoogleNative } = useSignInWithGoogleNative()
    const { mutate: signInWithFacebook } = useSignInWithFacebookNative()
    const { mutate: signInWithAppleNative } = useSignInWithAppleNative()
    const { getMe, isFetchingProfile } = useGetProfileData(
        error => {
            if (onError) {
                onError(error)
            }

            setIsLoading(false)
        })
    const [isLoading, setIsLoading] = useState(false)

    const { getErrorMessage } = useGetErrorMessage()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onAuthSuccess = (data: SignInResponse, authMethod: AuthSegmentMethod) => {
        saveAccessToken(data.tokens.accessToken)
        saveRefreshToken(data.tokens.refreshToken)
        setIsFirstSession(true)
        getMe(data.token)
        const { deviceId, deviceName } = DeviceInfo.get()

        Segment.identify(data.token, {
            device: {
                deviceId,
                deviceName,
                platform: Platform.OS
            }
        })
    }

    const signInWithAppleAfterAuth = async (response: AuthorizeWithAppleResponse) => {

        const name = userInfoHelper.getFullName(response.user?.name.firstName, response.user?.name.lastName)
        const email = response?.user?.email

        const payload: SignInWithAppleRequest = {
            tracking: { ...sessionData, anonymousId: await getAnonymousID() },
            accountId: response.authorization.code,
            token: response.authorization.id_token || ''
        }

        if (name) {
            payload.name = name
        }

        if (email) {
            payload.email = email
        }

        signInWithAppleNative(payload, {
            onSuccess: ({ data }) => {
                GoogleAnalytics.logEvent({
                    eventType: AnalyticsEvent.UserAction,
                    description: AnalyticsEventDescription.AppleSignIn
                })

                onAuthSuccess(data, AuthSegmentMethod.Apple)
                setIsLoading(false)
            },
            onError: errorResponse => {
                setIsLoading(false)

                if (errorResponse.errors.error_code === ErrorCodes.UnhandledException) {
                    setToastMessage({
                        message: getErrorMessage(errorResponse),
                        type: NotificationType.Error
                    })
                }
            }
        })
    }

    const isUserAuthenticated = (authenticatedResponse?: AuthorizeWithAppleResponse) => authenticatedResponse &&
        authenticatedResponse.authorization?.code &&
        authenticatedResponse.authorization?.id_token

    return {
        isLoading: isLoading || isFetchingProfile,
        signInWithFacebook: () => {
            setIsLoading(true)
            authorizeWithFacebook(async (accessToken, error) => {
                if (!error && accessToken) {
                    return signInWithFacebook({
                        tracking: { ...sessionData, anonymousId: await getAnonymousID() },
                        token: accessToken
                    }, {
                        onSuccess: ({ data }) => {
                            GoogleAnalytics.logEvent({
                                eventType: AnalyticsEvent.UserAction,
                                description: AnalyticsEventDescription.FacebookSignIn
                            })

                            onAuthSuccess(data, AuthSegmentMethod.Facebook)
                        },
                        onError: async error => {
                            if (error.errors?.error_code !== ErrorCodes.UnhandledException) {
                                setIsLoading(false)

                                return setToastMessage({
                                    message: getErrorMessage(error),
                                    type: NotificationType.Error
                                })
                            }

                            // user has no facebook account, redirect to sign up
                            const userProfile = await getFacebookUserDetails(accessToken)
                                .catch(R.always(null))

                            if (!userProfile) {
                                setIsLoading(false)

                                return setToastMessage({
                                    message: getErrorMessage(error),
                                    type: NotificationType.Error
                                })
                            }

                            setSocialSignInToken(accessToken)
                            setIsLoading(false)
                        }
                    })
                }

                setIsLoading(false)

                if (error === AuthFlowError.ServerError) {
                    setToastMessage({
                        message: T.screens.auth.errors.facebookError,
                        type: NotificationType.Error
                    })
                }
            })
        },
        signInWithApple: (authenticatedResponse?: AuthorizeWithAppleResponse) => {
            setIsLoading(true)

            // check if the user is already signed up
            if (authenticatedResponse && isUserAuthenticated(authenticatedResponse)) {
                signInWithAppleAfterAuth(authenticatedResponse)

                return
            }

            authorizeWithApple()
                .then(response => {
                    signInWithAppleAfterAuth(response)
                })
                .catch((error: AuthFlowError) => {
                    console.log(error)

                    if (error === AuthFlowError.ServerError) {
                        setToastMessage({
                            message: T.screens.auth.errors.appleError,
                            type: NotificationType.Error
                        })
                    }
                })
                .finally(() => setIsLoading(false))
        },
        signInWithGoogle: async (idToken: string, user: GoogleSignInUser) => {
            if (!idToken || !user.id) {
                return setToastMessage({
                    message: T.screens.auth.googleSignInError,
                    type: NotificationType.Error
                })
            }

            setIsLoading(true)
            signInWithGoogleNative({
                tracking: { ...sessionData, anonymousId: await getAnonymousID() },
                token: idToken,
                accountId: user.id
            }, {
                onSuccess: ({ data }) => {
                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.GoogleSignIn
                    })

                    onAuthSuccess(data, AuthSegmentMethod.Google)
                    setIsLoading(false)
                },
                onError: async error => {
                    if (error.errors?.error_code !== ErrorCodes.UnhandledException) {
                        setIsLoading(false)

                        return setToastMessage({
                            message: getErrorMessage(error),
                            type: NotificationType.Error
                        })
                    }

                    // user has not signed up, redirect
                    setIsLoading(false)
                    setSocialSignInToken(idToken)
                }
            })
        }
    }
}
