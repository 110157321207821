import { APP_CONFIG } from 'lib/config'
import qs from 'qs'

export enum RootStack {
    Root = 'Root'
}

export enum NavigatorId {
    LeftDrawer = 'LeftDrawer',
    RightDrawer = 'RightDrawer',
    BottomTabs = 'BottomTabs'
}

// Rewrite this according to requirements
export enum ScreenNamesNative {
    // Page404 = '404',
    NativeRootLeftDrawer = 'NativeRootLeftDrawer',
    NativeRootRightDrawer = 'NativeRootRightDrawer',
    RootBottomTabs = 'RootBottomTabs',
    NativeHome = 'NativeHome',
    NativeGetStarted = 'NativeGetStarted',
    NativeRatingsScreen = 'NativeRatingsScreen',
    // NativeAuth = 'NativeAuth',
    User = 'User',
    UserNative = 'UserNative',
    UserDeleteAccount = 'UserDeleteAccount',
    UserAddress = 'UserAddress',
    AddressSetup = 'AddressSetup',
    UserPreferences = 'UserPreferences',
    UserNewAddress = 'UserNewAddress',
    UserEditAddress = 'UserEditAddress',
    // AuthForgotPassword = 'AuthForgotPassword',
    // AuthNewPassword = 'AuthNewPassword',
    // AuthSingleSignOn = 'AuthSingleSignOn',
    // AuthFinishSocialSignUp = 'AuthFinishSocialSignUp',
    Favourite = 'Favourite',
    Refer = 'Refer',
    UserCards = 'Cards',
    UserCardsNew = 'CardsNew',
    // todo for now not available
    // UserCardsHistory = 'CardsNewHistory',
    // OffersAndDeals = 'OffersAndDeals',
    Cleaning = 'Cleaning',
    CleaningNative = 'CleaningNative',
    CleaningPropertyDetailsNative = 'CleaningPropertyDetailsNative',
    CleaningServiceDetailsNative = 'CleaningServiceDetailsNative',
    CleaningDateTimeNative = 'CleaningDateTimeNative',
    CleaningServiceAddressNative = 'CleaningServiceAddressNative',
    CleaningSummaryNative = 'CleaningSummaryNative',
    CleaningConfirmationNative = 'CleaningConfirmationNative',
    CleaningServiceCustomiseScheduleNative = 'CleaningServiceCustomiseScheduleNative',
    PromoCodeNative = 'PromoCodeNative',
    PaymentMethodNative = 'PaymentMethodNative',
    OrdersNative = 'OrdersNative',
    Bookings = 'Bookings',
    BookingsCompleted = 'BookingsCompleted',
    BookingsCancelled = 'BookingsCancelled',
    BookingSuccess = 'BookingSuccess',
    Chat = 'BookingsChat',
    SendPay = 'SendPay',
    DeepCleaning = 'DeepCleaning',
    DeepCleaningSummary = 'DeepCleaningSummary',
    CleaningDetails = 'BookingsCleaningDetails',
    Notifications = 'Notifications',
    UnreadNotifications = 'UnreadNotifications',
    DeepCleaningDetails = 'DeepCleaningDetails',
    AirCondition = 'AirCondition',
    AirConditionSummary = 'AirConditionSummary',
    Summary = 'Summary',
    AllServices = 'AllServices',
    AllServicesPostcode = 'AllServicesPostcode',
    AirConditionDetails = 'AirConditionDetails',
    Handyman = 'Handyman',
    HandymanSummary = 'HandymanSummary',
    HandymanDetails = 'HandymanDetails',
    Laundry = 'Laundry',
    LaundrySummary = 'LaundrySummary',
    LaundryDetails = 'LaundryDetails',
    Cooking = 'Cooking',
    CookingSummary = 'CookingSummary',
    CookingDetails = 'CookingDetails',
    PestControl = 'PestControl',
    PestControlSummary = 'PestControlSummary',
    PestControlDetails = 'PestControlDetails',
    TaskAndErrands = 'TaskAndErrands',
    TaskAndErrandsSummary = 'TaskAndErrandsSummary',
    TaskAndErrandsDetails = 'TaskAndErrandsDetails',
    GrabPayWebView = 'GrabPayWebView',
    LogInNative = 'LogInNative',
    SignUpNative = 'SignUpNative',
    LandingNative = 'LandingNative',
    MarketingConsentNative = 'MarketingConsentNative',
    // AuthVerificationCodeNative = 'AuthVerificationCodeNative',
    // OnboardingNative = 'OnboardingNative',
    VerifyIdentityNative = 'VerifyIdentityNative',
    ResetPasswordNative = 'ResetPasswordNative',
    ForgotPasswordNative = 'ForgotPasswordNative',
    DashboardNative = 'DashboardNative',
    MoversAndPackers = 'MoversAndPackers',
    MoversAndPackersServiceDetails = 'MoversAndPackersServiceDetails',
    MoversAndPackersSummary = 'MoversAndPackersSummary',
    MoversAndPackersDetails = 'MoversAndPackersDetails',
    ContactUs = 'ContactUs',
    CancelJob = 'CancelJob',
}

export type DetailsScreensNames = ScreenNamesNative.CleaningDetails
| ScreenNamesNative.DeepCleaningDetails
| ScreenNamesNative.AirConditionDetails
| ScreenNamesNative.HandymanDetails
| ScreenNamesNative.LaundryDetails
| ScreenNamesNative.CookingDetails
| ScreenNamesNative.PestControlDetails
| ScreenNamesNative.TaskAndErrandsDetails
| ScreenNamesNative.MoversAndPackersDetails

export const ScreensConfig = {
    [ScreenNamesNative.NativeHome]: {
        path: 'native'
    },
    [ScreenNamesNative.LogInNative]: {
        path: 'login-native'
    },
    [ScreenNamesNative.SignUpNative]: {
        path: 'signup-native'
    },
    [ScreenNamesNative.ForgotPasswordNative]: {
        path: 'forgot-password-native'
    },
    [ScreenNamesNative.NativeGetStarted]: {
        path: 'get-started-native'
    },
    [ScreenNamesNative.DashboardNative]: {
        path: 'dashboard-native'
    },
    [ScreenNamesNative.NativeRatingsScreen]: {
        path: 'ratings-native'
    },
    // [ScreenNamesNative.Page404]: {
    //     path: '*'
    // },
    // [ScreenNamesNative.NativeAuth]: {
    //     path: 'native-auth',
    //     parse: {
    //         fromResetPassword: value => value === 'true'
    //     }
    // },
    [ScreenNamesNative.User]: {
        path: 'user'
    },
    [ScreenNamesNative.UserNative]: {
        path: 'user-native'
    },
    [ScreenNamesNative.UserDeleteAccount]: {
        path: 'user/delete-account'
    },
    [ScreenNamesNative.UserAddress]: {
        path: 'user/address'
    },
    [ScreenNamesNative.UserNewAddress]: {
        path: 'user/address/new'
    },
    [ScreenNamesNative.UserEditAddress]: {
        path: 'user/address/edit',
        stringify: {
            address: qs.stringify
        },
        parse: {
            form: qs.parse
        }
    },
    [ScreenNamesNative.UserPreferences]: {
        path: 'user/preferences'
    },
    // [ScreenNamesNative.AuthForgotPassword]: {
    //     path: 'auth/forgot-password'
    // },
    // [ScreenNamesNative.AuthNewPassword]: {
    //     path: 'auth/new-password'
    // },
    // [ScreenNamesNative.AuthSingleSignOn]: {
    //     path: 'auth/single-sign-on'
    // },
    [ScreenNamesNative.Favourite]: {
        path: 'favourite'
    },
    [ScreenNamesNative.Refer]: {
        path: 'refer'
    },
    [ScreenNamesNative.UserCards]: {
        path: 'cards'
    },
    [ScreenNamesNative.UserCardsNew]: {
        path: 'cards/new'
    },
    // todo for now not available
    // [ScreenNamesNative.UserCardsHistory]: {
    //     path: 'cards/history'
    // },
    // [ScreenNamesNative.OffersAndDeals]: {
    //     path: 'offers'
    // },
    [ScreenNamesNative.Cleaning]: {
        path: 'bookings/cleaning'
    },
    [ScreenNamesNative.CleaningNative]: {
        path: 'bookings-native/cleaning'
    },
    [ScreenNamesNative.CleaningPropertyDetailsNative]: {
        path: 'bookings-native/cleaning/property-details'
    },
    [ScreenNamesNative.CleaningServiceDetailsNative]: {
        path: 'bookings-native/cleaning/service-details'
    },
    [ScreenNamesNative.CleaningDateTimeNative]: {
        path: 'bookings-native/cleaning/date-and-time'
    },
    [ScreenNamesNative.CleaningServiceAddressNative]: {
        path: 'bookings-native/cleaning/service-address'
    },
    [ScreenNamesNative.CleaningSummaryNative]: {
        path: 'bookings-native/cleaning/summary'
    },
    [ScreenNamesNative.CleaningConfirmationNative]: {
        path: 'bookings-native/cleaning/confirmation'
    },
    [ScreenNamesNative.CleaningServiceCustomiseScheduleNative]: {
        path: 'bookings-native/cleaning/customise-schedule'
    },
    [ScreenNamesNative.PromoCodeNative]: {
        path: 'bookings-native/promo-code'
    },
    [ScreenNamesNative.PaymentMethodNative]: {
        path: 'bookings-native/payment-method'
    },
    [ScreenNamesNative.OrdersNative]: {
        path: 'orders-native'
    },
    [ScreenNamesNative.Bookings]: {
        path: 'bookings'
    },
    [ScreenNamesNative.BookingsCompleted]: {
        path: 'bookings/completed'
    },
    [ScreenNamesNative.BookingsCancelled]: {
        path: 'bookings/cancelled'
    },
    [ScreenNamesNative.BookingSuccess]: {
        path: 'booking/success'
    },
    [ScreenNamesNative.Chat]: {
        path: 'bookings/chat'
    },
    [ScreenNamesNative.SendPay]: {
        path: 'send-pay'
    },
    [ScreenNamesNative.DeepCleaning]: {
        path: 'bookings/deep-cleaning'
    },
    [ScreenNamesNative.DeepCleaningSummary]: {
        path: 'bookings/deep-cleaning/summary',
        stringify: {
            form: qs.stringify,
            servicePrice: String,
            price: String,
            supplyId: String
        },
        parse: {
            form: qs.parse,
            servicePrice: Number,
            price: Number,
            supplyId: Number
        }
    },
    [ScreenNamesNative.Notifications]: {
        path: 'notifications'
    },
    [ScreenNamesNative.UnreadNotifications]: {
        path: 'notifications/unread'
    },
    [ScreenNamesNative.CleaningDetails]: {
        path: 'bookings/details/cleaning',
        parse: {
            jobStatus: Number,
            recurringJob: qs.parse,
            isSession: value => value === 'true'
        },
        stringify: {
            jobStatus: String,
            recurringJob: qs.stringify
        }
    },
    [ScreenNamesNative.DeepCleaningDetails]: {
        path: 'bookings/details/deep-cleaning',
        parse: {
            jobStatus: Number
        },
        stringify: {
            jobStatus: String
        }
    },
    [ScreenNamesNative.AirCondition]: {
        path: 'bookings/air-condition'
    },
    [ScreenNamesNative.AirConditionSummary]: {
        path: 'bookings/air-condition/summary',
        stringify: {
            form: qs.stringify,
            price: String,
            supplyId: String
        },
        parse: {
            form: qs.parse,
            price: Number,
            supplyId: Number
        }
    },
    [ScreenNamesNative.AirConditionDetails]: {
        path: 'bookings/details/air-condition',
        parse: {
            jobStatus: Number
        },
        stringify: {
            jobStatus: String
        }
    },
    [ScreenNamesNative.AllServices]: {
        path: 'all-services'
    },
    [ScreenNamesNative.AllServicesPostcode]: {
        path: 'all-services/postcode'
    },
    [ScreenNamesNative.ContactUs]: {
        path: 'contact-us'
    },
    [ScreenNamesNative.CancelJob]: {
        path: 'bookings/details/cancel-job',
        parse: {
            jobId: String
        },
        stringify: {
            jobId: String
        }
    },
    ...!APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED
        ? {}
        : {
            [ScreenNamesNative.Handyman]: {
                path: 'bookings/handyman'
            },
            [ScreenNamesNative.HandymanSummary]: {
                path: 'bookings/handyman/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNamesNative.HandymanDetails]: {
                path: 'bookings/details/handyman',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED
        ? {}
        : {
            [ScreenNamesNative.Laundry]: {
                path: 'bookings/laundry'
            },
            [ScreenNamesNative.LaundrySummary]: {
                path: 'bookings/laundry/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNamesNative.LaundryDetails]: {
                path: 'bookings/details/laundry',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_COOKING_ENABLED
        ? {}
        : {
            [ScreenNamesNative.Cooking]: {
                path: 'bookings/cooking'
            },
            [ScreenNamesNative.CookingSummary]: {
                path: 'bookings/cooking/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNamesNative.CookingDetails]: {
                path: 'bookings/details/cooking',
                parse: {
                    jobStatus: Number,
                    recurringJob: qs.parse,
                    isSession: value => value === 'true'
                },
                stringify: {
                    jobStatus: String,
                    recurringJob: qs.stringify
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED
        ? {}
        : {
            [ScreenNamesNative.PestControl]: {
                path: 'bookings/pest-control'
            },
            [ScreenNamesNative.PestControlSummary]: {
                path: 'bookings/pest-control/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNamesNative.PestControlDetails]: {
                path: 'bookings/details/pest-control',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED
        ? {}
        : {
            [ScreenNamesNative.TaskAndErrands]: {
                path: 'bookings/task-and-errands'
            },
            [ScreenNamesNative.TaskAndErrandsSummary]: {
                path: 'bookings/task-and-errands/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNamesNative.TaskAndErrandsDetails]: {
                path: 'bookings/details/task-and-errands',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_MOVERS_AND_PACKERS_ENABLED
        ? {}
        : {
            [ScreenNamesNative.MoversAndPackers]: {
                path: 'bookings/movers-and-packers'
            },
            [ScreenNamesNative.MoversAndPackersSummary]: {
                path: 'bookings/movers-and-packers/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNamesNative.MoversAndPackersDetails]: {
                path: 'bookings/details/movers-and-packers',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        }
}
