import React, { useCallback, useMemo, useState } from 'react'
import { NativeSyntheticEvent, TextInputChangeEventData, View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { TextInput } from '@propertyguru/hive-text-input'
import { AccessibilityEyeA11YOutline, ShowEyeSeeRevealLookVisibleOutline } from '@propertyguru/hive-icons'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

export const TextInputField: React.FunctionComponent<{
    value: string;
    onChange: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
    errorMessage: string;
    placeholder: string;
    focusedMessage?: string;
    secureTextEntry?: boolean;
    accessibilityLabel?: string;
    errorAccessibilityLabel?: string;
    onBlur?: () => void;
    onFocus?: () => void;
}> = React.memo(({ value, onChange, errorMessage, placeholder, secureTextEntry, focusedMessage, onBlur, onFocus, accessibilityLabel, errorAccessibilityLabel }) => {
    const { styles } = useStyles(stylesheet)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [isShowSecureText, setIsShowSecureText] = useState(!secureTextEntry)

    const shouldShowError = useMemo(() => Boolean(errorMessage), [errorMessage])
    const shouldShowFocusedMessage = useMemo(
        () => Boolean(focusedMessage) && isFocused,
        [focusedMessage, isFocused]
    )

    const handleFocus = useCallback(() => {
        setIsFocused(true)
        onFocus?.()
    }, [])

    const handleBlur = useCallback(() => {
        setIsFocused(false)
        onBlur?.()
    }, [onBlur])

    const onTogglePasswordVisibility = useCallback(() => {
        setIsShowSecureText(prev => !prev)
    }, [])

    return (
        <View style={styles.inputContainer}>
            <TextInput
                onFocus={handleFocus}
                onBlur={handleBlur}
                secureTextEntry={!isShowSecureText}
                onChange={onChange}
                value={value}
                error={shouldShowError}
                placeholder={placeholder}
                style={styles.textInput}
                size="default"
                endIcon={secureTextEntry ? (isShowSecureText ? ShowEyeSeeRevealLookVisibleOutline : AccessibilityEyeA11YOutline) : undefined}
                onEndIconPress={secureTextEntry ? onTogglePasswordVisibility : undefined}
                accessibilityLabel={accessibilityLabel}
            />
            {shouldShowError && (
                <Text color="text/error/primary" typography="caption/xs" style={styles.errorMessage} accessibilityLabel={errorAccessibilityLabel}>
                    {errorMessage}
                </Text>
            )}
            {shouldShowFocusedMessage && !shouldShowError && (
                <Text color="text/active/secondary" typography="caption/xs" style={styles.errorMessage}>
                    {focusedMessage}
                </Text>
            )}
        </View>
    )
})

const stylesheet = createStyles(theme => ({
    inputContainer: {
        width: '100%',
        marginTop: theme.utils.gap(1)
    },
    textInput: {
        width: '100%'
    },
    errorMessage: {
        marginTop: theme.utils.gap(0.5)
    }
}))
