import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'
import { JobDetailsResponse } from 'features/jobs/types'
import { isNative } from 'lib/common'
import { CustomFonts } from 'lib/types'
import { PlanRescheduleRequest, SessionRescheduleRequest } from 'lib/models/Job'
import { OneOffBookingTime } from './OneOffBookingTime'
import { SubscriptionBookingTime } from './SubscriptionBookingTime'

type RescheduleRequestDetailModalProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    jobDetail: JobDetailsResponse,
}

export const RescheduleRequestDetailModal: React.FunctionComponent<RescheduleRequestDetailModalProps> = ({
    isOpen,
    onClose,
    jobDetail
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isSessionJob = jobDetail?.job?.recurring_job

    const rescheduleRequest = isSessionJob ?
        (jobDetail?.job?.sessionRescheduleRequest) as SessionRescheduleRequest:
        (jobDetail?.job?.planRescheduleRequest) as PlanRescheduleRequest

    const popupTitle = rescheduleRequest?.popup?.title || T.screens.jobDetails.rescheduleRequestDetailModal.title

    return (
        <CustomModal
            testID={T.accessibility.jobDetailsScreen.rescheduleRequestDetail.modal}
            onClose={onClose}
            isOpen={isOpen}
            closeButtonStyles={styles.closeButton}
            contentStyles={styles.modalContent}
            childrenWrapper={styles.modalChildrenWrapper}
            customScrollViewStyles={styles.customScrollViewStyles}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.headerContainer}>
                    <Typography.Title style={styles.title} testID={T.accessibility.jobDetailsScreen.rescheduleRequestDetail.title}>
                        {popupTitle}
                    </Typography.Title>
                    <View style={styles.modalActionContainer}>
                        <Touchable
                            testID={T.accessibility.jobDetailsScreen.rescheduleRequestDetail.close}
                            onPress={onClose}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}>
                            <Icons.Close size={isNative ? 14 : 18} />
                        </Touchable>
                    </View>
                </View>
            </View>

            <View style={styles.container}>
                <View style={styles.containerRow}>
                    <Typography.Regular bold style={styles.label}>
                        {T.screens.jobDetails.rescheduleRequestDetailModal.requestDate}:
                    </Typography.Regular>
                    {
                        isSessionJob ?
                            <OneOffBookingTime rescheduleRequest={rescheduleRequest as SessionRescheduleRequest} />:
                            <SubscriptionBookingTime frequency={jobDetail?.job?.frequency} rescheduleRequest={rescheduleRequest as PlanRescheduleRequest} />

                    }
                </View>
                <View style={styles.containerRow}>
                    <Typography.Regular bold style={styles.label}>
                        {T.screens.jobDetails.rescheduleRequestDetailModal.currentDate}:
                    </Typography.Regular>
                    {
                        isSessionJob  ?
                            <OneOffBookingTime rescheduleRequest={rescheduleRequest as SessionRescheduleRequest} isCurrentTime/>:
                            <SubscriptionBookingTime frequency={jobDetail?.job?.frequency} rescheduleRequest={rescheduleRequest as PlanRescheduleRequest} isCurrentTime/>
                    }
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center',
        flex: 1
    },
    title: {
        textAlign: 'left',
        fontSize: isNative ? 17 : 20,
        lineHeight: 30,
        fontFamily: CustomFonts.Poppins600,
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces',
        width: isNative ? '90%' : undefined
    },
    repeatDay: {
        fontWeight: 'bold',
        fontSize: 13,
        color: theme.colors.darkGrey
    },
    frequencyNote: {
        color: theme.colors.darkGrey,
        marginTop: theme.utils.gap(1),
        fontSize: 13
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: theme.utils.gap(isNative ? 1 : 1.5),
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.fog,
        paddingTop: theme.utils.gap(0.5),
        width: '100%'
    },
    modalActionContainer: {
        paddingLeft: theme.utils.gap(1)
    },
    closeButton: {
        display: 'none'
    },
    modalContent:{
        paddingVertical: 0,
        paddingTop: theme.utils.gap(1),
        paddingBottom: theme.utils.gap(2.5),
        padding: 0
    },
    modalChildrenWrapper: {
        paddingTop: 0,
        marginTop: theme.utils.gap(isNative ? 0.5 : 1.75),
        width: {
            lg: 600,
            md: 500,
            sm: 400,
            xs: '100%'
        }
    },
    customScrollViewStyles: {
        marginTop: theme.utils.gap(2)
    },
    label: {
        color: theme.colors.night
    },
    container: {
        paddingTop: theme.utils.gap(0)
    },
    containerRow: {
        marginTop: theme.utils.gap(2)
    }
}))
