import React from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { ChecklistBoxCheckListNotificationOutline } from '@propertyguru/hive-icons'
import { Icon } from '@propertyguru/hive-icon'
import { Modal } from '@propertyguru/hive-modal'
import { Text } from '@propertyguru/hive-text'
import { AlertV2, NativeModal, Table } from 'lib/native/components'
import { useTranslations } from 'lib/hooks'

type Props = {
    isOpen: boolean
    onClose: () => void
}

export const IroningGuideModal: React.FunctionComponent<Props> = ({ isOpen, onClose }) => {
    const T = useTranslations()
    const hive = useHive()
    const tableHeaderCols = [
        T.components.ironingGuideModal.table.header.garments,
        T.components.ironingGuideModal.table.header.ironingTime
    ]
    const tableBodyRows = [
        {
            label: T.components.ironingGuideModal.table.body.rowOneLabel,
            value: T.components.ironingGuideModal.table.body.rowOneValue
        },
        {
            label: T.components.ironingGuideModal.table.body.rowTwoLabel,
            value: T.components.ironingGuideModal.table.body.rowTwoValue
        },
        {
            label: T.components.ironingGuideModal.table.body.rowThreeLabel,
            value: T.components.ironingGuideModal.table.body.rowThreeValue
        },
        {
            label: T.components.ironingGuideModal.table.body.rowFourLabel,
            value: T.components.ironingGuideModal.table.body.rowFourValue
        }
    ]

    return (
        <NativeModal fullWidth isOpen={isOpen} onClose={onClose}>
            <Modal>
                <Modal.Header>
                    <Modal.Header.Title>
                        {T.components.ironingGuideModal.title}
                    </Modal.Header.Title>
                    <Modal.Header.Button onPress={onClose} />
                </Modal.Header>
                <Modal.Body>
                    <View style={{ rowGap: hive.spacing('x6') }}>
                        <Text typography="body/s" color="text/active/secondary">
                            {T.components.ironingGuideModal.description}
                        </Text>
                        <Table>
                            <Table.Header>
                                {tableHeaderCols.map((header, index) => (
                                    <Table.Header.Item key={index}>
                                        <Text typography="label/s" color="text/active/secondary">
                                            {header}
                                        </Text>
                                    </Table.Header.Item>
                                ))}
                            </Table.Header>
                            <Table.Body>
                                {tableBodyRows.map((row, index) => (
                                    <Table.Body.Row key={index}>
                                        <Table.Body.Row.Item>
                                            <Text typography="label/xs" color="text/active/secondary">
                                                {row.label}
                                            </Text>
                                        </Table.Body.Row.Item>
                                        <Table.Body.Row.Item>
                                            <Text typography="caption/m" color="text/active/secondary">
                                                {row.value}
                                            </Text>
                                        </Table.Body.Row.Item>
                                    </Table.Body.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        <Text typography="body/xs" color="text/active/secondary">
                            {T.components.ironingGuideModal.note}
                        </Text>
                        <AlertV2>
                            <AlertV2.Icon>
                                <Icon icon={ChecklistBoxCheckListNotificationOutline} color="icon/warning/primary" width={24} height={24} />
                            </AlertV2.Icon>
                            <AlertV2.Body>
                                <Text typography="caption/xs" color="text/warning/primary">
                                    {T.components.ironingGuideModal.reminderPartOne}{' '}
                                    <Text typography="caption/s" color="text/warning/primary">
                                        {T.components.ironingGuideModal.reminderPartTwo}
                                    </Text>{' '}
                                    {T.components.ironingGuideModal.reminderPartThree}
                                </Text>
                            </AlertV2.Body>
                        </AlertV2>
                    </View>
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}
