import React from 'react'
import { View, Image } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Text } from '@propertyguru/hive-text'
import { Touchable } from 'lib/components'
import { BubbleMessageQuestionMarkTooltipOutline, WhatsappBw } from '@propertyguru/hive-icons'
import { Icon } from '@propertyguru/hive-icon'
import { Images } from 'assets'
import { linkingHelpers } from 'lib/utils'
import { Surface } from '@propertyguru/hive-surface'
import { Segment, SegmentNative } from 'lib/analytics'

export const SupportSection: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()

    const handleWhatsappTap = () => {
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.TalkToUsClicked })
        linkingHelpers.openUrl(T.screens.dashboard.supportSection.whatsappUrl)
    }

    const handleFaqTap = () => {
        Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.FAQClicked })
        linkingHelpers.openUrl(T.screens.dashboard.supportSection.faqUrl)
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={styles.headerText}>
                    <Text typography="label/l">{T.screens.dashboard.supportSection.heading}</Text>
                    <Text typography="label/xs">{T.screens.dashboard.supportSection.subHeading}</Text>
                </View>
                <Image source={Images.CustomerSupport} style={styles.image} />
            </View>
            <Surface elevation="soft/down/medium">
                <View style={styles.buttonsWrapper}>
                    <Touchable
                        testID={T.accessibility.dashboard.support.talk}
                        onPress={handleWhatsappTap}
                        style={styles.button}
                    >
                        <Icon icon={WhatsappBw} />
                        <Text typography="label/m">{T.screens.dashboard.supportSection.talkCta}</Text>
                    </Touchable>
                    <Touchable
                        testID={T.accessibility.dashboard.support.faq}
                        onPress={handleFaqTap}
                        style={styles.button}
                    >
                        <Icon icon={BubbleMessageQuestionMarkTooltipOutline} />
                        <Text typography="label/m">{T.screens.dashboard.supportSection.faqCta}</Text>
                    </Touchable>
                </View>
            </Surface>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        padding: theme.utils.hive.spacing('x4')
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    image: {
        height: 73,
        width: 102
    },
    headerText: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.utils.hive.spacing('x1')
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: 0,
        backgroundColor: theme.colors.white,
        borderRadius: theme.utils.hive.borderRadius('medium')
    },
    button: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        paddingVertical: theme.utils.hive.spacing('x6'),
        gap: theme.utils.hive.spacing('x2')
    }
}))
