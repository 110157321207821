import React, { useEffect, useRef } from 'react'
import { View, Animated, Easing } from 'react-native'
import { Icon } from '@propertyguru/hive-icon'
import { LoaderOutline } from '@propertyguru/hive-icons'

export const SpinningIcon = ({ icon = LoaderOutline }) => {
    const spinValue = useRef(new Animated.Value(0)).current

    useEffect(() => {
        Animated.loop(
            Animated.timing(spinValue, {
                toValue: 1,
                duration: 2000,
                easing: Easing.linear,
                useNativeDriver: true
            })
        ).start()
    }, [spinValue])

    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg']
    })

    return (
        <View>
            <Animated.View style={{ transform: [{ rotate: spin }] }}>
                <Icon
                    width={24}
                    height={24}
                    icon={icon}
                />
            </Animated.View>
        </View>
    )
}
