import { PromotionCodeJobData } from 'lib/models'
import { useBookingPromoCode } from './useBookingPromoCode'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useCleaningTotalPrice = (jobData: PromotionCodeJobData) => {
    const { isLoading: isLoadingPromotion, requestPromotion, promotionPackage, removePromoCode } = useBookingPromoCode()

    return {
        isLoadingPromotion,
        promotionPackage,
        removePromoCode,
        requestPromotion
    }
}
