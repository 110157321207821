import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
    NotificationsScreen,
    RatingsScreen,
    SendPayScreen
} from 'features/native/home'
import { NativeAppStackParams } from 'lib/native/routing/stackParams'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { BottomTabsStack } from './BottomTabsStack'

const Stack = createNativeStackNavigator<NativeAppStackParams>()

export const AuthorizedNativeStack = () => (
    <React.Fragment>
        <Stack.Screen
            name={ScreenNamesNative.RootBottomTabs}
            component={BottomTabsStack}
        />
        <Stack.Screen
            name={ScreenNamesNative.NativeRatingsScreen}
            component={RatingsScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.Notifications}
            component={NotificationsScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.SendPay}
            component={SendPayScreen}
        />
    </React.Fragment>
)
