import React, { useState } from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { VoucherRedemptionModal, VoucherRedeemedModal } from 'features/sendPay/components'

type VoucherRedemptionProps = object

export const VoucherRedemption: React.FunctionComponent<VoucherRedemptionProps> = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isVoucherRedemptionModalOpen, setIsVoucherRedemptionModalOpen] = useState<boolean>(false)
    const [isVoucherRedeemedModalOpen, setIsVoucherRedeemedModalOpen] = useState<boolean>(false)

    const onVoucherRedeemed = () => {
        setIsVoucherRedemptionModalOpen(false)
        setIsVoucherRedeemedModalOpen(true)
    }

    return (
        <View>
            <View style={styles.formContainer}>
                <Typography.Regular style={styles.question}>
                    {T.components.sendPayModal.voucherRedemption.haveAVoucher}
                    <Touchable onPress={() => setIsVoucherRedemptionModalOpen(true)}>
                        <Typography.Regular style={styles.redeem}>
                            {` ${T.components.sendPayModal.voucherRedemption.redeemNow}`}
                        </Typography.Regular>
                    </Touchable>
                </Typography.Regular>
            </View>
            <VoucherRedemptionModal
                isOpen={isVoucherRedemptionModalOpen}
                onClose={() => setIsVoucherRedemptionModalOpen(false)}
                onVoucherRedeemed={onVoucherRedeemed}
            />
            <VoucherRedeemedModal
                isOpen={isVoucherRedeemedModalOpen}
                onClose={() => setIsVoucherRedeemedModalOpen(false)}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    formContainer: {
        maxWidth: 800,
        paddingHorizontal: theme.utils.gap(1)
    },
    question: {
        fontFamily: CustomFonts.Roboto400,
        fontSize: 16,
        lineHeight: 22
    },
    redeem: {
        fontFamily: CustomFonts.Roboto500,
        fontSize: 16,
        lineHeight: 22,
        color: theme.colors.orange,
        marginTop: theme.utils.gap(0.5)/2
    }
}))
