import React, { useEffect, useState } from 'react'
import { View, TextInput as TextInputForWeb } from 'react-native'
import { AvoidSoftInputView } from 'react-native-avoid-softinput'
import { useHive } from '@propertyguru/hive'
import { Modal } from '@propertyguru/hive-modal'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { Icon } from '@propertyguru/hive-icon'
import { TextInput } from '@propertyguru/hive-text-input'
import {
    CheckRadioCircleCheckboxCheckCheckmarkConfirmOutline,
    CrossedSmallDeleteRemoveOutline,
    ErrorWarningAlertOutline
} from '@propertyguru/hive-icons'
import { useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { isNative } from 'lib/common'
import { Segment } from 'lib/analytics'
import { Breakpoint, theme } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Button as ButtonForWeb, OTPInput, Typography } from 'lib/components'
import { useNativeUserTokenAtom } from 'lib/native/atoms'
import { NativeModal } from 'lib/native/components'
import { useNativeUserAtom } from 'lib/native/atoms/user'
import { OTPInputs } from 'features/native/onboarding/components'
import { useSendMobileOtp, useVerifyMobileOtp } from 'features/native/auth/actions'
import { ElementViewedElementType } from 'lib/analytics/segment/typewriter/segmentNative'
import { stylesheet } from './MobileOtpModal.styles'
import { OtpResendState, OtpState } from '../constants'

type Props = {
    isOpen: boolean
    onClose: () => void
    onOtpVerify: () => void
}

const INPUT_LENGTH = 5

export const MobileOtpModal: React.FunctionComponent<Props> = ({ isOpen, onClose, onOtpVerify }) => {
    const T = useTranslations()
    const hive = useHive()
    const { styles } = useStyles(stylesheet)
    const HitSlop = { top: 24, bottom: 24, left: 24, right: 24 }
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const [tokenForWeb] = useUserTokenAtom()
    const [tokenForNativeApp] = useNativeUserTokenAtom()
    const [modalState, setModalState] = useState(OtpState.MOBILE)
    const [modalTitle, setModalTitle] = useState(T.components.mobileOtpModal.title.addNumber)
    const [modalCta, setModalCta] = useState(T.components.mobileOtpModal.cta.continue)
    const [mobileInput, setMobileInput] = useState('')
    const [mobileError, setMobileError] = useState('')
    const [countryCodeInput, setCountryCodeInput] = useState('+65')
    const [countryCodeError, setCountryCodeError] = useState('')
    const [pinInput, setPinInput] = useState<Array<string>>(new Array(INPUT_LENGTH).fill(''))
    const [pinError, setPinError] = useState('')
    const [otpResendStatus, setOtpResendStatus] = useState(OtpResendState.INITIAL)
    const [otpAlertText, setOtpAlertText] = useState('')
    const [userForWeb, setUserForWeb] = useUserAtom()
    const [user, setUser] = useNativeUserAtom()
    const { mutate: sendMobileOtp, isLoading: isSending } = useSendMobileOtp()
    const [otpResponse, setOtpResponse] = useState({ otpId: '' })
    const { mutate: verifyMobileOtp, isLoading: isVerifying } = useVerifyMobileOtp()
    const isLoading = isSending || isVerifying
    const shouldRenderMobileView = isNative || isMobile
    const token = tokenForNativeApp || tokenForWeb
    const email = user.email_id || userForWeb?.email_id

    useEffect(() => {
        if (isOpen && !token) {
            onClose()
        }
    }, [token, isOpen])

    const onCtaPress = () => {
        if (!mobileInput) {
            setMobileError(T.components.mobileOtpModal.validations.pleaseEnterMobileNumber)

            return
        }

        if (modalState === OtpState.MOBILE) {
            const payload = {
                phone: `${countryCodeInput.slice(1)}${mobileInput}`,
                email,
                token
            }

            sendMobileOtp(payload,
                {
                    onSuccess: response => {
                        setOtpResponse(response.data)
                        setModalState(OtpState.OTP)
                        setModalTitle(T.components.mobileOtpModal.title.verifyNumber)
                        setModalCta(T.components.mobileOtpModal.cta.verify)
                    },
                    onError: error => {
                        if (error.errors.error_code === 'PHONE_OTP_COUNTRY_CODE_NOT_SUPPORTED') {
                            setCountryCodeError(error.errors.error_msg)

                            Segment.oTPErrorOccured && Segment.oTPErrorOccured({ error: error.errors.error_msg })

                            return
                        }

                        Segment.oTPErrorOccured && Segment.oTPErrorOccured({ error: error.errors.error_msg })
                        setMobileError(error.errors.error_msg)

                        return
                    }
                }
            )
        }

        if (modalState === OtpState.OTP) {
            Segment.elementViewed && Segment.elementViewed({ elementType: ElementViewedElementType.PhoneOtpModalViewed })
            setOtpResendStatus(OtpResendState.INITIAL)

            const phone = `${countryCodeInput.slice(1)}${mobileInput}`

            // eslint-disable-next-line nested-if/nested-if-statements
            if (pinInput.length < 5) {
                setPinError(T.components.mobileOtpModal.validations.pleaseEnterOtp)

                return
            }

            const payload = {
                phone,
                email,
                token,
                otpId: otpResponse.otpId,
                otp: pinInput.join('')
            }

            verifyMobileOtp(
                payload,
                {
                    onSuccess: () => {
                        setModalState(OtpState.VALID)
                        setUser(prev => ({ ...prev, phone }))
                        setUserForWeb(prev => ({ ...prev, phone }))
                        onClose()
                        onOtpVerify()
                    },
                    onError: error => {
                        setPinError(error.errors.error_msg)
                        Segment.oTPErrorOccured && Segment.oTPErrorOccured({ error: error.errors.error_msg })
                    }
                }
            )
        }
    }

    const onCountryCodeChange = (value: string) => {
        setCountryCodeError('')
        const withPrefix = value.startsWith('+') ? value : `+${value}`
        setCountryCodeInput(withPrefix)
    }

    const onMobileChange = (value: string) => {
        setMobileError('')
        setMobileInput(value)
    }

    const onPinChange = (value: Array<string>) => {
        setPinError('')
        setPinInput(value)
    }

    const onOtpChangeNumber = () => {
        setModalState(OtpState.MOBILE)
        setModalCta(T.components.mobileOtpModal.cta.continue)
    }

    const onResendOtp = () => {
        const payload = {
            phone: `${countryCodeInput.slice(1)}${mobileInput}`,
            email,
            token
        }

        sendMobileOtp(
            payload,
            {
                onSuccess: () => {
                    setOtpResendStatus(OtpResendState.SUCCESS)
                    setOtpAlertText(T.components.mobileOtpModal.otp.alert.success)
                },
                onError: error => {
                    // TODO: pending design for error message
                    setOtpResendStatus(OtpResendState.FAILURE)
                    setOtpAlertText(T.components.mobileOtpModal.otp.alert.failure)
                    Segment.oTPErrorOccured && Segment.oTPErrorOccured({ error: error?.errors?.error_msg })
                }
            }
        )
    }

    const onResendAlertClose = () => {
        setOtpResendStatus(OtpResendState.INITIAL)
    }

    return (
        <NativeModal
            fullWidth={shouldRenderMobileView}
            isOpen={isOpen}
            onClose={onClose}
            scrollViewContainerStyles={styles.modalStyle}
            wrapperStyles={styles.modalWrapperStyle}
        >
            <AvoidSoftInputView>
                <Modal>
                    <Modal.Header>
                        <Modal.Header.Title>{modalTitle}</Modal.Header.Title>
                        <Modal.Header.Button onPress={onClose} />
                    </Modal.Header>
                    <Modal.Body style={styles.modalBody}>
                        <View style={styles.mobileWrapper}>
                            {modalState === OtpState.MOBILE && (
                                <>
                                    <Text typography={'body/s'} style={styles.description}>
                                        {T.components.mobileOtpModal.description.reason}
                                    </Text>
                                    <View>
                                        <View style={styles.mobileInputWrapper}>
                                            {
                                                isNative ? (
                                                    <>
                                                        <TextInput
                                                            placeholder={'+'}
                                                            value={countryCodeInput}
                                                            onChangeText={onCountryCodeChange}
                                                            style={styles.countryCodeInput}
                                                            error={countryCodeError !== ''}
                                                            size="default"
                                                            inputMode="numeric"
                                                            keyboardType="phone-pad"
                                                            accessibilityLabel={
                                                                T.accessibility.native.mobileOtpModal.countryCodeInput
                                                            }
                                                        />
                                                        <TextInput
                                                            placeholder={'mobile number'}
                                                            value={mobileInput}
                                                            onChangeText={onMobileChange}
                                                            style={styles.mobileInput}
                                                            error={mobileError !== ''}
                                                            size="default"
                                                            inputMode="numeric"
                                                            keyboardType="phone-pad"
                                                            accessibilityLabel={T.accessibility.native.mobileOtpModal.mobileInput}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <TextInputForWeb
                                                            placeholder={'+'}
                                                            value={countryCodeInput}
                                                            style={{
                                                                ...styles.borderedInput,
                                                                ...styles.countryCodeInputForWeb
                                                            }}
                                                            placeholderTextColor={theme.colors.fog}
                                                            onChangeText={onCountryCodeChange}
                                                            editable={!isLoading}
                                                            inputMode="numeric"
                                                            keyboardType="phone-pad"
                                                            accessibilityLabel={
                                                                T.accessibility.native.mobileOtpModal.countryCodeInput
                                                            }
                                                        />
                                                        <TextInputForWeb
                                                            placeholder={'mobile number'}
                                                            value={mobileInput}
                                                            style={{
                                                                ...styles.borderedInput,
                                                                ...styles.mobileInputForWeb
                                                            }}
                                                            placeholderTextColor={theme.colors.fog}
                                                            onChangeText={onMobileChange}
                                                            editable={!isLoading}
                                                            inputMode="numeric"
                                                            keyboardType="phone-pad"
                                                            accessibilityLabel={T.accessibility.native.mobileOtpModal.mobileInput}
                                                        />
                                                    </>
                                                )
                                            }

                                        </View>
                                        {(countryCodeError !== '' || mobileError !== '') && (
                                            <View style={styles.errorMobileWrapper}>
                                                {countryCodeError !== '' && (
                                                    <Text typography="caption/xs" style={styles.errorMessage}>
                                                        {countryCodeError}
                                                    </Text>
                                                )}
                                                {mobileError !== '' && (
                                                    <Text typography="caption/xs" style={styles.errorMessage}>
                                                        {mobileError}
                                                    </Text>
                                                )}
                                            </View>
                                        )}
                                    </View>
                                </>
                            )}
                            {modalState === OtpState.OTP && (
                                <>
                                    <Text
                                        typography={'label/m'}
                                        style={{ ...styles.otpTitle, color: hive.color('fill/inactive/secondary') }}
                                    >
                                        {T.components.mobileOtpModal.otp.title}
                                    </Text>
                                    <Text typography={'body/s'} style={styles.description}>
                                        {T.components.mobileOtpModal.otp.description}
                                    </Text>
                                    <View style={styles.otpMobileWrapper}>
                                        <Text
                                            typography={'label/m'}
                                            color={'text/active/primary'}
                                            style={styles.otpMobile}
                                        >
                                            {countryCodeInput} {mobileInput.slice(0, 4)} {mobileInput.slice(4)}
                                        </Text>
                                        <Touchable onPress={onOtpChangeNumber}>
                                            <Text
                                                typography={'body/xs'}
                                                color={'text/active/primary'}
                                                style={styles.fontWeightNormal}
                                                underline
                                            >
                                                {T.components.mobileOtpModal.otp.change}
                                            </Text>
                                        </Touchable>
                                    </View>
                                    <View>
                                        {
                                            isNative ? (
                                                <OTPInputs
                                                    value={pinInput}
                                                    length={5}
                                                    onChange={onPinChange}
                                                    disabled={false}
                                                    placeholder={'0'}
                                                    errorMessage={pinError}
                                                />
                                            ): (
                                                <View>
                                                    <OTPInput
                                                        value={pinInput}
                                                        length={5}
                                                        onChange={onPinChange}
                                                        disabled={false}
                                                        error={Boolean(pinError)}
                                                        customInputStyle={styles.customInputStyle}
                                                    />
                                                    <Typography.Error style={styles.pinError} forceColor={theme.colors.flame}>
                                                        {pinError}
                                                    </Typography.Error>
                                                </View>
                                            )
                                        }
                                    </View>
                                </>
                            )}
                            {
                                isNative ? (
                                    <Button
                                        text={modalCta}
                                        disabled={isLoading}
                                        loading={isLoading}
                                        onPress={onCtaPress}
                                    />
                                ): (
                                    <ButtonForWeb
                                        text={modalCta}
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                        onPress={onCtaPress}
                                        buttonStyles={styles.buttonForWeb}
                                    />
                                )}

                            {modalState === OtpState.OTP && (
                                <View>
                                    <Text
                                        typography={'body/xs'}
                                        color={'text/active/secondary'}
                                        style={styles.fontWeightNormal}
                                    >
                                        {T.components.mobileOtpModal.otp.validity}
                                    </Text>
                                    <View style={styles.otpResendWrapper}>
                                        <Text
                                            typography={'body/xs'}
                                            color={'text/active/secondary'}
                                            style={styles.fontWeightNormal}
                                        >
                                            {T.components.mobileOtpModal.otp.issue}
                                        </Text>
                                        <Touchable onPress={onResendOtp}>
                                            <Text
                                                typography={'body/xs'}
                                                color={'text/active/primary'}
                                                style={styles.fontWeightNormal}
                                                underline
                                            >
                                                {T.components.mobileOtpModal.otp.resend}
                                            </Text>
                                        </Touchable>
                                    </View>
                                </View>
                            )}
                            {otpResendStatus !== OtpResendState.INITIAL && (
                                <View
                                    style={{
                                        ...styles.otpResendAlert,
                                        backgroundColor:
                                            otpResendStatus === OtpResendState.SUCCESS
                                                ? hive.color('fill/success/secondary')
                                                : hive.color('fill/error/secondary'),
                                        padding: hive.spacing('x4'),
                                        borderRadius: hive.borderRadius('medium')
                                    }}
                                >
                                    <Icon
                                        icon={
                                            otpResendStatus === OtpResendState.SUCCESS
                                                ? CheckRadioCircleCheckboxCheckCheckmarkConfirmOutline
                                                : ErrorWarningAlertOutline
                                        }
                                        color={
                                            otpResendStatus === OtpResendState.SUCCESS
                                                ? 'icon/success/primary'
                                                : 'icon/error/primary'
                                        }
                                        width={24}
                                        height={24}
                                    />
                                    <Text
                                        typography="label/m"
                                        style={{
                                            ...styles.otpResendAlertText,
                                            paddingLeft: hive.spacing('x2'),
                                            color:
                                                otpResendStatus === OtpResendState.SUCCESS
                                                    ? hive.color('text/success/primary')
                                                    : hive.color('text/error/primary')
                                        }}
                                        numberOfLines={1}
                                    >
                                        {otpAlertText}
                                    </Text>
                                    <View style={styles.otpResendAlertClose}>
                                        <Touchable
                                            hitSlop={HitSlop}
                                            style={{ paddingLeft: hive.spacing('x3') }}
                                            onPress={onResendAlertClose}
                                        >
                                            <Icon
                                                icon={CrossedSmallDeleteRemoveOutline}
                                                color={'icon/active/primary'}
                                                width={24}
                                                height={24}
                                            />
                                        </Touchable>
                                    </View>
                                </View>
                            )}
                        </View>
                    </Modal.Body>
                </Modal>
            </AvoidSoftInputView>
        </NativeModal>
    )
}
