import React from 'react'
import Animated, { SharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type OnboardingPaginationDotProps = {
    index: number,
    activeIndex: SharedValue<number>
}

export const OnboardingPaginationDot: React.FunctionComponent<OnboardingPaginationDotProps> = ({
    index,
    activeIndex
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const activeAnimatedStyles = useAnimatedStyle(() => {
        const isActive = index === activeIndex.value

        return {
            width: withTiming(isActive
                ? 30
                : 6
            ),
            backgroundColor: withTiming(isActive
                ? theme.colors.sun
                : theme.colors.darkSilver
            )
        }
    })

    return (
        <Animated.View style={[styles.dot, activeAnimatedStyles]} />
    )
}

const stylesheet = createStyles(() => ({
    dot: {
        height: 6,
        borderRadius: 3,
        marginHorizontal: 2
    }
}))
