import React, { useEffect } from 'react'
import { useWAPaymentAtom, useIsAuthorizedAtom } from 'lib/atoms'
import { NavigationProps, ScreenNames } from 'lib/routing'

type HomeScreenProps = {
    navigation: NavigationProps<ScreenNames.Home>
}

export const HomeScreen: React.FunctionComponent<HomeScreenProps> = ({ navigation }) => {
    const [isAuthorized] = useIsAuthorizedAtom()
    const [waPaymentAdded] = useWAPaymentAtom()

    useEffect(() => {
        if (isAuthorized) {
            const targetScreen = waPaymentAdded ? waPaymentAdded.screen : ScreenNames.User

            return navigation.navigate(targetScreen)
        }

        return navigation.navigate(ScreenNames.Auth)
    }, [])

    return null
}
