import React from 'react'
import { DimensionValue } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Grid, Typography } from 'lib/components'

type LaundryPackagingMessageProps = {
    hasManyServices?: boolean,
    expandRight?: boolean,
    width?: DimensionValue
}

export const LaundryPackagingMessage: React.FunctionComponent<LaundryPackagingMessageProps> = ({
    hasManyServices,
    expandRight,
    width
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const description = hasManyServices
        ? T.screens.laundry.packingDescription.multipleServices
        : T.screens.laundry.packingDescription.singleService

    return (
        <Grid.Gap
            style={{
                width,
                ...(hasManyServices
                    ? styles.expandLeft
                    : {}
                ),
                ...(expandRight
                    ? styles.expandRight
                    : {}
                )
            }}
            gapBottom={2}
        >
            <Typography.Label forceColor={theme.colors.fog}>
                {description}
            </Typography.Label>
        </Grid.Gap>
    )
}

const stylesheet = createStyles(() => ({
    expandLeft: {
        marginLeft: {
            lg: 'calc(-100% - 20px)' as DimensionValue,
            xs: undefined
        }
    },
    expandRight: {
        marginLeft: undefined,
        marginRight: {
            lg: 'calc(-100% - 20px)' as DimensionValue,
            xs: undefined
        }
    }
}))
