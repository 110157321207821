import { useTranslations } from 'lib/hooks'
import { NotificationType } from 'lib/types'

export const useToastTitle = (type: NotificationType) => {
    const T = useTranslations()

    switch (type) {
        case NotificationType.Success:
            return T.toast.title.success
        default:
            return T.toast.title.error
    }
}
