import React from 'react'
import { View } from 'react-native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { useDynamicServiceAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { BookingFrequency } from 'lib/types'
import { RescheduleBookingDateTime as OneTimeRescheduleBookingDateTime }  from './oneTimeBookingForm/RescheduleBookingDatetime'
import { RescheduleBookingDateTime as RecurringRescheduleBookingDateTime }  from './recurringBookingForm/RescheduleBookingDatetime'
import { JobDetailsResponse } from '../../types'
import { RescheduleFormShape } from '../../forms'
import { isAirconSubscriptionParentOrChild } from 'features/bookings/utils/airconUtils'

type RescheduleBookingFormProps = {
    job: JobDetailsResponse,
    formReturn: UseFormReturn<RescheduleFormShape>,
    isLoading: boolean
}

export const RescheduleBookingForm: React.FunctionComponent<RescheduleBookingFormProps> = ({
    job, formReturn, isLoading
}) => {
    const { styles } = useStyles(stylesheet)
    const { form, submit } = formReturn
    const [ config ] = useDynamicServiceAtom(job.job.supply.id)
    const isOneTime = form.frequency.value.value === BookingFrequency.OneTime || isAirconSubscriptionParentOrChild(job.job)

    return (
        <View style={styles.container}>
            {isOneTime ? (
                <OneTimeRescheduleBookingDateTime
                    onContinue={submit}
                    disabled={isLoading}
                    isLoading={isLoading}
                    frequency={form.frequency.value}
                    startingDateField={form.startingDate}
                    bookingTimeField={form.bookingTime}
                    bookingDaysField={form.bookingDays}
                    minHoursNotice={config.min_notice_hours}
                />
            ): (
                <RecurringRescheduleBookingDateTime
                    job={job}
                    onContinue={submit}
                    disabled={isLoading}
                    isLoading={isLoading}
                    frequency={form.frequency.value}
                    startingDateField={form.startingDate}
                    bookingDaysField={form.bookingDays}
                    minHoursNotice={config.min_notice_hours}
                />
            )}

        </View>
    )

}

const stylesheet = createStyles(() => ({
    container: {
        alignItems: 'center',
        width: '100%'
    }
}))

export default RescheduleBookingForm
