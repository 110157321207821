import React from 'react'
import { DimensionValue, View } from 'react-native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { Segment } from 'lib/analytics'
import { Breakpoint, createStyles } from 'lib/styles'
import { HandymanFormShape } from 'features/bookings'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, FormComponents, PhotoUpload, Typography } from 'lib/components'
import { handymanHooks, usePetsOptions } from '../../hooks'
import { ServiceAddonsFields, HandymanFields } from '../../forms'

type HandymanDetailsFieldsProps = {
    formReturn: UseFormReturn<HandymanFormShape>,
    isUploadingPhotos: boolean,
    formShape: HandymanFormShape,
    setIsUploadingPhotos(value: boolean): void
}

export const HandymanDetailsFields: React.FunctionComponent<HandymanDetailsFieldsProps> = ({
    isUploadingPhotos,
    setIsUploadingPhotos,
    formReturn,
    formShape
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { form } = formReturn
    const petsOptions = usePetsOptions()
    const handymanAdditionalInfo = handymanHooks.useHandymanAdditionalInfo(formShape)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(50% - 10px)' as DimensionValue

    return (
        <FormComponents.FormRow>
            <Typography.Regular
                bold
                style={styles.textLine}
            >
                {form.photos.label}
            </Typography.Regular>
            {!isMobile && (
                <Typography.Regular style={styles.textLine}>
                    {form.photos.placeholder}
                </Typography.Regular>
            )}
            <PhotoUpload
                renderButtonLabel={() => (
                    <Typography.Regular style={styles.textLine}>
                        {form.photos.placeholder}
                    </Typography.Regular>
                )}
                withLabel={false}
                highlightOnDrag
                buttonStyle={isMobile}
                supplyId={SupplyID.Handyman}
                onPhotoRemove={uuid => {
                    const newValue = formReturn.form.photos.value.filter(prevFile => uuid !== prevFile.fileUUID)
                    formReturn.form.photos.onChangeValue(newValue)
                }}
                isLoading={isUploadingPhotos}
                onSuccess={data => {
                    setIsUploadingPhotos(false)
                    formReturn.form.photos.onChangeValue([...formReturn.form.photos.value, ...data])
                }}
                onError={() => setIsUploadingPhotos(false)}
                onUploadStarts={() => setIsUploadingPhotos(true)}
            />
            <Adapter.Radio
                {...form.materials}
                falseLabel={T.screens.handyman.formFields.materials.falseText}
                trueLabel={T.screens.handyman.formFields.materials.trueText}
                onChangeValue={value => {
                    Segment.bookingAdditionalInformationAdded({
                        information: {
                            type: HandymanFields.Materials,
                            value: String(value)
                        }
                    })

                    form.materials.onChangeValue(value)
                }}
            />
            {form.materials.value && (
                <View style={styles.materialsWrapper}>
                    {!isMobile && (
                        <Typography.Label
                            style={styles.label}
                            forceColor={theme.colors.darkGrey}
                        >
                            {T.screens.handyman.formFields.materials.uploadLabel}
                        </Typography.Label>
                    )}
                    <PhotoUpload
                        shouldInitialize={false}
                        renderButtonLabel={() => (
                            <Typography.Regular style={styles.textLine}>
                                {T.screens.handyman.formFields.materials.uploadLabel}
                            </Typography.Regular>
                        )}
                        withLabel={false}
                        highlightOnDrag
                        buttonStyle={isMobile}
                        supplyId={SupplyID.Handyman}
                        onPhotoRemove={uuid => {
                            const newValue = formReturn.form.photos.value.filter(prevFile => uuid !== prevFile.fileUUID)
                            formReturn.form.photos.onChangeValue(newValue)
                        }}
                        isLoading={isUploadingPhotos}
                        onSuccess={data => {
                            setIsUploadingPhotos(false)
                            formReturn.form.photos.onChangeValue([...formReturn.form.photos.value, ...data])
                        }}
                        onError={() => setIsUploadingPhotos(false)}
                        onUploadStarts={() => setIsUploadingPhotos(true)}
                    />
                </View>
            )}
            <FormComponents.FormRow
                isOptional
                title={T.screens.handymanSummary.sections.additionalInformation}
            >
                <View
                    style={{
                        ...styles.selectWrapper,
                        width: columnWidth
                    }}
                >
                    <Adapter.SelectInputColumn
                        {...form.pets}
                        options={petsOptions}
                        isClearable={false}
                        onOptionSelect={({ label }) => {
                            Segment.bookingAdditionalInformationAdded({
                                information: {
                                    type: ServiceAddonsFields.Pets,
                                    value: label
                                }
                            })
                        }}
                    />
                </View>
                <Adapter.TextInput
                    {...form.comment}
                    extraContent={handymanAdditionalInfo}
                    inputProps={{
                        multiline: true
                    }}
                    onBlur={() => {
                        if (form.comment.value) {
                            Segment.bookingAdditionalInformationAdded({
                                information: {
                                    type: ServiceAddonsFields.Comment,
                                    value: form.comment.value
                                }
                            })
                        }
                    }}
                />
            </FormComponents.FormRow>
        </FormComponents.FormRow>
    )
}

const stylesheet = createStyles(theme => ({
    selectWrapper: {
        flex: 1,
        zIndex: theme.zIndex[10] - 1
    },
    textLine: {
        marginBottom: theme.utils.gap(2)
    },
    label: {
        marginTop: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(1)
    },
    materialsWrapper: {
        marginBottom: theme.utils.gap(4)
    }
}))
