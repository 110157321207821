import React from 'react'
import { ActivityIndicator, DimensionValue, View } from 'react-native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable } from './Touchable'
import { ButtonText } from './typography'
import { Children, CustomFonts, VoidFunction } from '../types'

type CallToActionButtonProps = {
    disabled?: boolean,
    text: string,
    onPress: VoidFunction,
    onHoverIn?: VoidFunction,
    onHoverOut?: VoidFunction,
    width?: DimensionValue,
    height?: number,
    isLoading?: boolean,
    renderIcon?: (color: string) => Children
    testID?: string
}

export const CallToActionButton: React.FunctionComponent<CallToActionButtonProps> = ({
    text,
    disabled,
    onPress,
    onHoverIn,
    onHoverOut,
    width = '100%',
    height,
    isLoading = false,
    testID
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const backgroundColor = disabled
        ? theme.components.button.disabled.backgroundColor
        : theme.colors.marble
    const textColor =  disabled
        ? theme.components.button.disabled.text
        : theme.components.button.typography.text

    return (
        <Touchable
            onHoverIn={onHoverIn}
            onHoverOut={onHoverOut}
            onPress={onPress}
            disabled={disabled || isLoading}
            style={{
                ...styles.button,
                width,
                height: height ?? theme.components.button.height,
                backgroundColor
            }}
            testID={testID}
        >
            {isLoading ? (
                <ActivityIndicator
                    size="small"
                    color={disabled
                        ? theme.colors.night
                        : theme.colors.white
                    }
                />
            ) : (
                <View style={styles.textContainer}>
                    <View>
                        <ButtonText forceColor={textColor} style={styles.buttonText}>
                            {text}
                        </ButtonText>
                    </View>
                    <View
                        style={{
                            ...styles.icon,
                            transform: [
                                {
                                    rotateZ: '-90deg'
                                }
                            ]
                        }}
                    >
                        <Icons.Chevron
                            size={14}
                            forceColor={disabled ? theme.colors.paleTaupe : theme.colors.orange}
                        />
                    </View>
                </View>
            )}
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    button: {
        borderRadius: 8,
        paddingHorizontal: theme.utils.gap(2),
        borderWidth: 1,
        borderColor: theme.colors.silver,
        justifyContent: 'center',
        alignItems: 'center'
    },
    textContainer: {
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        marginLeft: theme.utils.gap(2)
    },
    buttonText: {
        fontFamily: CustomFonts.Roboto500
    }
}))
