import React, { useState, useEffect, useMemo } from 'react'
import { View } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { useHive } from '@propertyguru/hive'
import { Text } from '@propertyguru/hive-text'
import { ArrowLeftCircleOutline, ArrowRightCircleOutline, ChevronDownSmallOutline } from '@propertyguru/hive-icons'
import { NativeModal } from 'lib/native/components'
import { Touchable } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { HiveCalendarDay as Day } from './HiveCalendarDay.native'
import { HiveCalendar } from './HiveCalendar.native'
import { getCalendarDaysInWeek, getWeekStartDate, resetDateTime } from './utils'
import { useDayNames, useMonthNames } from './hooks'
import { useCalendarListeners } from 'lib/native/hooks'

type HiveMiniCalendarProps = {
    selectedDate: Date,
    minYear?: number,
    maxYear?: number,
    daysWithIcons?: Array<{ date: Date, icon: React.ReactNode }>,
    dayNamesWithIcons?: Array<{ day: number, icon: React.ReactNode }>,
    minDate?: Date,
    maxDate?: Date,
    onDateSelect: (date: Date) => void
}

export const HiveMiniCalendar = ({
    daysWithIcons = [],
    dayNamesWithIcons = [],
    onDateSelect,
    ...props
}: HiveMiniCalendarProps) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)
    const monthNames = useMonthNames()
    const dayNames = useDayNames()
    const todayDate = useMemo(() => resetDateTime(new Date()), [])
    const selectedDate = useMemo(() => resetDateTime(props.selectedDate), [props.selectedDate])
    const minDate = useMemo(() => props.minDate ? resetDateTime(props.minDate) : undefined, [props.minDate])
    const maxDate = useMemo(() => props.maxDate ? resetDateTime(props.maxDate) : undefined, [props.maxDate])
    const [isFullCalendarOpen, setIsFullCalendarOpen] = useState(false)
    useCalendarListeners(setIsFullCalendarOpen)
    const isFullCalendarVisible = useSharedValue(false)
    const animatedChevronStyles = useAnimatedStyle(() => ({
        transform: [
            {
                rotate: withTiming(`${isFullCalendarVisible.value ? 180 : 0}deg`, {
                    duration: 200
                })
            }
        ]
    }))
    const [currentWeekStartDate, setCurrentWeekStartDate] = useState(getWeekStartDate(selectedDate))
    const currentMonth = useMemo(() => {
        const weekEnd = new Date(currentWeekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000)

        if (selectedDate >= currentWeekStartDate && selectedDate <= weekEnd) {
            return monthNames[selectedDate.getMonth()]
        }

        return monthNames[currentWeekStartDate.getMonth()]
    }, [currentWeekStartDate, selectedDate, monthNames])
    const calendarDaysInWeek = useMemo(() => getCalendarDaysInWeek(currentWeekStartDate), [currentWeekStartDate])
    const isPrevWeekDisabled = useMemo(() => {
        const prevWeekEnd = new Date(currentWeekStartDate.getTime() - 1 * 24 * 60 * 60 * 1000)

        return minDate && prevWeekEnd < minDate
    }, [currentWeekStartDate, minDate])

    const isNextWeekDisabled = useMemo(() => {
        const nextWeekStartDate = new Date(currentWeekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000)

        return maxDate && nextWeekStartDate > maxDate
    }, [currentWeekStartDate, maxDate])

    const showFullCalendar = () => {
        setIsFullCalendarOpen(true)
    }

    const hideFullCalendar = () => {
        setIsFullCalendarOpen(false)
    }

    const handleDateSelect = (date: Date) => {
        onDateSelect(resetDateTime(date))
        hideFullCalendar()
    }

    const handlePrevWeek = () => {
        setCurrentWeekStartDate(new Date(currentWeekStartDate.getTime() - 7 * 24 * 60 * 60 * 1000))
    }

    const handleNextWeek = () => {
        setCurrentWeekStartDate(new Date(currentWeekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000))
    }

    useEffect(() => {
        isFullCalendarVisible.value = isFullCalendarOpen
    }, [isFullCalendarOpen])

    useEffect(() => {
        setCurrentWeekStartDate(getWeekStartDate(selectedDate))
    }, [selectedDate])

    return (
        <>
            <View style={{ ...styles.calendar, rowGap: hive.spacing('x4'), paddingHorizontal: hive.spacing('x4') }}>
                <View style={{ ...styles.header, paddingVertical: hive.spacing('x1') }}>
                    <Touchable
                        style={{ padding: hive.spacing('x1') }}
                        disabled={isPrevWeekDisabled}
                        onPress={!isPrevWeekDisabled ? handlePrevWeek : undefined}
                    >
                        <ArrowLeftCircleOutline width={24} height={24} fill={hive.color(isPrevWeekDisabled ? 'icon/inactive/primary' : 'icon/active/primary')} />
                    </Touchable>
                    <Touchable style={{ ...styles.headerTitle, columnGap: hive.spacing('x1') }} onPress={showFullCalendar}>
                        <Text typography="label/m" color="text/active/secondary">{currentMonth} {currentWeekStartDate.getFullYear()}</Text>
                        <Animated.View style={animatedChevronStyles}>
                            <ChevronDownSmallOutline width={24} height={24} fill={hive.color('icon/active/secondary')} />
                        </Animated.View>
                    </Touchable>
                    <Touchable
                        style={{ padding: hive.spacing('x1') }}
                        disabled={isNextWeekDisabled}
                        onPress={!isNextWeekDisabled ? handleNextWeek : undefined}
                    >
                        <ArrowRightCircleOutline width={24} height={24} fill={hive.color(isNextWeekDisabled ? 'icon/inactive/primary' : 'icon/active/primary')} />
                    </Touchable>
                </View>
                <View style={styles.weekDays}>
                    {dayNames.map(day => (
                        <Text key={day} typography="label/s" color="text/active/secondary" style={styles.weekDayText}>
                            {day}
                        </Text>
                    ))}
                </View>
                <View style={styles.days}>
                    {calendarDaysInWeek.map(date => {
                        const dayIndex = date.getDay()
                        const isToday = date.toDateString() === todayDate.toDateString()
                        const isSelected = date.toDateString() === selectedDate.toDateString()
                        const icon = daysWithIcons.find(d => d.date.toDateString() === date.toDateString())?.icon || dayNamesWithIcons.find(d => d.day === dayIndex)?.icon
                        const isDisabled = (minDate && date < minDate) || (maxDate && date > maxDate) || false

                        return (
                            <Day
                                key={date.toDateString()}
                                date={date}
                                isToday={isToday}
                                isSelected={isSelected}
                                isDisabled={isDisabled}
                                icon={icon}
                                isCurrentMonth
                                onPress={handleDateSelect}
                            />
                        )
                    })}
                </View>
            </View>
            <NativeModal isOpen={isFullCalendarOpen} onClose={hideFullCalendar}>
                <HiveCalendar
                    selectedDate={selectedDate}
                    daysWithIcons={daysWithIcons}
                    dayNamesWithIcons={dayNamesWithIcons}
                    minDate={minDate}
                    maxDate={maxDate}
                    onDateSelect={handleDateSelect}
                />
            </NativeModal>
        </>
    )
}

const stylesheet = createStyles(theme => ({
    calendar: {
        backgroundColor: theme.colors.white
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerTitle: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    weekDays: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    weekDayText: {
        width: 40,
        textAlign: 'center'
    },
    days: {
        justifyContent: 'space-around',
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
}))
