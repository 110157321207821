import React from 'react'
import { View, ViewStyle } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { isNative } from 'lib/common'
import { VERIFY_CODE_RECAPTCHA_CONTAINER } from 'lib/firebase'

export const Recaptcha: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)

    if (isNative) {
        return null
    }

    return (
        <View
            id={VERIFY_CODE_RECAPTCHA_CONTAINER}
            style={styles.recaptcha as ViewStyle}
        />
    )
}

const stylesheet = createStyles(() => ({
    recaptcha: {
        // contains only web props, so we need to cast it ViewStyle
        visibility: 'hidden'
    }
}))
