import React, { useMemo, useState } from 'react'
import { DimensionValue, View } from 'react-native'
import { Icons } from 'assets'
import { isNative, Measurements } from 'lib/common'
import { FormComponents, Grid, Touchable, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { SelectInputOption } from 'lib/types'
import { useLaundryServiceDetails, useLaundryServiceOptions } from '../hooks'
import { CategoryPrices, LaundryService } from '../types'
import { LaundryPriceList } from './LaundryPriceList'
import { LaundryWeightExample } from './LaundryWeightExample'
import { filterPriceSections } from '../utils'

type LaundryPriceDetailsModalContentProps = {
    initialValue: LaundryService,
    prices: Array<CategoryPrices>
}

export const LaundryPriceDetailsModalContent: React.FunctionComponent<LaundryPriceDetailsModalContentProps> = ({
    initialValue,
    prices
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const options = useLaundryServiceOptions()
    const [defaultOption] = options
    const initialOption = options.find(option => option.value === initialValue)
    const [currentOption, setCurrentOption] = useState<SelectInputOption>(initialOption || defaultOption)
    const [areDetailsVisible, setDetailsVisible] = useState(false)
    const [query, setQuery] = useState('')
    const optionsPerRow = isNative ? 2 : 5
    const optionWidth = isNative
        ? Math.floor((Measurements.WindowWidth - theme.utils.gap(9)) / optionsPerRow)
        : `calc((100% / ${optionsPerRow}) - 10px)` as DimensionValue

    const getServiceDetails = useLaundryServiceDetails()
    const serviceDetails = useMemo(() => getServiceDetails(currentOption.value as LaundryService), [currentOption])
    const categoriesToDisplay = useMemo(() => filterPriceSections({
        array: prices
            .map(priceCategory => ({
                title: priceCategory.title,
                data: priceCategory.content
            })),
        activeService: currentOption.value as LaundryService,
        query
    }), [prices, currentOption, query])

    return (
        <View style={styles.container}>
            <Typography.Subheading>
                {T.components.laundryPrices.title}
            </Typography.Subheading>
            <Grid.Gap gapBottom={2}/>
            <View style={styles.optionsContainer}>
                {options.map((option, index) => (
                    <Touchable
                        key={`laundryOption-${index}`}
                        style={{
                            ...styles.option,
                            maxWidth: optionWidth,
                            marginRight: theme.utils.gap(1),
                            backgroundColor: option.value === currentOption.value
                                ? theme.colors.yellow
                                : theme.colors.white
                        }}
                        onPress={() => setCurrentOption(option)}
                    >
                        <Typography.Label
                            numberOfLines={1}
                            bold={option.value === currentOption.value}
                            forceColor={option.value === currentOption.value
                                ? theme.colors.orange
                                : theme.components.input.typography.text
                            }
                        >
                            {option.label}
                        </Typography.Label>
                    </Touchable>
                ))}
            </View>
            <Grid.Gap gapBottom={3}/>
            <Typography.Regular>
                {serviceDetails.description}
            </Typography.Regular>
            <Grid.Gap gapBottom={1}/>
            <Typography.Label forceColor={theme.colors.softBlueGrey}>
                {T.components.laundryPrices.minimumCharge}
            </Typography.Label>
            <Grid.Gap gapBottom={4}/>
            <View style={styles.tabsWrapper}>
                <View style={styles.tabsContainer}>
                    <Touchable
                        style={{
                            ...styles.tab,
                            ...(areDetailsVisible ? {} : styles.activeTab)
                        }}
                        onPress={() => setDetailsVisible(false)}
                    >
                        <Typography.Regular
                            bold={!areDetailsVisible}
                            forceColor={!areDetailsVisible
                                ? theme.colors.orange
                                : undefined
                            }
                        >
                            {T.components.laundryPrices.tabs.prices}
                        </Typography.Regular>
                    </Touchable>
                    <Touchable
                        style={{
                            ...styles.tab,
                            ...(areDetailsVisible ? styles.activeTab : {})
                        }}
                        onPress={() => setDetailsVisible(true)}
                    >
                        <Typography.Regular
                            bold={areDetailsVisible}
                            forceColor={areDetailsVisible
                                ? theme.colors.orange
                                : undefined
                            }
                        >
                            {T.components.laundryPrices.tabs.serviceDetails}
                        </Typography.Regular>
                    </Touchable>
                </View>
                <View style={styles.searchContainer}>
                    {!areDetailsVisible && (currentOption.value !== LaundryService.LoadWash && (
                        <View style={styles.searchPaddings}>
                            <FormComponents.SearchTextInput
                                value={query}
                                onQuery={setQuery}
                                placeholder={T.components.laundryPrices.searchPlaceholder}
                                leftIcon={() => (
                                    <Icons.Search size={14}/>
                                )}
                            />
                        </View>
                    ))}
                </View>
            </View>
            <Grid.Gap gapBottom={2}/>
            {areDetailsVisible ? (
                <React.Fragment>
                    {serviceDetails.paragraphs.map(detailItem => (
                        <View
                            key={detailItem.title}
                            style={styles.section}
                        >
                            <Typography.Regular bold>
                                {detailItem.title}
                            </Typography.Regular>
                            <Grid.Gap gapBottom={1}/>
                            <Typography.Regular forceColor={theme.colors.darkGrey}>
                                {detailItem.description}
                            </Typography.Regular>
                        </View>
                    ))}
                </React.Fragment>
            ) : (
                <View style={styles.row}>
                    <LaundryPriceList
                        sections={isMobile // show single list on mobile
                            ? categoriesToDisplay
                            : categoriesToDisplay.slice(0, Math.ceil(categoriesToDisplay.length / 2))
                        }
                        activeService={currentOption.value as LaundryService}
                    />
                    {!isMobile && (currentOption.value !== LaundryService.LoadWash ? (
                        <React.Fragment>
                            <View style={styles.verticalSeparator}/>
                            <LaundryPriceList
                                sections={categoriesToDisplay.slice(Math.ceil(categoriesToDisplay.length / 2))}
                                activeService={currentOption.value as LaundryService}
                            />
                        </React.Fragment>
                    ) : (
                        <LaundryWeightExample/>
                    ))}
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        maxWidth: 940
    },
    optionsContainer: {
        marginRight: -theme.utils.gap(1),
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    option: {
        flex: 1,
        flexBasis: {
            lg: '100%',
            xs: '50%'
        },
        borderRadius: 4,
        borderWidth: 1,
        width: 140,
        minWidth: {
            lg: 180,
            xs: !isNative
                ? 160
                : undefined
        },
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.components.input.height,
        paddingHorizontal: theme.utils.gap(1) / 2,
        marginBottom: theme.utils.gap(1)
    },
    searchContainer: {
        flex: 1
    },
    searchPaddings: {
        paddingHorizontal: {
            lg: theme.utils.gap(5),
            xs: undefined
        },
        paddingVertical: {
            lg: undefined,
            xs: theme.utils.gap(2)
        }
    },
    tabsWrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    tabsContainer: {
        flex: 1,
        flexDirection: 'row',
        height: 70,
        minHeight: 70
    },
    tab: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        }
    },
    activeTab: {
        borderBottomWidth: 2,
        borderBottomColor: theme.colors.orange
    },
    row: {
        flex: 1,
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        marginBottom: -theme.utils.gap(3),
        overflow: 'hidden'
    },
    verticalSeparator: {
        width: {
            lg: 1,
            xs: 0
        },
        height: {
            lg: '100%',
            xs: 0
        },
        backgroundColor: theme.colors.silver,
        marginVertical: {
            lg: theme.utils.gap(5),
            xs: undefined
        }
    },
    section: {
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(2)
    }
}))
