import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'
import { ServiceType } from 'lib/analytics'
import { CustomFonts, FrequencyOption } from 'lib/types'
import { useDynamicPriceAtom, useDynamicPriceSelectedAtom, useFeatureFlagAtom } from 'lib/atoms'
import { jobHelpers } from 'lib/utils'
import { BookingFlowMessage as BookingFlowMessageComponent } from './components'

interface DateAndTimeProps extends Field<string> {
    onPress: VoidFunction,
    disabled?: boolean,
    customValueFormat?: string,
    testID?: string,
    serviceType?: ServiceType,
    bookingTime?: string,
    frequency?: FrequencyOption
}

export const DateAndTime: React.FunctionComponent<DateAndTimeProps> = ({
    value,
    label,
    placeholder,
    disabled,
    onPress,
    customValueFormat,
    testID,
    serviceType,
    frequency
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [dynamicPriceData] = useDynamicPriceAtom()
    const [dynamicPriceSelected] = useDynamicPriceSelectedAtom()
    const [featureFlagAtom] = useFeatureFlagAtom()
    const customValue = customValueFormat ?? value
    const shouldShowCleaningDynamicTimeSlotLabel =
        jobHelpers.shouldShowCleaningDynamicTimeSlotLabel(serviceType as ServiceType, frequency as FrequencyOption, featureFlagAtom?.isEnableDynamicPricing) &&
        dynamicPriceData.messages?.bookingFlowMessage

    return (
        <View
            style={disabled
                ? styles.disabled
                : undefined
            }
        >
            {
                shouldShowCleaningDynamicTimeSlotLabel ? (
                    <View>
                        <Typography.Regular style={styles.customLabel}>
                            {T.components.form.adapter.dateAndTime.whenToClean}
                        </Typography.Regular>
                        {
                            !dynamicPriceSelected?.slotId && (
                                <BookingFlowMessageComponent bookingFlowMessage={dynamicPriceData.messages.bookingFlowMessage}/>
                            )
                        }
                    </View>
                ) : (
                    <Typography.Regular bold>
                        {label}
                    </Typography.Regular>
                )
            }

            <Touchable
                onPress={onPress}
                testID={testID}
                disabled={disabled}
            >
                <View style={styles.container}>
                    <Icons.DateAndTime size={24} />
                    <View style={styles.valueText}>
                        <Typography.Label numberOfLines={1}>
                            {customValue ? customValue : placeholder}
                        </Typography.Label>
                    </View>
                    <View
                        style={{
                            ...styles.chevron,
                            transform: [
                                {
                                    rotateZ: '-90deg'
                                }
                            ]
                        }}
                    >
                        <Icons.Chevron size={10} />
                    </View>
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    disabled: {
        opacity: 0.65
    },
    container: {
        borderWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.utils.gap(2),
        borderRadius: theme.components.input.borderRadius,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1),
        borderColor: theme.components.input.emptyBorderColor
    },
    valueText: {
        flex: 1,
        marginHorizontal: theme.utils.gap(1)
    },
    chevron: {
        marginRight: theme.utils.gap(1)
    },
    customLabel: {
        fontSize: 14,
        fontFamily: CustomFonts.Poppins600,
        lineHeight: 24,
        marginBottom: theme.utils.gap(0.5)
    }
}))
