import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Surface } from '@propertyguru/hive-surface'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Icon, IconProps } from '@propertyguru/hive-icon'
import { Text } from '@propertyguru/hive-text'
import { Badge, BadgeProps } from '@propertyguru/hive-badge'

type ChipBoxProps = {
    active?: boolean,
    text: string,
    icon: IconProps['icon'],
    iconActive: IconProps['icon'],
    iconWidth?: number,
    iconHeight?: number,
    badgeProps?: BadgeProps,
    style?: ViewStyle,
    onPress?: () => void;
}

export const ChipBox: React.FunctionComponent<ChipBoxProps> = props => {
    const { active, text, icon, iconActive, iconWidth = 24, iconHeight = 24, badgeProps, style, onPress } = props
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Surface
                style={[{
                    ...styles.card,
                    borderColor: theme.utils.hive.color(active ? 'border/active/tertiary' : 'border/active/primary'),
                    ...style
                }, active && styles.boxShadow]}
                onPress={onPress}
            >
                <Icon icon={active ? iconActive : icon} color="icon/active/primary" width={iconWidth} height={iconHeight} />
                <Text typography={active ? 'label/s' : 'label/xs'}>{text}</Text>
                {badgeProps && (
                    <View style={styles.badgeContainer} pointerEvents="none">
                        <Badge {...badgeProps} />
                    </View>
                )}
            </Surface>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        overflow: 'hidden',
        paddingBottom: theme.utils.hive.spacing('x1'),
        marginBottom: -theme.utils.hive.spacing('x1'),
        borderRadius: theme.utils.hive.borderRadius('medium')
    },
    card: {
        flex: 1,
        alignItems: 'flex-start',
        backgroundColor: theme.colors.white,
        borderWidth: 1,
        paddingVertical: theme.utils.hive.spacing('x5'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        borderRadius: theme.utils.hive.borderRadius('medium'),
        rowGap: theme.utils.hive.spacing('x2')
    },
    badgeContainer: {
        marginTop: theme.utils.hive.spacing('x4')
    },
    boxShadow: {
        ...theme.utils.createShadow(0, 0.4, 4, 4, 0, theme.colors.shadowSoft)
    }
}))

