import React, { useEffect, useMemo, useState } from 'react'
import { DimensionValue, View } from 'react-native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { isNative } from 'lib/common'
import { Segment } from 'lib/analytics'
import { Adapter, BasicModal, FormComponents, Grid } from 'lib/components'
import { useIsWithinBreakpoints, useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { Nullable } from 'lib/types'
import { getLaundryPriceList } from '../actions'
import { CategoryPrices, LaundryService } from '../types'
import { LaundryFormShape } from '../forms'
import { LaundryPackagingMessage } from './LaundryPackagingMessage'
import { LaundryPriceDetails } from './LaundryPriceDetails'
import { LaundryPriceDetailsModalContent } from './LaundryPriceDetailsModalContent'

type LaundryServicesProps = {
    formReturn: UseFormReturn<LaundryFormShape>
}

export const LaundryServices: React.FunctionComponent<LaundryServicesProps> = ({ formReturn: { form } }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { onRequestError } = useQueryHelpers()
    const [activeModalService, setActiveModalService] = useState<Nullable<LaundryService>>(null)
    const [prices, setPrices] = useState<Array<CategoryPrices>>([])
    const { mutate: getPrices } = getLaundryPriceList()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(55% - 10px)' as DimensionValue
    const packagingDescription = useMemo(() => {
        const services = [
            form.washAndIron,
            form.dryCleanAndIron,
            form.ironOnly,
            form.loadWash,
            form.curtainsWash
        ]
        const hasManyServices = services.filter(service => Boolean(service.value)).length > 1
        const width = services.filter(Boolean).length > 1
            ? undefined
            : columnWidth
        const relatedInputKey = services
            .reverse() // message should be under last service that is selected
            .find(service => Boolean(service.value))
            ?.key

        return {
            relatedInputKey,
            component: (
                <LaundryPackagingMessage
                    width={width}
                    hasManyServices={hasManyServices}
                    expandRight={hasManyServices && [form.curtainsWash.key, form.ironOnly.key].includes(relatedInputKey || '')}
                />
            )
        }
    }, [form])

    useEffect(() => {
        getPrices({}, {
            onSuccess: ({ data }) => setPrices(data.laundry_price_list),
            onError: onRequestError
        })
    }, [])

    return (
        <FormComponents.FormRow
            title={T.screens.laundry.services.title}
            description={T.screens.laundry.services.description}
        >
            <Grid.Gap gapTop={1} />
            <View style={styles.row}>
                <View style={styles.selectWrapper}>
                    <View>
                        <Adapter.AddonCheckBox
                            {...form.washAndIron}
                            renderContent={() => (
                                <LaundryPriceDetails onPress={() => setActiveModalService(LaundryService.WashAndIron)} />
                            )}
                            onOptionSelected={option => {
                                Segment.bookingLaundryServiceSelected({
                                    service: option
                                })
                            }}
                        />
                    </View>
                    {form.washAndIron.value && (
                        <View>
                            <Adapter.NumberInput
                                plusStep={5}
                                {...form.washAndIronItems}
                            />
                        </View>
                    )}
                    {packagingDescription?.relatedInputKey === form.washAndIron.key && packagingDescription.component}
                </View>
                <View style={styles.spacer} />
                <View style={styles.selectWrapper}>
                    <View>
                        <Adapter.AddonCheckBox
                            {...form.dryCleanAndIron}
                            renderContent={() => (
                                <LaundryPriceDetails onPress={() => setActiveModalService(LaundryService.DryCleanAndIron)} />
                            )}
                            onOptionSelected={option => {
                                Segment.bookingLaundryServiceSelected({
                                    service: option
                                })
                            }}
                        />
                    </View>
                    {form.dryCleanAndIron.value && (
                        <View>
                            <Adapter.NumberInput
                                plusStep={5}
                                {...form.dryCleanAndIronItems}
                            />
                        </View>
                    )}
                    {packagingDescription?.relatedInputKey === form.dryCleanAndIron.key && packagingDescription.component}
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.selectWrapper}>
                    <View>
                        <Adapter.AddonCheckBox
                            {...form.ironOnly}
                            renderContent={() => (
                                <LaundryPriceDetails onPress={() => setActiveModalService(LaundryService.IronOnly)} />
                            )}
                            onOptionSelected={option => {
                                Segment.bookingLaundryServiceSelected({
                                    service: option
                                })
                            }}
                        />
                    </View>
                    {form.ironOnly.value && (
                        <View>
                            <Adapter.NumberInput
                                plusStep={5}
                                {...form.ironOnlyItems}
                            />
                        </View>
                    )}
                    {packagingDescription?.relatedInputKey === form.ironOnly.key && packagingDescription.component}
                </View>
                <View style={styles.spacer} />
                <View style={styles.selectWrapper}>
                    <View>
                        <Adapter.AddonCheckBox
                            {...form.loadWash}
                            renderContent={() => (
                                <LaundryPriceDetails onPress={() => setActiveModalService(LaundryService.LoadWash)} />
                            )}
                            onOptionSelected={option => {
                                Segment.bookingLaundryServiceSelected({
                                    service: option
                                })
                            }}
                        />
                    </View>
                    {form.loadWash.value && (
                        <View>
                            <Adapter.NumberInput
                                plusStep={5}
                                {...form.loadWashItems}
                            />
                        </View>
                    )}
                    {packagingDescription?.relatedInputKey === form.loadWash.key && packagingDescription.component}
                </View>
            </View>
            <View
                style={{
                    width: columnWidth,
                    ...styles.row
                }}
            >
                <View style={styles.selectWrapper}>
                    <View>
                        <Adapter.AddonCheckBox
                            {...form.curtainsWash}
                            renderContent={() => (
                                <LaundryPriceDetails onPress={() => setActiveModalService(LaundryService.CurtainsWash)} />
                            )}
                            onOptionSelected={option => {
                                Segment.bookingLaundryServiceSelected({
                                    service: option
                                })
                            }}
                        />
                    </View>
                    {form.curtainsWash.value && (
                        <View>
                            <Adapter.NumberInput
                                plusStep={5}
                                {...form.curtainsWashItems}
                            />
                        </View>
                    )}
                    {packagingDescription?.relatedInputKey === form.curtainsWash.key && packagingDescription.component}
                </View>
            </View>
            <BasicModal
                insetsPadding
                isOpen={Boolean(activeModalService)}
                onClose={() => setActiveModalService(null)}
            >
                <LaundryPriceDetailsModalContent
                    initialValue={activeModalService || {} as LaundryService}
                    prices={prices}
                />
            </BasicModal>
        </FormComponents.FormRow>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(2),
        height: theme.utils.gap(2)
    },
    selectWrapper: {
        flex: {
            lg: 1,
            xs: undefined
        },
        zIndex: theme.zIndex[10]
    }
}))
