import React from 'react'
import { View } from 'react-native'
import { TextInput } from '@propertyguru/hive-text-input'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { CustomFonts } from 'lib/types'

type OTPInputsPlaceholderProps = {
    length: number
}

export const OTPInputsPlaceholder: React.FunctionComponent<OTPInputsPlaceholderProps> = ({
    length
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View>
            <View style={styles.container}>
                {[...new Array(length)].map((item, index) => (
                    <TextInput
                        key={index}
                        maxLength={1}
                        value=""
                        disabled
                        style={styles.input}
                        keyboardType="decimal-pad"
                    />
                ))}
            </View>
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    input: {
        width: 50,
        height: 50,
        textAlign: 'center',
        fontFamily: CustomFonts.Poppins400
    }
}))
