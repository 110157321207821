import React, { ReactNode } from 'react'
import { Text } from '@propertyguru/hive-text'

type ScreenHeaderTitleProps = {
    children: ReactNode;
}

export const ScreenHeaderTitle: React.FunctionComponent<ScreenHeaderTitleProps> = ({ children }) => (
    <Text typography="label/l" color="text/active/primary">
        {children}
    </Text>
)

