import React, { useEffect, useState } from 'react'
import { Field, useForm } from '@codegateinc/react-form-builder-v2'
import { useAddressAtom, useDynamicServiceAtom, useResetBookingJobQuotationAtom, useUserTokenAtom } from 'lib/atoms'
import { useSegmentSession, useTranslations, useSessionData } from 'lib/hooks'
import { PaymentOption } from 'lib/models'
import { PaymentNewCardModal } from 'features/payments'
import { Segment, ServiceType } from 'lib/analytics'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { SupplyID } from 'lib/types'
import { taskAndErrandsHooks, useBookingPromoCode, useJobQuotation, useTaskAndErrandsCreateJob } from '../hooks'
import { BookingPaymentView, TaskAndErrandsComponents } from '../components'
import { ServiceAddonsFormShape, useServiceAddonsForm } from '../forms'
import { CreateTaskAndErrandsJobRequest } from '../types'
import { MobileOtpModal } from 'features/native/bookings/components'

type TaskAndErrandsSummaryScreenProps = {
    navigation: NavigationProps<ScreenNames.TaskAndErrandsSummary>,
    route: NavigationParams<ScreenNames.TaskAndErrandsSummary>
}

export const TaskAndErrandsSummaryScreen: React.FunctionComponent<TaskAndErrandsSummaryScreenProps> = ({ route }) => {
    const T = useTranslations()
    const [address] = useAddressAtom()
    const [token] = useUserTokenAtom()
    const { segmentSession } = useSegmentSession()
    const [paymentView, setPaymentView] = useState(false)
    const [config] = useDynamicServiceAtom(route.params.supplyId)
    const supplyId = config._id
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.TasksErrands)
    const [totalPrice, setTotalPrice] = useState(0)
    const [originalPrice, setOriginalPrice] = useState(0)
    const [, setResetJobQuotationTypeAtom] = useResetBookingJobQuotationAtom()
    const sessionData = useSessionData()

    // This is from url params, and qs does not handle booleans very well, so if user refresh page this value is parsed to string so need to do this check
    const isPurchasing = typeof route.params.form.purchasing === 'string'
        ? route.params.form.purchasing === 'true'
        : route.params.form.purchasing
    const { form, submit, resetForm } = useForm<ServiceAddonsFormShape>(useServiceAddonsForm(), {
        onSuccess: ({ pets }) => {
            setResetJobQuotationTypeAtom()
            const createJobPayload: CreateTaskAndErrandsJobRequest = {
                token,
                ...sessionData,
                bookingSessionId: segmentSession.id,
                booking: {
                    messageToSupplier: `${additionalInformation}\n${form.comment.value}`,
                    date: route.params.form.bookingTime,
                    location: addressCreated ?? address,
                    taskId: Number(route.params.form.taskType.value),
                    numberOfHours: parseFloat(route.params.form.hours),
                    shopping: isPurchasing ?? undefined,
                    paymentType: paymentMethod,
                    petOnPresmises: Boolean(pets),
                    photos: []
                }
            }

            if (promotionPackage?.promotion) {
                createJobPayload.promo = {
                    code: promotionPackage?.promotion.promo_code,
                    auth: promotionPackage?.promotion.promo_auth
                }
            }

            onCreateJob(
                route.params.supplyId,
                createJobPayload,
                () => {
                    resetForm()
                    removePromoCode()
                }
            )
        }
    })
    const { isLoading: isLoadingPromotion, promotionPackage, removePromoCode, requestPromotion } = useBookingPromoCode()
    const additionalInformation = taskAndErrandsHooks.useTaskAndErrandsAdditionalInformation(route.params.form)
    // backend doesn't take into account purchasing cost when calculating discount

    const {
        jobUniqueId,
        onCreateJob,
        isLoading: isCreatingJob,
        addressCreated,
        paymentMethod,
        isDisabled,
        handleSubmit,
        isLoadingAddressForm,
        setPaymentMethod,
        fetchPostalCodes,
        addressForm,
        isNewCardModalOpen,
        setIsNewCardModalOpen,
        isMobileOtpModalOpen,
        setIsMobileOtpModalOpen
    } = useTaskAndErrandsCreateJob(submit, ScreenNames.TaskAndErrandsDetails, route.params.postalcode)

    const isLoading = isLoadingPromotion || isCreatingJob

    const onRequestPromotion = (code: string) => {
        requestPromotion(code, {
            jobUniqueId,
            photos: [],
            linkUploads: {},
            petOnPresmises: Boolean(form.pets.value),
            ironing: Boolean(form.ironing.value),
            isAllImageUploaded: true,
            num_hours: parseFloat(route.params.form.hours),
            message_to_supplier: `${additionalInformation}\n${form.comment.value}`,
            targetPromoCode: promotionPackage?.promotion.promo_code || null,
            checkingPayment: false,
            date_time: route.params.form.bookingTime,
            location: addressCreated ?? address,
            shopping: isPurchasing ?? undefined,
            is_purchasing_services: isPurchasing,
            supply: supplyId,
            is_recurring: false,
            paymentType: paymentMethod,
            paymentOptions: [PaymentOption.Card, PaymentOption.SendPay]
        })
    }

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingSummary({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                amount: route.params?.price as number,
                serviceType: ServiceType.TaskErrands
            })
        }
    }, [segmentSession])

    useEffect(() => {
        if(jobQuotation) {
            setTotalPrice(jobQuotation?.total?.value)
            setOriginalPrice(jobQuotation?.total?.valueBeforeDiscount ?? 0)
        }
    }, [jobQuotation])

    useEffect(() => {
        const promoPayload = {
            code: promotionPackage?.promotion?.promo_code,
            auth: promotionPackage?.promotion?.promo_auth
        }

        const data = {
            numberOfHours: +route.params.form.hours,
            taskId: route.params.form.taskType.value,
            includeShoppingFee: Boolean(route.params.form.purchasing),
            ...(promoPayload.code && { promo: promoPayload })
        }

        requestJobQuotation(data)

    }, [promotionPackage?.promotion.promo_code])

    if (paymentView) {
        return (
            <BookingPaymentView
                showBillingMessage
                disabled={isDisabled}
                submit={handleSubmit}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                onRemovePromoCode={removePromoCode}
                promotionPackage={promotionPackage}
                setPaymentView={setPaymentView}
                service={T.screens.taskAndErrands.title}
                setPaymentMethod={setPaymentMethod}
                selectedPaymentMethod={paymentMethod}
                discount={promotionPackage?.promotion.percent || promotionPackage?.promotion.package.credit}
                promotionType={promotionPackage?.promotion.promo_type}
                requestPromotion={onRequestPromotion}
                promoCode={form.promoCode}
            />
        )
    }

    return (
        <React.Fragment>
            <MobileOtpModal
                isOpen={isMobileOtpModalOpen}
                onClose={() => setIsMobileOtpModalOpen(false)}
                onOtpVerify={() => setIsMobileOtpModalOpen(false)}
            />
            <PaymentNewCardModal
                isOpen={isNewCardModalOpen}
                onClose={() => setIsNewCardModalOpen(false)}
            />
            <TaskAndErrandsComponents.TaskAndErrandsSummaryView
                submit={handleSubmit}
                disabled={isDisabled}
                setPaymentMethod={setPaymentMethod}
                additionalInformation={additionalInformation}
                fetchPostalCodes={fetchPostalCodes}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                onRemovePromoCode={removePromoCode}
                setPaymentView={setPaymentView}
                promotionPackage={promotionPackage}
                requestPromotion={onRequestPromotion}
                selectedPaymentMethod={paymentMethod}
                formShape={route.params.form}
                addressForm={addressForm}
                form={form as Record<keyof ServiceAddonsFormShape, Field<string & boolean>>}
                jobQuotation={jobQuotation}
            />
        </React.Fragment>
    )
}
