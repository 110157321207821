import React from 'react'
import { View } from 'react-native'
import { Surface } from '@propertyguru/hive-surface'
import { useStyles } from 'lib/hooks'
import { Icon } from '@propertyguru/hive-icon'
import {
    ErrorWarningAlertOutline
} from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { useHive } from '@propertyguru/hive'
import { PromoBoxProps } from './PromoBox.types.native'
import { stylesheet } from './PromoBox.styles.native'
import { PromoBoxContent } from './PromoBoxContent.native'

export const PromoBox: React.FC<PromoBoxProps> = ({
    active,
    selected,
    accessibilityLabel,
    precondition,
    onPress,
    ...contentProps
}) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <Surface
            style={{
                ...styles.container,
                paddingVertical: active ? hive.spacing('x4') : 0,
                paddingHorizontal: active ? hive.spacing('x4') : 0,
                borderColor: hive.color(active ? 'border/active/primary' : 'border/active/primary'),
                borderRadius: hive.borderRadius('large'),
                backgroundColor: active ? hive.color('fill/neutral/secondary') : hive.color('fill/inactive/primary')
            }}
            onPress={onPress}
            accessibilityLabel={accessibilityLabel}
        >
            <PromoBoxContent
                active={active}
                selected={selected}
                accessibilityLabel={accessibilityLabel}
                {...contentProps}
            />
            {precondition && (
                <View style={{
                    ...styles.preconditionWrapper,
                    paddingVertical: hive.spacing('x2'),
                    paddingHorizontal: hive.spacing('x4'),
                    borderColor: hive.color('fill/neutral/tertiary'),
                    columnGap: hive.spacing('x2')
                }}>
                    <Icon icon={ErrorWarningAlertOutline} width={16} height={16} />
                    <Text typography={'body/xs'} color={'text/active/secondary'}>
                        {precondition}
                    </Text>
                </View>
            )}
        </Surface>
    )
}
