import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Typography } from 'lib/components'
import { useDynamicPriceSelectedAtom } from 'lib/atoms'

type RightIconProps = {
    isFocus: boolean,
    isOptionsEmpty: boolean
}

const RightIcon: React.FunctionComponent<RightIconProps> = React.memo(props => {
    const { isFocus, isOptionsEmpty } = props
    const { styles, theme } = useStyles(stylesheet)
    const [dynamicPriceSelected] = useDynamicPriceSelectedAtom()

    return isOptionsEmpty ? null : (
        <View style={styles.rightIconContainer}>
            {
                dynamicPriceSelected?.slotId && (
                    <View style={styles.itemIconContainer}>
                        <View style={styles.itemIcon}>
                            <Icons.TrendsDownward forceColor={theme.colors.green} size={16} viewBox="0 0 16 16"/>
                        </View>
                        <Typography.Regular forceColor={theme.colors.green}>{dynamicPriceSelected?.price}</Typography.Regular>
                    </View>
                )
            }
            <View style={[styles.rightIcon, isFocus && { transform: [{ rotate: '180deg' }] }]}>
                <Icons.Chevron size={11} />
            </View>
        </View>
    )
})

export default RightIcon

const stylesheet = createStyles(theme => ({
    rightIcon: {
        paddingHorizontal: 10
    },
    rightIconContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    itemIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    itemIcon: {
        marginRight: theme.utils.gap(0.5)
    }
}))
