import { DrawerActions, useNavigation } from '@react-navigation/native'
import { DrawerProps, NavigatorId, ScreenNames } from 'lib/routing'

export const useToggleNestedDrawer = () => {
    const navigation = useNavigation<DrawerProps<ScreenNames>>()

    return (navigatorId?: NavigatorId) => {
        navigation.getParent(navigatorId)?.dispatch(DrawerActions.toggleDrawer())
    }
}
