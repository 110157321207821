import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Button, SectionBox, Typography } from 'lib/components'

export const PaymentHistoryEmpty: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <SectionBox>
            <View style={styles.container}>
                <View style={styles.text}>
                    <Typography.Title bold>
                        {T.screens.paymentHistory.noPaymentsHistory}
                    </Typography.Title>
                </View>
                <Button
                    width={180}
                    noBackground
                    // TODO Navigate to book services
                    onPress={() => {}}
                    text={T.common.bookServices}
                />
            </View>
        </SectionBox>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        marginVertical: theme.utils.gap(6)
    },
    text: {
        marginBottom: theme.utils.gap(3)
    }
}))
