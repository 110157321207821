// @ts-nocheck
// noinspection ALL

// This file is generated by svgr

import React, { memo } from 'react'
import Svg, { Path, type SvgProps } from 'react-native-svg'
const SvgGoogle = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            fill="#4285F4"
            d="M12.5 10.182v3.873h5.382a4.611 4.611 0 0 1-2.01 3.009l3.246 2.518c1.891-1.746 2.982-4.31 2.982-7.355 0-.709-.064-1.39-.182-2.045H12.5Z"
        />
        <Path
            fill="#34A853"
            d="m6.897 13.903-.732.56-2.59 2.019C5.22 19.745 8.591 22 12.5 22c2.7 0 4.964-.89 6.618-2.418l-3.245-2.518c-.891.6-2.027.963-3.373.963-2.6 0-4.809-1.754-5.6-4.118l-.004-.006Z"
        />
        <Path
            fill="#FBBC05"
            d="M3.573 7.518A9.877 9.877 0 0 0 2.5 12c0 1.618.39 3.136 1.073 4.482C3.573 16.49 6.9 13.9 6.9 13.9c-.2-.6-.318-1.236-.318-1.9 0-.664.118-1.3.318-1.9L3.573 7.518Z"
        />
        <Path
            fill="#EA4335"
            d="M12.501 5.982c1.473 0 2.782.509 3.828 1.49l2.863-2.863C17.456 2.991 15.202 2 12.502 2 8.591 2 5.22 4.245 3.573 7.518L6.901 10.1c.791-2.364 3-4.118 5.6-4.118Z"
        />
    </Svg>
)
const Memo = memo(SvgGoogle)
export default Memo

