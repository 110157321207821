import { NativeJobSchedule } from 'lib/types'

export const INITIAL_SCHEDULE: Array<NativeJobSchedule> = [
    {
        day: 'Su',
        start_time: new Date().toISOString(),
        ui_start_time: new Date().toISOString(),
        activated: false,
        fortnightly: false
    },
    {
        day: 'Mo',
        start_time: new Date().toISOString(),
        ui_start_time: new Date().toISOString(),
        activated: false,
        fortnightly: false
    },
    {
        day: 'Tu',
        start_time: new Date().toISOString(),
        ui_start_time: new Date().toISOString(),
        activated: false,
        fortnightly: false
    },
    {
        day: 'We',
        start_time: new Date().toISOString(),
        ui_start_time: new Date().toISOString(),
        activated: false,
        fortnightly: false
    },
    {
        day: 'Th',
        start_time: new Date().toISOString(),
        ui_start_time: new Date().toISOString(),
        activated: false,
        fortnightly: false
    },
    {
        day: 'Fr',
        start_time: new Date().toISOString(),
        ui_start_time: new Date().toISOString(),
        activated: false,
        fortnightly: false
    },
    {
        day: 'Sa',
        start_time: new Date().toISOString(),
        ui_start_time: new Date().toISOString(),
        activated: false,
        fortnightly: false
    }
]
