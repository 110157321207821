import { useEffect } from 'react'
import { useGetCleaningConfigsNative } from 'features/native/bookings'
import { BookingFrequency, CleaningConfigAtom, Price } from 'lib/native/models'
import { useNativeCleaningConfigsAtom, useNativeResetCleaningConfigAtom } from 'lib/native/atoms/cleaningConfigs'
import { GetCleaningConfigsResponse } from 'features/native/bookings/types'

export const useCleaningConfigs = () => {
    const [cleaningConfigsAtom, setCleaningConfigsAtom] = useNativeCleaningConfigsAtom()
    const [, resetCleaningConfigsAtom] = useNativeResetCleaningConfigAtom()
    const { mutate: getCleaningConfigs } = useGetCleaningConfigsNative()

    const updateCleaningConfigsToState = (data: GetCleaningConfigsResponse) => {
        const oneTimePriceValue=
            data?.pricing?.frequency?.options?.find(option =>
                option.frequency === BookingFrequency.OneTime
            )?.price || {} as Price

        const flatPriceValue =
            data?.pricing?.frequency?.options?.find(option =>
                option.frequency === BookingFrequency.Recurring
            )?.price || {} as Price

        const oneTimePrice = oneTimePriceValue?.amount ?? 0
        const oneTimePriceLabel = oneTimePriceValue?.amount ? `${oneTimePriceValue?.currency} ${oneTimePriceValue?.amount}${oneTimePriceValue?.perUnit}` : ''
        const flatPrice = flatPriceValue?.amount ?? 0
        const flatPriceLabel = flatPriceValue?.amount ? `${flatPriceValue?.currency} ${flatPriceValue?.amount}${flatPriceValue?.perUnit}`: ''
        const newData: CleaningConfigAtom = {
            ...data,
            oneTimePrice,
            oneTimePriceLabel,
            flatPrice,
            flatPriceLabel,
            isLoading: false
        }

        setCleaningConfigsAtom(newData)
    }

    useEffect(() => {
        setCleaningConfigsAtom({
            ...cleaningConfigsAtom,
            isLoading: true
        })

        getCleaningConfigs({}, {
            onSuccess: response => {
                updateCleaningConfigsToState(response)
            },
            onError: () => {
                resetCleaningConfigsAtom()
            }
        })
    }, [])
}
