import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { useHive } from '@propertyguru/hive'
import { Badge } from '@propertyguru/hive-badge'
import { Button } from '@propertyguru/hive-button'
import { Icon } from '@propertyguru/hive-icon'
import * as AllIcons from '@propertyguru/hive-icons'
import { Modal } from '@propertyguru/hive-modal'
import { Surface } from '@propertyguru/hive-surface'
import { Text } from '@propertyguru/hive-text'
import { useNavigation } from '@react-navigation/native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { AnyDate } from 'lib/utils/date'
import { Segment } from 'lib/analytics'
import { AlertV2, NativeModal } from 'lib/native/components'
import { useNativeCleaningConfigsAtom, useNativeJobBookingDetailAtom } from 'lib/native/atoms'
import { CleaningScheduleType } from 'lib/native/models'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import {
    useCleaningRecurringSchedule,
    useRegularBookingTimeGenerator
} from 'features/native/bookings/hooks'
import { dateHelpers } from 'lib/utils'

type CleaningScheduleModalProps = {
    isModalOpen: boolean
    date: AnyDate,
    uiDate: AnyDate,
    onClose: () => void
}

export const CleaningScheduleTypeModal: React.FC<CleaningScheduleModalProps> = ({ isModalOpen, onClose, date, uiDate }) => {
    const T = useTranslations()
    const hive = useHive()
    const navigation = useNavigation()
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const [cleaningConfigsAtom] = useNativeCleaningConfigsAtom()
    const { handleDatesChange } = useCleaningRecurringSchedule()
    const { title, paymentDisclaimer, minSessionsInfo, repeatLabel, repeatOptions } = cleaningConfigsAtom.serviceDetails.schedule
    const formattedDisclaimer = useMemo(() => {
        const boldStrings = paymentDisclaimer.message.match(/\*.*?\*/g)
        const otherStrings = paymentDisclaimer.message.split(/\*.*?\*/g)

        const output: Array<React.JSX.Element> = []
        otherStrings.forEach((string, i) => {
            output.push(
                <Text typography="caption/xs" color="text/warning/primary">
                    {string}
                </Text>
            )

            if (boldStrings && boldStrings[i]) {
                output.push(
                    <Text typography="caption/s" color="text/warning/primary">
                        {`${boldStrings[i].replace(/\*/g, '')}`}
                    </Text>
                )
            }
        })

        return output
    }, [paymentDisclaimer])
    const { styles } = useStyles(stylesheet)
    const [scheduleType, setScheduleType] = useState<CleaningScheduleType>(CleaningScheduleType.Weekly)
    const { formattedStartDate, sequenceText } = useRegularBookingTimeGenerator(uiDate, scheduleType)

    const getAnimatedAccordionStyles = (isActive: boolean) => {
        const borderColor = useSharedValue(hive.color('border/active/primary') as string)

        borderColor.value = hive.color('border/active/primary') as string

        if (isActive) {
            borderColor.value = hive.color('border/active/tertiary') as string
        }

        return useAnimatedStyle(() => ({
            height: withTiming(isActive ? 105 : 52),
            borderColor: borderColor.value,
            shadowOpacity: withTiming(isActive ? 0.4 : 0)
        }))
    }

    const getAnimatedAccordionDescriptionStyles = (isActive: boolean) => useAnimatedStyle(() => {
        const height = { value: 40 }

        if (!isActive) {
            height.value = 0
        }

        if (isActive && scheduleType === CleaningScheduleType.Custom) {
            height.value = 60
        }

        return ({
            opacity: withTiming(isActive ? 1 : 0),
            height: withTiming(height.value)
        })
    })

    const onScheduleTypeChange = (value: CleaningScheduleType) => {
        setScheduleType(value)
    }

    const handleSubmit = () => {
        Segment.cleaningPlanFrequencySelected({ frequencyType: scheduleType })

        if (scheduleType === CleaningScheduleType.Custom) {
            onClose()

            return navigation.navigate(ScreenNamesNative.CleaningServiceCustomiseScheduleNative, { scheduleDateTime: uiDate })
        }

        if ([CleaningScheduleType.Weekly, CleaningScheduleType.BiWeekly].includes(scheduleType)) {
            handleDatesChange([{ date, day: dateHelpers.getDayOfDateShort(date) }], scheduleType === CleaningScheduleType.BiWeekly, [{ date: uiDate, day: dateHelpers.getDayOfDateShort(uiDate) }])
            onClose()

            return navigation.navigate(ScreenNamesNative.CleaningServiceAddressNative)
        }
    }

    useEffect(() => {
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            scheduleType
        })
    }, [scheduleType])

    return (
        <NativeModal fullWidth isOpen={isModalOpen} onClose={onClose} >
            <Modal>
                <Modal.Header>
                    <Modal.Header.Title>
                        {title}
                    </Modal.Header.Title>
                    <Modal.Header.Button onPress={onClose} />
                </Modal.Header>
                <Modal.Body>
                    <Text typography="body/s" style={styles.modalDescription} >
                        {T.screens.cleaningDateTime.cleaningScheduleModal.description.start}
                        <Text typography="caption/m">{` ${formattedStartDate} `}</Text>
                        {T.screens.cleaningDateTime.cleaningScheduleModal.description.then}
                        <Text typography="caption/m">{sequenceText}</Text>
                    </Text>
                    <Text typography="label/m" style={styles.modalSubtitle}>{repeatLabel}</Text>
                    <View style={styles.accordionContainer}>
                        {
                            repeatOptions.map(({ title, description, type, featured, featuredText }, index) => {
                                const isActive = type === scheduleType

                                return (
                                    <Surface key={index} style={styles.boxShadowContainer} onPress={() => onScheduleTypeChange(type)}>
                                        <Animated.View
                                            style={[
                                                styles.accordion,
                                                isActive && styles.boxShadow,
                                                getAnimatedAccordionStyles(isActive)
                                            ]}
                                        >
                                            <View style={styles.accordionText}>
                                                <Text typography={isActive ? 'label/s' : 'label/xs'} color="text/active/primary">{title}</Text>
                                                {featured && (
                                                    <View pointerEvents="none">
                                                        <Badge label={featuredText ?? ''} color="green" size="small" />
                                                    </View>
                                                )}
                                            </View>
                                            <Animated.View style={[styles.accordionDescription, getAnimatedAccordionDescriptionStyles(isActive)]}>
                                                <Text typography="body/xs" color="text/active/primary">{description}</Text>
                                            </Animated.View>
                                        </Animated.View>
                                    </Surface>
                                )
                            })
                        }
                    </View>
                    <Text typography="caption/xs" style={styles.minSessionsText} color="text/active/primary">{minSessionsInfo}</Text>
                    <View style={styles.alertSlot}>
                        <AlertV2>
                            <AlertV2.Icon>
                                <Icon icon={AllIcons[paymentDisclaimer.icon]} color="icon/warning/primary" width={24} height={24} />
                            </AlertV2.Icon>
                            <AlertV2.Body>
                                <Text typography="caption/xs" color="text/warning/primary">
                                    {formattedDisclaimer}
                                </Text>
                            </AlertV2.Body>
                        </AlertV2>
                    </View>
                    <Button text={T.common.continue} onPress={handleSubmit} />
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => {

    const hive = useHive()

    return ({
        alertSlot: {
            marginVertical: hive.spacing('x8')
        },
        modalDescription: {
            marginBottom: hive.spacing('x8')
        },
        modalSubtitle: {
            marginBottom: hive.spacing('x3')
        },
        accordionContainer: {
            gap: hive.spacing('x4')
        },
        accordion: {
            borderRadius: hive.borderRadius('full'),
            backgroundColor: hive.color('surface') as string,
            borderWidth: 1,
            borderColor: hive.color('border/active/primary') as string,
            justifyContent: 'center',
            paddingHorizontal: hive.spacing('x4'),
            paddingVertical: hive.spacing('x1')
        },
        accordionText: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        accordionDescription: {
            marginTop: hive.spacing('x1')
        },
        minSessionsText: {
            textAlign: 'center',
            marginTop: hive.spacing('x6')
        },
        boxShadowContainer: {
            flex: 1,
            overflow: 'hidden',
            paddingBottom: theme.utils.hive.spacing('x1'),
            marginBottom: -theme.utils.hive.spacing('x1'),
            borderRadius: theme.utils.hive.borderRadius('medium')
        },
        boxShadow: {
            ...theme.utils.createShadow(0, 0, 4, 4, 0, theme.colors.shadowSoft)
        }
    })
})
