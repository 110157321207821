import { useTranslations } from 'lib/hooks'
import { PestControlServices, PropertyType } from '../../types'

export const useGetServices = (propertyType?: PropertyType) => {
    const T = useTranslations()
    const standardOptions = [
        {
            label: T.screens.pestControl.services.cockroach,
            value: PestControlServices.Cockroach,
            price: 150,
            minNumberOfTreatments: 1
        },
        {
            label: T.screens.pestControl.services.commonAnts,
            value: PestControlServices.CommonAnts,
            price: 150,
            minNumberOfTreatments: 1
        },
        {
            label: T.screens.pestControl.services.subterraneanTermites,
            value: PestControlServices.SubterraneanTermites,
            price: 360,
            minNumberOfTreatments: 2,
            note: T.screens.pestControl.servicesNotes.perLocation
        },
        {
            label: T.screens.pestControl.services.drywoodTermites,
            value: PestControlServices.DrywoodTermites,
            price: 360,
            minNumberOfTreatments: 2,
            note: T.screens.pestControl.servicesNotes.perLocation
        },
        {
            label: T.screens.pestControl.services.bedbugs,
            value: PestControlServices.Bedbugs,
            price: 620,
            minNumberOfTreatments: 2,
            note: T.screens.pestControl.servicesNotes.allBedrooms
        },
        {
            label: T.screens.pestControl.services.booklice,
            value: PestControlServices.Booklice,
            price: 520,
            minNumberOfTreatments: 2
        }
    ]
    const extraOptions = [
        {
            label: T.screens.pestControl.services.mosquitoes,
            value: PestControlServices.Mosquitoes,
            price: 180,
            minNumberOfTreatments: 1
        },
        {
            label: T.screens.pestControl.services.commensalRodents,
            value: PestControlServices.CommensalRodents,
            price: 520,
            minNumberOfTreatments: 4
        },
        {
            label: T.screens.pestControl.services.commonFlies,
            value: PestControlServices.CommonFlies,
            price: 600,
            minNumberOfTreatments: 3
        },
        {
            label: T.screens.pestControl.services.beehives,
            value: PestControlServices.BeehivesSmall,
            price: 180,
            minNumberOfTreatments: 1,
            note: T.screens.pestControl.servicesNotes.tennisBallSize
        },
        {
            label: T.screens.pestControl.services.beehives,
            value: PestControlServices.BeehivesMedium,
            price: 250,
            minNumberOfTreatments: 1,
            note: T.screens.pestControl.servicesNotes.soccerBallSize
        },
        {
            label: T.screens.pestControl.services.beehives,
            value: PestControlServices.BeehivesLarge,
            price: 350,
            minNumberOfTreatments: 1,
            note: T.screens.pestControl.servicesNotes.bigger
        },
        {
            label: T.screens.pestControl.services.hornets,
            value: PestControlServices.HornetsSmall,
            price: 420,
            minNumberOfTreatments: 1,
            note: T.screens.pestControl.servicesNotes.soccerBallSize
        },
        {
            label: T.screens.pestControl.services.hornets,
            value: PestControlServices.HornetsLarge,
            price: 450,
            minNumberOfTreatments: 1,
            note: T.screens.pestControl.servicesNotes.bigger
        },
        {
            label: T.screens.pestControl.services.python,
            value: PestControlServices.Python,
            price: 680,
            minNumberOfTreatments: 1,
            note: T.screens.pestControl.servicesNotes.estLength
        },
        {
            label: T.screens.pestControl.services.cobra,
            value: PestControlServices.Cobra,
            price: 1500,
            minNumberOfTreatments: 1,
            note: T.screens.pestControl.servicesNotes.anyLength
        },
        {
            label: T.screens.pestControl.services.commonHouseSnakes,
            value: PestControlServices.CommonHouseSnakes,
            price: 350,
            minNumberOfTreatments: 1
        }
    ]
    const propertiesWithExtraServices = [
        PropertyType.SmallLanded,
        PropertyType.LargeLanded
    ]
    const allServices = standardOptions.concat(extraOptions)
    const availableServices = propertyType && propertiesWithExtraServices.includes(propertyType)
        ? allServices
        : standardOptions

    return {
        standardOptions,
        extraOptions,
        allServices,
        availableServices,
        propertiesWithExtraServices
    }
}
