// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

export interface AddAddressClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddHoursToSessionClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * ID of the supplier
     */
    supplierId?: string;
    [property: string]: any;
}

export interface AddNewCreditCardClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddressAdded {
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    [property: string]: any;
}

export interface AddressCancelClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddressDeleted {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddressExpanded {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddressLineEntered {
    /**
     * Type of address entry
     */
    entryType?: EntryType;
    [property: string]: any;
}

/**
 * Type of address entry
 *
 * Type of block entry
 *
 * Type of unit and floor entry
 *
 * Type of unit/floor entry
 */
export enum EntryType {
    Manual = "Manual",
    Prefill = "Prefill",
}

export interface AddressSaveClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AirConPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface ApplicationBackgrounded {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface ApplicationInstalled {
    /**
     * Build number
     */
    build?: string;
    /**
     * Version number
     */
    version?: string;
    [property: string]: any;
}

export interface ApplicationOpened {
    /**
     * Build number
     */
    build?: string;
    /**
     * Opened from background
     */
    from_background?: boolean;
    /**
     * Version number
     */
    version?: string;
    [property: string]: any;
}

export interface ApplicationUpdated {
    /**
     * Build number
     */
    build?: string;
    /**
     * Previous build number
     */
    previous_build?: string;
    /**
     * Previous version number
     */
    previous_version?: string;
    /**
     * Version number
     */
    version?: string;
    [property: string]: any;
}

export interface BlockLineEntered {
    /**
     * Type of block entry
     */
    entryType?: EntryType;
    [property: string]: any;
}

export interface BookAgainClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * Status of the job eg. Paused
 */
export enum JobStatus {
    Accepted = "Accepted",
    Cancelled = "Cancelled",
    Completed = "Completed",
    Hold = "Hold",
    Paused = "Paused",
    Rated = "Rated",
    Submitted = "Submitted",
}

export interface BookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookServiceClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingAdditionalInformationAdded {
    /**
     * Details about the additional information
     */
    information?: Information;
    [property: string]: any;
}

/**
 * Details about the additional information
 */
export interface Information {
    /**
     * Type of the information eg. Pets
     */
    type?: string;
    /**
     * Value of the additional information
     */
    value?: string;
    [property: string]: any;
}

export interface BookingAddonAdded {
    /**
     * Details about the addon
     */
    addon?: Addon;
    [property: string]: any;
}

/**
 * Details about the addon
 */
export interface Addon {
    /**
     * Name of the addon
     */
    name?: string;
    /**
     * Selected value of the addon
     */
    value?: string;
    [property: string]: any;
}

export interface BookingAddressConfirmClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingAddressModuleClosed {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingAnotherServiceTypeSelected {
    /**
     * Selected category eg. General
     */
    category?: string;
    /**
     * Selected item eg. Door
     */
    item?: string;
    /**
     * Selected type eg. Repair
     */
    type?: string;
    [property: string]: any;
}

export interface BookingBedroomNumberClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingBedroomNumberSelected {
    /**
     * Number of selected bedrooms
     */
    number?: number;
    [property: string]: any;
}

export interface BookingCanceled {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Short reason why user canceled the job
     */
    reason?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface BookingCancellationChargesClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingCancellationChargesModuleClosed {
    /**
     * Close type by button or click outside
     */
    closeType?: CloseType;
    [property: string]: any;
}

/**
 * Close type by button or click outside
 */
export enum CloseType {
    Button = "Button",
    Outside = "Outside",
}

export interface BookingClearBedroomsClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingClearPetsClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingClearPropertyFloorClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingClearPropertyTypeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingCookingStyleEntered {
    /**
     * Cooking style entered by customer
     */
    cookingStyle?: string;
    [property: string]: any;
}

export interface BookingCookingStyleSelected {
    /**
     * Cooking style eg. italian cuisine
     */
    cookingStyle?: string;
    [property: string]: any;
}

export interface BookingDateAndTimeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingDateAndTimeModuleClosed {
    /**
     * Is date selected
     */
    dateSelected?: boolean;
    /**
     * Is time selected
     */
    timeSelected?: boolean;
    [property: string]: any;
}

export interface BookingDateAndTimeSelected {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingDiscountCodeApplied {
    /**
     * Entered promo code
     */
    promoCode?: string;
    /**
     * Is code valid
     */
    success?: boolean;
    [property: string]: any;
}

export interface BookingDiscountCodeErrorShowed {
    /**
     * Error message
     */
    error?: string;
    /**
     * Entered promo code
     */
    promoCode?: string;
    [property: string]: any;
}

export interface BookingEditAddressClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingEditPaymentClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingElevatorSelected {
    /**
     * If property have elevator
     */
    elevator?: boolean;
    [property: string]: any;
}

export interface BookingExistingAddressSelected {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingFoodAllergiesSelected {
    /**
     * Food allergies
     */
    allergies?: boolean;
    [property: string]: any;
}

export interface BookingFrequencyClicked {
    /**
     * Type of the frequency eg. One Time
     */
    frequencyType?: FrequencyType;
    /**
     * Index of the frequency placed in the view. Starts from 1
     */
    position?: number;
    [property: string]: any;
}

/**
 * Type of the frequency eg. One Time
 */
export enum FrequencyType {
    Fortnightly = "Fortnightly",
    OneTime = "OneTime",
    Weekly = "Weekly",
}

export interface BookingHoursSelected {
    /**
     * Number of hours
     */
    hours?: number;
    [property: string]: any;
}

export interface BookingImageUploaded {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingInspectionDateAndTimeModuleClosed {
    /**
     * Is date selected
     */
    dateSelected?: boolean;
    /**
     * Number of selected dates
     */
    numberOfDatesSelected?: number;
    /**
     * Is time selected
     */
    timeSelected?: boolean;
    [property: string]: any;
}

export interface BookingInspectionDateAndTimeSelected {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingInstructionForDriverSelected {
    /**
     * Instruction for the driver eg. Collect items from reception
     */
    instruction?: string;
    [property: string]: any;
}

export interface BookingLaundryServiceSelected {
    /**
     * Booking laundry service name eg. Iron only
     */
    service?: string;
    [property: string]: any;
}

export interface BookingMoreClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingMovingTypeSelected {
    /**
     * Moving type name
     */
    movingType?: string;
    [property: string]: any;
}

export interface BookingNewAddressClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingNumberOfAirConditionersSelected {
    /**
     * Number of air conditioners
     */
    quantity?: number;
    [property: string]: any;
}

export interface BookingPaymentSwitched {
    /**
     * Selected payment method
     */
    selectedPaymentMethod?: SelectedPaymentMethod;
    [property: string]: any;
}

/**
 * Selected payment method
 */
export enum SelectedPaymentMethod {
    CreditCard = "CreditCard",
    SendPay = "SendPay",
}

export interface BookingPestControlServiceSelected {
    /**
     * Pest control service name eg. Common ants
     */
    service?: string;
    [property: string]: any;
}

export interface BookingPriceReferenceClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPriceReferenceClosed {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPriceReferenceTabClicked {
    /**
     * Price reference tab name
     */
    tabName?: string;
    [property: string]: any;
}

export interface BookingPromoCodeEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPropertyFloorClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPropertyFloorSelected {
    /**
     * Number of selected floors
     */
    number?: number;
    [property: string]: any;
}

export interface BookingPropertyOptionSelected {
    /**
     * Booking property option eg. I have an oven but no dishwasher
     */
    propertyOption?: string;
    [property: string]: any;
}

export interface BookingPropertySizeSelected {
    /**
     * Size of the property eg. 800 - 1000 sqft
     */
    propertySize?: string;
    [property: string]: any;
}

export interface BookingPropertyTypeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPropertyTypeSelected {
    /**
     * Type of the property eg. HDB
     */
    propertyType?: string;
    [property: string]: any;
}

export interface BookingPurchaseSendPayCreditsClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPurchasingServicesSelected {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingRescheduled {
    /**
     * Job frequency eg. Weekly, Bi-weekly, Monthly
     */
    cancellationFeesCharged?: string;
    /**
     * New date time
     */
    dateTime?: string;
    /**
     * Job frequency eg. Weekly, Bi-weekly, Monthly
     */
    jobFrequency?: string;
    /**
     * Job id of the job
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface BookingSelectSavedAddressClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingSelectServiceTypeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingServiceDescriptionModuleClosed {
    /**
     * Close type by button or click outside
     */
    closeType?: CloseType;
    [property: string]: any;
}

export interface BookingServicePlanClicked {
    /**
     * Index of the service plan placed in the view. Starts from 1
     */
    position?: number;
    /**
     * Type of the service plan eg. One Time
     */
    servicePlan?: ServicePlan;
    [property: string]: any;
}

/**
 * Type of the service plan eg. One Time
 */
export enum ServicePlan {
    Contract = "Contract",
    Custom = "Custom",
    OneTime = "OneTime",
}

export interface BookingServiceTypeSelected {
    /**
     * Selected category eg. General
     */
    category?: string;
    /**
     * Selected item eg. Door
     */
    item?: string;
    /**
     * Selected type eg. Repair
     */
    type?: string;
    [property: string]: any;
}

export interface BookingStarted {
    /**
     * Unique Booking Session ID
     */
    bookingSessionId?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface BookingSummary {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Unique Booking Session ID
     */
    bookingSessionId?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface BookingTaskOrErrandTypeSelected {
    /**
     * Selected task or errand type eg. Grocery Shopping
     */
    type?: string;
    [property: string]: any;
}

export interface BookingUploadedImageRemoveClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingWhatsIncludedAndHowItWorksClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CallSupplierClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * ID of the supplier
     */
    supplierId?: string;
    [property: string]: any;
}

export interface CancelBookingClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface ChatWithSupplierClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * ID of the supplier
     */
    supplierId?: string;
    [property: string]: any;
}

export interface CleaningPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface CleaningPlanFrequencySelected {
    /**
     * Called when user clicks on cleaning frequency
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CleaningPlanSelected {
    /**
     * Called when user clicks on cleaning plan
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface ContinueAsGuestClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CookingPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface CreditCardAdded {
    /**
     * Card type eg. Visa
     */
    cardType?: string;
    [property: string]: any;
}

export interface CreditCardCVVEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardCancelClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardDateEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardInvalidNumberEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardModuleClosed {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardNameEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardNumberEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossSaleBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleAirconBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleCleaningBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleCookingBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleDeepCleaningBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleHandymanBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleLaundryBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleMoversAndPackersBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleMyBookingsClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossalePestControlBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleTasksAndErrandsBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface DeepLinkOpened {
    /**
     * Referring application name
     */
    referring_application?: string;
    /**
     * Url that opened the app
     */
    url?: string;
    [property: string]: any;
}

export interface DeepCleaningPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface DynamicPricingSelected {
    /**
     * Day of the week
     */
    dayOfTheWeek?: string;
    /**
     * Price per hour
     */
    pricePerHour?: number;
    /**
     * Time of the day
     */
    timeOfTheDay?: string;
    [property: string]: any;
}

export interface EditProfileClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface ExternalLinkClicked {
    /**
     * External url
     */
    url?: string;
    [property: string]: any;
}

export interface FavouritePartnerSet {
    /**
     * Favourite partner details
     */
    partner?: Partner;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * Favourite partner details
 */
export interface Partner {
    /**
     * Handle of the favourite partner (supplier)
     */
    partnerHandle?: string;
    /**
     * Id of the favourite partner (supplier)
     */
    partnerId?: string;
    [property: string]: any;
}

export interface HandymanPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface InstallAttributed {
    /**
     * Campaign info
     */
    campaign?: Campaign;
    /**
     * Name of the provider
     */
    provider?: string;
    [property: string]: any;
}

/**
 * Campaign info
 */
export interface Campaign {
    /**
     * Name of the campaign
     */
    name?: string;
    /**
     * Source of the campaign
     */
    source?: string;
    [property: string]: any;
}

export interface JobAccepted {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * Supplier ID who accepted the job
     */
    supplierId?: string;
    [property: string]: any;
}

export interface JobCancelled {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Amount of the cancellation fees
     */
    cancellationFees?: number;
    /**
     * From where the job cancellation is triggered, e.g - CustomerPreAcceptance,
     * CustomerPostAcceptance, Supplier, Console
     */
    cancellationType?: CancellationType;
    /**
     * The status of the job at cancellation: JobAccepted, JobNotAccepted
     */
    jobAcceptanceStatus?: JobAcceptanceStatus;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Job type - adhoc or recurring
     */
    recurring?: boolean;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * From where the job cancellation is triggered, e.g - CustomerPreAcceptance,
 * CustomerPostAcceptance, Supplier, Console
 */
export enum CancellationType {
    Console = "Console",
    CustomerPostAcceptance = "CustomerPostAcceptance",
    CustomerPreAcceptance = "CustomerPreAcceptance",
    Supplier = "Supplier",
}

/**
 * The status of the job at cancellation: JobAccepted, JobNotAccepted
 */
export enum JobAcceptanceStatus {
    JobAccepted = "JobAccepted",
    JobNotAccepted = "JobNotAccepted",
}

export interface JobClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface JobCompleted {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * Supplier ID who completed the job
     */
    supplierId?: string;
    [property: string]: any;
}

export interface JobExpired {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface JobSessionExtended {
    /**
     * Number of extended hours
     */
    extraHours?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * ID of the supplier
     */
    supplierId?: string;
    [property: string]: any;
}

export interface LaundryPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface MarketingAffiliateClicked {
    /**
     * Was form completed
     */
    formCompletion?: boolean;
    [property: string]: any;
}

export interface MarketingBecomeAPartnerClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingBecomeAPartnerClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingBecomeAPartnerClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingBlogClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingBlogClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingBlogClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingBookNowClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingBookNowClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    /**
     * Call to action placement eg. Main banner
     */
    placement?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingBookNowClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingBookServiceClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service Id
     */
    serviceId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface MarketingClickBookNowOnOfferBanner {
    /**
     * Additional data from the event
     */
    eventData?: MarketingClickBookNowOnOfferBannerEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingClickBookNowOnOfferBannerEventData {
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

export interface MarketingContactClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Contact type eg. Email
     */
    contactType?: ContactType;
    /**
     * Form was submitted
     */
    formSubmission?: boolean;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

/**
 * Contact type eg. Email
 */
export enum ContactType {
    Email = "Email",
    Hotline = "Hotline",
    Whatsapp = "Whatsapp",
}

export interface MarketingContactUsClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingContactUsClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingContactUsClickedEventData {
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

export interface MarketingDealsClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingEnterEmailSubscribed {
    /**
     * Newsletter Subscribe Email
     */
    email?: string;
    /**
     * Additional data from the event
     */
    eventData?: MarketingEnterEmailSubscribedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingEnterEmailSubscribedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingEnterPostCode {
    /**
     * Additional data from the event
     */
    eventData?: MarketingEnterPostCodeEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingEnterPostCodeEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingExternalLinkClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    /**
     * External url
     */
    url?: string;
    [property: string]: any;
}

export interface MarketingHelpCentreClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingHelpCentreClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    /**
     * Name of the module
     */
    module?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingHelpCentreClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingHelpCentreSearched {
    /**
     * Search keywords
     */
    keywordUsed?: string;
    /**
     * Search results
     */
    searchResults?: any[];
    [property: string]: any;
}

export interface MarketingMobileAppDownload {
    /**
     * Name of the OS
     */
    os?: OS;
    /**
     * Page name from where user clicked on download CTA
     */
    pageName?: string;
    [property: string]: any;
}

/**
 * Name of the OS
 */
export enum OS {
    Android = "Android",
    IOS = "iOS",
}

export interface MarketingMobileAppDownloadClicked {
    /**
     * OS eg. Android
     */
    os?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingNewsletterSubscribed {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Newsletter Subscribe Email
     */
    email?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingNotificationClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingNotificationClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingNotificationClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingOurServicesClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingOurServicesClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingOurServicesClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingPageViewed {
    /**
     * Device info
     */
    device?: Device;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    /**
     * User ID
     */
    userId?: string;
    [property: string]: any;
}

/**
 * Device info
 */
export interface Device {
    /**
     * Device name eg. Samsung Galaxy
     */
    deviceName?: string;
    /**
     * Device OS eg. Windows
     */
    deviceOS?: string;
    /**
     * Device type eg. Tablet
     */
    deviceType?: string;
    [property: string]: any;
}

export interface MarketingPartnerSignedUp {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingReviewClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingReviewClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingReviewClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingScrollToBottom {
    /**
     * Additional data from the event
     */
    eventData?: MarketingScrollToBottomEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingScrollToBottomEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingSearchBookNowClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingSearchBookNowClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingSearchBookNowClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingSearchPostCodeClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingSearchPostCodeClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingSearchPostCodeClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingSearchServiceClicked {
    /**
     * Call to action placement eg. Main banner
     */
    placement?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface MarketingSearchServiceTypeClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingSearchServiceTypeClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingSearchServiceTypeClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingServiceClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface MarketingServiceIconClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingServiceIconClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingServiceIconClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingUserClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingUserClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingUserClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MobileAppDownload {
    /**
     * Name of the OS
     */
    os?: OS;
    /**
     * Page name from where user clicked on download CTA
     */
    pageName?: string;
    [property: string]: any;
}

export interface MoversAndPackersPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface OrganicInstall {
    /**
     * Name of the provider
     */
    provider?: string;
    [property: string]: any;
}

export interface PaymentCharged {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * Type of the payment method
     */
    paymentMethod?: PaymentMethod;
    /**
     * Type of the payment
     */
    paymentType?: PaymentType;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * Type of the payment method
 */
export enum PaymentMethod {
    Card = "Card",
    Combined = "Combined",
    SendPay = "SendPay",
}

/**
 * Type of the payment
 */
export enum PaymentType {
    Acceptance = "Acceptance",
    Completion = "Completion",
    NewSession = "NewSession",
}

export interface PestPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface PostcodeEntered {
    /**
     * Entered postcode
     */
    postcode?: string;
    /**
     * Is postcode valid
     */
    success?: boolean;
    [property: string]: any;
}

export interface PriceGuideHandymanClicked {
    /**
     * Price guide modal in Handyman service is clicked.
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface Purchase {
    /**
     * Currency of the purchase
     */
    currency?: string;
    /**
     * Meta analytics click id
     */
    fbc?: string;
    /**
     * Meta analytics browser id
     */
    fbp?: string;
    /**
     * Google analytics client id
     */
    ga_client_id?: string;
    /**
     * Google analytics sessions Id
     */
    ga_session_id?: string;
    /**
     * Google analytics sessions number
     */
    ga_session_number?: number;
    /**
     * Additional data from the event
     */
    items?: Item[];
    /**
     * Amount of the purchase
     */
    value?: number;
    [property: string]: any;
}

export interface Item {
    /**
     * Currency of the item
     */
    currency?: string;
    /**
     * Job ID
     */
    item_id?: string;
    /**
     * Product Name
     */
    item_name?: string;
    /**
     * Price of the item
     */
    price?: number;
    [property: string]: any;
}

export interface PurchaseSendPayCreditPackClicked {
    /**
     * Credit Pack details
     */
    creditPack?: CreditPack;
    [property: string]: any;
}

/**
 * Credit Pack details
 */
export interface CreditPack {
    /**
     * Credit Pack name eg. Credit Pack 1000
     */
    name?: string;
    /**
     * Credit Pack value
     */
    value?: number;
    [property: string]: any;
}

export interface PurchaseTopUpSendPayCreditsClicked {
    /**
     * Amount of purchase (manual)
     */
    topUpAmount?: number;
    [property: string]: any;
}

export interface PurchasedSendPayCredits {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Type of the payment method eg. Grab
     */
    paymentMethodType?: PaymentMethodType;
    /**
     * Type of the purchase
     */
    purchaseType?: PurchaseType;
    [property: string]: any;
}

/**
 * Type of the payment method eg. Grab
 */
export enum PaymentMethodType {
    Card = "Card",
    Credits = "Credits",
    Grab = "Grab",
}

/**
 * Type of the purchase
 */
export enum PurchaseType {
    Manual = "Manual",
    Package = "Package",
}

export interface ReferFriendClicked {
    /**
     * Referral code
     */
    referralCode?: string;
    /**
     * Name of the platform where user wants to share referral code
     */
    sharePlatform?: SharePlatform;
    [property: string]: any;
}

/**
 * Name of the platform where user wants to share referral code
 */
export enum SharePlatform {
    Clipboard = "Clipboard",
    Email = "Email",
    Instagram = "Instagram",
    Messenger = "Messenger",
    Other = "Other",
    Telegram = "Telegram",
    WhatsApp = "WhatsApp",
}

export interface ReferralCodeCopied {
    /**
     * Referral code
     */
    referralCode?: string;
    [property: string]: any;
}

export interface RescheduleBookingClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface RescheduleRequestClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface SelectedServiceFromDropdown {
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface ServiceBooked {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Unique Booking Session ID
     */
    bookingSessionId?: string;
    /**
     * Meta analytics click id
     */
    fbc?: string;
    /**
     * Meta analytics browser id
     */
    fbp?: string;
    /**
     * Google analytics client id
     */
    ga_client_id?: string;
    /**
     * Google analytics sessions Id
     */
    ga_session_id?: string;
    /**
     * Google analytics sessions number
     */
    ga_session_number?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Type of job eg. Adhoc or Recurring
     */
    jobType?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Revenue generated from the service eg. 100
     */
    revenue?: number;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface ServiceClicked {
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface ShareReferralCodeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface SubscriptionBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface TasksErrandsPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface UnitAndFloorEntered {
    /**
     * Type of unit and floor entry
     */
    entryType?: EntryType;
    [property: string]: any;
}

export interface UnitFloorLineEntered {
    /**
     * Type of unit/floor entry
     */
    entryType?: EntryType;
    [property: string]: any;
}

export interface UserSignedIn {
    /**
     * Auth method used to sign up eg. Facebook
     */
    authMethod?: AuthMethod;
    /**
     * User email
     */
    email?: string;
    /**
     * Meta analytics click id
     */
    fbc?: string;
    /**
     * Meta analytics browser id
     */
    fbp?: string;
    /**
     * Google analytics client id
     */
    ga_client_id?: string;
    /**
     * Google analytics sessions Id
     */
    ga_session_id?: string;
    /**
     * Google analytics sessions number
     */
    ga_session_number?: number;
    [property: string]: any;
}

/**
 * Auth method used to sign up eg. Facebook
 */
export enum AuthMethod {
    Apple = "Apple",
    Email = "Email",
    Facebook = "Facebook",
    Google = "Google",
}

export interface UserSignedUp {
    /**
     * Auth method used to sign up eg. Facebook
     */
    authMethod?: AuthMethod;
    /**
     * User email
     */
    email?: string;
    /**
     * Meta analytics click id
     */
    fbc?: string;
    /**
     * Meta analytics browser id
     */
    fbp?: string;
    /**
     * Google analytics client id
     */
    ga_client_id?: string;
    /**
     * Google analytics sessions Id
     */
    ga_session_id?: string;
    /**
     * Google analytics sessions number
     */
    ga_session_number?: number;
    [property: string]: any;
}

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 * 
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv, { ErrorObject } from 'ajv'

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this: 
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next' 
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 * 
 * setTypewriterOptions({ analytics: analytics })
 */
import type { AnalyticsSnippet, Analytics, AnalyticsBrowser, Options } from '@segment/analytics-next'

declare global {
    interface Window {
        analytics: AnalyticsSnippet;
    }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser

/** The callback exposed by analytics.js. */
export type Callback = () => void

export type ViolationHandler = (
    message: Record<string, any>,
    violations: ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
    const msg = JSON.stringify(
        {
            type: 'Typewriter JSON Schema Validation Error',
            description:
                `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
                'Tracking Plan spec.',
            errors: violations,
        },
        undefined,
        2,
    );

    console.warn(msg);
};

let onViolation = defaultValidationErrorHandler

let analytics: () => AnyAnalytics | undefined = () => {
    return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
    /**
     * Underlying analytics instance where analytics calls are forwarded on to.
     * Defaults to window.analytics.
     */
    analytics?: AnyAnalytics;
    /**
     * Handler fired when if an event does not match its spec. This handler
     * does not fire in production mode, because it requires inlining the full
     * JSON Schema spec for each event in your Tracking Plan.
     *
     * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
     * if a message does not match the spec. Otherwise, errors will be logged to stderr.
     */
    onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
    analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
    onViolation = options.onViolation || onViolation
}

/**
    * Validates a message against a JSON Schema using Ajv. If the message
    * is invalid, the `onViolation` handler will be called.
    */
function validateAgainstSchema(
    message: Record<string, any>,
    schema: object
) {
    const ajv = new Ajv({ allErrors: true, verbose: true })

    if (!ajv.validate(schema, message) && ajv.errors) {
        onViolation(message, ajv.errors)
    }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
    return {
        ...message,
        context: {
            ...(message.context || {}),
            typewriter: {
                language: 'typescript',
                version: '9.1.0',
            },
        },
    };
}

/**
 * Fires a 'AddAddressClicked' track call.
 *
 * @param AddAddressClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addAddressClicked(props: AddAddressClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AddAddressClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AddAddressClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddHoursToSessionClicked' track call.
 *
 * @param AddHoursToSessionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addHoursToSessionClicked(props: AddHoursToSessionClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AddHoursToSessionClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"},"supplierId":{"$id":"/properties/supplierId","description":"ID of the supplier","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AddHoursToSessionClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddNewCreditCardClicked' track call.
 *
 * @param AddNewCreditCardClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addNewCreditCardClicked(props: AddNewCreditCardClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AddNewCreditCardClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AddNewCreditCardClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddressAdded' track call.
 *
 * @param AddressAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addressAdded(props: AddressAdded, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AddressAdded","$schema":"http://json-schema.org/draft-07/schema","properties":{"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AddressAdded', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddressCancelClicked' track call.
 *
 * @param AddressCancelClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addressCancelClicked(props: AddressCancelClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AddressCancelClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AddressCancelClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddressDeleted' track call.
 *
 * @param AddressDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addressDeleted(props: AddressDeleted, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AddressDeleted","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AddressDeleted', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddressExpanded' track call.
 *
 * @param AddressExpanded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addressExpanded(props: AddressExpanded, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AddressExpanded","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AddressExpanded', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddressLineEntered' track call.
 *
 * @param AddressLineEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addressLineEntered(props: AddressLineEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AddressLineEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"entryType":{"$id":"/properties/entryType","description":"Type of address entry","enum":["Prefill","Manual"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AddressLineEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddressSaveClicked' track call.
 *
 * @param AddressSaveClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addressSaveClicked(props: AddressSaveClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AddressSaveClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AddressSaveClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AirConPayment' track call.
 *
 * @param AirConPayment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function airConPayment(props: AirConPayment, options?: Options, callback?: Callback): void {

    const schema = {"$id":"AirConPayment","$schema":"http://json-schema.org/draft-07/schema","properties":{"paymentType":{"$id":"/properties/paymentType","description":"Payment Method used to complete the booking","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('AirConPayment', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ApplicationBackgrounded' track call.
 *
 * @param ApplicationBackgrounded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function applicationBackgrounded(props: ApplicationBackgrounded, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Application_Backgrounded","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Application Backgrounded', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ApplicationInstalled' track call.
 *
 * @param ApplicationInstalled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function applicationInstalled(props: ApplicationInstalled, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Application_Installed","$schema":"http://json-schema.org/draft-07/schema","properties":{"build":{"$id":"/properties/build","description":"Build number","type":"string"},"version":{"$id":"/properties/version","description":"Version number","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Application Installed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ApplicationOpened' track call.
 *
 * @param ApplicationOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function applicationOpened(props: ApplicationOpened, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Application_Opened","$schema":"http://json-schema.org/draft-07/schema","properties":{"build":{"$id":"/properties/build","description":"Build number","type":"string"},"from_background":{"$id":"/properties/from_background","description":"Opened from background","type":"boolean"},"version":{"$id":"/properties/version","description":"Version number","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Application Opened', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ApplicationUpdated' track call.
 *
 * @param ApplicationUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function applicationUpdated(props: ApplicationUpdated, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Application_Updated","$schema":"http://json-schema.org/draft-07/schema","properties":{"build":{"$id":"/properties/build","description":"Build number","type":"string"},"previous_build":{"$id":"/properties/previous_build","description":"Previous build number","type":"string"},"previous_version":{"$id":"/properties/previous_version","description":"Previous version number","type":"string"},"version":{"$id":"/properties/version","description":"Version number","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Application Updated', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BlockLineEntered' track call.
 *
 * @param BlockLineEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function blockLineEntered(props: BlockLineEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BlockLineEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"entryType":{"$id":"/properties/entryType","description":"Type of block entry","enum":["Prefill","Manual"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BlockLineEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookAgainClicked' track call.
 *
 * @param BookAgainClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookAgainClicked(props: BookAgainClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookAgainClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"jobStatus":{"$id":"/properties/jobStatus","description":"Status of the job eg. Paused","enum":["Submitted","Accepted","Cancelled","Completed","Rated","Paused","Hold"],"type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookAgainClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookNowClicked' track call.
 *
 * @param BookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookNowClicked(props: BookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookServiceClicked' track call.
 *
 * @param BookServiceClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookServiceClicked(props: BookServiceClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookServiceClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookServiceClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingAdditionalInformationAdded' track call.
 *
 * @param BookingAdditionalInformationAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingAdditionalInformationAdded(props: BookingAdditionalInformationAdded, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingAdditionalInformationAdded","$schema":"http://json-schema.org/draft-07/schema","properties":{"information":{"$id":"/properties/information","description":"Details about the additional information","properties":{"type":{"$id":"/properties/properties/properties/information/properties/type","description":"Type of the information eg. Pets","type":"string"},"value":{"$id":"/properties/properties/properties/information/properties/value","description":"Value of the additional information","type":"string"}},"type":"object"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingAdditionalInformationAdded', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingAddonAdded' track call.
 *
 * @param BookingAddonAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingAddonAdded(props: BookingAddonAdded, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingAddonAdded","$schema":"http://json-schema.org/draft-07/schema","properties":{"addon":{"$id":"/properties/addon","description":"Details about the addon","properties":{"name":{"$id":"/properties/properties/properties/addon/properties/name","description":"Name of the addon","type":"string"},"value":{"$id":"/properties/properties/properties/addon/properties/value","description":"Selected value of the addon","type":"string"}},"type":"object"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingAddonAdded', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingAddressConfirmClicked' track call.
 *
 * @param BookingAddressConfirmClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingAddressConfirmClicked(props: BookingAddressConfirmClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingAddressConfirmClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingAddressConfirmClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingAddressModuleClosed' track call.
 *
 * @param BookingAddressModuleClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingAddressModuleClosed(props: BookingAddressModuleClosed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingAddressModuleClosed","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingAddressModuleClosed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingAnotherServiceTypeSelected' track call.
 *
 * @param BookingAnotherServiceTypeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingAnotherServiceTypeSelected(props: BookingAnotherServiceTypeSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingAnotherServiceTypeSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"category":{"$id":"/properties/category","description":"Selected category eg. General","type":"string"},"item":{"$id":"/properties/item","description":"Selected item eg. Door","type":"string"},"type":{"$id":"/properties/type","description":"Selected type eg. Repair","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingAnotherServiceTypeSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingBedroomNumberClicked' track call.
 *
 * @param BookingBedroomNumberClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingBedroomNumberClicked(props: BookingBedroomNumberClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingBedroomNumberClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingBedroomNumberClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingBedroomNumberSelected' track call.
 *
 * @param BookingBedroomNumberSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingBedroomNumberSelected(props: BookingBedroomNumberSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingBedroomNumberSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"number":{"$id":"/properties/number","description":"Number of selected bedrooms","type":"number"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingBedroomNumberSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingCanceled' track call.
 *
 * @param BookingCanceled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingCanceled(props: BookingCanceled, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingCanceled","$schema":"http://json-schema.org/draft-07/schema","properties":{"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"reason":{"$id":"/properties/reason","description":"Short reason why user canceled the job","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingCanceled', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingCancellationChargesClicked' track call.
 *
 * @param BookingCancellationChargesClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingCancellationChargesClicked(props: BookingCancellationChargesClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingCancellationChargesClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingCancellationChargesClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingCancellationChargesModuleClosed' track call.
 *
 * @param BookingCancellationChargesModuleClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingCancellationChargesModuleClosed(props: BookingCancellationChargesModuleClosed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingCancellationChargesModuleClosed","$schema":"http://json-schema.org/draft-07/schema","properties":{"closeType":{"$id":"/properties/closeType","description":"Close type by button or click outside","enum":["Button","Outside"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingCancellationChargesModuleClosed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingClearBedroomsClicked' track call.
 *
 * @param BookingClearBedroomsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingClearBedroomsClicked(props: BookingClearBedroomsClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingClearBedroomsClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingClearBedroomsClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingClearPetsClicked' track call.
 *
 * @param BookingClearPetsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingClearPetsClicked(props: BookingClearPetsClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingClearPetsClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingClearPetsClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingClearPropertyFloorClicked' track call.
 *
 * @param BookingClearPropertyFloorClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingClearPropertyFloorClicked(props: BookingClearPropertyFloorClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingClearPropertyFloorClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingClearPropertyFloorClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingClearPropertyTypeClicked' track call.
 *
 * @param BookingClearPropertyTypeClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingClearPropertyTypeClicked(props: BookingClearPropertyTypeClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingClearPropertyTypeClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingClearPropertyTypeClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingCookingStyleEntered' track call.
 *
 * @param BookingCookingStyleEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingCookingStyleEntered(props: BookingCookingStyleEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingCookingStyleEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"cookingStyle":{"$id":"/properties/cookingStyle","description":"Cooking style entered by customer","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingCookingStyleEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingCookingStyleSelected' track call.
 *
 * @param BookingCookingStyleSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingCookingStyleSelected(props: BookingCookingStyleSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingCookingStyleSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"cookingStyle":{"$id":"/properties/cookingStyle","description":"Cooking style eg. italian cuisine","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingCookingStyleSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingDateAndTimeClicked' track call.
 *
 * @param BookingDateAndTimeClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingDateAndTimeClicked(props: BookingDateAndTimeClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingDateAndTimeClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingDateAndTimeClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingDateAndTimeModuleClosed' track call.
 *
 * @param BookingDateAndTimeModuleClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingDateAndTimeModuleClosed(props: BookingDateAndTimeModuleClosed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingDateAndTimeModuleClosed","$schema":"http://json-schema.org/draft-07/schema","properties":{"dateSelected":{"$id":"/properties/dateSelected","description":"Is date selected","type":"boolean"},"timeSelected":{"$id":"/properties/timeSelected","description":"Is time selected","type":"boolean"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingDateAndTimeModuleClosed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingDateAndTimeSelected' track call.
 *
 * @param BookingDateAndTimeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingDateAndTimeSelected(props: BookingDateAndTimeSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingDateAndTimeSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingDateAndTimeSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingDiscountCodeApplied' track call.
 *
 * @param BookingDiscountCodeApplied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingDiscountCodeApplied(props: BookingDiscountCodeApplied, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingDiscountCodeApplied","$schema":"http://json-schema.org/draft-07/schema","properties":{"promoCode":{"$id":"/properties/promoCode","description":"Entered promo code","type":"string"},"success":{"$id":"/properties/success","description":"Is code valid","type":"boolean"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingDiscountCodeApplied', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingDiscountCodeErrorShowed' track call.
 *
 * @param BookingDiscountCodeErrorShowed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingDiscountCodeErrorShowed(props: BookingDiscountCodeErrorShowed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingDiscountCodeErrorShowed","$schema":"http://json-schema.org/draft-07/schema","properties":{"error":{"$id":"/properties/error","description":"Error message","type":"string"},"promoCode":{"$id":"/properties/promoCode","description":"Entered promo code","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingDiscountCodeErrorShowed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingEditAddressClicked' track call.
 *
 * @param BookingEditAddressClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingEditAddressClicked(props: BookingEditAddressClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingEditAddressClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingEditAddressClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingEditPaymentClicked' track call.
 *
 * @param BookingEditPaymentClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingEditPaymentClicked(props: BookingEditPaymentClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingEditPaymentClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingEditPaymentClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingElevatorSelected' track call.
 *
 * @param BookingElevatorSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingElevatorSelected(props: BookingElevatorSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingElevatorSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"elevator":{"$id":"/properties/elevator","description":"If property have elevator","type":"boolean"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingElevatorSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingExistingAddressSelected' track call.
 *
 * @param BookingExistingAddressSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingExistingAddressSelected(props: BookingExistingAddressSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingExistingAddressSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingExistingAddressSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingFoodAllergiesSelected' track call.
 *
 * @param BookingFoodAllergiesSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingFoodAllergiesSelected(props: BookingFoodAllergiesSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingFoodAllergiesSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"allergies":{"$id":"/properties/allergies","description":"Food allergies","type":"boolean"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingFoodAllergiesSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingFrequencyClicked' track call.
 *
 * @param BookingFrequencyClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingFrequencyClicked(props: BookingFrequencyClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingFrequencyClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"frequencyType":{"$id":"/properties/frequencyType","description":"Type of the frequency eg. One Time","enum":["OneTime","Weekly","Fortnightly"],"type":"string"},"position":{"$id":"/properties/position","description":"Index of the frequency placed in the view. Starts from 1","type":"number"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingFrequencyClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingHoursSelected' track call.
 *
 * @param BookingHoursSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingHoursSelected(props: BookingHoursSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingHoursSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"hours":{"$id":"/properties/hours","description":"Number of hours","type":"number"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingHoursSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingImageUploaded' track call.
 *
 * @param BookingImageUploaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingImageUploaded(props: BookingImageUploaded, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingImageUploaded","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingImageUploaded', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingInspectionDateAndTimeModuleClosed' track call.
 *
 * @param BookingInspectionDateAndTimeModuleClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingInspectionDateAndTimeModuleClosed(props: BookingInspectionDateAndTimeModuleClosed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingInspectionDateAndTimeModuleClosed","$schema":"http://json-schema.org/draft-07/schema","properties":{"dateSelected":{"$id":"/properties/dateSelected","description":"Is date selected","type":"boolean"},"numberOfDatesSelected":{"$id":"/properties/numberOfDatesSelected","description":"Number of selected dates","type":"number"},"timeSelected":{"$id":"/properties/timeSelected","description":"Is time selected","type":"boolean"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingInspectionDateAndTimeModuleClosed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingInspectionDateAndTimeSelected' track call.
 *
 * @param BookingInspectionDateAndTimeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingInspectionDateAndTimeSelected(props: BookingInspectionDateAndTimeSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingInspectionDateAndTimeSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingInspectionDateAndTimeSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingInstructionForDriverSelected' track call.
 *
 * @param BookingInstructionForDriverSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingInstructionForDriverSelected(props: BookingInstructionForDriverSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingInstructionForDriverSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"instruction":{"$id":"/properties/instruction","description":"Instruction for the driver eg. Collect items from reception","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingInstructionForDriverSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingLaundryServiceSelected' track call.
 *
 * @param BookingLaundryServiceSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingLaundryServiceSelected(props: BookingLaundryServiceSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingLaundryServiceSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"service":{"$id":"/properties/service","description":"Booking laundry service name eg. Iron only","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingLaundryServiceSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingMoreClicked' track call.
 *
 * @param BookingMoreClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingMoreClicked(props: BookingMoreClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingMoreClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingMoreClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingMovingTypeSelected' track call.
 *
 * @param BookingMovingTypeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingMovingTypeSelected(props: BookingMovingTypeSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingMovingTypeSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"movingType":{"$id":"/properties/movingType","description":"Moving type name","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingMovingTypeSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingNewAddressClicked' track call.
 *
 * @param BookingNewAddressClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingNewAddressClicked(props: BookingNewAddressClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingNewAddressClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingNewAddressClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingNumberOfAirConditionersSelected' track call.
 *
 * @param BookingNumberOfAirConditionersSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingNumberOfAirConditionersSelected(props: BookingNumberOfAirConditionersSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingNumberOfAirConditionersSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"quantity":{"$id":"/properties/quantity","description":"Number of air conditioners","type":"number"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingNumberOfAirConditionersSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPaymentSwitched' track call.
 *
 * @param BookingPaymentSwitched props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPaymentSwitched(props: BookingPaymentSwitched, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPaymentSwitched","$schema":"http://json-schema.org/draft-07/schema","properties":{"selectedPaymentMethod":{"$id":"/properties/selectedPaymentMethod","description":"Selected payment method","enum":["CreditCard","SendPay"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPaymentSwitched', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPestControlServiceSelected' track call.
 *
 * @param BookingPestControlServiceSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPestControlServiceSelected(props: BookingPestControlServiceSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPestControlServiceSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"service":{"$id":"/properties/service","description":"Pest control service name eg. Common ants","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPestControlServiceSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPriceReferenceClicked' track call.
 *
 * @param BookingPriceReferenceClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPriceReferenceClicked(props: BookingPriceReferenceClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPriceReferenceClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPriceReferenceClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPriceReferenceClosed' track call.
 *
 * @param BookingPriceReferenceClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPriceReferenceClosed(props: BookingPriceReferenceClosed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPriceReferenceClosed","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPriceReferenceClosed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPriceReferenceTabClicked' track call.
 *
 * @param BookingPriceReferenceTabClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPriceReferenceTabClicked(props: BookingPriceReferenceTabClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPriceReferenceTabClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"tabName":{"$id":"/properties/tabName","description":"Price reference tab name","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPriceReferenceTabClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPromoCodeEntered' track call.
 *
 * @param BookingPromoCodeEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPromoCodeEntered(props: BookingPromoCodeEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPromoCodeEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPromoCodeEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPropertyFloorClicked' track call.
 *
 * @param BookingPropertyFloorClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPropertyFloorClicked(props: BookingPropertyFloorClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPropertyFloorClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPropertyFloorClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPropertyFloorSelected' track call.
 *
 * @param BookingPropertyFloorSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPropertyFloorSelected(props: BookingPropertyFloorSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPropertyFloorSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"number":{"$id":"/properties/number","description":"Number of selected floors","type":"number"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPropertyFloorSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPropertyOptionSelected' track call.
 *
 * @param BookingPropertyOptionSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPropertyOptionSelected(props: BookingPropertyOptionSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPropertyOptionSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"propertyOption":{"$id":"/properties/propertyOption","description":"Booking property option eg. I have an oven but no dishwasher","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPropertyOptionSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPropertySizeSelected' track call.
 *
 * @param BookingPropertySizeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPropertySizeSelected(props: BookingPropertySizeSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPropertySizeSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"propertySize":{"$id":"/properties/propertySize","description":"Size of the property eg. 800 - 1000 sqft","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPropertySizeSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPropertyTypeClicked' track call.
 *
 * @param BookingPropertyTypeClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPropertyTypeClicked(props: BookingPropertyTypeClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPropertyTypeClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPropertyTypeClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPropertyTypeSelected' track call.
 *
 * @param BookingPropertyTypeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPropertyTypeSelected(props: BookingPropertyTypeSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPropertyTypeSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"propertyType":{"$id":"/properties/propertyType","description":"Type of the property eg. HDB","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPropertyTypeSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPurchaseSendPayCreditsClicked' track call.
 *
 * @param BookingPurchaseSendPayCreditsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPurchaseSendPayCreditsClicked(props: BookingPurchaseSendPayCreditsClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPurchaseSendPayCreditsClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPurchaseSendPayCreditsClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingPurchasingServicesSelected' track call.
 *
 * @param BookingPurchasingServicesSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingPurchasingServicesSelected(props: BookingPurchasingServicesSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingPurchasingServicesSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingPurchasingServicesSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingRescheduled' track call.
 *
 * @param BookingRescheduled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingRescheduled(props: BookingRescheduled, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingRescheduled","$schema":"http://json-schema.org/draft-07/schema","properties":{"cancellationFeesCharged":{"$id":"/properties/cancellationFeesCharged","description":"Job frequency eg. Weekly, Bi-weekly, Monthly","type":"string"},"dateTime":{"$id":"/properties/dateTime","description":"New date time ","type":"string"},"jobFrequency":{"$id":"/properties/jobFrequency","description":"Job frequency eg. Weekly, Bi-weekly, Monthly","type":"string"},"jobId":{"$id":"/properties/jobId","description":"Job id of the job","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingRescheduled', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingSelectSavedAddressClicked' track call.
 *
 * @param BookingSelectSavedAddressClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingSelectSavedAddressClicked(props: BookingSelectSavedAddressClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingSelectSavedAddressClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingSelectSavedAddressClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingSelectServiceTypeClicked' track call.
 *
 * @param BookingSelectServiceTypeClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingSelectServiceTypeClicked(props: BookingSelectServiceTypeClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingSelectServiceTypeClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingSelectServiceTypeClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingServiceDescriptionModuleClosed' track call.
 *
 * @param BookingServiceDescriptionModuleClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingServiceDescriptionModuleClosed(props: BookingServiceDescriptionModuleClosed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingServiceDescriptionModuleClosed","$schema":"http://json-schema.org/draft-07/schema","properties":{"closeType":{"$id":"/properties/closeType","description":"Close type by button or click outside","enum":["Button","Outside"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingServiceDescriptionModuleClosed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingServicePlanClicked' track call.
 *
 * @param BookingServicePlanClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingServicePlanClicked(props: BookingServicePlanClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingServicePlanClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"position":{"$id":"/properties/position","description":"Index of the service plan placed in the view. Starts from 1","type":"number"},"servicePlan":{"$id":"/properties/servicePlan","description":"Type of the service plan eg. One Time","enum":["OneTime","Contract","Custom"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingServicePlanClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingServiceTypeSelected' track call.
 *
 * @param BookingServiceTypeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingServiceTypeSelected(props: BookingServiceTypeSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingServiceTypeSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"category":{"$id":"/properties/category","description":"Selected category eg. General","type":"string"},"item":{"$id":"/properties/item","description":"Selected item eg. Door","type":"string"},"type":{"$id":"/properties/type","description":"Selected type eg. Repair","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingServiceTypeSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingStarted' track call.
 *
 * @param BookingStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingStarted(props: BookingStarted, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingStarted","$schema":"http://json-schema.org/draft-07/schema","properties":{"bookingSessionId":{"$id":"/properties/bookingSessionId","description":"Unique Booking Session ID","type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingStarted', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingSummary' track call.
 *
 * @param BookingSummary props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingSummary(props: BookingSummary, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingSummary","$schema":"http://json-schema.org/draft-07/schema","properties":{"amount":{"$id":"/properties/amount","description":"Total amount of the purchase","type":"number"},"bookingSessionId":{"$id":"/properties/bookingSessionId","description":"Unique Booking Session ID","type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingSummary', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingTaskOrErrandTypeSelected' track call.
 *
 * @param BookingTaskOrErrandTypeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingTaskOrErrandTypeSelected(props: BookingTaskOrErrandTypeSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingTaskOrErrandTypeSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"type":{"$id":"/properties/type","description":"Selected task or errand type eg. Grocery Shopping","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingTaskOrErrandTypeSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingUploadedImageRemoveClicked' track call.
 *
 * @param BookingUploadedImageRemoveClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingUploadedImageRemoveClicked(props: BookingUploadedImageRemoveClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingUploadedImageRemoveClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingUploadedImageRemoveClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookingWhatsIncludedAndHowItWorksClicked' track call.
 *
 * @param BookingWhatsIncludedAndHowItWorksClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookingWhatsIncludedAndHowItWorksClicked(props: BookingWhatsIncludedAndHowItWorksClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"BookingWhatsIncludedAndHowItWorksClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('BookingWhatsIncludedAndHowItWorksClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CallSupplierClicked' track call.
 *
 * @param CallSupplierClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function callSupplierClicked(props: CallSupplierClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CallSupplierClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"},"supplierId":{"$id":"/properties/supplierId","description":"ID of the supplier","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CallSupplierClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CancelBookingClicked' track call.
 *
 * @param CancelBookingClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cancelBookingClicked(props: CancelBookingClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CancelBookingClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CancelBookingClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ChatWithSupplierClicked' track call.
 *
 * @param ChatWithSupplierClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function chatWithSupplierClicked(props: ChatWithSupplierClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"ChatWithSupplierClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"},"supplierId":{"$id":"/properties/supplierId","description":"ID of the supplier","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('ChatWithSupplierClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CleaningPayment' track call.
 *
 * @param CleaningPayment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cleaningPayment(props: CleaningPayment, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CleaningPayment","$schema":"http://json-schema.org/draft-07/schema","properties":{"paymentType":{"$id":"/properties/paymentType","description":"Payment Method used to complete the booking","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CleaningPayment', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CleaningPlanFrequencySelected' track call.
 *
 * @param CleaningPlanFrequencySelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cleaningPlanFrequencySelected(props: CleaningPlanFrequencySelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CleaningPlanFrequencySelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Called when user clicks on cleaning frequency","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CleaningPlanFrequencySelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CleaningPlanSelected' track call.
 *
 * @param CleaningPlanSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cleaningPlanSelected(props: CleaningPlanSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CleaningPlanSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Called when user clicks on cleaning plan","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CleaningPlanSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContinueAsGuestClicked' track call.
 *
 * @param ContinueAsGuestClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function continueAsGuestClicked(props: ContinueAsGuestClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"ContinueAsGuestClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('ContinueAsGuestClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CookingPayment' track call.
 *
 * @param CookingPayment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cookingPayment(props: CookingPayment, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CookingPayment","$schema":"http://json-schema.org/draft-07/schema","properties":{"paymentType":{"$id":"/properties/paymentType","description":"Payment Method used to complete the booking","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CookingPayment', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CreditCardAdded' track call.
 *
 * @param CreditCardAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditCardAdded(props: CreditCardAdded, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CreditCardAdded","$schema":"http://json-schema.org/draft-07/schema","properties":{"cardType":{"$id":"/properties/cardType","description":"Card type eg. Visa","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CreditCardAdded', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CreditCardCVVEntered' track call.
 *
 * @param CreditCardCVVEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditCardCVVEntered(props: CreditCardCVVEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CreditCardCVVEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CreditCardCVVEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CreditCardCancelClicked' track call.
 *
 * @param CreditCardCancelClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditCardCancelClicked(props: CreditCardCancelClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CreditCardCancelClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CreditCardCancelClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CreditCardDateEntered' track call.
 *
 * @param CreditCardDateEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditCardDateEntered(props: CreditCardDateEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CreditCardDateEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CreditCardDateEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CreditCardInvalidNumberEntered' track call.
 *
 * @param CreditCardInvalidNumberEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditCardInvalidNumberEntered(props: CreditCardInvalidNumberEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CreditCardInvalidNumberEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CreditCardInvalidNumberEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CreditCardModuleClosed' track call.
 *
 * @param CreditCardModuleClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditCardModuleClosed(props: CreditCardModuleClosed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CreditCardModuleClosed","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CreditCardModuleClosed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CreditCardNameEntered' track call.
 *
 * @param CreditCardNameEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditCardNameEntered(props: CreditCardNameEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CreditCardNameEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CreditCardNameEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CreditCardNumberEntered' track call.
 *
 * @param CreditCardNumberEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditCardNumberEntered(props: CreditCardNumberEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CreditCardNumberEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CreditCardNumberEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossSaleBookNowClicked' track call.
 *
 * @param CrossSaleBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossSaleBookNowClicked(props: CrossSaleBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossSaleBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossSaleBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossaleAirconBookNowClicked' track call.
 *
 * @param CrossaleAirconBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossaleAirconBookNowClicked(props: CrossaleAirconBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossaleAirconBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossaleAirconBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossaleCleaningBookNowClicked' track call.
 *
 * @param CrossaleCleaningBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossaleCleaningBookNowClicked(props: CrossaleCleaningBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossaleCleaningBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossaleCleaningBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossaleCookingBookNowClicked' track call.
 *
 * @param CrossaleCookingBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossaleCookingBookNowClicked(props: CrossaleCookingBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossaleCookingBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossaleCookingBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossaleDeepCleaningBookNowClicked' track call.
 *
 * @param CrossaleDeepCleaningBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossaleDeepCleaningBookNowClicked(props: CrossaleDeepCleaningBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossaleDeepCleaningBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossaleDeepCleaningBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossaleHandymanBookNowClicked' track call.
 *
 * @param CrossaleHandymanBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossaleHandymanBookNowClicked(props: CrossaleHandymanBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossaleHandymanBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossaleHandymanBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossaleLaundryBookNowClicked' track call.
 *
 * @param CrossaleLaundryBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossaleLaundryBookNowClicked(props: CrossaleLaundryBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossaleLaundryBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossaleLaundryBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossaleMoversAndPackersBookNowClicked' track call.
 *
 * @param CrossaleMoversAndPackersBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossaleMoversAndPackersBookNowClicked(props: CrossaleMoversAndPackersBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossaleMoversAndPackersBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossaleMoversAndPackersBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossaleMyBookingsClicked' track call.
 *
 * @param CrossaleMyBookingsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossaleMyBookingsClicked(props: CrossaleMyBookingsClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossaleMyBookingsClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossaleMyBookingsClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossalePestControlBookNowClicked' track call.
 *
 * @param CrossalePestControlBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossalePestControlBookNowClicked(props: CrossalePestControlBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossalePestControlBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossalePestControlBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CrossaleTasksAndErrandsBookNowClicked' track call.
 *
 * @param CrossaleTasksAndErrandsBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function crossaleTasksAndErrandsBookNowClicked(props: CrossaleTasksAndErrandsBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"CrossaleTasksAndErrandsBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('CrossaleTasksAndErrandsBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'DeepLinkOpened' track call.
 *
 * @param DeepLinkOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function deepLinkOpened(props: DeepLinkOpened, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Deep_Link_Opened","$schema":"http://json-schema.org/draft-07/schema","properties":{"referring_application":{"$id":"/properties/referring_application","description":"Referring application name","type":"string"},"url":{"$id":"/properties/url","description":"Url that opened the app","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Deep Link Opened', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'DeepCleaningPayment' track call.
 *
 * @param DeepCleaningPayment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function deepCleaningPayment(props: DeepCleaningPayment, options?: Options, callback?: Callback): void {

    const schema = {"$id":"DeepCleaningPayment","$schema":"http://json-schema.org/draft-07/schema","properties":{"paymentType":{"$id":"/properties/paymentType","description":"Payment Method used to complete the booking","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('DeepCleaningPayment', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'DynamicPricingSelected' track call.
 *
 * @param DynamicPricingSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dynamicPricingSelected(props: DynamicPricingSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"DynamicPricingSelected","$schema":"http://json-schema.org/draft-07/schema","properties":{"dayOfTheWeek":{"$id":"/properties/dayOfTheWeek","description":"Day of the week","type":"string"},"pricePerHour":{"$id":"/properties/pricePerHour","description":"Price per hour","type":"number"},"timeOfTheDay":{"$id":"/properties/timeOfTheDay","description":"Time of the day","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('DynamicPricingSelected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'EditProfileClicked' track call.
 *
 * @param EditProfileClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function editProfileClicked(props: EditProfileClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"EditProfileClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('EditProfileClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ExternalLinkClicked' track call.
 *
 * @param ExternalLinkClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function externalLinkClicked(props: ExternalLinkClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"ExternalLinkClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"url":{"$id":"/properties/url","description":"External url","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('ExternalLinkClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FavouritePartnerSet' track call.
 *
 * @param FavouritePartnerSet props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function favouritePartnerSet(props: FavouritePartnerSet, options?: Options, callback?: Callback): void {

    const schema = {"$id":"FavouritePartnerSet","$schema":"http://json-schema.org/draft-07/schema","properties":{"partner":{"$id":"/properties/partner","description":"Favourite partner details","properties":{"partnerHandle":{"$id":"/properties/properties/properties/partner/properties/partnerHandle","description":"Handle of the favourite partner (supplier)","type":"string"},"partnerId":{"$id":"/properties/properties/properties/partner/properties/partnerId","description":"Id of the favourite partner (supplier)","type":"string"}},"type":"object"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('FavouritePartnerSet', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'HandymanPayment' track call.
 *
 * @param HandymanPayment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function handymanPayment(props: HandymanPayment, options?: Options, callback?: Callback): void {

    const schema = {"$id":"HandymanPayment","$schema":"http://json-schema.org/draft-07/schema","properties":{"paymentType":{"$id":"/properties/paymentType","description":"Payment Method used to complete the booking","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('HandymanPayment', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'InstallAttributed' track call.
 *
 * @param InstallAttributed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function installAttributed(props: InstallAttributed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Install_Attributed","$schema":"http://json-schema.org/draft-07/schema","properties":{"campaign":{"$id":"/properties/traits/properties/campaign","description":"Campaign info","properties":{"name":{"$id":"/properties/traits/properties/device/properties/name","description":"Name of the campaign","type":"string"},"source":{"$id":"/properties/traits/properties/device/properties/source","description":"Source of the campaign","type":"string"}},"type":"object"},"provider":{"$id":"/properties/provider","description":"Name of the provider","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Install Attributed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'JobAccepted' track call.
 *
 * @param JobAccepted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function jobAccepted(props: JobAccepted, options?: Options, callback?: Callback): void {

    const schema = {"$id":"JobAccepted","$schema":"http://json-schema.org/draft-07/schema","properties":{"amount":{"$id":"/properties/amount","description":"Total amount of the purchase","type":"number"},"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"jobStatus":{"$id":"/properties/jobStatus","description":"Status of the job eg. Paused","enum":["Submitted","Accepted","Cancelled","Completed","Rated","Paused","Hold"],"type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"},"supplierId":{"$id":"/properties/supplierId","description":"Supplier ID who accepted the job","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('JobAccepted', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'JobCancelled' track call.
 *
 * @param JobCancelled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function jobCancelled(props: JobCancelled, options?: Options, callback?: Callback): void {

    const schema = {"$id":"JobCancelled","$schema":"http://json-schema.org/draft-07/schema","properties":{"amount":{"$id":"/properties/amount","description":"Total amount of the purchase","type":"number"},"cancellationFees":{"$id":"/properties/cancellationFees","description":"Amount of the cancellation fees","type":"number"},"cancellationType":{"$id":"/properties/cancellationType","description":"From where the job cancellation is triggered, e.g - CustomerPreAcceptance, CustomerPostAcceptance, Supplier, Console","enum":["CustomerPreAcceptance","CustomerPostAcceptance","Supplier","Console"],"type":"string"},"jobAcceptanceStatus":{"$id":"/properties/jobAcceptanceStatus","description":"The status of the job at cancellation: JobAccepted, JobNotAccepted","enum":["JobAccepted","JobNotAccepted"],"type":"string"},"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"recurring":{"$id":"/properties/recurring","description":"Job type - adhoc or recurring","type":"boolean"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('JobCancelled', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'JobClicked' track call.
 *
 * @param JobClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function jobClicked(props: JobClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"JobClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"jobStatus":{"$id":"/properties/jobStatus","description":"Status of the job eg. Paused","enum":["Submitted","Accepted","Cancelled","Completed","Rated","Paused","Hold"],"type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('JobClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'JobCompleted' track call.
 *
 * @param JobCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function jobCompleted(props: JobCompleted, options?: Options, callback?: Callback): void {

    const schema = {"$id":"JobCompleted","$schema":"http://json-schema.org/draft-07/schema","properties":{"amount":{"$id":"/properties/amount","description":"Total amount of the purchase","type":"number"},"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"jobStatus":{"$id":"/properties/jobStatus","description":"Status of the job eg. Paused","enum":["Submitted","Accepted","Cancelled","Completed","Rated","Paused","Hold"],"type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"},"supplierId":{"$id":"/properties/supplierId","description":"Supplier ID who completed the job","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('JobCompleted', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'JobExpired' track call.
 *
 * @param JobExpired props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function jobExpired(props: JobExpired, options?: Options, callback?: Callback): void {

    const schema = {"$id":"JobExpired","$schema":"http://json-schema.org/draft-07/schema","properties":{"amount":{"$id":"/properties/amount","description":"Total amount of the purchase","type":"number"},"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('JobExpired', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'JobSessionExtended' track call.
 *
 * @param JobSessionExtended props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function jobSessionExtended(props: JobSessionExtended, options?: Options, callback?: Callback): void {

    const schema = {"$id":"JobSessionExtended","$schema":"http://json-schema.org/draft-07/schema","properties":{"extraHours":{"$id":"/properties/extraHours","description":"Number of extended hours","type":"number"},"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"},"supplierId":{"$id":"/properties/supplierId","description":"ID of the supplier","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('JobSessionExtended', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'LaundryPayment' track call.
 *
 * @param LaundryPayment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function laundryPayment(props: LaundryPayment, options?: Options, callback?: Callback): void {

    const schema = {"$id":"LaundryPayment","$schema":"http://json-schema.org/draft-07/schema","properties":{"paymentType":{"$id":"/properties/paymentType","description":"Payment Method used to complete the booking","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('LaundryPayment', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingAffiliateClicked' track call.
 *
 * @param MarketingAffiliateClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingAffiliateClicked(props: MarketingAffiliateClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingAffiliateClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"formCompletion":{"$id":"/properties/formCompletion","description":"Was form completed","type":"boolean"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingAffiliateClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingBecomeAPartnerClicked' track call.
 *
 * @param MarketingBecomeAPartnerClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingBecomeAPartnerClicked(props: MarketingBecomeAPartnerClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingBecomeAPartnerClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingBecomeAPartnerClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingBlogClicked' track call.
 *
 * @param MarketingBlogClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingBlogClicked(props: MarketingBlogClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingBlogClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingBlogClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingBookNowClicked' track call.
 *
 * @param MarketingBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingBookNowClicked(props: MarketingBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"},"placement":{"$id":"/properties/placement","description":"Call to action placement eg. Main banner","type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingBookServiceClicked' track call.
 *
 * @param MarketingBookServiceClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingBookServiceClicked(props: MarketingBookServiceClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingBookServiceClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"baseURI":{"$id":"/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/pageName","description":"Page name eg. HelpCenter","type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"serviceId":{"$id":"/properties/serviceId","description":"Service Id","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingBookServiceClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingClickBookNowOnOfferBanner' track call.
 *
 * @param MarketingClickBookNowOnOfferBanner props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingClickBookNowOnOfferBanner(props: MarketingClickBookNowOnOfferBanner, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingClickBookNowOnOfferBanner","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"eventName":{"$id":"/properties/properties/properties/eventData/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/properties/properties/eventData/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingClickBookNowOnOfferBanner', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingContactClicked' track call.
 *
 * @param MarketingContactClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingContactClicked(props: MarketingContactClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingContactClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"baseURI":{"$id":"/properties/baseURI","description":"Base URL","type":"string"},"contactType":{"$id":"/properties/contactType","description":"Contact type eg. Email","enum":["Email","Hotline","Whatsapp"],"type":"string"},"formSubmission":{"$id":"/properties/formSubmission","description":"Form was submitted","type":"boolean"},"pageName":{"$id":"/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingContactClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingContactUsClicked' track call.
 *
 * @param MarketingContactUsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingContactUsClicked(props: MarketingContactUsClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingContactUsClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"eventName":{"$id":"/properties/properties/properties/eventData/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/properties/properties/eventData/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingContactUsClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingDealsClicked' track call.
 *
 * @param MarketingDealsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingDealsClicked(props: MarketingDealsClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingDealsClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"baseURI":{"$id":"/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingDealsClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingEnterEmailSubscribed' track call.
 *
 * @param MarketingEnterEmailSubscribed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingEnterEmailSubscribed(props: MarketingEnterEmailSubscribed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingEnterEmailSubscribed","$schema":"http://json-schema.org/draft-07/schema","properties":{"email":{"$id":"/properties/email","description":"Newsletter Subscribe Email","type":"string"},"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingEnterEmailSubscribed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingEnterPostCode' track call.
 *
 * @param MarketingEnterPostCode props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingEnterPostCode(props: MarketingEnterPostCode, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingEnterPostCode","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingEnterPostCode', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingExternalLinkClicked' track call.
 *
 * @param MarketingExternalLinkClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingExternalLinkClicked(props: MarketingExternalLinkClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingExternalLinkClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"baseURI":{"$id":"/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/pageName","description":"Page name eg. HelpCenter","type":"string"},"url":{"$id":"/properties/url","description":"External url","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingExternalLinkClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingHelpCentreClicked' track call.
 *
 * @param MarketingHelpCentreClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingHelpCentreClicked(props: MarketingHelpCentreClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingHelpCentreClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"},"module":{"$id":"/properties/module","description":"Name of the module","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingHelpCentreClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingHelpCentreSearched' track call.
 *
 * @param MarketingHelpCentreSearched props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingHelpCentreSearched(props: MarketingHelpCentreSearched, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingHelpCentreSearched","$schema":"http://json-schema.org/draft-07/schema","properties":{"keywordUsed":{"$id":"/properties/keywordUsed","description":"Search keywords","type":"string"},"searchResults":{"$id":"/properties/searchResults","description":"Search results","type":"array"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingHelpCentreSearched', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingMobileAppDownload' track call.
 *
 * @param MarketingMobileAppDownload props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingMobileAppDownload(props: MarketingMobileAppDownload, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingMobileAppDownload","$schema":"http://json-schema.org/draft-07/schema","properties":{"os":{"$id":"/properties/os","description":"Name of the OS","enum":["iOS","Android"],"type":"string"},"pageName":{"$id":"/properties/pageName","description":"Page name from where user clicked on download CTA","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingMobileAppDownload', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingMobileAppDownloadClicked' track call.
 *
 * @param MarketingMobileAppDownloadClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingMobileAppDownloadClicked(props: MarketingMobileAppDownloadClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingMobileAppDownloadClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"os":{"$id":"/properties/os","description":"OS eg. Android","type":"string"},"pageName":{"$id":"/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingMobileAppDownloadClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingNewsletterSubscribed' track call.
 *
 * @param MarketingNewsletterSubscribed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingNewsletterSubscribed(props: MarketingNewsletterSubscribed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingNewsletterSubscribed","$schema":"http://json-schema.org/draft-07/schema","properties":{"baseURI":{"$id":"/properties/baseURI","description":"Base URL","type":"string"},"email":{"$id":"/properties/email","description":"Newsletter Subscribe Email","type":"string"},"pageName":{"$id":"/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingNewsletterSubscribed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingNotificationClicked' track call.
 *
 * @param MarketingNotificationClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingNotificationClicked(props: MarketingNotificationClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingNotificationClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingNotificationClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingOurServicesClicked' track call.
 *
 * @param MarketingOurServicesClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingOurServicesClicked(props: MarketingOurServicesClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingOurServicesClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingOurServicesClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingPageViewed' track call.
 *
 * @param MarketingPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingPageViewed(props: MarketingPageViewed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingPageViewed","$schema":"http://json-schema.org/draft-07/schema","properties":{"device":{"$id":"/properties/device","description":"Device info","properties":{"deviceName":{"$id":"/properties/properties/properties/device/properties/deviceName","description":"Device name eg. Samsung Galaxy","type":"string"},"deviceOS":{"$id":"/properties/properties/properties/device/properties/deviceOS","description":"Device OS eg. Windows","type":"string"},"deviceType":{"$id":"/properties/properties/properties/device/properties/deviceType","description":"Device type eg. Tablet","type":"string"}},"type":"object"},"pageName":{"$id":"/properties/pageName","description":"Page name eg. HelpCenter","type":"string"},"userId":{"$id":"/properties/userId","description":"User ID","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingPageViewed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingPartnerSignedUp' track call.
 *
 * @param MarketingPartnerSignedUp props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingPartnerSignedUp(props: MarketingPartnerSignedUp, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingPartnerSignedUp","$schema":"http://json-schema.org/draft-07/schema","properties":{"baseURI":{"$id":"/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingPartnerSignedUp', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingReviewClicked' track call.
 *
 * @param MarketingReviewClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingReviewClicked(props: MarketingReviewClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingReviewClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingReviewClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingScrollToBottom' track call.
 *
 * @param MarketingScrollToBottom props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingScrollToBottom(props: MarketingScrollToBottom, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingScrollToBottom","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingScrollToBottom', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingSearchBookNowClicked' track call.
 *
 * @param MarketingSearchBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingSearchBookNowClicked(props: MarketingSearchBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingSearchBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingSearchBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingSearchPostCodeClicked' track call.
 *
 * @param MarketingSearchPostCodeClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingSearchPostCodeClicked(props: MarketingSearchPostCodeClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingSearchPostCodeClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingSearchPostCodeClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingSearchServiceClicked' track call.
 *
 * @param MarketingSearchServiceClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingSearchServiceClicked(props: MarketingSearchServiceClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingSearchServiceClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"placement":{"$id":"/properties/placement","description":"Call to action placement eg. Main banner","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingSearchServiceClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingSearchServiceTypeClicked' track call.
 *
 * @param MarketingSearchServiceTypeClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingSearchServiceTypeClicked(props: MarketingSearchServiceTypeClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingSearchServiceTypeClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingSearchServiceTypeClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingServiceClicked' track call.
 *
 * @param MarketingServiceClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingServiceClicked(props: MarketingServiceClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingServiceClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"baseURI":{"$id":"/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/pageName","description":"Page name eg. HelpCenter","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingServiceClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingServiceIconClicked' track call.
 *
 * @param MarketingServiceIconClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingServiceIconClicked(props: MarketingServiceIconClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingServiceIconClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingServiceIconClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingUserClicked' track call.
 *
 * @param MarketingUserClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingUserClicked(props: MarketingUserClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MarketingUserClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"eventData":{"$id":"/properties/eventData","description":"Additional data from the event","properties":{"baseURI":{"$id":"/properties/properties/properties/eventData/properties/baseURI","description":"Base URL","type":"string"},"pageName":{"$id":"/properties/properties/properties/eventData/properties/pageName","description":"Page name eg. HelpCenter","type":"string"}},"type":"object"},"eventName":{"$id":"/properties/eventName","description":"Name of the event","type":"string"},"eventType":{"$id":"/properties/eventType","description":"Eg: Input or Click event","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MarketingUserClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MobileAppDownload' track call.
 *
 * @param MobileAppDownload props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mobileAppDownload(props: MobileAppDownload, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MobileAppDownload","$schema":"http://json-schema.org/draft-07/schema","properties":{"os":{"$id":"/properties/os","description":"Name of the OS","enum":["iOS","Android"],"type":"string"},"pageName":{"$id":"/properties/pageName","description":"Page name from where user clicked on download CTA","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MobileAppDownload', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MoversAndPackersPayment' track call.
 *
 * @param MoversAndPackersPayment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function moversAndPackersPayment(props: MoversAndPackersPayment, options?: Options, callback?: Callback): void {

    const schema = {"$id":"MoversAndPackersPayment","$schema":"http://json-schema.org/draft-07/schema","properties":{"paymentType":{"$id":"/properties/paymentType","description":"Payment Method used to complete the booking","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('MoversAndPackersPayment', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'OrganicInstall' track call.
 *
 * @param OrganicInstall props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function organicInstall(props: OrganicInstall, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Organic_Install","$schema":"http://json-schema.org/draft-07/schema","properties":{"provider":{"$id":"/properties/provider","description":"Name of the provider","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Organic Install', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PaymentCharged' track call.
 *
 * @param PaymentCharged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paymentCharged(props: PaymentCharged, options?: Options, callback?: Callback): void {

    const schema = {"$id":"PaymentCharged","$schema":"http://json-schema.org/draft-07/schema","properties":{"amount":{"$id":"/properties/amount","description":"Total amount of the purchase","type":"number"},"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"jobStatus":{"$id":"/properties/jobStatus","description":"Status of the job eg. Paused","enum":["Submitted","Accepted","Cancelled","Completed","Rated","Paused","Hold"],"type":"string"},"paymentMethod":{"$id":"/properties/paymentMethod","description":"Type of the payment method","enum":["Card","SendPay","Combined"],"type":"string"},"paymentType":{"$id":"/properties/paymentType","description":"Type of the payment","enum":["Acceptance","Completion","NewSession"],"type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('PaymentCharged', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PestPayment' track call.
 *
 * @param PestPayment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pestPayment(props: PestPayment, options?: Options, callback?: Callback): void {

    const schema = {"$id":"PestPayment","$schema":"http://json-schema.org/draft-07/schema","properties":{"paymentType":{"$id":"/properties/paymentType","description":"Payment Method used to complete the booking","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('PestPayment', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PostcodeEntered' track call.
 *
 * @param PostcodeEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postcodeEntered(props: PostcodeEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"PostcodeEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"postcode":{"$id":"/properties/postcode","description":"Entered postcode","type":"string"},"success":{"$id":"/properties/success","description":"Is postcode valid","type":"boolean"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('PostcodeEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PriceGuideHandymanClicked' track call.
 *
 * @param PriceGuideHandymanClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function priceGuideHandymanClicked(props: PriceGuideHandymanClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"PriceGuideHandymanClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Price guide modal in Handyman service is clicked.","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('PriceGuideHandymanClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'Purchase' track call.
 *
 * @param Purchase props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function purchase(props: Purchase, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Purchase","$schema":"http://json-schema.org/draft-07/schema","properties":{"currency":{"$id":"/properties/currency","description":"Currency of the purchase","type":"string"},"fbc":{"$id":"/properties/fbc","description":"Meta analytics click id","type":"string"},"fbp":{"$id":"/properties/fbp","description":"Meta analytics browser id","type":"string"},"ga_client_id":{"$id":"/properties/ga_client_id","description":"Google analytics client id","type":"string"},"ga_session_id":{"$id":"/properties/ga_session_id","description":"Google analytics sessions Id","type":"string"},"ga_session_number":{"$id":"/properties/ga_session_number","description":"Google analytics sessions number","type":"number"},"items":{"$id":"/properties/items","description":"Additional data from the event","items":{"properties":{"currency":{"description":"Currency of the item","type":"string"},"item_id":{"description":"Job ID","type":"string"},"item_name":{"description":"Product Name","type":"string"},"price":{"description":"Price of the item","type":"number"}},"type":"object"},"type":"array"},"value":{"$id":"/properties/value","description":"Amount of the purchase","type":"number"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Purchase', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PurchaseSendPayCreditPackClicked' track call.
 *
 * @param PurchaseSendPayCreditPackClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function purchaseSendPayCreditPackClicked(props: PurchaseSendPayCreditPackClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"PurchaseSendPayCreditPackClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"creditPack":{"$id":"/properties/creditPack","description":"Credit Pack details","properties":{"name":{"$id":"/properties/properties/properties/creditPack/properties/name","description":"Credit Pack name eg. Credit Pack 1000","type":"string"},"value":{"$id":"/properties/properties/properties/creditPack/properties/value","description":"Credit Pack value","type":"number"}},"type":"object"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('PurchaseSendPayCreditPackClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PurchaseTopUpSendPayCreditsClicked' track call.
 *
 * @param PurchaseTopUpSendPayCreditsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function purchaseTopUpSendPayCreditsClicked(props: PurchaseTopUpSendPayCreditsClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"PurchaseTopUpSendPayCreditsClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"topUpAmount":{"$id":"/properties/topUpAmount","description":"Amount of purchase (manual)","type":"number"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('PurchaseTopUpSendPayCreditsClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PurchasedSendPayCredits' track call.
 *
 * @param PurchasedSendPayCredits props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function purchasedSendPayCredits(props: PurchasedSendPayCredits, options?: Options, callback?: Callback): void {

    const schema = {"$id":"PurchasedSendPayCredits","$schema":"http://json-schema.org/draft-07/schema","properties":{"amount":{"$id":"/properties/amount","description":"Total amount of the purchase","type":"number"},"paymentMethodType":{"$id":"/properties/paymentMethodType","description":"Type of the payment method eg. Grab","enum":["Card","Grab","Credits"],"type":"string"},"purchaseType":{"$id":"/properties/purchaseType","description":"Type of the purchase","enum":["Manual","Package"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('PurchasedSendPayCredits', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ReferFriendClicked' track call.
 *
 * @param ReferFriendClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function referFriendClicked(props: ReferFriendClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"ReferFriendClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"referralCode":{"$id":"/properties/referralCode","description":"Referral code","type":"string"},"sharePlatform":{"$id":"/properties/sharePlatform","description":"Name of the platform where user wants to share referral code","enum":["Clipboard","WhatsApp","Messenger","Instagram","Telegram","Email","Other"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('ReferFriendClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ReferralCodeCopied' track call.
 *
 * @param ReferralCodeCopied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function referralCodeCopied(props: ReferralCodeCopied, options?: Options, callback?: Callback): void {

    const schema = {"$id":"ReferralCodeCopied","$schema":"http://json-schema.org/draft-07/schema","properties":{"referralCode":{"$id":"/properties/referralCode","description":"Referral code","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('ReferralCodeCopied', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'RescheduleBookingClicked' track call.
 *
 * @param RescheduleBookingClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function rescheduleBookingClicked(props: RescheduleBookingClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"RescheduleBookingClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('RescheduleBookingClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'RescheduleRequestClicked' track call.
 *
 * @param RescheduleRequestClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function rescheduleRequestClicked(props: RescheduleRequestClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"RescheduleRequestClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('RescheduleRequestClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SelectedServiceFromDropdown' track call.
 *
 * @param SelectedServiceFromDropdown props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function selectedServiceFromDropdown(props: SelectedServiceFromDropdown, options?: Options, callback?: Callback): void {

    const schema = {"$id":"SelectedServiceFromDropdown","$schema":"http://json-schema.org/draft-07/schema","properties":{"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('SelectedServiceFromDropdown', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ServiceBooked' track call.
 *
 * @param ServiceBooked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function serviceBooked(props: ServiceBooked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"ServiceBooked","$schema":"http://json-schema.org/draft-07/schema","properties":{"amount":{"$id":"/properties/amount","description":"Total amount of the purchase","type":"number"},"bookingSessionId":{"$id":"/properties/bookingSessionId","description":"Unique Booking Session ID","type":"string"},"fbc":{"$id":"/properties/fbc","description":"Meta analytics click id","type":"string"},"fbp":{"$id":"/properties/fbp","description":"Meta analytics browser id","type":"string"},"ga_client_id":{"$id":"/properties/ga_client_id","description":"Google analytics client id","type":"string"},"ga_session_id":{"$id":"/properties/ga_session_id","description":"Google analytics sessions Id","type":"string"},"ga_session_number":{"$id":"/properties/ga_session_number","description":"Google analytics sessions number","type":"number"},"jobId":{"$id":"/properties/jobId","description":"Short ID of the job eg. SG-D178244","type":"string"},"jobType":{"$id":"/properties/jobType","description":"Type of job eg. Adhoc or Recurring","type":"string"},"postcode":{"$id":"/properties/postcode","description":"6-digit postcode eg. 546080","type":"string"},"revenue":{"$id":"/properties/revenue","description":"Revenue generated from the service eg. 100","type":"number"},"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('ServiceBooked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ServiceClicked' track call.
 *
 * @param ServiceClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function serviceClicked(props: ServiceClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"ServiceClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"serviceType":{"$id":"/properties/serviceType","description":"Service name eg. Cleaning","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('ServiceClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ShareReferralCodeClicked' track call.
 *
 * @param ShareReferralCodeClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function shareReferralCodeClicked(props: ShareReferralCodeClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"ShareReferralCodeClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('ShareReferralCodeClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SubscriptionBookNowClicked' track call.
 *
 * @param SubscriptionBookNowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionBookNowClicked(props: SubscriptionBookNowClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"SubscriptionBookNowClicked","$schema":"http://json-schema.org/draft-07/schema","properties":{"anonymousID":{"$id":"/properties/anonymousID","description":"Required to generate empty event - do not provide it","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('SubscriptionBookNowClicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TasksErrandsPayment' track call.
 *
 * @param TasksErrandsPayment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tasksErrandsPayment(props: TasksErrandsPayment, options?: Options, callback?: Callback): void {

    const schema = {"$id":"TasksErrandsPayment","$schema":"http://json-schema.org/draft-07/schema","properties":{"paymentType":{"$id":"/properties/paymentType","description":"Payment Method used to complete the booking","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('TasksErrandsPayment', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'UnitAndFloorEntered' track call.
 *
 * @param UnitAndFloorEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function unitAndFloorEntered(props: UnitAndFloorEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"UnitAndFloorEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"entryType":{"$id":"/properties/entryType","description":"Type of unit and floor entry","enum":["Prefill","Manual"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('UnitAndFloorEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'UnitFloorLineEntered' track call.
 *
 * @param UnitFloorLineEntered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function unitFloorLineEntered(props: UnitFloorLineEntered, options?: Options, callback?: Callback): void {

    const schema = {"$id":"UnitFloorLineEntered","$schema":"http://json-schema.org/draft-07/schema","properties":{"entryType":{"$id":"/properties/entryType","description":"Type of unit/floor entry","enum":["Prefill","Manual"],"type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('UnitFloorLineEntered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'UserSignedIn' track call.
 *
 * @param UserSignedIn props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userSignedIn(props: UserSignedIn, options?: Options, callback?: Callback): void {

    const schema = {"$id":"UserSignedIn","$schema":"http://json-schema.org/draft-07/schema","properties":{"authMethod":{"$id":"/properties/authMethod","description":"Auth method used to sign up eg. Facebook","enum":["Email","Facebook","Apple","Google"],"type":"string"},"email":{"$id":"/properties/email","description":"User email","type":"string"},"fbc":{"$id":"/properties/fbc","description":"Meta analytics click id","type":"string"},"fbp":{"$id":"/properties/fbp","description":"Meta analytics browser id","type":"string"},"ga_client_id":{"$id":"/properties/ga_client_id","description":"Google analytics client id","type":"string"},"ga_session_id":{"$id":"/properties/ga_session_id","description":"Google analytics sessions Id","type":"string"},"ga_session_number":{"$id":"/properties/ga_session_number","description":"Google analytics sessions number","type":"number"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('UserSignedIn', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'UserSignedUp' track call.
 *
 * @param UserSignedUp props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userSignedUp(props: UserSignedUp, options?: Options, callback?: Callback): void {

    const schema = {"$id":"UserSignedUp","$schema":"http://json-schema.org/draft-07/schema","properties":{"authMethod":{"$id":"/properties/authMethod","description":"Auth method used to sign up eg. Facebook","enum":["Email","Facebook","Apple","Google"],"type":"string"},"email":{"$id":"/properties/email","description":"User email","type":"string"},"fbc":{"$id":"/properties/fbc","description":"Meta analytics click id","type":"string"},"fbp":{"$id":"/properties/fbp","description":"Meta analytics browser id","type":"string"},"ga_client_id":{"$id":"/properties/ga_client_id","description":"Google analytics client id","type":"string"},"ga_session_id":{"$id":"/properties/ga_session_id","description":"Google analytics sessions Id","type":"string"},"ga_session_number":{"$id":"/properties/ga_session_number","description":"Google analytics sessions number","type":"number"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('UserSignedUp', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}

const clientAPI = {
    /**
     * Updates the run-time configuration of this Typewriter client.
     *
     * @param {TypewriterOptions} options - the options to upsert
     *
     * @typedef {Object} TypewriterOptions
     * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
     * 		calls are forwarded on to. Defaults to window.analytics.
     * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
     * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
     * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
     * 		will be logged to stderr.
     */
    setTypewriterOptions,

    /**
     * Fires a 'AddAddressClicked' track call.
     *
     * @param AddAddressClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addAddressClicked,
    /**
     * Fires a 'AddHoursToSessionClicked' track call.
     *
     * @param AddHoursToSessionClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addHoursToSessionClicked,
    /**
     * Fires a 'AddNewCreditCardClicked' track call.
     *
     * @param AddNewCreditCardClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addNewCreditCardClicked,
    /**
     * Fires a 'AddressAdded' track call.
     *
     * @param AddressAdded props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addressAdded,
    /**
     * Fires a 'AddressCancelClicked' track call.
     *
     * @param AddressCancelClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addressCancelClicked,
    /**
     * Fires a 'AddressDeleted' track call.
     *
     * @param AddressDeleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addressDeleted,
    /**
     * Fires a 'AddressExpanded' track call.
     *
     * @param AddressExpanded props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addressExpanded,
    /**
     * Fires a 'AddressLineEntered' track call.
     *
     * @param AddressLineEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addressLineEntered,
    /**
     * Fires a 'AddressSaveClicked' track call.
     *
     * @param AddressSaveClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addressSaveClicked,
    /**
     * Fires a 'AirConPayment' track call.
     *
     * @param AirConPayment props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    airConPayment,
    /**
     * Fires a 'ApplicationBackgrounded' track call.
     *
     * @param ApplicationBackgrounded props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    applicationBackgrounded,
    /**
     * Fires a 'ApplicationInstalled' track call.
     *
     * @param ApplicationInstalled props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    applicationInstalled,
    /**
     * Fires a 'ApplicationOpened' track call.
     *
     * @param ApplicationOpened props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    applicationOpened,
    /**
     * Fires a 'ApplicationUpdated' track call.
     *
     * @param ApplicationUpdated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    applicationUpdated,
    /**
     * Fires a 'BlockLineEntered' track call.
     *
     * @param BlockLineEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    blockLineEntered,
    /**
     * Fires a 'BookAgainClicked' track call.
     *
     * @param BookAgainClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookAgainClicked,
    /**
     * Fires a 'BookNowClicked' track call.
     *
     * @param BookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookNowClicked,
    /**
     * Fires a 'BookServiceClicked' track call.
     *
     * @param BookServiceClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookServiceClicked,
    /**
     * Fires a 'BookingAdditionalInformationAdded' track call.
     *
     * @param BookingAdditionalInformationAdded props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingAdditionalInformationAdded,
    /**
     * Fires a 'BookingAddonAdded' track call.
     *
     * @param BookingAddonAdded props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingAddonAdded,
    /**
     * Fires a 'BookingAddressConfirmClicked' track call.
     *
     * @param BookingAddressConfirmClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingAddressConfirmClicked,
    /**
     * Fires a 'BookingAddressModuleClosed' track call.
     *
     * @param BookingAddressModuleClosed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingAddressModuleClosed,
    /**
     * Fires a 'BookingAnotherServiceTypeSelected' track call.
     *
     * @param BookingAnotherServiceTypeSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingAnotherServiceTypeSelected,
    /**
     * Fires a 'BookingBedroomNumberClicked' track call.
     *
     * @param BookingBedroomNumberClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingBedroomNumberClicked,
    /**
     * Fires a 'BookingBedroomNumberSelected' track call.
     *
     * @param BookingBedroomNumberSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingBedroomNumberSelected,
    /**
     * Fires a 'BookingCanceled' track call.
     *
     * @param BookingCanceled props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingCanceled,
    /**
     * Fires a 'BookingCancellationChargesClicked' track call.
     *
     * @param BookingCancellationChargesClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingCancellationChargesClicked,
    /**
     * Fires a 'BookingCancellationChargesModuleClosed' track call.
     *
     * @param BookingCancellationChargesModuleClosed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingCancellationChargesModuleClosed,
    /**
     * Fires a 'BookingClearBedroomsClicked' track call.
     *
     * @param BookingClearBedroomsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingClearBedroomsClicked,
    /**
     * Fires a 'BookingClearPetsClicked' track call.
     *
     * @param BookingClearPetsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingClearPetsClicked,
    /**
     * Fires a 'BookingClearPropertyFloorClicked' track call.
     *
     * @param BookingClearPropertyFloorClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingClearPropertyFloorClicked,
    /**
     * Fires a 'BookingClearPropertyTypeClicked' track call.
     *
     * @param BookingClearPropertyTypeClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingClearPropertyTypeClicked,
    /**
     * Fires a 'BookingCookingStyleEntered' track call.
     *
     * @param BookingCookingStyleEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingCookingStyleEntered,
    /**
     * Fires a 'BookingCookingStyleSelected' track call.
     *
     * @param BookingCookingStyleSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingCookingStyleSelected,
    /**
     * Fires a 'BookingDateAndTimeClicked' track call.
     *
     * @param BookingDateAndTimeClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingDateAndTimeClicked,
    /**
     * Fires a 'BookingDateAndTimeModuleClosed' track call.
     *
     * @param BookingDateAndTimeModuleClosed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingDateAndTimeModuleClosed,
    /**
     * Fires a 'BookingDateAndTimeSelected' track call.
     *
     * @param BookingDateAndTimeSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingDateAndTimeSelected,
    /**
     * Fires a 'BookingDiscountCodeApplied' track call.
     *
     * @param BookingDiscountCodeApplied props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingDiscountCodeApplied,
    /**
     * Fires a 'BookingDiscountCodeErrorShowed' track call.
     *
     * @param BookingDiscountCodeErrorShowed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingDiscountCodeErrorShowed,
    /**
     * Fires a 'BookingEditAddressClicked' track call.
     *
     * @param BookingEditAddressClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingEditAddressClicked,
    /**
     * Fires a 'BookingEditPaymentClicked' track call.
     *
     * @param BookingEditPaymentClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingEditPaymentClicked,
    /**
     * Fires a 'BookingElevatorSelected' track call.
     *
     * @param BookingElevatorSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingElevatorSelected,
    /**
     * Fires a 'BookingExistingAddressSelected' track call.
     *
     * @param BookingExistingAddressSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingExistingAddressSelected,
    /**
     * Fires a 'BookingFoodAllergiesSelected' track call.
     *
     * @param BookingFoodAllergiesSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingFoodAllergiesSelected,
    /**
     * Fires a 'BookingFrequencyClicked' track call.
     *
     * @param BookingFrequencyClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingFrequencyClicked,
    /**
     * Fires a 'BookingHoursSelected' track call.
     *
     * @param BookingHoursSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingHoursSelected,
    /**
     * Fires a 'BookingImageUploaded' track call.
     *
     * @param BookingImageUploaded props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingImageUploaded,
    /**
     * Fires a 'BookingInspectionDateAndTimeModuleClosed' track call.
     *
     * @param BookingInspectionDateAndTimeModuleClosed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingInspectionDateAndTimeModuleClosed,
    /**
     * Fires a 'BookingInspectionDateAndTimeSelected' track call.
     *
     * @param BookingInspectionDateAndTimeSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingInspectionDateAndTimeSelected,
    /**
     * Fires a 'BookingInstructionForDriverSelected' track call.
     *
     * @param BookingInstructionForDriverSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingInstructionForDriverSelected,
    /**
     * Fires a 'BookingLaundryServiceSelected' track call.
     *
     * @param BookingLaundryServiceSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingLaundryServiceSelected,
    /**
     * Fires a 'BookingMoreClicked' track call.
     *
     * @param BookingMoreClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingMoreClicked,
    /**
     * Fires a 'BookingMovingTypeSelected' track call.
     *
     * @param BookingMovingTypeSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingMovingTypeSelected,
    /**
     * Fires a 'BookingNewAddressClicked' track call.
     *
     * @param BookingNewAddressClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingNewAddressClicked,
    /**
     * Fires a 'BookingNumberOfAirConditionersSelected' track call.
     *
     * @param BookingNumberOfAirConditionersSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingNumberOfAirConditionersSelected,
    /**
     * Fires a 'BookingPaymentSwitched' track call.
     *
     * @param BookingPaymentSwitched props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPaymentSwitched,
    /**
     * Fires a 'BookingPestControlServiceSelected' track call.
     *
     * @param BookingPestControlServiceSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPestControlServiceSelected,
    /**
     * Fires a 'BookingPriceReferenceClicked' track call.
     *
     * @param BookingPriceReferenceClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPriceReferenceClicked,
    /**
     * Fires a 'BookingPriceReferenceClosed' track call.
     *
     * @param BookingPriceReferenceClosed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPriceReferenceClosed,
    /**
     * Fires a 'BookingPriceReferenceTabClicked' track call.
     *
     * @param BookingPriceReferenceTabClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPriceReferenceTabClicked,
    /**
     * Fires a 'BookingPromoCodeEntered' track call.
     *
     * @param BookingPromoCodeEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPromoCodeEntered,
    /**
     * Fires a 'BookingPropertyFloorClicked' track call.
     *
     * @param BookingPropertyFloorClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPropertyFloorClicked,
    /**
     * Fires a 'BookingPropertyFloorSelected' track call.
     *
     * @param BookingPropertyFloorSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPropertyFloorSelected,
    /**
     * Fires a 'BookingPropertyOptionSelected' track call.
     *
     * @param BookingPropertyOptionSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPropertyOptionSelected,
    /**
     * Fires a 'BookingPropertySizeSelected' track call.
     *
     * @param BookingPropertySizeSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPropertySizeSelected,
    /**
     * Fires a 'BookingPropertyTypeClicked' track call.
     *
     * @param BookingPropertyTypeClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPropertyTypeClicked,
    /**
     * Fires a 'BookingPropertyTypeSelected' track call.
     *
     * @param BookingPropertyTypeSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPropertyTypeSelected,
    /**
     * Fires a 'BookingPurchaseSendPayCreditsClicked' track call.
     *
     * @param BookingPurchaseSendPayCreditsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPurchaseSendPayCreditsClicked,
    /**
     * Fires a 'BookingPurchasingServicesSelected' track call.
     *
     * @param BookingPurchasingServicesSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingPurchasingServicesSelected,
    /**
     * Fires a 'BookingRescheduled' track call.
     *
     * @param BookingRescheduled props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingRescheduled,
    /**
     * Fires a 'BookingSelectSavedAddressClicked' track call.
     *
     * @param BookingSelectSavedAddressClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingSelectSavedAddressClicked,
    /**
     * Fires a 'BookingSelectServiceTypeClicked' track call.
     *
     * @param BookingSelectServiceTypeClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingSelectServiceTypeClicked,
    /**
     * Fires a 'BookingServiceDescriptionModuleClosed' track call.
     *
     * @param BookingServiceDescriptionModuleClosed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingServiceDescriptionModuleClosed,
    /**
     * Fires a 'BookingServicePlanClicked' track call.
     *
     * @param BookingServicePlanClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingServicePlanClicked,
    /**
     * Fires a 'BookingServiceTypeSelected' track call.
     *
     * @param BookingServiceTypeSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingServiceTypeSelected,
    /**
     * Fires a 'BookingStarted' track call.
     *
     * @param BookingStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingStarted,
    /**
     * Fires a 'BookingSummary' track call.
     *
     * @param BookingSummary props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingSummary,
    /**
     * Fires a 'BookingTaskOrErrandTypeSelected' track call.
     *
     * @param BookingTaskOrErrandTypeSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingTaskOrErrandTypeSelected,
    /**
     * Fires a 'BookingUploadedImageRemoveClicked' track call.
     *
     * @param BookingUploadedImageRemoveClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingUploadedImageRemoveClicked,
    /**
     * Fires a 'BookingWhatsIncludedAndHowItWorksClicked' track call.
     *
     * @param BookingWhatsIncludedAndHowItWorksClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookingWhatsIncludedAndHowItWorksClicked,
    /**
     * Fires a 'CallSupplierClicked' track call.
     *
     * @param CallSupplierClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    callSupplierClicked,
    /**
     * Fires a 'CancelBookingClicked' track call.
     *
     * @param CancelBookingClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cancelBookingClicked,
    /**
     * Fires a 'ChatWithSupplierClicked' track call.
     *
     * @param ChatWithSupplierClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    chatWithSupplierClicked,
    /**
     * Fires a 'CleaningPayment' track call.
     *
     * @param CleaningPayment props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cleaningPayment,
    /**
     * Fires a 'CleaningPlanFrequencySelected' track call.
     *
     * @param CleaningPlanFrequencySelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cleaningPlanFrequencySelected,
    /**
     * Fires a 'CleaningPlanSelected' track call.
     *
     * @param CleaningPlanSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cleaningPlanSelected,
    /**
     * Fires a 'ContinueAsGuestClicked' track call.
     *
     * @param ContinueAsGuestClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    continueAsGuestClicked,
    /**
     * Fires a 'CookingPayment' track call.
     *
     * @param CookingPayment props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cookingPayment,
    /**
     * Fires a 'CreditCardAdded' track call.
     *
     * @param CreditCardAdded props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    creditCardAdded,
    /**
     * Fires a 'CreditCardCVVEntered' track call.
     *
     * @param CreditCardCVVEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    creditCardCVVEntered,
    /**
     * Fires a 'CreditCardCancelClicked' track call.
     *
     * @param CreditCardCancelClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    creditCardCancelClicked,
    /**
     * Fires a 'CreditCardDateEntered' track call.
     *
     * @param CreditCardDateEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    creditCardDateEntered,
    /**
     * Fires a 'CreditCardInvalidNumberEntered' track call.
     *
     * @param CreditCardInvalidNumberEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    creditCardInvalidNumberEntered,
    /**
     * Fires a 'CreditCardModuleClosed' track call.
     *
     * @param CreditCardModuleClosed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    creditCardModuleClosed,
    /**
     * Fires a 'CreditCardNameEntered' track call.
     *
     * @param CreditCardNameEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    creditCardNameEntered,
    /**
     * Fires a 'CreditCardNumberEntered' track call.
     *
     * @param CreditCardNumberEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    creditCardNumberEntered,
    /**
     * Fires a 'CrossSaleBookNowClicked' track call.
     *
     * @param CrossSaleBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossSaleBookNowClicked,
    /**
     * Fires a 'CrossaleAirconBookNowClicked' track call.
     *
     * @param CrossaleAirconBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossaleAirconBookNowClicked,
    /**
     * Fires a 'CrossaleCleaningBookNowClicked' track call.
     *
     * @param CrossaleCleaningBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossaleCleaningBookNowClicked,
    /**
     * Fires a 'CrossaleCookingBookNowClicked' track call.
     *
     * @param CrossaleCookingBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossaleCookingBookNowClicked,
    /**
     * Fires a 'CrossaleDeepCleaningBookNowClicked' track call.
     *
     * @param CrossaleDeepCleaningBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossaleDeepCleaningBookNowClicked,
    /**
     * Fires a 'CrossaleHandymanBookNowClicked' track call.
     *
     * @param CrossaleHandymanBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossaleHandymanBookNowClicked,
    /**
     * Fires a 'CrossaleLaundryBookNowClicked' track call.
     *
     * @param CrossaleLaundryBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossaleLaundryBookNowClicked,
    /**
     * Fires a 'CrossaleMoversAndPackersBookNowClicked' track call.
     *
     * @param CrossaleMoversAndPackersBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossaleMoversAndPackersBookNowClicked,
    /**
     * Fires a 'CrossaleMyBookingsClicked' track call.
     *
     * @param CrossaleMyBookingsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossaleMyBookingsClicked,
    /**
     * Fires a 'CrossalePestControlBookNowClicked' track call.
     *
     * @param CrossalePestControlBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossalePestControlBookNowClicked,
    /**
     * Fires a 'CrossaleTasksAndErrandsBookNowClicked' track call.
     *
     * @param CrossaleTasksAndErrandsBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    crossaleTasksAndErrandsBookNowClicked,
    /**
     * Fires a 'DeepLinkOpened' track call.
     *
     * @param DeepLinkOpened props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    deepLinkOpened,
    /**
     * Fires a 'DeepCleaningPayment' track call.
     *
     * @param DeepCleaningPayment props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    deepCleaningPayment,
    /**
     * Fires a 'DynamicPricingSelected' track call.
     *
     * @param DynamicPricingSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    dynamicPricingSelected,
    /**
     * Fires a 'EditProfileClicked' track call.
     *
     * @param EditProfileClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    editProfileClicked,
    /**
     * Fires a 'ExternalLinkClicked' track call.
     *
     * @param ExternalLinkClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    externalLinkClicked,
    /**
     * Fires a 'FavouritePartnerSet' track call.
     *
     * @param FavouritePartnerSet props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    favouritePartnerSet,
    /**
     * Fires a 'HandymanPayment' track call.
     *
     * @param HandymanPayment props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    handymanPayment,
    /**
     * Fires a 'InstallAttributed' track call.
     *
     * @param InstallAttributed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    installAttributed,
    /**
     * Fires a 'JobAccepted' track call.
     *
     * @param JobAccepted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    jobAccepted,
    /**
     * Fires a 'JobCancelled' track call.
     *
     * @param JobCancelled props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    jobCancelled,
    /**
     * Fires a 'JobClicked' track call.
     *
     * @param JobClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    jobClicked,
    /**
     * Fires a 'JobCompleted' track call.
     *
     * @param JobCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    jobCompleted,
    /**
     * Fires a 'JobExpired' track call.
     *
     * @param JobExpired props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    jobExpired,
    /**
     * Fires a 'JobSessionExtended' track call.
     *
     * @param JobSessionExtended props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    jobSessionExtended,
    /**
     * Fires a 'LaundryPayment' track call.
     *
     * @param LaundryPayment props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    laundryPayment,
    /**
     * Fires a 'MarketingAffiliateClicked' track call.
     *
     * @param MarketingAffiliateClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingAffiliateClicked,
    /**
     * Fires a 'MarketingBecomeAPartnerClicked' track call.
     *
     * @param MarketingBecomeAPartnerClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingBecomeAPartnerClicked,
    /**
     * Fires a 'MarketingBlogClicked' track call.
     *
     * @param MarketingBlogClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingBlogClicked,
    /**
     * Fires a 'MarketingBookNowClicked' track call.
     *
     * @param MarketingBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingBookNowClicked,
    /**
     * Fires a 'MarketingBookServiceClicked' track call.
     *
     * @param MarketingBookServiceClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingBookServiceClicked,
    /**
     * Fires a 'MarketingClickBookNowOnOfferBanner' track call.
     *
     * @param MarketingClickBookNowOnOfferBanner props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingClickBookNowOnOfferBanner,
    /**
     * Fires a 'MarketingContactClicked' track call.
     *
     * @param MarketingContactClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingContactClicked,
    /**
     * Fires a 'MarketingContactUsClicked' track call.
     *
     * @param MarketingContactUsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingContactUsClicked,
    /**
     * Fires a 'MarketingDealsClicked' track call.
     *
     * @param MarketingDealsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingDealsClicked,
    /**
     * Fires a 'MarketingEnterEmailSubscribed' track call.
     *
     * @param MarketingEnterEmailSubscribed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingEnterEmailSubscribed,
    /**
     * Fires a 'MarketingEnterPostCode' track call.
     *
     * @param MarketingEnterPostCode props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingEnterPostCode,
    /**
     * Fires a 'MarketingExternalLinkClicked' track call.
     *
     * @param MarketingExternalLinkClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingExternalLinkClicked,
    /**
     * Fires a 'MarketingHelpCentreClicked' track call.
     *
     * @param MarketingHelpCentreClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingHelpCentreClicked,
    /**
     * Fires a 'MarketingHelpCentreSearched' track call.
     *
     * @param MarketingHelpCentreSearched props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingHelpCentreSearched,
    /**
     * Fires a 'MarketingMobileAppDownload' track call.
     *
     * @param MarketingMobileAppDownload props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingMobileAppDownload,
    /**
     * Fires a 'MarketingMobileAppDownloadClicked' track call.
     *
     * @param MarketingMobileAppDownloadClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingMobileAppDownloadClicked,
    /**
     * Fires a 'MarketingNewsletterSubscribed' track call.
     *
     * @param MarketingNewsletterSubscribed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingNewsletterSubscribed,
    /**
     * Fires a 'MarketingNotificationClicked' track call.
     *
     * @param MarketingNotificationClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingNotificationClicked,
    /**
     * Fires a 'MarketingOurServicesClicked' track call.
     *
     * @param MarketingOurServicesClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingOurServicesClicked,
    /**
     * Fires a 'MarketingPageViewed' track call.
     *
     * @param MarketingPageViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingPageViewed,
    /**
     * Fires a 'MarketingPartnerSignedUp' track call.
     *
     * @param MarketingPartnerSignedUp props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingPartnerSignedUp,
    /**
     * Fires a 'MarketingReviewClicked' track call.
     *
     * @param MarketingReviewClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingReviewClicked,
    /**
     * Fires a 'MarketingScrollToBottom' track call.
     *
     * @param MarketingScrollToBottom props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingScrollToBottom,
    /**
     * Fires a 'MarketingSearchBookNowClicked' track call.
     *
     * @param MarketingSearchBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingSearchBookNowClicked,
    /**
     * Fires a 'MarketingSearchPostCodeClicked' track call.
     *
     * @param MarketingSearchPostCodeClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingSearchPostCodeClicked,
    /**
     * Fires a 'MarketingSearchServiceClicked' track call.
     *
     * @param MarketingSearchServiceClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingSearchServiceClicked,
    /**
     * Fires a 'MarketingSearchServiceTypeClicked' track call.
     *
     * @param MarketingSearchServiceTypeClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingSearchServiceTypeClicked,
    /**
     * Fires a 'MarketingServiceClicked' track call.
     *
     * @param MarketingServiceClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingServiceClicked,
    /**
     * Fires a 'MarketingServiceIconClicked' track call.
     *
     * @param MarketingServiceIconClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingServiceIconClicked,
    /**
     * Fires a 'MarketingUserClicked' track call.
     *
     * @param MarketingUserClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingUserClicked,
    /**
     * Fires a 'MobileAppDownload' track call.
     *
     * @param MobileAppDownload props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    mobileAppDownload,
    /**
     * Fires a 'MoversAndPackersPayment' track call.
     *
     * @param MoversAndPackersPayment props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    moversAndPackersPayment,
    /**
     * Fires a 'OrganicInstall' track call.
     *
     * @param OrganicInstall props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    organicInstall,
    /**
     * Fires a 'PaymentCharged' track call.
     *
     * @param PaymentCharged props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    paymentCharged,
    /**
     * Fires a 'PestPayment' track call.
     *
     * @param PestPayment props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pestPayment,
    /**
     * Fires a 'PostcodeEntered' track call.
     *
     * @param PostcodeEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    postcodeEntered,
    /**
     * Fires a 'PriceGuideHandymanClicked' track call.
     *
     * @param PriceGuideHandymanClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    priceGuideHandymanClicked,
    /**
     * Fires a 'Purchase' track call.
     *
     * @param Purchase props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    purchase,
    /**
     * Fires a 'PurchaseSendPayCreditPackClicked' track call.
     *
     * @param PurchaseSendPayCreditPackClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    purchaseSendPayCreditPackClicked,
    /**
     * Fires a 'PurchaseTopUpSendPayCreditsClicked' track call.
     *
     * @param PurchaseTopUpSendPayCreditsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    purchaseTopUpSendPayCreditsClicked,
    /**
     * Fires a 'PurchasedSendPayCredits' track call.
     *
     * @param PurchasedSendPayCredits props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    purchasedSendPayCredits,
    /**
     * Fires a 'ReferFriendClicked' track call.
     *
     * @param ReferFriendClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    referFriendClicked,
    /**
     * Fires a 'ReferralCodeCopied' track call.
     *
     * @param ReferralCodeCopied props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    referralCodeCopied,
    /**
     * Fires a 'RescheduleBookingClicked' track call.
     *
     * @param RescheduleBookingClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    rescheduleBookingClicked,
    /**
     * Fires a 'RescheduleRequestClicked' track call.
     *
     * @param RescheduleRequestClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    rescheduleRequestClicked,
    /**
     * Fires a 'SelectedServiceFromDropdown' track call.
     *
     * @param SelectedServiceFromDropdown props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    selectedServiceFromDropdown,
    /**
     * Fires a 'ServiceBooked' track call.
     *
     * @param ServiceBooked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    serviceBooked,
    /**
     * Fires a 'ServiceClicked' track call.
     *
     * @param ServiceClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    serviceClicked,
    /**
     * Fires a 'ShareReferralCodeClicked' track call.
     *
     * @param ShareReferralCodeClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    shareReferralCodeClicked,
    /**
     * Fires a 'SubscriptionBookNowClicked' track call.
     *
     * @param SubscriptionBookNowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    subscriptionBookNowClicked,
    /**
     * Fires a 'TasksErrandsPayment' track call.
     *
     * @param TasksErrandsPayment props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    tasksErrandsPayment,
    /**
     * Fires a 'UnitAndFloorEntered' track call.
     *
     * @param UnitAndFloorEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    unitAndFloorEntered,
    /**
     * Fires a 'UnitFloorLineEntered' track call.
     *
     * @param UnitFloorLineEntered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    unitFloorLineEntered,
    /**
     * Fires a 'UserSignedIn' track call.
     *
     * @param UserSignedIn props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    userSignedIn,
    /**
     * Fires a 'UserSignedUp' track call.
     *
     * @param UserSignedUp props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    userSignedUp,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
    get(target, method) {
        if (typeof method === 'string' && target.hasOwnProperty(method)) {
            return target[method as keyof typeof clientAPI];
        }

        return () => {
            console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
            const a = analytics();
            if (a) {
                a.track(
                    'Unknown Analytics Call Fired',
                    {
                        method,
                    },
                    withTypewriterContext(),
                );
            }
        };
    },
});

