import React, { ReactNode, useState } from 'react'
import { ViewStyle } from 'react-native'
import { InfoCircleTooltipOutline } from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { Icon } from '@propertyguru/hive-icon'
import { Surface } from '@propertyguru/hive-surface'
import { Typography } from '@propertyguru/hive'
import { Divider } from '@propertyguru/hive-divider'
import { Touchable } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { PriceSummaryItemNames } from 'lib/types'
import { useNativeJobQuotationsAtom } from 'lib/native/atoms'
import { PriceSummary } from 'lib/native/models'
import { Footer, PlatformFeeModal } from 'features/native/bookings/components'

type Props = {
    style?: ViewStyle,
    children?: ReactNode,
    titleTypography?: Typography,
    labelTypography?: Typography,
    valueTypography?: Typography
}

export const FooterBreakdownBodyWrapper: React.FunctionComponent<Props> = props => {
    const {
        titleTypography = 'label/s',
        labelTypography = 'body/xs',
        valueTypography = 'label/s'
    } = props
    const [jobQuotation] = useNativeJobQuotationsAtom()
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const [shouldShowPlatformFeeModal, setShouldShowPlatformFeeModal] = useState(false)
    const showPlatformFeeModal = () => setShouldShowPlatformFeeModal(true)
    const hidePlatformFeeModal = () => setShouldShowPlatformFeeModal(false)
    const newPriceSummary = (jobQuotation?.newPriceSummary || {}) as PriceSummary
    const priceSummary = (jobQuotation?.priceSummary || {}) as PriceSummary
    const priceSummaryData = Object.keys(newPriceSummary).length ? newPriceSummary : priceSummary

    return priceSummaryData ? (
        <>
            <Footer.Breakdown.Body style={props.style}>
                <Text typography={titleTypography} color="text/active/primary">
                    {
                        priceSummaryData?.description ||
                        T.screens.cleaningNative.footerBreakdown.title
                    }
                </Text>
                <Divider />
                {
                    priceSummaryData?.items?.map((item, index) => {
                        const isPlatformFee = [PriceSummaryItemNames.PlatformFee, PriceSummaryItemNames.PlatformAndSupportFee].includes(item?.name as PriceSummaryItemNames)

                        return isPlatformFee ? (
                            <Footer.Breakdown.Body.Row key={index}>
                                <Surface style={styles.platformFeeContainer}>
                                    <Text typography={labelTypography} color="text/active/primary">
                                        {item?.name ?? '-'}
                                    </Text>
                                    <Touchable style={styles.platformFeeIcon} onPress={showPlatformFeeModal}>
                                        <Icon icon={InfoCircleTooltipOutline} width={16} height={16} />
                                    </Touchable>
                                </Surface>
                                <Text typography={valueTypography} color="text/active/primary">
                                    {item?.value ?? '-'}
                                </Text>
                                <PlatformFeeModal
                                    isOpen={shouldShowPlatformFeeModal}
                                    onClose={hidePlatformFeeModal}
                                    content={item?.description}
                                />
                            </Footer.Breakdown.Body.Row>
                        ): (
                            <Footer.Breakdown.Body.Row key={index}>
                                <Surface>
                                    <Text typography={labelTypography} color="text/active/primary">
                                        {item?.name ?? '-'}
                                    </Text>
                                </Surface>
                                <Text typography={valueTypography} color="text/active/primary">
                                    {item?.value ?? '-'}
                                </Text>
                            </Footer.Breakdown.Body.Row>
                        )
                    })
                }
                {props.children}
                <Divider />
            </Footer.Breakdown.Body>
        </>
    ) : null
}

const stylesheet = createStyles(theme => ({
    footerBreakdownBodyRowFee: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.utils.hive.spacing('x1')
    },
    platformFeeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    platformFeeIcon: {
        marginLeft: theme.utils.hive.spacing('x1')
    }
}))
