import { useEffect } from 'react'
import { GoogleSignin, statusCodes, isErrorWithCode } from '@react-native-google-signin/google-signin'
import { useToastAtom } from 'lib/atoms'
import { NotificationType } from 'lib/types'
import { ENV } from 'lib/config'
import { useTranslations } from 'lib/hooks'
import { useSocialSignIn } from './useSocialSignIn'

export const useGoogleSignIn = updateDisabledStatus => {

    const T = useTranslations()
    const { isLoading, signInWithGoogle } = useSocialSignIn()
    const [, setToastMessage ] = useToastAtom()

    GoogleSignin.configure({
        webClientId: ENV.GOOGLE_WEB_CLIENT_ID,
        iosClientId: ENV.GOOGLE_IOS_CLIENT_ID
    })

    useEffect(() => {
        updateDisabledStatus(isLoading)
    }, [isLoading])

    const onPress = async () => {
        updateDisabledStatus(true)
        try {
            await GoogleSignin.hasPlayServices()
            await GoogleSignin.signOut()
            const userInfo = await GoogleSignin.signIn()

            const { idToken, user } = userInfo
            signInWithGoogle(idToken || '', user)
        } catch (error) {
            updateDisabledStatus(false)

            if (!isErrorWithCode(error)) {
                return setToastMessage({
                    message: T.screens.auth.googleSignInError,
                    type: NotificationType.Error
                })
            }

            switch (error.code) {
                case statusCodes.PLAY_SERVICES_NOT_AVAILABLE:
                    return setToastMessage({
                        message: T.screens.auth.playServicesNotAvailable,
                        type: NotificationType.Error
                    })
                case statusCodes.SIGN_IN_CANCELLED:
                    break
                case '-1': // User denied access to their account
                    break
                default:
                    return setToastMessage({
                        message: T.screens.auth.googleSignInError,
                        type: NotificationType.Error
                    })
            }
        }
        updateDisabledStatus(false)
    }

    return { onPress }
}
