import React, { MutableRefObject, useState } from 'react'
import { View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { useSharedValue } from 'react-native-reanimated'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Segment } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { AirConditionService, AirConditionServiceOption, Nullable } from 'lib/types'
import { useStyles, useTranslations } from 'lib/hooks'
import { FormComponents, TextButton, Typography } from 'lib/components'
import { AirConditionPricingModal, ContractTypeOption, ServicePlanOption } from 'features/bookings'

interface AirConditionServiceOptionsProps extends Field<Nullable<AirConditionServiceOption>> {
    options: Array<ServicePlanOption>,
    disabled?: boolean,
    scrollRef: MutableRefObject<KeyboardAwareScrollView>,
    selectedOption?: ServicePlanOption
    selectedContractType?: ContractTypeOption
    onSelectPlan?(value: ServicePlanOption): void,
    onOpenContractModal?(): void,
}

export const AirConditionServiceOptions: React.FunctionComponent<AirConditionServiceOptionsProps> = ({
    value,
    options,
    errorMessage,
    onSelectPlan,
    selectedOption,
    // onChangeContractType
    onOpenContractModal,
    selectedContractType
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [priceModalOpen, steIsPriceModalOpen] = useState(false)
    const isDropdownVisible = useSharedValue<boolean>(false)

    const handleChangeServicesPlan = (option: ServicePlanOption) => {
        onSelectPlan && onSelectPlan(option)
    }

    return (
        <View>
            <AirConditionPricingModal
                isOpen={priceModalOpen}
                onClose={() => steIsPriceModalOpen(false)}
            />
            <Typography.Regular bold>
                {T.screens.airCondition.planChangeModalHeader}
            </Typography.Regular>
            <View style={styles.optionsContainer}>
                {options.filter(option => option.frequency !== AirConditionService.Custom).map((option, index) => (
                    <FormComponents.ServicesPlanItem
                        key={index}
                        index={index}
                        option={option}
                        isSelected={selectedOption?.title === option.title}
                        isLast={index === options.length - 1}
                        onChangeValue={() => {
                            handleChangeServicesPlan(option as ServicePlanOption)
                        }}
                        onOpenContractModal={onOpenContractModal}
                        selectedContractType={selectedContractType}
                        testID={T.accessibility.airCondition.serviceOption}
                        disableChangeContractType={selectedOption?.frequency !== AirConditionService.Contract}
                    />
                ))}
            </View>
            <View style={styles.optionsContainer}>
                {options.filter(option => option.frequency === AirConditionService.Custom).map((option, index) => (
                    <FormComponents.ServicesPlanItem
                        key={index}
                        index={index}
                        option={option}
                        isSelected={selectedOption?.title === option.title}
                        isLast={index === options.length - 1}
                        onChangeValue={() => {
                            handleChangeServicesPlan(option as ServicePlanOption)
                        }}
                        onOpenContractModal={onOpenContractModal}
                        selectedContractType={selectedContractType}
                        testID={T.accessibility.airCondition.serviceOption}
                    />
                ))}
            </View>
            {value?.title?.toLowerCase().includes(AirConditionService.Custom) && (
                <Typography.Error
                    style={styles.customMessage}
                    forceColor={theme.colors.mouse}
                >
                    {T.screens.airCondition.customMessage}
                </Typography.Error>
            )}
            <TextButton
                onPress={() => {
                    Segment.bookingPriceReferenceClicked({})

                    isDropdownVisible.value = false
                    steIsPriceModalOpen(true)
                }}
                text={T.common.priceReference}
            />
            <FormComponents.ErrorMessage text={errorMessage} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    optionsContainer: {
        zIndex: theme.zIndex[20],
        marginTop: theme.utils.gap(2),
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        justifyContent: 'space-between',
        gap: theme.utils.gap(1)
    },
    description: {
        flex: 1,
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(3)
    },
    title: {
        alignItems: 'center'
    },
    option: {
        flex: 1,
        borderWidth: 1,
        paddingTop: theme.utils.gap(3),
        paddingBottom: theme.utils.gap(2),
        paddingHorizontal: {
            lg: theme.utils.gap(3) / 2,
            xs: theme.utils.gap(1) / 2
        },
        borderRadius: theme.components.input.borderRadius,
        marginBottom: {
            lg: undefined,
            xs: theme.utils.gap(1)
        },
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    selectContainer: {
        borderWidth: 1,
        alignItems: 'center',
        marginTop: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1),
        borderRadius: theme.components.button.borderRadius
    },
    listItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1) / 2
    },
    listText: {
        marginLeft: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(1) / 2
        },
        paddingRight: theme.utils.gap(1)
    },
    customMessage: {
        marginTop: theme.utils.gap(3)
    }
}))
