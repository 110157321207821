export type DynamicPriceSlot = {
    status: boolean;
    header: {
        title: string;
        subtitle: string;
        price: string;
        disclaimerNoSlots?: string;
        disclaimerSlotsAvailable?: string;
    };
    reschedule: {
        message: string;
        icon: string;
    };
    slots: {
        [date: string]: {
            [timeOrLowestRate: string]: PriceSlot | number;
        };
    };
    totalSlots: number;
    lowestRate: number;
    highestRate?: number;
}

export enum PricingTypes {
    Regular = 'regular',
    Dynamic = 'dynamic'
}

export type PricingType = PricingTypes.Regular | PricingTypes.Dynamic

export type PriceSlot = {
    slotId: string;
    price: string;
    limit: number;
}

export type ErrorResponse = {
    errors?: {
        error_code?: string;
        error_msg?: string;
    }
}

export type DynamicPriceSlotAtom = DynamicPriceSlot & ErrorResponse & {
    isLoading: boolean;
    daySelected?: string;
}
