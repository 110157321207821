import React, { useState } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { DimensionValue, View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Children, SelectInputOption } from 'lib/types'
import { FormComponents, Touchable, Typography } from 'lib/components'
import { isNative, Measurements } from 'lib/common'
import { Segment } from 'lib/analytics'

interface OptionsRadioProps extends Field<string> {
    options: Array<SelectInputOption>,
    headerComponent?: Children,
    disabled?: boolean,
    maxOptionsPerRow?: number,
    showCount?: boolean,
    initialAmountToShow?: number,
    renderCustomPlaceholder?: () => Children,
    testID?: string,
    testIDReplaceKey?: string,
}

export const OptionsRadio: React.FunctionComponent<OptionsRadioProps> = ({
    label,
    value,
    placeholder,
    headerComponent,
    onChangeValue,
    errorMessage,
    hasError,
    options,
    disabled,
    maxOptionsPerRow,
    showCount,
    initialAmountToShow = 4,
    renderCustomPlaceholder,
    testID,
    testIDReplaceKey
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [showMore, setShowMore] = useState(options.length < 5)
    const availableOptionsPerRow = options.length > 4 ? 5 : options.length
    const optionsPerRow = maxOptionsPerRow
        ? Math.min(maxOptionsPerRow, availableOptionsPerRow)
        : availableOptionsPerRow
    const optionWidth = isNative
        ? ((Measurements.WindowWidth - 40) / optionsPerRow) - 10
        : `calc((100% / ${optionsPerRow}) - 10px)` as DimensionValue
    const optionsToShow = showMore
        ? options
        : options.slice(0, initialAmountToShow)

    return (
        <View>
            {
                label ? (
                    <Typography.Regular style={styles.label} bold>
                        {label}
                    </Typography.Regular>
                ) : null
            }
            {headerComponent}
            {
                renderCustomPlaceholder ?
                    renderCustomPlaceholder() :
                    placeholder ? (
                        <View style={styles.placeholder}>
                            <Typography.Regular forceColor={theme.colors.darkGrey}>
                                {placeholder}
                            </Typography.Regular>
                        </View>
                    ) : null
            }
            <View style={styles.optionsContainer}>
                {optionsToShow.map((option, index) => (
                    <React.Fragment key={index}>
                        <Touchable
                            style={{
                                ...styles.option,
                                maxWidth: optionWidth,
                                marginRight: theme.utils.gap(1),
                                backgroundColor: option.value === value
                                    ? theme.colors.yellow
                                    : theme.colors.white,
                                borderColor: option.value === value
                                    ? theme.colors.orange
                                    : hasError
                                        ? theme.components.input.error.borderColor
                                        : theme.components.input.emptyBorderColor
                            }}
                            disabled={disabled}
                            onPress={() => onChangeValue(option.value)}
                            testID={
                                testIDReplaceKey && testID ?
                                    testID.replace(testIDReplaceKey, option.value):
                                    testID
                            }
                        >
                            <Typography.Label
                                numberOfLines={1}
                                bold={option.value === value}
                                forceColor={option.value === value
                                    ? theme.colors.orange
                                    : hasError
                                        ? theme.components.input.typography.error
                                        : theme.components.input.typography.text
                                }
                            >
                                {option.label}
                            </Typography.Label>
                            {showCount && Number(option?.count) > 0 && (
                                <View style={styles.count}>
                                    <Typography.Label forceColor={theme.colors.white}>
                                        {option.count}
                                    </Typography.Label>
                                </View>
                            )}
                        </Touchable>
                    </React.Fragment>
                ))}
                {!showMore && (
                    <Touchable
                        style={{
                            ...styles.option,
                            maxWidth: optionWidth,
                            marginRight: theme.utils.gap(1),
                            backgroundColor: theme.colors.white,
                            borderColor: theme.components.input.emptyBorderColor
                        }}
                        testID={`${testID}-more`}
                        disabled={disabled}
                        onPress={() => {
                            Segment.bookingMoreClicked({})

                            setShowMore(true)
                        }}
                    >
                        <Typography.Label
                            numberOfLines={1}
                            forceColor={theme.components.input.typography.text}
                        >
                            {T.common.more}
                        </Typography.Label>
                    </Touchable>
                )}
            </View>
            {errorMessage ? <FormComponents.ErrorMessage text={errorMessage} /> : null}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    placeholder: {
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(3)
    },
    label: {
        marginBottom: theme.utils.gap(1)
    },
    optionsContainer: {
        marginRight: -theme.utils.gap(1),
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    option: {
        flex: 1,
        flexBasis: {
            lg: '100%',
            xs: '50%'
        },
        borderRadius: 4,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.components.input.height,
        paddingHorizontal: theme.utils.gap(1) / 2,
        marginBottom: theme.utils.gap(1)
    },
    count: {
        position: 'absolute',
        right: theme.utils.gap(1),
        alignItems: 'center',
        justifyContent: 'center',
        width: 16,
        height: 16,
        borderRadius: 8,
        backgroundColor: theme.colors.orange
    }
}))
