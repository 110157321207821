import React, { useRef } from 'react'
import * as Linking from 'expo-linking'
import appsFlyer from 'react-native-appsflyer'
import Constants from 'expo-constants'
import ErrorBoundary from 'react-native-error-boundary'
import { getStateFromPath, NavigationContainer, NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { useTranslations, useUserTracking } from 'lib/hooks'
// import { authActions } from 'features/auth'
import { ScreenNamesNative, ScreensConfig } from './screens'
import { NativeStack } from './stacks'
import { GoogleAnalytics, Segment } from 'lib/analytics'
import { useToastAtom, useUnreadJobChatsAtom } from 'lib/atoms'
import { useNativeCampaignAtom, useNativeIsAuthorizedAtom, useNativeUserAtom, useNativeUserTokenAtom } from 'lib/native/atoms'
import { NotificationType } from 'lib/types'
import { linkingHelpers } from 'lib/utils'
import { authActions } from 'features/auth'
import { ErrorBoundaryHandler } from '../components/ErrorBoundaryHandler.native'
import { AtomUpdater } from '../components'
import { NativeNavigationScreenParams } from './stackParams'

export type NativeAppRouterProps = {
    navigationRef: NavigationContainerRefWithCurrent<NativeNavigationScreenParams>;
    onNavigationReady: VoidFunction;
}

export const NativeAppRouter: React.FunctionComponent<NativeAppRouterProps> = ({
    navigationRef,
    onNavigationReady
}) => {
    const T = useTranslations()
    const routeNameRef = useRef<string>()
    const deepLinkVal = useRef<string | null>()
    const [isAuthorized] = useNativeIsAuthorizedAtom()

    const [, setToastMessage] = useToastAtom()
    const [unreadJobChats] = useUnreadJobChatsAtom()
    const [token] = useNativeUserTokenAtom()
    const [user, setUser] = useNativeUserAtom()
    const [, setCampaign] = useNativeCampaignAtom()

    const { mutate: getMe } = authActions.useGetMe()

    useUserTracking()

    const fetchUserData = () => {
        getMe({ token }, { onSuccess: ({ data }) => setUser(data.profile) })
    }

    const logScreenView = () => {
        const screenName = routeNameRef.current

        // TODO: LandingNative screen event is fired twice on initial app load.
        if (screenName) {
            GoogleAnalytics.logScreenView(screenName)
            Segment.trackPageView(routeNameRef.current as ScreenNamesNative, {
                appVariant: Constants?.expoConfig?.extra?.appVariant
            })

            const isUserPath = ScreenNamesNative.NativeHome

            const shouldFetchUserData = isAuthorized && isUserPath
            shouldFetchUserData && fetchUserData()
        }
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorBoundaryHandler}>
            <NavigationContainer
                ref={navigationRef}
                documentTitle={{
                    formatter: (options, route) =>
                        `${unreadJobChats.length === 0 ? '' : `(${unreadJobChats.length}) `}`.concat(
                            `${options?.title ?? route?.name} - ${T.common.sendHelper}`
                        )
                }}
                onReady={() => {
                    routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name
                    onNavigationReady()

                    // When navigation is ready,
                    // redirect if there is a deep link value
                    if (user.email_id && deepLinkVal.current && ScreenNamesNative[deepLinkVal.current]) {
                        navigationRef.current?.navigate(ScreenNamesNative[deepLinkVal.current])
                        deepLinkVal.current = null
                    }

                    // onStageChange is not called on first app entry, so let's send trackPageView manually
                    // it will also re-generate anonymousId for Segment
                    logScreenView()
                }}
                linking={{
                    prefixes: [Linking.createURL('/')],
                    config: {
                        screens: {
                            ...ScreensConfig,
                            [ScreenNamesNative.NativeRootLeftDrawer]: {
                                screens: isAuthorized
                                    ? {
                                        [ScreenNamesNative.DashboardNative]: {
                                            path: 'sendhelper'
                                        }
                                    }
                                    : {
                                        [ScreenNamesNative.NativeHome]: {
                                            path: 'sendhelper'
                                        }
                                    }
                            }
                        }
                    },
                    getStateFromPath: (path, options) => {
                        if (!linkingHelpers.isBookingLink(path)) {
                            return getStateFromPath(path, options)
                        }

                        const redirectUrl = linkingHelpers.getRedirectUrlFromBookingLink(
                            isAuthorized,
                            () => {
                                setToastMessage({
                                    message: T.linking.unsupportedServiceError,
                                    type: NotificationType.Info
                                })
                            },
                            path
                        )

                        return getStateFromPath(redirectUrl || path, options)
                    },
                    getInitialURL: async () => {
                        // getIntitialURL is called only once on app start
                        // we can extract the deepLink value that opened the app here
                        const url = await Linking.getInitialURL() || ''
                        const { name, source, af_ad, af_adset, af_channel } = linkingHelpers.getDeepLinkCampaignDetails(url)

                        if(name && source) {
                            setCampaign({ name, source, af_ad, af_adset, af_channel })
                        }

                        if (url) {
                            const deepLinkParam = url.split('&').find(val => val.includes('deep_link_value'))
                            const routeName =
                                deepLinkParam && deepLinkParam.length ? deepLinkParam.split('=')[1] : undefined

                            // eslint-disable-next-line nested-if/nested-if-statements
                            if (user.email_id && routeName && ScreenNamesNative[routeName]) {
                                deepLinkVal.current = routeName
                            }
                        }

                        return url
                    },
                    subscribe: listener => {
                        // Listen to incoming links from AppsFlyer
                        // For cases when app transitions from background to foreground
                        // due to deep link clicked.
                        const onDeepLinkCanceller = appsFlyer.onDeepLink(res => {
                            const routeName = res.data?.deep_link_value
                            const name = res?.data?.campaign
                            const source = res?.data?.media_source
                            const af_ad = res?.data?.af_ad
                            const af_adset = res?.data?.af_adset
                            const af_channel = res?.data?.af_channel

                            if(name && source) {
                                setCampaign({ name, source, af_ad, af_adset, af_channel })
                            }

                            if (user.email_id && routeName && ScreenNamesNative[routeName]) {
                                navigationRef.current?.navigate(ScreenNamesNative[routeName])
                            }
                        })

                        const linkingSubscription = Linking.addEventListener('url', data => {
                            const deepLinkParam = data.url.split('&').find(val => val.includes('deep_link_value'))
                            const routeName =
                                deepLinkParam && deepLinkParam.length ? deepLinkParam.split('=')[1] : undefined

                            if (user.email_id && routeName && ScreenNamesNative[routeName]) {
                                navigationRef.current?.navigate(ScreenNamesNative[routeName])
                            }

                            listener(data.url)
                        })

                        return () => {
                            onDeepLinkCanceller()
                            linkingSubscription.remove()
                        }
                    }
                }}
                onStateChange={() => {
                    const previousRouteName = routeNameRef.current
                    const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name

                    if (previousRouteName === currentRouteName) {
                        return
                    }

                    routeNameRef.current = currentRouteName

                    logScreenView()
                }}
            >
                {NativeStack()}
            </NavigationContainer>
            <AtomUpdater />
        </ErrorBoundary>
    )
}
