import React, { useEffect, useState } from 'react'
import { Field, useForm } from '@codegateinc/react-form-builder-v2'
import { PaymentNewCardModal } from 'features/payments'
import { useSegmentSession, useTranslations, useSessionData } from 'lib/hooks'
import { useAddressAtom, useDynamicServiceAtom, useResetBookingJobQuotationAtom, useUserTokenAtom } from 'lib/atoms'
import { PaymentOption } from 'lib/models'
import { Segment, ServiceType } from 'lib/analytics'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { BookingPaymentView, LaundrySummaryView } from '../components'
import { ServiceAddonsFormShape, useServiceAddonsForm } from '../forms'
import { useBookingPromoCode, useLaundryCreateJob } from '../hooks'
import { CreateLaundryJobRequest } from '../types'
import { MobileOtpModal } from 'features/native/bookings/components'

type LaundrySummaryScreenProps = {
    navigation: NavigationProps<ScreenNames.LaundrySummary>,
    route: NavigationParams<ScreenNames.LaundrySummary>
}

export const LaundrySummaryScreen: React.FunctionComponent<LaundrySummaryScreenProps> = ({ route }) => {
    const T = useTranslations()
    const [address] = useAddressAtom()
    const [token] = useUserTokenAtom()
    const { segmentSession } = useSegmentSession()
    const [config] = useDynamicServiceAtom(route.params.supplyId)
    const supplyId = config._id
    const [paymentView, setPaymentView] = useState(false)
    const sessionData = useSessionData()
    const [, setResetJobQuotationTypeAtom] = useResetBookingJobQuotationAtom()

    const { form, submit, resetForm } = useForm<ServiceAddonsFormShape>(useServiceAddonsForm(), {
        onSuccess: ({ pets }) => {
            setResetJobQuotationTypeAtom()
            const createJobPayload: CreateLaundryJobRequest = {
                token,
                ...sessionData,
                bookingSessionId: segmentSession.id,
                booking: {
                    messageToSupplier: `${route.params.persistentComment}\n${form.comment.value}`,
                    date: route.params.dateTime || '',
                    location: addressCreated ?? address,
                    collectionTime: route.params.form.bookingTime,
                    paymentType: paymentMethod,
                    petOnPresmises: Boolean(pets)
                }
            }

            if (promotionPackage?.promotion) {
                createJobPayload.promo = {
                    code: promotionPackage?.promotion.promo_code,
                    auth: promotionPackage?.promotion.promo_auth
                }
            }

            onCreateJob(
                route.params.supplyId,
                createJobPayload,
                () => {
                    resetForm()
                    removePromoCode()
                }
            )
        }
    })
    const {
        jobUniqueId,
        onCreateJob,
        isLoading: isCreatingJob,
        addressCreated,
        paymentMethod,
        isDisabled,
        handleSubmit,
        isLoadingAddressForm,
        setPaymentMethod,
        fetchPostalCodes,
        addressForm,
        isNewCardModalOpen,
        setIsNewCardModalOpen,
        isMobileOtpModalOpen,
        setIsMobileOtpModalOpen
    } = useLaundryCreateJob(submit, ScreenNames.LaundryDetails, route.params.postalcode)
    const { isLoading: isLoadingPromotion, promotionPackage, requestPromotion, removePromoCode } = useBookingPromoCode()
    const isLoading = isLoadingPromotion || isCreatingJob
    const onRequestPromotion = (code: string) => {
        requestPromotion(code, {
            jobUniqueId,
            photos: [],
            petOnPresmises: false,
            ironing: false,
            isAllImageUploaded: true,
            message_to_supplier: `${route.params.persistentComment}\n${form.comment.value}`,
            linkUploads: {},
            targetPromoCode: promotionPackage?.promotion.promo_code || null,
            checkingPayment: false,
            date_time: route.params.dateTime || route.params.form.startingDate,
            location: addressCreated ?? address,
            is_purchasing_services: false,
            supply: supplyId,
            is_recurring: false,
            paymentType: paymentMethod,
            paymentOptions: [PaymentOption.Card, PaymentOption.SendPay],
            laundry_options: {
                pickup_slot: {
                    display: route.params.form.bookingTime
                }
            }
        })
    }

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingSummary({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                amount: route.params?.price as number,
                serviceType: ServiceType.Laundry
            })
        }
    }, [segmentSession])

    if (paymentView) {
        return (
            <BookingPaymentView
                disabled={isDisabled}
                submit={handleSubmit}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={promotionPackage?.total_cost || route.params?.price}
                onRemovePromoCode={removePromoCode}
                promotionPackage={promotionPackage}
                setPaymentView={setPaymentView}
                service={T.screens.laundry.title}
                setPaymentMethod={setPaymentMethod}
                selectedPaymentMethod={paymentMethod}
                discount={promotionPackage?.promotion.percent || promotionPackage?.promotion.package.credit}
                promotionType={promotionPackage?.promotion.promo_type}
                requestPromotion={onRequestPromotion}
                promoCode={form.promoCode}
            />
        )
    }

    return (
        <React.Fragment>
            <MobileOtpModal
                isOpen={isMobileOtpModalOpen}
                onClose={() => setIsMobileOtpModalOpen(false)}
                onOtpVerify={() => setIsMobileOtpModalOpen(false)}
            />
            <PaymentNewCardModal
                isOpen={isNewCardModalOpen}
                onClose={() => setIsNewCardModalOpen(false)}
            />
            <LaundrySummaryView
                submit={handleSubmit}
                disabled={isDisabled}
                setPaymentMethod={setPaymentMethod}
                onRemovePromoCode={removePromoCode}
                fetchPostalCodes={fetchPostalCodes}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={promotionPackage?.total_cost || route.params?.price}
                setPaymentView={setPaymentView}
                promotionPackage={promotionPackage}
                requestPromotion={onRequestPromotion}
                selectedPaymentMethod={paymentMethod}
                formShape={route.params.form}
                addressForm={addressForm}
                persistentComment={route.params.persistentComment}
                form={form as Record<keyof ServiceAddonsFormShape, Field<string & boolean>>}
            />
        </React.Fragment>
    )
}
