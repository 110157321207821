import React, { useState } from 'react'
import { Image, View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Text } from '@propertyguru/hive-text'
import { Button } from '@propertyguru/hive-button'
import { useNavigation } from '@react-navigation/native'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { linkingHelpers } from 'lib/utils'
import { WebViewModal } from 'features/native/common/components'
import { ENV } from 'lib/config'
import { useNativeUserAtom } from 'lib/native/atoms'
import {
    CLEANING_HELPER_ARRIVED,
    CLEANING_TOOLS_READY,
    INCOMPLETE_PROFILE,
    PRIMARY,
    ROUTINE_CLEANING,
    SECONDARY,
    URL
} from '../constants'
import { ActionDetail, ActionTitleParam, CTAData, TitleElement } from '../types'
import { useSaveHelperArrived } from '../actions'
import { HelperArrivedRequest } from '../types/requests'
import { Segment, SegmentNative } from 'lib/analytics'

enum ActionTypes {
    Primary = 'primary',
    Secondary = 'secondary',
}

type ActionCardProps = {
    actionData: CTAData;
    refreshDynamicCardData?: () => void;
}

export const ActionCard: React.FunctionComponent<ActionCardProps> = ({ actionData, refreshDynamicCardData }) => {
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const T = useTranslations()
    const [nativeUser] = useNativeUserAtom()
    const { mutate: saveHelperArrived } = useSaveHelperArrived()
    const [isCleaningSuppliesModalOpen, setIsCleaningSuppliesModalOpen] = useState(false)

    const { image, title, actions } = actionData
    const hasSecondaryAction = actions?.findIndex(action => action.actionType === SECONDARY) > -1

    const getBoldFormattedText = (text?: string) => (
        <Text key={text} typography="label/s">
            {text}
        </Text>
    )

    const getCTAFormattedString = (text: string | undefined, onPress: VoidFunction) => (
        <Text
            key={text}
            onPress={onPress}
            typography="label/xs"
            underline
            testID={T.accessibility.dashboard.actionCard.link}
            accessible
            accessibilityLabel={T.accessibility.dashboard.actionCard.link}
        >
            {text}
        </Text>
    )

    const getActionTitle = (title: Array<TitleElement>) =>
        Object.keys(title).map((key, index) => {
            const actionTitle = title[index]
            const innerKey = Object.keys(actionTitle)[0]

            if (innerKey === ActionTitleParam.TextBold) {
                return getBoldFormattedText(actionTitle.textBold)
            }

            if (innerKey === ActionTitleParam.Text) {
                return actionTitle.text
            }

            if (innerKey === ActionTitleParam.TextAction) {
                return getCTAFormattedString(actionTitle.textAction, () => { })
            }
        })

    const navigateToProfile = (action?: ActionDetail) => {
        action && Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.ActionCardClicked, information: { type: action?.actionType, value: action?.title } })
        navigation.navigate(ScreenNamesNative.UserNative)
    }

    const openCleaningTools = (action?: ActionDetail) => {
        action && Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.ActionCardClicked, information: { type: action?.actionType, value: T.segment.actionCards.CleaningSuppliesCtaClicked } })
        setIsCleaningSuppliesModalOpen(true)
    }

    const redirectToCleaning = (action?: ActionDetail) => {
        action && Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.ActionCardClicked, information: { type: action?.actionType, value: action?.title } })
        navigation.navigate(ScreenNamesNative.CleaningNative)
    }

    const saveCleanerArrived = action => {
        action && Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.ActionCardClicked, information: { type: action?.actionType, value: T.segment.actionCards.HelperArrivedCtaClicked } })

        const payload: HelperArrivedRequest = {
            token: nativeUser._id || '',
            bookingId: action?.action?.data?.bookingId,
            updates: {
                helperArrived: true
            }
        }
        saveHelperArrived(payload, {
            onSuccess: () => {
                refreshDynamicCardData && refreshDynamicCardData()
            },
            onError: error => {
                console.log('error', error)
            }
        })
    }

    const onRedirectSecondaryClick = action => {
        action && Segment.elementClicked({ elementType: SegmentNative.ElementClickedElementType.ActionCardClicked, information: { type: action?.actionType, value: T.segment.actionCards.HelperDelayedCtaClicked } })

        switch (action?.action?.type) {
            case URL:
                return linkingHelpers.openUrl(action?.action?.url)
            default:
                return null
        }
    }

    const renderAction = (primaryAction, secondaryAction) => (
        <View style={styles.container}>
            {image && (
                <Image source={{ uri: image }} style={styles.image} />
            )}
            <View style={styles.contentContainer}>
                <Text
                    typography="label/xs"
                    testID={T.accessibility.dashboard.actionCard.text}
                    accessible
                    accessibilityLabel={T.accessibility.dashboard.actionCard.text}
                >
                    {getActionTitle(title)}
                </Text>
            </View>
            <View style={styles.actionsContainer}>
                {actions?.map((action, key) => (
                    <Button
                        key={key}
                        style={hasSecondaryAction ? styles.primaryCTA : styles.fullWidthCTA}
                        type={action.actionType}
                        onPress={action.actionType === PRIMARY ? () => primaryAction(action) : () => secondaryAction(action)}
                        text={action.title}
                        size="small"
                        testID={
                            action.actionType === ActionTypes.Secondary
                                ? T.accessibility.dashboard.actionCard.secondaryButton
                                : T.accessibility.dashboard.actionCard.ctaButton
                        }
                        accessible
                        accessibilityLabel={
                            action.actionType === ActionTypes.Secondary
                                ? T.accessibility.dashboard.actionCard.secondaryButton
                                : T.accessibility.dashboard.actionCard.ctaButton
                        }
                    />
                ))}
            </View>
        </View>
    )

    const getPrimaryAction = () => actionData.actions.find(({ actionType }) => actionType === ActionTypes.Primary)
    const getSecondaryAction = () => actionData.actions.find(({ actionType }) => actionType === ActionTypes.Secondary)

    const getAction = () => {
        switch (actionData.id) {
            case INCOMPLETE_PROFILE:
                return renderAction(() => navigateToProfile(getPrimaryAction()), () => { })
            case CLEANING_TOOLS_READY:
                return renderAction(() => openCleaningTools(getPrimaryAction()), () => { })
            case CLEANING_HELPER_ARRIVED:
                return renderAction(() => saveCleanerArrived(getPrimaryAction()),()  => onRedirectSecondaryClick(getSecondaryAction()))
            case ROUTINE_CLEANING:
                return renderAction(() => { },() => redirectToCleaning(getSecondaryAction()))
            default:
                return renderAction(
                    () => { },
                    () => { }
                )
        }
    }

    return (
        <>
            {getAction()}
            <WebViewModal
                title={T.screens.cleaningNative.infoButtons.supplies}
                isOpen={isCleaningSuppliesModalOpen}
                onClose={() => setIsCleaningSuppliesModalOpen(false)}
                uri={`${ENV.SENDHELPER_MARKETING_SITE_URL}/cleaning-supplies`}
            />
        </>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        paddingVertical: theme.utils.hive.spacing('x4'),
        marginBottom: theme.utils.hive.spacing('x6'),
        gap: theme.utils.hive.spacing('x4'),
        justifyContent: 'space-between'
    },
    contentContainer: {
        flexDirection: 'row',
        alignContent: 'center',
        flex: 1
    },
    bold: {
        fontSize: 14,
        fontWeight: 'bold',
        color: 'blue'
    },
    image: {
        width: 50,
        height: 50
    },
    content: {
        flexDirection: 'row'
    },

    actionsContainer: {
        justifyContent: 'center',
        flexDirection: 'row',
        gap: theme.utils.hive.spacing('x2')
    },
    primaryCTA: {
        minWidth: 60,
        maxWidth: 60
    },
    fullWidthCTA: {
        minWidth: 100
    }
}))
