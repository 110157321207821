import React from 'react'
import { ScrollView } from 'react-native'
import { DrawerContentComponentProps } from '@react-navigation/drawer'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { AppDrawerContent } from '../appLayout'

export const CustomRightDrawer: React.FunctionComponent<DrawerContentComponentProps> = () => {
    const { styles } = useStyles(stylesheet)

    return (
        <ScrollView style={styles.drawerWrapper}>
            <AppDrawerContent />
        </ScrollView>
    )
}

const stylesheet = createStyles(theme => ({
    drawerWrapper: {
        flex: 1,
        width: '100%',
        paddingTop: {
            ':w[1440, ]': Measurements.WideHeaderHeight,
            ':w[, 1440]': Measurements.HeaderHeight
        },
        paddingHorizontal: theme.utils.gap(2)
    }
}))
