import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Camera: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon {...props}>
            <Path
                stroke={color}
                fill="transparent"
                strokeLinejoin="round"
                strokeWidth={6}
                d="m25 20 5-10h20l5 10H25ZM68.333 20H11.667a5 5 0 0 0-5 5v40a5 5 0 0 0 5 5h56.666a5 5 0 0 0 5-5V25a5 5 0 0 0-5-5Z"
            />
            <Path
                stroke={color}
                fill="transparent"
                strokeLinejoin="round"
                strokeWidth={6}
                d="M40 58.333c7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.333-13.333-13.333-7.364 0-13.334 5.97-13.334 13.333 0 7.364 5.97 13.333 13.334 13.333Z"
            />
        </Icon>
    )
}
