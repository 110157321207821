import React from 'react'
import Animated from 'react-native-reanimated'
import { AvoidSoftInputView } from 'react-native-avoid-softinput'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { Surface } from '@propertyguru/hive-surface'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { Breakpoint, breakpoints, createStyles } from 'lib/styles'
import { BackButton } from 'features/native/common/components'

type UnauthorizedNativeLayoutProps = {
    children: Children,
    centerVertical?: boolean,
    scrollEnabled?: boolean,
    themeColor?: string,
    fullScrollContainerHeight?: boolean,
    bounces?: boolean
}

export const UnauthorizedNativeLayout: React.FunctionComponent<UnauthorizedNativeLayoutProps> = ({
    children,
    centerVertical = false,
    scrollEnabled = true,
    fullScrollContainerHeight = true,
    bounces = true,
    themeColor
}) => {
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const { styles, theme } = useStyles(stylesheet)

    const scrollContainerStyle = fullScrollContainerHeight ? {
        ...styles.scrollContainerStyle,
        height: '100%'
    } : styles.scrollContainerStyle

    const containerStyle = centerVertical
        ? {
            ...scrollContainerStyle,
            ...styles.centerVertical
        }
        : scrollContainerStyle

    return (
        <Surface style={{
            ...styles.mainContainer,
            backgroundColor: themeColor ? themeColor : theme.colors.white
        }}>
            <View
                style={{
                    ...styles.backButtonContainer,
                    marginTop: insets.top + theme.utils.gap(1),
                    backgroundColor: themeColor
                }}
            >
                <BackButton themeColor={themeColor} onPress={() => navigation.canGoBack() ? navigation.goBack() : null} />
            </View>
            <View style={styles.container}>
                <View style={styles.content}>
                    {
                        scrollEnabled ? (
                            <Animated.ScrollView
                                scrollEventThrottle={16}
                                showsVerticalScrollIndicator={false}
                                keyboardShouldPersistTaps="handled"
                                bounces={bounces}
                            >
                                <AvoidSoftInputView style={containerStyle}>
                                    {children}
                                </AvoidSoftInputView>
                            </Animated.ScrollView>
                        ) : (
                            <KeyboardAwareScrollView
                                scrollEnabled={false}
                                extraScrollHeight={20}
                                showsVerticalScrollIndicator={false}
                                contentContainerStyle={containerStyle}
                                keyboardShouldPersistTaps="handled"
                                bounces={bounces}
                            >
                                {children}
                            </KeyboardAwareScrollView>
                        )}

                </View>
            </View>
        </Surface>
    )
}

const stylesheet = createStyles(theme => ({
    mainContainer: {
        flex: 1
    },
    container: {
        flex: 1,
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        justifyContent: 'center',
        alignItems: {
            lg: 'center'
        }
    },
    content: {
        flex: {
            xl: 2,
            lg: 1,
            xs: 4
        },
        // adding a 100% height will give child components the ability to span across screens if needed
        height: '100%',
        justifyContent: {
            lg: 'center',
            xs: 'flex-start'
        },
        maxWidth: breakpoints[Breakpoint.LG],
        backgroundColor: theme.colors.white,
        paddingHorizontal: {
            xl: theme.utils.gap(10),
            lg: theme.utils.gap(5)
        }
    },
    scrollContainerStyle: {
        paddingTop: {
            lg: theme.utils.gap(8),
            xs: 0
        }
    },
    backButtonContainer: {
        paddingHorizontal: 0
    },
    centerVertical: {
        flex: 1,
        justifyContent: {
            lg: 'center',
            xs: 'flex-start'
        },
        paddingTop: 0
    }
}))
