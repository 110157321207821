import React from 'react'
import { View } from 'react-native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { useAddressAtom, useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { Address, MediaQuery, PolicyLinks } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { PaymentType } from 'lib/models'
import { Breakpoint } from 'lib/styles'
import { Nullable, SupplyID, VoidFunction } from 'lib/types'
import {
    MoversAndPackersFormShape,
    MoversAndPackersAddonsShape
} from '../../forms'
import { GetPromotionResponse } from '../../types'
import { BookingCancellationFees } from '../BookingCancellationFees'
import { MobileSummary } from '../MobileSummary'
import { PaymentMethodTile } from '../PaymentMethodTile'
import { PromotionCodeInput } from '../PromotionCodeInput'
import { Rated } from '../Rated'
import { VisitCharges } from '../VisitCharges'
import { MoversAndPackersSummaryColumn } from './MoversAndPackersSummaryColumn'
import { MoversAndPackersPriceNote } from './MoversAndPackersPriceNote'
import { getServiceStaticConfig } from 'features/bookings/utils'

type MoversAndPackersSummaryFooterProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    formReturn: UseFormReturn<MoversAndPackersAddonsShape>,
    formShape: MoversAndPackersFormShape,
    isLoading: boolean,
    totalPrice: number,
    originalPrice?: Nullable<number>,
    authenticationView: boolean,
    submit: VoidFunction,
    promotionPackage: Nullable<GetPromotionResponse>
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction
}

export const MoversAndPackersSummaryFooter: React.FunctionComponent<MoversAndPackersSummaryFooterProps> = ({
    formReturn,
    disabled,
    formShape,
    totalPrice,
    originalPrice,
    requestPromotion,
    setPaymentView,
    promotionPackage,
    authenticationView,
    isLoading,
    submit,
    selectedPaymentMethod,
    onRemovePromoCode
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [address] = useAddressAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const { form } = formReturn

    const config = getServiceStaticConfig(SupplyID.MoversPackers)
    const { pricing: { cancellationCharges: { title, subtitle, details: visitChargesDetails } } } = config

    return (
        <MediaQuery.Hidden from={Breakpoint.LG}>
            <MobileSummary
                authenticationView={authenticationView && !isAuthorized}
                submit={submit}
                submitText={T.common.confirm}
                priceLabel={T.common.totalPrice}
                price={totalPrice}
                onRemovePromoCode={onRemovePromoCode}
                priceBeforeDiscount={originalPrice}
                promoCode={form.promoCode}
                promotionPackage={promotionPackage}
                requestPromotion={requestPromotion}
                disabled={disabled}
                isLoading={isLoading}
                content={(
                    <React.Fragment>
                        <View>
                            {address.postcode && (
                                <Address disabled={isLoading} />
                            )}
                        </View>
                        <MoversAndPackersSummaryColumn
                            price={totalPrice}
                            totalPrice={totalPrice}
                            promotionPackage={promotionPackage}
                            renderPriceComponent={() => (
                                <MoversAndPackersPriceNote
                                    price={totalPrice}
                                    originalPrice={originalPrice}
                                />
                            )}
                            form={formShape}
                            couponText={promotionPackage
                                ? promotionPackage.promotion.message_to_client
                                : undefined
                            }
                            renderExtraContent={() => (
                                <View>
                                    <VisitCharges
                                        labelText={title}
                                        chargesMessage={subtitle}
                                        modalTitle={visitChargesDetails.title}
                                        messageText={visitChargesDetails.description}
                                    />
                                    <PromotionCodeInput
                                        promoCode={form.promoCode}
                                        isLoading={isLoading}
                                        promotionPackage={promotionPackage}
                                        onRemovePromoCode={onRemovePromoCode}
                                        requestPromotion={requestPromotion}
                                    />
                                </View>
                            )}
                        />
                        {!authenticationView && (
                            <PaymentMethodTile
                                notEnoughCredits={notEnoughCredits}
                                onPress={() => setPaymentView(true)}
                                selectedPaymentMethod={selectedPaymentMethod}
                            />
                        )}
                        <PolicyLinks />
                        <Rated />
                        <BookingCancellationFees />
                    </React.Fragment>
                )}
            />
        </MediaQuery.Hidden>
    )
}
