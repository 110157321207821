import React, { ReactNode } from 'react'
import { useHive } from '@propertyguru/hive'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { ScreenHeaderButton } from './sub-components/Button.native'
import { ScreenHeaderTitle } from './sub-components/Title.native'

type ScreenHeaderProps = {
    children: ReactNode;
}

const ScreenHeader = ({ children }: ScreenHeaderProps) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={{
            ...styles.container,
            paddingVertical: hive.spacing('x3'),
            paddingHorizontal: hive.spacing('x4'),
            columnGap: hive.spacing('x4')
        }}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        flexDirection: 'row',
        alignItems: 'center'
    }
}))

export default Object.assign(ScreenHeader, {
    Button: ScreenHeaderButton,
    Title: ScreenHeaderTitle
})
