import React from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { Text } from '@propertyguru/hive-text'
import { Touchable } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'

type HiveCalendarDayProps = {
    date: Date,
    isCurrentMonth: boolean,
    isToday: boolean,
    isSelected: boolean,
    isDisabled: boolean,
    icon?: React.ReactNode,
    onPress: (date: Date) => void
}

export const HiveCalendarDay = ({ date, isCurrentMonth, isToday, isSelected, isDisabled, icon, onPress }: HiveCalendarDayProps) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            onPress={() => !isDisabled && onPress(date)}
            style={styles.day}
            disabled={isDisabled}
        >
            <View style={{
                ...styles.dayContainer,
                ...(isDisabled ? styles.dayContainerDisabled : {}),
                borderRadius: hive.borderRadius('large'),
                backgroundColor: isSelected || isToday ? hive.color(isSelected ? 'fill/active/primary' : 'fill/active/secondary') : 'transparent'
            }}>
                <Text
                    typography={'body/m'}
                    color={isDisabled || !isCurrentMonth ? 'text/inactive/primary' : 'text/active/primary'}>
                    {date.getDate()}
                </Text>
            </View>
            <View style={styles.iconContainer}>{icon}</View>
        </Touchable>
    )
}

const stylesheet = createStyles(() => ({
    day: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    dayContainer: {
        width: 40,
        height: 40,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dayContainerDisabled: {
        opacity: 0.5
    },
    iconContainer: {
        minHeight: 16
    }
}))
