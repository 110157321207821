// @ts-nocheck
// noinspection ALL

// This file is generated by svgr

import React, { memo } from 'react'
import Svg, { Path, type SvgProps } from 'react-native-svg'
const SvgWhatsApp = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={17}
        fill="none"
        {...props}
    >
        <Path
            fill="#3E4246"
            d="m1.333 15.167.938-3.424a6.593 6.593 0 0 1-.882-3.304c.002-3.642 2.965-6.606 6.607-6.606 1.767 0 3.426.69 4.674 1.938a6.569 6.569 0 0 1 1.933 4.675c-.001 3.642-2.965 6.606-6.607 6.606a6.611 6.611 0 0 1-3.16-.804l-3.503.919Zm3.665-2.115c.931.553 1.82.884 2.996.884a5.502 5.502 0 0 0 5.494-5.492 5.49 5.49 0 0 0-5.49-5.495A5.5 5.5 0 0 0 2.504 8.44c0 1.236.362 2.162.97 3.13l-.555 2.027L5 13.052Zm6.326-3.036c-.04-.069-.15-.11-.316-.193-.165-.082-.977-.482-1.129-.537-.15-.055-.26-.083-.371.083-.11.165-.427.537-.523.647-.096.11-.193.124-.358.041-.165-.083-.697-.256-1.328-.82a4.97 4.97 0 0 1-.918-1.143c-.096-.165-.01-.255.072-.337.075-.074.165-.193.248-.29.084-.095.111-.164.167-.274.055-.11.028-.207-.014-.29-.042-.082-.372-.895-.509-1.225-.134-.322-.27-.279-.372-.284l-.316-.005a.605.605 0 0 0-.44.207c-.151.165-.578.564-.578 1.377 0 .813.592 1.598.674 1.708.083.11 1.164 1.777 2.82 2.492.394.17.701.272.94.348a2.27 2.27 0 0 0 1.04.066c.318-.047.978-.4 1.115-.785.138-.386.138-.717.096-.786Z"
        />
    </Svg>
)
export default memo(SvgWhatsApp)

