import React from 'react'
import { ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useHive } from '@propertyguru/hive'
import { Divider } from '@propertyguru/hive-divider'
import { ChevronLeftOutline } from '@propertyguru/hive-icons'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { SendPayScreenContent } from 'features/sendPay/components'
import { ScreenHeader } from 'lib/native/components'

export const SendPayScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const hive = useHive()
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()

    return (
        <View style={{
            ...styles.container,
            marginTop: insets.top
        }}>
            <ScreenHeader>
                <ScreenHeader.Button icon={ChevronLeftOutline} onPress={() => navigation.canGoBack() && navigation.goBack()} />
                <ScreenHeader.Title>{T.screens.sendPay.pageTitle}</ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <ScrollView style={{ marginBottom: insets.bottom + hive.spacing('x4') }}>
                <SendPayScreenContent hideTitle/>
            </ScrollView>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string
    },
    backButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.hive.spacing('x4'),
        borderBottomWidth: 0.5,
        borderColor: theme.utils.hive.color('border/active/secondary') as string
    },
    tabContainer: {
        width: Measurements.WindowWidth,
        height: Measurements.WindowHeight
    },
    activeLabel: {
        color: theme.colors.orange
    }
}))
