import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
import { QueryKey, useFetcher } from 'lib/api'
import {
    useSubscriptionRecommendationListAtom,
    useSubscriptionSpecialRecommendationAtom,
    useUnreadJobChatsAtom, useUserTokenAtom
} from 'lib/atoms'
import { ErrorResponse, HttpMethod, JobStatus, Response } from 'lib/types'
import { Job, RecurringJob, UnreadChatMessage } from 'lib/models'
import {
    AcceptChargesJobResponse,
    AcceptChargesRequest,
    CancelJobRequest,
    CancelJobResponse,
    ConsumerJobsResponse,
    ExtendJobRequest,
    ExtendJobResponse,
    JobDetailsResponse,
    PauseJobRequest,
    PauseJobResponse,
    ProposalActionRequest,
    ProposalResponse,
    RateJobRequest,
    RateJobResponse,
    RescheduleJobRequest,
    RescheduleJobResponse,
    RescheduleLaundryRequest,
    ResumeJobRequest,
    ResumeJobResponse
} from './types'

// isSubscription is used to determine if the job is a subscription or not only for air condition jobs
// for other jobs, it is not used
// need to find a better implementation for this
export const getAirConditionJobDetails = (jobId: string) => {
    const [token] = useUserTokenAtom()
    const fetcher = useFetcher<JobDetailsResponse>(HttpMethod.POST, '/consumer-aircon-booking-get-details')

    return useQuery<Response<JobDetailsResponse>, ErrorResponse>([QueryKey.JobDetails, jobId], () => fetcher({ token, id: jobId }), { enabled: Boolean(jobId) })

}

export const getJobDetails = (jobId: string) => {
    const [token] = useUserTokenAtom()
    const fetcher = useFetcher<JobDetailsResponse>(HttpMethod.POST, '/job/detail')

    return useQuery<Response<JobDetailsResponse>, ErrorResponse>([QueryKey.JobDetails, jobId], () => fetcher({ token, id: jobId }), { enabled: Boolean(jobId) })
}

export const getConsumerActiveJobs = () => {
    const [token] = useUserTokenAtom()
    const fetcher = useFetcher<ConsumerJobsResponse>(HttpMethod.POST, '/consumer-get-active-jobs')
    const [, setSubscriptionSpecialRecommendation] = useSubscriptionSpecialRecommendationAtom()
    const [, setSubscriptionRecommendationList] = useSubscriptionRecommendationListAtom()

    return useQuery<Response<ConsumerJobsResponse>, ErrorResponse>([QueryKey.ActiveJobs], () => fetcher({ token }), {
        onSuccess: ({ data }) => {
            setSubscriptionSpecialRecommendation(data?.subscriptionSpecialRecommendation || {})
            setSubscriptionRecommendationList(data?.subscriptionMyBookingsRecommendations || [])
        }
    })
}

export const useGetConsumerActiveJobs = () => {
    const [token] = useUserTokenAtom()
    const [, setUnreadJobChats] = useUnreadJobChatsAtom()
    const [, setSubscriptionSpecialRecommendation] = useSubscriptionSpecialRecommendationAtom()
    const [, setSubscriptionRecommendationList] = useSubscriptionRecommendationListAtom()
    const fetcher = useFetcher<ConsumerJobsResponse>(HttpMethod.POST, '/consumer-get-active-jobs')

    return useQuery<Response<ConsumerJobsResponse>, ErrorResponse>([QueryKey.ActiveJobsLoop], () => fetcher({ token }), {
        enabled: Boolean(token),
        refetchOnMount: false,
        refetchInterval: 1000 * 30,
        refetchIntervalInBackground: true,
        onSuccess: ({ data }) => {
            const toSharableJobContext = (job: Job | RecurringJob): UnreadChatMessage => ({
                jobFetchId: job._id,
                jobId: job.job_id,
                status: job.status,
                supplierId: job.supplier?._id,
                hasUnreadNotification: Boolean(job.notifications?.consumerHasUnreadChatMsg)
            })

            const chatsWithUnreadMessages = data.job_list
                .map(toSharableJobContext)
                .concat(data.recurring_job_list.map(toSharableJobContext))
                .filter(job => job.status === JobStatus.Accepted && job.hasUnreadNotification)

            setUnreadJobChats(chatsWithUnreadMessages)
            setSubscriptionSpecialRecommendation(data?.subscriptionSpecialRecommendation || {})
            setSubscriptionRecommendationList(data?.subscriptionMyBookingsRecommendations || [])
        }
    })
}

export const getConsumerCompletedJobs = () => {
    const [token] = useUserTokenAtom()
    const fetcher = useFetcher<ConsumerJobsResponse>(HttpMethod.POST, '/consumer-get-completed-jobs-history')
    const [, setSubscriptionSpecialRecommendation] = useSubscriptionSpecialRecommendationAtom()
    const [, setSubscriptionRecommendationList] = useSubscriptionRecommendationListAtom()

    return useQuery<Response<ConsumerJobsResponse>, ErrorResponse>([QueryKey.CompletedJobs], () => fetcher({ token }), {
        onSuccess: ({ data }) => {
            setSubscriptionSpecialRecommendation(data?.subscriptionSpecialRecommendation || {})
            setSubscriptionRecommendationList(data?.subscriptionMyBookingsRecommendations || [])
        }
    })
}

export const getConsumerCancelledJobs = () => {
    const [token] = useUserTokenAtom()
    const fetcher = useFetcher<ConsumerJobsResponse>(HttpMethod.POST, '/consumer-get-cancelled-jobs-history')
    const [, setSubscriptionSpecialRecommendation] = useSubscriptionSpecialRecommendationAtom()
    const [, setSubscriptionRecommendationList] = useSubscriptionRecommendationListAtom()

    return useQuery<Response<ConsumerJobsResponse>, ErrorResponse>([QueryKey.CancelledJobs], () => fetcher({ token }), {
        onSuccess: ({ data }) => {
            setSubscriptionSpecialRecommendation(data?.subscriptionSpecialRecommendation || {})
            setSubscriptionRecommendationList(data?.subscriptionMyBookingsRecommendations || [])
        }
    })
}

export const cancelAirconJob = () => {
    const fetcher = useFetcher<CancelJobResponse>(HttpMethod.POST, '/consumer-aircon-booking-cancel')

    return useMutation<Response<CancelJobResponse>, ErrorResponse, CancelJobRequest>(fetcher)
}

export const cancelJob = () => {
    const fetcher = useFetcher<CancelJobResponse>(HttpMethod.POST, '/job/cancel')

    return useMutation<Response<CancelJobResponse>, ErrorResponse, CancelJobRequest>(fetcher)
}

export const rateJob = () => {
    const fetcher = useFetcher<RateJobResponse>(HttpMethod.POST, '/job/rate')

    return useMutation<Response<RateJobResponse>, ErrorResponse, RateJobRequest>(fetcher)
}

export const proposalAction = () => {
    const fetcher = useFetcher<ProposalResponse>(HttpMethod.POST, '/job/proposal')

    return useMutation<Response<ProposalResponse>, ErrorResponse, ProposalActionRequest>(fetcher)
}

export const getJobsDetails = (jobsIds: Array<string>) => {
    const [token] = useUserTokenAtom()
    const fetcher = useFetcher<JobDetailsResponse>(HttpMethod.POST, '/job/detail')

    const queries = jobsIds.map(id => ({
        queryKey: [QueryKey.JobDetails, id],
        queryFn: () => fetcher({ token, id })
    }))

    return useQueries({
        queries
    })
}

export const rescheduleJob = () => {
    const fetcher = useFetcher<RescheduleJobResponse>(HttpMethod.POST, '/consumer-reschedule-job')

    return useMutation<Response<RescheduleJobResponse>, ErrorResponse, RescheduleJobRequest>(fetcher)
}

export const extendJobHours = () => {
    const fetcher = useFetcher<ExtendJobResponse>(HttpMethod.POST, '/job/extend_job_hours')

    return useMutation<Response<ExtendJobResponse>, ErrorResponse, ExtendJobRequest>(fetcher)
}

export const pauseJob = () => {
    const fetcher = useFetcher<PauseJobResponse>(HttpMethod.POST, '/job/pause_recurring_job')

    return useMutation<Response<PauseJobResponse>, ErrorResponse, PauseJobRequest>(fetcher)
}

export const resumeJob = () => {
    const fetcher = useFetcher<ResumeJobResponse>(HttpMethod.POST, '/job/un_pause_recurring_job')

    return useMutation<Response<ResumeJobResponse>, ErrorResponse, ResumeJobRequest>(fetcher)
}

export const acceptCharges = () => {
    const fetcher = useFetcher<AcceptChargesJobResponse>(HttpMethod.POST, '/job/charges/customer_approve_charges')

    return useMutation<Response<AcceptChargesJobResponse>, ErrorResponse, AcceptChargesRequest>(fetcher)
}

// for now used only to reschedule laundry delivery
export const updateJob = () => {
    const fetcher = useFetcher<ConsumerJobsResponse>(HttpMethod.POST, '/job/laundry/update_sub_status')

    return useMutation<Response<ConsumerJobsResponse>, ErrorResponse, RescheduleLaundryRequest>(fetcher)
}
