import { Address, HandymanService, Service } from 'lib/models'
import { AirConditionServiceOption, FrequencyOption, NewAddressRequestShape, Nullable } from 'lib/types'
import { UploadedPhoto } from 'lib/components'
import {
    CommercialPropertyType,
    HomePropertyType,
    PestControlPackage,
    PestControlSession,
    ServicePlanOption
} from '../types'

export enum CleaningFields {
    PropertyType = 'propertyType',
    PropertySize = 'propertySize',
    Bedrooms = 'bedrooms',
    Bathrooms = 'bathrooms',
    Hours = 'hours',
    Frequency = 'frequency',
    StartingDate = 'startingDate',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays',
    ServicePlan = 'servicePlan'
}

export type CleaningFormShape = {
    [CleaningFields.PropertyType]: string,
    // TODO Not supported by API
    // [CleaningFields.Bedrooms]: string,
    // [CleaningFields.Bathrooms]: string,
    [CleaningFields.Hours]: string,
    [CleaningFields.Frequency]: FrequencyOption,
    [CleaningFields.StartingDate]: string,
    [CleaningFields.BookingTime]: string,
    [CleaningFields.BookingDays]: Record<string, Nullable<string>>,
    [CleaningFields.ServicePlan]: Nullable<ServicePlanOption>,
}

export enum ServiceAddonsFields {
    ExtraHours = 'extraHours',
    Ironing = 'ironing',
    CleaningSupplies = 'cleaningSupplies',
    Pets = 'pets',
    Comment = 'comment',
    PromoCode = 'promoCode',
    Allergies = 'allergies',
    AllergiesComment = 'allergiesComment',
    ACCeiling = 'acCeiling',
    ACDuctMount = 'acDuctMount'
}

export type ServiceAddonsFormShape = {
    [ServiceAddonsFields.ExtraHours]: string,
    [ServiceAddonsFields.Ironing]: boolean,
    [ServiceAddonsFields.CleaningSupplies]: boolean,
    [ServiceAddonsFields.Pets]: string,
    [ServiceAddonsFields.Comment]: string,
    [ServiceAddonsFields.PromoCode]: string,
    [ServiceAddonsFields.Allergies]: boolean,
    [ServiceAddonsFields.AllergiesComment]: string,
    [ServiceAddonsFields.ACCeiling]: boolean,
    [ServiceAddonsFields.ACDuctMount]: boolean,
}

export enum SendPayCreditsFields {
    Amount = 'amount'
}

export type SendPayCreditsFormShape = {
    [SendPayCreditsFields.Amount]: string
}

export enum SelectPostcodeFields {
    Postcode = 'postcode'
}

export type SelectPostcodeFormShape = {
    [SelectPostcodeFields.Postcode]: string
}

export enum DeepCleaningFields {
    PropertyCleaning = 'propertyCleaning',
    PropertyType = 'propertyType',
    PropertySize = 'propertySize',
    Frequency = 'frequency',
    StartingDate = 'startingDate',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays',
    Services = 'services'
}

export type DeepCleaningFormShape = {
    [DeepCleaningFields.PropertyCleaning]: string,
    [DeepCleaningFields.PropertyType]: string,
    [DeepCleaningFields.PropertySize]: string,
    [DeepCleaningFields.Frequency]: FrequencyOption,
    [DeepCleaningFields.StartingDate]: string,
    [DeepCleaningFields.BookingTime]: string,
    [DeepCleaningFields.BookingDays]: Record<string, Nullable<string>>,
    [DeepCleaningFields.Services]: Array<Service>
}

export enum AirConditionFields {
    ServiceOption = 'serviceOption',
    Frequency = 'frequency',
    BookingDate = 'bookingDate',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays',
    NumberOfAirConditioners = 'numberOfAirConditioners'
}

export type AirConditionFormShape = {
    [AirConditionFields.ServiceOption]: AirConditionServiceOption,
    [AirConditionFields.Frequency]: FrequencyOption,
    [AirConditionFields.BookingDate]: string,
    [AirConditionFields.BookingTime]: string,
    [AirConditionFields.BookingDays]: Record<string, Nullable<string>>,
    [AirConditionFields.NumberOfAirConditioners]: number
}

export enum HandymanFields {
    Frequency = 'frequency',
    StartingDate = 'startingDate',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays',
    ServiceType = 'serviceType',
    Pets = 'pets',
    Comment = 'comment',
    Photos = 'photos',
    Materials = 'materials'
}

export type HandymanFormShape = {
    [HandymanFields.Frequency]: FrequencyOption,
    [HandymanFields.StartingDate]: string,
    [HandymanFields.BookingTime]: string,
    [HandymanFields.BookingDays]: Record<string, Nullable<string>>,
    [HandymanFields.ServiceType]: Array<HandymanService>,
    [HandymanFields.Pets]: string,
    [HandymanFields.Comment]: string,
    [HandymanFields.Photos]: Array<UploadedPhoto>,
    [HandymanFields.Materials]: boolean,
}

export enum HandymanAddonsFields {
    PromoCode = 'promoCode'
}

export type HandymanAddonsShape = {
    [ServiceAddonsFields.PromoCode]: string
}

export enum LaundryFields {
    Frequency = 'frequency',
    StartingDate = 'startingDate',
    DriverInstructions = 'driverInstructions',
    WashAndIron = 'washAndIron',
    WashAndIronItems = 'washAndIronItems',
    DryCleanAndIron = 'dryCleanAndIron',
    DryCleanAndIronItems = 'dryCleanAndIronItems',
    IronOnly = 'ironOnly',
    IronOnlyItems = 'ironOnlyItems',
    LoadWash = 'loadWash',
    LoadWashItems = 'loadWashItems',
    CurtainsWash = 'curtainsWash',
    CurtainsWashItems = 'curtainsWashItems',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays'
}

export type LaundryFormShape = {
    [LaundryFields.Frequency]: FrequencyOption,
    [LaundryFields.StartingDate]: string,
    [LaundryFields.DriverInstructions]: string,
    [LaundryFields.WashAndIron]: boolean,
    [LaundryFields.WashAndIronItems]: number,
    [LaundryFields.DryCleanAndIron]: boolean,
    [LaundryFields.DryCleanAndIronItems]: number,
    [LaundryFields.IronOnly]: boolean,
    [LaundryFields.IronOnlyItems]: number,
    [LaundryFields.LoadWash]: boolean,
    [LaundryFields.LoadWashItems]: number,
    [LaundryFields.CurtainsWash]: boolean,
    [LaundryFields.CurtainsWashItems]: number,
    [LaundryFields.BookingTime]: string,
    [LaundryFields.BookingDays]: Record<string, Nullable<string>>,
}

export enum CookingFields {
    Frequency = 'frequency',
    StartingDate = 'startingDate',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays',
    Hours = 'hours',
    PropertyType = 'propertyType',
    Appliances = 'appliances',
    Cuisines = 'cuisines',
    Comment = 'comment'
}

export type CookingFormShape = {
    [CookingFields.Frequency]: FrequencyOption,
    [CookingFields.StartingDate]: string,
    [CookingFields.BookingTime]: string,
    [CookingFields.BookingDays]: Record<string, Nullable<string>>,
    [CookingFields.Hours]: string,
    [CookingFields.PropertyType]: string,
    [CookingFields.Appliances]: string,
    [CookingFields.Cuisines]: string,
    [CookingFields.Comment]: string
}

export enum PestControlFields {
    Frequency = 'frequency',
    StartingDate = 'startingDate',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays',
    PropertyType = 'propertyType',
    Services = 'services',
    Packages = 'packages',
}

export type PestControlFormShape = {
    [PestControlFields.Frequency]: FrequencyOption,
    [PestControlFields.StartingDate]: string,
    [PestControlFields.BookingTime]: string,
    [PestControlFields.BookingDays]: Record<string, Nullable<string>>,
    [PestControlFields.PropertyType]: number,
    [PestControlFields.Services]: Array<PestControlSession>,
    [PestControlFields.Packages]: Array<PestControlPackage>
}

export enum PestControlAddonsFields {
    Pets = 'pets',
    Comment = 'comment',
    PromoCode = 'promoCode'
}

export type PestControlAddonsFormShape = {
    [PestControlAddonsFields.Pets]: string,
    [PestControlAddonsFields.Comment]: string,
    [ServiceAddonsFields.PromoCode]: string
}

export enum TaskAndErrandsFields {
    Frequency = 'frequency',
    StartingDate = 'startingDate',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays',
    Hours = 'hours',
    TaskType = 'taskType',
    OtherTaskComment = 'otherTaskComment',
    Purchasing = 'purchasing'
    // TODO Hide for now
    // Vehicle = 'vehicle'
}

export type TaskInput = {
    key: string;
    isRequired: boolean;
    placeholder: string;
    parentKey: string;
    hasError: boolean;
    value: string;
    hasChange: boolean;
    errorMessage: string;
    label: string;
}

export type TaskAndErrandsFormShape = {
    [TaskAndErrandsFields.Frequency]: FrequencyOption,
    [TaskAndErrandsFields.StartingDate]: string,
    [TaskAndErrandsFields.BookingTime]: string,
    [TaskAndErrandsFields.BookingDays]: Record<string, Nullable<string>>,
    [TaskAndErrandsFields.Hours]: string,
    [TaskAndErrandsFields.TaskType]: TaskInput,
    [TaskAndErrandsFields.OtherTaskComment]: string,
    [TaskAndErrandsFields.Purchasing]: boolean
    // TODO Hide for now
    // [TaskAndErrandsFields.Vehicle]: string
}

export enum MoversAndPackersFields {
    Frequency = 'frequency',
    StartingDate = 'startingDate',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays',
    ServiceType = 'serviceType',
    PickUpLocation = 'pickUpLocation',
    PickUpPropertyType = 'pickUpPropertyType',
    PickUpNumberOfBedrooms = 'pickUpNumberOfBedrooms',
    PickUpPropertySize = 'pickUpPropertySize',
    PickUpFloor = 'pickUpFloor',
    PickUpHasElevator = 'pickUpHasElevator',
    PickUpDetails = 'pickupDetails',
    DropOffLocation = 'dropOffLocation',
    DropOffPropertyType = 'dropOffPropertyType',
    DropOffNumberOfBedrooms = 'dropOffNumberOfBedrooms',
    DropOffPropertySize = 'dropOffPropertySize',
    DropOffFloor = 'dropOffFloor',
    DropOffHasElevator = 'dropOffHasElevator',
    DropOffDetails = 'dropOffDetails'
}

export type MoversAndPackersFormShape = {
    [MoversAndPackersFields.Frequency]: FrequencyOption,
    [MoversAndPackersFields.StartingDate]: string,
    [MoversAndPackersFields.BookingTime]: string,
    [MoversAndPackersFields.BookingDays]: Record<string, Nullable<string>>,
    [MoversAndPackersFields.ServiceType]: Nullable<string>,
    [MoversAndPackersFields.PickUpLocation]: Nullable<Address | NewAddressRequestShape>,
    [MoversAndPackersFields.PickUpPropertyType]: Nullable<HomePropertyType | CommercialPropertyType>,
    [MoversAndPackersFields.PickUpNumberOfBedrooms]: number,
    [MoversAndPackersFields.PickUpPropertySize]: Nullable<string>,
    [MoversAndPackersFields.PickUpFloor]: number,
    [MoversAndPackersFields.PickUpHasElevator]: boolean,
    [MoversAndPackersFields.PickUpDetails]: string,
    [MoversAndPackersFields.DropOffLocation]: Nullable<Address | NewAddressRequestShape>,
    [MoversAndPackersFields.DropOffPropertyType]: Nullable<HomePropertyType | CommercialPropertyType>,
    [MoversAndPackersFields.DropOffNumberOfBedrooms]: number,
    [MoversAndPackersFields.DropOffPropertySize]: Nullable<string>,
    [MoversAndPackersFields.DropOffFloor]: number,
    [MoversAndPackersFields.DropOffHasElevator]: boolean,
    [MoversAndPackersFields.DropOffDetails]: string,
}

export enum MoversAndPackersServiceDetailsFields {
    ListOfItems = 'listOfItems',
    PromoCode = 'promoCode',
    InspectionFrequency = 'inspectionFrequency',
    InspectionStartingDate = 'inspectionStartingDate',
    InspectionBookingTime = 'inspectionBookingTime',
    InspectionBookingDays = 'inspectionBookingDays'
}

export type MoversAndPackersServiceDetailsShape = {
    [MoversAndPackersServiceDetailsFields.ListOfItems]: string
    [MoversAndPackersServiceDetailsFields.PromoCode]: string,
    [MoversAndPackersServiceDetailsFields.InspectionFrequency]: FrequencyOption,
    [MoversAndPackersServiceDetailsFields.InspectionStartingDate]: string,
    [MoversAndPackersServiceDetailsFields.InspectionBookingTime]: string,
    [MoversAndPackersServiceDetailsFields.InspectionBookingDays]: Record<string, Nullable<string>>
}

export enum MoversAndPackersAddonsFields {
    Movers = 'movers',
    Tailgating = 'tailgating',
    Wrapping = 'wrapping',
    Pets = 'pets',
    Comment = 'comment',
    PromoCode = 'promoCode'
}

export type MoversAndPackersAddonsShape = {
    [MoversAndPackersAddonsFields.Movers]: number,
    [MoversAndPackersAddonsFields.Tailgating]: number,
    [MoversAndPackersAddonsFields.Wrapping]: boolean,
    [MoversAndPackersAddonsFields.Pets]: string,
    [MoversAndPackersAddonsFields.Comment]: string,
    [MoversAndPackersAddonsFields.PromoCode]: string
}
