import { View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Surface } from '@propertyguru/hive-surface'
import { Text } from '@propertyguru/hive-text'
import { useIsLastTimeSlotExpired, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { GroupedTimeSlots, TimeSlotFooter, TimeSlotHeader } from 'features/native/bookings/components'
import { Period, TimeSlot } from 'lib/native/types'
import { PriceSlot, PricingType } from 'lib/native/models'
import { Segment } from 'lib/analytics'
import { useNativeDynamicPriceSlotsAtom } from 'lib/native/atoms'
import { useTimeSlotsByPeriod } from 'lib/native/hooks'

type Props = {
    scheduledDate: string,
    activeSlot?: TimeSlot;
    type?: PricingType;
    onSelectBookingTime?: (priceSlot: PriceSlot | undefined, slot?: TimeSlot) => void;
}

export const TimeSlots: React.FunctionComponent<Props> = ({ onSelectBookingTime, type, activeSlot, scheduledDate }) => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const [selectedSlot, setSelectedSlot] = useState<TimeSlot | null>(activeSlot || null)
    const [dynamicPriceSlots] = useNativeDynamicPriceSlotsAtom()
    const lateTimeSlots = useTimeSlotsByPeriod(Period.Late)
    const isLateTimeSlotExpired = useIsLastTimeSlotExpired(lateTimeSlots, scheduledDate)
    const shouldShowDynamicPriceInfo =
        !dynamicPriceSlots?.daySelected ||
        Object.keys(dynamicPriceSlots?.slots?.[dynamicPriceSlots?.daySelected] ?? {}).length > 0

    useEffect(() => {
        if(activeSlot) {
            Segment.bookingDateAndTimeClicked({})
        }

        setSelectedSlot(activeSlot || null)
    }, [activeSlot])

    return (
        <View>
            <TimeSlotHeader shouldShowDynamicPriceInfo={shouldShowDynamicPriceInfo} />
            {isLateTimeSlotExpired && (
                <Text
                    style={styles.emptyTimeSlots}
                    typography="caption/xs"
                    color={'text/active/secondary'}
                >
                    {T.screens.cleaningDateTime.noAvailableTimeSlots}
                </Text>
            )}
            {!isLateTimeSlotExpired && (
                <Surface style={styles.list}>
                    <GroupedTimeSlots
                        scheduledDate={scheduledDate}
                        period={Period.Early}
                        type={type}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        onSelectBookingTime={onSelectBookingTime}
                        shouldShowDynamicPriceInfo={shouldShowDynamicPriceInfo}
                    />
                    <GroupedTimeSlots
                        scheduledDate={scheduledDate}
                        period={Period.Midday}
                        type={type}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        onSelectBookingTime={onSelectBookingTime}
                        shouldShowDynamicPriceInfo={shouldShowDynamicPriceInfo}
                    />
                    <GroupedTimeSlots
                        scheduledDate={scheduledDate}
                        period={Period.Late}
                        type={type}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        onSelectBookingTime={onSelectBookingTime}
                        shouldShowDynamicPriceInfo={shouldShowDynamicPriceInfo}
                    />
                </Surface>
            )}
            <TimeSlotFooter />
        </View>

    )
}

const stylesheet = createStyles(theme => ({
    header: {
        alignItems: 'center'
    },
    emptyTimeSlots: {
        textAlign: 'center',
        marginVertical: theme.utils.gap(5)
    },
    list: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: theme.utils.hive.spacing('x4'),
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string,
        borderRadius: theme.utils.hive.borderRadius('large'),
        gap: theme.utils.hive.spacing('x2')
    }
}))
