import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Addon: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M46 18c0-3.36 6-10 6-10s6 6.64 6 10c0 3.32-2.68 6-6 6s-6-2.68-6-6Zm16 34c5.52 0 10-4.48 10-10 0-6.68-10-18-10-18S52 35.32 52 42c0 5.52 4.48 10 10 10Zm-26-4h-8v-8h-8v8h-8v8h8v8h8v-8h8v-8Zm12-8v32c0 4.4-3.6 8-8 8H8c-4.4 0-8-3.6-8-8V40c0-11.88 8.64-21.72 20-23.64V8h-8V0h24c4.52 0 8.6 1.56 11.96 4.04l-5.72 5.72C40.4 8.68 38.28 8 36 8h-8v8.36C39.36 18.28 48 28.12 48 40Zm-8 0c0-8.84-7.16-16-16-16S8 31.16 8 40v32h32V40Z" />
    </Icon>
)
