import React, { useEffect, useState } from 'react'
import { Field, useForm } from '@codegateinc/react-form-builder-v2'
import { PaymentNewCardModal } from 'features/payments'
import { useSegmentSession, useTranslations, useSessionData } from 'lib/hooks'
import { useAddressAtom, useDynamicServiceAtom, useResetBookingJobQuotationAtom, useUserTokenAtom } from 'lib/atoms'
import { PaymentOption } from 'lib/models'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Segment, ServiceType } from 'lib/analytics'
import { SupplyID } from 'lib/types'
import { BookingPaymentView, PestControlComponents } from '../components'
import { PestControlAddonsFormShape, usePestControlAddonsForm } from '../forms'
import { useBookingPromoCode, usePestControlCreateJob, useJobQuotation } from '../hooks'
import { PackageIdentifier, PestControlJobQuotationRequest, CreatePestControlJobRequest } from '../types'
import { MobileOtpModal } from 'features/native/bookings/components'

type PestControlSummaryScreenProps = {
    navigation: NavigationProps<ScreenNames.PestControlSummary>,
    route: NavigationParams<ScreenNames.PestControlSummary>
}

export const PestControlSummaryScreen: React.FunctionComponent<PestControlSummaryScreenProps> = ({ route }) => {
    const T = useTranslations()
    const [address] = useAddressAtom()
    const [token] = useUserTokenAtom()
    const { segmentSession } = useSegmentSession()
    const [config] = useDynamicServiceAtom(route.params.supplyId)
    const supplyId = config._id
    const [paymentView, setPaymentView] = useState(false)
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.PestControl)
    const [totalPrice, setTotalPrice] = useState(0)
    const [originalPrice, setOriginalPrice] = useState(0)
    const [, setResetJobQuotationTypeAtom] = useResetBookingJobQuotationAtom()
    const sessionData = useSessionData()
    const { form, submit, resetForm } = useForm<PestControlAddonsFormShape>(usePestControlAddonsForm(), {
        onSuccess: ({ pets, comment }) => {
            setResetJobQuotationTypeAtom()
            const createJobPayload: CreatePestControlJobRequest = {
                token,
                ...sessionData,
                bookingSessionId: segmentSession.id,
                booking: {
                    ...jobDetails,
                    date: route.params.form.bookingTime,
                    petOnPresmises: Boolean(pets),
                    messageToSupplier: comment
                }
            }

            if (promotionPackage?.promotion) {
                createJobPayload.promo = {
                    code: promotionPackage?.promotion.promo_code,
                    auth: promotionPackage?.promotion.promo_auth
                }
            }

            onCreateJob(
                route.params.supplyId,
                createJobPayload,
                () => {
                    resetForm()
                    removePromoCode()
                }
            )
        }
    })
    const {
        jobUniqueId,
        onCreateJob,
        isLoading: isCreatingJob,
        addressCreated,
        paymentMethod,
        isDisabled,
        handleSubmit,
        isLoadingAddressForm,
        setPaymentMethod,
        fetchPostalCodes,
        addressForm,
        isNewCardModalOpen,
        setIsNewCardModalOpen,
        isMobileOtpModalOpen,
        setIsMobileOtpModalOpen
    } = usePestControlCreateJob(submit, ScreenNames.PestControlDetails, route.params.postalcode)
    const { isLoading: isLoadingPromotion, promotionPackage, requestPromotion, removePromoCode } = useBookingPromoCode()
    const packages = route.params.form.packages
        ? route.params.form.packages.map(packageItem => ({
            cost: packageItem.cost,
            package_type: packageItem.package_type,
            package_name: packageItem.package_name,
            per_annum: packageItem.per_annum
        }) as PackageIdentifier)
        : []

    const getSelectedServices = () => {
        if (!route.params.form.services) {
            return []
        }

        return route.params.form.services
            .filter(service => service?.isSelected)
            .map(service => ({
                id: Number(service.id),
                quantity: Number(service.number_of_treatments)
            }))
    }

    const getSelectedPackages = () => {
        if (!route.params.form.packages) {
            return []
        }

        return route.params.form.packages.flatMap(p => {
            if (p.items) {
                return p.items?.filter(i => i.isSelected).map(i => ({
                    packageId: Number(p.id),
                    optionId: Number(i.id)
                }))
            }

            return []
        })
    }

    const jobDetails = {
        location: addressCreated ?? address,
        propertyType: Number(route.params.form.propertyType),
        bySession: getSelectedServices(),
        byPackage: getSelectedPackages(),
        paymentType: paymentMethod,
        petOnPresmises: Boolean(form.pets.value)
    }
    const isLoading = isLoadingPromotion || isCreatingJob
    const onRequestPromotion = (code: string) => {
        requestPromotion(code, {
            ...jobDetails,
            jobUniqueId,
            total_cost_of_services: totalPrice,
            total_cost: totalPrice,
            photos: [],
            linkUploads: {},
            ironing: false,
            isAllImageUploaded: true,
            targetPromoCode: promotionPackage?.promotion.promo_code || null,
            checkingPayment: false,
            is_purchasing_services: false,
            supply: supplyId,
            is_recurring: false,
            paymentOptions: [PaymentOption.Card, PaymentOption.SendPay],
            service_type_pestcontrol: [...route.params.form.services, ...packages],
            date_time: route.params.form.bookingTime,
            petOnPresmises: Boolean(form.pets.value),
            message_to_supplier: form.comment.value
        })
    }

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingSummary({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                amount: totalPrice as number,
                serviceType: ServiceType.PestControl
            })
        }
    }, [segmentSession])

    useEffect(() => {
        if(jobQuotation) {
            setTotalPrice(jobQuotation?.total?.value)
            setOriginalPrice(jobQuotation?.total?.valueBeforeDiscount ?? 0)
        }
    }, [jobQuotation])

    useEffect(() => {
        const selectedServices = getSelectedServices()
        const selectedPackages = getSelectedPackages()

        if((selectedServices.length === 0 && selectedPackages.length === 0) || !route.params.form.propertyType) {
            return
        }

        const jobQuotationPayload: PestControlJobQuotationRequest = {
            propertyType: route.params.form.propertyType,
            bySession: selectedServices,
            byPackage: selectedPackages
        }

        if (promotionPackage?.promotion) {
            jobQuotationPayload.promo = {
                code: promotionPackage?.promotion.promo_code,
                auth: promotionPackage?.promotion.promo_auth
            }
        }

        requestJobQuotation(jobQuotationPayload)
    }, [promotionPackage?.promotion.promo_code])

    const renderSummaryScreen = () => {
        if (paymentView) {
            return (
                <BookingPaymentView
                    disabled={isDisabled}
                    submit={handleSubmit}
                    isLoading={isLoading || isLoadingAddressForm}
                    totalPrice={totalPrice}
                    onRemovePromoCode={removePromoCode}
                    promotionPackage={promotionPackage}
                    setPaymentView={setPaymentView}
                    service={T.screens.pestControl.title}
                    setPaymentMethod={setPaymentMethod}
                    selectedPaymentMethod={paymentMethod}
                    discount={promotionPackage?.promotion.percent || promotionPackage?.promotion.package.credit}
                    promotionType={promotionPackage?.promotion.promo_type}
                    requestPromotion={onRequestPromotion}
                    promoCode={form.promoCode}
                />
            )
        }

        return (
            <React.Fragment>
                <MobileOtpModal
                    isOpen={isMobileOtpModalOpen}
                    onClose={() => setIsMobileOtpModalOpen(false)}
                    onOtpVerify={() => setIsMobileOtpModalOpen(false)}
                />
                <PaymentNewCardModal
                    isOpen={isNewCardModalOpen}
                    onClose={() => setIsNewCardModalOpen(false)}
                />
                <PestControlComponents.PestControlSummaryView
                    submit={handleSubmit}
                    disabled={isDisabled}
                    setPaymentMethod={setPaymentMethod}
                    onRemovePromoCode={removePromoCode}
                    fetchPostalCodes={fetchPostalCodes}
                    isLoading={isLoading || isLoadingAddressForm}
                    totalPrice={totalPrice}
                    originalPrice={originalPrice}
                    setPaymentView={setPaymentView}
                    promotionPackage={promotionPackage}
                    requestPromotion={onRequestPromotion}
                    selectedPaymentMethod={paymentMethod}
                    formShape={route.params.form}
                    addressForm={addressForm}
                    form={form as Record<keyof PestControlAddonsFormShape, Field<string & boolean>>}
                    jobQuotation={jobQuotation}
                />
            </React.Fragment>
        )
    }

    return renderSummaryScreen()
}
