import { useState } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { authActions } from 'features/auth'
import { BraintreeError, BraintreeErrorFields, NotificationType, VoidFunction } from 'lib/types'
import { BraintreeErrorMessage, createNewCard } from 'lib/braintree'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, Segment } from 'lib/analytics'
import { useGetErrorMessage, useTranslations } from 'lib/hooks'
import { useToastAtom, useUserAtom, useUserTokenAtom, useWAPaymentAtom } from 'lib/atoms'
import { useNativeUserAtom } from 'lib/native/atoms'
import { linkingHelpers } from 'lib/utils'
import { createBraintreeToken, createPaymentMethod } from '../actions'
import { CreditCardShape, useNewCreditCardForm } from '../forms'

export const useAddNewCreditCard = (onSuccess: VoidFunction) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const [waAddPayment, setWaAddPayment] = useWAPaymentAtom()
    const [, setUser] = useUserAtom()
    const [, setNativeUser] = useNativeUserAtom()
    const [, setToastMessage] = useToastAtom()
    const { getErrorMessage } = useGetErrorMessage()
    const { mutate: getMe } = authActions.useGetMe()
    const [isLoading, setIsLoading] = useState(false)
    const { mutateAsync: getBraintreeToken } = createBraintreeToken()
    const { mutate: onCreatePaymentMethod } = createPaymentMethod()
    // TODO: isWeb flag to be removed after native hooks implemented
    const addPaymentMethod = (nonce: string, type: string, isWeb: boolean = false) => {
        onCreatePaymentMethod(
            {
                token,
                type,
                nonce,
                operationId: waAddPayment?.paymentId
            },
            {
                onSuccess: ({ data: addPaymentResponse }) => {
                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.CreditCardAdded
                    })
                    Segment.creditCardAdded({
                        cardType: type
                    })

                    if(waAddPayment && isWeb) {
                        setWaAddPayment(undefined)
                        linkingHelpers.openUrl(addPaymentResponse?.redirectDeepLink, false)
                    }

                    getMe(
                        {
                            token
                        },
                        {
                            onSuccess: ({ data }) => {
                                onSuccess()
                                resetForm()
                                setIsLoading(false)
                                setUser(data.profile)
                                setNativeUser(data.profile)
                                setToastMessage({
                                    message: T.screens.paymentAndCards.successMessage,
                                    type: NotificationType.Success
                                })
                            },
                            onError: error => {
                                setIsLoading(false)
                                setToastMessage({
                                    message: getErrorMessage(error),
                                    type: NotificationType.Error
                                })
                            }
                        }
                    )
                },
                onError: error => {
                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.CreditCardNotAdded
                    })

                    setIsLoading(false)
                    setToastMessage({
                        message: getErrorMessage(error),
                        type: NotificationType.Error
                    })
                }
            }
        )
    }
    const { submit, form, hasError, isFilled, resetForm } = useForm<CreditCardShape>(useNewCreditCardForm(), {
        onSuccess: async creditCardData => {
            try {
                setIsLoading(true)

                const { data } = await getBraintreeToken({ token })
                const { nonce, type } = await createNewCard(data.token, creditCardData)

                addPaymentMethod(nonce, type, true)
            } catch (error) {
                const braintreeError = error as BraintreeError

                if (braintreeError?.details?.originalError?.fieldErrors?.some(field => field.fieldErrors.some(error => error.field === BraintreeErrorFields.Number))) {
                    Segment.creditCardInvalidNumberEntered({})

                    setIsLoading(false)
                    setToastMessage({
                        message: T.common.errorMessage,
                        type: NotificationType.Error
                    })
                }
            }
        }
    })
    const mobileSubmit = async () => {
        try {
            setIsLoading(true)

            const { data } = await getBraintreeToken({ token })
            const { nonce, type } = await createNewCard(data.token)

            addPaymentMethod(nonce, type)
        } catch (error) {
            setIsLoading(false)

            const isUserCancelation = error instanceof Error
                ? error.message === BraintreeErrorMessage.UserCancelled
                : false

            if (!isUserCancelation) {
                setToastMessage({
                    message: T.common.errorMessage,
                    type: NotificationType.Error
                })
            }
        }
    }

    return {
        form,
        submit,
        mobileSubmit,
        hasError,
        isLoading,
        isFilled
    }
}
