import { createStyles } from 'lib/styles'

export const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    main: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    },
    jobSummary: {
        paddingTop: theme.utils.hive.spacing('x10'),
        paddingBottom: theme.utils.hive.spacing('x6'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        rowGap: theme.utils.hive.spacing('x6')
    },
    jobCard: {
        borderRadius: theme.utils.hive.borderRadius('medium'),
        backgroundColor: theme.colors.white
    },
    jobCardHeader: {
        flexDirection: 'row',
        columnGap: theme.utils.hive.spacing('x3'),
        padding: theme.utils.hive.spacing('x4')
    },
    jobCardHeaderIcon: {
        width: 55,
        height: 55
    },
    jobCardHeaderWithoutIcon: {
        width: 55,
        height: 55,
        borderRadius: theme.utils.hive.borderRadius('medium'),
        backgroundColor: theme.colors.grey
    },
    jobCardHeaderInfo: {
        flex: 1,
        rowGap: theme.utils.hive.spacing('x2')
    },
    jobCardHeaderInfoTitle: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.utils.hive.spacing('x1')
    },
    jobCardHeaderInfoAddress: {
        flexDirection: 'row',
        columnGap: theme.utils.hive.spacing('x1')
    },
    jobCardHeaderInfoAddressText: {
        flex: 1
    },
    jobSection: {
        padding: theme.utils.hive.spacing('x4'),
        rowGap: theme.utils.hive.spacing('x2')
    },
    jobSectionHeader: {
        flexDirection: 'row',
        columnGap: theme.utils.hive.spacing('x2')
    },
    jobSectionHeaderTitle: {
        flex: 1
    },
    jobSectionBody: {
        rowGap: theme.utils.hive.spacing('x2'),
        marginTop: theme.utils.hive.spacing('x1')
    },
    jobSectionButtonText: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.utils.hive.spacing('x1')
    },
    jobSectionRow: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    sections: {
        rowGap: theme.utils.hive.spacing('x3'),
        paddingBottom: theme.utils.hive.spacing('x14')
    },
    section: {
        backgroundColor: theme.colors.white,
        paddingVertical: theme.utils.hive.spacing('x12'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        rowGap: theme.utils.hive.spacing('x3')
    },
    sectionBody: {
        rowGap: theme.utils.hive.spacing('x6')
    },
    sectionList: {
        marginTop: theme.utils.hive.spacing('x2'),
        rowGap: theme.utils.hive.spacing('x8')
    },
    sectionListItem: {
        flexDirection: 'row',
        columnGap: theme.utils.hive.spacing('x2')
    },
    sectionListItemText: {
        flex: 1
    },
    priceBreakdown: {
        rowGap: theme.utils.hive.spacing('x6'),
        paddingTop: 0
    },
    priceTotal: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    loadingContainer: {
        height: 28
    },
    buttonText: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.utils.hive.spacing('x1')
    },
    paymentMethod: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.utils.hive.spacing('x3'),
        paddingVertical: theme.utils.hive.spacing('x3')
    },
    paymentMethodText: {
        flex: 1
    },
    paymentMethodValue: {
        marginTop: theme.utils.hive.spacing('x3')
    },
    sendPayIcon: {
        alignSelf: 'flex-start'
    },
    creditCardImage: {
        width: theme.utils.hive.spacing('x6'),
        height: theme.utils.hive.spacing('x6'),
        objectFit: 'contain'
    },
    footerBtnNext: {
        flex: 1
    }
}))
