export enum OtpState {
    MOBILE = 'mobile',
    OTP = 'otp',
    VALID = 'valid'
}

export enum OtpResendState {
    INITIAL = 'initial',
    SUCCESS = 'success',
    FAILURE = 'failure'
}

export const COLOR_GREY = '#505050'

export const FONT_WEIGHT_NORMAL = '400'
