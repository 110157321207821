import React from 'react'
import { View, Image } from 'react-native'
import {
    CheckRadioCircleCheckboxCheckCheckmarkConfirmFill,
    ChevronRightFill
} from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { Icon, Svg } from '@propertyguru/hive-icon'
import { Touchable } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

export type PaymentMethodTileProps = {
    prefixIconUrl?: string
    prefixIcon?: Svg
    label: string
    description?: string
    actionText?: string
    isSelected?: boolean
    onSelect: () => void
    onAction?: () => void
    errorText?: string
}

export const PaymentMethodTile: React.FunctionComponent<PaymentMethodTileProps> = ({
    prefixIconUrl,
    prefixIcon,
    label,
    description,
    actionText,
    isSelected,
    onSelect,
    onAction,
    errorText
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable style={styles.paymentMethodButton} onPress={onSelect}>
            {prefixIconUrl && (
                <Image source={{ uri: prefixIconUrl }} style={{ height: 24, width: 24 }} resizeMode="contain" />
            )}
            {prefixIcon && !prefixIconUrl && <Icon icon={prefixIcon} />}
            <View style={styles.column}>
                <Text typography="label/s">{label}</Text>
                {(description || actionText || errorText) && (
                    <View>
                        <View style={styles.row}>
                            {description && <Text typography="label/xs">{description}</Text>}
                            {actionText && (
                                <Text typography="label/xs" underline onPress={onAction}>
                                    {actionText}
                                </Text>
                            )}
                        </View>
                        {errorText && <Text typography="label/xs" color="text/error/primary">{errorText}</Text>}
                    </View>
                )}
            </View>
            <Icon
                icon={isSelected ? CheckRadioCircleCheckboxCheckCheckmarkConfirmFill : ChevronRightFill}
                color={isSelected ? 'icon/success/primary' : undefined}
            />
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    paymentMethodButton: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.hive.spacing('x3'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        paddingVertical: theme.utils.hive.spacing('x5'),
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string
    },
    column: {
        flex: 1,
        flexDirection: 'column'
    },
    row: {
        flexDirection: 'row',
        gap: theme.utils.hive.spacing('x3')
    }
}))
