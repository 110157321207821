import { useMutation } from '@tanstack/react-query'
import { useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { APP_CONFIG } from 'lib/config'

import { MarketingConsentResponse, NativeMarketingConsent } from '../types'

export const saveMarketingConsent = () => {
    const fetcher = useFetcher<MarketingConsentResponse>(HttpMethod.POST, `${APP_CONFIG.AUTH.URL}/profile/update`)

    return useMutation<Response<MarketingConsentResponse>, ErrorResponse & { token: string }, NativeMarketingConsent>(fetcher)
}
