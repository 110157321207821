import { TimeSlot } from 'lib/native/types'

export enum BookingFrequency {
    Recurring = 'recurring',
    OneTime = 'onetime'
}

export enum CleaningScheduleType {
    Weekly = 'weekly',
    BiWeekly = 'biweekly',
    Custom = 'custom'
}
export type Price = {
    amount: number;
    currency: string;
    perUnit: string;
}

export type FrequencyOption = {
    frequency: BookingFrequency;
    price: Price
}

export type Frequency = {
    options: Array<FrequencyOption>
}

export type Pricing = {
    frequency: Frequency
}

export type PaymentDisclaimer = {
    icon: string;
    message: string;
}

export type RepeatOption = {
    type: CleaningScheduleType;
    title: string;
    description: string;
    featuredText?: string;
    featured?: boolean;
}

export type Schedule = {
    title: string;
    paymentDisclaimer: PaymentDisclaimer;
    minSessionsInfo: string;
    repeatLabel: string;
    repeatOptions: Array<RepeatOption>;
}

export type ServiceDetails = {
    schedule: Schedule
}

export type CleaningConfig = {
    timeSlots: Array<TimeSlot>;
    pricing: Pricing;
    serviceDetails: ServiceDetails
}

export type CleaningConfigAtom = CleaningConfig & {
    isLoading: boolean;
    oneTimePrice?: number;
    oneTimePriceLabel?: string;
    flatPrice?: number;
    flatPriceLabel?: string;
}
