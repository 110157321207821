import { useState } from 'react'
import { Animated, View } from 'react-native'
import { useAnimatedReaction, runOnJS, runOnUI } from 'react-native-reanimated'
import { isNative } from 'lib/common'
import { Touchable, Typography } from 'lib/components'
import { useAccordion, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { notificationFullDate } from 'lib/utils/date'

export const AirConditionServiceSchedule = ({ scheduledDates }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [, setFullyOpen] = useState(false)

    const { setHeight, aref, isOpened } = useAccordion()

    useAnimatedReaction(
        () => isOpened.value,
        isOpen => runOnJS(setFullyOpen)(isOpen)
    )

    if(!scheduledDates || scheduledDates.length === 0) {
        return null
    }

    const firstYearDates = scheduledDates.slice(0, 4)
    const secondYearDates = scheduledDates.length > 4 ? scheduledDates.slice(4, 8) : []

    return (
        <Animated.View style={[styles.wrapper]}>
            <Touchable
                style={styles.button}
                onPress={() => {
                    runOnUI(setHeight)()
                }}
            >
                <Typography.Label
                    bold
                    style={styles.title}
                >
                    {T.screens.airCondition.showMaintenanceSchedule}
                </Typography.Label>
            </Touchable>
            <Animated.View style={{ minHeight: isNative ? 450 : 300 }}>
                <View
                    ref={aref}
                    collapsable={false}
                    style={styles.accordionWrapper}
                >
                    <View>
                        <View style={styles.scheduledDatesWrapper}>
                            <View style={styles.scheduledDatesColumn}>
                                <Typography.Label style={styles.scheduledDatesColumnHeader} bold>{T.screens.airCondition.firstYearSessions}</Typography.Label>
                                {firstYearDates?.map((date, index) => (
                                    <Typography.Regular key={index} style={styles.scheduledDate}>
                                        {notificationFullDate(date.toISOString())}
                                    </Typography.Regular>
                                ))}
                            </View>
                            {secondYearDates?.length > 0 && (
                                <View style={styles.scheduledDatesColumn}>
                                    <Typography.Label style={styles.scheduledDatesColumnHeader} bold>{T.screens.airCondition.secondYearSessions}</Typography.Label>
                                    {secondYearDates?.map((date, index) => (
                                        <Typography.Regular key={index + 4} style={styles.scheduledDate}>
                                            {notificationFullDate(date.toISOString())}
                                        </Typography.Regular>
                                    ))}
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            </Animated.View>
        </Animated.View>

    )
}

const stylesheet = createStyles(theme => ({
    scheduledDatesWrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        gap: 10
    },
    scheduledDate: {
        paddingVertical: 16,
        paddingLeft: 10
    },
    scheduledDatesColumn: {
        flexGrow: 1
    },
    scheduledDatesColumnHeader: {
        color: theme.colors.withOpacity(theme.colors.grey, 0.4)
    },
    wrapper: {
        overflow: 'hidden',
        marginBottom: theme.utils.gap(2),
        zIndex: -1
    },
    title: {
        marginRight: theme.utils.gap(1),
        fontSize: 12
    },
    button: {
        flexDirection: 'row',
        paddingVertical: theme.utils.gap(2),
        alignItems: 'center'
    },
    accordionWrapper: {
        left: 0,
        right: 0,
        position: isNative
            ? 'absolute'
            : undefined
    }
}))
