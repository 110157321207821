import React from 'react'
import { DimensionValue, View } from 'react-native'
import { Job, RecurringJob } from 'lib/models'
import { useStyles, useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { isNative, Measurements } from 'lib/common'
import { KeyValuePair, Nullable } from 'lib/types'
import { SmallSubheading } from '../typography'
import { BookingsCalendarBlankDays } from './BookingsCalendarBlankDays'
import { BookingsCalendarEvents } from './BookingsCalendarEvents'
import { Touchable } from '../Touchable'

type BookingsCalendarMonthProps = {
    date: string,
    selectedDate: Nullable<string>,
    selectedDay: Nullable<string>,
    data: KeyValuePair<Array<Job>>,
    onDayPress: (date: string) => void,
    recurringJobs: Array<RecurringJob>,
    mobilePadding: number
}

export const BookingsCalendarMonth: React.FunctionComponent<BookingsCalendarMonthProps> = ({
    date,
    data,
    recurringJobs,
    selectedDate,
    selectedDay,
    mobilePadding,
    onDayPress
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const numberOfDaysInMonth = dateHelpers.getMonthDaysNumber(date)
    const numberOfDaysInPreviousMonth = dateHelpers.getPreviousMonthDaysNumber(date)
    const dayOfFirstWeek = dateHelpers.getDayNumberOfFirstWeek(date)
    const blankDays = dayOfFirstWeek === 0
        ? 6
        : dayOfFirstWeek - dateHelpers.WEEK_STARTS_ON
    const lastRowDays = (blankDays + numberOfDaysInMonth) % 7
    const dayContainerWidth = isNative
        ? Math.floor((Measurements.WindowWidth - mobilePadding) / 7)
        : 'calc(100% / 7)' as DimensionValue

    return (
        <View style={styles.container}>
            <BookingsCalendarBlankDays
                mobilePadding={mobilePadding}
                previousMonthNumber={numberOfDaysInPreviousMonth}
                numberOfDays={blankDays}
            />
            {Array.from(new Array(numberOfDaysInMonth)).map((item, index) => {
                const dayNumber = index + 1
                const dayDate = dateHelpers.calendarDayDate(date, dayNumber)
                const selectedStyles = dayDate === selectedDay
                    ? styles.selected
                    : {}
                const isToday = selectedDate
                    ? dateHelpers.getIsSameDay(selectedDate, dayDate)
                    : false

                return (
                    <Touchable
                        key={index}
                        disabled={!data[dayDate]}
                        onPress={() => onDayPress(dayDate)}
                        style={{
                            ...styles.dayContainer,
                            width: dayContainerWidth,
                            borderRightWidth: (index + blankDays + 1) % 7 === 0 && dayDate !== selectedDay
                                ? 0
                                : 1,
                            ...selectedStyles
                        }}
                        testID={T.accessibility.bookingsScreen.views.calendarView.dayOfMonth}
                    >
                        <View
                            style={
                                isToday
                                    ? styles.today
                                    : styles.dayNumber
                            }
                        >
                            <SmallSubheading
                                forceColor={
                                    isToday
                                        ? theme.colors.sun
                                        : theme.colors.darkGrey
                                }
                            >
                                {dayNumber.toString()}
                            </SmallSubheading>
                        </View>
                        {data[dayDate] && (
                            <BookingsCalendarEvents
                                jobs={data[dayDate]}
                                recurringJobs={recurringJobs}
                            />
                        )}
                    </Touchable>
                )
            })}
            {lastRowDays !== 0 && (
                <BookingsCalendarBlankDays
                    mobilePadding={mobilePadding}
                    numberOfDays={7 - lastRowDays}
                />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    dayContainer: {
        height: {
            ':w[860, ]': 150,
            ':w[, 860]': 50
        },
        alignItems: 'center',
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        }
    },
    dayNumber: {
        paddingVertical: 2
    },
    today: {
        width: {
            lg: theme.utils.gap(5),
            xs: theme.utils.gap(3)
        },
        borderRadius: theme.utils.gap(2),
        paddingVertical: 2,
        alignItems: 'center',
        borderWidth: 1,
        borderColor: theme.colors.sun
    },
    selected: {
        borderWidth: 1,
        borderColor: theme.colors.orange,
        backgroundColor: theme.colors.yellow,
        ...theme.utils.createShadow(2, 0, 10, 4, 4, theme.colors.webShadow(0.2))
    }
}))
