import { IconColor } from '@propertyguru/hive'
import { Icon } from '@propertyguru/hive-icon'
import { StarFavoriteAwardFill } from '@propertyguru/hive-icons'
import { Surface } from '@propertyguru/hive-surface'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import React from 'react'
import { View } from 'react-native'

export const ratings = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 }
]

export const getStarColor= (value: number, rating: number, selectedStarColor) => value <= rating ? selectedStarColor as IconColor : 'fill/inactive/primary' as IconColor

type RatingStarsProps = {
    rating: number,
    onRate: (value: number) => void,
    selectedStarColor?: IconColor
}

export const RatingStars: React.FunctionComponent<RatingStarsProps> = ({ rating, onRate, selectedStarColor = 'fill/active/primary' }) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.ratingStars}>
            { ratings.map(({ id, value }) =>(
                <Surface key={id} onPress={() => onRate(value)} >
                    <Icon icon={StarFavoriteAwardFill} width={32} height={32} color={getStarColor(value, rating, selectedStarColor)}   />
                </Surface>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    ratingStars: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: theme.utils.gap(1),
        marginTop: theme.utils.gap(2)
    }
}))
