import { useEffect, useState } from 'react'
import { PaymentType } from 'lib/models'
import { useNativeUserAtom } from 'lib/native/atoms'

export const usePaymentMethod = () => {
    const [paymentType, setPaymentType] = useState<PaymentType | undefined>(undefined)
    const [paymentMethod, setPaymentMethod] = useState<string | undefined>(undefined)
    const [paymentMethodId, setPaymentMethodId] = useState<string | undefined>(undefined)
    const [user] = useNativeUserAtom()

    useEffect(() => {
        const defaultPaymentProvider = user?.payment_providers?.find(provider => provider.default)

        if (!defaultPaymentProvider) {
            setPaymentType(undefined)
            setPaymentMethod(undefined)
            setPaymentMethodId(undefined)

            return
        }

        const { card_type, last_4 } = defaultPaymentProvider
        const formattedPaymentMethod = card_type && last_4 ? `${card_type} ${last_4}` : undefined

        setPaymentType(formattedPaymentMethod ? PaymentType.Card : undefined)
        setPaymentMethod(formattedPaymentMethod)
        setPaymentMethodId(defaultPaymentProvider?._id)
    }, [user])

    return {
        defaultPaymentType: paymentType,
        defaultPaymentMethod: paymentMethod,
        defaultPaymentMethodId: paymentMethodId
    }
}
