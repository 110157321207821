// @ts-nocheck
// noinspection ALL

// This file is generated by svgr

import React, { memo } from 'react'
import Svg, { Path, type SvgProps } from 'react-native-svg'
const SvgCalendar = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
        {...props}
    >
        <Path
            fill="#3E4246"
            fillRule="evenodd"
            d="M5.167 1.833a.5.5 0 0 1 .5.5v.834h4.666v-.834a.5.5 0 0 1 1 0v.834h.834C13.179 3.167 14 3.987 14 5v7.667c0 1.012-.82 1.833-1.833 1.833H3.833A1.833 1.833 0 0 1 2 12.667V5c0-1.012.82-1.833 1.833-1.833h.834v-.834a.5.5 0 0 1 .5-.5ZM3.833 4.167A.833.833 0 0 0 3 5v1.5h10V5a.833.833 0 0 0-.833-.833H3.833ZM13 7.5H3v5.167c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V7.5Z"
            clipRule="evenodd"
        />
    </Svg>
)

export default memo(SvgCalendar)

