import { useNativeResetIsAuthorizedAtom } from './auth'
import { useNativeResetCleaningConfigAtom } from './cleaningConfigs'
import { useNativeResetDynamicPriceSlotsAtom } from './dynamicPriceSlots'
import { useNativeResetJobBookingDetailAtom } from './jobBookingDetail'
import { useNativeResetJobQuotationsAtom } from './jobQuotations'
import { useNativeResetUserTokenAtom } from './token'
import { useNativeResetUserAtom } from './user'

export const useNativeResetAtoms = () => {
    const [, resetIsAuthorizedAtom] = useNativeResetIsAuthorizedAtom()
    const [, resetTokenAtom] = useNativeResetUserTokenAtom()
    const [, resetUserAtom] = useNativeResetUserAtom()
    const [, resetDynamicPriceSlotsAtom] = useNativeResetDynamicPriceSlotsAtom()
    const [, resetCleaningConfigAtom] = useNativeResetCleaningConfigAtom()
    const [, resetJobQuotationsAtom] = useNativeResetJobQuotationsAtom()
    const [, resetJobBookingDetailAtom] = useNativeResetJobBookingDetailAtom()

    return {
        resetAtoms: () => [
            resetIsAuthorizedAtom,
            resetTokenAtom,
            resetUserAtom,
            resetDynamicPriceSlotsAtom,
            resetJobQuotationsAtom,
            resetJobBookingDetailAtom,
            resetCleaningConfigAtom
        ].forEach(reset => reset())
    }
}
