import React, { useEffect, useState } from 'react'
import { Field, useForm } from '@codegateinc/react-form-builder-v2'
import { useSegmentSession, useTranslations, useSessionData } from 'lib/hooks'
import { PaymentNewCardModal } from 'features/payments'
import { PaymentOption } from 'lib/models'
import { useAddressAtom, useDynamicServiceAtom, useResetBookingJobQuotationAtom, useUserTokenAtom } from 'lib/atoms'
import { Segment, ServiceType } from 'lib/analytics'
import { NavigationProps, NavigationParams, ScreenNames } from 'lib/routing'
import { useBookingPromoCode, useDeepCleaningCreateJob, useJobQuotation } from '../hooks'
import { BookingPaymentView, DeepCleaningSummaryView } from '../components'
import { ServiceAddonsFormShape, useServiceAddonsForm } from '../forms'
import { CreateDeepCleaningJobRequest, PropertyCleaningValue } from '../types'
import { SupplyID } from 'lib/types'
import { MobileOtpModal } from 'features/native/bookings/components'

type DeepCleaningSummaryScreenProps = {
    navigation: NavigationProps<ScreenNames.DeepCleaningSummary>,
    route: NavigationParams<ScreenNames.DeepCleaningSummary>
}

export const DeepCleaningSummaryScreen: React.FunctionComponent<DeepCleaningSummaryScreenProps> = ({ route }) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const [address] = useAddressAtom()
    const { segmentSession } = useSegmentSession()
    const [paymentView, setPaymentView] = useState(false)
    const [config] = useDynamicServiceAtom(route.params.supplyId)
    const [, setResetJobQuotationTypeAtom] = useResetBookingJobQuotationAtom()
    const supplyId = config._id

    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.DeepCleaning)
    const [totalPrice, setTotalPrice] = useState(0)
    const [originalPrice, setOriginalPrice] = useState(0)
    const sessionData = useSessionData()

    useEffect(() => {
        if (jobQuotation) {
            setTotalPrice(jobQuotation?.total?.value)
            setOriginalPrice(jobQuotation?.total?.valueBeforeDiscount ?? 0)
        }
    }, [jobQuotation])

    const selectedServices = route.params.form.services
        .filter(service => +service.quantity > 0)
        .map(service => ({
            id: service.id,
            quantity: service.quantity
        }))

    const isNotPropertyCleaning = route.params.form.propertyCleaning === PropertyCleaningValue.False
    const propertySize = !isNotPropertyCleaning ? +route.params.form.propertySize : undefined
    const { form, submit, resetForm } = useForm<ServiceAddonsFormShape>(useServiceAddonsForm(), {
        onSuccess: ({ comment, pets }) => {
            setResetJobQuotationTypeAtom()

            const createJobPayload: CreateDeepCleaningJobRequest = {
                token,
                ...sessionData,
                bookingSessionId: segmentSession.id,
                booking: {
                    messageToSupplier: comment,
                    date: route.params.form.bookingTime,
                    location: addressCreated ?? address,
                    propertySize,
                    additionalServices: selectedServices,
                    paymentType: paymentMethod,
                    buildingType: route.params.form.propertyType,
                    petOnPresmises: Boolean(pets),
                    isNotPropertyCleaning
                }
            }

            if (promotionPackage?.promotion.promo_auth) {
                createJobPayload.promo = {
                    auth: promotionPackage?.promotion.promo_auth,
                    code: promotionPackage?.promotion.promo_code
                }
            }

            onCreateJob(
                route.params.supplyId,
                createJobPayload,
                () => {
                    resetForm()
                    removePromoCode()
                }
            )
        }
    })
    const { isLoading: isLoadingPromotion, promotionPackage, requestPromotion, removePromoCode } = useBookingPromoCode()
    const {
        jobUniqueId,
        onCreateJob,
        isLoading: isCreatingJob,
        addressCreated,
        paymentMethod,
        isDisabled,
        handleSubmit,
        isLoadingAddressForm,
        setPaymentMethod,
        fetchPostalCodes,
        addressForm,
        isNewCardModalOpen,
        setIsNewCardModalOpen,
        isMobileOtpModalOpen,
        setIsMobileOtpModalOpen
    } = useDeepCleaningCreateJob(
        submit,
        ScreenNames.DeepCleaningDetails,
        route.params.postalcode
    )

    useEffect(() => {
        const promoPayload = {
            code: promotionPackage?.promotion?.promo_code,
            auth: promotionPackage?.promotion?.promo_auth
        }

        const data = {
            propertySize,
            additionalServices: selectedServices,
            isNotPropertyCleaning,
            ...(promoPayload.code && { promo: promoPayload })
        }

        requestJobQuotation(data)

    }, [promotionPackage?.promotion.promo_code])

    const isLoading = isLoadingPromotion || isCreatingJob
    const onRequestPromotion = (code: string) => {

        const deepCleaningServices = route.params.form.services
            .filter(service => Number(service.quantity) !== 0)
            .map(service => ({
                id: service.id,
                quantity: service.quantity
            })
            )

        requestPromotion(code, {
            jobUniqueId,
            photos: [],
            petOnPresmises: Boolean(form.pets.value),
            ironing: Boolean(form.ironing.value),
            isAllImageUploaded: true,
            message_to_supplier: form.comment.value,
            linkUploads: {},
            targetPromoCode: promotionPackage?.promotion.promo_code || null,
            checkingPayment: false,
            date_time: route.params.form.bookingTime,
            location: addressCreated ?? address,
            is_purchasing_services: false,
            supply: supplyId,
            building_type: route.params.form.propertyType,
            is_recurring: false,
            paymentType: paymentMethod,
            paymentOptions: [PaymentOption.Card, PaymentOption.SendPay],
            service_type_deepcleaning: deepCleaningServices,
            deepcleaning_area: +route.params.form.propertySize
        })
    }

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingSummary({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                amount: totalPrice as number,
                serviceType: ServiceType.DeepCleaning
            })
        }
    }, [segmentSession])

    if (paymentView) {
        return (
            <BookingPaymentView
                disabled={isDisabled}
                submit={handleSubmit}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                onRemovePromoCode={removePromoCode}
                promotionPackage={promotionPackage}
                setPaymentView={setPaymentView}
                service={T.screens.cleaning.title}
                setPaymentMethod={setPaymentMethod}
                selectedPaymentMethod={paymentMethod}
                discount={promotionPackage?.promotion.percent || promotionPackage?.promotion.package.credit}
                promotionType={promotionPackage?.promotion.promo_type}
                requestPromotion={onRequestPromotion}
                promoCode={form.promoCode}
            />
        )
    }

    return (
        <React.Fragment>
            <MobileOtpModal
                isOpen={isMobileOtpModalOpen}
                onClose={() => setIsMobileOtpModalOpen(false)}
                onOtpVerify={() => setIsMobileOtpModalOpen(false)}
            />
            <PaymentNewCardModal
                isOpen={isNewCardModalOpen}
                onClose={() => setIsNewCardModalOpen(false)}
            />
            <DeepCleaningSummaryView
                submit={handleSubmit}
                disabled={isDisabled}
                fetchPostalCodes={fetchPostalCodes}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                setPaymentMethod={setPaymentMethod}
                originalPrice={originalPrice}
                onRemovePromoCode={removePromoCode}
                setPaymentView={setPaymentView}
                promotionPackage={promotionPackage}
                requestPromotion={onRequestPromotion}
                selectedPaymentMethod={paymentMethod}
                formShape={route.params.form}
                addressForm={addressForm}
                form={form as Record<keyof ServiceAddonsFormShape, Field<string & boolean>>}
                jobQuotation={jobQuotation}
            />
        </React.Fragment>
    )
}
