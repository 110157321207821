import React from 'react'
import { View } from 'react-native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { Button, MediaQuery, PolicyLinks } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'
import { PaymentType } from 'lib/models'
import { Breakpoint } from 'lib/styles'
import { Nullable, SupplyID, VoidFunction } from 'lib/types'
import { MoversAndPackersFormShape, MoversAndPackersAddonsShape, MoversAndPackersServiceDetailsShape } from '../../forms'
import { GetPromotionResponse } from '../../types'
import { BookingCancellationFees } from '../BookingCancellationFees'
import { PaymentMethodTile } from '../PaymentMethodTile'
import { PromotionCodeInput } from '../PromotionCodeInput'
import { MoversAndPackersSummaryColumn } from './MoversAndPackersSummaryColumn'
import { DoubleAddress } from './DoubleAddress'
import { MoversAndPackersPriceNote } from './MoversAndPackersPriceNote'
import { VisitCharges } from '../VisitCharges'
import { getServiceStaticConfig } from 'features/bookings/utils'

type MoversAndPackersSummaryColumnViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    formReturn: UseFormReturn<MoversAndPackersAddonsShape>
    formShape: MoversAndPackersFormShape & MoversAndPackersServiceDetailsShape,
    isLoading: boolean,
    totalPrice: number,
    submit: VoidFunction,
    authenticationView: boolean,
    promotionPackage: Nullable<GetPromotionResponse>,
    originalPrice?: Nullable<number>,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction
}

export const MoversAndPackersSummaryColumnView: React.FunctionComponent<MoversAndPackersSummaryColumnViewProps> = ({
    formReturn,
    submit,
    selectedPaymentMethod,
    onRemovePromoCode,
    setPaymentView,
    promotionPackage,
    requestPromotion,
    authenticationView,
    isLoading,
    totalPrice,
    originalPrice,
    disabled,
    formShape
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const { form } = formReturn
    const config = getServiceStaticConfig(SupplyID.MoversPackers)
    const { pricing : { cancellationCharges: { title, subtitle, details: visitChargesDetails } } } = config
    const selectedDays = Object.keys(formShape.inspectionBookingDays)
        .filter(key => Boolean(formShape.inspectionBookingDays[key]))
        .sort((firstDate, secondDate) => firstDate.localeCompare(secondDate))
    const inspectionDateLabel = selectedDays.length > 0
        ? selectedDays.map(dateHelpers.inspectionDate).join(', ')
        : ''

    return (
        <MediaQuery.Visible from={Breakpoint.LG}>
            {!(isAuthorized && authenticationView) && (
                <View>
                    <DoubleAddress
                        disabled={isLoading}
                        pickUpAddress={formShape.pickUpLocation}
                        dropOffAddress={formShape.dropOffLocation}
                    />
                </View>
            )}
            <MoversAndPackersSummaryColumn
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                promotionPackage={promotionPackage}
                inspectionDays={inspectionDateLabel}
                title={T.common.bookingSummary}
                renderPriceComponent={() => (
                    <MoversAndPackersPriceNote
                        price={totalPrice}
                        originalPrice={originalPrice}
                    />
                )}
                form={formShape}
                summaryForm={formReturn}
                couponText={promotionPackage
                    ? promotionPackage.promotion.message_to_client
                    : undefined
                }
                renderExtraContent={() => (
                    <View>
                        <VisitCharges
                            labelText={title}
                            chargesMessage={subtitle}
                            modalTitle={visitChargesDetails.title}
                            messageText={visitChargesDetails.description}
                        />
                        <PromotionCodeInput
                            promoCode={form.promoCode}
                            isLoading={isLoading}
                            promotionPackage={promotionPackage}
                            onRemovePromoCode={onRemovePromoCode}
                            requestPromotion={requestPromotion}
                        />
                    </View>
                )}
            />
            {!authenticationView && (
                <PaymentMethodTile
                    notEnoughCredits={notEnoughCredits}
                    onPress={() => setPaymentView(true)}
                    selectedPaymentMethod={selectedPaymentMethod}
                />
            )}
            <PolicyLinks />
            {(!authenticationView || isAuthorized) && (
                <Button
                    disabled={notEnoughCredits || disabled}
                    isLoading={isLoading}
                    onPress={submit}
                    text={T.common.confirm}
                />
            )}
            <BookingCancellationFees />
        </MediaQuery.Visible>
    )
}
