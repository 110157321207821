import {
    getAvailableServices
} from '../actions'
import { useState } from 'react'
import { AvailableServicesResponse } from 'features/bookings/types'

export const useAvailableServices = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [availableServices, setAvailableServices] = useState<AvailableServicesResponse>()
    const { mutate: fetchAvailableServices } = getAvailableServices()

    const requestAvailableServices = () => {
        setIsLoading(true)

        fetchAvailableServices({}, {
            onSuccess: response => {
                setAvailableServices(response)
            },
            onError: error => {
                console.log(error)
            },
            onSettled: () => {
                setIsLoading(false)
            }
        })
    }

    return {
        availableServices,
        requestAvailableServices,
        isLoading
    }
}
