import React, { useCallback, useEffect, useState } from 'react'
import { View, Image } from 'react-native'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable } from 'lib/components'
import { Icon } from '@propertyguru/hive-icon'
import { ChevronDownSmallFill, ChevronTopSmallFill } from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { useAvailableServices } from 'features/bookings/hooks/useAvailableServices'
import { ServiceCategoriesType } from 'features/native/types'
import { SERVICE_CATEGORIES_MAX } from 'features/native/constants'
import { useHandleCardAction } from 'lib/native/hooks'
import { useNativeAvailableServicesAtom } from 'lib/native/atoms'

export const ServiceCategories: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const labelTestIdMap = {
        'Home Cleaning': T.accessibility.dashboard.serviceCategories.cleaning,
        'Deep Cleaning': T.accessibility.dashboard.serviceCategories.deepCleaning,
        'Aircon Servicing': T.accessibility.dashboard.serviceCategories.aircon,
        'Electrical & Plumbing': T.accessibility.dashboard.serviceCategories.electrician,
        'Repair & Assembly': T.accessibility.dashboard.serviceCategories.repair,
        Cooking: T.accessibility.dashboard.serviceCategories.cooking,
        'Pest Control': T.accessibility.dashboard.serviceCategories.pestControl,
        Laundry: T.accessibility.dashboard.serviceCategories.laundry,
        'Task & Errands': T.accessibility.dashboard.serviceCategories.tasks,
        'Movers & Packers': T.accessibility.dashboard.serviceCategories.movers,
        'Renovation Works': T.accessibility.dashboard.serviceCategories.renovation,
        'Coming Soon': T.accessibility.dashboard.serviceCategories.comingSoon
    }

    const { availableServices, requestAvailableServices } = useAvailableServices()
    const [, setAvailableServicesAtom] = useNativeAvailableServicesAtom()

    const [isExpanded, setIsExpanded] = useState(false)
    const [serviceCategories, setServiceCategories] = useState<ServiceCategoriesType | undefined>(undefined)
    const handleCardAction = useHandleCardAction()

    const handleExpand = useCallback(() => {
        setIsExpanded(!isExpanded)
    }, [isExpanded])

    useEffect(() => {
        requestAvailableServices()
    }, [])

    useEffect(() => {
        if (availableServices?.categories) {
            setServiceCategories(
                availableServices.categories.map(category => ({
                    image: { uri: category.image },
                    label: category.label,
                    action: category.action
                }))
            )
        }

        availableServices && setAvailableServicesAtom(availableServices)
    }, [availableServices])

    const renderServiceItem = (serviceCategory, index) => (
        <Touchable
            key={index}
            onPress={() => handleCardAction(serviceCategory.action)}
            style={styles.serviceContainer}
            disabled={!serviceCategory.action}
            testID={labelTestIdMap[serviceCategory.label] ?? T.accessibility.dashboard.serviceCategories.other}
        >
            <View
                style={{
                    ...styles.imageWrapper,
                    backgroundColor: serviceCategory.action ? theme.colors.pearlBush : theme.colors.mercury
                }}
            >
                <Image source={serviceCategory.image} style={styles.image} />
            </View>
            <Text
                typography="caption/xs"
                style={styles.label}
                color={serviceCategory.action ? 'text/active/primary' : 'text/inactive/primary'}
            >
                {serviceCategory.label}
            </Text>
        </Touchable>
    )

    return (
        serviceCategories && (
            <View style={styles.container}>
                {serviceCategories.length > 0 &&
                    serviceCategories.slice(0, SERVICE_CATEGORIES_MAX).map(renderServiceItem)}
                {isExpanded &&
                    serviceCategories.length > SERVICE_CATEGORIES_MAX &&
                    serviceCategories.slice(SERVICE_CATEGORIES_MAX).map(renderServiceItem)}
                {serviceCategories.length > SERVICE_CATEGORIES_MAX && (
                    <View style={styles.expandButtonWrapper}>
                        <Touchable
                            onPress={handleExpand}
                            style={styles.expandButton}
                            testID={T.accessibility.dashboard.serviceCategories.more}
                        >
                            <Text typography="caption/s">
                                {isExpanded
                                    ? T.screens.dashboard.serviceCategoriesSection.expandedText
                                    : T.screens.dashboard.serviceCategoriesSection.expansionText.replace(
                                        T.common.more.toLowerCase(),
                                        `${serviceCategories.filter(sc => sc.action).length -
                                        SERVICE_CATEGORIES_MAX
                                        } ${T.common.more.toLowerCase()}`
                                    )}
                            </Text>
                            <Icon icon={isExpanded ? ChevronTopSmallFill : ChevronDownSmallFill} />
                        </Touchable>
                    </View>
                )}
            </View>
        )
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: theme.utils.hive.spacing('x4'),
        gap: theme.utils.hive.spacing('x4')
    },
    serviceContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '20%',
        gap: theme.utils.hive.spacing('x2')
    },
    imageWrapper: {
        height: 70,
        width: 70,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.utils.hive.borderRadius('full')
    },
    image: {
        height: 70,
        width: 70
    },
    label: {
        textAlign: 'center'
    },
    expandButtonWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    expandButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.hive.spacing('x1')
    }
}))
