import React from 'react'
import { View } from 'react-native'
import { APP_CONFIG } from 'lib/config'
import { createStyles } from 'lib/styles'
import { dateHelpers, R } from 'lib/utils'
import { BookingFrequency, FrequencyOption, Nullable } from 'lib/types'
import { useStyles, useTranslations } from 'lib/hooks'
import { useLocaleAtom } from 'lib/atoms'
import { Touchable } from '../Touchable'
import { Regular } from '../typography'

type CalendarMonthProps = {
    date: string,
    disabled?: boolean,
    liteMode?: boolean,
    fullScreen?: boolean,
    frequency: FrequencyOption,
    selectedDate: Nullable<string>,
    minHoursNotice: number,
    onDayPress: (date: string) => void,
    mobilePadding: number,
    selectedDays: Record<string, Nullable<string>>,
    selectedDaysChangeValue: (value: Record<string, Nullable<string>>) => void,
    isMultiSelect?: boolean,
    allowToggle?: boolean,
    showDateRange?: boolean,
    minDate?: string,
    maxDate?: string,
    simpleMode?: boolean,
    setSelectedDate?: (date: string) => void,
    setSelectedDays?: (day: Record<string, Nullable<string>>) => void
}

export const CalendarMonth: React.FunctionComponent<CalendarMonthProps> = ({
    date,
    disabled,
    frequency,
    selectedDate,
    minHoursNotice,
    isMultiSelect,
    selectedDays,
    allowToggle,
    minDate,
    liteMode,
    showDateRange,
    maxDate = APP_CONFIG?.DATE_TIME_SUNSET,
    setSelectedDate,
    onDayPress,
    selectedDaysChangeValue
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const numberOfDaysInMonth = dateHelpers.getMonthDaysNumber(date)
    const dayOfFirstWeek = dateHelpers.getDayNumberOfFirstWeek(date)
    const blankDays = dayOfFirstWeek === 0
        ? 7
        : dayOfFirstWeek - dateHelpers.WEEK_STARTS_ON
    const rangeDates = selectedDate?.split(', ') || []
    const startRangeDate = rangeDates?.[0]
    const endRangeDate = rangeDates?.[rangeDates.length - 1]
    const handleDayRangePress = (dayDate: string) => {
        if (!setSelectedDate) {
            return
        }

        const rangeDates = (selectedDate?.split(', ') || []).filter(date => date)

        if (rangeDates.length !== 1) {
            setSelectedDate(dayDate)
        } else if (rangeDates.length === 1) {
            const startRangeDate = rangeDates[0]
            const endRangeDate = dayDate

            setSelectedDate(startRangeDate === endRangeDate ? startRangeDate : dateHelpers.fillMissingDates(`${startRangeDate}, ${endRangeDate}`))
        }
    }

    const handleDayPress = (dayDate: string, isSelected: boolean) => {
        if (isSelected && !allowToggle) {
            return
        }

        if (!isMultiSelect) {
            return onDayPress(dayDate)
        }

        const newSelectedDaysValue = isSelected
            ? R.omit([dayDate], selectedDays)
            : {
                ...selectedDays,
                [dayDate]: null
            }

        selectedDaysChangeValue(newSelectedDaysValue)

        const selectedDates = selectedDate
            ? selectedDate?.split(', ')
            : []
        const newSelectedDates = isSelected
            ? selectedDates.filter(date => date !== dayDate)
            : selectedDates.concat(dayDate)

        return onDayPress(newSelectedDates.join(', '))
    }

    return (
        <View style={styles.container}>
            {blankDays < 7 && Array.from(new Array(blankDays)).map((item, index) => (
                <View
                    style={{
                        ...styles.dayContainer
                    }}
                    key={index}
                />
            ))}
            {Array.from(new Array(numberOfDaysInMonth)).map((item, index) => {
                const dayNumber = index + 1
                const dayDate = dateHelpers.calendarDayDate(date, dayNumber)
                const selectedDayInputValue = isMultiSelect
                    ? selectedDate?.split(', ').find(date => date === dayDate)
                    : selectedDate
                const isPast = dateHelpers.calendarIsPast(dateHelpers.subHoursFromDate(dayDate, minHoursNotice))
                const isPastMax = maxDate ? dateHelpers.checkIfIsAfter(dayDate, maxDate) : false
                const isToday = dateHelpers.checkIfIsToday(dayDate)
                const dayName = dateHelpers.getWeekDayFullNames(dayDate, locale)
                const isDisabled = frequency.value === BookingFrequency.OneTime
                    ? disabled
                    : disabled || !selectedDays[dayName]
                const isSelected = selectedDayInputValue
                    ? dateHelpers.getIsSameDay(selectedDayInputValue, dayDate)
                    : false
                const selectedTime = dateHelpers.getDateTime(selectedDays[dayName] || new Date())
                const selectedTimeDate = `${dayDate}T${selectedTime}`
                const isPastMinimumNotice = frequency.value === BookingFrequency.OneTime
                    ? false
                    : dateHelpers.isDatePast(dateHelpers.subHoursFromDate(selectedTimeDate, minHoursNotice))

                const showApplyDateRangeStyle = showDateRange && isMultiSelect
                const isSelectedStartRange = showApplyDateRangeStyle && isSelected && dateHelpers.getIsSameDay(startRangeDate, dayDate)
                const isSelectedEndRange = showApplyDateRangeStyle && isSelected && dateHelpers.getIsSameDay(endRangeDate, dayDate)
                const isSelectedRestOfRange = showApplyDateRangeStyle && isSelected && !isSelectedStartRange && !isSelectedEndRange
                const isLessThanMinDate = minDate ? dateHelpers.checkIfIsAfter(minDate, dayDate) && !dateHelpers.getIsSameDay(minDate, dayDate) : false
                const shouldDisableDate = isPast || isDisabled || isPastMinimumNotice || isPastMax || isLessThanMinDate
                const dateSuffix = dayDate.split('-')[2]

                return (
                    <Touchable
                        testID={T.accessibility.calendar.month.date.replace('[date]', dateSuffix)}
                        key={index}
                        disabled={shouldDisableDate}
                        style={{
                            ...(liteMode ? styles.dayContainerWithLiteMode : styles.dayContainer)
                        }}
                        onPress={() => showDateRange ?
                            handleDayRangePress(dayDate) :
                            handleDayPress(dayDate, isSelected)
                        }
                    >
                        <View
                            style={
                                isSelected
                                    ? (
                                        isSelectedRestOfRange ?
                                            styles.selectedRestOfRange :
                                            styles.selected
                                    ) : isToday && !isDisabled
                                        ? styles.today
                                        : styles.dayNumber
                            }
                        >
                            <Regular
                                forceColor={shouldDisableDate ?
                                    theme.colors.fog :
                                    isSelected
                                        ? (
                                            isSelectedRestOfRange ?
                                                theme.colors.fog :
                                                theme.colors.white
                                        ) : isToday
                                            ? theme.colors.sun
                                            : theme.colors.darkGrey
                                }
                            >
                                {dayNumber.toString()}
                            </Regular>
                        </View>
                    </Touchable>
                )
            })}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderRadius: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
    },
    dayContainerWithLiteMode: {
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(1.5),
        width: '14.2%'
    },
    dayContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(2),
        width: '14.2%'
    },
    dayNumber: {
        paddingVertical: 2
    },
    selected: {
        width: theme.utils.gap(4),
        borderRadius: theme.utils.gap(2),
        paddingVertical: 2,
        alignItems: 'center',
        backgroundColor: theme.colors.sun
    },
    selectedRestOfRange: {
        width: theme.utils.gap(4),
        borderRadius: theme.utils.gap(2),
        paddingVertical: 2,
        alignItems: 'center',
        backgroundColor: theme.colors.oldLace
    },
    today: {
        width: theme.utils.gap(4),
        borderRadius: theme.utils.gap(2),
        alignItems: 'center',
        borderColor: theme.colors.sun,
        borderWidth: 1
    }
}))
