import { createDrawerNavigator } from '@react-navigation/drawer'
import { CustomLeftDrawer } from 'lib/components'
import { MobileStack } from './MobileStack'
import { NativeDrawerParams } from '../stackParams'
import { NavigatorId, ScreenNamesNative } from '../screens'

const LeftDrawer = createDrawerNavigator<NativeDrawerParams>()

export const NativeStack = () => (
    <LeftDrawer.Navigator
        id={NavigatorId.LeftDrawer}
        initialRouteName={ScreenNamesNative.NativeRootLeftDrawer}
        screenOptions={{
            headerShown: false,
            swipeEnabled: false,
            drawerPosition: 'left'
        }}
        drawerContent={props => (
            <CustomLeftDrawer {...props} />
        )}
    >
        <LeftDrawer.Screen
            name={ScreenNamesNative.NativeRootLeftDrawer}
            component={MobileStack}
        />

    </LeftDrawer.Navigator>
)
