import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useUserAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'

type JobBillingsProps = {
    job: JobDetailsResponse
}

export const AirconBilling: React.FunctionComponent<JobBillingsProps> = ({ job }) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const { styles, theme } = useStyles(stylesheet)
    const isFavourite = user.consumer.per_service_account.some(account => account?.preferred_helper?._id === job?.job?.supplier?._id)

    if(!job?.subscriptionBill) {
        return
    }

    return (
        <View style={styles.content}>
            <View style={styles.billingsTitle}>
                <View style={styles.billingsNumber}>
                    <Typography.Regular forceColor={theme.colors.white}>
                        {job.subscriptionBill?.length}
                    </Typography.Regular>
                </View>
                <Typography.Regular bold>
                    {job.subscriptionBillTitle || T.components.noBilling.estimatedBilling}
                </Typography.Regular>
            </View>
            <View>
                <View style={styles.titleWrapper}>
                    <View style={styles.title}>
                        <View style={styles.expertName}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {`${T.components.proposals.expert}: ${job.job.supplier.handle} `}
                            </Typography.Label>
                            {isFavourite && (
                                <Icons.Heart
                                    size={14}
                                    fill={theme.colors.orange}
                                    forceColor={theme.colors.orange}
                                />
                            )}
                        </View>
                        <Icons.CheckCircle
                            size={14}
                            forceColor={theme.colors.green}
                        />
                    </View>
                    <View>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {`${T.components.proposals.jobID}: ${job.job.job_id} `}
                        </Typography.Label>
                    </View>
                </View>
                <View style={styles.charges}>
                    {job?.subscriptionBill?.map((billItem, index) => (
                        <View key={index}>
                            <View style={styles.row}>
                                <View>
                                    <Typography.Label forceColor={theme.colors.grey} bold>
                                        {billItem.name}
                                    </Typography.Label>
                                    {billItem.label &&(
                                        <View>
                                            <Typography.Label forceColor={theme.colors.grey}>
                                                {billItem.label}
                                            </Typography.Label>
                                        </View>
                                    )}
                                </View>
                                <Typography.Label bold>
                                    {billItem.value}
                                </Typography.Label>
                            </View>

                        </View>
                    ))}
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1
    },
    buttons: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(1)
    },
    separator: {
        width: theme.utils.gap(1) / 2
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        marginBottom: theme.utils.gap(2),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    titleWrapper: {
        backgroundColor: theme.colors.marble,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(2),
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    },
    title: {
        borderRadius: 8,
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.gap(1)
    },
    expertName: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    row: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(2)
    },
    charges: {
        paddingHorizontal: theme.utils.gap(1)
    },
    description: {
        marginBottom: theme.utils.gap(1)
    },
    billingsTitle: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    billingsNumber: {
        width: 24,
        height: 24,
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.utils.gap(1),
        backgroundColor: theme.colors.night
    },
    totalPrice: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: theme.utils.gap(1)
    },
    lineThrough: {
        textDecorationLine: 'line-through'
    },
    lineSeparator: {
        height: 1,
        backgroundColor: theme.colors.marble,
        marginBottom: theme.utils.gap(1)
    },
    paidLabel: {
        marginTop: theme.utils.gap(1)
    },
    termsAndConditions: {
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: theme.utils.gap(1)
    },
    viewTerms: {
        textDecorationLine: 'underline'
    }
}))
