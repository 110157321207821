import React, { useState } from 'react'
import { NativeSyntheticEvent, TextInputChangeEventData, View, Platform } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Chip } from '@propertyguru/hive-chip'
import { IconColor, useHive } from '@propertyguru/hive'
import { Divider } from '@propertyguru/hive-divider'
import { TextInput } from '@propertyguru/hive-text-input'
import { Button } from '@propertyguru/hive-button'
import { Modal } from '@propertyguru/hive-modal'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Icon } from '@propertyguru/hive-icon'
import { WhatsappBw } from '@propertyguru/hive-icons'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { BackButton } from 'features/native/common/components'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { useNativeUserTokenAtom } from 'lib/native/atoms/token'
import { Touchable } from 'lib/components'
import { NativeModal } from 'lib/native/components'
import { linkingHelpers } from 'lib/utils'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { NativeNavigationParams } from 'lib/routing'
import { RatingStars } from '../components/RatingStars.native'
import { useRateJob } from '../actions'
import { Segment } from 'lib/analytics'

type ThankyouModalProps = {
    isOpen: boolean,
    onClose(): void
}

export const ThankyouModal: React.FunctionComponent<ThankyouModalProps> = ({ isOpen, onClose }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()

    const onPressBackToHome = () => {
        onClose()
        navigation.navigate(ScreenNamesNative.DashboardNative)
    }

    return (
        <NativeModal fullWidth isOpen={isOpen} onClose={onClose}>
            <Modal>
                <Modal.Header>
                    <Modal.Header.Title>
                        {T.screens.ratingsScreen.thankYou}
                    </Modal.Header.Title>
                    <Modal.Header.Button onPress={onClose} />
                </Modal.Header>
                <Modal.Body>
                    <Text typography="body/m">
                        {T.screens.ratingsScreen.thankYouForFeedback}
                    </Text>
                    <View style={styles.actionContainer}>
                        <Button onPress={onPressBackToHome} type="primary" text={T.screens.ratingsScreen.backToHome} />
                    </View>
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

export const RatingsScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const hive = useHive()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const [token] = useNativeUserTokenAtom()
    const navigation = useNavigation()
    const route = useRoute<NativeNavigationParams<ScreenNamesNative.NativeRatingsScreen>>()
    const { isLoading, mutate: submitJobRating } = useRateJob()
    const [rating, setRating] = useState(route.params?.rating || 0)
    const [comment, setComment] = useState('')
    const [feedback, setFeedback] = useState('')
    const [isThankyouModalOpen, setIsThankyouModalOpen] = useState(false)
    const jobId = route.params?.jobId || ''

    const onJobRatingSubmit = () => {
        submitJobRating(
            { id: jobId, token, rate: rating, comment, feedback },
            {
                onSuccess: () => {
                    setIsThankyouModalOpen(true)
                    Segment.serviceRatingFeedbackSubmitted({ serviceType: route.params?.jobId })
                },
                onError: error => {
                    console.log('error job rating', error)
                }
            }
        )
    }

    const onRatingStarPress = (value: number) => {
        setRating(value)
    }

    const onCommentChange = (event: NativeSyntheticEvent<TextInputChangeEventData>) => {
        setComment(event.nativeEvent.text)
    }

    const onFeedbackChange = (feedbackValue: string) => {
        setFeedback(prevFeedback => {
            const feedbackArray = prevFeedback.split(',').filter(Boolean)

            if (feedbackArray.includes(feedbackValue)) {
                return feedbackArray.filter(item => item !== feedbackValue).join(',')
            }

            return [...feedbackArray, feedbackValue].join(',')
        })
    }

    const isFeedbackItemActive = (item: string) => feedback.split(',').includes(item)

    const getRatingTitle = (rating: number) => {
        if (rating === 5) {
            return T.screens.ratingsScreen.exceptional
        }

        if (rating === 4) {
            return T.screens.ratingsScreen.great
        }

        if (rating === 3) {
            return T.screens.ratingsScreen.satisfactory
        }

        if (rating === 2) {
            return T.screens.ratingsScreen.belowExpectation
        }

        return T.screens.ratingsScreen.needsImprovement
    }

    const getRatingSubtitle = (rating: number) => {
        if (rating >= 4) {
            return T.screens.ratingsScreen.aboutTheService
        }

        return T.screens.ratingsScreen.howCanWeImprove
    }

    const getFeedbackOptions = () => [
        T.screens.ratingsScreen.timeliness,
        T.screens.ratingsScreen.communication,
        T.screens.ratingsScreen.professional,
        T.screens.ratingsScreen.respectful,
        T.screens.ratingsScreen.friendly,
        T.screens.ratingsScreen.efficient,
        T.screens.ratingsScreen.attentionToDetail,
        T.screens.ratingsScreen.customerService
    ]

    const onOpenWhatsapp = () => {
        linkingHelpers.openUrl(T.screens.dashboard.supportSection.whatsappUrl)
    }

    return (
        <KeyboardAwareScrollView
            style={styles.container}
            contentContainerStyle={styles.contentContainer}
            enableOnAndroid
            extraHeight={Platform.OS === 'ios' ? 200 : 100}  // This helps to give extra space when focusing input
            keyboardShouldPersistTaps="handled"
        >
            <View
                style={{
                    ...styles.backButtonContainer,
                    marginTop: insets.top + hive.spacing('x4')
                }}
            >
                <BackButton onPress={() => (navigation.canGoBack() ? navigation.goBack() : null)} />
                <Touchable style={styles.contactUs} onPress={onOpenWhatsapp}>
                    <Icon icon={WhatsappBw} height={16} width={16} />
                    <Text typography="caption/xs">{T.common.contactUs}</Text>
                </Touchable>
            </View>
            <View style={styles.content}>
                <Text typography="label/l" style={styles.title}>
                    {getRatingTitle(rating)}
                </Text>

                <View style={styles.ratings}>
                    <RatingStars rating={rating} onRate={onRatingStarPress} selectedStarColor={'fill/active/primary' as IconColor} />
                </View>

                <Divider style={styles.divider} />

                <Text typography="label/m" style={styles.subtitle}>
                    {getRatingSubtitle(rating)}
                </Text>

                <Text typography="body/xs" style={styles.helperText}>
                    {T.screens.ratingsScreen.willShareFeedbackWithHelper}
                </Text>

                <View style={styles.feedback}>
                    {getFeedbackOptions().map((tag, index) => (
                        <Chip
                            key={index}
                            text={tag}
                            active={isFeedbackItemActive(tag)}
                            onPress={() => onFeedbackChange(tag)}
                        />
                    ))}
                </View>

                <TextInput
                    placeholder={T.screens.ratingsScreen.anythingElse}
                    multiline
                    size="large"
                    onChange={onCommentChange}
                />

                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    onPress={onJobRatingSubmit}
                    style={styles.actionButton}
                    text={T.screens.ratingsScreen.submitFeedback}
                />
            </View>
            <ThankyouModal isOpen={isThankyouModalOpen} onClose={() => setIsThankyouModalOpen(false)} />
        </KeyboardAwareScrollView>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    content: {
        paddingHorizontal: theme.utils.gap(2)
    },
    contentContainer: {
        flexGrow: 1
    },
    backButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: theme.utils.gap(2),
        borderBottomWidth: 0.5,
        borderColor: theme.utils.hive.color('border/active/secondary') as string
    },
    contactUs: {
        flexDirection: 'row',
        gap: theme.utils.gap(0.5)
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: theme.utils.gap(2)
    },
    subtitle: {
        textAlign: 'center',
        marginBottom: 10
    },
    feedback: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: theme.utils.gap(0.5),
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    },
    ratings: {
        marginBottom: theme.utils.gap(2)
    },
    helperText: {
        textAlign: 'center',
        color: 'gray'
    },
    actionButton: {
        marginTop: theme.utils.gap(2)
    },
    divider: {
        marginBottom: theme.utils.hive.spacing('x10')
    },
    actionContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    }
}))
