import { useNavigation } from '@react-navigation/native'
import { CommonCardAction } from 'features/bookings'
import { REDIRECT, URL } from 'features/native/home/constants'
import { linkingHelpers } from 'lib/utils'
import { useToastAtom } from 'lib/atoms'
import { useTranslations } from 'lib/hooks'
import { NotificationType } from 'lib/types'
import { ScreenNamesNative } from '../routing/screens'
import { Segment } from 'lib/analytics'

export const useHandleCardAction = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()

    const handleAction = (action?: CommonCardAction) => {

        try {
            const actionType = action?.type
            const actionUrl = action?.url
            const componentName = action?.name as string
            const tracking = action?.tracking

            switch (actionType) {
                case REDIRECT:
                    if (ScreenNamesNative[componentName]) {
                        Segment.elementClicked(tracking || {})

                        return navigation.navigate(ScreenNamesNative[componentName])
                    }

                    console.error('Screen name not supported yet', action)

                    break

                case URL:
                    Segment.serviceClicked({
                        serviceType: componentName
                    })
                    Segment.elementClicked(tracking || {})

                    return linkingHelpers.openUrl(actionUrl)

                default:
                    console.error('Card action is invalid', action)
            }
        } catch {
            setToastMessage({
                message: T.common.errorMessage,
                type: NotificationType.Error
            })
        }
    }

    return handleAction
}
