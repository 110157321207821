import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
    LandingScreen,
    ForgotPasswordScreen,
    ResetPasswordScreen,
    VerifyIdentityScreen
} from 'features/native/onboarding/screens'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { NativeAppStackParams } from 'lib/native/routing/stackParams'
import { SignUpScreen, LoginScreen } from 'features/native/auth/screens'

const Stack = createNativeStackNavigator<NativeAppStackParams>()

export const UnauthorizedNativeStack = () => (
    <React.Fragment>
        <Stack.Screen
            name={ScreenNamesNative.LandingNative}
            component={LandingScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.LogInNative}
            component={LoginScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.SignUpNative}
            component={SignUpScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.ForgotPasswordNative}
            component={ForgotPasswordScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.VerifyIdentityNative}
            component={VerifyIdentityScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.ResetPasswordNative}
            component={ResetPasswordScreen}
        />
    </React.Fragment>
)
