import React from 'react'
import Animated, { SharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { isNative } from 'lib/common'

type FadeTabButtonProps = {
    activeIndex: SharedValue<number>,
    index: number,
    selectedTab: number,
    children: Children
}

export const FadeTabContent: React.FunctionComponent<FadeTabButtonProps> = ({
    selectedTab,
    activeIndex,
    index,
    children
}) => {
    const { styles } = useStyles(stylesheet)
    const animatedTabStyles = useAnimatedStyle(() => ({
        position: activeIndex.value === index
            ? 'relative'
            : 'absolute',
        zIndex: activeIndex.value === index
            ? 1
            : -1,
        opacity: withTiming(activeIndex.value === index
            ? 1
            : 0
        )
    }))

    if (isNative) {
        return selectedTab === index
            ? children
            : null
    }

    return (
        <Animated.View style={[styles.tabWrapper, animatedTabStyles]}>
            {children}
        </Animated.View>
    )
}

const stylesheet = createStyles(() => ({
    tabWrapper: {
        left: 0,
        right: 0,
        top: 0
    }
}))
