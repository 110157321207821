import React from 'react'
import { DimensionValue, View } from 'react-native'
import { Icons } from 'assets'
import { createStyles, theme } from 'lib/styles'
import { CustomFonts } from 'lib/types'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Typography, Touchable } from 'lib/components'
import { isNative, Measurements } from 'lib/common'

type SubscriptionCancelSuccessModalProps = {
    isOpen: boolean,
    onClose: VoidFunction,
}

export const VoucherRedeemedModal: React.FunctionComponent<SubscriptionCancelSuccessModalProps> = ({
    isOpen,
    onClose
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <CustomModal
            childrenWrapper={styles.modalChildrenWrapper}
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                </View>
                <Typography.SmallSubheading style={styles.title} >
                    {T.components.sendPayModal.voucherRedemption.voucherRedeemed}
                </Typography.SmallSubheading>
                <Typography.Regular style={styles.message}>
                    {T.components.sendPayModal.voucherRedemption.voucherRedeemedMessage}
                </Typography.Regular>
                <View style={styles.actionsContainer}>
                    <Touchable
                        onPress={onClose}
                        style={styles.button}>
                        <Typography.Regular style={styles.backToHomeButtonText}>
                            {T.components.sendPayModal.voucherRedemption.done}
                        </Typography.Regular>
                    </Touchable>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalChildrenWrapper: {
        paddingTop: 0,
        maxWidth: 500,
        width: {
            xs: isNative ? Measurements.WindowWidth * 0.75 : '75vw' as DimensionValue
        }
    },
    modalWrapper: {
        width: '100%',
        alignItems: 'center',
        paddingHorizontal: {
            sm: theme.utils.gap(4),
            xs: theme.utils.gap(2)
        }
    },
    iconContainer: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    message: {
        fontFamily: CustomFonts.Poppins400,
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 22
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 32,
        marginBottom: theme.utils.gap(2)
    },
    button: {
        borderWidth: 2,
        borderColor: theme.colors.sun,
        borderRadius: 4,
        flex: 1,
        paddingVertical: theme.utils.gap(1.25),
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors.sun,
        paddingHorizontal: {
            md: theme.utils.gap(4),
            xs: theme.utils.gap(1.25)
        }
    },
    backToHomeButtonText: {
        fontFamily: CustomFonts.Poppins400,
        color: theme.colors.night,
        fontSize: 14
    },
    actionsContainer: {
        marginTop: theme.utils.gap(3),
        width: {
            md: undefined,
            xs: '100%'
        }
    }
}))
