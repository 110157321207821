import { Platform } from 'react-native'
import * as Updates from 'expo-updates'
import Constants from 'expo-constants'
import { APP_CONFIG } from 'lib/config'

const pkg = require('../../../package.json')

export const getAppVersionForPlatform = () => {
    switch (Platform.OS) {
        case 'android':
            return `${Constants.expoConfig?.version || Updates.runtimeVersion} (${Constants.manexpoConfigifest?.android?.versionCode || Updates.updateId?.slice(0, 4) || '-'})`
        case 'ios':
            return `${Constants.expoConfig?.version || Updates.runtimeVersion} (${Constants.expoConfig?.ios?.buildNumber || Updates.updateId?.slice(0, 4) || '-'})`
        case 'web':
            return `${pkg.version}`
        default:
            return ''
    }
}

export const getAppBuildEnvironment = () => {
    const isIntegration = APP_CONFIG.API.URL?.includes('integration')
    const isAlpha = APP_CONFIG.API.URL?.includes('alpha')
    const isBeta = APP_CONFIG.API.URL?.includes('beta')
    const isOmega = APP_CONFIG.API.URL?.includes('omega')

    if(APP_CONFIG.API.URL?.includes('staging')) {
        return 'staging'
    }

    if(isIntegration && isAlpha) {
        return 'integration-alpha'
    }

    if(isIntegration && isBeta) {
        return 'integration-beta'
    }

    if(isIntegration && isOmega) {
        return 'integration-omega'
    }

    if(isIntegration) {
        return 'integration-alpha'
    }

    return ''

}
