export enum TextType {
    Regular = 'regular',
    Bold = 'bold'
}

export const parseMessageText = (message: string): Array<{ text: string; type: TextType.Regular | TextType.Bold }> => {
    try {
        const boldPattern = /\*\*(.*?)\*\*/g
        const matches = Array.from(message.matchAll(boldPattern))
        const result: Array<{ text: string; type: TextType.Regular | TextType.Bold }> = []

        const processMatches = (message, matches) => {
            matches.reduce((lastIndex, match) => {
                const start = match.index

                if (start > lastIndex) {
                    result.push({ text: message.slice(lastIndex, start).trim(), type: TextType.Regular })
                }

                result.push({ text: match[1].trim(), type: TextType.Bold })

                return start + match[0].length
            }, 0)

            const finalIndex =
                matches.length > 0 ? matches[matches.length - 1].index + matches[matches.length - 1][0].length : 0

            if (finalIndex < message.length) {
                result.push({ text: message.slice(finalIndex).trim(), type: TextType.Regular })
            }
        }

        processMatches(message, matches)

        return result
    } catch (e) {
        return [{ text: message, type: TextType.Regular }]
    }
}
