import React, { useEffect, useState } from 'react'
import { View, Image, Platform } from 'react-native'
import emojiRegex from 'emoji-regex'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { UnauthorizedNativeLayout } from 'features/native/common/components'
import { Text } from '@propertyguru/hive-text'
import { TextInput } from '@propertyguru/hive-text-input'
import { Button } from '@propertyguru/hive-button'
import { Surface } from '@propertyguru/hive-surface'
import { AccessibilityEyeA11YOutline, ShowEyeSeeRevealLookVisibleOutline } from '@propertyguru/hive-icons'
import {
    useStyles,
    useTranslations
} from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Images } from 'assets/native'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { checkEmailValid } from 'lib/native/utils'
import { SignupScreenParams } from 'lib/native/routing/screenParams'
import { useGoogleSignIn, useSocialSignIn } from '../hooks'
import { useSignUpWithEmail } from '../actions'
import { saveAccessToken, saveRefreshToken } from '../utils'
import { getAnonymousID } from 'lib/analytics/segment/utils'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, Segment } from 'lib/analytics'
import { DeviceInfo, linkingHelpers } from 'lib/utils'
import { NativeErrorCodes } from 'lib/native/types'
import { useNativeIsAuthorizedAtom } from 'lib/native/atoms/auth'
import { useNativeUserTokenAtom } from 'lib/native/atoms/token'
import { ErrorModal, SocialButtonsNative } from '../components'
import { AuthMethod } from 'lib/analytics/segment/typewriter/segment'

export const SignUpScreen: React.FunctionComponent<SignupScreenParams> = React.memo(({ navigation }) => {
    const T = useTranslations()
    const regexEmoji = emojiRegex()
    const insets = useSafeAreaInsets()
    const { styles, theme } = useStyles(stylesheet)
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const { onPress: onGoogleSignInClick } = useGoogleSignIn(() => { })
    const { signInWithFacebook, signInWithApple } = useSocialSignIn()
    const [email, setEmail] = useState('')
    const [, setUserToken] = useNativeUserTokenAtom()
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [emailErrorString, setEmailErrorString] = useState(T.screens.auth.inCorrectEmail)
    const [password, setPassword] = useState('')
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [passwordErrorString, setPasswordErrorString] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [, setIsAuthorized] = useNativeIsAuthorizedAtom()

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [errorModalTitle, setErrorModalTitle] = useState('')
    const [errorModalMessage, setErrorModalMessage] = useState('')

    const { mutate: signUpWithEmailAndPassword } = useSignUpWithEmail()

    useEffect(() => {
        resetState()
    }, [])

    const resetState = () => {
        setEmail('')
        setPassword('')
        setIsPasswordValid(true)
        setEmailErrorString('')
        setPasswordErrorString('')
    }

    const checkPasswordValidity = (value: string) => {
        const lowercaseRegex = /(?=.*[a-z])/
        const uppercaseRegex = /(?=.*[A-Z])/
        const digitRegex = /(?=.*\d)/
        const specialCharRegex = /[!@#$%^&*()\-=_+[\]{}|;:'",.<>/?\\]/
        const minLengthRegex = /.{10,}/

        const hasLowercase = lowercaseRegex.test(value)
        const hasUppercase = uppercaseRegex.test(value)
        const hasDigit = digitRegex.test(value)
        const hasSpecialChar = specialCharRegex.test(value)
        const hasMinLength = minLengthRegex.test(value)

        const isCorrectPassword = hasLowercase && hasUppercase && hasDigit && hasSpecialChar && hasMinLength

        setPasswordErrorString(
            !isCorrectPassword
                ? `Make sure it contains at least ${[
                    !hasMinLength && '10 characters',
                    !hasLowercase && '1 lowercase',
                    !hasUppercase && '1 uppercase',
                    !hasDigit && '1 numeric',
                    !hasSpecialChar && '1 special character'
                ]
                    .filter(Boolean)
                    .join(', ')}.`
                : ''
        )

        return isCorrectPassword
    }

    const onPasswordChange = value => {
        setPassword(value.replace(regexEmoji, ''))

        if (value.length === 0) {
            setPasswordErrorString('')
            setIsPasswordValid(true)

            return
        }

        const isPassValid = checkPasswordValidity(value)

        if (isPassValid) {
            setPasswordErrorString('')
            setIsPasswordValid(true)
        }
    }

    const onPasswordBlur = () => {
        setIsPasswordValid(checkPasswordValidity(password))
    }

    const onShowPassword = () => {
        setIsPasswordHidden(!isPasswordHidden)
    }

    const onEmailChange = (value: string) => {
        setEmail(value.replace(regexEmoji, ''))
        setEmailErrorString('')
        setIsValidEmail(true)
    }

    const onEmailBlur = () => {
        if (email.length !== 0) {
            setIsValidEmail(checkEmailValid(email))
        }

        if (!checkEmailValid(email)) {
            setEmailErrorString(T.screens.auth.inCorrectEmail)
        }
    }

    const navigateToLogin = () => {
        resetState()
        navigation?.navigate(ScreenNamesNative.LogInNative)
    }

    const onReceiveAuthEmailError = error => {
        const details = error.details
        const { email, password } = details

        if (email) {
            setEmailErrorString(email)
            setIsValidEmail(false)
        }

        if (password) {
            setIsPasswordValid(false)
            setPasswordErrorString(password)
        }
    }

    const onReceiveUserExistsError = error => {
        setEmailErrorString(error.error_msg)
        setIsValidEmail(false)
    }

    const onReceiveError = error => {
        const { error_code: errorCode, error_msg: errorMsg } = error
        Segment.signUpFailed({
            authMethod: AuthMethod.Email,
            errorMessage: errorMsg
        })
        switch (errorCode) {
            case NativeErrorCodes.INVALID_AUTH_REQUEST:
                onReceiveAuthEmailError(error)
                break
            case NativeErrorCodes.USER_ALREADY_EXISTS:
                onReceiveUserExistsError(error)
                break
            default:
                setIsErrorModalOpen(true)
                setErrorModalTitle(T.screens.auth.errors.signUpFailed)
                setErrorModalMessage(errorMsg)
        }
    }

    const onUserSignUp = async () => {
        setIsLoading(true)
        signUpWithEmailAndPassword({
            email,
            password,
            tracking: {
                anonymousId: await getAnonymousID()
            }
        }, {
            onSuccess: ({ data }) => {
                saveAccessToken(data.tokens.accessToken)
                saveRefreshToken(data.tokens.refreshToken)
                setUserToken(data.token)
                setIsAuthorized(true)

                GoogleAnalytics.logEvent({
                    eventType: AnalyticsEvent.UserAction,
                    description: AnalyticsEventDescription.EmailSignUp
                })
                const { deviceId, deviceName } = DeviceInfo.get()
                Segment.identify(data.token, {
                    email,
                    device: {
                        deviceId,
                        deviceName,
                        platform: Platform.OS
                    }
                })
                setIsLoading(false)
                resetState()
                navigation?.navigate(ScreenNamesNative.NativeGetStarted)
            },
            onError: response => {
                setIsLoading(false)
                onReceiveError(response.errors)
            }
        })
    }

    const navigateToTermsAndPrivacy = () => {
        linkingHelpers.openUrl(`https://www.sendhelper.com/terms`)
    }

    const navigateToPrivacyPolicy = () => {
        linkingHelpers.openUrl(`https://www.sendhelper.com/privacy`)
    }

    return (
        <UnauthorizedNativeLayout themeColor={theme.colors.yellow} bounces={false}>
            <Surface style={styles.imageContainer}>
                <Image
                    source={Images.SignUp}
                    style={styles.image}
                />
            </Surface>
            <View style={{ ...styles.container, paddingBottom: insets.bottom }}>
                <View style={styles.titleContainer}>
                    <Text typography="body/s">{T.screens.auth.signUpWithEmail}</Text>
                </View>
                <View style={styles.inputContainer}>
                    <TextInput
                        placeholder={T.screens.forgotPassword.email}
                        style={styles.textInput}
                        value={email}
                        onChangeText={onEmailChange}
                        error={!isValidEmail}
                        size="default"
                        onBlur={onEmailBlur}
                        inputMode="email"
                        keyboardType="email-address"
                        accessibilityLabel={T.accessibility.native.signUp.email}
                    />
                    {!isValidEmail && <Text style={styles.errorContainer} accessibilityLabel={T.accessibility.native.signUp.errorEmail} typography="caption/xs" color="text/error/primary">{emailErrorString}</Text>}
                </View>
                <View style={styles.inputContainer}>
                    <TextInput
                        placeholder={T.screens.auth.formFields.password.placeholder}
                        onChangeText={onPasswordChange}
                        secureTextEntry={isPasswordHidden}
                        style={styles.textInput}
                        value={password}
                        size="default"
                        onBlur={onPasswordBlur}
                        error={!isPasswordValid}
                        endIcon={isPasswordHidden ? AccessibilityEyeA11YOutline : ShowEyeSeeRevealLookVisibleOutline}
                        onEndIconPress={onShowPassword}
                        accessibilityLabel={T.accessibility.native.signUp.password}
                    />
                    {passwordErrorString && <Text style={styles.errorContainer} accessibilityLabel={T.accessibility.native.signUp.errorPassword} typography="caption/xs" color={isPasswordValid ? 'text/static/primary' : 'text/error/primary'}>{passwordErrorString}</Text>}
                </View>
                <View style={styles.linkContainer}>
                    <Text typography="body/xs">{T.screens.auth.haveAnAccount}<Text typography="body/xs" style={styles.link} accessibilityLabel={T.accessibility.native.login.email} onPress={navigateToLogin}> {T.common.logIn}</Text></Text>
                </View>
                <View style={styles.actionContainer}>
                    <Button type="primary" disabled={isLoading} loading={isLoading} accessibilityLabel={T.accessibility.native.signUp.continueButton} onPress={onUserSignUp} text={T.common.continue} />
                </View>
                <View style={styles.socialSignUpContainer}>
                    <SocialButtonsNative
                        prefixText={T.screens.auth.socialSignInLabelExtended}
                        onContinueWithGoogle={onGoogleSignInClick}
                        onContinueWithFacebook={signInWithFacebook}
                        onContinueWithApple={signInWithApple}
                    />
                </View>
                <View style={styles.tcWrapper}>
                    <Text typography="caption/xs" color="text/inactive/primary">
                        {T.screens.auth.termsConditionPartOneSignup}
                        <Text typography="caption/xs" onPress={navigateToTermsAndPrivacy} style={styles.link}>{T.screens.auth.termsConditionPartTwo}</Text>{T.common.and}{' '}
                        <Text typography="caption/xs" onPress={navigateToPrivacyPolicy} style={styles.link}>{T.components.policies.privacy}</Text>
                        {T.screens.auth.termsConditionPartThree}
                    </Text>
                </View>
                <ErrorModal isOpen={isErrorModalOpen} onClose={() => {
                    setIsErrorModalOpen(false)
                }} title={errorModalTitle} message={errorModalMessage} />
            </View>
        </UnauthorizedNativeLayout>
    )
})

const stylesheet = createStyles(theme => ({
    imageContainer: {
        aspectRatio: 374 / 231
    },
    image: {
        width: '100%',
        height: '100%'
    },
    container: {
        marginTop: theme.utils.gap(2),
        flex: 1,
        paddingHorizontal: {
            xs: theme.utils.gap(2)
        }
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: theme.utils.gap(1.5)
    },
    inputContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    },
    actionContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    },
    textInput: {
        width: '100%'
    },
    subTitle: {
        marginTop: theme.utils.gap(1.5)
    },
    description: {
        marginTop: theme.utils.gap(1.75)
    },
    link: {
        textDecorationLine: 'underline'
    },
    socialSignUpContainer: {
        marginTop: theme.utils.gap(2)
    },
    tcWrapper: {
        marginTop: theme.utils.gap(1)
    },
    errorContainer: {
        marginTop: theme.utils.gap(1)
    },
    linkContainer: {
        marginTop: theme.utils.gap(2)
    }
}))
