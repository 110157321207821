import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Button } from '@propertyguru/hive-button'
import { useRoute } from '@react-navigation/native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { SendForgotPasswordEmailActions } from 'features/native/constants'
import { VerifyIdentityScreenParams, VerifyIdentityScreenParams as VerifyIdentityScreenProps } from 'lib/native/routing/screenParams'
import { OTP_LENGTH } from 'features/native/onboarding/constants'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { UnauthorizedNativeLayout } from 'features/native/common/components'
import { OTPInputs, OTPInputsPlaceholder, ResendCodeSuccessModal } from 'features/native/onboarding/components'
import { requestSendForgotPasswordEmail, requestVerifyOTP } from 'features/native/actions'
import { Segment } from 'lib/analytics'

export const VerifyIdentityScreen: React.FunctionComponent<VerifyIdentityScreenProps> = ({ navigation }) => {
    const route = useRoute()
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const params = (route.params || {}) as VerifyIdentityScreenParams
    const { email, timerValue = 0 } = params
    const [defaultTimerValue] = useState<number>(timerValue)
    const [isResendCodeModalVisible, setIsResendCodeModalVisible] = useState<boolean>(false)
    const [otpValues, setOtpValues] = useState<Array<string>>([])
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [popupTitle, setPopupTitle] = useState<string>('')
    const [popupContent, setPopupContent] = useState<string>('')
    const [timeCounter, setTimeCounter] = useState<number>(timerValue)
    const { mutate: requestResendEmailResetPassword, isLoading: isSending } = requestSendForgotPasswordEmail()
    const { mutate: requestValidateOTP, isLoading: isVerifying } = requestVerifyOTP()
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const shouldDisableResendCodeButton = isSending

    const calculateTimeCounterText = () => timeCounter > 0 ?
        T.screens.verifyIdentity.verificationCodeValidityMessage.replace('[timerValue]', (timeCounter > 9 ? timeCounter.toString() : `0${timeCounter}`)
        ?? ` ${T.screens.verifyIdentity.aFew} `) : T.screens.verifyIdentity.resendCode

    useEffect(() => {
        if (timeCounter < 0 && intervalRef.current) {
            clearInterval(intervalRef.current)

            return
        }

        intervalRef.current = setInterval(() => {
            setTimeCounter(prev => {
                if (prev <= 0) {
                    intervalRef.current && clearInterval(intervalRef.current)
                    intervalRef.current = null

                    return 0
                }

                return prev - 1
            })
        }, 1000)

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [timeCounter])

    const backToForgotPasswordScreen = () => {
        navigation?.canGoBack() && navigation.goBack()
    }

    const onVerifyOTP = () => {
        if (!email) {
            return Segment.emailOtpVerified({
                status: false,
                errorMessage: T.screens.verifyIdentity.emailNotFound
            })
        }

        if (isVerifying) {
            return
        }

        setErrorMessage('')

        requestValidateOTP({ email, otp: otpValues.join('') }, {
            onSuccess: ({ data }) => {
                const passwordResetToken = data?.passwordResetToken

                if (!passwordResetToken) {
                    setErrorMessage(T.screens.verifyIdentity.cantGetPasswordResetToken)
                    Segment.emailOtpVerified({
                        status: false,
                        errorMessage: T.screens.verifyIdentity.cantGetPasswordResetToken
                    })

                    return
                }

                Segment.emailOtpVerified({
                    status: true
                })

                navigation?.navigate(ScreenNamesNative.ResetPasswordNative, {
                    email,
                    passwordResetToken
                })
            },
            onError: error => {
                Segment.emailOtpVerified({
                    status: false,
                    errorMessage: error?.errors?.error_msg || T.common.error
                })
                setErrorMessage(error?.errors?.error_msg || T.common.error)
            }
        })
    }

    const closeModalResentOTP = () => {
        setIsResendCodeModalVisible(false)
        setOtpValues([])
        setErrorMessage('')
        setTimeCounter(defaultTimerValue)
    }

    const onResendCode = () => {
        if (!email || shouldDisableResendCodeButton || timeCounter > 0) {
            return
        }

        requestResendEmailResetPassword({ email, action: SendForgotPasswordEmailActions.Resend }, {
            onSuccess: ({ data }) => {
                setPopupTitle(data?.popup?.title ?? '')
                setPopupContent(data?.popup?.message ?? '')
                setIsResendCodeModalVisible(true)
            },
            onError: error => {
                setPopupTitle(error?.errors?.error_title || T.common.error)
                setPopupContent(error?.errors?.error_msg || T.common.errorMessage)
                setIsResendCodeModalVisible(true)
            }
        })
    }

    return (
        <UnauthorizedNativeLayout fullScrollContainerHeight={false}>
            <View style={styles.container}>
                <View style={styles.titleContainer}>
                    <Text typography="title/xs" style={styles.title}>{T.screens.verifyIdentity.title}</Text>
                    <Text typography="body/s" style={styles.description}>{T.screens.verifyIdentity.description}</Text>
                    <Text typography="label/m" style={styles.email}>{`${email} `} <Text onPress={backToForgotPasswordScreen} accessibilityLabel={T.accessibility.native.forgotPassword.changeEmail} typography="body/xs" style={styles.link}>{T.screens.verifyIdentity.change}</Text></Text>
                </View>
                <View style={styles.inputContainer}>
                    {
                        isResendCodeModalVisible ?
                            <OTPInputsPlaceholder length={OTP_LENGTH} /> :
                            (
                                <OTPInputs
                                    value={otpValues}
                                    length={OTP_LENGTH}
                                    onChange={setOtpValues}
                                    disabled={false}
                                    errorMessage={errorMessage}
                                />
                            )
                    }
                </View>
                <View style={styles.actionContainer}>
                    <Button accessibilityLabel={T.accessibility.native.failedLogin.verifyAccount} onPress={onVerifyOTP} type="primary" text={T.screens.verifyIdentity.verifyAccount}/>
                </View>
                <View style={styles.footerContainer}>
                    <Text typography="body/xs">
                        {`${T.screens.verifyIdentity.didNotReceiveTheCode} `}
                    </Text>
                    <Text typography="body/xs"
                        style={timeCounter <= 0 && !isSending && styles.link}
                        accessibilityLabel={T.accessibility.native.failedLogin.resendCode}
                        onPress={onResendCode}
                    >
                        {isSending ? T.screens.verifyIdentity.sending : calculateTimeCounterText()}
                    </Text>
                </View>
            </View>
            <ResendCodeSuccessModal
                isOpen={isResendCodeModalVisible}
                onClose={closeModalResentOTP}
                title={popupTitle}
                content={popupContent}
            />
        </UnauthorizedNativeLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.utils.gap(2),
        flex: 1,
        paddingHorizontal: {
            xs: theme.utils.gap(2)
        }
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%'
    },
    inputContainer: {
        flex: 1,
        marginTop: theme.utils.gap(2),
        flexDirection: 'row'
    },
    actionContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    },
    textInput: {
        flex: 1
    },
    title: {
        fontWeight: '600'
    },
    subTitle: {
        marginTop: theme.utils.gap(1.5)
    },
    description: {
        marginTop: theme.utils.gap(2)
    },
    footerContainer: {
        marginTop: theme.utils.gap(2),
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center'
    },
    link: {
        fontWeight: '400',
        textDecorationLine: 'underline'
    },
    email: {
        fontWeight: '700',
        marginTop: theme.utils.gap(2)
    },
    actionLink: {
        marginTop: theme.utils.gap(0.5)
    },
    validityMessage: {
        textAlign: 'center'
    }
}))
