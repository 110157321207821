import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { FooterButtonsInfo } from './ButtonsInfo.native'

type Props = {
    children: ReactNode,
}

const FooterButtons = ({ children }: Props) => {
    const { styles } = useStyles(stylesheet)
    const hive = useHive()

    return (
        <View style={{
            ...styles.container,
            columnGap: hive.spacing('x4'),
            paddingVertical: hive.spacing('x6'),
            paddingHorizontal: hive.spacing('x4')
        }}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}))

export default Object.assign(FooterButtons, {
    Info: FooterButtonsInfo
})
