import React, { FunctionComponent } from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { DashboardScreen } from 'features/native/home'
// import { OrdersScreen } from 'features/native/jobs'
import { BookingsScreen } from 'features/jobs'
// import { UserScreen } from 'features/native/user'
import { UserScreen } from 'features/user'
import { BottomBar as BottomBarNative } from 'lib/native/components/BottomBar.native'
import { SendPayScreen } from 'features/sendPay'
import { ContactUsScreen } from 'features/contact'
import { UserCardsScreen } from 'features/payments'
import { FavouriteScreen } from 'features/favourite'
import { NavigatorId, ScreenNamesNative } from '../screens'
import { BottomTabsParams } from '../stackParams'
import { ScreenRedirect } from 'lib/native/components'

const BottomTabs = createBottomTabNavigator<BottomTabsParams>()

export const BottomTabsStack = () => (
    <BottomTabs.Navigator
        id={NavigatorId.BottomTabs}
        initialRouteName={ScreenNamesNative.DashboardNative}
        screenOptions={{
            headerShown: false
        }}
        tabBar={props => (
            <BottomBarNative {...props} />
        )}
    >
        <BottomTabs.Screen
            name={ScreenNamesNative.DashboardNative}
            component={DashboardScreen}
        />
        {/* <BottomTabs.Screen
            name={ScreenNamesNative.OrdersNative}
            component={OrdersScreen}
        /> */}
        <BottomTabs.Screen
            name={ScreenNamesNative.Bookings}
            component={BookingsScreen as FunctionComponent}
        />
        <BottomTabs.Screen
            name={ScreenNamesNative.User}
            component={UserScreen as FunctionComponent}
        />
        <BottomTabs.Screen
            name={ScreenNamesNative.SendPay}
            component={SendPayScreen}
        />
        <BottomTabs.Screen
            name={ScreenNamesNative.Favourite}
            component={FavouriteScreen}
        />
        <BottomTabs.Screen
            name={ScreenNamesNative.UserCards}
            component={UserCardsScreen}
        />
        <BottomTabs.Screen
            name={ScreenNamesNative.ContactUs}
            component={ContactUsScreen}
        />
        <BottomTabs.Screen
            name={ScreenNamesNative.AllServices}
        >
            {() => <ScreenRedirect to={ScreenNamesNative.DashboardNative} />}
        </BottomTabs.Screen>
        <BottomTabs.Screen
            name={ScreenNamesNative.UserNative}
        >
            {() => <ScreenRedirect to={ScreenNamesNative.User} />}
        </BottomTabs.Screen>
    </BottomTabs.Navigator>
)
