import { useStyles } from 'lib/hooks'
import { AlertSeverity } from '../types'

export const useAlertColors = (type: AlertSeverity) => {
    const { theme } = useStyles()

    switch (type) {
        case AlertSeverity.Error:
            return {
                backgroundColor: theme.colors.linen,
                text: theme.colors.strawberry
            }
        case AlertSeverity.Success:
            return {
                backgroundColor: theme.colors.mint,
                text: theme.colors.green
            }
        default:
            return {
                backgroundColor: theme.colors.yellow,
                text: theme.colors.orange
            }
    }
}
