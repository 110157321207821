import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const FormUser: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M36.294.9c-11.732 0-21.277 9.544-21.277 21.277 0 11.731 9.545 21.276 21.277 21.276 11.732 0 21.276-9.545 21.276-21.277C57.57 10.446 48.026.9 36.294.9Zm0 37.647c-9.027 0-16.37-7.344-16.37-16.37 0-9.027 7.343-16.371 16.37-16.371 9.026 0 16.37 7.344 16.37 16.37 0 9.027-7.344 16.371-16.37 16.371ZM62.713 57.03C56.896 51.18 49.185 47.96 41 47.96h-9.412c-8.185 0-15.896 3.222-21.712 9.072C4.088 62.85.9 70.537.9 78.669c0 1.343 1.1 2.43 2.453 2.43h65.882a2.442 2.442 0 0 0 2.453-2.43c0-8.132-3.188-15.817-8.975-21.638Zm3.956 19.208H5.92C7.144 63.12 18.189 52.82 31.589 52.82H41c13.399 0 24.444 10.3 25.67 23.418Z" />
    </Icon>
)
