import React from 'react'
import { View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Button } from '@propertyguru/hive-button'
import { Modal } from '@propertyguru/hive-modal'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { NativeModal } from 'lib/native/components'

type ResendCodeSuccessModalProps = {
    isOpen: boolean,
    title: string,
    content: string
    onClose(): void
}

export const ResendCodeSuccessModal: React.FunctionComponent<ResendCodeSuccessModalProps> = ({ isOpen, onClose, title, content }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <NativeModal fullWidth isOpen={isOpen} onClose={onClose}>
            <Modal>
                <Modal.Header>
                    <Modal.Header.Title>
                        {title}
                    </Modal.Header.Title>
                    <Modal.Header.Button onPress={onClose} />
                </Modal.Header>
                <Modal.Body>
                    <Text typography="body/m">
                        {content}
                    </Text>
                    <View style={styles.actionContainer}>
                        <Button onPress={onClose} type="primary" text={T.common.done} />
                    </View>
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    actionContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    }
}))
