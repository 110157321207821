import React from 'react'
import { View } from 'react-native'
import { CopyBookingId, Touchable, Typography } from 'lib/components'
import { useCopyToClipboard, useStyles, useTranslations } from 'lib/hooks'
import { Job, JobDetails } from 'lib/models'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'
import { JobDetailsResponse } from 'features/jobs/types'
import { NextSessionActions } from './NextSessionActions'

type NextSessionInfoProps = {
    job: Job,
    jobDetails?: JobDetails,
    nextSessionResponse?: JobDetailsResponse,
    sessionIndex: number,
    allSessionsCount: number
}

export const NextSessionInfo: React.FunctionComponent<NextSessionInfoProps> = ({
    job,
    jobDetails,
    sessionIndex,
    allSessionsCount,
    nextSessionResponse
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { onCopyPress } = useCopyToClipboard(T.common.copiedBookingIdMessage)
    const sessionTime = jobDetails?.num_hours
        ? `${dateHelpers.selectTimeLabel(jobDetails?.date_time || '')} - ${dateHelpers.selectTimeLabel(dateHelpers.addHoursToDate(jobDetails?.date_time || '', jobDetails?.num_hours))}`
        : ''
    const airconSessionTime = dateHelpers.selectTimeLabel(jobDetails?.date_time || new Date())
    const isAirconChildJob = isAirconSubscription(job)
    const nextResponseData = nextSessionResponse

    return (
        <React.Fragment>
            <View style={styles.headerContainer}>
                <View style={styles.titleAndTagContainer}>
                    <View style={styles.row}>
                        <Typography.Regular style={styles.sessionTitleText} bold>
                            {T.components.currentJobSession.title}
                        </Typography.Regular>
                        <Touchable
                            onPress={() => onCopyPress(job.job_id)}
                            style={styles.jobId}
                        >
                            <View style={styles.jobIdTextContainer}>
                                <Typography.Label forceColor={theme.colors.white} bold>
                                    {job.job_id}
                                </Typography.Label>
                            </View>
                        </Touchable>
                        <Typography.Label forceColor={theme.colors.fog}>
                            {`${T.components.currentJobSession.sessionIndexPrefix} ${sessionIndex + 1} ${T.components.currentJobSession.sessionIndexInfix} ${allSessionsCount}`}
                        </Typography.Label>
                    </View>
                </View>
                <View style={styles.copyButton}>
                    <CopyBookingId id={job.job_id} />
                </View>
            </View>
            <View style={styles.breakRow}>
                <View style={styles.flexedRow}>
                    <View style={styles.dateContainer}>
                        <Typography.Regular style={styles.jobDate} bold>
                            {dateHelpers.shortFullDate(job.date_time)}
                        </Typography.Regular>
                        {jobDetails?.date_time && (
                            <Typography.Label>
                                { isAirconChildJob ? airconSessionTime : `${sessionTime} (${jobDetails?.num_hours}${T.components.currentJobSession.hours})`}
                            </Typography.Label>
                        )}
                    </View>
                    <View>
                        {jobDetails && (
                            <Typography.Label style={styles.supplierText} forceColor={theme.colors.fog}>
                                {`${T.components.currentJobSession.expert}: ${jobDetails?.supplier?.handle ||'-'}`}
                            </Typography.Label>
                        )}
                    </View>
                </View>
                <View style={styles.flex}>
                    <NextSessionActions jobDetails={jobDetails} jobDetailsResponse={nextResponseData}/>
                </View>
            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    titleAndTagContainer: {
        flexDirection: {
            xl: 'row',
            lg: 'column'
        }
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(3)
    },
    copyButton: {
        marginRight: theme.utils.gap(1)
    },
    dateContainer: {
        flexDirection: 'row',
        gap: theme.utils.gap(1.5),
        alignItems: 'center'
    },
    row: {
        flexDirection: {
            xl: 'row',
            lg: 'column'
        },
        alignItems: {
            xl: 'center',
            lg: 'flex-start'
        },
        gap: theme.utils.gap(1.5)
    },
    flexedRow: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        justifyContent: {
            lg: `space-between`,
            xs: 'flex-start'
        },
        alignItems: {
            lg: 'center',
            xs: 'flex-start'
        }
    },
    flex: {
        flex: 1
    },
    breakRow: {
        justifyContent: 'space-evenly'
    },
    jobDate: {
        fontSize: theme.fontSize[18]
    },
    sessionTitleText: {
        fontSize: theme.fontSize[18]
    },
    jobId: {
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(1),
        borderRadius: theme.utils.gap(0.5),
        backgroundColor: theme.colors.night,
        height: theme.utils.gap(3)
    },
    jobIdTextContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1
    },
    supplierText: {
        marginTop: theme.utils.gap(1)
    }
}))
