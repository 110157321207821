import { APP_CONFIG } from 'lib/config'
import { useTranslations } from 'lib/hooks'
import { BookingFrequency } from 'lib/types'

export const useCookingFrequencyOptions = config => {
    const T = useTranslations()
    const pricePerHour = config.pricing.frequency.options.filter(item => item.frequency === 'onetime')[0].price
    const recurringPrice = config.pricing.frequency.options.filter(item => item.frequency === 'recurring')[0].price

    const oneTimeOption = {
        value: BookingFrequency.OneTime,
        label: T.components.frequency.oneTime.title,
        title: T.components.frequency.oneTime.title,
        description: T.components.frequency.oneTime.description,
        list: [
            `${T.components.frequency.options.startingFrom} ${pricePerHour.currency} ${pricePerHour.amount} ${pricePerHour.perUnit}`
        ]
    }

    const weeklyOption = {
        value: BookingFrequency.Weekly,
        label: T.components.frequency.weekly.title,
        title: T.components.frequency.weekly.title,
        description: T.components.frequency.weekly.description,
        list: [
            `${recurringPrice.currency} ${recurringPrice.amount} ${recurringPrice.perUnit}`,
            T.components.frequency.options.cancelAnyTime
        ]
    }

    const biWeeklyOption = {
        value: BookingFrequency.Fortnightly,
        label: T.components.frequency.fortnightly.title,
        title: T.components.frequency.fortnightly.title,
        description: T.components.frequency.fortnightly.description,
        list: [
            `${recurringPrice.currency} ${recurringPrice.amount} ${recurringPrice.perUnit}`,
            T.components.frequency.options.cancelAnyTime
        ]
    }

    if (APP_CONFIG?.DISABLE_SUBSCRIPTION_SERVICE?.COOKING) {
        return [oneTimeOption]
    }

    return [
        oneTimeOption,
        weeklyOption,
        biWeeklyOption
    ]
}
