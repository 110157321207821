import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'

type AirConditionServiceDetailsProps = {
    job: JobDetailsResponse
}

export const AirConditionServiceDetails: React.FunctionComponent<AirConditionServiceDetailsProps> = ({
    job
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.flex}>
            <View style={styles.wrapper}>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.sections.numberOfAirConditioners}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {job.job.num_air_con || job.job.numberOfUnits}
                    </Typography.Label>
                </View>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.sections.numberOfSessions}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {job.job?.numberOfSessions}
                    </Typography.Label>
                </View>
            </View>
            <View style={styles.wrapper}>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.sections.acType}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {job.job?.isDuctedAC ? T.screens.jobDetails.sections.ductedAC : '-'}
                    </Typography.Label>
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    cleaningDays: {
        textTransform: 'capitalize'
    }
}))
