import React from 'react'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Button } from '@propertyguru/hive-button'
import { RefreshRenewOutline } from '@propertyguru/hive-icons'

type RefreshButtonProps = {
    onPress: VoidFunction,
    size?: number,
    themeColor?: string
}

export const RefreshButton: React.FunctionComponent<RefreshButtonProps> = ({
    onPress,
    themeColor
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Button.Icon
            icon={RefreshRenewOutline}
            style={{
                ...styles.backButton,
                backgroundColor: themeColor ? themeColor : theme.colors.white,
                borderColor: themeColor ? themeColor : theme.colors.white
            }}
            onPress={onPress}
        />
    )
}

const stylesheet = createStyles(theme => ({
    backButton: {
        width: 58,
        height: 58,
        alignItems: 'center',
        borderWidth: 0,
        ...theme.utils.createShadow(0, 0, 0, 0, 0, theme.colors.webShadow(0.0))
    }
}))
