import { datadogRum } from '@datadog/browser-rum'
import { GoogleSignin } from '@react-native-google-signin/google-signin'
import { Segment } from 'lib/analytics'
import { useResetAtoms } from 'lib/atoms'
import { mmkvStorage } from 'lib/mmkv'
import { authUtils, cookieUtils } from 'features/auth'
import { mmkvStorage as nativeMmkvStorage } from '../mmkv'
import { useNativeResetAtoms } from '../atoms/useResetAtoms'

export const useNativeCleanApp = () => {
    const { resetAtoms } = useResetAtoms()
    const { resetAtoms: resetNativeAtoms } = useNativeResetAtoms()

    return {
        cleanPersonalData: () => {
            Segment.logout()
            datadogRum.clearUser()

            authUtils.clearAuthTokens()
            cookieUtils.removeAuthCookies()
            cookieUtils.removeMarketingConsentCookie()
            GoogleSignin.signOut()
            // TODO: sign out from social providers
            // todo how to check auth provider?
            // if (authProvider === AuthProvider.Facebook) {
            //     socialActions.logoutFromFacebook()
            //         .catch(R.T)
            // }

            resetAtoms()
            resetNativeAtoms()
            mmkvStorage.deleteAll()
            nativeMmkvStorage.deleteAll()
        }
    }
}
