import { JobDetails } from 'lib/models'
import { SupplyID } from 'lib/types'

export const useJobPrice = (job: JobDetails) => {
    if (job.deepcleaning_area) {
        return job.deepcleaning_area.price
    }

    if (job.supply.id === SupplyID.ACService && job.isSubscription) {
        return job?.total_cost || job?.supplierTotalCost || job?.consumerJobCostBreakdown?.total || job?.estimateCostBreakdown?.totalSubjectToDiscount || 0
    }

    return job?.total_cost || job?.bookingSummary?.total?.valueSubjectToDiscount
}
