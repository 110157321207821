import React from 'react'
import { View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Button } from '@propertyguru/hive-button'
import { Modal } from '@propertyguru/hive-modal'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { NativeModal } from 'lib/native/components'

type ErrorModalProps = {
    isOpen: boolean,
    title: string,
    message: string,
    onClose(): void
}

export const ErrorModal: React.FunctionComponent<ErrorModalProps> = ({ isOpen, onClose, title, message }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <NativeModal fullWidth isOpen={isOpen} onClose={onClose}>
            <Modal accessibilityLabel={T.accessibility.native.errorModal.container}>
                <Modal.Header>
                    <Modal.Header.Title accessibilityLabel={T.accessibility.native.errorModal.title}>
                        {title}
                    </Modal.Header.Title>
                    <Modal.Header.Button onPress={onClose} />
                </Modal.Header>
                <Modal.Body>
                    <Text typography="body/m" accessibilityLabel={T.accessibility.native.errorModal.message}>
                        {message}
                    </Text>
                    <View style={styles.actionContainer}>
                        <Button onPress={onClose} type="primary" accessibilityLabel={T.accessibility.native.errorModal.button} text={T.common.ok} />
                    </View>
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    actionContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    }
}))
