import React from 'react'
import { Dimensions, View } from 'react-native'
import Animated, { useAnimatedScrollHandler, useDerivedValue, useSharedValue } from 'react-native-reanimated'
import { useNavigation } from '@react-navigation/native'
// import { Button, Typography } from 'lib/components'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
// import { ScreenNames } from 'lib/routing'
// import { useOnboardingAtom } from 'lib/atoms'
import { useStyles, useTranslations } from 'lib/hooks'
import { Images } from 'assets'
import { LandingUspPage, LandingPaginationBar, LoginSignUpButtons } from '../components'
import { SocialButtonsNative } from 'features/native/auth/components'
import { ScreenNames } from 'lib/routing'
import { useGoogleSignIn, useSocialSignIn } from 'features/native/auth/hooks'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export type Page = {
    image: Images,
    title: string,
    message: string
}

export const LandingScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const animatedX = useSharedValue(0)
    const insets = useSafeAreaInsets()
    const { onPress: onGoogleSignInClick } = useGoogleSignIn(() => { })
    const { signInWithFacebook, signInWithApple } = useSocialSignIn()
    const activeIndex = useDerivedValue(() => Math.round(animatedX.value / Measurements.WindowWidth))
    const scrollHandler = useAnimatedScrollHandler(event => {
        animatedX.value = event.contentOffset.x
    })

    const pages: Array<Page> = [
        { image: Images.LandingUspConvenience, title: T.screens.onboarding.content.convenience.title, message: T.screens.onboarding.content.convenience.message },
        { image: Images.LandingUspSafeguarding, title: T.screens.onboarding.content.safeguard.title, message: T.screens.onboarding.content.safeguard.message },
        { image: Images.LandingUspHome, title: T.screens.onboarding.content.worthIt.title, message: T.screens.onboarding.content.worthIt.message }
    ]

    const onLoginClick = () => {
        navigation.navigate(ScreenNames.LogInNative)
    }

    const onSignUpClick = () => {
        navigation.navigate(ScreenNames.SignUpNative)
    }

    return (
        <View style={styles.wrapper}>
            <Animated.View style={{ ...styles.carouselContainer, paddingTop: insets.top }}>
                <View style={styles.carouselContent}>
                    <View style={styles.pagination}>
                        {[...new Array(pages.length)].map((item, index) => (
                            <LandingPaginationBar
                                key={index}
                                index={index}
                                activeIndex={activeIndex}
                            />
                        ))}
                    </View>
                    <Animated.View style={[styles.carousel]}>
                        <Animated.ScrollView
                            horizontal
                            pagingEnabled
                            scrollEventThrottle={16}
                            onScroll={scrollHandler}
                            showsHorizontalScrollIndicator={false}
                        >
                            {pages.map((page, index) => (
                                <View style={styles.carouselItem} key={index}>
                                    <LandingUspPage
                                        page={page}
                                        index={index}
                                        activeIndex={activeIndex}
                                    />
                                </View>
                            ))}
                        </Animated.ScrollView>
                    </Animated.View>
                </View>
            </Animated.View>

            <View style={styles.actionsContainer}>
                <LoginSignUpButtons onLoginClick={onLoginClick} onSignUpClick={onSignUpClick} />

                <View style={styles.socialSignUpContainer}>
                    <SocialButtonsNative
                        prefixText={T.screens.auth.socialSignInLabelExtended}
                        onContinueWithGoogle={onGoogleSignInClick}
                        onContinueWithFacebook={signInWithFacebook}
                        onContinueWithApple={signInWithApple}
                    />
                </View>
            </View>

        </View>
    )
}

const screenHeight = Dimensions.get('screen').height
const screenWidth = Dimensions.get('screen').width
const carouselHeight = 0.75 * screenHeight

const stylesheet = createStyles(theme => ({
    carouselContainer: {
        height: carouselHeight,
        width: '100%',
        backgroundColor: theme.colors.yellow,
        borderBottomRightRadius: 60,
        overflow: 'hidden'
    },
    carouselContent: {
        height: carouselHeight,
        justifyContent: 'center',
        alignItems: 'center'
    },
    carouselItem: {
        width: screenWidth,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 450
    },
    wrapper: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    carousel: {
        maxHeight: 450
    },
    pagination: {
        flexDirection: 'row',
        justifyContent: 'center',
        height: 30
    },
    socialSignUpContainer: {
        marginTop: theme.utils.gap(2)
    },
    actionsContainer: {
        flex: 1,
        justifyContent: 'center'
    }
}))
