// @ts-nocheck
// noinspection ALL

// This file is generated by svgr

import React, { memo } from 'react'
import Svg, { Path, type SvgProps } from 'react-native-svg'
const SvgFacebook = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            fill="#1877F2"
            d="M22.5 12c0-5.523-4.477-10-10-10s-10 4.477-10 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.093 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.563V12h2.773l-.443 2.89h-2.33v6.988c4.78-.75 8.437-4.887 8.437-9.878Z"
        />
        <Path
            fill="#fff"
            d="m16.389 14.89.443-2.89H14.06v-1.875c0-.79.388-1.563 1.63-1.563h1.26v-2.46s-1.144-.196-2.238-.196c-2.284 0-3.777 1.385-3.777 3.89V12h-2.54v2.89h2.54v6.988c1.035.163 2.09.163 3.125 0v-6.987h2.33Z"
        />
    </Svg>
)
const Memo = memo(SvgFacebook)
export default Memo
