import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const TrendsDownward: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path fillRule="evenodd" clipRule="evenodd" d="M9.8 10.75C9.8 10.9985 10.0015 11.2 10.25 11.2L13.55 11.2C13.7985 11.2 14 10.9985 14 10.75L14 7.44995C14 7.20142 13.7985 6.99995 13.55 6.99995C13.3015 6.99995 13.1 7.20142 13.1 7.44995L13.1 9.65755L9.7665 6.32883C9.122 5.68526 8.07792 5.68563 7.43388 6.32967L6.73033 7.03323C6.43744 7.32612 5.96256 7.32612 5.66967 7.03322L2.7682 4.13175C2.59246 3.95602 2.30754 3.95602 2.1318 4.13175C1.95607 4.30749 1.95607 4.59241 2.1318 4.76815L5.03327 7.66962C5.67764 8.31399 6.72236 8.31399 7.36673 7.66962L8.07028 6.96607C8.36302 6.67332 8.8376 6.67315 9.13056 6.96569L12.4696 10.3L10.25 10.3C10.0015 10.3 9.8 10.5014 9.8 10.75Z" fill="#07A35D"/>
    </Icon>
)
