import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

type SubscriptionBenefitProps = {
    amount: number
}

export const RemainingPriceAlert: React.FunctionComponent<SubscriptionBenefitProps> = ({
    amount
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View>
                <Icons.Info size={20} forceColor={theme.colors.purple} />
            </View>
            <View style={styles.message}>
                <Typography.Regular forceColor={theme.colors.purple}>
                    {T.screens.deepCleaning.formFields.services.summaryMessage.youAre}
                    <Typography.Regular forceColor={theme.colors.purple} bold>
                        {T.common.currency} {amount}
                    </Typography.Regular>
                    <Typography.Regular forceColor={theme.colors.purple}>
                        {T.screens.deepCleaning.formFields.services.summaryMessage.awayFromBooking}
                    </Typography.Regular>
                </Typography.Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        padding: theme.utils.gap(1),
        backgroundColor: theme.colors.lightPurple,
        marginBottom: theme.utils.gap(2.5),
        flexDirection: 'row',
        gap: theme.utils.gap(1),
        borderRadius: 8
    },
    message: {
        flexDirection: 'row',
        flex: 1
    }
}))
