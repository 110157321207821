import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { Breakpoint, createStyles } from 'lib/styles'
import { useAutoApplyPromoAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { AppLayout, BasicModal, Grid, Touchable, Typography } from 'lib/components'
import { linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { cookieUtils } from 'features/auth'
import { updatePromotionalNotifications } from 'features/user/actions'
import { useAllServices } from '../hooks'
import { ServiceTile, MobileServiceTile, StoresModalBody } from '../components'

export const AllServicesScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const route = useRoute<NavigationParams<ScreenNames.AllServices>>()

    const services = useAllServices()
    const isNotMobile = useIsWithinBreakpoints(Breakpoint.SM)
    const [isStoresModalVisible, setStoresModalVisibility] = useState(false)
    const [user, setUser] = useUserAtom()
    const [token] = useUserTokenAtom()
    const { mutate: onMarketingConsent } = updatePromotionalNotifications()
    const [, setAutoApplyPromo] = useAutoApplyPromoAtom()

    const onUpdatePromotionalNotifications = () => {
        onMarketingConsent({
            token,
            accepted: true
        },
        {
            onSuccess: () => {
                setUser(prevValue => ({
                    ...prevValue,
                    consumer: {
                        ...prevValue?.consumer,
                        marketing_consent: {
                            ...prevValue?.consumer?.marketing_consent,
                            accepted: true
                        }
                    }
                }))

                cookieUtils.setMarketingConsentAcceptedCookie(true)

                if(!user?.bookingCount) {
                    setAutoApplyPromo(route.params?.promoCode || T.components.promotionalModal.discountCode)
                }
            }
        })
    }

    useEffect(() => {
        if (route.params?.marketingConsent?.toString() === 'true') {
            onUpdatePromotionalNotifications()
        }
    }, [])

    return (
        <AppLayout
            renderSideMenu={false}
            disableUserMenu={false}
            customContainerStyles={styles.layout}
        >
            <View style={styles.container}>
                <Grid.Gap
                    gapTop={4}
                    gapBottom={4}
                    style={styles.heading}
                >
                    <Typography.Subheading>
                        {T.screens.allServices.pageTitle}
                    </Typography.Subheading>
                </Grid.Gap>
                <View style={styles.row}>
                    {services.map((service, index) => isNotMobile
                        ? (
                            <ServiceTile
                                key={index}
                                isLast={index === services.length - 1}
                                {...service}
                            />
                        ) : (
                            <MobileServiceTile
                                key={index}
                                onShowStoresModal={() => setStoresModalVisibility(true)}
                                isLast={index === services.length - 1}
                                {...service}
                            />
                        )
                    )}
                </View>
            </View>
            <View style={styles.row}>
                <Typography.Regular style={styles.note}>
                    {T.screens.allServices.note.question}
                </Typography.Regular>
                <Touchable onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contacts`)}>
                    <Typography.Regular
                        bold
                        forceColor={theme.colors.sun}
                    >
                        {` ${T.screens.allServices.note.contactUs}`}
                    </Typography.Regular>
                </Touchable>
            </View>
            <BasicModal
                wrapperStyles={styles.modal}
                isOpen={isStoresModalVisible}
                onClose={() => setStoresModalVisibility(false)}
            >
                <StoresModalBody />
            </BasicModal>
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    layout: {
        alignItems: 'center'
    },
    container: {
        maxWidth: {
            lg: 1024,
            sm: undefined
        },
        marginBottom: theme.utils.gap(4)
    },
    row: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: {
            xs: theme.utils.gap(1),
            md: theme.utils.gap(2)
        },
        marginLeft: {
            xs: theme.utils.gap(1),
            md: theme.utils.gap(2)
        }
    },
    note: {
        textAlign: 'center',
        color: theme.colors.softBlueGrey
    },
    modal: {
        width: '100%',
        justifyContent: 'center',
        alignItems: undefined
    },
    heading: {
        marginLeft: {
            lg: undefined,
            xs: theme.utils.gap(1),
            sm: theme.utils.gap(2)
        }
    }
}))
