import { useMutation } from '@tanstack/react-query'
import { useExternalFetcher, useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { useIsAuthorizedAtom } from 'lib/atoms'
import {
    CreateJobRequest,
    CreateDeepCleaningJobRequest,
    CreatePestControlJobRequest,
    CreateMoversAndPackersJobRequest,
    CreateJobResponse,
    GetLaundryPriceListRequest,
    GetLaundryPriceListResponse,
    GetLaundryTimeSlotsRequest,
    GetLaundryTimeSlotsResponse,
    GetPromotionRequest,
    GetPromotionResponse,
    GetServicePackageRequest,
    GetServicePackageResponse,
    JobQuotationResponse,
    CreateLaundryJobRequest,
    CreateTaskAndErrandsJobRequest,
    CreateAirconJobRequest,
    CreateHandymanJobRequest,
    CreateCleaningJobRequest,
    CreateCookingJobRequest,
    CreateCleaningGuestJobRequest,
    CreateGuestJobResponse,
    DynamicPriceResponse,
    DynamicPriceRequest
} from './types'
import { APP_CONFIG } from 'lib/config'

export const getServicePackage = () => {
    const [isAuthorized] = useIsAuthorizedAtom()
    const fetcher = useFetcher<GetServicePackageResponse>(
        HttpMethod.POST,
        isAuthorized
            ? '/job/get_package_applicable'
            : '/consumer-estimate-booking-cost'
    )

    return useMutation<Response<GetServicePackageResponse>, ErrorResponse, GetServicePackageRequest>(fetcher)
}

export const getPromotion = () => {
    const fetcher = useFetcher<GetPromotionResponse>(HttpMethod.POST, '/promotion/get_promotion_applicable')

    return useMutation<Response<GetPromotionResponse>, ErrorResponse, GetPromotionRequest>(fetcher)
}

export const getBestDeals = () => {
    const fetcher = useExternalFetcher(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/promotions/best-deals`)

    return useMutation(fetcher)
}

export const getAvailableServices = () => {
    const fetcher = useExternalFetcher(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/available`)

    return useMutation(fetcher)
}

export const getCleaningJobQuotation = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/cleaning/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedCleaningJobQuotation = () => {
    const fetcher = useFetcher<JobQuotationResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/cleaning/quotations`)

    return useMutation(fetcher)
}

export const getCookingJobQuotation = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/cooking/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedCookingJobQuotation = () => {
    const fetcher = useFetcher<JobQuotationResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/cooking/quotations`)

    return useMutation(fetcher)
}

export const getDeepCleaningJobQuotation = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/deep-cleaning/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedDeepCleaningJobQuotation = () => {
    const fetcher = useFetcher<JobQuotationResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/deep-cleaning/quotations`)

    return useMutation(fetcher)
}

export const getAirconJobQuotation = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/aircon/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedAirconJobQuotation = () => {
    const fetcher = useFetcher<JobQuotationResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/aircon/quotations`)

    return useMutation(fetcher)
}

export const getPestControlJobQuotation = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/pest-control/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedPestControlJobQuotation = () => {
    const fetcher = useFetcher<JobQuotationResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/pest-control/quotations`)

    return useMutation(fetcher)
}

export const getHandymanJobQuotation = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/handyman/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedHandymanJobQuotation = () => {
    const fetcher = useFetcher<JobQuotationResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/handyman/quotations`)

    return useMutation(fetcher)
}

export const getTaskErrandsJobQuotation = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/tasks-and-errands/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedTaskErrandsJobQuotation = () => {
    const fetcher = useFetcher<JobQuotationResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/tasks-and-errands/quotations`)

    return useMutation(fetcher)
}

export const getLaundryJobQuotation = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/laundry/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedLaundryJobQuotation = () => {
    const fetcher = useFetcher<JobQuotationResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/laundry/quotations`)

    return useMutation(fetcher)
}

export const getMoversAndPackersJobQuotation = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/movers-and-packers/quotations`)

    return useMutation(fetcher)
}

export const getAuthorizedMoversAndPackersJobQuotation = () => {
    const fetcher = useFetcher<JobQuotationResponse>(HttpMethod.POST, `${APP_CONFIG.BOOKING_SERVICES.URL}/movers-and-packers/quotations`)

    return useMutation(fetcher)
}

export const createJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, '/consumer-create-job')

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateJobRequest>(fetcher)
}

export const getLaundryTimeSlots = () => {
    const fetcher = useFetcher<GetLaundryTimeSlotsResponse>(HttpMethod.POST, '/consumer-get-laundry-time-slots')

    return useMutation<Response<GetLaundryTimeSlotsResponse>, ErrorResponse, GetLaundryTimeSlotsRequest>(fetcher)
}

export const getLaundryPriceList = () => {
    const fetcher = useFetcher<GetLaundryPriceListResponse>(HttpMethod.POST, '/consumer-get-laundry-price-list')

    return useMutation<Response<GetLaundryPriceListResponse>, ErrorResponse, GetLaundryPriceListRequest>(fetcher)
}

export const createDeepCleaningJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-deep-cleaning-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateDeepCleaningJobRequest>(fetcher)
}

export const createPestControlJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-pest-control-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreatePestControlJobRequest>(fetcher)
}

export const createLaundryJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-laundry-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateLaundryJobRequest>(fetcher)
}

export const createTaskAndErrandsJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-tasks-and-errands-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateTaskAndErrandsJobRequest>(fetcher)
}

export const createHandymanJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-handyman-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateHandymanJobRequest>(fetcher)
}

export const createMoversAndPackersJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-movers-and-packers-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateMoversAndPackersJobRequest>(fetcher)
}

export const createCleaningJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-cleaning-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateCleaningJobRequest>(fetcher)
}

export const createGuestCleaningJob = () => {
    const fetcher = useExternalFetcher(HttpMethod.POST, `/guest-book-cleaning-job`)

    return useMutation<Response<CreateGuestJobResponse>, ErrorResponse, CreateCleaningGuestJobRequest>(fetcher)
}

export const createAirconJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-aircon-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateAirconJobRequest>(fetcher)
}

export const createAirconSubscriptionJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-aircon-subscription`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateAirconJobRequest>(fetcher)
}

export const createCookingJob = () => {
    const fetcher = useFetcher<CreateJobResponse>(HttpMethod.POST, `/consumer-book-cooking-job`)

    return useMutation<Response<CreateJobResponse>, ErrorResponse, CreateCookingJobRequest>(fetcher)
}

export const getCleaningDynamicPrice = () => {
    const fetcher = useExternalFetcher<DynamicPriceResponse>(HttpMethod.GET, `${APP_CONFIG.BOOKING_SERVICES.URL}/cleaning/dynamic-pricing-slots`)

    return useMutation<Response<DynamicPriceResponse>, ErrorResponse, DynamicPriceRequest>(fetcher)
}
