import React from 'react'
import { useHive } from '@propertyguru/hive'
import { Icon, Svg } from '@propertyguru/hive-icon'
import { Touchable } from 'lib/components/Touchable'

type ScreenHeaderButtonProps = {
    icon: Svg,
    onPress?: () => void
}

export const ScreenHeaderButton: React.FunctionComponent<ScreenHeaderButtonProps> = ({ icon, onPress }) => {
    const hive = useHive()

    return (
        <Touchable
            style={{ padding: hive.spacing('x2') }}
            onPress={onPress}
        >
            <Icon icon={icon} />
        </Touchable>
    )
}
