import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useDynamicPriceSelectedAtom } from 'lib/atoms'
import { Typography } from 'lib/components'
import { isNative } from 'lib/common'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { ConsumerJobCostBreakdown } from 'lib/models/Job'
import { ReceiptSummary } from 'features/jobs/types'
import {
    Discount,
    JobQuotationTotal,
    JobQuotationTotalSubscription,
    PriceSummary
} from '../types'
import { ServiceType } from 'lib/analytics'
import { PriceSummaryDetails } from './PriceSummaryDetails'
import { DynamicPriceSummaryDetails } from './DynamicPriceSummaryDetails'

type PriceSummaryComponentProps = {
    priceSummary?: PriceSummary,
    promotion?: Discount,
    total?: JobQuotationTotal,
    totalSubscription?: JobQuotationTotalSubscription,
    showPayBySessionForJobDetail?: boolean
    consumerJobCostBreakdown?: ConsumerJobCostBreakdown,
    receiptSummary?: Array<ReceiptSummary>,
    serviceType?: ServiceType
}

export const PriceSummaryComponent: React.FunctionComponent<PriceSummaryComponentProps> =
    ({
        priceSummary,
        promotion,
        total,
        showPayBySessionForJobDetail,
        consumerJobCostBreakdown,
        receiptSummary,
        serviceType
    }) => {
        const { styles } = useStyles(stylesheet)
        const T = useTranslations()
        const [dynamicPriceSelected] = useDynamicPriceSelectedAtom()
        const formattedDiscountAmount = showPayBySessionForJobDetail ?
            usePrice(consumerJobCostBreakdown?.discount || 0) :
            usePrice(promotion?.value || 0)

        const Discount = () => formattedDiscountAmount && promotion && (
            <View
                style={styles.summaryRow}
            >
                <View style={{
                    ...styles.labelWrapper,
                    ...styles.widthNone
                }}>
                    <Typography.Label>
                        {T.components.bookingSummary.discountCode} :
                        <Typography.Label bold style={styles.discountName}> {promotion?.code}</Typography.Label>
                    </Typography.Label>
                </View>
                <Typography.Label
                    bold
                    style={{
                        ...styles.value,
                        ...styles.discountValue
                    }}
                >
                    {showPayBySessionForJobDetail && formattedDiscountAmount ? '-': ''}{formattedDiscountAmount || '-'}
                </Typography.Label>
            </View>
        )

        const shouldRenderDynamicPriceSummary =
            isNative &&
            Boolean(dynamicPriceSelected?.slotId)
            && serviceType === ServiceType.Cleaning

        const PriceSummaryElement = shouldRenderDynamicPriceSummary ? DynamicPriceSummaryDetails : PriceSummaryDetails

        return (
            <View style={showPayBySessionForJobDetail ? styles.spaceBetweenWithReverse : styles.spaceBetween}>
                <PriceSummaryElement
                    receiptSummary={receiptSummary}
                    priceSummary={priceSummary}
                    total={total}
                    showPayBySessionForJobDetail={showPayBySessionForJobDetail}
                    consumerJobCostBreakdown={consumerJobCostBreakdown}  />
                <Discount/>
            </View>
        )
    }

const stylesheet = createStyles(theme => ({
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(2)
    },
    labelWrapper: {
        marginRight: theme.utils.gap(0.5)
    },
    discountName: {
        textTransform: 'uppercase'
    },
    discountValue: {
        color: theme.colors.flame
    },
    value: {
        textAlign: 'right',
        overflow: 'visible'
    },
    spaceBetween: {
        justifyContent: 'space-between',
        width: '100%'
    },
    spaceBetweenWithReverse: {
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'column-reverse'
    },
    widthNone: {
        width: undefined
    }
}))
