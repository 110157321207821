import React, { FunctionComponent } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { NativeAppStackParams } from 'lib/native/routing/stackParams'
import {
    AddressSetupScreen,
    UserAddressScreen,
    UserEditAddressScreen,
    UserDeleteAccountScreen,
    UserNewAddressScreen,
    UserPreferencesScreen
} from 'features/user'
import { UnreadNotificationsScreen } from 'features/notifications'
import { ChatScreen } from 'features/chat'
import { UserCardsNewScreen } from 'features/payments'
import {
    AirConditionDetailsScreen,
    BookingsCancelledScreen,
    BookingsCompletedScreen,
    CancelJobScreen,
    CleaningDetailsScreen,
    CookingDetailsScreen,
    DeepCleaningDetailsScreen,
    HandymanDetailsScreen,
    LaundryDetailsScreen,
    MoversAndPackersDetailsScreen,
    PestControlDetailsScreen,
    TaskAndErrandsDetailsScreen
} from 'features/jobs'
import { HomeScreen } from 'features/native/home'
import { GrabPayWebViewScreen } from 'features/sendPay'
import { ScreenRedirect } from 'lib/native/components'

const Stack = createNativeStackNavigator<NativeAppStackParams>()

export const SharedStack = () => (
    <React.Fragment>
        <Stack.Screen
            name={ScreenNamesNative.NativeHome}
            component={HomeScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.UserDeleteAccount}
            component={UserDeleteAccountScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.UserAddress}
            component={UserAddressScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.AddressSetup}
            component={AddressSetupScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.UserNewAddress}
            component={UserNewAddressScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.UserEditAddress}
            component={UserEditAddressScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.UserPreferences}
            component={UserPreferencesScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.UserCardsNew}
            component={UserCardsNewScreen as FunctionComponent}
        />
        {/* todo for now not available */}
        {/* <Stack.Screen */}
        {/*     name={ScreenNames.UserCardsHistory} */}
        {/*     component={PaymentHistory} */}
        {/*     options={{ */}
        {/*         title: T.screens.paymentAndCards.pageTitle */}
        {/*     }} */}
        {/* /> */}
        {/* <Stack.Screen */}
        {/*     name={ScreenNames.OffersAndDeals} */}
        {/*     component={OffersAndDeals} */}
        {/*     options={{ */}
        {/*         title: T.screens.offersAndDeals.pageTitle */}
        {/*     }} */}
        {/* /> */}
        <Stack.Screen
            name={ScreenNamesNative.AllServicesPostcode}
        >
            {() => <ScreenRedirect to={ScreenNamesNative.DashboardNative} />}
        </Stack.Screen>
        <Stack.Screen
            name={ScreenNamesNative.BookingsCompleted}
            component={BookingsCompletedScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.BookingsCancelled}
            component={BookingsCancelledScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.Chat}
            component={ChatScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.UnreadNotifications}
            component={UnreadNotificationsScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.CleaningDetails}
            component={CleaningDetailsScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.DeepCleaningDetails}
            component={DeepCleaningDetailsScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.AirConditionDetails}
            component={AirConditionDetailsScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.LaundryDetails}
            component={LaundryDetailsScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.CookingDetails}
            component={CookingDetailsScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.HandymanDetails}
            component={HandymanDetailsScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.PestControlDetails}
            component={PestControlDetailsScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.TaskAndErrandsDetails}
            component={TaskAndErrandsDetailsScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.MoversAndPackersDetails}
            component={MoversAndPackersDetailsScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.CancelJob}
            component={CancelJobScreen as FunctionComponent}
        />
        <Stack.Screen
            name={ScreenNamesNative.GrabPayWebView}
            component={GrabPayWebViewScreen as FunctionComponent}
        />
    </React.Fragment>
)
