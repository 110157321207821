import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type Props = {
    children: ReactNode,
}

export const FooterBanner = ({ children }: Props) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={{
            ...styles.container,
            paddingVertical: hive.spacing('x3'),
            paddingHorizontal: hive.spacing('x4'),
            backgroundColor: hive.color('fill/success/secondary')
        }}>
            {children}
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}))
