import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Location: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M40.62 13.333c6.433 0 16.666 4.667 16.666 17.167 0 7.2-5.733 15.567-16.666 24.4-10.934-8.833-16.667-17.233-16.667-24.4 0-12.5 10.233-17.167 16.667-17.167Zm0-6.666c-10.9 0-23.334 8.2-23.334 23.833 0 10.4 7.767 21.367 23.334 32.833C56.186 51.867 63.953 40.9 63.953 30.5c0-15.633-12.434-23.833-23.334-23.833Z" />
        <Path d="M40.62 23.333c-3.667 0-6.667 3-6.667 6.667s3 6.667 6.666 6.667a6.667 6.667 0 0 0 0-13.334ZM17.286 66.667h46.667v6.666H17.286v-6.666Z" />
    </Icon>
)
