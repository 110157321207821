/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react'

export const useWhyDidYouUpdate = (name: string, props: Record<string, any>) => {
    const previousProps = useRef<Record<string, any>>({})

    useEffect(() => {
        const previous = previousProps.current
        const allKeys = Object.keys({ ...previous, ...props })
        const changesObj: Record<string, { from: any; to: any }> = {}

        allKeys.forEach(key => {
            if (previous[key] !== props[key]) {
                const fromValue = typeof previous[key] === 'function'
                    ? previous[key].toString()
                    : JSON.stringify(previous[key])
                const toValue = typeof props[key] === 'function'
                    ? props[key].toString()
                    : JSON.stringify(props[key])

                changesObj[key] = {
                    from: fromValue,
                    to: toValue
                }
            }
        })

        if (Object.keys(changesObj).length) {
            console.log('[useWhyDidYouUpdate]', name, changesObj)
        }

        previousProps.current = props
    })
}
