import { NativeJobSchedule } from 'lib/types'
import { dateHelpers } from 'lib/utils'

export const getSelectedSchedule = (schedule?: Array<NativeJobSchedule>) => schedule?.filter(({ activated }) => activated).map(schedule => ({
    day: schedule.day,
    time: schedule?.ui_start_time
}))

const scheduleDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

// TODO: remove this implementation, either add backend support or replace completely
export const getMappedScheduleDays = (schedule): Array<NativeJobSchedule> => schedule?.map((schedule, dayIndex) => ({ ...schedule, day: scheduleDays[dayIndex], start_time: dateHelpers.formatDateWithDateFns(schedule.start_time) }))
