import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { NativeAppStackParams } from 'lib/native/routing/stackParams'
import { GetStartedScreen, MarketingConsentScreen } from 'features/native/onboarding/screens'

const Stack = createNativeStackNavigator<NativeAppStackParams>()

export const OnboardingStack = () => (
    <React.Fragment>
        <Stack.Screen
            name={ScreenNamesNative.NativeGetStarted}
            component={GetStartedScreen}
        />
        <Stack.Screen
            name={ScreenNamesNative.MarketingConsentNative}
            component={MarketingConsentScreen}
        />
    </React.Fragment>
)

