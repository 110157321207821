import React, { useEffect, useState } from 'react'
import { Dimensions, ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
    BusinessShopStoreFill,
    BusinessShopStoreOutline,
    HomeDoorHouseFill,
    HomeDoorHouseOutline,
    MapFill,
    MapOutline
} from '@propertyguru/hive-icons'
import { Modal } from '@propertyguru/hive-modal'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { AddressChipBox, NativeModal } from 'lib/native/components'
import { useNativeUserAtom } from 'lib/native/atoms'
import { AddressPropertyType } from 'lib/native/types'
import { Address } from 'lib/native/models'
import { ScreenNamesNative } from 'lib/native/routing/screens'

type Props = {
    isOpen: boolean
    onClose: () => void,
    onSelectAddress?: (address: Address | undefined) => void
}

export const SelectAddressModal: React.FunctionComponent<Props> = ({ isOpen, onClose, onSelectAddress }) => {
    const T = useTranslations()
    const insets = useSafeAreaInsets()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [user] = useNativeUserAtom()
    const locations = user?.locations || []
    const primaryAddress = locations.find(location => location.isPrimary)
    const firstLocation = locations?.[0]
    const defaultAddress = primaryAddress?._id ? primaryAddress : firstLocation
    const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(defaultAddress)

    const handleAddressPress = (address: Address | undefined) => {
        setSelectedAddress(address)
    }

    const continueOnPress = () => {
        onSelectAddress && onSelectAddress(selectedAddress)
        onClose()
        navigation.navigate(ScreenNamesNative.CleaningPropertyDetailsNative)
    }

    useEffect(() => {
        onSelectAddress && onSelectAddress(selectedAddress)
    }, [selectedAddress?._id])

    return (
        <NativeModal fullWidth isOpen={isOpen} scrollViewProps={{ scrollEnabled: false }} onClose={onClose}>
            <Modal>
                <Modal.Header>
                    <Modal.Header.Title>
                        {T.components.selectAddressModal.title}
                    </Modal.Header.Title>
                    <Modal.Header.Button onPress={onClose} />
                </Modal.Header>
                <Modal.Body>
                    <ScrollView style={{
                        maxHeight: Dimensions.get('window').height - insets.top - insets.bottom - 180
                    }}>
                        <View style={styles.main}>
                            <View style={styles.header}>
                                <Text typography="label/m" color="text/active/primary">
                                    {T.components.selectAddressModal.description}
                                </Text>
                            </View>
                            {locations.map((item, index) => {
                                const isCommercial = item.propertyDetails?.type === AddressPropertyType.Commercial
                                const unitText = item.unit ? ` #${item.unit}` : ''

                                return (
                                    <AddressChipBox
                                        key={index}
                                        active={selectedAddress?._id === item._id}
                                        text={`${item.address}${unitText}`}
                                        icon={isCommercial ? BusinessShopStoreOutline : HomeDoorHouseOutline}
                                        iconActive={isCommercial ? BusinessShopStoreFill : HomeDoorHouseFill}
                                        badgeProps={item.isPrimary ? {
                                            label: T.components.selectAddressModal.badgeLabelDefault,
                                            type: 'primary',
                                            color: 'orange',
                                            size: 'small'
                                        } : undefined}
                                        onPress={() => handleAddressPress(item)}
                                    />

                                )
                            })}
                            <AddressChipBox
                                active={!selectedAddress}
                                text={T.components.selectAddressModal.useNewLocation}
                                icon={MapOutline}
                                iconActive={MapFill}
                                onPress={() => handleAddressPress(undefined)}

                            />
                        </View>
                    </ScrollView>
                    <View style={styles.footer}>
                        <Button text={T.components.selectAddressModal.ctaText} onPress={continueOnPress} />
                    </View>
                </Modal.Body>
            </Modal>
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    main: {
        rowGap: theme.utils.hive.spacing('x3')
    },
    footer: {
        paddingTop: theme.utils.hive.spacing('x3')
    }
}))
