import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const MailFilled: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M70.714 11H8.746c-4.26 0-7.707 3.268-7.707 7.262L1 61.833c0 3.994 3.486 7.262 7.746 7.262h61.968c4.26 0 7.746-3.268 7.746-7.262V18.262c0-3.994-3.486-7.262-7.746-7.262Zm0 50.833H8.746v-36.31L39.73 43.679l30.984-18.154v36.31Z" />
    </Icon>
)
