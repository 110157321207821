import React from 'react'
import { View } from 'react-native'
import { BasicModal } from 'lib/components'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles } from 'lib/hooks'
import { MoversAndPackersAddOnPricingContent } from './MoversAndPackersAddOnPricingContent'

type MoverAndPackersAddOnPricingProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    tabIndex: number
}

export const MoverAndPackersAddOnPricing: React.FunctionComponent<MoverAndPackersAddOnPricingProps> = ({
    isOpen,
    onClose,
    tabIndex
}) => {
    const { styles } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    return (
        <View style={styles.container}>
            <BasicModal
                isFullWindowSize={isMobile}
                isOpen={isOpen}
                onClose={onClose}
            >
                <MoversAndPackersAddOnPricingContent tabIndex={tabIndex} />
            </BasicModal>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        marginVertical: theme.utils.gap(1)
    },
    row: {
        flexDirection: 'row'
    },
    text: {
        borderBottomWidth: 1,
        borderColor: theme.colors.orange,
        marginRight: theme.utils.gap(1)
    }
}))
