import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useBackHandler, useStyles, useTranslations } from 'lib/hooks'
import { Grid, Typography } from 'lib/components'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { AuthLayout, CancelButton, SignUpForm } from '../components'
import { SignUpFormShape } from '../forms'
import { useFinishSocialSignUp } from '../hooks'

type AuthFinishSocialSignUpScreenProps = {
    navigation: NavigationProps<ScreenNames.AuthFinishSocialSignUp>,
    route: NavigationParams<ScreenNames.AuthFinishSocialSignUp>
}

export const AuthFinishSocialSignUpScreen: React.FunctionComponent<AuthFinishSocialSignUpScreenProps> = ({ route }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { providerDetails } = route.params
    const { submit, isLoading, isFilled, hasError, form } = useFinishSocialSignUp(route.params)
    const socialPlatformName = `${providerDetails.charAt(0).toUpperCase()}${providerDetails.slice(1)}`

    useBackHandler(() => isLoading)

    return (
        <AuthLayout
            title={T.screens.auth.welcomeBack}
            message={T.screens.auth.welcomeMessage}
        >
            <Grid.Gap
                gapTop={4}
                style={styles.titleContainer}
            >
                <Typography.SmallSubheading style={styles.heading}>
                    {`${T.screens.authFinishSocialSignUp.pageTitle} ${T.common.with} ${socialPlatformName}`}
                </Typography.SmallSubheading>
            </Grid.Gap>
            <Grid.Gap
                gapTop={4}
                gapBottom={2}
            >
                <SignUpForm
                    isLoading={isLoading}
                    emailDisabled
                    onSubmit={submit}
                    providerDetails={providerDetails}
                    formHasError={!isFilled || hasError}
                    form={form as Record<keyof SignUpFormShape, Field<string & boolean>>}
                />
            </Grid.Gap>
            <CancelButton isDisabled={isLoading} />
        </AuthLayout>
    )
}

const stylesheet = createStyles(() => ({
    titleContainer: {
        alignSelf: 'center'
    },
    heading: {
        textAlign: 'center'
    }
}))
