import React, { useCallback, useMemo, useState } from 'react'
import { NativeSyntheticEvent, TextInputChangeEventData, View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import { Button } from '@propertyguru/hive-button'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { ResetPasswordScreenParams as ResetPasswordScreenProps } from 'lib/native/routing/screenParams'
import { TextInputField, UnauthorizedNativeLayout } from 'features/native/common/components'
import { useToastAtom } from 'lib/atoms'
import { NotificationType } from 'lib/types'
import { useRoute } from '@react-navigation/native'
import { requestResetPassword } from 'features/native/actions'
import { Segment } from 'lib/analytics'

export const ResetPasswordScreen: React.FunctionComponent<ResetPasswordScreenProps> = ({ navigation }) => {
    const T = useTranslations()
    const route = useRoute()
    const params = route.params as ResetPasswordScreenProps
    const { email, passwordResetToken } = params
    const { styles } = useStyles(stylesheet)
    const [, setToastMessage] = useToastAtom()

    const { mutate: requestUpdatePassword, isLoading: isRequesting } = requestResetPassword()

    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [emailFocusedMessage, setEmailFocusedMessage] = useState<string>(T.screens.resetPassword.passwordFocusedMessage)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('')
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState<string>('')

    const shouldDisableSubmitButton = useMemo(() => !password || !confirmPassword || isRequesting, [password, confirmPassword, isRequesting])

    const onPasswordChange = value => {
        checkPasswordValidity(value)
        setPassword(value)
    }

    const checkPasswordValidity = (value: string) => {
        if (value.length === 0) {
            setPasswordErrorMessage('')

            return setEmailFocusedMessage(T.screens.resetPassword.passwordFocusedMessage)
        }

        const lowercaseRegex = /(?=.*[a-z])/
        const uppercaseRegex = /(?=.*[A-Z])/
        const digitRegex = /(?=.*\d)/
        const specialCharRegex = /(?=.*[@$!%*?&])/
        const minLengthRegex = /.{10,}/

        const hasLowercase = lowercaseRegex.test(value)
        const hasUppercase = uppercaseRegex.test(value)
        const hasDigit = digitRegex.test(value)
        const hasSpecialChar = specialCharRegex.test(value)
        const hasMinLength = minLengthRegex.test(value)

        const isCorrectPassword = hasLowercase && hasUppercase && hasDigit && hasSpecialChar && hasMinLength

        if (!isCorrectPassword) {
            setPasswordErrorMessage(`Make sure it contains at least ${[
                !hasMinLength && '10 characters',
                !hasLowercase && '1 lowercase',
                !hasUppercase && '1 uppercase',
                !hasDigit && '1 numeric',
                !hasSpecialChar && '1 special character'
            ]
                .filter(Boolean)
                .join(', ')}.`)

            return setEmailFocusedMessage('')

        }

        return isCorrectPassword
    }

    const validateConfirmPassword = useCallback(() => {
        const shouldShowMismatchError = confirmPassword && password !== confirmPassword
        setConfirmPasswordErrorMessage(shouldShowMismatchError ? T.screens.resetPassword.confirmPasswordNotMatch : '')

        return !shouldShowMismatchError
    }, [confirmPassword, password, T.screens.resetPassword.confirmPasswordNotMatch])

    const handleChangePassword = useCallback(
        (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
            setPasswordErrorMessage('')
            setPassword(e.nativeEvent.text)
            onPasswordChange(e.nativeEvent.text)
            validateConfirmPassword()
        },
        [validateConfirmPassword]
    )

    const handleChangeConfirmPassword = useCallback(
        (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
            setConfirmPasswordErrorMessage('')
            setConfirmPassword(e.nativeEvent.text)
        },
        []
    )

    const goToLoginScreen = useCallback(() => {
        navigation?.navigate(ScreenNamesNative.LogInNative)
    }, [navigation])

    const onSubmit = useCallback(() => {
        if (!email || !password || !passwordResetToken) {
            return Segment.passwordReset({
                status: false,
                ErrorMessage: T.screens.resetPassword.missingRequiredFields
            })
        }

        setPasswordErrorMessage('')
        setConfirmPasswordErrorMessage('')

        if (!validateConfirmPassword()) {
            return Segment.passwordReset({
                status: false,
                ErrorMessage: T.screens.resetPassword.confirmPasswordNotMatch
            })
        }

        requestUpdatePassword({ email, password, passwordResetToken }, {
            onSuccess: () => {
                Segment.passwordReset({
                    status: true
                })
                setToastMessage({
                    message: T.screens.resetPassword.changedPassword,
                    type: NotificationType.Success
                })
                goToLoginScreen()
            },
            onError: error => {
                const errorMessage =
                    (Object.values(error?.errors?.details ?? {})?.[0]) ||
                    error.errors.error_msg ||
                    T.common.error

                Segment.passwordReset({
                    errorMessage,
                    status: false
                })

                setPasswordErrorMessage(errorMessage)
            }
        })

    }, [navigation, validateConfirmPassword])

    return (
        <UnauthorizedNativeLayout fullScrollContainerHeight={false}>
            <View style={styles.container}>
                <View style={styles.titleContainer}>
                    <Text typography="title/xs" style={styles.title}>
                        {T.screens.resetPassword.identityVerifiedTitle}
                    </Text>
                    <Text typography="title/xs" style={styles.title}>
                        {T.screens.resetPassword.newPasswordTitle}
                    </Text>
                    <Text typography="body/s" style={styles.description}>
                        {T.screens.resetPassword.description}
                    </Text>
                </View>
                <TextInputField
                    secureTextEntry
                    value={password}
                    onChange={handleChangePassword}
                    errorMessage={passwordErrorMessage}
                    placeholder={T.screens.resetPassword.password}
                    focusedMessage={emailFocusedMessage}
                    onBlur={validateConfirmPassword}
                    accessibilityLabel={T.accessibility.native.resetPassword.newPassword}
                />
                <TextInputField
                    secureTextEntry
                    value={confirmPassword}
                    onChange={handleChangeConfirmPassword}
                    errorMessage={confirmPasswordErrorMessage}
                    placeholder={T.screens.resetPassword.confirmPassword}
                    onBlur={validateConfirmPassword}
                    accessibilityLabel={T.accessibility.native.resetPassword.confirmPassword}
                />
                <View style={styles.actionContainer}>
                    <Button disabled={shouldDisableSubmitButton} loading={isRequesting} accessibilityLabel={T.accessibility.native.resetPassword.resetPassword} onPress={onSubmit} type="primary" text={T.screens.resetPassword.resetPassword} />
                </View>
                <View style={styles.linkContainer}>
                    <Text onPress={goToLoginScreen} accessibilityLabel={T.accessibility.native.resetPassword.cancelPassword} typography="body/xs" style={styles.link}>
                        {T.screens.resetPassword.cancel}
                    </Text>
                </View>
            </View>
        </UnauthorizedNativeLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.utils.gap(2),
        flex: 1,
        paddingHorizontal: {
            xs: theme.utils.gap(2)
        }
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: theme.utils.gap(1.5)
    },
    actionContainer: {
        width: '100%',
        marginTop: theme.utils.gap(2)
    },
    title: {
        fontWeight: '600'
    },
    description: {
        marginTop: theme.utils.gap(1.75)
    },
    linkContainer: {
        marginTop: theme.utils.gap(2)
    },
    link: {
        textDecorationLine: 'underline'
    }
}))
