import React from 'react'
import { View } from 'react-native'
import { Breakpoint, createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { useLocaleAtom } from 'lib/atoms'
import { useFormatPrice, useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'
import { DetailsExpert } from './DetailsExpert'
import { RescheduleAirconSession } from './RescheduleAirconSession'
import { AirConditionServiceDetails } from './AirConditionServiceDetails'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'

type CleaningServiceDateProps = {
    job: JobDetailsResponse,
    isSubmitted: boolean,
    isRecurring?: boolean
}

export const AirconServiceDate: React.FunctionComponent<CleaningServiceDateProps> = ({
    job,
    isSubmitted,
    isRecurring
}) => {
    const T = useTranslations()
    const [locale] = useLocaleAtom()
    const { styles, theme } = useStyles(stylesheet)
    const { job: { date_time, beginning_date  } } = job
    const formatPrice = useFormatPrice()
    const isAirconSubscriptionJob = isAirconSubscription(job.job)
    const totalPriceValue = isAirconSubscriptionJob && !job?.job?.recurring_job ? job?.estimatedPrice || 0  : job.job?.bookingSummary?.total?.value || 0
    const totalPrice = formatPrice(totalPriceValue)
    const isAccepted = !isSubmitted && !isRecurring

    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    const isPayBySession = job?.isPayBySession
    const totalPerSessionSubjectToDiscount = job?.job?.consumerJobCostBreakdown?.totalPerSessionSubjectToDiscount || 0
    const shouldShowTotalPerSessionSubjectToDiscount = isPayBySession && totalPerSessionSubjectToDiscount > 0
    const cancellationFee = jobHelpers.getCancellationFee(job)
    const formattedCancellationFee = formatPrice(cancellationFee)

    return (
        <View>
            {isAccepted && (
                <DetailsExpert job={job} />
            )}
            {!isAccepted && (
                <View style={styles.wrapper}>
                    <View style={styles.flex}>
                        <View style={styles.title}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {T.screens.jobDetails.sections.serviceDate}
                            </Typography.Label>
                        </View>
                        <Typography.Label>
                            {dateHelpers.getFullBookingDate(date_time || beginning_date, locale)}
                        </Typography.Label>
                    </View>
                </View>
            )}
            {isMobile ? (
                <View style={styles.row}>
                    <View style={styles.rescheduleSessionContainer}>
                        <RescheduleAirconSession data={job} fullWidth/>
                    </View>
                </View>
            ):(
                <View style={styles.row}>
                    <View style={styles.rescheduleSessionContainer}>
                        <RescheduleAirconSession data={job} fullWidth/>
                    </View>
                    <View style={styles.spacer}/>
                </View>
            )}
            <View style={styles.wrapper}>
                <AirConditionServiceDetails job={job} />
            </View>

            <View style={styles.wrapper}>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.sections.promotionCode}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {job.job.promotion?.code ?? '-'}
                    </Typography.Label>
                </View>
                {
                    shouldShowTotalPerSessionSubjectToDiscount ? (
                        <View style={styles.flex}>
                            <View style={styles.title}>
                                <Typography.Label forceColor={theme.colors.mouse}>
                                    {T.screens.jobDetails.sections.pricePerSession}
                                </Typography.Label>
                            </View>
                            <Typography.Label testID={T.accessibility.jobDetailsScreen.cleaningServiceDate.price}>
                                {formatPrice(totalPerSessionSubjectToDiscount)}
                            </Typography.Label>
                        </View>
                    ): totalPriceValue > 0 && (
                        <View style={styles.flex}>
                            <View style={styles.title}>
                                <Typography.Label forceColor={theme.colors.mouse}>
                                    { isAirconSubscriptionJob ? T.screens.jobDetails.sections.estimatedPrice : T.screens.jobDetails.sections.totalAmount}
                                </Typography.Label>
                            </View>
                            <Typography.Label>
                                {totalPrice ?? 0}
                            </Typography.Label>
                        </View>
                    )}

            </View>

            {
                cancellationFee > 0 && (
                    <View style={styles.wrapper}>
                        <View style={styles.flex}>
                            <View style={styles.title}>
                                <Typography.Label forceColor={theme.colors.mouse}>
                                    {T.screens.jobDetails.sections.additionalFee}
                                </Typography.Label>
                            </View>
                            <Typography.Label>
                                {formattedCancellationFee}
                            </Typography.Label>
                        </View>
                    </View>
                )
            }

        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: {
            xl: 'row',
            lg: 'column'
        },
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    rescheduleSessionContainer: {
        flex: 1,
        width: '100%'
    },
    extendSessionContainer: {
        flex: 1,
        width: '100%'
    },
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(2.5)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    cleaningDays: {
        textTransform: 'capitalize'
    },
    spacer: {
        width: {
            xl: theme.utils.gap(2),
            lg: 0
        }
    }
}))
