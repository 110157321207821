import React, { useEffect } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import LottieView from 'lottie-react-native'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { Divider } from '@propertyguru/hive-divider'
import { Icon } from '@propertyguru/hive-icon'
import { BroomBrushOutline, WhatsappBw } from '@propertyguru/hive-icons'
import { useBackHandler, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { useUserAtom } from 'lib/atoms'
import { LottieFiles } from 'assets/native'
import {
    useNativeResetDynamicPriceSlotsAtom,
    useNativeResetJobBookingDetailAtom,
    useNativeResetJobQuotationsAtom
} from 'lib/native/atoms'
import { NativeNavigationParams, NativeNavigationProps } from 'lib/native/routing'
import { PropertyType } from 'lib/native/models'
import { ScreenNamesNative } from 'lib/native/routing/screens'

type Props = {
    route: NativeNavigationParams<ScreenNamesNative.CleaningConfirmationNative>
    navigation: NativeNavigationProps<ScreenNamesNative.CleaningConfirmationNative>
}

export const CleaningConfirmationScreen: React.FunctionComponent<Props> = ({ route, navigation }) => {
    const routeParams = route.params
    const propertyType = routeParams?.propertyType as PropertyType
    const jobId = routeParams?.jobId as string
    const bookingId = routeParams?.bookingId as string
    const T = useTranslations()
    const explainerItems = [{
        text: T.screens.cleaningConfirmation.explainer.reachOut,
        icon: WhatsappBw
    }, {
        text: T.screens.cleaningConfirmation.explainer.remember,
        icon: BroomBrushOutline
    }]
    const [user] = useUserAtom()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const [, resetJobQuotationsAtom] = useNativeResetJobQuotationsAtom()
    const [, resetJobBookingDetailAtom] = useNativeResetJobBookingDetailAtom()
    const [, resetDynamicPriceSlotsAtom] = useNativeResetDynamicPriceSlotsAtom()
    const handleNext = () => {
        navigation.navigate(ScreenNamesNative.Bookings, {
            jobId,
            bookingId,
            screenName: ScreenNamesNative.CleaningDetails
        })
    }

    useBackHandler(() => true)

    useEffect(() => {
        resetJobQuotationsAtom()
        resetJobBookingDetailAtom()
        resetDynamicPriceSlotsAtom()
    }, [])

    return (
        <ScrollView style={styles.container} bounces={false}>
            <View style={styles.imageWrapper}>
                <LottieView
                    source={LottieFiles.OrderConfirmed}
                    style={styles.image}
                    resizeMode="cover"
                    autoPlay
                    loop
                />
            </View>
            <View
                style={{ ...styles.main, paddingBottom: insets.bottom }}
            >
                <View style={styles.messageWrapper}>
                    <Text typography="title/xs" style={styles.centered}>
                        {T.screens.cleaningConfirmation.title}
                    </Text>
                    <Text typography="body/s" color="text/active/secondary" style={styles.centered}>
                        {
                            propertyType === PropertyType.Residential ?
                                T.screens.cleaningConfirmation.message.residential :
                                T.screens.cleaningConfirmation.message.commercial
                        }
                    </Text>
                    <Text typography="label/m" style={styles.centered}>
                        {user.email_id}
                    </Text>
                </View>
                <Divider style={styles.divider} />
                <View style={styles.ctaWrapper}>
                    {
                        explainerItems.map((item, index) => (
                            <View key={index} style={styles.explainer}>
                                <Icon icon={item.icon} color="icon/active/secondary" />
                                <Text typography="body/s" color="text/active/secondary" style={styles.explainerText}>
                                    {item.text}
                                </Text>
                            </View>
                        ))
                    }
                    <Button text={T.screens.cleaningConfirmation.ctaText} onPress={handleNext} />
                </View>
            </View>
        </ScrollView>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    imageWrapper: {
        aspectRatio: 800 / 480
    },
    image: {
        width: '100%',
        height: '100%',
        resizeMode: 'cover'
    },
    main: {
        paddingVertical: theme.utils.hive.spacing('x10'),
        paddingHorizontal: theme.utils.hive.spacing('x4')
    },
    messageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.utils.hive.spacing('x3'),
        textAlign: 'center'
    },
    centered: {
        textAlign: 'center'
    },
    divider: {
        marginVertical: theme.utils.hive.spacing('x8')
    },
    ctaWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.utils.hive.spacing('x6'),
        paddingHorizontal: theme.utils.hive.spacing('x4')
    },
    explainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: theme.utils.hive.spacing('x2')
    },
    explainerText: {
        flex: 1
    }
}))
