import React from 'react'
import { BasicModal } from 'lib/components'

type HandymanPricingChartModalProps = {
    isOpen: boolean,
    onClose: VoidFunction
}

export const HandymanPricingChartModal: React.FunctionComponent<HandymanPricingChartModalProps> = ({
    isOpen,
    onClose
}) => (
    <BasicModal
        isOpen={isOpen}
        onClose={onClose}
    >
        {/* TODO Missing figma */}
    </BasicModal>
)

