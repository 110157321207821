import { useMutation } from '@tanstack/react-query'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import {  useFetcher } from 'lib/api'
import {
    NewAddressResponse,
    NewAddressRequest
} from 'features/native/user/types'

export const addNewAddress = () => {
    const fetcher = useFetcher<NewAddressResponse>(HttpMethod.POST, '/consumer-add-address')

    return useMutation<Response<NewAddressResponse>, ErrorResponse, NewAddressRequest>(fetcher)
}
