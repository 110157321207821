import { getJobDetails, useGetConsumerActiveJobs, getConsumerActiveJobs, getAirConditionJobDetails } from './actions'

export * from './screens'
export { useJobStatus, useGetAllJobsData } from './hooks'
export { BookingTileButtonSmall, JobContactSmall, JobStatusLabelSmall, JobTypeLabelSmall, ServiceLocation } from './components'
export { useJobDetailsRoute } from './hooks'
export type { CancelationShape, CancelBookingShape } from './forms'
export const jobActions = {
    getJobDetails,
    useGetConsumerActiveJobs,
    getConsumerActiveJobs,
    getAirConditionJobDetails
}
