export type VoidFunction = () => void
export type Nullable<T> = T | null
export type Optional<T> = T | undefined
type ChildrenTypes = JSX.Element | string | number | boolean | null | undefined

export type Children = Array<ChildrenTypes> | ChildrenTypes
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type KeyValuePair<T = any> = Record<string, T>

export enum InternetConnectivityStatus {
    online = 'online',
    offline = 'offline'
}

export type PointerEvents = 'none' | 'auto' | 'box-none' | 'box-only'
export enum PointerEvent {
    none = 'none',
    auto = 'auto',
    boxNon = 'box-none',
    boxOnly = 'box-only'
}

export type AnimatedTab = {
    text: string
    testID?: string
}

export type GA4SessionData = {
    sessionId?: string,
    sessionNumber?: string,
    clientId?: string,
    anonymousId?: string
}

export type TrackingSessionData = {
    sessionId?: string
    sessionNumber?: string
    clientId?: string
    fbp?: string
    fbc?: string
}

export enum GTagError {
    NoGtagInstance = 'No gtag instance'
}
