import React from 'react'
import { useIsMutating } from '@tanstack/react-query'
import { MutationKey } from 'lib/api'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { AppLayout } from 'lib/components'
import { SendPayScreenContent } from 'features/sendPay/components'
import { getPromotionPackages } from 'features/sendPay/actions'

type SendPayScreenProps = {
    navigation: NavigationProps<ScreenNames.SendPay>
}

export const SendPayScreen: React.FunctionComponent<SendPayScreenProps> = () => {
    const isFetchingProfile = useIsMutating([MutationKey.GetMe])
    const { isLoading: isFetchingPackages, isRefetching } = getPromotionPackages()
    const isLoading = isFetchingPackages || isRefetching || Boolean(isFetchingProfile)

    return (
        <AppLayout isLoading={isLoading}>
            <SendPayScreenContent />
        </AppLayout>
    )
}
