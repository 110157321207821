import React from 'react'
import { TextStyle, View } from 'react-native'
import { QuestionMark } from 'assets/icons'
import { APP_CONFIG } from 'lib/config'
import { createStyles } from 'lib/styles'
import { ReferralWarning, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { Children, Nullable } from 'lib/types'
import { stringHelpers } from 'lib/utils'
import { HandymanService } from 'lib/models'
import { SubscriptionBenefit, SwitchPlan } from 'features/bookings/components'
import { GetPromotionResponse, JobQuotationResponse } from '../types'
import { ModalWithIcon } from '../../../lib/components/ModalWithIcon'
import { SummaryModalContent } from './SummaryModalContent'
import { CleaningFormShape } from '../forms'

type BookingSummaryProps = {
    couponText?: string,
    pricingMessage?: string,
    title?: string,
    renderPriceComponent?: () => Children,
    renderPriceSummaryComponent?: () => Children,
    renderSubTotalComponent?: () => Children,
    handymanService?: Array<HandymanService>
    bookingSummary: Record<string, string | number | Array<string>>,
    supplyId?: number,
    numberOfLines?: number,
    valueStyle?: TextStyle,
    promotionPackage?: Nullable<GetPromotionResponse>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>,
    form?: Nullable<CleaningFormShape>,
    platformFeeDescription?: string,
    sessionsGeneratedDescription?: string,
    jobQuotation?: JobQuotationResponse,
    selectWeeklyPlan?(): void
}

export const BookingSummary: React.FunctionComponent<BookingSummaryProps> = ({
    renderPriceComponent,
    renderPriceSummaryComponent,
    renderSubTotalComponent,
    pricingMessage,
    title,
    bookingSummary,
    numberOfLines = 1,
    valueStyle,
    handymanService = [],
    promotionPackage,
    form,
    platformFeeDescription,
    sessionsGeneratedDescription,
    jobQuotation,
    selectWeeklyPlan,
    couponText
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View>
            <Typography.Title bold>
                {title ?? T.common.bookingSummary}
            </Typography.Title>
            <View style={styles.summaryContainer}>
                {Object.keys(bookingSummary).map((key, index) => {
                    if (Array.isArray(bookingSummary[key])) {
                        const itemsArray = bookingSummary[key] as Array<string>
                        const itemsCount = handymanService.reduce((acc, nextValue) => nextValue.category === key ? acc + nextValue.amount : acc, 0)

                        return (
                            <View
                                key={index}
                                style={styles.itemsWrapper}
                            >
                                <View style={styles.summaryCountRow}>
                                    <View style={styles.labelWrapper}>
                                        <Typography.Label
                                            bold
                                            numberOfLines={numberOfLines}
                                        >
                                            {`${key} ${T.common.items}`}
                                        </Typography.Label>
                                    </View>
                                    <Typography.Label
                                        bold
                                        style={{
                                            ...styles.value,
                                            ...valueStyle
                                        }}
                                    >
                                        {itemsCount}
                                    </Typography.Label>
                                </View>
                                {itemsArray.map((item, index) => (
                                    <View
                                        key={index}
                                        style={styles.summaryItem}
                                    >
                                        <Typography.Label forceColor={theme.colors.darkGrey}>
                                            {item}
                                        </Typography.Label>
                                    </View>
                                ))}
                            </View>
                        )
                    }

                    return (
                        <View
                            key={index}
                            style={styles.summaryRow}
                        >
                            <View style={styles.labelWrapper}>
                                <Typography.Label numberOfLines={numberOfLines} style={styles.label}>
                                    {key}
                                    {/* TODO: change architecture to remove this check */}
                                    {key === T.components.bookingSummary.platformFee && (
                                        <View style={styles.platformFeeHelp}>
                                            <ModalWithIcon
                                                icon={<QuestionMark size={16} />}
                                            >
                                                <SummaryModalContent title={key} description={platformFeeDescription || ''} />
                                            </ModalWithIcon>
                                        </View>
                                    )}
                                    {key === T.components.bookingSummary.sessionsGenerated && (
                                        <View style={styles.platformFeeHelp}>
                                            <ModalWithIcon
                                                icon={<QuestionMark size={16} />}
                                            >
                                                <SummaryModalContent title={key} description={sessionsGeneratedDescription || ''} />
                                            </ModalWithIcon>
                                        </View>
                                    )}
                                </Typography.Label>
                            </View>
                            <Typography.Label
                                testID={stringHelpers.toKebabCase(`${key}-${bookingSummary[key]}`)}
                                bold
                                style={{
                                    ...styles.value,
                                    ...valueStyle
                                }}
                            >
                                {bookingSummary[key] || '-'}
                            </Typography.Label>
                        </View>
                    )
                })}
            </View>
            {/* Adding this extra check to avoid rendering the pricing summary component heading if it is not passed */}
            {renderPriceSummaryComponent ? renderPriceSummaryComponent() : null}
            {renderSubTotalComponent && (
                <View style={styles.pricingSummaryContainer}>
                    {renderSubTotalComponent()}
                </View>
            )}
            {/* This PromotionSummary summary component is duplicate and is already being handled in PriceSummaryComponent.
            Hence commented, will remove in future */}
            {/* <PromotionSummary
                promotionPackage={promotionPackage}
                totalPrice={totalPrice}
                originalPrice={originalPrice}
            /> */}
            {renderPriceComponent && (
                <View style={styles.priceContainer}>
                    {renderPriceComponent()}
                </View>
            )}
            {pricingMessage && (
                <Typography.Error
                    style={styles.pricingMessage}
                    forceColor={theme.colors.mouse}
                >
                    {pricingMessage}
                </Typography.Error>
            )}
            {
                !APP_CONFIG?.DISABLE_SUBSCRIPTION_SERVICE?.CLEANING && (
                    <SwitchPlan
                        jobQuotation={jobQuotation}
                        form={form}
                        selectWeeklyPlan={selectWeeklyPlan}
                    />
                )}
            <SubscriptionBenefit
                form={form}
                jobQuotation={jobQuotation}
            />
            {couponText && (
                <View style={styles.couponContainer}>
                    <Typography.Label
                        bold
                        forceColor={theme.colors.green}
                    >
                        {couponText}
                    </Typography.Label>
                    {promotionPackage?.promotion.warning_message && (
                        <ReferralWarning />
                    )}
                </View>
            )}

        </View>
    )
}

const stylesheet = createStyles(theme => ({
    priceContainer: {
        borderTopWidth: 1,
        borderColor: theme.colors.silver,
        marginBottom: theme.utils.gap(2),
        paddingTop: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pricingSummaryContainer: {
        borderTopWidth: 1,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2)
    },
    summaryContainer: {
        borderTopWidth: 1,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        marginTop: theme.utils.gap(1)
    },
    priceSummaryContainer: {
        justifyContent: 'space-between',
        width: '100%'
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(2)
    },
    summaryCountRow: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    summaryItem: {
        marginBottom: theme.utils.gap(1)
    },
    itemsWrapper: {
        marginBottom: theme.utils.gap(1)
    },
    labelWrapper: {
        flex: 1,
        minWidth: 50,
        marginRight: theme.utils.gap(2)
    },
    platformFeeHelp: {
        paddingLeft: theme.utils.gap(1) / 2
    },
    value: {
        textAlign: 'right'
    },
    couponContainer: {
        borderRadius: 8,
        justifyContent: 'center',
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(1),
        backgroundColor: theme.colors.mint
    },
    couponIcon: {
        marginRight: theme.utils.gap(1)
    },
    pricingMessage: {
        marginBottom: theme.utils.gap(2)
    },
    discountName: {
        textTransform: 'uppercase'
    },
    discountValue: {
        color: theme.colors.flame
    },
    flex: {
        display: 'flex'
    },
    label: {
        display: 'flex',
        overflow: 'visible'
    }
}))
