import type { useHive } from '@propertyguru/hive'
import { isWeb } from 'lib/common'
import { CustomFonts } from 'lib/types'
import { breakpoints } from './breakpoints'
import { colors } from './colors'

const GAP = 10

export const theme = {
    colors,
    gap: GAP,
    breakpoints,
    utils: {
        createShadow: (
            offsetY = 0,
            opacity = 0.25,
            radius = 18,
            elevation = 18,
            offsetX = 0,
            color = colors.shadow
        ) => {
            if (isWeb) {
                return {
                    boxShadow: `${offsetX}px ${offsetY}px ${radius}px ${color}`
                }
            }

            return {
                shadowOffset: {
                    width: 0,
                    height: offsetY
                },
                shadowOpacity: opacity,
                shadowRadius: radius,
                elevation,
                shadowColor: color
            }
        },
        gap: (factor: number) => factor * GAP,
        hexToRGBA: (hex: string, opacity: number) => {
            const rgb = hex
                .replace('#', '')
                .split(/(?=(?:..)*$)/)
                .map(x => parseInt(x, 16))

            return `rgba(${rgb.at(0)}, ${rgb.at(1)}, ${rgb.at(2)}, ${opacity})`
        },
        hive: {} as ReturnType<typeof useHive>
    },
    zIndex: {
        10: 10,
        20: 20
    },
    ui: {
        primary: colors.night,
        accent: colors.sky,
        background: colors.marble,
        foreground: colors.silver,
        transparent: colors.transparent
    },
    icon: {
        success: colors.green,
        selected: colors.orange,
        unselected: colors.night,
        error: colors.strawberry
    },
    components: {
        input: {
            height: 48,
            borderRadius: 8,
            borderWidth: 1,
            padding: GAP * 1.5,
            backgroundColor: colors.white,
            unitBackgroundColor: colors.silver,
            emptyBorderColor: colors.fog,
            filledBorderColor: colors.night,
            error: {
                borderColor: colors.strawberry,
                unitBackground: colors.linen
            },
            regular: {
                borderColor: colors.night
            },
            disabled: {
                borderColor: colors.fog,
                backgroundColor: colors.silver
            },
            typography: {
                placeholder: colors.fog,
                text: colors.night,
                error: colors.strawberry
            },
            icon: {
                regular: colors.fog,
                error: colors.strawberry
            }
        },
        OTPInput: {
            borderWidth: 1,
            borderRadius: 4,
            focusBorderColor: colors.night,
            borderColor: colors.fog,
            backgroundColor: colors.white,
            typography: {
                placeholder: colors.mouse,
                text: colors.night,
                error: colors.strawberry
            },
            error: {
                borderColor: colors.strawberry
            }
        },
        button: {
            backgroundColor: colors.sun,
            padding: GAP,
            height: isWeb ? 53 : 42,
            borderRadius: 4,
            disabled: {
                backgroundColor: colors.silver,
                text: colors.darkSilver
            },
            typography: {
                text: colors.night
            }
        },
        typography: {
            fontFamily: CustomFonts.Roboto400,
            heading: {
                fontFamily: CustomFonts.Poppins600
            },
            subheading: {
                fontFamily: CustomFonts.Poppins600
            },
            title: {
                fontFamily: CustomFonts.Roboto400
            },
            regular: {
                fontFamily: CustomFonts.Roboto400
            },
            label: {
                fontFamily: CustomFonts.Poppins400
            },
            logo: {
                fontFamily: CustomFonts.Lato700
            },
            error: {
                fontFamily: CustomFonts.Roboto400
            }
        },
        header: {
            link: {
                fontFamily: CustomFonts.Poppins500,
                fontSize: 16,
                lineHeight: 19
            }
        },
        userDropDown: {
            height: 45,
            borderRadius: 45 / 2,
            borderWidth: 1,
            color: colors.night,
            backgroundColor: colors.white,
            borderColor: colors.silver,
            padding: GAP * 2,
            disabled: {
                backgroundColor: colors.silver
            }
        },
        checkBox: {
            width: 20,
            height: 20,
            borderWidth: 2,
            borderRadius: 3,
            borderColor: colors.night,
            backgroundColor: colors.sun,
            iconColor: colors.night
        },
        modal: {
            backdrop: {
                backgroundColor: 'rgba(0, 0, 0, 0.6)'
            },
            content: {
                width: 800,
                backgroundColor: colors.white,
                borderRadius: 8
            }
        },
        notificationBox: {
            background: {
                error: colors.linen,
                success: colors.mint,
                info: colors.latte,
                completed: colors.purple
            },
            typography: {
                error: colors.strawberry,
                success: colors.green,
                info: colors.orange,
                completed: colors.purple
            }
        },
        alert: {
            minHeight: 30,
            text: {
                lineHeight: 20
            }
        }
    },
    fontWeight: {
        normal: 'normal',
        medium: '500',
        semiBold: '600',
        bold: '700'
    },
    fontSize: {
        12: 12,
        14: 14,
        16: 16,
        18: 18,
        20: 20,
        24: 24,
        28: 28,
        30: 30,
        32: 32,
        48: 48
    }
}
