import React from 'react'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { TextStyle, View } from 'react-native'
import { Measurements, isNative } from 'lib/common'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { AnimatedTab } from 'lib/types'
import { Tab } from './Tab'

type TabsProps = {
    tabs: Array<AnimatedTab>,
    activeLabelStyles: TextStyle,
    inactiveLabelStyles: TextStyle,
    scrollViewWidth?: Animated.SharedValue<number>,
    activeTabIndex: Animated.SharedValue<number>,
    onPress(index: number): void
}

export const Tabs: React.FunctionComponent<TabsProps>  = ({
    tabs,
    activeTabIndex,
    onPress,
    activeLabelStyles,
    inactiveLabelStyles
}) => {
    const { styles } = useStyles(stylesheet)
    const step = 100 / tabs.length
    const animatedTabStyles = useAnimatedStyle(() => ({
        transform: [
            {
                // @ts-ignore
                translateX: isNative
                    ? withTiming(((Measurements.WindowWidth) * activeTabIndex.value) / tabs.length)
                    : withTiming(activeTabIndex.value === 0 ? '-50%' : '50%')
            }
        ]
    }))

    return (
        <View style={styles.container}>
            <View style={styles.tabsContainer}>
                {tabs.map((tab, index) => (
                    <Tab
                        label={tab.text}
                        key={index}
                        index={index}
                        onPress={onPress}
                        activeTabIndex={activeTabIndex}
                        activeLabelStyles={activeLabelStyles}
                        inactiveLabelStyles={inactiveLabelStyles}
                        testID={tab.testID}
                    />
                ))}
            </View>
            <Animated.View
                style={[
                    styles.activeTab,
                    animatedTabStyles,
                    {
                        width: `${step}%`
                    }
                ]}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: isNative
            ? undefined
            : 'center',
        flexDirection: 'column',
        borderBottomWidth: 1,
        marginBottom: theme.utils.gap(2),
        borderColor: theme.colors.fog
    },
    activeTab: {
        borderBottomWidth: 2,
        borderColor: theme.colors.orange,
        width: isNative
            ? undefined
            : '50%'
    },
    tabsContainer: {
        flexDirection: 'row',
        width: '100%'
    }
}))
