import React from 'react'
import { View } from 'react-native'
import Animated, { FadeIn } from 'react-native-reanimated'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useFirstSignUpAtom } from 'lib/atoms'
import { useGetAllJobsData } from 'features/jobs'
import { useStyles, useTranslations } from 'lib/hooks'

export const PromoBar: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isFirstSignUp] = useFirstSignUpAtom()
    const { isLoading, allJobsData } = useGetAllJobsData()
    const shouldShowPromo = isFirstSignUp || allJobsData.length === 0

    if (!shouldShowPromo || isLoading) {
        return null
    }

    return (
        <Animated.View entering={FadeIn}>
            <View style={styles.promoMessage}>
                <Typography.Regular bold>
                    {T.components.promoBar.message}
                </Typography.Regular>
            </View>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    promoMessage: {
        alignItems: 'center',
        paddingVertical: theme.utils.gap(1),
        backgroundColor: theme.colors.sun
    }
}))
