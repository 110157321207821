import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

export const AuthSuccessMessage: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.icon}>
                <Icons.Check
                    size={45}
                    forceColor={theme.colors.night}
                />
            </View>
            <View style={styles.title}>
                <Typography.SmallSubheading>
                    {T.screens.authSuccess.verifyTitle}
                </Typography.SmallSubheading>
            </View>
            <Typography.Regular>
                {T.screens.authSuccess.verifyMessage}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: {
            lg: undefined,
            xs: 1
        },
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.utils.gap(8),
        height: theme.utils.gap(8),
        borderRadius: theme.utils.gap(4),
        backgroundColor: theme.colors.darkSilver
    },
    title: {
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(3)
    }
}))
