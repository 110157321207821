import React, { Fragment } from 'react'
import { View } from 'react-native'
import { Divider } from '@propertyguru/hive-divider'
import { Icon } from '@propertyguru/hive-icon'
import {
    BadgeVerifiedAwardOutline,
    CalendarEditDateEditOutline,
    CalenderRemoveOutline
} from '@propertyguru/hive-icons'
import { Text } from '@propertyguru/hive-text'
import { Touchable } from 'lib/components'
import { ENV } from 'lib/config'
import { linkingHelpers } from 'lib/utils'
import { Segment } from 'lib/analytics'
import { useStyles, useTranslations } from 'lib/hooks'
import { stylesheet } from './styles'

export const Benefits: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const benefitItems = [
        {
            icon: BadgeVerifiedAwardOutline,
            text: T.screens.cleaningSummaryNative.benefits.list.itemOneText
        },
        {
            icon: CalendarEditDateEditOutline,
            highlight: T.screens.cleaningSummaryNative.benefits.list.itemTwoHighlight,
            text: T.screens.cleaningSummaryNative.benefits.list.itemTwoText
        },
        {
            icon: CalenderRemoveOutline,
            highlight: T.screens.cleaningSummaryNative.benefits.list.itemThreeHighlight,
            text: T.screens.cleaningSummaryNative.benefits.list.itemThreeText,
            ctaText: T.screens.cleaningSummaryNative.benefits.list.itemThreeCta,
            ctaLink: `${ENV.SENDHELPER_MARKETING_SITE_URL}/cancellation-policy`
        }
    ]

    const navigateToTermsAndPrivacy = () => {
        linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/terms`)
    }

    const navigateToPrivacyPolicy = () => {
        linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/privacy`)
    }

    const navigateToCancellation = () => {
        linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/cancellation-policy`)
    }

    const onNavigateToLink = link => {
        if (link) {
            linkingHelpers.openUrl(link)
        }
    }

    const onFooterLinkCTAClick = (link: string) => {
        Segment.bookingCancellationChargesClicked({})
        onNavigateToLink(link)
    }

    return (
        <View style={styles.section}>
            <Text typography="label/m" color="text/active/primary">
                {T.screens.cleaningSummaryNative.benefits.titlePartOne}
                <Text typography="label/m" color="text/success/primary">
                    {T.screens.cleaningSummaryNative.benefits.titlePartTwo}
                </Text>
                {T.screens.cleaningSummaryNative.benefits.titlePartThree}
            </Text>
            <View style={styles.sectionBody}>
                <View style={styles.sectionList}>
                    {benefitItems.map((item, index) => (
                        <Fragment key={index}>
                            <View key={index} style={styles.sectionListItem}>
                                <Icon icon={item.icon} color="icon/active/primary" width={24} height={24} />
                                <View style={styles.sectionListItemText}>
                                    <Text typography="body/s" color="text/active/secondary" >
                                        {item.highlight && <Text typography="label/m" color="text/success/primary">{item.highlight}{' '}</Text>}
                                        {item.text}
                                    </Text>
                                    {item.ctaText && (
                                        <Touchable>
                                            <Text
                                                typography="body/s"
                                                color="text/active/primary"
                                                underline
                                                onPress={() => onFooterLinkCTAClick(item?.ctaLink)}
                                            >
                                                {item.ctaText}
                                            </Text>
                                        </Touchable>
                                    )}
                                </View>
                            </View>
                        </Fragment>
                    ))}
                </View>
                <Divider />
                <Text typography="caption/xs" color="text/active/secondary">
                    {T.components.policies.confirm}{' '}
                    <Text typography="caption/xs" color="text/active/primary" underline onPress={navigateToCancellation}>
                        {T.components.policies.cancellation}
                    </Text>,{' '}
                    <Text typography="caption/xs" color="text/active/primary" underline onPress={navigateToTermsAndPrivacy}>
                        {T.components.policies.terms}
                    </Text>
                    {' '}
                    {T.common.and}{' '}
                    <Text typography="caption/xs" color="text/active/primary" underline onPress={navigateToPrivacyPolicy}>
                        {T.components.policies.privacy}
                    </Text>.
                </Text>
            </View>
        </View>
    )
}
