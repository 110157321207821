/* eslint-disable max-lines */
/* eslint-disable no-else/no-else */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { debounceTime, Subject } from 'rxjs'
import { NativeSyntheticEvent, ScrollView, TextInputChangeEventData, View, Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Divider } from '@propertyguru/hive-divider'
import {
    ArrowLeftOutline,
    ChevronLeftOutline,
    PlusAddSmallFill,
    MinusSmallFill,
    FocusExposureMacroFlower2Fill,
    FocusExposureMacroFlower2Outline,
    PetFill,
    PetOutline,
    Search2MagnifyingGlassOutline,
    CrossedLargeCloseFill
} from '@propertyguru/hive-icons'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { TextInput } from '@propertyguru/hive-text-input'
import { Icon } from '@propertyguru/hive-icon'
import { Touchable } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { dateHelpers, district, regexes } from 'lib/utils'
import { useToastAtom } from 'lib/atoms'
import { NotificationType } from 'lib/types'
import { Segment } from 'lib/analytics'
import { findAddressByOneMap } from 'features/user/actions'
import { ChipBox, ScreenHeader } from 'lib/native/components'
import { useNativeJobBookingDetailAtom, useNativeUserTokenAtom } from 'lib/native/atoms'
import { BookingFrequency, CommercialPropertyDetails, ResidentialPropertyDetails } from 'lib/native/models'
import { ADDRESS_HOUSING_TYPE_MAP } from 'lib/native/constants'
import { Address, AddressPropertyType, LatLng } from 'lib/native/types'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { Footer, FooterBreakdownWrapper, StickyScheduleMessage } from 'features/native/bookings/components'
import { addNewAddress } from 'features/native/user/actions'
import { PropertyType } from 'lib/native/models/JobBookingDetail'
import { stylesheet } from './styles'
import { getSelectedSchedule } from '../../utils'
import { AUTO_SUGGEST_MIN_THRESHOLD } from '../../constants'

export const CleaningServiceAddressScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()
    const [token] = useNativeUserTokenAtom()
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const { mutate: findAddress, isLoading: isFetchingPostcode } = findAddressByOneMap()
    const { mutate: addAddress, isLoading: isAddingAddress } = addNewAddress()
    const inputStream$ = useMemo(() => new Subject(), [])
    const [addressSuggestions, setAddressSuggestions] = useState<Array<Address>>([])
    const [postcode, setPostcode] = useState<string>(jobBookingDetailAtom.address?.postcode ?? '')
    const [addressKeyRequests, setAddressKeyRequests] = useState({})
    const [errorMessagePostcode, setErrorMessagePostcode] = useState<string>('')
    const [postcodeTouched, setPostcodeTouched] = useState<boolean>(false)
    const [address, setAddress] = useState<string>(jobBookingDetailAtom.address?.address ?? '')
    const [addressTouched, setAddressTouched] = useState<boolean>(false)
    const [geo, setGeo] = useState<LatLng>({ lat: 0.0, lng: 0.0 })
    const [errorMessageAddress, setErrorMessageAddress] = useState<string>('')
    const [unit, setUnit] = useState<string>(jobBookingDetailAtom.address?.unit ?? '')
    const [unitTouched, setUnitTouched] = useState<boolean>(false)
    const [errorMessageUnit, setErrorMessageUnit] = useState<string>('')
    const [hasAddressNotes, setHasAddressNotes] = useState<boolean>(!!jobBookingDetailAtom.addressNotes)
    const [notes, setNotes] = useState<string>(jobBookingDetailAtom.addressNotes ?? '')
    const [notesTouched, setNotesTouched] = useState<boolean>(false)
    const [hasPet, setHasPet] = useState<boolean>(false)
    const [remarks, setRemarks] = useState<string>('')
    const isLoading = isAddingAddress || isFetchingPostcode
    const isOneTime = jobBookingDetailAtom.frequency === BookingFrequency.OneTime
    const selectedAddress = jobBookingDetailAtom?.newAddress?.postcode
        ? jobBookingDetailAtom.newAddress
        : jobBookingDetailAtom.address

    const selectedSchedule = getSelectedSchedule(jobBookingDetailAtom?.booking?.schedule)

    const onChangePostcode = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        const inputText = e.nativeEvent.text.trim()
        setPostcode(inputText)
        setPostcodeTouched(true)
        clearAddress()
        inputStream$.next(inputText)
    }

    const onPostcodeBlur = () =>
        postcode.length < 1 && setErrorMessagePostcode(T.screens.cleaningServiceAddress.errorMessagePostcode)

    const onChangeAddress = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        setAddress(e.nativeEvent.text.replace(/\r?\n|\r/g, ''))
        setAddressTouched(true)
        Segment.addressLineEntered({
            entryType: Segment.EntryType.Manual
        })
    }

    const onAddressBlur = () =>
        address.length < 1 && setErrorMessageAddress(T.screens.cleaningServiceAddress.errorMessageAddress)

    const onChangeUnit = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        setUnitTouched(true)
        setUnit(e.nativeEvent.text)
    }

    const onUnitBlur = () => {
        unit.length < 1 && setErrorMessageUnit(T.screens.cleaningServiceAddress.errorMessageUnit)
    }

    const onChangeNotes = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        setNotes(e.nativeEvent.text)
        setNotesTouched(true)
    }

    const onChangeRemarks = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        setRemarks(e.nativeEvent.text)
    }

    const clearAddress = (withPostcode?: boolean) => {
        if (withPostcode) {
            setPostcode('')
        }

        setAddress('')
        setAddressTouched(false)
        setUnit('')
        setNotes('')
        setHasAddressNotes(false)
        setAddressSuggestions([])
        setGeo({ lat: 0.0, lng: 0.0 })
    }

    useEffect(() => {
        if (!postcode?.trim()) {
            setJobBookingDetailAtom({
                ...jobBookingDetailAtom,
                newAddress: undefined,
                addressNotes: undefined
            })
        }
    }, [postcode])

    const handleNext = useCallback(() => {
        if (!postcode && !selectedAddress?._id) {
            setErrorMessagePostcode(T.screens.cleaningServiceAddress.errorMessagePostcode)

            return
        }

        if (postcode && !errorMessagePostcode && !regexes.isValidPostalCode(postcode)) {
            setErrorMessagePostcode(T.screens.cleaningServiceAddress.errorMessagePostcode)
        }

        if (!errorMessageAddress && !address) {
            setErrorMessageAddress(T.screens.cleaningServiceAddress.errorMessageAddress)
        }

        if (!errorMessageUnit && !unit) {
            setErrorMessageUnit(T.screens.cleaningServiceAddress.errorMessageUnit)
        }

        if (!postcode || !address || !unit || errorMessageAddress || errorMessagePostcode || errorMessageUnit) {
            setToastMessage({
                message: T.screens.cleaningServiceAddress.errorMessageDefault,
                type: NotificationType.Error
            })

            return
        }

        const keyRequest = `${postcode}-${address}-${unit}`

        if (addressKeyRequests[keyRequest]) {
            navigation.navigate(ScreenNamesNative.CleaningSummaryNative)

            return
        }

        const payload = {
            token,
            address: {
                address,
                district: district.getDistrictFromPostcode(postcode),
                postcode,
                unit,
                geo: {
                    lat: parseFloat(geo.lat?.toString()),
                    lng: parseFloat(geo.lng?.toString())
                },
                propertyDetails:
                    jobBookingDetailAtom?.propertyInfo === PropertyType.Residential
                        ? ({
                            bedrooms: jobBookingDetailAtom.bedrooms,
                            bathrooms: jobBookingDetailAtom.bathrooms,
                            type: AddressPropertyType.Residential,
                            housingType: ADDRESS_HOUSING_TYPE_MAP[jobBookingDetailAtom.housingType as string]
                        } as ResidentialPropertyDetails)
                        : ({
                            size: jobBookingDetailAtom.commercialSize as string,
                            type: AddressPropertyType.Commercial
                        } as CommercialPropertyDetails)
            }
        }

        if (postcode && !postcodeTouched && !addressTouched && !unitTouched && !notesTouched) {
            setJobBookingDetailAtom({
                ...jobBookingDetailAtom,
                messageToSupplier: remarks,
                petOnPresmises: hasPet
            })
            navigation.navigate(ScreenNamesNative.CleaningSummaryNative)

            return
        }

        addAddress(payload, {
            onSuccess: response => {
                const addressId = response?.data?.addressId
                setAddressKeyRequests(Object.assign(addressKeyRequests, { [keyRequest]: addressId }))
                setJobBookingDetailAtom({
                    ...jobBookingDetailAtom,
                    messageToSupplier: remarks,
                    petOnPresmises: hasPet,
                    newAddress: {
                        _id: addressId,
                        ...payload.address
                    }
                })
                navigation.navigate(ScreenNamesNative.CleaningSummaryNative)
            },
            onError: error => {
                setToastMessage({
                    message: error?.errors?.error_msg || T.common.errorMessage,
                    type: NotificationType.Error
                })
            }
        })
    }, [postcode, address, unit, errorMessageAddress, errorMessagePostcode, errorMessageUnit, postcodeTouched, remarks, hasPet])

    const fetchPostalCodes = value => {
        if (value.length >= AUTO_SUGGEST_MIN_THRESHOLD) {
            findAddress(
                {
                    searchVal: value.trim(),
                    returnGeom: 'Y',
                    getAddrDetails: 'Y',
                    pageNum: 1
                },
                {
                    onSuccess: response => {
                        const addresses = response?.results?.filter(
                            item => item.POSTAL !== 'NIL' && regexes.isValidPostalCode(item.POSTAL) && item.ADDRESS
                        )
                        const results =
                            addresses?.map(address => ({
                                postcode: address.POSTAL,
                                address: address.ADDRESS.replace(address.POSTAL, '').trim(),
                                geo: {
                                    lat: address.LATITUDE,
                                    lng: address.LONGITUDE
                                }
                            })) ?? []

                        if (results.length === 0) {
                            return
                        }

                        setAddressSuggestions(results)
                        Segment.postcodeEntered({
                            postcode,
                            success: results.length !== 0
                        })
                    },
                    onError: error => console.error('find address error: ', error)
                }
            )
        }
    }

    useEffect(() => {
        Segment.bookingAdditionalInformationAdded({
            information: {
                type: 'pets',
                value: hasPet ? 'Yes, I’m a pawrent' : 'Nope, no pet'
            }
        })
        remarks && Segment.bookingAdditionalInformationAdded({
            information: {
                type: 'comment',
                value: remarks
            }
        })
    }, [hasPet, remarks])

    useEffect(() => {
        const subscribe = inputStream$?.pipe(debounceTime(500)).subscribe(value => {
            fetchPostalCodes(value)
        })

        return () => {
            subscribe?.unsubscribe()
        }
    }, [inputStream$])

    useEffect(() => {
        if (postcodeTouched && !(!regexes.isValidPostalCode(postcode) || addressSuggestions.length === 0)) {
            setErrorMessagePostcode(T.screens.cleaningServiceAddress.errorMessagePostcode)
        } else {
            setErrorMessagePostcode('')
        }
    }, [postcode, postcodeTouched])

    useEffect(() => {
        if (addressTouched && address.length < 1) {
            setErrorMessageAddress(T.screens.cleaningServiceAddress.errorMessageAddress)
        } else {
            setErrorMessageAddress('')
        }
    }, [address])

    useEffect(() => {
        if (unitTouched && unit?.length < 0) {
            setErrorMessageUnit(T.screens.cleaningServiceAddress.errorMessageUnit)
        } else {
            setErrorMessageUnit('')
        }
    }, [unit, unitTouched])

    useEffect(() => {
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            petOnPresmises: hasPet,
            addressNotes: notes,
            messageToSupplier: remarks
        })
    }, [hasPet, notes, remarks])

    return (
        <View style={{ ...styles.container, paddingTop: insets.top }}>
            <ScreenHeader>
                <ScreenHeader.Button icon={ChevronLeftOutline} onPress={navigation.goBack} />
                <ScreenHeader.Title>{T.screens.cleaningServiceAddress.pageTitle}</ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <KeyboardAwareScrollView
                style={styles.main}
                enableOnAndroid
                extraHeight={Platform.OS === 'ios' ? 200 : 250} // This helps to give extra space when focusing input
                keyboardShouldPersistTaps="handled"
            >
                {jobBookingDetailAtom.booking?.date ? (
                    <StickyScheduleMessage
                        message={
                            isOneTime
                                ? T.screens.cleaningDateTime.stickyScheduleMessage.cleaningOn
                                : T.screens.cleaningDateTime.stickyScheduleMessage.firstCleaningStartFrom
                        }
                        date={
                            isOneTime
                                ? (dateHelpers.formatBookingDate(jobBookingDetailAtom.booking.uiDate as string) as string)
                                : (dateHelpers.formatDateWithDayAndMonth(
                                    jobBookingDetailAtom.booking.uiDate as string
                                ) as string)
                        }
                        schedules={isOneTime ? [] : selectedSchedule?.filter(schedule => schedule.time !== undefined).map(schedule => ({
                            ...schedule,
                            time: schedule.time || ''
                        }))}
                        interval={jobBookingDetailAtom.scheduleType}
                    />
                ) : null}

                <View style={styles.formContainer}>
                    <Text typography="label/m">{T.screens.cleaningServiceAddress.addressLabel}</Text>
                    <TextInput
                        style={styles.addressFieldSpacing}
                        value={postcode}
                        keyboardType="numeric"
                        startIcon={Search2MagnifyingGlassOutline}
                        endIcon={postcode && !isFetchingPostcode ? CrossedLargeCloseFill : undefined}
                        onEndIconPress={() => clearAddress(true)}
                        onChange={onChangePostcode}
                        error={Boolean(errorMessagePostcode)}
                        onBlur={onPostcodeBlur}
                        placeholder={T.screens.cleaningServiceAddress.addressPlaceholder}
                    />
                    {errorMessagePostcode && (
                        <Text typography="body/xs" color="text/error/primary" style={styles.addressFieldSpacing}>
                            {errorMessagePostcode}
                        </Text>
                    )}
                    {addressSuggestions.length > 0 && (
                        <ScrollView style={styles.suggestions} nestedScrollEnabled>
                            {addressSuggestions.map((suggestion, index) => (
                                <Touchable
                                    style={styles.suggestion}
                                    key={index}
                                    onPress={() => {
                                        setAddress(suggestion.address)
                                        setAddressTouched(true)
                                        setPostcode(suggestion.postcode)
                                        setGeo({
                                            lat: parseFloat(suggestion.geo.lat?.toString()),
                                            lng: parseFloat(suggestion.geo.lng?.toString())
                                        })
                                        setAddressSuggestions([])
                                        Segment.bookingExistingAddressSelected({})
                                    }}
                                >
                                    <Text>{suggestion.postcode}</Text>
                                </Touchable>
                            ))}
                        </ScrollView>
                    )}
                    {postcode && regexes.isValidPostalCode(postcode) && (
                        <>
                            <TextInput
                                style={styles.addressFieldSpacing}
                                value={address}
                                onChange={onChangeAddress}
                                error={Boolean(errorMessageAddress)}
                                onBlur={onAddressBlur}
                                supportiveText="Address"
                                multiline
                                blurOnSubmit
                                maxLength={100}
                            />
                            {errorMessageAddress && (
                                <Text
                                    typography="body/xs"
                                    color="text/error/primary"
                                    style={styles.addressFieldSpacing}
                                >
                                    {errorMessageAddress}
                                </Text>
                            )}
                            <View style={styles.unitRow}>
                                <TextInput
                                    value={unit}
                                    style={styles.unitInput}
                                    onChange={onChangeUnit}
                                    error={Boolean(errorMessageUnit)}
                                    onBlur={onUnitBlur}
                                    supportiveText={T.screens.cleaningServiceAddress.unitPlaceholder}
                                />
                            </View>
                            {errorMessageUnit && (
                                <View style={styles.unitRow}>
                                    <Text typography="body/xs" color="text/error/primary" style={styles.unitInput}>
                                        {errorMessageUnit}
                                    </Text>
                                </View>
                            )}
                            {hasAddressNotes ? (
                                <View style={styles.addressNotesContainer}>
                                    <View style={styles.addressNotesTitleWrapper}>
                                        <Text typography="label/s">{T.screens.cleaningServiceAddress.notesLabel}</Text>
                                        <Touchable onPress={() => setHasAddressNotes(false)}>
                                            <Icon icon={MinusSmallFill} />
                                        </Touchable>
                                    </View>
                                    <TextInput
                                        value={notes}
                                        onChange={onChangeNotes}
                                        multiline
                                        blurOnSubmit
                                        maxLength={300}
                                        placeholder={T.screens.cleaningServiceAddress.notesPlaceholder}
                                    />
                                </View>
                            ) : (
                                <View style={styles.addressNotesButtonWrapper}>
                                    <Touchable
                                        style={styles.addressNotesButton}
                                        onPress={() => setHasAddressNotes(true)}
                                    >
                                        <Icon icon={PlusAddSmallFill} />
                                        <Text typography="caption/s" style={styles.underline}>
                                            {T.screens.cleaningServiceAddress.notesButtonText}
                                        </Text>
                                    </Touchable>
                                </View>
                            )}
                        </>
                    )}
                    <View>
                        <View style={styles.petsContainer}>
                            <Text typography="label/s" style={styles.petLabel}>
                                {T.screens.cleaningServiceAddress.petLabel}
                            </Text>
                            <Text typography="body/xs">{T.screens.cleaningServiceAddress.petDescription}</Text>
                            <View style={styles.chipBoxes}>
                                <ChipBox
                                    active={!hasPet}
                                    text={T.screens.cleaningServiceAddress.noPetText}
                                    icon={FocusExposureMacroFlower2Outline}
                                    iconActive={FocusExposureMacroFlower2Fill}
                                    onPress={() => setHasPet(false)}
                                />
                                <ChipBox
                                    active={hasPet}
                                    text={T.screens.cleaningServiceAddress.hasPetText}
                                    icon={PetOutline}
                                    iconActive={PetFill}
                                    onPress={() => setHasPet(true)}
                                />
                            </View>
                        </View>
                        <Divider style={styles.divider} />
                        <View style={styles.additionalInfoContainer}>
                            <Text typography="label/m">
                                {T.screens.cleaningServiceAddress.additionalInfoLabel}
                                <Text typography="label/xs">
                                    {T.screens.cleaningServiceAddress.additionalInfoLabelType}
                                </Text>
                            </Text>
                            <TextInput
                                value={remarks}
                                style={styles.remarksInput}
                                size="large"
                                onChange={onChangeRemarks}
                                multiline
                                blurOnSubmit
                                maxLength={300}
                                placeholder={T.screens.cleaningServiceAddress.additionalInfoPlaceholder}
                            />
                        </View>
                    </View>

                </View>
            </KeyboardAwareScrollView>
            <Footer style={{ paddingBottom: insets.bottom }}>
                <FooterBreakdownWrapper />
                <Footer.Buttons>
                    <Button.Icon icon={ArrowLeftOutline} onPress={navigation.goBack} />
                    <Button
                        text={T.screens.cleaningServiceAddress.footerButtons.next}
                        style={styles.footerBtnNext}
                        disabled={isLoading}
                        loading={isLoading}
                        onPress={handleNext}
                    />
                </Footer.Buttons>
            </Footer>
        </View>
    )
}
