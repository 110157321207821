import { Image, View } from 'react-native'
import { Text } from '@propertyguru/hive-text'
import React, { useEffect, useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Surface } from '@propertyguru/hive-surface'
import { Button } from '@propertyguru/hive-button'
import { CheckLargeCheckmarkOutline, Copy1LayersPagesOutline } from '@propertyguru/hive-icons'
import { useRoute } from '@react-navigation/native'
import { MarketingConsentScreenParams } from 'lib/native/routing/screenParams'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, Segment } from 'lib/analytics'
import { useNativeUserTokenAtom } from 'lib/native/atoms/token'
import { useCopyToClipboard, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { useNativeUserAtom } from 'lib/native/atoms'
import { AuthorizedNativeLayout } from 'features/native/common/components'
import { useGetProfileData } from 'features/native/auth/hooks/useNativeGetProfileData'
import { Images } from 'assets/native'
import { saveMarketingConsent } from '../actions'

export const MarketingConsentScreen: React.FunctionComponent<MarketingConsentScreenParams> = () => {
    const { styles, theme } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const T = useTranslations()
    const route = useRoute()
    const params = route?.params as MarketingConsentScreenParams
    const [name, setName] = React.useState<string>(params?.name || '')
    const [user] = useNativeUserAtom()
    const [codeCopied, setCodeCopied] = useState(false)
    const [token] = useNativeUserTokenAtom()
    const { mutate: onSaveMarketingConsent } = saveMarketingConsent()
    const { onCopyPress } = useCopyToClipboard(T.screens.auth.marketingConsent.copied)
    const [isPrimaryLoading, setIsPrimaryLoading] = useState(false)
    const [isSecondaryLoading, setIsSecondaryLoading] = useState(false)
    const { getMe, isFetchingProfile } = useGetProfileData(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        error => {
            setIsPrimaryLoading(false)
            setIsSecondaryLoading(false)
        })

    useEffect(() => {
        if(user.handle){
            setName(user.handle || '')
        }
    }, [user?.handle])

    const getHeaderIcon = () => codeCopied ? CheckLargeCheckmarkOutline : Copy1LayersPagesOutline
    const getHeaderBackgroundStyle = () => codeCopied ? styles.copiedBg : styles.defaultBg

    const handleCopyCode = () => {
        if (!codeCopied) {
            setCodeCopied(true)
            onCopyPress(T.screens.auth.marketingConsent.welcome50, false)
            Segment.promoCodeCopied({})
        }
    }

    const onSaveConsent = (decision: boolean) => {
        decision ? setIsPrimaryLoading(true) : setIsSecondaryLoading(true)
        const payload = {
            marketingConsentOpted: decision,
            handle: name,
            token
        }
        onSaveMarketingConsent(payload, {
            onSuccess: () => {
                GoogleAnalytics.logEvent({
                    eventType: AnalyticsEvent.UserAction,
                    description: AnalyticsEventDescription.EmailSignUp
                })

                Segment.marketingConsentProvided({
                    status: decision
                })

                setIsPrimaryLoading(false)
                setIsSecondaryLoading(false)
                getMe(token)
            },
            onError: () => {
                setIsPrimaryLoading(false)
                setIsSecondaryLoading(false)
            }
        })
    }

    return (
        <AuthorizedNativeLayout scrollEnabled={false}>
            <View style={styles.container}>
                <Text typography="title/s" accessibilityLabel={T.accessibility.native.onboarding.welcomeMessage} style={styles.title}>{T.screens.auth.marketingConsent.welcome} {params.name}{T.screens.auth.marketingConsent.giftText}</Text>
                <Text typography="body/s" style={styles.text}>{T.screens.auth.marketingConsent.nativeVoucherDescription}</Text>
                <Image
                    style={styles.image}
                    resizeMode="contain"
                    source={Images.GetOff}
                />

            </View>
            <View style={styles.consentSectionContainer}>
                <Surface style={{ ...styles.consentSection, ...styles.boxShadow }} >
                    <View style={{ ...styles.consentSectionHeader, ...getHeaderBackgroundStyle() }}>
                        <View style={styles.consentHeaderContent}>
                            <Text typography="body/s">{!codeCopied ? T.screens.auth.marketingConsent.copyCode : ''} <Text typography="body/m" style={styles.welcomeText} >{T.screens.auth.marketingConsent.welcome50}</Text>
                                <Text typography="body/s"> {codeCopied ? T.screens.auth.marketingConsent.copied : ''} </Text>
                            </Text>
                            <Button.Icon
                                icon={getHeaderIcon()}
                                onPress={handleCopyCode}
                            />
                        </View>
                    </View>
                    <View
                        style={{
                            ...styles.consentSectionContent,
                            paddingBottom: insets.bottom + theme.utils.gap(3)
                        }}
                    >
                        <Text typography="body/s" style={styles.text}>{T.screens.auth.marketingConsent.consentText}</Text>
                        <View style={styles.consentActions}>
                            <Button
                                style={styles.actionButton}
                                type="secondary"
                                text={T.screens.auth.marketingConsent.notNow}
                                disabled={isSecondaryLoading}
                                loading={isSecondaryLoading}
                                onPress={() => {
                                    if(!isFetchingProfile) {
                                        onSaveConsent(false)
                                    }
                                }}
                                accessibilityLabel={T.accessibility.native.onboarding.noThanks}
                            />
                            <Button
                                style={styles.actionButton}
                                type="primary"
                                text={T.screens.auth.marketingConsent.yesPlease}
                                disabled={isPrimaryLoading}
                                loading={isPrimaryLoading}
                                onPress={() => {
                                    if(!isFetchingProfile) {
                                        onSaveConsent(true)
                                    }
                                }}
                                accessibilityLabel={T.accessibility.native.onboarding.yesPlease}
                            />
                        </View>
                    </View>
                </Surface>
            </View>
        </AuthorizedNativeLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: theme.utils.gap(2),
        lineHeight: 32
    },
    text: {
        textAlign: 'center',
        marginBottom: theme.utils.gap(2)
    },
    image: {
        width: '100%',
        flex: 1
    },
    consentSectionContainer: {
        justifyContent: 'flex-end'
    },
    consentSection: {
        bottom: 0,
        height: 240,
        marginTop: theme.utils.gap(1),
        width: '100%'
    },
    consentSectionHeader: {
        height: 65,
        borderRadius: 24,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: theme.colors.yellow,
        justifyContent: 'center',
        marginTop: theme.utils.gap(1)
    },
    consentSectionContent: {
        backgroundColor: theme.colors.white,
        height: '100%',
        paddingTop: theme.utils.gap(2)
    },
    consentActions: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2)
    },
    actionButton: {
        flex: 1
    },
    consentHeaderContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: theme.utils.gap(2)
    },
    flexColumnCentered: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    welcomeText: {
        fontWeight: 'bold'
    },
    defaultBg: {
        backgroundColor: theme.colors.yellow
    },
    copiedBg: {
        backgroundColor: theme.colors.successSecondary
    },
    boxShadow: {
        shadowColor: 'black',
        shadowOffset: {
            width: 20,
            height: 20
        },
        shadowOpacity: 0.2,
        shadowRadius: 0,
        elevation: 24,
        borderRadius: 24
    }
}))
