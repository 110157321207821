import React, { useState } from 'react'
import { View, Dimensions, Platform, DimensionValue } from 'react-native'
import { WebView } from 'react-native-webview'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { isNative } from 'lib/common'
import { CustomFonts } from 'lib/types'
import { createStyles } from 'lib/styles'
import { CustomModal, LoadingIndicator, Touchable, Typography } from 'lib/components'

type WebViewModalProps = {
    uri: string,
    isOpen: boolean,
    closeModal: () => void,
    title: string
}

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

export const WebViewInModal: React.FunctionComponent<WebViewModalProps> = ({
    uri,
    isOpen,
    title,
    closeModal
}) => {
    const isWeb = Platform.OS === 'web'
    const { styles } = useStyles(stylesheet)
    const [isLoading, setIsLoading] = useState(true)

    return (
        <CustomModal
            onClose={closeModal}
            closeButtonStyles={styles.closeButton}
            contentStyles={styles.modalContent}
            childrenWrapper={styles.modalChildrenWrapper}
            customScrollViewStyles={styles.customScrollViewStyles}
            isOpen={isOpen}
        >
            <View >
                <View style={styles.headerContainer}>
                    <Typography.Title style={styles.title}>
                        {title}
                    </Typography.Title>
                    <View style={styles.modalActionContainer}>
                        <Touchable
                            onPress={closeModal}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}>
                            <Icons.Close size={isNative ? 14 : 18} />
                        </Touchable>
                    </View>
                </View>
                <View style={styles.contentContainer}>
                    <LoadingIndicator isLoading={isLoading} />
                    {
                        isWeb ? (
                            <iframe
                                onLoad={() => setIsLoading(false)}
                                allowFullScreen
                                src={uri}
                                style={styles.webViewContainer}
                            />
                        ) : (
                            <WebView
                                originWhitelist={['*']}
                                onLoadEnd={() => setIsLoading(false)}
                                source={{ uri }}
                                style={styles.webViewContainer}
                            />
                        )}

                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    webViewContainer :{
        border: 'none',
        height: isNative ? windowHeight * 0.8 : '100%',
        width: isNative ? windowWidth * 0.8 : '100%'
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: theme.utils.gap(isNative ? 1 : 1.5),
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.fog,
        paddingHorizontal: theme.utils.gap(isNative ? 0.5 : 2),
        paddingTop: theme.utils.gap(0.5)
    },
    title: {
        fontSize: isNative ? 14 : 18,
        fontFamily: CustomFonts.Poppins500,
        lineHeight: 28,
        flex: 1
    },
    modalActionContainer: {
        paddingLeft: theme.utils.gap(1)
    },
    modalContent:{
        paddingHorizontal: theme.utils.gap(1),
        paddingBottom: theme.utils.gap(1),
        paddingTop: 0
    },
    modalChildrenWrapper: {
        paddingTop: 0,
        marginTop: theme.utils.gap(isNative ? 0.5 : 1.75)
    },
    contentContainer: {
        paddingTop: theme.utils.gap(0.5),
        height: isNative ? windowHeight * 0.8 : '80vh' as DimensionValue
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderBottomWidth: 1,
        borderColor: theme.colors.fog,
        padding: theme.utils.gap(1)
    },
    closeButton: {
        display: 'none'
    },
    customScrollViewStyles: {
        marginTop: theme.utils.gap(2)
    }
}))
