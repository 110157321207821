export type DynamicTimeSlot = {
    price: string;
    slotId: string;
}

export type DynamicTimeSlots = {
    [time: string]: DynamicTimeSlot;
}

export type BookingFlowMessage = {
    platform?: string;
    message?: string;
    price?: string;
}

export type CalendarMessage = {
    platform?: string;
    message?: string;
    price?: string;
    daysOfWeek?: Array<string>;
}

export type DynamicPriceMessages = {
    bookingFlowMessage?: string | BookingFlowMessage;
    calendarMessage?: string | CalendarMessage;
    dynamicPricingMessage?: string;
}

export type DynamicPriceError = {
    status: boolean;
    errors: {
        error_code: string;
        error_msg: string;
    }
}

export type DynamicPriceData = {
    timeSlots: DynamicTimeSlots;
    messages: DynamicPriceMessages;
    errors: DynamicPriceError;
    month?: string | undefined;
    day?: string | undefined;
}

export enum DynamicPriceMessagesKeys {
    BookingFlowMessage = 'bookingFlowMessage',
    CalendarMessage = 'calendarMessage',
    DynamicPricingMessage = 'dynamicPricingMessage'
}
