import React, { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { AvoidSoftInputView } from 'react-native-avoid-softinput'
import { Icons } from 'assets'
import { Address } from 'lib/models'
import { modalHelpers } from 'lib/utils'
import { Measurements } from 'lib/common'
import { createStyles } from 'lib/styles'
import { useUserNewAddress } from 'features/user'
import { useStyles, useTranslations } from 'lib/hooks'
import { AnimatedModal, Button, NewAddressModalForm, Touchable, Typography } from 'lib/components'

type NativeNewAddressModalProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    onConfirm: VoidFunction,
    onSuccess: (newAddress: Address) => void
}

export const NativeNewAddressModal: React.FunctionComponent<NativeNewAddressModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    onSuccess
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const [closeNewAddressModal, setCloseNewAddressModal] = useState(false)
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const modalHeight = modalHelpers.modalHeight(modalHelpers.MODAL_HALF_HEIGHT + 1)
    const confirmModalHeight = Measurements.WindowHeight / 3
    const {
        submit,
        isLoading,
        isFilled,
        form: addressForm,
        hasError,
        isFetchingPostcode,
        fetchPostalCodesWithDebounce
    } = useUserNewAddress({
        onSuccess: address => {
            onSuccess(address)
            setCloseNewAddressModal(true)
        }
    })

    const showConfirmation = () => {
        setConfirmationModalOpen(true)
    }

    return (
        <React.Fragment>
            <AnimatedModal
                isVisible={isConfirmationModalOpen}
                backdropHeight={Measurements.WindowHeight - confirmModalHeight}
                onClose={() => setConfirmationModalOpen(false)}
            >
                {({ onModalClose }) => (
                    <View
                        style={{
                            ...styles.container,
                            height: confirmModalHeight,
                            paddingBottom: insets.bottom + theme.utils.gap(1)
                        }}
                    >
                        <View style={styles.confirmContent}>
                            <Icons.CheckCircle
                                size={40}
                                forceColor={theme.colors.orange}
                            />
                            <Typography.Subheading>
                                {T.components.nativeAddressSelectModal.addedTitle}
                            </Typography.Subheading>
                            <Typography.Regular>
                                {T.components.nativeAddressSelectModal.message}
                            </Typography.Regular>
                        </View>
                        <View style={styles.buttons}>
                            <View style={styles.button}>
                                <Button
                                    noBackground
                                    disabled={isLoading}
                                    onPress={() => {
                                        onModalClose()
                                    }}
                                    text={T.common.cancel}
                                />
                            </View>
                            <View style={styles.buttonSeparator} />
                            <View style={styles.button}>
                                <Button
                                    text={T.common.confirm}
                                    onPress={() => {
                                        onConfirm()
                                        onModalClose()
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                )}
            </AnimatedModal>
            <AnimatedModal
                isVisible={isOpen}
                backdropHeight={Measurements.WindowHeight - modalHeight}
                onClose={onClose}
            >
                {({ onModalClose }) => {
                    useEffect(() => {
                        if (closeNewAddressModal) {
                            onModalClose(showConfirmation)
                            setCloseNewAddressModal(false)
                        }
                    }, [closeNewAddressModal])

                    return (
                        <View
                            style={{
                                ...styles.container,
                                height: modalHeight
                            }}
                        >
                            <View style={styles.title}>
                                <Typography.Subheading>
                                    {T.screens.user.addAddress}
                                </Typography.Subheading>
                                <Touchable
                                    disabled={isLoading}
                                    onPress={() => {
                                        onModalClose()
                                    }}
                                    hitSlopBottom={20}
                                    hitSlopRight={20}
                                    hitSlopLeft={20}
                                    hitSlopTop={20}
                                >
                                    <Icons.Close size={18} />
                                </Touchable>
                            </View>
                            <AvoidSoftInputView showAnimationDuration={0}>
                                <ScrollView showsVerticalScrollIndicator={false}>
                                    <NewAddressModalForm
                                        form={addressForm}
                                        isLoading={isLoading}
                                        fetchPostalCodes={fetchPostalCodesWithDebounce}
                                    />
                                    <View style={styles.buttons}>
                                        <View style={styles.button}>
                                            <Button
                                                noBackground
                                                disabled={isLoading}
                                                onPress={() => {
                                                    onModalClose()
                                                }}
                                                text={T.common.cancel}
                                            />
                                        </View>
                                        <View style={styles.buttonSeparator} />
                                        <View style={styles.button}>
                                            <Button
                                                text={T.common.save}
                                                onPress={submit}
                                                isLoading={isLoading || isFetchingPostcode}
                                                disabled={!isFilled || hasError}
                                            />
                                        </View>
                                    </View>
                                </ScrollView>
                            </AvoidSoftInputView>
                        </View>
                    )
                }}
            </AnimatedModal>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.utils.gap(2)
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttons: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(3)
    },
    buttonSeparator: {
        width: theme.utils.gap(1)
    },
    button: {
        flex: 1
    },
    confirmContent: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-evenly'
    }
}))
