import React from 'react'
import { DimensionValue, ImageBackground, View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Icons } from 'assets'
import { stringHelpers } from 'lib/utils'
import { Grid, Touchable, Typography } from 'lib/components'
import { SendhelperService } from '../../types'

type MobileServiceTileProps = {
    isLast: boolean
    onShowStoresModal: VoidFunction
} & SendhelperService

export const MobileServiceTile: React.FunctionComponent<MobileServiceTileProps> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLast,
    onShowStoresModal,
    ...service
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Touchable
                onPress={() => {
                    service.isAvailable
                        ? service.onPress()
                        : onShowStoresModal()
                }}
                testID={stringHelpers.toKebabCase(`book-${service.name}`)}
            >
                <ImageBackground
                    imageStyle={styles.image}
                    source={service.image}
                    style={styles.imageContainer}
                >
                    <View style={styles.bookNow}>
                        <Typography.Regular
                            bold
                            forceColor={theme.colors.white}
                        >
                            {T.screens.allServices.bookNow}
                        </Typography.Regular>
                    </View>
                </ImageBackground>
            </Touchable>
            <Grid.Gap
                gapTop={1}
                gapBottom={2}
            >
                <Typography.Regular
                    bold
                    numberOfLines={1}
                >
                    {service.name}
                </Typography.Regular>
            </Grid.Gap>
            <Touchable
                style={styles.iconContainer}
                onPress={() => service.onLearnMore()}
            >
                <Typography.Regular style={styles.learnMore}>
                    {T.screens.allServices.learnMore}
                </Typography.Regular>
                <View
                    style={{
                        ...styles.chevron,
                        transform: [
                            {
                                rotate: '-90deg'
                            }
                        ]
                    }}
                >
                    <Icons.Chevron
                        size={10}
                        forceColor={theme.colors.orange}
                    />
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        // It is safe here we are not using this screen on native
        width: 'calc((100% / 3) - 10px)' as DimensionValue,
        marginBottom: theme.utils.gap(4)
    },
    image: {
        borderRadius: 8
    },
    imageContainer: {
        width: '100%',
        height: 109,
        borderRadius: 8,
        justifyContent: 'flex-end'
    },
    bookNow: {
        height: 38,
        backgroundColor: theme.colors.sun,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    learnMore: {
        color: theme.colors.orange,
        textDecorationLine: 'underline'
    },
    chevron: {
        marginLeft: theme.utils.gap(1)
    }
}))
