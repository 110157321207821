import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

export const UserPasswordRequirements: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.Regular
                bold
                style={styles.title}
            >
                {T.screens.user.newPasswordRequirementsTitle}
            </Typography.Regular>
            {Object.values(T.screens.user.newPasswordRequirements).map((item, index) => (
                <View
                    key={index}
                    style={styles.listItem}
                >
                    <View style={styles.dot} />
                    <Typography.Label>
                        {item}
                    </Typography.Label>
                </View>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderRadius: 8,
        backgroundColor: theme.colors.yellow,
        marginTop: theme.utils.gap(2),
        marginRight: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        padding: theme.utils.gap(2)
    },
    title: {
        marginBottom: theme.utils.gap(2)
    },
    listItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1) / 2
    },
    dot: {
        width: 4,
        height: 4,
        borderRadius: 2,
        marginRight: theme.utils.gap(1),
        backgroundColor: theme.colors.night
    }
}))
