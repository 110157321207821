import React, { useState } from 'react'
import { View } from 'react-native'
import { jobHelpers } from 'lib/utils'
import { CallToActionButton, PhotoGallery } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomerChargeStatus, JobStatus, JobType } from 'lib/types'
import { JobName } from './JobName'
import { JobRating } from './JobRating'
import { JobDetailsResponse } from '../types'
import { ServiceLocation } from './ServiceLocation'
import { SubmittedMessage } from './SubmittedMessage'
import { BookingsProposals } from './BookingsProposals'
import { AdditionalInformation } from './AdditionalInformation'
import { ServiceDetailsAccepted } from './ServiceDetailsAccepted'
import { AirConditionServiceDetails } from './AirConditionServiceDetails'
import { AirConditionServiceDate } from './AirConditionServiceDate'
import { AirConditionPriceDetails } from './AirConditionPriceDetails'
import { AirConditionRecurringDetails } from './AirConditionRecurringDetails'
import { Job } from 'lib/models'
import { RescheduleAirconSession } from './RescheduleAirconSession'
import { AirconCancelRequest } from './AirconCancelRequest'
import { RescheduledMessage } from './RescheduledMessage'
import { isAirconParentSubscription } from 'features/bookings/utils/airconUtils'

type AirConditionDetailsContentProps = {
    data: JobDetailsResponse,
    jobStatus?: JobStatus,
    allJobsData: Array<Job>,
}

export const AirConditionDetailsContent: React.FunctionComponent<AirConditionDetailsContentProps> = ({
    data,
    jobStatus,
    allJobsData
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isSubmitted = data.job.status === JobStatus.Submitted
    const isCompletedOrRated = data.job.status === JobStatus.Completed || data.job.status === JobStatus.Rated

    const [isGalleryOpen, setIsGalleryOpen] = useState(false)
    const photos = data.job.photos
    const isRecurringAccepted =
        data.job.job_type === JobType.Recurring &&
        (data.job.status === JobStatus.Accepted || data.job.status === JobStatus.Paused) &&
        data && !data.job.recurring_job

    const sessions = allJobsData
        .filter(item => data.job._id === item.recurring_job && item.status === JobStatus.Accepted)
        .sort((firstDate, secondDate) => firstDate.date_time.localeCompare(secondDate.date_time))

    const currentSessionIndex = sessions.findIndex(session => session._id === data.job._id)
    const isFirstSession = currentSessionIndex === 0
    const previousSession = sessions[ !isFirstSession ? currentSessionIndex - 1 : 0]

    const isPreviousSessionCompleted  = previousSession?.status === JobStatus.Completed || isFirstSession

    const isRescheduleDisabled = !data.job.recurring_job ? true : !isPreviousSessionCompleted

    const customerHasPendingCharges = (customerJob: Job) => !!customerJob.customer_charges?.some(item => item.auth_status === CustomerChargeStatus.Pending) && customerJob.status === JobStatus.Accepted
    const isAirconParentSubscriptionJob = isAirconParentSubscription(data.job)

    return (
        <View style={styles.wrapper}>
            {isRecurringAccepted ? (
                <AirConditionRecurringDetails response={data} allJobsData={allJobsData} />
            ) : (
                <View style={styles.contentWrapper}>
                    <View style={styles.content}>
                        <JobName
                            job={data}
                            isRecurring={false}
                        />
                        <RescheduledMessage response={data} />
                        {isSubmitted && (
                            <SubmittedMessage />
                        )}
                        <AirConditionServiceDate
                            job={data}
                            isSubmitted={isSubmitted}
                        />
                        <AirConditionServiceDetails job={data} />
                        <AirConditionPriceDetails
                            job={data}
                        />
                        <RescheduleAirconSession disabled={isRescheduleDisabled} data={data} />
                        <AdditionalInformation job={data} />
                        {photos?.length > 0 && (
                            <View style={styles.buttonWrapper}>
                                <CallToActionButton
                                    onPress={() => setIsGalleryOpen(true)}
                                    text={T.common.viewImages}
                                />
                            </View>
                        )}
                        <PhotoGallery
                            photos={photos}
                            isVisible={isGalleryOpen}
                            onClose={() => setIsGalleryOpen(false)}
                        />
                        <ServiceLocation job={data.job} />
                        {(!customerHasPendingCharges(data.job) || isAirconParentSubscriptionJob) && !isCompletedOrRated && (
                            <AirconCancelRequest
                                job={data}
                                text={isAirconParentSubscriptionJob ? T.screens.jobDetails.cancelPlan : T.screens.jobDetails.cancelSession}
                                jobStatus={jobStatus}
                                hideButton={jobHelpers.isCancelButtonHiddenForChildJob(data)}
                            />
                        )}
                    </View>
                    {jobStatus === JobStatus.Completed && (
                        <JobRating job={data} />
                    )}
                </View>
            )}
            {isSubmitted
                ? (
                    <BookingsProposals
                        job={data}
                        isRecurring={false}
                    />
                ) : (
                    <React.Fragment>
                        <ServiceDetailsAccepted
                            job={data}
                            sessions={sessions}
                            jobStatus={jobStatus}
                            isRecurringAccepted={false}
                        />
                    </React.Fragment>
                )
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'flex-start',
            xs: undefined
        },
        marginTop: -theme.utils.gap(6),
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    contentWrapper: {
        flex: 1
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    buttonWrapper: {
        width: {
            lg: 240,
            xs: undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    billingWrapper: {
        marginTop: theme.utils.gap(2)
    }
}))
