import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useHive } from '@propertyguru/hive'
import { TrendsDownwardOutline } from '@propertyguru/hive-icons'
import { APP_CONFIG } from 'lib/config'
import { HiveMiniCalendar } from 'lib/native/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { useNativeDynamicPriceSlotsAtom } from 'lib/native/atoms/dynamicPriceSlots'
import { Segment } from 'lib/analytics'
import { useNativeJobBookingDetailAtom } from 'lib/native/atoms'

type Props = {
    onSelectBookingDate?: (date: Date) => void;
}

export const CalendarWrapper: React.FunctionComponent<Props> = ({ onSelectBookingDate }) => {
    const hive = useHive()
    const { styles } = useStyles(stylesheet)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const [dynamicPriceSlots, setDynamicPriceSlots] = useNativeDynamicPriceSlotsAtom()

    useEffect(() => {
        const convertedDate = dateHelpers.convertToTimeZone(selectedDate)

        if (dynamicPriceSlots.daySelected !== convertedDate) {
            setDynamicPriceSlots({
                ...dynamicPriceSlots,
                daySelected: convertedDate
            })
        }

        if(dynamicPriceSlots?.slots) {
            setJobBookingDetailAtom({
                ...jobBookingDetailAtom,
                isDateInDynamicPriceSlot: dynamicPriceSlots?.slots[convertedDate] !== undefined
            })
        }

        onSelectBookingDate && onSelectBookingDate(selectedDate)

    }, [selectedDate, dynamicPriceSlots.daySelected])

    const minDate = new Date()
    const maxDate = useMemo(() => {
        const date = new Date()
        date.setFullYear(date.getFullYear() + 1)

        return APP_CONFIG?.DATE_TIME_SUNSET ? new Date(APP_CONFIG?.DATE_TIME_SUNSET) : date
    }, [])

    const dates = Object.keys(dynamicPriceSlots?.slots || {})
    const daysWithIcons = dates.map(date => ({
        date: dateHelpers.convertToDate(date),
        icon: <TrendsDownwardOutline fill={hive.color('fill/success/primary')} />
    }))

    const onDateSelect = (date: Date) => {
        setSelectedDate(date)
        Segment.bookingDateAndTimeSelected({})
        Segment.bookingDateAndTimeModuleClosed({ dateSelected: Boolean(date.toISOString()) })
    }

    const calendarProps = {
        selectedDate,
        minDate,
        maxDate,
        daysWithIcons,
        dayNamesWithIcons: [],
        onDateSelect
    }

    return (
        <View style={styles.container}>
            <View style={styles.section}>
                <View style={styles.calendarWrapper}>
                    <HiveMiniCalendar {...calendarProps} />
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        overflow: 'hidden',
        paddingBottom: 4
    },
    section: {
        backgroundColor: theme.colors.white,
        shadowColor: theme.colors.shadowSoft,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        elevation: 4
    },
    calendarWrapper: {
        paddingVertical: theme.utils.hive.spacing('x5')
    }
}))
