// TODO: TO REFACTOR THIS WHENEVER WE HAVE TIME, SOME OF THE THINGS ARE COMMENTED HERE FOR NOW.
// import qs from 'qs'
// import { useRoute } from '@react-navigation/native'
import {
    useCancelReasonAtom,
    // useCancelReasonAtom,
    // useCreditsPackageAtom,
    useToastAtom
} from 'lib/atoms'
// import { linkingHelpers } from 'lib/utils'
import { NotificationType } from 'lib/types'
import { GoogleAnalytics } from 'lib/analytics'
import { useTranslations } from 'lib/hooks'
import { useGetCancelationReasons, useGetMe } from '../actions'
// TODO: importing from features/auth copying entirely would duplicate code, need to decouple to general util
import { cookieUtils } from 'features/auth'
import { useNativeIsAuthorizedAtom } from 'lib/native/atoms/auth'
import { useNativeUserTokenAtom } from 'lib/native/atoms/token'
// import { ScreenNamesNative } from 'lib/native/routing/screens'
// import { NativeNavigationParams } from 'lib/native/routing'
import { useNativeUserAtom } from 'lib/native/atoms/user'
import { useNativeGetErrorMessage } from 'lib/native/atoms/useNativeGetErrorMessage'
import { cancelReasonHelpers } from 'lib/utils'
// import { useGetMe } from 'features/auth/actions'

export const useGetProfileData = (onError: (error: string) => void) => {
    const T = useTranslations()
    const [, setNativeUser] = useNativeUserAtom()
    const { getErrorMessage } = useNativeGetErrorMessage()
    const [, setNativeIsAuthorized] = useNativeIsAuthorizedAtom()
    const [, setNativeUserToken] = useNativeUserTokenAtom()
    // const [, setCreditsPackage] = useCreditsPackageAtom()
    const [, setToastMessage] = useToastAtom()
    const [, setCancelReason] = useCancelReasonAtom()
    // const route = useRoute<NativeNavigationParams<ScreenNamesNative.LandingNative>>()
    const { mutate: getMe, isLoading: isFetchingProfile } = useGetMe()
    const { mutateAsync: getCancellationReasons } = useGetCancelationReasons()
    // const { mutateAsync: getCreditPackages, isLoading: isFetchingCreditPackages } = useGetCreditPackages()

    return {
        isFetchingProfile,
        getMe: (token: string) => getMe({
            token
        }, {
            onSuccess: async ({ data }) => {
                try {
                    const cancellationReasonsResponse = await getCancellationReasons({ token })
                    // const creditPackagesResponse = await getCreditPackages({ token })
                    const cancelReason = cancelReasonHelpers.appendTheReasonOther(cancellationReasonsResponse.data.job_cancel_reasons)
                    setNativeUser(data.profile)
                    setNativeUserToken(data.token)
                    setNativeIsAuthorized(true)
                    setCancelReason(cancelReason)
                    // setCreditsPackage(creditPackagesResponse.data.package_list)
                    // cookieUtils.setMarketingConsentCookie(data.profile)

                    // TODO: are we keeping the old screen?, if not we can remove this
                    // if (isNative && data.profile?.locations?.length === 0) {
                    //     navigation.navigate(ScreenNames.AddressSetup)
                    // }

                    GoogleAnalytics.setUserId(data.token)
                    cookieUtils.setAuthCookies({
                        userId: data.token,
                        userEmail: data.profile.email_id,
                        userAddresses: data.profile.locations,
                        userName: data.profile.handle
                    })

                    // const shouldBeRedirectedToBooking = Boolean(route.params?.service && route.params?.postalcode)

                    // if (!shouldBeRedirectedToBooking) {
                    //     return
                    // }

                    // const { url } = linkingHelpers.getInternalPathForBooking(
                    //     `/${linkingHelpers.BOOK_URL}?service=${route.params?.service}&postalcode=${route.params?.postalcode}`
                    // )

                    // if (!url)  {
                    //     return
                    // }

                    // linkingHelpers.openUrl(`${url}?${qs.stringify(route.params)}`, false)
                } catch (error) {
                    console.error('error catch', error)
                    setToastMessage({
                        message: T.common.errorMessage,
                        type: NotificationType.Error
                    })
                }
            },
            onError: response => onError(getErrorMessage(response))
        })
    }
}
