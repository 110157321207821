import React from 'react'
import { View } from 'react-native'
import { Button, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetails } from 'lib/models'
import { createStyles, theme } from 'lib/styles'
import { jobHelpers, R } from 'lib/utils'
import { NoticeOfReschedulingMessage } from './NoticeOfReschedulingMessage'
import { JobDetailsResponse } from '../../types'
import { ExtendSession } from '../ExtendSession'
import { CancelRequest } from '../CancelRequest'
import { RescheduleSession } from '../RescheduleSession'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'
import { RescheduleAirconSession } from '../RescheduleAirconSession'

type NextSessionActionsProps = {
    jobDetails?: JobDetails,
    jobDetailsResponse?: JobDetailsResponse,
}

export const NextSessionActions: React.FunctionComponent<NextSessionActionsProps> = ({ jobDetails, jobDetailsResponse }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isAcceptedSessionJobForSubscription = jobDetailsResponse ?
        jobHelpers.isAcceptedSessionJobForSubscription(jobDetailsResponse) : false

    const shouldDisableRescheduleSessionJob = jobDetailsResponse ?
        jobHelpers.shouldDisableRescheduleSessionJob(jobDetailsResponse) : false
    const isAirconSubscriptionJob = jobDetails && isAirconSubscription(jobDetails)
    const shouldShowAddHoursNote = jobDetails && !isAirconSubscriptionJob

    return (
        <React.Fragment>
            <View style={styles.wrapper}>
                {jobDetails ? (
                    <>
                        {isAirconSubscriptionJob ?
                            <RescheduleAirconSession data={{ job: jobDetails } as JobDetailsResponse} fullWidth disabled={shouldDisableRescheduleSessionJob}/> :
                            <RescheduleSession data={{ job: jobDetails } as JobDetailsResponse} fullWidth disabled={shouldDisableRescheduleSessionJob}/>
                        }
                        <View style={styles.jobActionsContainer}>
                            { !isAirconSubscriptionJob &&
                        (

                            <View style={styles.extendSessionContainer}>
                                <ExtendSession
                                    fullWidth
                                    containerStyle={styles.addHoursContainer}
                                    data={{ job: jobDetails } as JobDetailsResponse}
                                />
                                {shouldShowAddHoursNote && (
                                    <View style={styles.infoContainer}>
                                        <Typography.Label
                                            forceColor={theme.colors.fog}
                                            style={styles.addHoursInfo}
                                        >
                                            {T.components.currentJobSession.addHoursInfo}
                                        </Typography.Label>
                                    </View>
                                )}
                            </View>

                        )}
                        </View>
                    </>
                ) : (
                    <View style={styles.buttonWrapper}>
                        <Button
                            noBackground
                            isLoading
                            text={T.components.currentJobSession.addHours}
                            onPress={R.T}
                        />
                    </View>
                )}
            </View>
            {
                shouldDisableRescheduleSessionJob && <NoticeOfReschedulingMessage/>
            }
            {jobDetails && jobDetailsResponse && !isAirconSubscription(jobDetails) && (
                <CancelRequest
                    jobStatus={jobDetails.status}
                    job={jobDetailsResponse}
                    text={T.components.currentJobSession.cancel}
                    hideButton={isAcceptedSessionJobForSubscription}
                />
            )}
        </React.Fragment>
    )
}

const stylesheet = createStyles(() => ({
    wrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        flex: 1,
        gap: {
            lg: theme.utils.gap(2)
        },
        marginTop: theme.utils.gap(1)
    },
    spacer: {
        width: {
            xl: theme.utils.gap(2),
            lg: 0
        }
    },
    jobActionsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flex: 1
    },
    extendSessionContainer: {
        flex: 1,
        width: '100%'
    },
    addHoursContainer: {
        flex: undefined,
        marginBottom: 0
    },
    buttonWrapper: {
        width: {
            ':w[1250, ]': 240,
            ':w[, 1250]': undefined
        }
    },
    infoContainer: {
        flex: 1,
        marginTop: theme.utils.gap(1)
    },
    addHoursInfo: {
        fontSize: 12
    },
    cancelWrapper: {
        alignSelf: 'center'
    },
    cancel: {
        textDecorationLine: 'underline'
    }
}))
