import { Dimensions } from 'react-native'
import { createStyles } from 'lib/styles'

export const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    main: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    },
    footerBtnNext: {
        flex: 1
    },
    formContainer: {
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string,
        paddingVertical: theme.utils.hive.spacing('x12'),
        paddingHorizontal: theme.utils.hive.spacing('x4')
    },
    addressFieldSpacing: {
        marginTop: theme.utils.hive.spacing('x3')
    },
    suggestions: {
        position: 'absolute',
        marginTop: 3 * theme.utils.hive.spacing('x12') - theme.utils.hive.spacing('x4'),
        zIndex: 100,
        elevation: 100,
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string,
        borderRadius: theme.utils.hive.borderRadius('medium'),
        borderWidth: 1,
        borderColor: theme.utils.hive.color('border/active/primary') as string,
        width: Dimensions.get('window').width - 2 * theme.utils.hive.spacing('x4'),
        maxHeight: 8 * theme.utils.hive.spacing('x10'),
        marginHorizontal: theme.utils.hive.spacing('x4')
    },
    suggestion: {
        padding: theme.utils.hive.spacing('x4'),
        borderBottomWidth: 1,
        borderBottomColor: theme.utils.hive.color('border/active/primary') as string,
        flex: 1
    },
    addressNotesContainer: {
        marginTop: theme.utils.hive.spacing('x6'),
        gap: theme.utils.hive.spacing('x4')
    },
    petsContainer: {
        marginTop: theme.utils.hive.spacing('x10'),
        marginBottom: theme.utils.hive.spacing('x12')
    },
    petLabel: {
        marginBottom: theme.utils.hive.spacing('x3')
    },
    chipBoxes: {
        flexDirection: 'row',
        marginTop: theme.utils.hive.spacing('x6'),
        columnGap: theme.utils.hive.spacing('x2')
    },
    serviceDetailBanner: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.hive.spacing('x2'),
        padding: theme.utils.hive.spacing('x4'),
        backgroundColor: theme.utils.hive.color('fill/success/secondary') as string
    },
    serviceDetailText: {
        flex: 1
    },
    underline: {
        textDecorationLine: 'underline'
    },
    addressNotesButtonWrapper: {
        flexDirection: 'row',
        flexBasis: 'auto',
        paddingTop: theme.utils.hive.spacing('x3')
    },
    addressNotesButton: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: theme.utils.hive.spacing('x2')
    },
    addressNotesTitleWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    unitRow: {
        flexDirection: 'row',
        gap: theme.utils.hive.spacing('x3'),
        paddingTop: theme.utils.hive.spacing('x3')
    },
    unitInput: {
        flex: 1
    },
    divider: {
        height: theme.utils.hive.spacing('x3'),
        width: Dimensions.get('window').width,
        marginLeft: -theme.utils.hive.spacing('x4')
    },
    additionalInfoContainer: {
        paddingVertical: theme.utils.hive.spacing('x12'),
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string
    },
    remarksInput: {
        marginTop: theme.utils.hive.spacing('x3')
    }
}))
