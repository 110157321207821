import { useNativeJobBookingDetailAtom } from 'lib/native/atoms'
import { dateHelpers } from 'lib/utils'
import { AnyDate } from 'lib/utils/date'

export const useCleaningRecurringSchedule = () => {
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()

    const handleDatesChange = (datesToBeUpdated: Array<{date: AnyDate, day: string}>, fortnightly: boolean, uiSchedule:  Array<{date: AnyDate, day: string}>) => {
        const updatedSchedule = jobBookingDetailAtom.booking?.schedule?.map(schedule => {
            const dateToBeUpdated = datesToBeUpdated.find(date => date.day === schedule.day)?.date as string
            const localDateToBeUpdated = uiSchedule?.find(date => date.day === schedule.day)?.date as string

            if(dateToBeUpdated) {
                return {
                    ...schedule,
                    activated: true,
                    start_time: dateToBeUpdated,
                    ui_start_time: localDateToBeUpdated,
                    fortnightly
                }
            }

            return {
                ...schedule,
                activated: false,
                fortnightly: false
            }
        })

        const startDate = jobBookingDetailAtom.booking?.date as string
        const bookingData = {
            ...jobBookingDetailAtom.booking,
            schedule: updatedSchedule
        }

        if (startDate) {
            const dayOfWeek = dateHelpers.getDayOfDateShort(startDate)
            const startSelectedDate = datesToBeUpdated.find(date => date.day === dayOfWeek)?.date as string
            bookingData.date = startSelectedDate
        }

        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            booking: bookingData
        })
    }

    return {
        handleDatesChange
    }

}
