import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { MoversAndPackersAddonsFields } from './forms'

export const useMoversAndPackersAddonsForm = () => {
    const T = useTranslations()

    const movers = useField<number>({
        key: MoversAndPackersAddonsFields.Movers,
        initialValue: 0,
        isRequired: false,
        label: T.screens.moversAndPackersSummary.formFields.movers.label
    })

    const tailgating = useField<boolean>({
        key: MoversAndPackersAddonsFields.Tailgating,
        initialValue: false,
        isRequired: false,
        label: T.screens.moversAndPackersSummary.formFields.tailgating.label
    })

    const wrapping = useField<number>({
        key: MoversAndPackersAddonsFields.Wrapping,
        initialValue: 0,
        isRequired: false,
        label: T.screens.moversAndPackersSummary.formFields.wrapping.label
    })

    const pets = useField<string>({
        key: MoversAndPackersAddonsFields.Pets,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.moversAndPackersSummary.formFields.pets.label
    })

    const comment = useField<string>({
        key: MoversAndPackersAddonsFields.Comment,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.moversAndPackersSummary.formFields.comment.placeholder
    })

    const promoCode = useField<string>({
        key: MoversAndPackersAddonsFields.PromoCode,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.moversAndPackersSummary.formFields.promoCode.placeholder
    })

    return {
        movers,
        tailgating,
        wrapping,
        pets,
        comment,
        promoCode
    }
}
