import { Button } from '@propertyguru/hive-button'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { View } from 'react-native'

type LoginSignUpButtonsProps = {
    onLoginClick: () => void,
    onSignUpClick: () => void
}

export const LoginSignUpButtons = ({ onLoginClick, onSignUpClick }: LoginSignUpButtonsProps) => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()

    return(
        <View>
            <View style={styles.wrapper}>
                <View style={styles.buttonWrapper}>
                    <Button
                        text={T.common.login}
                        type="secondary"
                        onPress={onLoginClick}
                        accessibilityLabel={T.accessibility.native.landingScreen.loginButton}
                    />
                </View>
                <View style={styles.buttonWrapper}>
                    <Button
                        text={T.common.signUp}
                        onPress={onSignUpClick}
                        accessibilityLabel={T.accessibility.native.landingScreen.signUpButton}
                    />
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: theme.utils.gap(1.6),
        paddingHorizontal: theme.utils.gap(2)
    },
    buttonWrapper: {
        flex: 1
    }
}))
