import { HousingType } from 'lib/native/models'
import { HousingType as AddressHousingType } from 'lib/native/types'

export const ADDRESS_HOUSING_TYPE_MAP = {
    [HousingType.Condominium]: AddressHousingType.Condominium,
    [HousingType.HDB]: AddressHousingType.HDB,
    [HousingType.LandedProperty]: AddressHousingType.Landed,
    [HousingType.ShophouseHome]: AddressHousingType.Other
}

export const ADDRESS_HOUSING_TYPE_MAP_REVERSE = Object.entries(ADDRESS_HOUSING_TYPE_MAP).reduce((acc, [key, value]) => {
    acc[value] = key as HousingType

    return acc
}
, {} as Record<AddressHousingType, HousingType>)
