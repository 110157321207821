import { ProviderDetails } from 'lib/models'
import { TrackingSessionData } from 'lib/types'

export type VerifyEmailRequest = {
    email_id: string
}

export type SignUpWithEmailRequest = TrackingSessionData & {
    email_id: string,
    password: string,
    handle: string,
    phone: string,
    provider_details: ProviderDetails.Email,
    anonymous_id?: string,
    marketing_consent_accepted?: boolean,
}

export type SignUpWithFacebookRequest = TrackingSessionData & {
    email_id: string,
    password: string,
    handle: string,
    phone: string,
    provider_details: {
        provider: ProviderDetails.Facebook,
        provider_access_token: string
    },
    anonymous_id?: string,
    marketing_consent_accepted?: boolean,
}

export type SignUpWithAppleRequest = TrackingSessionData & {
    email_id: string,
    password: string,
    handle: string,
    phone: string,
    provider_details: {
        apple_user_id: string
        provider: ProviderDetails.Apple,
        provider_access_token: string
    },
    anonymous_id?: string,
    marketing_consent_accepted?: boolean,
}

export type SignUpWithGoogleRequest = {
    email_id: string,
    handle: string,
    phone: string,
    password: string,
    provider_details: {
        account_id: string,
        provider: ProviderDetails.Google,
        provider_access_token: string
    },
    marketing_consent_accepted?: boolean
}

export type GetMeRequest = {
    token: string
}

export type SignInWithEmailRequest = TrackingSessionData & {
    email_id: string,
    password: string,
    anonymous_id?: string,
    provider_details: {
        provider: ProviderDetails.Email
    }
}

export type SignInWithFacebookRequest = TrackingSessionData & {
    provider_details: {
        provider: ProviderDetails.Facebook,
        provider_access_token: string,
        anonymous_id?: string,
    }
}

export type SignInWithAppleRequest = TrackingSessionData & {
    provider_details: {
        apple_user_id: string,
        provider: ProviderDetails.Apple,
        provider_access_token: string,
        anonymous_id?: string,
    }
}

export type SignInWithGoogleRequest = {
    email_id: string,
    provider_details: {
        account_id: string,
        provider: ProviderDetails.Google,
        provider_access_token: string
    }
}

export enum ChangePasswordAction {
    SetNewPassword = 'set_new_password',
    SendEmail = 'send_email'
}

export type ForgotPasswordRequest = {
    email: string,
    action: ChangePasswordAction.SendEmail
} | {
    action: ChangePasswordAction.SetNewPassword,
    new_password: string,
    temp_password: string,
    token: string
}

export type GetProfileDataRequest = {
    token: string
}
