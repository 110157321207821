import React from 'react'
import { Image, View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { SendhelperService } from 'features/bookings'
import { Touchable, Typography } from 'lib/components'
import { Error } from 'lib/components/typography/Error'

type NativeServiceTileProps = {
    onPress: VoidFunction
    testID?: string
} & SendhelperService

export const NativeServiceTile: React.FunctionComponent<NativeServiceTileProps> = ({
    onPress,
    image,
    isNew,
    name,
    testID
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable onPress={onPress} testID={testID}>
            <View style={styles.container}>
                <View style={styles.imageContainer}>
                    <Image
                        source={image}
                        resizeMode="cover"
                        style={styles.image}
                    />
                    {
                        isNew && (
                            <Error
                                bold
                                forceColor={theme.colors.white}
                                style={styles.badgeContainer}
                            >
                                <Typography.Regular bold style={styles.newText}>
                                    {T.common.new}
                                </Typography.Regular>
                            </Error>
                        )
                    }
                </View>
                <Typography.Regular bold>
                    {name}
                </Typography.Regular>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(1),
        width: Math.floor((Measurements.WindowWidth - theme.utils.gap(2)) / 3)
    },
    badgeContainer: {
        backgroundColor: theme.colors.flame,
        position: 'absolute',
        paddingTop: theme.utils.gap(1)/1.5,
        paddingBottom: theme.utils.gap(0),
        paddingHorizontal: theme.utils.gap(1),
        borderBottomRightRadius: 8,
        borderTopLeftRadius: 8
    },
    imageContainer: {
        position: 'relative'
    },
    image: {
        height: 110,
        width: '100%',
        borderRadius: 8,
        marginBottom: theme.utils.gap(1) / 2
    },
    newText: {
        color: theme.colors.white
    }
}))
