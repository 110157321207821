import React, { useEffect, useState } from 'react'
import { ScrollView, View, Image } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { Divider } from '@propertyguru/hive-divider'
import { Icon } from '@propertyguru/hive-icon'
import {
    ChevronLeftOutline,
    ShieldProtectSecurityCheckOutline,
    StarAiSparkleOutline
} from '@propertyguru/hive-icons'
import { Button } from '@propertyguru/hive-button'
import { Text } from '@propertyguru/hive-text'
import { useSegmentSession, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Images } from 'assets/native'
import { ScreenHeader } from 'lib/native/components'
import { useCleaningConfigs } from 'lib/native/hooks'
import {
    useNativeCleaningConfigsAtom,
    useNativeJobBookingDetailAtom,
    useNativeResetDynamicPriceSlotsAtom,
    useNativeResetJobBookingDetailAtom,
    useNativeResetJobQuotationsAtom,
    useNativeUserAtom
} from 'lib/native/atoms'
import { Address } from 'lib/native/models'
import { ScreenNamesNative } from 'lib/native/routing/screens'
import { CleaningInfo, Footer, SelectAddressModal } from 'features/native/bookings/components'
import { Segment, ServiceType } from 'lib/analytics'
import { ElementViewedElementType } from 'lib/analytics/segment/typewriter/segmentNative'

export const CleaningScreen: React.FunctionComponent = () => {
    useCleaningConfigs()
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const { segmentSession } = useSegmentSession()
    const [cleaningConfigsAtom] = useNativeCleaningConfigsAtom()
    const [jobBookingDetailAtom, setJobBookingDetailAtom] = useNativeJobBookingDetailAtom()
    const [user] = useNativeUserAtom()
    const bookingCount = user?.bookingCount || 0
    const isFirstTimeBooking = bookingCount === 0
    const [, resetJobQuotationsAtom] = useNativeResetJobQuotationsAtom()
    const [, resetJobBookingDetailAtom] = useNativeResetJobBookingDetailAtom()
    const [, resetDynamicPriceSlotsAtom] = useNativeResetDynamicPriceSlotsAtom()
    const locationsCount = user?.locations?.length || 0
    const hasAddress = locationsCount !== 0
    const [shouldShowAddressModal, setShouldShowAddressModal] = useState(false)
    const showAddressModal = () => {
        setShouldShowAddressModal(true)
        Segment.elementViewed({ elementType: ElementViewedElementType.AddressListViewed })
    }
    const hideAddressModal = () => setShouldShowAddressModal(false)
    const handleNext = () => {
        if (hasAddress) {
            return showAddressModal()
        }

        navigation.navigate(ScreenNamesNative.CleaningPropertyDetailsNative)
    }

    useEffect(() => {
        resetJobQuotationsAtom()
        resetJobBookingDetailAtom()
        resetDynamicPriceSlotsAtom()
    }, [])

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                serviceType: ServiceType.Cleaning,
                postcode: user.locations[0]?.postcode
            })
        }
    }, [segmentSession])
    const onSelectAddress = (address: Address | undefined) => {
        setJobBookingDetailAtom({
            ...jobBookingDetailAtom,
            address: address?._id ? address : undefined
        })
        Segment.bookingExistingAddressSelected({})
    }

    return (
        <View style={{ ...styles.container, paddingTop: insets.top }}>
            <ScreenHeader>
                <ScreenHeader.Button icon={ChevronLeftOutline} onPress={navigation.goBack} />
                <ScreenHeader.Title>
                    {T.screens.cleaningNative.pageTitle}
                </ScreenHeader.Title>
            </ScreenHeader>
            <Divider />
            <ScrollView style={styles.main}>
                <Image
                    source={Images.CleaningHero}
                    style={styles.heroImage}
                />
                <View style={styles.heroNotice}>
                    <Icon
                        icon={isFirstTimeBooking
                            ? ShieldProtectSecurityCheckOutline
                            : StarAiSparkleOutline
                        }
                        width={20}
                        height={20}
                    />
                    <Text typography="caption/xs">
                        {isFirstTimeBooking
                            ? T.screens.cleaningNative.heroNotice.firstTime
                            : T.screens.cleaningNative.heroNotice.returning
                        }
                    </Text>
                </View>
                <View style={styles.infoContainer}>
                    <Text typography="body/s" color="text/active/secondary">{T.screens.cleaningNative.infoContent}</Text>
                    <CleaningInfo />
                </View>
            </ScrollView>
            <Footer style={{ paddingBottom: insets.bottom }}>
                <Footer.Buttons>
                    <Button
                        disabled={cleaningConfigsAtom.isLoading}
                        loading={cleaningConfigsAtom.isLoading}
                        text={T.screens.cleaningNative.footerButtons.next}
                        style={styles.footerBtnNext}
                        onPress={handleNext}
                    />
                </Footer.Buttons>
            </Footer>
            <SelectAddressModal
                isOpen={shouldShowAddressModal}
                onClose={hideAddressModal}
                onSelectAddress={onSelectAddress}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/secondary') as string
    },
    main: {
        flex: 1,
        backgroundColor: theme.utils.hive.color('fill/neutral/primary') as string
    },
    heroImage: {
        width: '100%',
        height: 'auto',
        aspectRatio: 375 / 208
    },
    heroNotice: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.utils.hive.spacing('x2'),
        columnGap: theme.utils.hive.spacing('x1'),
        backgroundColor: theme.utils.hive.color('fill/active/secondary') as string
    },
    infoContainer: {
        paddingVertical: theme.utils.hive.spacing('x10'),
        paddingHorizontal: theme.utils.hive.spacing('x4'),
        rowGap: theme.utils.hive.spacing('x6')
    },
    footerBtnNext: {
        flex: 1
    }
}))
