import {
    parse,
    parseISO,
    fromUnixTime,
    addSeconds,
    differenceInSeconds,
    getUnixTime,
    format,
    addMonths,
    subMonths,
    formatISO,
    getDaysInMonth,
    getDay,
    isSameDay,
    isPast,
    addDays,
    startOfDay,
    addWeeks,
    isSameMonth,
    add,
    sub,
    set,
    isToday,
    isAfter,
    isValid,
    compareAsc,
    compareDesc,
    formatDistanceToNow,
    differenceInDays,
    hoursToMinutes,
    isSaturday,
    isSunday,
    differenceInMinutes,
    addMinutes,
    setSeconds,
    subHours
} from 'date-fns'
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { TimeOfDay, TimeSlot } from 'features/bookings'
import { calendarLanguages, Languages, timeZones } from 'lib/locale'
import { is } from './ramda'
import { BookingFrequency } from 'lib/types'
import { WEEKDAYS } from 'features/native/bookings/constants'

export {
    addDays,
    formatISO,
    getUnixTime,
    parse,
    isValid
}

export const YEAR_FORMAT = 'YYYY'
export const MONTH_YEAR_FORMAT = 'MM-YYYY'
export const DAY_MONTH_YEAR_FORMAT = 'DD-MM-YYYY'

const FORMAT_MAP = {
    [YEAR_FORMAT]: 'yyyy',
    [MONTH_YEAR_FORMAT]: 'MM-yyyy',
    [DAY_MONTH_YEAR_FORMAT]: 'dd-MM-yyyy'
}

export const WEEK_STARTS_ON = 0 // 0 for sunday
export const MAX_PAUSE_DURATION_DAYS_DEFAULT = 30
export type AnyDate = Date | number | string

// Date Formatting functions

export const toDateFnsDate = (date: AnyDate) => {
    const formattedDate = is(String, date)
        ? parseISO(date as string)
        : fromUnixTime(date as number)

    return formattedDate as Date
}
export const lastBookingDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'dd/MM/yy')
export const fullMonthAndYear = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'LLLL yyyy')
export const paymentDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'dd LLL yyyy')
export const startingDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEE, dd LLL, yyyy')
export const shortStartingDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'LLL dd, yyyy')
export const notificationDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEEE, LLL dd, yyyy')
export const notificationFullDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EE, LLL dd yyyy - HH:mm aa')

export const shortDateMonth = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEE, dd LLL')

// Date Manipulation and Calculation functions
export const nextMonth = (date: AnyDate) => formatISO(addMonths(toDateFnsDate(date), 1))
export const prevMonth = (date: AnyDate) => formatISO(subMonths(toDateFnsDate(date), 1))
export const getMonthDaysNumber = (date: AnyDate) => getDaysInMonth(toDateFnsDate(date))
export const getPreviousMonthDaysNumber = (date: AnyDate) => getDaysInMonth(sub(toDateFnsDate(date), { months: 1 }))
export const getDayOfWeek = (date: AnyDate) => getDay(toDateFnsDate(date))
export const getDayNumberOfFirstWeek = (date: AnyDate) => getDayOfWeek(formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'yyyy-MM-01'))
export const getIsSameDay = (firstDate: AnyDate, secondDate: AnyDate) => isSameDay(toDateFnsDate(firstDate), toDateFnsDate(secondDate))
export const indexToDayNumber = (index: number) => index.toString().padStart(2, '0')
export const calendarDayDate = (date: AnyDate, dayNumber: number) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, `yyyy-MM-${indexToDayNumber(dayNumber)}`)
export const calendarIsPast = (date: AnyDate) => isPast(addDays(toDateFnsDate(date), 1))
export const isDatePast = (date: AnyDate) => isPast(toDateFnsDate(date))
export const isSGTDatePast = (date: AnyDate) => isPast(subHours(zonedTimeToUtc(toDateFnsDate(date), timeZones.ASIA_SINGAPORE), 2))

// Time-related functions
export const selectTimeLabel = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'hh:mm aa')
export const selectTimeLabelWithoutTimezone = (date: AnyDate) => format(toDateFnsDate(date), 'hh:mm aa')
export const getDateTime = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'HH:mm')
export const selectTimeValue = (date: AnyDate, timeOffset?: number) => formatISO(add(startOfDay(toDateFnsDate(date)), { minutes: timeOffset }))
export const selectTimeLabelWithTimeZone = (date: AnyDate) => format(toDateFnsDate(date), '(z hh:mm aa)')
export const durationTimeLabelWithoutTimezone = (date: AnyDate, hoursDuration: number = 0) => {
    const fnsDate = toDateFnsDate(date)
    const newDate = setSeconds(addMinutes(fnsDate, hoursDuration * 60), 0)

    return format(newDate, 'hh:mm aa')
}

// Calendar-related functions
export const getMonthName = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'LLLL yyyy', { locale: calendarLanguages[locale] })
export const getWeekDayNames = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEEEEE', { locale: calendarLanguages[locale] })
export const getWeekDayShortNames = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEEEEE', { locale: calendarLanguages[locale] }).charAt(0)
export const getWeekDayLongNames = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEE', { locale: calendarLanguages[locale] })
export const getWeekDayFullNames = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEEE', { locale: calendarLanguages[locale] })
export const getFullBookingDate = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEE, dd LLL, yyyy - hh:mm aa', { locale: calendarLanguages[locale] })
export const createSessions = (date: AnyDate, numberOfWeeksToAdd: number) => formatISO(addWeeks(toDateFnsDate(date), numberOfWeeksToAdd))
export const getFullBookingListViewDate = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEEE, dd LLL yyyy', { locale: calendarLanguages[locale] })
export const getMonth = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'yyyy-MM-01')
export const isSameMonthAndYear = (dateLeft: AnyDate, dateRight: AnyDate) => isSameMonth(toDateFnsDate(dateLeft), toDateFnsDate(dateRight))
export const calendarDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, `yyyy-MM-dd`)

// Time Calculation functions
export const addSecondsToNow = (seconds: number) => getUnixTime(addSeconds(new Date(), seconds))
export const differenceInSecondsToNow = (date: AnyDate) => differenceInSeconds(toDateFnsDate(date), new Date())
export const currentYear = () => format(new Date(), 'yyyy')
export const addHoursToDate = (date: AnyDate, amount: number) => {
    // 'add' and 'addHours' rounds up the decimals
    // converting to minutes to get more precise results
    const minutesAmount = hoursToMinutes(amount)

    return formatISO(add(toDateFnsDate(date), { minutes: minutesAmount }))
}
export const subHoursFromDate = (date: AnyDate, amount: number) => formatISO(sub(toDateFnsDate(date), { hours: amount }))
export const addDaysToDate = (date: AnyDate, amount: number) => formatISO(add(toDateFnsDate(date), { days: amount }))
export const subDaysFromDate = (date: AnyDate, amount: number) => formatISO(sub(toDateFnsDate(date), { days: amount }))

// Date Comparison functions
export const checkIfIsToday = (date: AnyDate) => isToday(toDateFnsDate(date))
export const checkIfIsAfter = (dateOne: AnyDate, dateTwo: AnyDate) => isAfter(toDateFnsDate(dateOne), toDateFnsDate(dateTwo))
export const isDateDaySaturday = (date: AnyDate) => isSaturday(toDateFnsDate(date))
export const isDateDaySunday = (date: AnyDate) => isSunday(toDateFnsDate(date))

// Calendar Label functions
export const shortDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'LLL dd')
export const shortFullDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEE, LLL dd, yyyy')
export const shortFullDateWithoutYear = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEE, LLL dd')
export const multiSelectDateLabel = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'do LLLL', { locale: calendarLanguages[locale] })
export const inspectionDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'dd LLL')

// Time Zone functions
export const getTimeZone = () => format(toDateFnsDate(new Date()), 'z')
export const selectTimeFormat = (date: AnyDate) => formatISO(toDateFnsDate(date))

// Time Difference functions
export const getStartOfDay = (date: AnyDate) => startOfDay(toDateFnsDate(date))
export const getPauseDates = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEEE LLL dd', { locale: calendarLanguages[locale] })
export const getDaysDifferenceFromNow = (date: AnyDate) => differenceInDays(new Date(), toDateFnsDate(date))
export const differenceInMinutesToNow = (date: AnyDate) => differenceInMinutes(new Date(), toDateFnsDate(date))

// Date Comparison Sorting functions
export const compareDatesDesc = (dateA: AnyDate, dateB: AnyDate) => compareDesc(toDateFnsDate(dateA), toDateFnsDate(dateB))
export const compareDatesAsc = (dateA: AnyDate, dateB: AnyDate) => compareAsc(toDateFnsDate(dateA), toDateFnsDate(dateB))

export const distanceToNow = (date: AnyDate) => formatDistanceToNow(toDateFnsDate(date), {
    addSuffix: true
})

export const getStartDateOfTimeSlot = (timeSlot: TimeSlot, date: AnyDate) => {
    const getHours = () => {
        const { start } = timeSlot

        return start.hour + (start.time_of_day === TimeOfDay.PM ? 12 : 0)
    }

    // Convert date to Singapore time

    const ISOFormatString = 'yyyy-MM-dd\'T\'HH:mm:ssXXX'

    const startDate = zonedTimeToUtc(format(set(
        toDateFnsDate(date),
        {
            hours: getHours(),
            minutes: timeSlot.start.minute
        }
    ), ISOFormatString), timeZones.ASIA_SINGAPORE)

    const formattedDate = format(startDate, ISOFormatString)

    return formattedDate
}

// Notice Validation function
export const validateNoticeHours = (date: AnyDate, noticeHours: number) => !isDatePast(subHoursFromDate(date, noticeHours))

export const convertToCurrentTimeZone = (dateString: string): string => {
    const date = parseISO(dateString)

    if (!isValid(date)) {
        return new Date().toISOString()
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const convertedDate = utcToZonedTime(date, timeZone)

    return format(convertedDate, 'yyyy-MM-dd\'T\'HH:mm:ssxxx')
}
export const lastSessionDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'MMM d, yyyy')
export const pauseDate = (date: AnyDate) => getOrdinalFormattedDate(formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EE, dd LLLL yyyy'))
export const pauseShortDate = (date: AnyDate) => getOrdinalFormattedDate(formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'dd LLL, yyyy'))
export const bookingFullDate = (date: AnyDate) => getOrdinalFormattedDate(formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEEE, dd LLLL yyyy'))
export const calendarPickerDate = (date: AnyDate) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEEE, LLL dd, yyyy')
export const getFullNameDayOfWeek = (date: AnyDate): string => format(toDateFnsDate(date), 'EEEE')
export const getFullBookingDateWithTimeZone = (date: AnyDate, locale: Languages) => formatInTimeZone(toDateFnsDate(date), timeZones.ASIA_SINGAPORE, 'EEE, dd LLL, yyyy - hh:mm aa z', { locale: calendarLanguages[locale] })
export const adjustBookingDates = (bookingDates: object, startingDate: string, frequency: BookingFrequency): object => {
    const numberOfWeeksToAdd = frequency === BookingFrequency.Weekly ? 1 : 2
    const startDate = toDateFnsDate(startingDate)
    const startDateTimestamp = startDate.setHours(0, 0, 0, 0)

    const adjustedBookingDates = Object.entries(bookingDates).reduce((acc, [key, bookingDateStr]) => {
        if (!bookingDateStr) {
            return acc
        }

        const bookingDate = toDateFnsDate(bookingDateStr)
        const bookingDateTimestamp = bookingDate.setHours(0, 0, 0, 0)

        const adjustedDate = bookingDateTimestamp < startDateTimestamp
            ? addWeeks(bookingDate, Math.ceil((startDateTimestamp - bookingDateTimestamp) / (7 * 24 * 60 * 60 * 1000)) * numberOfWeeksToAdd)
            : bookingDate

        const bookingHours = new Date(bookingDateStr).getHours()
        const bookingMinutes = new Date(bookingDateStr).getMinutes()
        const result: Date = new Date(adjustedDate.setHours(bookingHours, bookingMinutes))
        result.setHours(bookingHours)
        result.setMinutes(bookingMinutes)

        return {
            ...acc,
            [key]: result.toISOString()
        }
    }, {})

    return adjustedBookingDates
}

export const isWithinLastFewHours = (formattedDate: string, hours: number) => {
    const date = new Date(parseISO(formattedDate))
    const now = new Date()
    const hourDiff = (date.getTime() - now.getTime()) / 36e5

    return hourDiff < hours
}

export const fillMissingDates = dateStr => {
    const formatDate = date => date.toISOString().split('T')[0]

    const dates = dateStr.split(', ')
        .map(date => new Date(date))
        .sort((a, b) => a - b)

    const startDate = dates[0]
    const endDate = dates[dates.length - 1]

    const dateRange = Array.from({ length: (endDate - startDate) / (1000 * 60 * 60 * 24) + 1 })
        .map((element, index) => new Date(startDate.getTime() + index * 1000 * 60 * 60 * 24))
        .map(date => formatDate(date))

    return dateRange.join(', ')
}

export const convertToDate = (dateString, formatStr = 'dd-MM-yyyy'): Date =>
    utcToZonedTime(parse(dateString, formatStr, new Date()), timeZones.ASIA_SINGAPORE)

export const convertToTimeZone = (date, timeZone = timeZones.ASIA_SINGAPORE, outputFormat = 'dd-MM-yyyy', locale: Languages = Languages.en_US) => format(utcToZonedTime(date, timeZone), outputFormat, { locale: calendarLanguages[locale] })
export const getFormattedDate = (formatType: string, dateString?: string): string => {
    const currentDate = dateString ? toDateFnsDate(dateString) : new Date()
    const formatStr = FORMAT_MAP[formatType]

    if (!formatStr) {
        return ''
    }

    return format(currentDate, formatStr)

}

const getOrdinalSuffix = (day: number): string => {
    if (day >= 11 && day <= 13) {
        return `${day}th`
    }

    const lastDigit = day % 10

    switch (lastDigit) {
        case 1:
            return `${day}st`
        case 2:
            return `${day}nd`
        case 3:
            return `${day}rd`
        default:
            return `${day}th`
    }
}

const getOrdinalFormattedDate = (formattedDate: string): string => formattedDate.replace(
    /(\b\d{1,2}\b)/,
    match => getOrdinalSuffix(parseInt(match, 10))
)

export const combineDateAndTime = (date: AnyDate, time: string): string => {
    const parsedDate = parseISO(date as string)
    const isISOFormatTime = time.includes('T')
    const [hours, minutes] = (isISOFormatTime ? formatDateWithTime(time) : time).split(':').map(Number)
    const combinedDate = set(parsedDate, { hours, minutes })

    return formatISO(combinedDate)
}

export const combineDateAndTimeToSGT = (date: AnyDate, time: string): string => {
    const parsedDate = parseISO(date as string)
    const isISOFormatTime = time.includes('T')
    const [hours, minutes] = (isISOFormatTime ? formatDateWithTime(time) : time).split(':').map(Number)

    const combinedDate = set(parsedDate, { hours, minutes })

    const utcDate = zonedTimeToUtc(combinedDate, 'Asia/Singapore')

    return formatISO(utcDate)
}

export const formatDateWithDayAndTime = (date: AnyDate) => format(toDateFnsDate(date), 'EEEE h:mm a')
export const formatDateWithTimeInterval = (numOfHours: number, startTimeDate: string): string => {
    const startDate = parseISO(startTimeDate)
    const endDate = add(startDate, {
        hours: Math.floor(numOfHours),
        minutes: Math.ceil((numOfHours - Math.floor(numOfHours)) * 60)
    })

    const startTime = format(startDate, 'h:mm a')
    const endTime = format(endDate, 'h:mm a')

    return `${startTime} - ${endTime}`
}
export const formatTime = (date: AnyDate) => format(toDateFnsDate(date), 'EEEE')
export const formatDateWithDay = (date: AnyDate) => format(toDateFnsDate(date), 'EEEE')
export const formatDateWithTime = (date: AnyDate) => format(toDateFnsDate(date), 'HH:mm')
export const formatDateWithDayAndMonth = (date: AnyDate) => format(toDateFnsDate(date), 'EEEE, dd MMM')
export const formatBookingDate = (date: AnyDate) => format(toDateFnsDate(date), 'EEEE dd MMM, hh:mm aa')
export const formatBookingDateWithDay = (date: AnyDate) => format(toDateFnsDate(date), 'EEEE hh:mm aa')
export const getDayOfDateShort = (date: AnyDate) => format(toDateFnsDate(date), 'EEEEEE')
export const changeDayOfDate = (dateString: AnyDate, newDay: string): AnyDate => {
    if (newDay === getDayOfDateShort(dateString)) {
        return dateString
    }

    const parsedDate = parseISO(dateString as string)

    const currentDayIndex = WEEKDAYS.findIndex(day => day.toLowerCase() === getDayOfDateShort(dateString).toLowerCase())
    const newDayIndex = WEEKDAYS.findIndex(day => day.toLowerCase() === newDay.toLowerCase())

    const dayDifference = (newDayIndex - currentDayIndex)
    const newDate = addDays(parsedDate, dayDifference > 0 ? dayDifference : dayDifference + 7)

    return formatISO(newDate)
}

export const formatDateWithDateFns = (date: AnyDate) => format(toDateFnsDate(date), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
const addMonthsToDate = (date, months) => {
    const d = new Date(date)
    d.setMonth(d.getMonth() + months)

    return d
}

export const generatedDatesEvery3Months = (bookingDate, durationInYears) => {
    const totalIntervals = durationInYears * 4 // 4 intervals per year (every 3 months)
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const dates = Array.from({ length: totalIntervals }, (__, i) => addMonthsToDate(bookingDate, i * 3))

    return dates
}
