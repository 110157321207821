import { useRef, useState } from 'react'
import { TextInput } from 'react-native'
import { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Nullable, SelectInputOption } from 'lib/types'

type UseTextInputDropdownProps = {
    onMenuClosed?: VoidFunction,
    onChange: (option: Nullable<SelectInputOption>) => void,
    value: Nullable<SelectInputOption>
}

export const useTextInputDropdown = ({
    onChange,
    value,
    onMenuClosed
}: UseTextInputDropdownProps) => {
    const ref = useRef<TextInput>(null)
    const isMenuVisible = useSharedValue<boolean>(false)
    const [controlledValue, setControlledValue] = useState('')
    const animatedContainerStyles = useAnimatedStyle(() => ({
        display: isMenuVisible.value ? 'flex' : 'none'
    }))
    const animatedChevronStyles = useAnimatedStyle(() => ({
        transform: [
            {
                rotate: withTiming(`${isMenuVisible.value ? 180 : 0}deg`, {
                    duration: 200
                })
            }
        ]
    }))

    const onInputBlur = () => {
        if (controlledValue.length === 0) {
            return onChange(null)
        }
    }
    const handleChevronPress = () => {
        isMenuVisible.value = !isMenuVisible.value

        if (!isMenuVisible.value) {
            return ref.current?.focus()
        }

        if (onMenuClosed) {
            onMenuClosed()
        }

        setControlledValue(value?.label || '')
        ref.current?.blur()
        onInputBlur()
    }
    const onSelectOption = (option: SelectInputOption) => {
        if (onMenuClosed) {
            onMenuClosed()
        }

        onChange(option)
        setControlledValue(option.label)
        isMenuVisible.value = false
        ref.current?.blur()
    }
    const onInputFocus = () => {
        isMenuVisible.value = true
    }

    return {
        ref,
        controlledValue,
        animatedChevronStyles,
        animatedContainerStyles,
        onInputFocus,
        onInputBlur,
        handleChevronPress,
        onSelectOption,
        setControlledValue
    }
}
