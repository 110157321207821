import { INITIAL_SCHEDULE } from 'features/native/bookings/constants'
import { useAtom } from 'jotai'
import { createAtom } from 'lib/native/atoms/atomFactory'
import { MMKVKey } from 'lib/native/mmkv'
import { JobBookingDetailAtom } from 'lib/native/models'

const { atom, resetAtom } = createAtom<JobBookingDetailAtom>({ booking: { schedule : INITIAL_SCHEDULE } } as JobBookingDetailAtom, MMKVKey.JobBookingDetail)

export const useNativeJobBookingDetailAtom = () => useAtom(atom)
export const useNativeResetJobBookingDetailAtom = () => useAtom(resetAtom)
