import React from 'react'
import { View } from 'react-native'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useFeatureFlagAtom, useLocaleAtom } from 'lib/atoms'
import { Children, Nullable } from 'lib/types'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingSummary } from './BookingSummary'
import { CleaningFormShape } from '../forms'
import { GetPromotionResponse, JobQuotationResponse } from '../types'

type CleaningSummaryColumnProps = {
    extraHours?: number,
    form: CleaningFormShape,
    couponText?: string,
    title?: string,
    jobQuotation?: JobQuotationResponse,
    renderPriceComponent: () => Children,
    renderPriceSummaryComponent?: () => Children,
    renderSubTotalComponent?: () => Children,
    renderExtraContent?: () => Children,
    promotionPackage?: Nullable<GetPromotionResponse>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>,
    selectWeeklyPlan?(): void;
}

export const CleaningSummaryColumn: React.FunctionComponent<CleaningSummaryColumnProps> = ({
    form,
    title,
    couponText,
    extraHours = 0,
    renderPriceComponent,
    renderPriceSummaryComponent,
    renderExtraContent,
    promotionPackage,
    totalPrice,
    originalPrice,
    jobQuotation,
    selectWeeklyPlan
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const [featureFlagAtom] = useFeatureFlagAtom()
    const bookingDateDayName = dateHelpers.getWeekDayFullNames(form.startingDate || new Date(), locale)
    const frequency = form.frequency
    const totalHours = Number(form.hours) + Number(extraHours)
    const shortStartingDate = form.startingDate ? dateHelpers.shortStartingDate(form.startingDate) : ''
    const bookingTime = form.bookingTime
        ? dateHelpers.selectTimeLabel(form.bookingTime)
        : form.bookingDays[bookingDateDayName]
            ? dateHelpers.selectTimeLabel(form.bookingDays[bookingDateDayName] || new Date())
            : ''

    const isOneOffPlan = frequency.value === 'oneTime'
    const cleaningFrequency = frequency && !isOneOffPlan ? frequency.label : ''
    const cleaningPlan = frequency.label ?
        isOneOffPlan ?
            T.components.bookingSummary.oneOff :
            T.components.bookingSummary.subscription : ''
    const firstSession = shortStartingDate && bookingTime ?
        `${shortStartingDate} - ${bookingTime}` :
        shortStartingDate ? shortStartingDate : ''

    const totalSessionsGenerated = jobHelpers.getTotalSession(jobQuotation as JobQuotationResponse)

    return (
        <View style={styles.container}>
            <BookingSummary
                promotionPackage={promotionPackage}
                form={form}
                jobQuotation={jobQuotation}
                selectWeeklyPlan={selectWeeklyPlan}
                title={title}
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                couponText={couponText}
                sessionsGeneratedDescription={T.components.bookingSummary.sessionsGeneratedDescription}
                renderPriceComponent={renderPriceComponent}
                renderPriceSummaryComponent={renderPriceSummaryComponent}
                bookingSummary={{
                    [T.components.bookingSummary.cleaningPlan]: cleaningPlan,
                    ...(cleaningFrequency && { [T.components.bookingSummary.cleaningFrequency]: cleaningFrequency }),
                    ...(cleaningFrequency && { [T.components.bookingSummary.firstSession]: firstSession }),
                    ...(!cleaningFrequency && { [featureFlagAtom?.isEnableDynamicPricing ? T.components.bookingSummary.startingDate : T.components.bookingSummary.date]: shortStartingDate }),
                    ...(!cleaningFrequency && { [featureFlagAtom?.isEnableDynamicPricing ? T.components.bookingSummary.bookingTime : T.components.bookingSummary.time]: bookingTime }),
                    [T.components.bookingSummary.propertyType]: String(form.propertyType),
                    [T.components.bookingSummary.hoursBooked]: `${totalHours} ${T.common.hours.toLowerCase()}`,
                    ...(cleaningFrequency && { [T.components.bookingSummary.sessionsGenerated]: totalSessionsGenerated })
                }}
            />
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
