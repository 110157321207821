import { getAllCookies, getCookieByName } from 'features/auth/cookies/utils'
import { APP_CONFIG } from 'lib/config'
import { useNativeCampaignAtom } from 'lib/native/atoms'
import { TrackingSessionData } from 'lib/types'
import { useEffect, useState } from 'react'

export const getGtagInstance = () => {
    if (!window.gtag) {
        return
    }

    return window.gtag
}
export const useSessionData = () => {
    const [sessionData, setSessionData] = useState<TrackingSessionData | null>(null)
    const [campaign] = useNativeCampaignAtom()

    const getParsedUtmCookie = () => {
        const rawUTM = getCookieByName('__UTM__')

        if (!rawUTM) {
            return {}
        }

        const rawUTMArray = rawUTM.split('&')

        const utm_source = rawUTMArray.length > 0 ? rawUTMArray[0] : ''
        const utm_medium = rawUTMArray.length > 1 ? rawUTMArray[1] : ''
        const utm_campaign = rawUTMArray.length > 2 ? rawUTMArray[2] : ''
        const utm_content = rawUTMArray.length > 3 ? rawUTMArray[3] : ''

        return {
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content
        }
    }

    const getDeepLinkCampaignDetails = () => {
        const utm_source = campaign?.source
        const utm_campaign = campaign?.name
        const af_adset = campaign?.af_adset
        const af_ad = campaign?.af_ad
        const af_channel = campaign?.af_channel

        return { utm_source, utm_campaign, af_adset, af_ad, af_channel }
    }

    const getTrackingCookies = () => ({
        fbp: getCookieByName('_fbp'),
        fbc: getCookieByName('_fbc'),
        gclid: getCookieByName('_gcl_aw')?.split('.').pop(),
        campaign: { ...getParsedUtmCookie(), ...getDeepLinkCampaignDetails() },
        allCookies: getAllCookies()
    })

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                const gtag = getGtagInstance()

                if (!gtag) {
                    return {}
                }

                const gtagPromises = [
                    new Promise<string>(resolve => gtag('get', APP_CONFIG.GA4.TRACKING_ID, 'session_id', resolve)),
                    new Promise<string>(resolve => gtag('get', APP_CONFIG.GA4.TRACKING_ID, 'session_number', resolve)),
                    new Promise<string>(resolve => gtag('get', APP_CONFIG.GA4.TRACKING_ID, 'client_id', resolve))
                ]

                const [sessionId,
                       sessionNumber,
                       clientId] = await Promise.all(gtagPromises)

                setSessionData({ sessionId, sessionNumber, clientId })
            } catch (error) {
                console.error('Error fetching session data:', error)
            }
        }

        fetchSessionData()
    }, [])

    return { ...sessionData, ...getTrackingCookies() }
}
