import React, { useEffect, useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { JobStatus, JobType, NotificationType } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useToastAtom, useUserTokenAtom } from 'lib/atoms'
import { Segment, segmentUtils } from 'lib/analytics'
import { Adapter, BasicModal, Button, CallToActionButton, Typography } from 'lib/components'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'
import { ExtendSessionFormShape, useExtendSessionForm } from '../forms'
import { extendJobHours, getAirConditionJobDetails, getConsumerActiveJobs, getJobDetails } from '../actions'
import { JobContact } from './JobContact'
import { jobHelpers } from 'lib/utils'
import { isAirconSubscription } from 'features/bookings/utils/airconUtils'

type ExtendSessionProps = {
    data: JobDetailsResponse,
    containerStyle?: ViewStyle,
    fullWidth?: boolean,
    customButton?(onPress: VoidFunction): JSX.Element
}

export const ExtendSession: React.FunctionComponent<ExtendSessionProps> = ({
    data,
    fullWidth,
    containerStyle,
    customButton
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [token] = useUserTokenAtom()
    const [, setToastMessage] = useToastAtom()
    const { onRequestError } = useQueryHelpers()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { mutate: extend, isLoading: isMutating } = extendJobHours()
    const getJobDetailsSource = isAirconSubscription(data.job) ? getAirConditionJobDetails : getJobDetails
    const { refetch: refetchJobDetails, isRefetching: isRefetchingJobDetails } = getJobDetailsSource(data.job._id)
    const { refetch: refetchRecurringJobDetails, isRefetching: isRefetchingRecurringJobDetails } = getJobDetailsSource(data.job.recurring_job!)
    const { refetch: refetchActiveJobs, isRefetching: isRefetchingActiveJobs } = getConsumerActiveJobs()
    const { form, submit, resetForm } = useForm<ExtendSessionFormShape>(useExtendSessionForm(), {
        onSuccess: ({ extraHours }) => {
            extend(
                {
                    token,
                    is_recurring: data.job.job_type === JobType.Recurring,
                    job_id: data.job._id,
                    num_hours: extraHours
                },
                {
                    onSuccess: () => {
                        resetForm()
                        refetchJobDetails()

                        if (data.job.job_type === JobType.Recurring) {
                            refetchRecurringJobDetails()
                        }

                        refetchActiveJobs()
                        setIsModalOpen(false)
                        setToastMessage({
                            type: NotificationType.Success,
                            message: T.components.extendSession.extendSuccessMessage
                        })

                        Segment.jobSessionExtended({
                            extraHours: Number(extraHours),
                            jobId: data.job.job_id,
                            supplierId: data?.job?.supplier?._id,
                            serviceType: segmentUtils.supplyIdToServiceType(data.job.supply.id)
                        })
                    },
                    onError: onRequestError
                }
            )
        }
    })
    const formatLabel = (value: string) => {
        switch (value) {
            case '0.5':
            case '1':
                return `${value} ${T.common.hour}`
            default:
                return `${value} ${T.common.hours}`
        }
    }

    useEffect(() => {
        if (isModalOpen) {
            Segment.addHoursToSessionClicked({
                jobId: data.job.job_id,
                supplierId: data?.job?.supplier?._id,
                serviceType: segmentUtils.supplyIdToServiceType(data.job.supply.id)
            })
        }
    }, [isModalOpen])

    if (!jobHelpers.shouldShowExtendSessionButton(data)) {
        return null
    }

    const totalHours = Number(data.job.num_hours) + Number(form.extraHours.value)
    const isLoading = isMutating || isRefetchingJobDetails || isRefetchingActiveJobs || isRefetchingRecurringJobDetails

    return (
        <View
            style={{
                ...(fullWidth ? styles.containerFullWidth : styles.container),
                ...containerStyle
            }}
        >
            <BasicModal
                isOpen={isModalOpen}
                isLoading={isLoading}
                onClose={() => setIsModalOpen(false)}
            >
                <View style={styles.modalContent}>
                    <Typography.SmallSubheading>
                        {T.components.extendSession.addTime}
                    </Typography.SmallSubheading>
                    <Typography.Regular
                        style={styles.message}
                        forceColor={theme.colors.darkGrey}
                    >
                        {T.components.extendSession.message}
                    </Typography.Regular>
                    <View style={styles.formContainer}>
                        <View style={styles.formWrapper}>
                            <Typography.Regular>
                                {T.components.extendSession.addTime}
                            </Typography.Regular>
                            <Adapter.NumberInput
                                {...form.extraHours}
                                step={0.5}
                                maxValue={8}
                                disabled={isLoading}
                                maxReached={totalHours >= 8}
                                onFormatLabel={formatLabel}
                            />
                            <View style={styles.buttonsContainer}>
                                <View style={styles.detailsWrapper}>
                                    <Typography.Label forceColor={theme.colors.darkGrey}>
                                        {T.components.extendSession.currentHours}
                                    </Typography.Label>
                                    <Typography.Label>
                                        {`${data.job.num_hours} ${T.common.hours}`}
                                    </Typography.Label>
                                </View>
                                <View style={styles.separator} />
                                <View style={styles.detailsWrapper}>
                                    <Typography.Label forceColor={theme.colors.darkGrey}>
                                        {T.components.extendSession.updatedHours}
                                    </Typography.Label>
                                    <Typography.Label>
                                        {Number(form.extraHours.value) > 0
                                            ? `${totalHours} ${T.common.hours}`
                                            : '-'
                                        }
                                    </Typography.Label>
                                </View>
                            </View>
                        </View>
                        {data.job.status === JobStatus.Accepted && (
                            <View style={styles.contactContainer}>
                                <Typography.Label style={styles.contactMessage}>
                                    {T.components.extendSession.notifyMessage}
                                </Typography.Label>
                                <JobContact
                                    job={data.job}
                                    onChatPress={() => setIsModalOpen(false)}
                                />
                            </View>
                        )}
                    </View>
                    <View style={styles.submitContainer}>
                        <View style={styles.container}>
                            <Button
                                noBackground
                                disabled={isLoading}
                                onPress={() => setIsModalOpen(false)}
                                text={T.common.cancel}
                            />
                        </View>
                        <View style={styles.separator} />
                        <View style={styles.container}>
                            <Button
                                onPress={submit}
                                text={T.common.confirm}
                                isLoading={isLoading}
                                disabled={Number(form.extraHours.value) === 0}
                            />
                        </View>
                    </View>
                </View>
            </BasicModal>
            <View style={fullWidth ? styles.buttonWrapperFullWidth : styles.buttonWrapper}>
                {customButton
                    ? customButton(() => setIsModalOpen(true))
                    : (
                        <CallToActionButton
                            onPress={() => setIsModalOpen(true)}
                            text={T.screens.jobDetails.addHoursToThisSession}
                        />
                    )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    containerFullWidth: {
        flex: 1,
        width: '100%',
        marginBottom: {
            lg: theme.utils.gap(2),
            xs: undefined
        }
    },
    container: {
        flex: 1,
        marginBottom: {
            lg: undefined,
            xs: theme.utils.gap(2)
        }
    },
    buttonWrapperFullWidth: {
        width: '100%'
    },
    buttonWrapper: {
        width: {
            ':w[1250, ]': 240,
            ':w[, 1250]': undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    detailsWrapper: {
        width: {
            lg: 240,
            xs: undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    currentSessionWrapper: {
        marginLeft: {
            lg: theme.utils.gap(4),
            xs: 0
        }
    },
    modalContent: {
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: undefined
        }
    },
    message: {
        marginVertical: theme.utils.gap(2)
    },
    buttonsContainer: {
        width: {
            lg: 450,
            xs: undefined
        },
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    contactContainer: {
        width: {
            lg: 450,
            xs: undefined
        },
        marginLeft: {
            lg: theme.utils.gap(2),
            xs: 0
        }
    },
    contactMessage: {
        marginBottom: theme.utils.gap(1)
    },
    separator: {
        width: theme.utils.gap(2)
    },
    formWrapper: {
        marginVertical: theme.utils.gap(2)
    },
    formContainer: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'center',
            xs: undefined
        }
    },
    submitContainer: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        marginTop: {
            lg: undefined,
            xs: theme.utils.gap(4)
        }
    }
}))
