import { CommercialPropertyDetails, ResidentialPropertyDetails } from 'lib/native/models'

export type LatLng = {
    lat: number,
    lng: number
}

export enum AddressPropertyType {
    Residential = 'Residential',
    Commercial = 'Commercial'
}

export enum HousingType {
    Condominium = 'Condominium',
    HDB = 'HDB',
    Landed = 'Landed Property',
    Other = 'Shophouse Home'
}

export type NewAddressRequestShape = {
    postcode: string,
    district: string,
    address: string,
    geo: LatLng,
    unit: string,
    propertyDetails?: ResidentialPropertyDetails | CommercialPropertyDetails
}

export type Address = {
    postcode: string;
    address: string,
    geo: LatLng
}
