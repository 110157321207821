import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Telegram: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="m1.913 38.572 18.434 6.88 7.135 22.946a2.17 2.17 0 0 0 3.446 1.038l10.275-8.377a3.065 3.065 0 0 1 3.737-.104L63.473 74.41c1.276.928 3.084.228 3.403-1.313L80.453 7.792c.35-1.684-1.306-3.089-2.91-2.469L1.891 34.507c-1.866.72-1.85 3.364.022 4.065Zm24.419 3.218L62.358 19.6c.647-.397 1.313.478.757.994L33.383 48.232a6.164 6.164 0 0 0-1.91 3.688l-1.012 7.506c-.135 1.002-1.542 1.101-1.819.131l-3.895-13.686a3.627 3.627 0 0 1 1.585-4.081Z" />
    </Icon>
)
