import { useAtom } from 'jotai'
import { DynamicPriceData, DynamicPriceMessages, DynamicTimeSlots, DynamicPriceError } from 'lib/types'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'

const initialDynamicPrice: DynamicPriceData = {
    timeSlots: {} as DynamicTimeSlots,
    messages: {} as DynamicPriceMessages,
    errors: {} as DynamicPriceError,
    month: undefined,
    day: undefined
}

const { atom, resetAtom } = createAtom<DynamicPriceData>(initialDynamicPrice, MMKVKey.DynamicPrice)

export const useDynamicPriceAtom = () => useAtom(atom)
export const useResetDynamicPriceAtom = () => useAtom(resetAtom)
