import React from 'react'
import { View } from 'react-native'
import { Breakpoint, createStyles } from 'lib/styles'
import { ProposalStatus } from 'lib/types'
import { Typography } from 'lib/components'
import { ConsumerPaymentDetails } from 'lib/models'
import { PaymentMethodTile } from 'features/bookings'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'
import { BookingProposalTile } from './BookingProposalTile'
import { DetailsContactLink } from './DetailsContactLink'

type BookingsProposalsProps = {
    job: JobDetailsResponse,
    isRecurring: boolean
}

export const BookingsProposals: React.FunctionComponent<BookingsProposalsProps> = ({
    job,
    isRecurring
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const submittedProposals = job.job.job_proposals?.filter(proposal => proposal.status === ProposalStatus.Submitted) || []
    const isEmpty = submittedProposals.length === 0
    const proposalsNumber = submittedProposals.length
    const getConsumerCreditCardToken = () => {
        try {
            const [payment] = job.job.customer_payment

            return JSON.parse(payment.payment_details) as ConsumerPaymentDetails

        } catch {
            return null
        }
    }

    if (job.job.recurring_job || isRecurring) {
        return (
            <View style={styles.wrapper}>
                <PaymentMethodTile
                    notEnoughCredits={false}
                    selectedPaymentMethod={job.job.paymentType}
                    creditCardToken={getConsumerCreditCardToken()?.result.creditCard.token}
                />
                {!isMobile && (
                    <DetailsContactLink text={T.common.gotQuestion} />
                )}
            </View>
        )
    }

    return (
        <View style={styles.wrapper}>
            <View style={styles.content}>
                <View style={styles.title}>
                    <View style={styles.proposalsNumber}>
                        <Typography.Regular forceColor={theme.colors.white}>
                            {proposalsNumber}
                        </Typography.Regular>
                    </View>
                    <Typography.Regular bold>
                        {T.components.proposals.title}
                    </Typography.Regular>
                </View>
                {isEmpty
                    ? (
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.components.proposals.empty}
                        </Typography.Label>
                    ) : (submittedProposals.map((proposal, index) => (
                        <BookingProposalTile
                            key={proposal._id}
                            job={job.job}
                            proposal={proposal}
                            isLast={index === proposalsNumber - 1}
                        />
                    )))
                }
            </View>
            {!isMobile && (
                <DetailsContactLink text={T.common.gotQuestion} />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        width: {
            lg: 290,
            xs: undefined
        },
        marginLeft: {
            lg: theme.utils.gap(2),
            xs: undefined
        },
        marginTop: {
            lg: undefined,
            xs: theme.utils.gap(2)
        },
        marginBottom: theme.utils.gap(2)
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    proposalsNumber: {
        width: 24,
        height: 24,
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.utils.gap(1),
        backgroundColor: theme.colors.night
    }
}))
