import React, { useEffect, useState } from 'react'
import { Text, StyleSheet } from 'react-native'
import 'react-native-get-random-values'
import 'date-time-format-timezone'
import 'core-js/actual/url-search-params'
import {
    NavigationContainerRefWithCurrent,
    useNavigationContainerRef
} from '@react-navigation/native'
import { StatusBar } from 'expo-status-bar'
import * as SplashScreen from 'expo-splash-screen'
import { QueryClientProvider } from '@tanstack/react-query'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { XMPPChatProvider } from 'features/chat'
import { PushNotificationProvider } from 'features/pushNotifications'
import { ForceUpdateListener, GeneralUpdateListener, OTAUpdateListener } from 'features/updates'
import { MaintenanceScreen } from 'features/maintenance'
import { ServiceConfigurationProvider } from 'features/servicesConfig'
import { HiveComponentProvider } from 'features/hiveComponents'
import { initDataDog } from 'lib/datadog'
import { isNative } from 'lib/common'
import { queryClient } from 'lib/api'
import {
    AppRouter,
    NavigationScreenParams
} from 'lib/routing'
import { R } from 'lib/utils'
import { useCustomFonts, useIsNewAppEnabled, useQueryFocusManager } from 'lib/hooks'
import { AppVersion, Hotjar, SendhelperListener, Toast } from 'lib/components'
import { Toast as ToastNative } from 'lib/native/components'
import { getAppsFlyerId } from 'lib/analytics/segment/utils'
import { useAppsflyerIdAtom } from 'lib/atoms'
import type { NativeAppRouterProps, NativeNavigationScreenParams } from 'lib/native/routing'

/* eslint-disable @typescript-eslint/ban-types */
interface TextWithDefaultProps extends Text {
    defaultProps?: { allowFontScaling?: boolean }
}

(Text as unknown as TextWithDefaultProps).defaultProps = {
    ...((Text as unknown as TextWithDefaultProps).defaultProps || {}),
    allowFontScaling: false
}

SplashScreen
    .preventAutoHideAsync()
    .catch(R.T)

export const App: React.FunctionComponent = () => {

    const nativeAppConfig: {
        nativeAppRouter: React.FunctionComponent<NativeAppRouterProps> | null;
        nativeNavigationRef: NavigationContainerRefWithCurrent<NativeNavigationScreenParams> | null;
    } = {
        nativeAppRouter: null,
        nativeNavigationRef: null
    }

    if (isNative) {
        // Import this way to avoid crashes when rendering on the web
        const nativeModule = require('lib/native/routing')
        nativeAppConfig.nativeAppRouter = nativeModule?.NativeAppRouter
        nativeAppConfig.nativeNavigationRef = useNavigationContainerRef<NativeNavigationScreenParams>()
    }

    const { fontsLoaded, onLayoutRootView } = useCustomFonts()
    const { isEnabled, isFetching } = useIsNewAppEnabled()
    const [isNavigationReady, setNavigationIsReady] = useState(false)
    const [, setAppsflyerId] = useAppsflyerIdAtom()
    const navigationRef = useNavigationContainerRef<NavigationScreenParams>()

    useQueryFocusManager()
    useEffect(() => {
        initDataDog()
            .catch(R.T)

        const setAppsflyerIdFn = async () => {
            const id = await getAppsFlyerId
            setAppsflyerId(id)
        }
        setAppsflyerIdFn()
    }, [])

    if (!fontsLoaded || isFetching) {
        return null
    }

    const renderAppRouter = () => {
        if (
            isEnabled &&
            nativeAppConfig?.nativeNavigationRef &&
            nativeAppConfig?.nativeAppRouter
        ) {
            return (
                <HiveComponentProvider>
                    <nativeAppConfig.nativeAppRouter
                        navigationRef={nativeAppConfig.nativeNavigationRef}
                        onNavigationReady={() => setNavigationIsReady(true)}
                    />
                </HiveComponentProvider>
            )
        }

        return (
            <AppRouter
                navigationRef={navigationRef}
                onNavigationReady={() => setNavigationIsReady(true)}
            />
        )
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Hotjar />
            <GestureHandlerRootView
                onLayout={onLayoutRootView}
                style={styles.container}
            >
                <SendhelperListener>
                    <XMPPChatProvider>
                        <ServiceConfigurationProvider>
                            {renderAppRouter()}
                        </ServiceConfigurationProvider>
                    </XMPPChatProvider>
                </SendhelperListener>
                <StatusBar style="auto" />
                <AppVersion />
                {isNative ? <ToastNative /> : <Toast />}
                {isNative && (
                    <React.Fragment>
                        <OTAUpdateListener />
                        <ForceUpdateListener />
                        <GeneralUpdateListener />
                    </React.Fragment>
                )}
                <MaintenanceScreen />
                {isNavigationReady && (
                    <PushNotificationProvider navigationRef={navigationRef} />
                )}
            </GestureHandlerRootView>
        </QueryClientProvider>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%'
    }
})
