import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import { useStyles, useTranslations } from 'lib/hooks'
import { regexes } from 'lib/utils'
import { AsyncSelectInputProps } from './types'
import { ErrorMessage } from '../ErrorMessage'
import { CustomClearIndicator, CustomDropdownIndicator } from './webComponents'

export const AsyncSelectInput: React.FunctionComponent<AsyncSelectInputProps> = ({
    disabled,
    onChange,
    value,
    placeholder,
    errorMessage,
    loadingOptions,
    defaultOptions
}) => {
    const { theme } = useStyles()
    const T = useTranslations()
    const [controlledValue, setControlledValue] = useState('')

    const handleInputChange = (value: string) => {
        if (value.length > 6 || !regexes.isValidDigit(value.trim())) {
            return
        }

        setControlledValue(value.trim())

    }

    const getBorderColor = () => {
        if (errorMessage) {
            return theme.components.input.error.borderColor
        }

        if (!value) {
            return theme.components.input.emptyBorderColor
        }

        return theme.components.input.filledBorderColor
    }

    return (
        <React.Fragment>
            <AsyncSelect
                isClearable
                cacheOptions
                backspaceRemovesValue
                value={value}
                isDisabled={disabled}
                loadOptions={value => loadingOptions(value)}
                placeholder={placeholder}
                defaultOptions={defaultOptions}
                onInputChange={handleInputChange}
                inputValue={controlledValue}
                onChange={onChange}
                loadingMessage={() => T.common.loading}
                noOptionsMessage={() => T.common.noOptions}
                components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: CustomDropdownIndicator
                }}
                styles={{
                    control: base => ({
                        ...base,
                        height: theme.components.input.height,
                        borderRadius: theme.components.input.borderRadius,
                        borderColor: getBorderColor(),
                        paddingLeft: theme.components.input.padding,
                        fontFamily: theme.components.typography.fontFamily,
                        boxShadow: 'none',
                        '&:hover': {
                            borderColor: theme.colors.night
                        }
                    }),
                    valueContainer: base => ({
                        ...base,
                        paddingLeft: 0
                    }),
                    singleValue: base => ({
                        ...base,
                        fontSize: 14,
                        color: theme.components.input.typography.text
                    }),
                    input: base => ({
                        ...base,
                        fontSize: 14,
                        color: theme.components.input.typography.text
                    }),
                    placeholder: base => ({
                        ...base,
                        fontSize: 14,
                        marginLeft: 0,
                        color: errorMessage
                            ? theme.components.input.typography.error
                            : theme.components.input.typography.placeholder
                    }),
                    indicatorSeparator: base => ({
                        ...base,
                        width: 0
                    }),
                    menu: base => ({
                        ...base,
                        borderRadius: theme.components.input.borderRadius,
                        boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.16)'
                    }),
                    option: (base, props) => ({
                        ...base,
                        fontSize: 16,
                        color: props.isFocused
                            ? theme.colors.orange
                            : theme.components.input.typography.text,
                        backgroundColor: props.isFocused
                            ? theme.colors.yellow
                            : 'transparent',
                        fontFamily: theme.components.typography.fontFamily
                    }),
                    loadingMessage: base => ({
                        ...base,
                        color: theme.components.input.typography.placeholder,
                        fontFamily: theme.components.typography.fontFamily
                    }),
                    noOptionsMessage: base => ({
                        ...base,
                        color: theme.components.input.typography.placeholder,
                        fontFamily: theme.components.typography.fontFamily
                    })
                }}
            />
            <ErrorMessage text={errorMessage} />
        </React.Fragment>
    )
}
