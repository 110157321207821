import React from 'react'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'

export const useGreetings = (isAuthorized: boolean, handle?: string) => {
    const T = useTranslations()
    const { theme, styles } = useStyles(stylesheet)
    const [userName] = handle && handle.length > 0
        ? handle.split(' ')
        : []

    return isAuthorized
        ? (
            <React.Fragment>
                <Typography.Regular>
                    <Typography.Regular style={styles.text}>
                        {`${T.common.hello}, `}
                    </Typography.Regular>
                    <Typography.Regular
                        numberOfLines={1}
                        style={styles.text}
                        forceColor={theme.colors.orange}
                    >
                        {userName}
                    </Typography.Regular>
                </Typography.Regular>
            </React.Fragment>
        )
        : T.headerLinks.signIn
}

const stylesheet = createStyles(theme => ({
    text: {
        ...theme.components.header.link
    }
}))
