import { Period } from 'lib/native/types'
import { useMemo } from 'react'
import { useNativeCleaningConfigsAtom } from '../atoms/cleaningConfigs'

export const useTimeSlotsByPeriod = (groupKey?: Period) => {
    const [cleaningConfigsAtom] = useNativeCleaningConfigsAtom()

    if(!groupKey) {
        return cleaningConfigsAtom?.timeSlots
    }

    const groupedSlots = useMemo(() =>
        cleaningConfigsAtom?.timeSlots?.filter(slot => slot.group === groupKey)
    , [])

    return groupedSlots
}
