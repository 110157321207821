import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const ChatFilled: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M67.725 3h-54.95C6.102 3 1 7.958 1 14.441v57.207c0 1.525.785 2.67 1.962 3.432.785.382 1.178.382 1.963.382.785 0 1.57 0 1.963-.382L24.55 64.402c.785-.382 1.57-.382 2.355-.382h40.82c6.672 0 11.775-4.958 11.775-11.441V14.44C79.5 7.958 74.397 3 67.725 3ZM24.55 37.324c-2.355 0-3.925-1.526-3.925-3.814 0-2.288 1.57-3.814 3.925-3.814 2.355 0 3.925 1.526 3.925 3.814 0 2.288-1.57 3.814-3.925 3.814Zm15.7 0c-2.355 0-3.925-1.526-3.925-3.814 0-2.288 1.57-3.814 3.925-3.814 2.355 0 3.925 1.526 3.925 3.814 0 2.288-1.57 3.814-3.925 3.814Zm15.7 0c-2.355 0-3.925-1.526-3.925-3.814 0-2.288 1.57-3.814 3.925-3.814 2.355 0 3.925 1.526 3.925 3.814 0 2.288-1.57 3.814-3.925 3.814Z" />
    </Icon>
)
