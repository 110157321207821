import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Facebook: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M80.5 40.248c0 20.088-14.628 36.738-33.75 39.756V51.88h9.32l1.774-11.633H46.75V32.7c0-3.183 1.55-6.285 6.519-6.285h5.044V16.51s-4.579-.786-8.955-.786c-9.136 0-15.108 5.572-15.108 15.658v8.866H24.094v11.634H34.25v28.122C15.128 76.986.5 60.336.5 40.248.5 18.023 18.41.004 40.5.004s40 18.019 40 40.244Z" />
    </Icon>
)
