import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Modal } from '@propertyguru/hive-modal'
import { Text } from '@propertyguru/hive-text'
import { DateTimeCalendarTimeOutline, HourglassTimeWatchClockOutline } from '@propertyguru/hive-icons'
import { Icon } from '@propertyguru/hive-icon'
import Animated, { Easing, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { NativeModal } from 'lib/native/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { RatingStars } from './RatingStars.native'

type RatingPopupProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    onRatingPress: (value: number) => void,
    supplierName?: string,
    date?: string,
    hours?: number
}

export const RatingPopup: React.FunctionComponent<RatingPopupProps> = ({ isOpen, onClose, onRatingPress, supplierName, date, hours }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [rating, setRating] = useState(0)

    useEffect(() => () => {
        setRating(0)
    }, [isOpen])

    const animatedPopupStyles = useAnimatedStyle(() => ({
        bottom: withTiming(isOpen ? 0 : -1000, { duration: 300, easing: Easing.inOut(Easing.ease) })
    }))

    const onRatingStarPress = (value: number) => {
        setRating(value)
        onRatingPress(value)
    }

    return (
        <NativeModal scrollViewContainerStyles={styles.scrollViewContainer} fullWidth isOpen={isOpen} onClose={onClose} >
            <Animated.View style={animatedPopupStyles}>
                <Modal>
                    <Modal.Header>
                        <Modal.Header.Title>
                            {T.screens.dashboard.ratingPopup.title}
                        </Modal.Header.Title>
                        <Modal.Header.Button onPress={onClose} />
                    </Modal.Header>
                    <Modal.Body style={styles.modalBody}>
                        <Text style={styles.content} typography="body/s">{T.screens.dashboard.ratingPopup.description}{supplierName}?</Text>
                        <View style={styles.scheduleInfo}>
                            <View style={styles.scheduleItem}><Icon icon={DateTimeCalendarTimeOutline} height={16} width={16} /><Text typography="body/s">{date}</Text></View>
                            <Text typography="body/s"> | </Text>
                            <View style={styles.scheduleItem}><Icon icon={HourglassTimeWatchClockOutline} height={16} width={16} /><Text typography="body/s">{hours} {T.common.smallHours}</Text></View>
                        </View>
                        <RatingStars rating={rating} onRate={onRatingStarPress} />
                    </Modal.Body>
                </Modal>
            </Animated.View>
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    ratingStars: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: theme.utils.gap(1),
        marginTop: theme.utils.gap(2)
    },
    scrollViewContainer: {
        justifyContent: 'flex-end'
    },
    modalBody: {
        padding: theme.utils.gap(2),
        alignItems: 'center'
    },
    scheduleInfo: {
        marginTop: theme.utils.gap(2),
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.gap(1)
    },
    scheduleItem: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.gap(0.5)
    },
    content: {
        textAlign: 'center'
    }
}))
